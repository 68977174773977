import React, { useState, useRef, useEffect } from 'react'
import { DirectUpload } from '@rails/activestorage'
import { useLazyQuery, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { FaEuroSign, FaQuestionCircle } from 'react-icons/fa'
import Modal from '../../../UI/Modal'
import RadioButton from '../../../UI/Form/RadioButton'
import DraggableList from '../../../UI/Form/DraggableList'
import { getCoursesQuery } from '../../../../apollo/query/courses'
import { sortOnKey } from '../../../../utils/format'
import { updateProductMutation } from '../../../../apollo/query/products'
import ProductState from '../ProductState'
import DataTooltip from '../../../UI/DataTooltip'
import scenarioBg from '../../../../../../images/default/scenario.png'
import {
  addSuccessAlert,
  addErrorAlert,
} from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import { getPublishedScenariosQuery } from '../../../../apollo/query/scenarios'

const EditProductModal = ({ id, appendTo, product, callback }) => {
  const LICENSE_DURATION_OPTIONS = [1, 2, 3, 6, 12]
  const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

  const INITIAL_STATE = {
    name: product?.name,
    description: product?.description,
    kind: product?.kind,
    price: product?.price,
    licenseDuration: product?.licenseDuration,
    licenseType: product?.licenseType,
    imageUrl: product?.imageUrl,
  }
  const newCoursesListRef = useRef([])
  const newScenariosListRef = useRef([])
  const [key, setKey] = useState(uuidv4())
  const [values, setValues] = useState(INITIAL_STATE)

  const [image, setImage] = useState(null)
  const [previewImage, setPreviewImage] = useState('')
  const [coursesList, setCoursesList] = useState([])
  const [selectedCoursesList, setSelectedCoursesList] = useState([])
  const [scenariosList, setScenariosList] = useState([])
  const [selectedScenariosList, setSelectedScenariosList] = useState([])

  const [getCourses, { loading }] = useLazyQuery(getCoursesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const list = data.courses.reduce((a, course) => {
        a.push({
          render: course.name,
          value: course.name,
          id: course.id,
        })
        return a
      }, [])
      setCoursesList(sortOnKey(list, 'value', false))
    },
  })

  const [getScenarios, { scenariosLoading }] = useLazyQuery(
    getPublishedScenariosQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const list = data.publishedScenarios.reduce((a, scenario) => {
          a.push({
            render: scenario.name,
            value: scenario.name,
            id: scenario.id,
          })
          return a
        }, [])
        setScenariosList(sortOnKey(list, 'value', false))
      },
    }
  )

  const [editProduct] = useMutation(updateProductMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({ title: 'Saved ' })
      callback()
    },
  })

  useEffect(() => {
    if (product) {
      setKey(uuidv4())
      const {
        name,
        description,
        kind,
        price,
        licenseDuration,
        state,
        licenseType,
        imageUrl,
        courses,
        scenarios,
        id: i,
      } = product
      setValues({
        name,
        state,
        description,
        kind,
        imageUrl,
        price,
        licenseDuration,
        licenseType,
        id: i,
      })
      if (product.imageUrl) {
        setImage(product.imageUrl)
        setPreviewImage(product.imageUrl)
      } else {
        setPreviewImage(scenarioBg)
      }
      setSelectedCoursesList(
        courses.map(({ course }) => ({
          render: course.name,
          value: course.name,
          id: course.id,
        }))
      )
      setSelectedScenariosList(
        scenarios.map(({ scenario }) => ({
          render: scenario.name,
          value: scenario.name,
          id: scenario.id,
        }))
      )
    }
  }, [product])

  if (!product)
    return (
      <Modal
        id={id}
        closeOnEscape={false}
        appendTo={appendTo}
        useDefaultButtons
      />
    )
  const checkState = () => {
    if (product?.state === 'READY') return 'READY'
    if (
      values.name &&
      values.price &&
      values.description &&
      (selectedCoursesList.length || selectedScenariosList.length) &&
      image
    ) {
      return 'PREPARED'
    }
    return 'INCOMPLETE'
  }

  const resetState = () => {
    setTimeout(() => {
      setValues(INITIAL_STATE)
      setKey(uuidv4())
    }, 300)
  }
  const submit = (e) => {
    const { name, price, description } = values
    if (!name) {
      document
        .querySelector('#edit-product-title')
        .classList.add('input--required')
    }
    if (!price) {
      document
        .querySelector('#edit-actual-price')
        .classList.add('input--required')
    }
    if (!description) {
      document
        .querySelector('#edit-product-description')
        .classList.add('input--required')
    }

    if (!name || !price || (!description && product.state === 'READY')) {
      e.preventDefault()
      e.stopPropagation()
      return null
    }
    const courseIds = newCoursesListRef.current.map((i) => Number(i.id))
    const scenarioIds = newScenariosListRef.current.map((i) => Number(i.id))
    const newState = checkState()
    if (image !== product.imageUrl) {
      const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
      upload.create((error, blob) => {
        if (error) throw new Error()
        editProduct({
          variables: {
            ...values,
            courseIds,
            scenarioIds,
            state: newState || values.state,
            blobId: blob?.signed_id || '',
          },
        })
      })
    } else {
      editProduct({
        variables: {
          ...values,
          courseIds,
          scenarioIds,
          state: newState || values.state,
        },
      })
    }
    return resetState()
  }

  const productFields = (
    <>
      <div className="cell small-12">
        <label htmlFor="edit-product-title" className="c-form__label--required">
          Product title
        </label>
        <input
          required
          type="text"
          name="product-title"
          id="edit-product-title"
          autoComplete="off"
          defaultValue={values.name}
          onChange={({ target }) => {
            setValues({ ...values, name: target.value })
          }}
        />
      </div>
      <div className="cell small-12">
        <label htmlFor="edit-product-description">Product description</label>
        <textarea
          rows="6"
          id="edit-product-description"
          defaultValue={values.description}
          required={product.state === 'READY'}
          onChange={({ target }) =>
            setValues({ ...values, description: target.value })
          }
        />
      </div>
      <div className="cell small-12">
        <label htmlFor="edit-product-courses">Add courses</label>
        <DraggableList
          list={selectedCoursesList}
          setListHandler={setSelectedCoursesList}
          loading={loading}
          newList={newCoursesListRef}
          getDropdownItemsHandler={() => getCourses()}
          dropdownItems={coursesList}
          buttonText="Select courses"
        />
      </div>
      <div className="cell small-12 mt-2">
        <label htmlFor="add-product-courses">Add scenarios</label>
        <DraggableList
          list={selectedScenariosList}
          setListHandler={setSelectedScenariosList}
          loading={scenariosLoading}
          newList={newScenariosListRef}
          getDropdownItemsHandler={() => getScenarios()}
          dropdownItems={scenariosList}
          buttonText="Select scenario"
        />
      </div>
    </>
  )
  const licenseFields = () => {
    const isDisabled = product.state === 'READY' || product.state === 'ARCHIVED'
    const isDisabledTitle =
      'It is not possible to change the pricing plan or license type for archived products and products in the Warp Store'
    return (
      <>
        <div className="cell small-12 mb-3">
          <label htmlFor="license-pricing">Set pricing plan</label>
          <fieldset className="small-12">
            <RadioButton
              id="radio-edit-purchase"
              disabled={isDisabled}
              label={
                <>
                  One-time purchase
                  <DataTooltip
                    position="right"
                    title={
                      isDisabled
                        ? isDisabledTitle
                        : 'Clients get access to this product until the end of the license duration, by paying a singular amount.'
                    }>
                    <FaQuestionCircle className="text-stable-dark ml-3" />
                  </DataTooltip>
                </>
              }
              checked={values.kind === 'ONE_TIME'}
              clickHandler={() => setValues({ ...values, kind: 'ONE_TIME' })}
              name="select-plan"
            />
            <RadioButton
              id="radio-edit-subscription"
              disabled={isDisabled}
              label={
                <>
                  Subscription
                  <DataTooltip
                    position="right"
                    title={
                      isDisabled
                        ? isDisabledTitle
                        : 'Clients get access to this product as long as they continue to pay.'
                    }>
                    <FaQuestionCircle className="text-stable-dark ml-3" />
                  </DataTooltip>
                </>
              }
              checked={values.kind === 'SUBSCRIPTION'}
              clickHandler={() =>
                setValues({ ...values, kind: 'SUBSCRIPTION' })
              }
              name="select-plan"
            />
          </fieldset>
        </div>
        <div className="cell mt-1 small-12">
          <label htmlFor="license-pricing">Set license type</label>
          <fieldset className="small-12 mb-3">
            <RadioButton
              id="radio-edit-trainee"
              disabled={isDisabled}
              label={
                <>
                  Trainee license
                  <DataTooltip
                    position="right"
                    title={
                      isDisabled
                        ? isDisabledTitle
                        : 'Each trainee needs a license, but can play on multiple devices.'
                    }>
                    <FaQuestionCircle className="text-stable-dark ml-3" />
                  </DataTooltip>
                </>
              }
              checked={values.licenseType === 'USER'}
              clickHandler={() => setValues({ ...values, licenseType: 'USER' })}
              name="select-license"
            />
            <RadioButton
              id="radio-edit-device"
              disabled={isDisabled}
              label={
                <>
                  Device license
                  <DataTooltip
                    position="right"
                    title={
                      isDisabled
                        ? isDisabledTitle
                        : 'Each device needs a license, but multiple trainees can play on one device.'
                    }>
                    <FaQuestionCircle className="text-stable-dark ml-3" />
                  </DataTooltip>
                </>
              }
              checked={values.licenseType === 'DEVICE'}
              clickHandler={() =>
                setValues({ ...values, licenseType: 'DEVICE' })
              }
              name="select-license"
            />
          </fieldset>
          <div className="cell mt-1 small-12 grid-y">
            <label
              htmlFor="edit-actual-price"
              className="cell actual-price c-form__label--required">
              License price
            </label>
            <div className="cell grid-x">
              <span className="cell mr-1 shrink mb-0 hollow button secondary">
                <FaEuroSign />
              </span>
              <input
                required
                className="cell auto"
                value={values.price}
                pattern="\d*"
                onChange={({ target }) => {
                  setValues({
                    ...values,
                    price: Number(target.value.replace(/[^\d]*/g, '')),
                  })
                }}
                placeholder="price in EUR"
                type="text"
                name="price"
                id="edit-actual-price"
              />
            </div>
          </div>
          <div className="cell mt-1 small-12">
            <label htmlFor="edit-license-duration">License duration</label>
            <select
              name="duration"
              defaultValue={product.licenseDuration}
              onChange={({ target }) =>
                setValues({ ...values, licenseDuration: Number(target.value) })
              }
              id="edit-license-duration">
              {LICENSE_DURATION_OPTIONS.map((v, i) => (
                <option value={v} key={i}>{`${v} ${
                  v === 1 ? 'month' : 'months'
                }`}</option>
              ))}
            </select>
          </div>
          <div className="cell small-12 grid-x">
            <label
              className="cell auto"
              htmlFor="state"
              style={{ alignSelf: 'center' }}>
              State
            </label>
            <div className="cell shrink">
              <ProductState state={checkState()} hasImage={!!image} />
            </div>
            <br />
            {product.state !== 'READY' && product.state !== 'ARCHIVED' && (
              <small className="text-stable-dark">
                Fill all fields and add scenarios or courses to change state to
                Prepared for sale
              </small>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <Modal
      overflowY="visible"
      id={id}
      width={902}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      headerText="Edit product"
      submitHandler={submit}
      cancelHandler={resetState}>
      <form key={key} className="mt-0">
        <div className="grid-x">
          <div className="cell auto pt-3 pr-3">
            <label>Cover image</label>
            <div
              className="cell o-modal--content o-modal__scenario--image with-ratio-4-3 border-none"
              style={{
                backgroundImage: `url("${previewImage}")`,
                borderRadius: '5px',
              }}>
              <label
                htmlFor="image-upload-edit-product"
                className={`button dark ${
                  image ? 'shadow dark-transparent' : ''
                }`}>
                <input
                  hidden
                  type="file"
                  id="image-upload-edit-product"
                  accept="image/png, image/jpeg"
                  onChange={async (event) => {
                    const file = event.target.files[0]
                    if (
                      file.type === 'image/png' ||
                      file.type === 'image/jpeg'
                    ) {
                      setPreviewImage(URL.createObjectURL(file))
                      setImage(file)
                    } else {
                      addErrorAlert({
                        title: 'Please upload a .PNG or .JPG image',
                        subtitle: 'other filetypes are not supported.',
                        timeout: false,
                        dismissable: true,
                      })
                    }
                  }}
                />
                {`${image ? 'Change' : 'Select'} image`}
              </label>
            </div>
            <div className="cell pt-3">{productFields}</div>
          </div>
          <div
            className="cell pt-3 pl-2"
            style={{ borderLeft: '1px solid #ddd', width: '300px' }}>
            {licenseFields()}
          </div>
        </div>
      </form>
    </Modal>
  )
}
export default EditProductModal
