import React from 'react'
import { FaSearch } from 'react-icons/fa'
import Input from '../Form/Input'

const TableSearchBar = ({
  searchQuery,
  showEmptyState,
  setSearchQuery,
  setShowEmptyState,
}) => {
  return (
    <span className="cell auto large-shrink">
      <Input
        styleComponent
        collapsable
        prefix={<FaSearch />}
        defaultValue={searchQuery}
        changeHandler={(value) => {
          if (showEmptyState) setShowEmptyState(false)
          setSearchQuery(value)
        }}
      />
    </span>
  )
}

export default TableSearchBar
