import { useContext, useEffect } from 'react'

import LanguageContext from '../../utils/context/LanguageContext'

const LauncherTemplate = ({ children }) => {
  const { translations: t } = useContext(LanguageContext)

  useEffect(() => {
    // used to ensure body loads correct styles
    document.querySelector('body').classList.add('c-launcher')
    return () => document.querySelector('body').classList.remove('c-launcher')
  }, [])

  if (!t) return null

  document.title = `Warp VR | ${t.signIn.title}`

  return children
}
export default LauncherTemplate
