import React, { useContext, useEffect, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import logo from '../../../../images/default/logo.png'
import enFlagIcon from '../../../../images/flag-en.svg'
import nlFlagIcon from '../../../../images/flag-nl.svg'
import LanguageContext from '../../utils/context/LanguageContext'
import Dropdown from '../UI/Menu/Dropdown'

const Header = () => {
  const {
    language,
    languages,
    translations: t,
    setLanguage,
  } = useContext(LanguageContext)
  const flags = {
    nl: nlFlagIcon,
    en: enFlagIcon,
  }
  const [flagIcon, setFlagIcon] = useState('')

  useEffect(() => {
    setFlagIcon(flags[language])
  }, [language])

  const dropdownContent = () => {
    return Object.keys(languages).map((option, i) => {
      return (
        <li
          className="o-dropdown__list-item"
          key={i}
          onClick={() => setLanguage(option)}>
          <img src={flags[option]} style={{ marginRight: '10px' }} />
          {t.languages[option].lang}
        </li>
      )
    })
  }

  return (
    <header
      id="header"
      className="c-launcher__header--container grid-container fluid">
      <div className="c-launcher__header--inner ">
        <a
          className="o-logo__link c-launcher__logo--container cursor-pointer"
          href="https://warpvr.com"
          target="_blank"
          rel="noreferrer">
          <img src={logo} className="c-launcher__logo--image" />
          <h1 className="c-launcher__logo--text">Warp VR</h1>
        </a>
        <span className="c-launcher__header--right-container">
          <Dropdown
            id="language-select"
            offset={{ top: '2px' }}
            button={
              <button
                data-toggle="language-select"
                className="mb-0 button hollow secondary">
                <img
                  src={flagIcon}
                  className="c-launcher__header--button__flagicon"
                />
                <span className="show-for-medium" style={{ color: 'black' }}>
                  {t.language}
                </span>
                <FaChevronDown className="ml-1" color="black" />
              </button>
            }>
            {dropdownContent()}
          </Dropdown>
          <a
            className="show-for-medium ml-2 c-launcher__link"
            target="_blank"
            rel="noreferrer"
            href="https://warpvr.com">
            {`${t.goTo} warpvr.com`}
          </a>
        </span>
      </div>
    </header>
  )
}
export default Header
