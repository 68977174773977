import React, { useContext } from 'react'
import PieGraph from '../UI/Graphs/PieGraph'
import Bullet from './Bullet'
import colors from '../Editor/helpers/colors'
import { getHexOpacityArray } from '../../utils/helpers/color'
import ActivityContext from '../../utils/context/ActivityContext'
import { gql, useQuery } from '@apollo/client'
import Loading from './Loading'
import EmptyParagraph from './EmptyParagraph'
import { capitalized } from '../../utils/helpers/javascript'
import { getDeviceTypeString } from './DeviceTypeHelper'

const getDeviceTypeAttemptsQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: COUNT
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: DEVICE_TYPE) {
        id
        name
        value
      }
    }
  }
`

const DeviceType = ({ attemptsMetricsData }) => {
  const { apiVars } = useContext(ActivityContext)
  const { data: deviceTypeMetrics } = useQuery(getDeviceTypeAttemptsQuery, {
    variables: apiVars,
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })
  const deviceType =
    deviceTypeMetrics?.attemptsMetrics?.grouped?.map((c) => ({
      name: getDeviceTypeString(c.id?.toUpperCase()),
      value: Number(c.value),
    })) ?? []

  const hexOpacityArray = getHexOpacityArray(deviceType?.length)

  return (
    <>
      <h2 className="text-bold mb-2">Device type</h2>
      <div className="border-light border-radius">
        <div className="pt-3 pb-3 border-light-bottom">
          <PieGraph data={deviceType} />
        </div>
        {deviceType?.map((entity, index) => (
          <div
            key={entity.name}
            className={`text-normal pt-2 pb-2 pl-3 pr-3 flex-container align-middle align-justify ${
              index < deviceType.length - 1 ? 'border-light-bottom' : ''
            }`}>
            <div>
              <Bullet color={`${colors.assertive}${hexOpacityArray[index]}`} />
              <span className="ml-1">{entity.name}</span>
            </div>
            <span className="text-bold">{entity.value}</span>
          </div>
        ))}
        {deviceType?.length === 0 && <EmptyParagraph />}
        {!deviceType && <Loading />}
      </div>
    </>
  )
}

export default DeviceType
