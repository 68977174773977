import React, { useState } from 'react'
import { FaUsers } from 'react-icons/fa'
import ActivitiesEmptyState from '../../components/Settings/Activities/ActivitiesEmptyState'
import ExportModal from '../../components/Settings/Activities/ExportModals'
import Table from '../../components/Table'
import TraineeUserRole from '../../components/Trainees/Table/TraineeUserRole'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import FilterEmptyState from '../../components/UI/Menu/FilterEmptyState'
import TableFilters from '../../components/UI/Table/TableFilters'
import TableSearchBar from '../../components/UI/Table/TableSearchBar'
import TableHeader from '../../components/UI/TableHeader'
import { dateFormat, sortOnKey } from '../../utils/format'
import Delay from '../../utils/helpers/Delay'
import { capitalized } from '../../utils/helpers/javascript'
import { actionFormatter } from '../../utils/helpers/activity'
import HistoryFeatureFlagError from '../FlowV2/tabs/Settings/History/HistoryFeatureFlagError'
import { getCurrentPlanQuery } from '../../apollo/query/plans'
import { useQuery } from '@apollo/client'

const MAX_RECORDS_COUNT = 1000
const COLUMNS_WIDTHS = ['0', '15%', '15%', '15%', '20%', '35%']

const ActivitiesTable = ({ data, error, loading, title, exportTitle, scenarioId }) => {
  const [showEmptyState, setShowEmptyState] = useState(false)

  // Filter users and activities
  const [filterOption, setFilterOption] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'createdAt',
    },
  })

  // Data & render states
  const numberOfItems = 100
  const [limit, setLimit] = useState(2 * numberOfItems)

  const { data: currentPlanData } = useQuery(getCurrentPlanQuery)
  const enterpriseEnabled =
    currentPlanData?.currentPlan.features.includes('ENTERPRISE')

  if (!currentPlanData) return false

  if (!enterpriseEnabled)
    return (
      <div className="grid-container fluid">
        <HistoryFeatureFlagError />
      </div>
    )

  if (error) throw new Error(error.message)

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (!data.activities.length) return <ActivitiesEmptyState />

  const tableHeaders = ['createdAt', 'userFullName', 'role', 'action', 'info']

  const getUserFullName = (user) => {
    if (!user) return 'Removed'
    if (user.administrator) return 'Warp Administrator'
    return `${user.firstName} ${user.lastName}`
  }

  const filters = [
    {
      text: 'User',
      type: 'user',
      searchable: true,
      options: data.activities.reduce((a, { user }) => {
        return {
          ...a,
          [getUserFullName(user)]: getUserFullName(user),
        }
      }, {}),
    },
    {
      text: 'Action',
      type: 'action',
      searchable: true,
      options: data.activities.reduce(
        (a, { action }) => ({
          ...a,
          [action]: actionFormatter(action),
        }),
        {}
      ),
    },
  ]

  const applyFilterToActions = (activities) => {
    return activities.reduce((f, activity) => {
      const { user, action } = filterOption

      const isInUser = !user || user.includes(getUserFullName(activity.user))
      const isInAction = !action || action.includes(activity.action)
      const isInInfo =
        !searchQuery ||
        activity.metadata.some((m) =>
          m.value.toLowerCase().includes(searchQuery.toLowerCase())
        )

      if (isInUser && isInAction && isInInfo) f.push(activity)
      return f
    }, [])
  }

  const clearFilterHandler = () => {
    setSearchQuery('')
    setFilterOption({ action: '', user: '' })
    setShowEmptyState(false)
  }

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn

    const filtered = applyFilterToActions(data.activities)

    let activities = sortOnKey(
      filtered || [...data.activities],
      value,
      isAscending
    ).slice(0, limit)
    if (!activities.length && !showEmptyState) setShowEmptyState(true)
    if (activities.length && showEmptyState) setShowEmptyState(false)

    return activities.map((activity) => {
      const formatted = {
        createdAt: dateFormat(activity.createdAt),
        userFullName: getUserFullName(activity.user),
        role: {
          value: activity.role,
          render: <TraineeUserRole role={activity.role} />,
        },
        action: {
          value: activity.action,
          render: actionFormatter(activity.action),
        },
        info: {
          render: activity.metadata.map((metadata) => (
            <span
              key={metadata.key}
              className="mr-1"
              style={{ maxWidth: '100%' }}>
              <b>{capitalized(metadata.key)}</b>: {metadata.value}
            </span>
          )),
        },
      }

      return formatted
    })
  }

  const leftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setSearchQuery={setSearchQuery}
        setShowEmptyState={setShowEmptyState}
      />
      <TableFilters
        filters={filters}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        setFilterOption={setFilterOption}
        filterOption={filterOption}
      />
    </>
  )

  const rightBar = () => {
    return (
      <button
        className="hollow button secondary text-bold"
        data-open="export-modal">
        Export
      </button>
    )
  }

  const showTablePrefix = () => {
    return (
      <div className="o-table__row o-table__placeholder--cell text-stable-dark text-center bg-stable-lightest">
        There are more than 1.000 lines.&nbsp;
        <span
          type="button"
          data-open="export-modal"
          className="text-stable-dark text-underline cursor-pointer">
          Export to show the full activity log.
        </span>
      </div>
    )
  }

  const pageContent = () => {
    if (showEmptyState) {
      return (
        <FilterEmptyState
          type="activities"
          icon={<FaUsers />}
          clickHandler={clearFilterHandler}
        />
      )
    }
    return (
      <Table
        data={sortedData()}
        headers={tableHeaders}
        sortOn={preferences.sortOn}
        sortHandler={(value) => {
          if (['userFullName', 'createdAt'].includes(value))
            sortHandler(value, preferences, setPreferences)
        }}
        type="activities"
        placeholderRows={35}
        tablePrefix={
          data.activities.length >= MAX_RECORDS_COUNT && showTablePrefix()
        }
        loadMore={applyFilterToActions(data.activities).length > limit}
        loadMoreHandler={() => setLimit(limit + numberOfItems)}
        columnWidths={COLUMNS_WIDTHS}
      />
    )
  }

  return (
    <div id="activities">
      <div className="grid-container fluid">
        <TableHeader title={title} leftBar={leftBar()} rightBar={rightBar()} />
      </div>
      <div className="o-table--page-container grid-container fluid">
        {pageContent()}
      </div>

      <ExportModal
        id="export-modal"
        appendTo="#activities"
        onConfirm={() => {}}
        scenarioId={scenarioId}
        title={exportTitle}
      />
    </div>
  )
}

export default ActivitiesTable
