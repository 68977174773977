import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { FaChevronDown, FaUsers, FaEllipsisH } from 'react-icons/fa'
import { getGroupsQuery } from '../apollo/query/groups'
import Table from '../components/Table'
import Checkbox from '../components/UI/Form/Checkbox'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import TableColumnsDropdown from '../components/UI/TableColumnsDropdown'
import TableHeader from '../components/UI/TableHeader'
import withApollo from '../hooks/withApollo'
import Delay from '../utils/helpers/Delay'
import { default as S } from '../utils/lang/en.json'
import { dateFormat, sortOnKey } from '../utils/format'
import Filter from '../components/UI/Menu/Filter'
import GroupOptionDropdown from '../components/Groups/GroupOptionDropdown'
import Toast from '../components/UI/Notifications/Toast'
import AddGroupModal from '../components/Groups/Modal/AddGroupModal'
import LinkSettingsModal from '../components/Groups/Modal/LinkSettingsModal'
import EditGroupModal from '../components/Groups/Modal/EditGroupModal'
import DeleteGroupModal from '../components/Groups/Modal/DeleteGroupModal'
import GroupCourseScenarioTooltip from '../components/Groups/Tooltips/GroupCourseScenarioTooltip'
import GroupPublicLink from '../components/Groups/GroupPublicLink'
import { truncate } from '../utils/helpers/javascript'
import {
  getPurchasedCoursesQuery,
  getCoursesQuery,
} from '../apollo/query/courses'
import DataTooltip from '../components/UI/DataTooltip'
import usePreferences from '../hooks/usePreferences'
import useCurrentUser from '../hooks/useCurrentUser'
import { filterColumnsHandler, sortHandler } from '../components/tableFunctions'
import TableSearchBar from '../components/UI/Table/TableSearchBar'
import TableAddButton from '../components/UI/Table/TableAddButton'
import Dropdown from '../components/UI/Menu/Dropdown'
import BulkEditGroupModal from '../components/Groups/Modal/BulkEditGroupModal'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import GroupsEmptyState from '../components/Groups/GroupsEmptyState'
import { getPurchasedScenariosQuery } from '../apollo/query/scenarios'

const Groups = () => {
  const [preferences, setPreferences] = usePreferences('groups')
  const [currentUser] = useCurrentUser()
  const [showEmptyFilters, setShowEmptyFilters] = useState(false)
  const [dismissToast, setDismissToast] = useState(false)
  const [currentGroup, setCurrentGroup] = useState(null)
  const [purchasedCourses, setPurchasedCourses] = useState([])
  const [purchasedScenarios, setPurchasedScenarios] = useState([])
  const [bulkEditGroupModeShouldAdd, setBulkEditGroupModeShouldAdd] =
    useState(true)
  // Sorting & filtering
  const [selectedItems, setSelectedItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filterOption, setFilterOption] = useState({
    course: [], // array of course names
    scenario: '',
  })

  const { loading: loadingPurchasedCourses } = useQuery(
    getPurchasedCoursesQuery,
    {
      onCompleted: ({ purchasedCourses: p }) => setPurchasedCourses(p),
    }
  )

  const { loading: loadingPurchasedScenarios } = useQuery(
    getPurchasedScenariosQuery,
    {
      onCompleted: ({ purchasedScenarios: p }) => setPurchasedScenarios(p),
    }
  )

  const { loading: loadingCourses, data: coursesData } =
    useQuery(getCoursesQuery)
  const { loading, data, refetch } = useQuery(getGroupsQuery, {
    onCompleted: (d) => {
      return d.groups.filter((group) => group.courses === [])
    },
  })

  const tableHeaders = [
    'name',
    'courses',
    'scenarios',
    'publicLink',
    'trainees',
    'startDate',
    'endDate',
    'createdAt',
  ]

  const copyLink = (id) => {
    navigator.clipboard.writeText(
      data.groups.find(({ id: i }) => i === id).publicLinkUrl
    )
  }

  if (
    loading ||
    loadingCourses ||
    loadingPurchasedCourses ||
    loadingPurchasedScenarios ||
    !data ||
    !preferences ||
    !currentUser ||
    !coursesData
  ) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const sortedData = () => {
    const filterCourses = [...data.groups].reduce((a, group) => {
      const names = group.courses.map(({ name }) => name)
      if (!filterOption.course.length) {
        a.push(group)
      } else if (filterOption.course.some((i) => names.includes(i))) {
        a.push(group)
      }
      return a
    }, [])
    const rows = filterCourses.reduce((a, group) => {
      const names = group.publishedScenarios.map(({ name }) => name)

      if (!filterOption.scenario) {
        a.push(group)
      } else if (names.includes(filterOption.scenario)) {
        a.push(group)
      }
      return a
    }, [])
    const filtered = rows.filter((group) => {
      return group.name.toLowerCase().includes(searchQuery.toLowerCase())
    })
    const groupsData = filtered || [...data.groups]

    if (!groupsData.length && !showEmptyFilters) setShowEmptyFilters(true)
    if (groupsData.length && showEmptyFilters) setShowEmptyFilters(false)

    const parsedData = groupsData.map((g) => {
      return {
        id: g.id,
        name: {
          value: g.name,
          render: (
            <>
              <span className="small hide-for-medium">
                {g.name.length > 40 ? (
                  <DataTooltip title={g.name}>
                    <span>{truncate(g.name, 40, true)}</span>
                  </DataTooltip>
                ) : (
                  g.name
                )}
              </span>
              <span className="show-for-medium hide-for-large">
                {g.name.length > 60 ? (
                  <DataTooltip title={g.name}>
                    <span>{truncate(g.name, 60, true)}</span>
                  </DataTooltip>
                ) : (
                  g.name
                )}
              </span>
              <span className="show-for-large hide-for-xxlarge">
                {g.name.length > 100 ? (
                  <DataTooltip title={g.name}>
                    <span>{truncate(g.name, 100, true)}</span>
                  </DataTooltip>
                ) : (
                  g.name
                )}
              </span>
              <span className="show-for-xxlarge">
                {g.name.length > 150 ? (
                  <DataTooltip title={g.name}>
                    <span>{truncate(g.name, 150, true)}</span>
                  </DataTooltip>
                ) : (
                  g.name
                )}
              </span>
            </>
          ),
        },
        courses: {
          value: g.courses.length,
          render: (
            <GroupCourseScenarioTooltip
              count={g.courses.length}
              names={g.courses.map(({ name }) => name)}
              type="course"
            />
          ),
        },
        scenarios: {
          value: g.publishedScenarios.length,
          render: (
            <GroupCourseScenarioTooltip
              count={g.publishedScenarios.length}
              names={g.publishedScenarios.map(({ name }) => name)}
              type="scenario"
            />
          ),
        },
        publicLink: {
          value: g.publicLinkEnabled,
          render: (
            <GroupPublicLink on={g.publicLinkEnabled} link={g.publicLinkUrl} />
          ),
        },
        trainees: g.userIds.length,
        startDate: {
          value: g.startDate,
          render: dateFormat(g.startDate, 'DD MMM YYYY', false) || '-',
        },
        endDate: {
          value: g.endDate,
          render: dateFormat(g.endDate, 'DD MMM YYYY', false) || '-',
        },
        createdAt: {
          value: g.createdAt,
          render: dateFormat(g.createdAt),
        },
      }
    })

    return sortOnKey(
      parsedData,
      preferences.sortOn.value,
      preferences.sortOn.isAscending
    )
  }

  const loadModals = () => {
    const appendTo = '#groups'
    return (
      <>
        <AddGroupModal
          id="add-group-modal"
          appendTo={appendTo}
          purchasedCourses={purchasedCourses}
          purchasedScenarios={purchasedScenarios}
          callback={refetch}
        />
        <LinkSettingsModal
          id="link-settings-modal"
          appendTo={appendTo}
          group={data.groups.find(({ id }) => id === currentGroup)}
          callback={() => setCurrentGroup(null)}
        />
        <BulkEditGroupModal
          id="bulk-edit-group-modal"
          addContent={bulkEditGroupModeShouldAdd}
          appendTo={appendTo}
          purchasedCourses={purchasedCourses}
          purchasedScenarios={purchasedScenarios}
          groupIds={selectedItems}
          groups={data.groups.filter((group) =>
            selectedItems.includes(group.id)
          )}
          callback={() => {
            setSelectedItems([])
            setCurrentGroup(null)
          }}
        />
        <EditGroupModal
          id="edit-group-modal"
          appendTo={appendTo}
          purchasedCourses={purchasedCourses}
          purchasedScenarios={purchasedScenarios}
          group={data.groups.find(({ id }) => id === currentGroup)}
          callback={() => setCurrentGroup(null)}
        />
        <DeleteGroupModal
          id="delete-group-modal"
          ids={currentGroup ? [currentGroup] : selectedItems}
          appendTo={appendTo}
          callback={() => {
            refetch()
            setCurrentGroup(null)
          }}
          clearSelectedItems={() => {
            refetch()
            setSelectedItems([])
            setCurrentGroup(null)
          }}
        />
      </>
    )
  }

  const renderOptions = (id) => {
    const group = data.groups.find(({ id: i }) => i === id)
    const isPublicLinkModalDisabled =
      !group.courses.length && !group.publishedScenarios.length
    return (
      <GroupOptionDropdown
        id={id}
        setCurrentGroup={setCurrentGroup}
        isPublicLinkModalDisabled={isPublicLinkModalDisabled}
        publicLinkEnabled={group.publicLinkEnabled}
        copyLinkHandler={() => copyLink(id)}
      />
    )
  }

  const tableSearchBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyFilters}
        setSearchQuery={setSearchQuery}
        setShowEmptyState={setShowEmptyFilters}
      />
      <span className="cell shrink large-order-2 mr-1">
        <Filter
          searchable
          text="Courses"
          icon={<FaChevronDown />}
          selected={filterOption.course.length ? filterOption.course[0] : 'All'}
          filterHandler={(filter) => {
            if (showEmptyFilters) setShowEmptyFilters(false)
            return setFilterOption(
              filter
                ? { ...filterOption, course: [filter] }
                : { ...filterOption, course: [] }
            )
          }}
          filterOptions={[
            'All',
            ...[
              ...coursesData.courses,
              ...purchasedCourses.filter(
                (pc) => !coursesData.courses.find((cd) => cd.id === pc.id)
              ),
            ]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ id, name }) => ({
                value: name,
                render: purchasedCourses.some((p) => p.id === id) ? (
                  <span>
                    {name}
                    <label className="float-right o-label--custom mb-0 o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                      PURCHASED
                    </label>
                  </span>
                ) : (
                  name
                ),
              })),
          ]}
        />
      </span>
      <span className="cell shrink large-order-2 mr-1">
        <Filter
          searchable
          text="Scenarios"
          icon={<FaChevronDown />}
          selected={filterOption.scenario || 'All'}
          filterHandler={(filter) => {
            if (showEmptyFilters) setShowEmptyFilters(false)
            setFilterOption(
              filter
                ? { ...filterOption, scenario: filter }
                : { ...filterOption, scenario: '' }
            )
          }}
          filterOptions={[
            'All',
            ...data.groups
              .reduce((a, { publishedScenarios }) => {
                for (const scenario of publishedScenarios) {
                  if (a.every((p) => p.value !== scenario.name)) {
                    a.push({
                      value: scenario.name,
                      render: purchasedScenarios.some(
                        (p) => p.id === scenario.id
                      ) ? (
                        <span>
                          {scenario.name}
                          <label className="float-right o-label--custom mb-0 o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                            PURCHASED
                          </label>
                        </span>
                      ) : (
                        scenario.name
                      ),
                    })
                  }
                }
                return a
              }, [])
              .sort((a, b) => a.value.localeCompare(b.value)),
          ]}
        />
      </span>
    </>
  )

  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }

    return setSelectedItems(() => newSelection)
  }

  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.groups.headers[item]}
          />
        </li>
      )
    })
  }

  const toastContent = (
    <Dropdown
      id="dropup-toast"
      position="top"
      alignment="right"
      offset={{ left: '17px' }}
      button={
        <button
          data-toggle="dropup-toast"
          className="cursor-pointer h-100 outline-none">
          <FaEllipsisH />
        </button>
      }>
      <>
        <li
          className="o-dropdown__list-item"
          onClick={() => setBulkEditGroupModeShouldAdd(true)}
          data-open="bulk-edit-group-modal">
          Add courses and scenarios to groups
        </li>
        <li
          className="o-dropdown__list-item"
          onClick={() => setBulkEditGroupModeShouldAdd(false)}
          data-open="bulk-edit-group-modal">
          Remove courses and scenarios from groups
        </li>
        <hr className="mb-1 mt-1" />
        <li className="o-dropdown__list-item" data-open="delete-group-modal">
          Delete groups
        </li>
      </>
    </Dropdown>
  )

  const getPageContent = () => {
    if (!data.groups.length) return <GroupsEmptyState />
    if (showEmptyFilters)
      return (
        <FilterEmptyState
          type="groups"
          icon={<FaUsers />}
          clickHandler={() => {
            setSearchQuery('')
            setSelectedItems([])
            setShowEmptyFilters(false)
            setFilterOption({ course: '', scenario: '' })
          }}
        />
      )

    return (
      <Table
        data={sortedData()}
        headers={tableHeaders.filter(
          (i) => !preferences.filteredColumns.includes(i)
        )}
        sortOn={preferences.sortOn}
        sortHandler={(value) => sortHandler(value, preferences, setPreferences)}
        selectedItems={selectedItems}
        selectable
        checkboxHandler={checkboxHandler}
        selectAllHandler={(ids) => setSelectedItems(ids)}
        type="groups"
        rowSuffix={(i) => renderOptions(i)}
      />
    )
  }
  return (
    <div id="groups">
      {loadModals()}
      <div className="grid-container fluid">
        <TableHeader
          title="Group"
          length={[...sortedData()].length}
          leftBar={tableSearchBar()}
          rightBar={
            <>
              <TableColumnsDropdown content={content} />
              <TableAddButton dataOpen="add-group-modal" title="New group" />
            </>
          }
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        {getPageContent()}
      </div>
      <Toast
        dismissable
        isClosing={dismissToast}
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'groups' : 'group'}
        closeHandler={() => {
          setDismissToast(true)
          setTimeout(() => setDismissToast(false), 300)
          setTimeout(() => setSelectedItems([]), 10)
        }}>
        {toastContent}
      </Toast>
    </div>
  )
}
export default withApollo(Groups)
