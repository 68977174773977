import React, { useState, useRef, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import RangeInput from '../../../components/UI/Form/RangeInput'
import placeholderAvatar from '../../../../../images/default/avatar.png'
import {
  getBlobfromDataURL,
  getFileFromBlob,
} from '../../../utils/helpers/files'
import {
  currentUserQuery,
  updateProfileMutation,
} from '../../../apollo/query/user'
import { handleApolloError } from '../../../utils/errors'

const ActivateProfileForm = ({ isActive, handleNext }) => {
  const SIZE = 150
  const BORDERWIDTH = 5
  const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'
  const [userIsOwner, setUserIsOwner] = useState(false)

  const editor = useRef()
  const fileUploader = useRef()
  const firstNameInputRef = useRef()
  const lastNameInputRef = useRef()

  const [image, setImage] = useState()
  const [scale, setScale] = useState(0)

  const [getCurrentUser, { loading, data }] = useLazyQuery(currentUserQuery, {
    onCompleted: (d) => {
      if (d.currentRole === 'OWNER') {
        setUserIsOwner(true)
      }
    },
    onError: handleApolloError,
  })

  const [updateProfile] = useMutation(updateProfileMutation)

  const handleDrop = (f) => setImage(f[0])

  const handleNavigation = () => {
    if (userIsOwner) return handleNext()
    window.location.href = '/home'
    return null
  }

  const handleSave = (e) => {
    e.preventDefault()
    if (image) {
      const scaledImage = editor.current.getImageScaledToCanvas().toDataURL()
      const blob = getBlobfromDataURL(scaledImage, image.type)
      const fileToUpload = getFileFromBlob(blob, image.name, 'image/jpeg')

      const upload = new DirectUpload(fileToUpload, DIRECT_UPLOAD_URL)
      upload.create((uploadError, b) => {
        if (uploadError) throw new Error(uploadError.message)
        updateProfile({
          variables: {
            firstName: firstNameInputRef.current.value,
            lastName: lastNameInputRef.current.value,
            blobId: b.signed_id,
          },
          onCompleted: handleNavigation,
        })
      })
    } else {
      updateProfile({
        variables: {
          firstName: firstNameInputRef.current.value,
          lastName: lastNameInputRef.current.value,
        },
        onCompleted: handleNavigation,
      })
    }
  }

  useEffect(() => {
    if (isActive) getCurrentUser()
  }, [isActive])

  if (loading) return `loading`

  return (
    <form autoComplete="off" data-testid="activate-profile-form">
      <h4 className="mb-1 text-bold">Profile picture</h4>
      <div className="grid-x grid-margin-x align-middle">
        <Dropzone onDrop={handleDrop} noClick noKeyboard>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              id="profile-image"
              style={{ width: `${SIZE + BORDERWIDTH * 2}px` }}
              className="cell shrink">
              <AvatarEditor
                ref={editor}
                borderRadius={SIZE}
                width={SIZE}
                height={SIZE}
                border={BORDERWIDTH}
                color={[255, 255, 255, 1]}
                scale={1 + Number(scale)}
                image={image || placeholderAvatar}
              />
              <input
                {...getInputProps()}
                type="file"
                id="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                accept="image/jpeg,image/png"
                multiple={false}
                onChange={(event) => {
                  const file = event.target.files[0]
                  setImage(file)
                }}
              />
            </div>
          )}
        </Dropzone>
        <div className="cell auto">
          <button
            data-testid="upload-picture-button"
            className="button text-bold secondary hollow mb-0"
            onClick={(e) => {
              e.preventDefault()
              fileUploader.current.click()
            }}>
            {`${image ? 'Change' : 'Upload'}`} picture
          </button>
        </div>
      </div>
      <div style={{ width: `${SIZE + BORDERWIDTH * 2}px` }}>
        <RangeInput
          showLabels={false}
          minValue={0}
          maxValue={3}
          value={scale}
          setValue={setScale}
          noBackground
        />
      </div>
      <div className="grid-x grid-margin-x mt-2">
        <div className="cell medium-6 mr-1-5">
          <label htmlFor="first-name">First name</label>
          <input
            ref={firstNameInputRef}
            type="text"
            name="first-name"
            data-testid="first-name-input"
            defaultValue={data?.currentUser?.firstName}
            placeholder="Ada"
          />
        </div>
        <div className="cell medium-6 ml-1-5">
          <label htmlFor="last-name">Last name</label>
          <input
            ref={lastNameInputRef}
            type="text"
            name="last-name"
            data-testid="last-name-input"
            defaultValue={data?.currentUser?.lastName}
            placeholder="Lovelace"
          />
        </div>
      </div>
      <button
        data-testid="submit-button"
        className="o-button text-bold w-100 mt-2"
        onClick={handleSave}>
        Open Warp Studio
      </button>
    </form>
  )
}
export default ActivateProfileForm
