const BASEURL =
  'https://assets.warpvr.com/aframe-language-fonts'
const fonts = {
  latin: `${BASEURL}/latin/basic-supplement/Montserrat-Medium.json`,
  cyrillic: `${BASEURL}/cyrillic/Roboto-Medium.json`,
  thai: `${BASEURL}/thai/IBMPlexSansThaiLooped-Medium.json`,
  japanese: `${BASEURL}/japanese/MPLUS1p-Medium.json`,
  fallback: `${BASEURL}/fallback/MPLUS1p-Medium-32.json`,
  settings: {
    default: `
    anchor: center;
    align: center;
    line-height: 50;
    width: 2.5;
    negate: false;
    wrapCount: 20;
    shader: msdf;`,
  },
}

export default fonts
