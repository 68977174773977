import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { getPurchasedProductsQuery } from '../../apollo/query/products'
import withApollo from '../../hooks/withApollo'
import TableHeader from '../../components/UI/TableHeader'
import TableSearchBar from '../../components/UI/Table/TableSearchBar'
import PurchasedProductInfoModal from '../../components/Store/PurchasedProducts/PurchasedProductInfoModal'
import PurchasedProductCards from '../../components/Store/PurchasedProducts/PurchasedProductCards'
import EmptyPage from '../../components/EmptyPage'
import { FaImage } from 'react-icons/fa'

const Purchased = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmptyPage, setShowEmptyPage] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [currentProduct, setCurrentProduct] = useState(null)
  const [showLicenseTab, setShowLicenseTab] = useState(false)

  const { data, loading } = useQuery(getPurchasedProductsQuery, {
    onCompleted: ({ purchasedProducts }) => {
      if (!purchasedProducts.length) setShowEmptyPage(true)
      else setShowEmptyPage(false)
    },
  })

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  if (showEmptyPage) {
    return (
      <EmptyPage
        type="purchased products"
        icon={<FaImage />}
      />
    )
  }

  return (
    <div id="purchased">
      <PurchasedProductInfoModal
        id="product-info-modal"
        appendTo="#purchased"
        showLicenseTab={showLicenseTab}
        product={currentProduct}
      />
      <div className="grid-container fluid">
        <TableHeader
          length={data.purchasedProducts.length}
          title="Product"
          leftBar={
            <TableSearchBar
              searchQuery={searchQuery}
              showEmptyState={showEmptyState}
              setShowEmptyState={setShowEmptyState}
              setSearchQuery={setSearchQuery}
            />
          }
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        <div
          id="grid-view"
          className="grid-container full grid--overflow h-100">
          <div className="grid-x grid-margin-x">
            <PurchasedProductCards
              products={data.purchasedProducts.filter((product) => {
                const searchString =
                  `${product.name} ${product.client.name}`.toLowerCase()
                return searchString.includes(searchQuery.toLowerCase())
              })}
              setShowLicenseTab={setShowLicenseTab}
              setCurrentProduct={setCurrentProduct}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withApollo(Purchased)
