import React, { useState } from 'react'

import ImportTSVFile from '../UI/Form/ImportTSVFile'

const ImportGroupsForm = ({ modalId, cancelHandler, callback }) => {
  const [shouldStartUploading, setShouldStartUploading] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)
  return (
    <form className="mt-3">
      <div className="cell small-12">
        <p>
          Provide a Tab-Separated-Values (TSV or TXT) file with column names
          Name, StartDate, EndDate and a row for each group.{' '}
          <a
            className="text-stable-dark text-underline"
            href="https://help.warpvr.com/users-and-groups/import-groups"
            target="_blank"
            rel="noreferrer">
            Need help?
          </a>
        </p>
        <ImportTSVFile
          modalId={modalId}
          kind="GROUPS"
          shouldStartUploading={shouldStartUploading}
          hasFileSelected={setFileSelected}
          callback={callback}
        />
        <div className="clearfix o-modal--footer pb-0 pr-0">
          <button
            className="mb-0 hollow button secondary wide"
            onClick={(e) => {
              e.preventDefault()
              cancelHandler()
            }}
            data-close={modalId}>
            Cancel
          </button>
          <button
            type="submit"
            data-close={modalId}
            disabled={!fileSelected}
            className="mb-0 button primary wide"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShouldStartUploading(true)
            }}>
            Confirm
          </button>
        </div>
      </div>
    </form>
  )
}
export default ImportGroupsForm
