import { useRef, useLayoutEffect } from 'react'

function useFoundation() {
  const foundationRef = useRef(null)

  useLayoutEffect(() => {
    jQuery(foundationRef.current).foundation()
  }, [])

  return foundationRef
}

export default useFoundation
