import React, { useContext, useState } from 'react'
import { FaQuestionCircle, FaMapPin } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import DataTooltip from '../../UI/DataTooltip'
import { convertMilliSeconds } from '../../../utils/format'
import RangeInput from '../../UI/Form/RangeInput'
import { SceneEditorContext } from '../SceneEditorContext'
import { updateSceneMutation } from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import { MediumContext } from '../../Media/Modal/MediumContext'

const VIDEO_LOOP_THRESHOLD = 2 //seconds

const Sliders = () => {
  const [showThumbTooltip, setShowThumbTooltip] = useState()
  const [sceneState, setSceneState] = useContext(SceneEditorContext)
  const {
    medium: [medium],
    time: [time],
  } = useContext(MediumContext)

  const [updateScene] = useMutation(updateSceneMutation, {
    onError: handleApolloError,
  })

  const updateSceneHandler = (value) => {
    updateScene({
      variables: value,
    })
  }

  if (!medium) return null
  if (sceneState.kind === 'TRANSITION')
    return (
      <div className="c-video__editor--slider-container">
        <div>
          <div className="grid-x c-video__editor--slider-row border-none"></div>
          <div className="grid-x c-video__editor--slider-row border-none"></div>
        </div>
      </div>
    )

  const maxVideoLoop = medium.trimEnabled
    ? Number(medium.trimEnd - medium.trimStart)
    : Number(sceneState.video.duration / 1000)

  const videoLoopEnabled =
    sceneState.videoLoopEnabled && sceneState.video.duration > 2000

  return (
    <div className="c-video__editor--slider-container">
      <div>
        <div className="grid-x c-video__editor--slider-row">
          <div className="cell small-3 large-2 text-normal">
            Elements fade in
            <DataTooltip
              position="right"
              title="Select a point from where all the interactive elements should fade in.">
              {' '}
              <FaQuestionCircle className="text-stable" />
            </DataTooltip>
          </div>
          <div className="cell small-1 text-normal">
            {convertMilliSeconds(sceneState.questionFadeIn * 1000, true)}
          </div>
          <div
            className="cell small-1 text-stable cursor-pointer"
            onClick={() => {
              setSceneState(() => ({
                ...sceneState,
                questionFadeIn: Number(time / 1000),
              }))
              updateSceneHandler({
                ...sceneState,
                questionFadeIn: Number(time / 1000),
              })
            }}>
            <DataTooltip delay={500} position="right" title="Set to video time">
              <button className="button hollow secondary mb-0">
                <FaMapPin className="cursor-pointer" />
              </button>
            </DataTooltip>
          </div>
          <div className="cell small-6 small-offset-1  large-7 ">
            <RangeInput
              showLabels={false}
              showLabelsOnHoverOnly
              minValue={0}
              reversed
              formatLabel={(v) => convertMilliSeconds(Number(v) * 1000)}
              updateOnDrag={true}
              maxValue={
                medium.trimEnabled
                  ? Number(medium.trimEnd - medium.trimStart)
                  : Number(sceneState.video.duration / 1000)
              }
              value={sceneState.questionFadeIn || 0}
              setValue={(v, save) => {
                setSceneState(() => ({
                  ...sceneState,
                  questionFadeIn: Number(v),
                }))
                if (save) {
                  updateSceneHandler({
                    ...sceneState,
                    questionFadeIn: Number(v),
                  })
                }
              }}
            />
          </div>
        </div>
        <div className="grid-x c-video__editor--slider-row">
          <div className="cell small-3 large-2 text-normal">
            {sceneState.video.duration > 2000 ? (
              <div className="flex-container align-middle">
                <div className="mr-1">
                  <div className="o-switch">
                    <input
                      type="checkbox"
                      name="videoLoop"
                      id="videoLoop"
                      checked={videoLoopEnabled}
                      onChange={({ target }) => {
                        updateSceneHandler({
                          ...sceneState,
                          videoLoopEnabled: target.checked,
                        })
                      }}
                      className="o-switch__input"
                    />
                    <label
                      htmlFor="videoLoop"
                      className="o-switch__paddle"></label>
                  </div>
                </div>
                <div>
                  <span>Video loop</span>
                  <DataTooltip
                    position="right"
                    title="Select a point from where your video should keep on looping. The video loop should be at least 2 seconds long.">
                    {' '}
                    <FaQuestionCircle className="text-stable" />
                  </DataTooltip>
                </div>
              </div>
            ) : (
              <DataTooltip
                position="top"
                title="Video loop cannot be set for videos shorter than 2 seconds.">
                <div className="flex-container align-middle">
                  <div className="mr-1">
                    <div className="o-switch">
                      <input
                        type="checkbox"
                        disabled
                        className="o-switch__input"
                      />
                      <label
                        htmlFor="videoLoop"
                        className="o-switch__paddle"></label>
                    </div>
                  </div>
                  <div>
                    <span>Video loop</span>
                  </div>
                </div>
              </DataTooltip>
            )}
          </div>
          {videoLoopEnabled && (
            <>
              <div className="cell small-1 text-normal">
                {convertMilliSeconds(sceneState.videoLoop * 1000, true)}
              </div>{' '}
              <div
                className="cell small-1 text-stable"
                onClick={() => {
                  setSceneState(() => ({
                    ...sceneState,
                    videoLoop: Number(time / 1000),
                  }))
                  updateSceneHandler({
                    ...sceneState,
                    videoLoop: Number(time / 1000),
                  })
                }}>
                <DataTooltip
                  delay={500}
                  position="right"
                  title="Set to video time">
                  <button className="button hollow secondary mb-0">
                    <FaMapPin className="cursor-pointer" />
                  </button>
                </DataTooltip>
              </div>
              <div className="cell small-6 small-offset-1  large-7 end">
                <RangeInput
                  showLabels={false}
                  showLabelsOnHoverOnly
                  minValue={0}
                  reversed
                  formatLabel={(v) => convertMilliSeconds(Number(v) * 1000)}
                  maxValue={maxVideoLoop}
                  updateOnDrag
                  value={sceneState.videoLoop || 0}
                  redRange={[maxVideoLoop - VIDEO_LOOP_THRESHOLD, maxVideoLoop]}
                  thumbTooltip={`The video loop can not be shorter than ${VIDEO_LOOP_THRESHOLD} seconds`}
                  showThumbTooltip={showThumbTooltip}
                  setValue={(v, save) => {
                    let value = Number(v)
                    if (value + VIDEO_LOOP_THRESHOLD > maxVideoLoop) {
                      // set value to maximum
                      value = maxVideoLoop - VIDEO_LOOP_THRESHOLD

                      setSceneState(() => ({
                        ...sceneState,
                        videoLoop: value,
                      }))

                      if (save) {
                        setShowThumbTooltip(true)
                        setTimeout(() => {
                          setShowThumbTooltip(false)
                        }, 4000)
                        updateSceneHandler({
                          ...sceneState,
                          videoLoop: value,
                        })
                      }
                    } else {
                      setSceneState(() => ({
                        ...sceneState,
                        videoLoop: value,
                      }))

                      if (save) {
                        setShowThumbTooltip(false)
                        updateSceneHandler({
                          ...sceneState,
                          videoLoop: value,
                        })
                      }
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default Sliders
