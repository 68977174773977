import React, { useContext, useEffect } from 'react'
import { useNodes } from 'reactflow'
import Sliders from '../../EditorV2/Video/Sliders'
import { VideoProvider } from '../../EditorV2/VideoContext'
import { MediumProvider } from '../../Media/Modal/MediumContext'
import Player from '../../FlowV2/components/VideoPlayerV2/Player'
import useSelectedNode from '../../FlowV2/hooks/useSelectedNode'
import { ScenarioEditorContext } from '../../FlowV2/context/ScenarioEditorProvider'
import { FaLink } from 'react-icons/fa'
import EditorControlsRow from '../../EditorV2/Video/EditorControlsRow'
import useAddUpdateElement from '../../FlowV2/hooks/useAddUpdateElement'
import useAddUpdateScene from '../../FlowV2/hooks/useAddUpdateScene'

const VideoEditor = () => {
  const { scenario } = useContext(ScenarioEditorContext)
  const selectedNode = useSelectedNode()
  const nodes = useNodes()
  const { updateElement } = useAddUpdateElement()
  const { reloadScene } = useAddUpdateScene()

  useEffect(() => {
    if (selectedNode)
      selectedNode.data.elements
        .filter((el) => el.anchorX === null && el.defaultAnchorX !== null)
        .forEach((el) => {
          updateElement({
            variables: {
              ...el,
              anchorX: el.defaultAnchorX,
              anchorY: el.defaultAnchorY,
            },
          })
        })
  }, [selectedNode])

  useEffect(() => {
    const video = selectedNode?.data?.video
    const interval = setInterval(() => {
      const hasIncompleteMedia = ['QUEUED', 'PROGRESSING'].includes(
        video?.state
      )
      if (hasIncompleteMedia)
        reloadScene({
          variables: {
            id: selectedNode?.id,
          },
        })
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [selectedNode])

  if (!selectedNode) return null
  const { video } = selectedNode?.data

  const renderVideoControls = () => {
    if (!video)
      return (
        <div className="c-video__editor--player--no-video pt-2 pb-2 pr-3 pl-3 flex-container align-justify text-normal align-middle">
          <span className="text-bold">
            This scene does not have a media file linked
          </span>
          <button
            className="o-button text-normal text-bold"
            data-open="media-modal">
            <FaLink className="mr-1" />
            Link media file
          </button>
        </div>
      )

    return (
      <div className="c-video__editor--bottom-controls">
        <div className="c-video__editor--bottom-controls--sliders">
          <EditorControlsRow />
          <Sliders />
        </div>
      </div>
    )
  }

  return (
    <VideoProvider>
      <MediumProvider medium={video}>
        <div
          className="w-100 h-100 bg-light flex-container"
          style={{ position: 'absolute', inset: 0, flexDirection: 'column' }}>
          <div className="h-100">
            <div className="c-video__editor--player">
              <div
                className={`c-video__editor--player--video min-h h-100
                    ${video ? '' : 'c-video__editor--player--no-video'}`}>
                <Player showControls={false} scenarioData={scenario} />
              </div>
            </div>
          </div>

          {renderVideoControls()}
        </div>
      </MediumProvider>
    </VideoProvider>
  )
}

export default VideoEditor
