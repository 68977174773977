import React from 'react'
import DataTooltip from '../UI/DataTooltip'

const GroupPublicLink = ({ on, link }) => {
  if (on) {
    return (
      <>
        <span style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
          <span className="o-bullet o-bullet--energized"></span>{' '}
          <DataTooltip position="right" title="Open link in new tab">
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                width: '100%',
                marginRight: '6px',
                alignItems: 'center',
              }}
              className="text-normal mr-2 text-stable-dark cursor-pointer text-underline">
              On
            </a>
          </DataTooltip>
        </span>
        <span style={{ display: 'flex', flexGrow: '1' }}></span>
      </>
    )
  }
  return <span>Off</span>
}

export default GroupPublicLink
