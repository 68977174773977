import {
  goToHomeScene,
  goToNextScene,
  goToPrevScene,
  zoomIn,
  zoomOut,
  zoomToDefault,
} from '../helpers/controlsHelper'
import useKeyboardShortcuts from './useKeyboardShortcuts'
import { useEffect, useRef } from 'react'

const useFlowKeyboardShortcuts = (reactFlow) => {
  const shortcutsRef = useRef()
  useEffect(() => {
    // Define your keyboard shortcuts
    shortcutsRef.current = [
      {
        keys: ['Control', '-'],
        action: () => zoomOut(reactFlow),
      },
      {
        keys: ['Control', '+'],
        action: () => zoomIn(reactFlow),
      },
      {
        keys: ['Control', '='],
        action: () => zoomIn(reactFlow),
      },
      {
        keys: ['Control', '0'],
        action: () => zoomToDefault(reactFlow),
      },
      {
        keys: ['Control', 's'],
        action: () => goToHomeScene(reactFlow),
      },
      {
        keys: ['Control', 'k'],
        action: () => $('#flow-search-modal').foundation('open'),
      },
      {
        keys: ['Control', 'Shift', '.'],
        action: () => goToNextScene(reactFlow),
      },
      {
        keys: ['Control', 'Shift', ','],
        action: () => goToPrevScene(reactFlow),
      },
      {
        keys: ['Control', 'Shift', '>'],
        action: () => goToNextScene(reactFlow),
      },
      {
        keys: ['Control', 'Shift', '<'],
        action: () => goToPrevScene(reactFlow),
      },
    ]
  }, [reactFlow])

  // Use the custom hook
  useKeyboardShortcuts(shortcutsRef.current)
}

export default useFlowKeyboardShortcuts
