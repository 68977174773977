import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { updateClientPlanMutation } from '../../../apollo/query/clients'
import { getCurrentClientSettingsQuery } from '../../../apollo/query/settings'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { getAdditionalPrice } from '../../../utils/helpers/subscription'
import NumberInput from '../../UI/Form/NumberInput'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'

const WorkspaceSlots = ({ currentClient }) => {
  const [additionalWorkspaces, setAdditionalWorkspaces] = useState()

  const [updateClientPlan, { loading: updating }] = useMutation(
    updateClientPlanMutation,
    {
      onError: handleApolloError,
      onCompleted: () => {
        addSuccessAlert({
          title: 'Additional workspace slots have been updated',
        })
      },
      refetchQueries: [
        {
          query: getCurrentClientSettingsQuery,
        },
      ],
    }
  )

  useEffect(() => {
    if (currentClient) {
      setAdditionalWorkspaces(currentClient.planAdditionalWorkspaces)
    }
  }, [currentClient])

  if (!currentClient) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  const workspacesCount = currentClient.planUsedWorkspaces
  const minWorkspaceSlots =
    workspacesCount - currentClient.plan.includedWorkspaces

  const buyWorkspaces = () => {
    updateClientPlan({
      variables: {
        additionalPublishedScenarios:
          currentClient.planAdditionalPublishedScenarios,
        additionalWorkspaces,
      },
    })
  }

  return (
    <div className="row border-top pt-3 mt-3">
      <div className="medium-3 small-12 columns">
        <h2 className="text-bold">Workspace slots</h2>
        <p>
          Set the amount of workspace slots required. First{' '}
          {currentClient.plan.includedWorkspaces} workspace slot
          {currentClient.plan.includedWorkspaces > 1 && `s`} are included in
          your plan.{' '}
          <a
            href="https://help.warpvr.com/organization-settings/qtJLeZHXC5TPNkXEcohX5m"
            target="_blank"
            className="text-stable-dark text-underline">
            More info about workspace slots.
          </a>
        </p>
      </div>

      <div className="medium-6 small-12 columns">
        <div className="mb-3">
          <label className="mb-1">Included in your plan</label>
          <span>
            {currentClient.plan.includedWorkspaces} workspace slot
            {currentClient.plan.includedWorkspaces > 1 && 's'}
          </span>
        </div>

        {currentClient.plan.features.includes('WORKSPACES') && (
          <>
            <label className="mb-1">Get additional slots</label>
            <div className="flex-container text-normal align-justify align-middle mb-3">
              <div className="flex-container align-middle">
                <NumberInput
                  value={additionalWorkspaces}
                  min={minWorkspaceSlots}
                  changeHandler={setAdditionalWorkspaces}
                  step={1}
                />
                <span className="ml-1">
                  workspace slot{additionalWorkspaces > 1 && `s`}
                </span>
              </div>
              <span>
                {getAdditionalPrice(
                  'workspace',
                  currentClient.planBillingCurrency,
                  additionalWorkspaces,
                  currentClient.planBillingPeriod === 'YEAR'
                )}{' '}
                / {currentClient.planBillingPeriod.toLowerCase()}
              </span>
            </div>
            {additionalWorkspaces < minWorkspaceSlots && (
              <p className="text-assertive">
                The amount of workspace slots can not be lower than the amount
                of workspaces you have. Delete workspaces first before changing
                this value. You currently have {workspacesCount} workspace
                {workspacesCount > 1 && 's'}.
              </p>
            )}

            <div className="flex-container align-right">
              <button
                type="button"
                className={`button primary wide ${
                  updating && 'o-button--loading'
                }`}
                disabled={additionalWorkspaces < minWorkspaceSlots || updating}
                onClick={buyWorkspaces}>
                Confirm
              </button>
            </div>
          </>
        )}

        {!currentClient.plan.features.includes('WORKSPACES') && (
          <p>
            You can not get any additional slots with the current plan. Upgrade
            to get more slots
          </p>
        )}
      </div>
    </div>
  )
}

export default WorkspaceSlots
