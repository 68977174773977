import { useMutation } from '@apollo/client'
import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'
import {
  sendPlayMailMutation,
  startPublicLinkSessionMutation,
} from '../../../apollo/query/session'
import useFoundation from '../../../hooks/useFoundation'
import AppStoreLinks from '../AppStoreLinks'
import LanguageContext from '../../../utils/context/LanguageContext'

const SignInModal = ({ id, appendTo, domains = [] }) => {
  const ref = useFoundation()
  const emailRef = useRef()
  const { groupSecret } = useParams()
  const { translations: t, language } = useContext(LanguageContext)

  const [showConfirm, setShowConfirm] = useState(false)
  const [error, setError] = useState({
    show: false,
    message: '',
  })
  const [sendMail] = useMutation(sendPlayMailMutation, {
    onCompleted: () => {
      setShowConfirm(true)
    },
    onError: (e) => {
      throw new Error(e.message)
    },
  })

  const [sendMailWithGroupSecret] = useMutation(
    startPublicLinkSessionMutation,
    {
      onCompleted: () => {
        setShowConfirm(true)
      },
      onError: (e) => {
        throw new Error(e.message)
      },
    }
  )
  const submitHandler = (e) => {
    e.preventDefault()
    const emailValue = emailRef.current.value.trim()
    const mailDomain = emailValue.split('@')[1]
    const { prefix, separator, suffix } = t.modals.signin.error
    const restrictedByDomains = domains.length
    if (restrictedByDomains && !domains.includes(mailDomain)) {
      const array = [...domains]
      const last = array.pop()
      const message = `${prefix} ${array.join(
        ', '
      )} ${separator} ${last} ${suffix}`
      setError({
        show: true,
        message,
      })
    }
    if (groupSecret) {
      sendMailWithGroupSecret({
        variables: {
          email: emailValue,
          publicLinkSecret: groupSecret,
          locale: language.toUpperCase(),
        },
      })
    } else {
      sendMail({
        variables: { email: emailValue, locale: language.toUpperCase() },
      })
    }
  }

  $(`#${id}`).on('closed.zf.reveal', () => {
    setShowConfirm(false)
  })

  const emailInput = () => {
    const { title, subtext, placeholder, buttontext } =
      t.modals.signin.enterEmail
    const { email } = t.errors
    return (
      <div id="email-content" key="email" className="fade-in-text">
        <h1 className="o-modal__launcher--heading">{title}</h1>
        <p className="o-modal__launcher--subtext">{subtext}</p>
        <form
          className="grid-x o-modal__launcher--input-container"
          onSubmit={submitHandler}>
          <input
            ref={emailRef}
            data-testid="input-email-field"
            className="cell small-12 medium-auto"
            type="email"
            placeholder={placeholder}
            style={{ letterSpacing: '-0.7px', color: 'black' }}
            title={email.invalid}
            autoComplete="true"
            required
          />
          <button
            data-testid="submit-button"
            type="submit"
            className="cell mb-0 small-12 medium-shrink button primary wide"
            style={{ fontSize: '16px' }}>
            {buttontext}
          </button>
        </form>
        {error.show && (
          <p
            data-testid="error-container"
            className=" mt-2 mb-0 text-normal text-assertive">
            {error.message}
          </p>
        )}
      </div>
    )
  }
  const confirmSent = () => {
    const {
      title,
      subtext,
      subtextBold,
      subtextAfter,
      noAppTitle,
      noAppSubtext,
    } = t.modals.signin.showConfirm

    return (
      <div key="showConfirm" className="fade-in-text">
        <h1 className="o-modal__launcher--heading">{title}</h1>
        <p className="o-modal__launcher--subtext">
          {subtext}
          <strong style={{ color: 'black' }}>{subtextBold}</strong>
          {subtextAfter}
        </p>
        <h3 className="o-modal__launcher--heading-h3">{noAppTitle}</h3>
        <p>{noAppSubtext}</p>
        <AppStoreLinks S={t} grid />
      </div>
    )
  }
  return (
    <div
      ref={ref}
      className="reveal o-modal__launcher"
      data-animation-in="fade-in"
      data-animation-out="fade-out"
      id={id}
      style={{ position: 'relative' }}
      data-v-offset={Foundation.MediaQuery.only('small') ? '0' : ''}
      data-append-to={appendTo}
      data-reveal>
      <span
        className="hide-for-medium"
        style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
        }}
        onClick={() => $('#signinmodal').foundation('close')}>
        <FaTimes />
      </span>
      {showConfirm ? confirmSent() : emailInput()}
    </div>
  )
}

export default SignInModal
