import React, { useState } from 'react'
import Stepper from '../../components/UI/Stepper/Stepper'
import ActivateProfileForm from './Forms/ActivateProfileForm'
import ActivateOrganisationForm from './Forms/ActivateOrganisationForm'
import withApollo from '../../hooks/withApollo'
import ActivateForm from './Forms/ActivateForm'
import TwoFactorsForm from './Forms/TwoFactorsForm'
import logo from '../../../../images/logo.svg'
import PasswordForm from '../../components/UI/Form/PasswordForm'

const renderPasswordFormButton = (valid, passwordsMatch) => (
  <button
    type="submit"
    className="o-button text-bold w-100 mt-2"
    disabled={!(valid.allChecksPassed && passwordsMatch)}
    data-testid="submit-button">
    Next
  </button>
)

const UserRegistration = ({ token }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentStepForm, setCurrentStepForm] = useState(1)
  const [totalSteps, setTotalSteps] = useState(4)

  const forms = [
    {
      step: 1,
      form: (
        <ActivateForm
          token={token}
          handleNext={(user) => {
            if (user.ssoManaged) {
              setTotalSteps(2)
              setCurrentStepForm(4)
            }
            setCurrentStep(currentStep + 1)
            setCurrentStepForm(currentStep + 1)
          }}
        />
      ),
      title: (
        <>
          Welcome to Warp Studio
          <br /> Let’s first activate your account
        </>
      ),
      subtitle: '',
    },
    {
      step: 2,
      form: (
        <PasswordForm
          onCompleted={() => {
            setCurrentStep(currentStep + 1)
            setCurrentStepForm(currentStep + 1)
          }}
          submitButtonText="Next"
          button={renderPasswordFormButton}
        />
      ),
      title: <>Create a password</>,
      subtitle: '',
    },
    {
      step: 3,
      form: (
        <TwoFactorsForm
          setTotalSteps={setTotalSteps}
          handleNext={() => {
            setCurrentStep(currentStep + 1)
            setCurrentStepForm(currentStep + 1)
          }}
        />
      ),
      title: 'Set up two-factor authentication',
    },
    {
      step: 4,
      form: (
        <ActivateProfileForm
          isActive={currentStep === 4}
          handleNext={() => {
            setCurrentStep(currentStep + 1)
            setCurrentStepForm(currentStep + 1)
          }}
        />
      ),
      title: 'Please introduce yourself',
      subtitle:
        'Please fill in your name and upload a profile picture. This makes it easier for your colleagues to recognize you.',
    },
    {
      step: 5,
      form: <ActivateOrganisationForm isActive={currentStep === 5} />,
      title: 'Introduce your organization',
      subtitle: `
Determine what your organization looks like in the Warp VR app. These settings can be changed at any time in the Organization settings. `,
      link: (
        <a
          className="text-normal text-stable-dark text-underline"
          href="https://help.warpvr.com/organization-settings/qtJLeZHXC5TPNkXEcohX5m/branding-settings/aeVfBvrTrwGMsujS581bbL"
          target="_blank">
          Learn more.
        </a>
      ),
    },
  ]

  if (!token) throw new Error('No token provided, something went wrong')

  return (
    <div
      data-testid="userregistration-wrapper"
      className="pt-4 pb-4 c-form c-form__devise">
      <header>
        <div className="flex-container align-middle align-center w-100 mb-5 pb-5">
          <img src={logo} loading="lazy" width={65} />
          <h2 className="ml-1 text-bold mb-0 c-launcher__logo--text">
            Warp Studio
          </h2>
        </div>
      </header>

      <Stepper
        steps={totalSteps}
        showActiveUntilCurrentStep
        currentStep={currentStep}
      />
      <div className="text-center">
        <h1 className="text-bold mb-4">{forms[currentStepForm - 1].title}</h1>
        {forms[currentStepForm - 1].subtitle && (
          <span
            className="text-stable-dark text-normal"
            style={{ letterSpacing: '-0.7px' }}>
            {forms[currentStepForm - 1].subtitle}{' '}
            {forms[currentStepForm - 1].link}
          </span>
        )}
      </div>
      {forms[currentStepForm - 1].form}
    </div>
  )
}

export default withApollo(UserRegistration)
