import React, { useContext } from 'react'
import { FaPause, FaPlay } from 'react-icons/fa'
import { MediumContext } from '../MediumContext'
import VolumeSlider from './Controls/VolumeSlider'
import TimeIndicator from './TimeIndicator'
import TimeSlider from './TimeSlider'

const MediumControlsRow = () => {
  const {
    medium: [medium],
    currentVideoRef,
    controls: [controls, setControls],
  } = useContext(MediumContext)

  const pauseHandler = () => {
    if (!currentVideoRef.current.paused) {
      currentVideoRef.current.pause()
      setControls({ ...controls, isPlaying: false })
    } else {
      currentVideoRef.current.play()
      setControls({ ...controls, isPlaying: true })
    }
  }
  return (
    <div data-testid="medium-controls-row" key={medium.id}>
      <div className="pl-3 pr-3 mb-1 mt-1 grid-x c-video__editor--slider-row border-none">
        <div className="cell small-3 grid-x">
          <button
            data-testid="play-button"
            style={{
              minWidth: '46px',
              maxWidth: '80px',
            }}
            className="button cell auto secondary hollow mb-0 mr-1"
            onClick={pauseHandler}>
            {controls.isPlaying ? (
              <FaPause className="text-dark" data-testid="is-playing" />
            ) : (
              <FaPlay className="text-dark" data-testid="is-paused" />
            )}
          </button>
          <VolumeSlider />
        </div>
        <TimeIndicator />
        <TimeSlider />
      </div>
    </div>
  )
}

export default MediumControlsRow
