import React from 'react'
import BulkManageTraineeGroupsModal from './Modal/BulkManageTraineeGroupsModal'
import DeleteTraineeModal from './Modal/DeleteTraineeModal'
import EditTraineeModal from './Modal/EditTraineeModal'
import ExportTraineesModal from './Modal/ExportTraineesModal'
import HelpSigninTraineesModal from './Modal/HelpSigninTraineesModal'
import ManageTraineeGroupsModal from './Modal/ManageTraineeGroupsModal'
import AddTraineeModal from './Modal/AddTraineeModal'
import GetTraineeCodeModal from './Modal/GetTraineeCodeModal'

const TraineeModals = ({
  appendTo,
  selectedTrainees,
  lmsUserIds,
  ownerIds,
  currentTrainee,
  groups,
  bulkActionAddUsers,
  clearHandler,
  editCallbackHandler,
  addTraineeCallBack,
  importHandler,
  bulkActionCallback,
}) => {
  return (
    <>
      <GetTraineeCodeModal
        appendTo={appendTo}
        id="get-trainee-code-modal"
        currentTrainee={currentTrainee}
      />
      <ExportTraineesModal
        appendTo={appendTo}
        id="export-trainees-modal"
        userIds={selectedTrainees}
      />
      <HelpSigninTraineesModal
        appendTo={appendTo}
        id="help-signin-trainees-modal"
        userIds={selectedTrainees}
      />
      <AddTraineeModal
        id="add-trainee-modal"
        appendTo={appendTo}
        callback={addTraineeCallBack}
        importHandler={importHandler}
      />
      <BulkManageTraineeGroupsModal
        appendTo={appendTo}
        id="bulk-manage-trainee-groups-modal"
        userIds={selectedTrainees.filter(
          (id) => !lmsUserIds.includes(Number(id))
        )}
        groups={groups}
        addUsers={bulkActionAddUsers}
        bulkActionCallback={() => {
          bulkActionCallback()
        }}
      />
      <EditTraineeModal
        appendTo={appendTo}
        id="edit-trainee-modal"
        currentTrainee={currentTrainee}
        callbackHandler={editCallbackHandler}
      />
      <ManageTraineeGroupsModal
        appendTo={appendTo}
        currentTrainee={currentTrainee}
        id="manage-trainee-groups-modal"
      />
      <DeleteTraineeModal
        appendTo={appendTo}
        id="delete-trainee-modal"
        ids={
          currentTrainee.id
            ? [currentTrainee.id]
            : selectedTrainees.filter((id) => !ownerIds.includes(id))
        }
        selectedTraineesCount={selectedTrainees.length}
        clearSelectedItems={clearHandler}
      />
    </>
  )
}
export default TraineeModals
