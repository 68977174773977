const { AFRAME } = window

AFRAME.registerComponent('scene-settings', {
  dependencies: ['collider-check', 'embedded'],
  init() {
    this.el.addEventListener('loaded', () => {
      this.el.setAttribute('cursor', 'rayOrigin: mouse')
      this.el.setAttribute('raycaster', 'near: 6; far: 10; interval: 50;')
    })
  },
})
