import React from 'react'
import { BarChart, CartesianGrid, ResponsiveContainer } from '.'

const BarGraph = ({ children, data, ...props }) => {
  return (
    <ResponsiveContainer width={'99.9%'} height={210}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          bottom: 5,
        }}
        {...props}>
        <CartesianGrid vertical={false} stroke="var(--stable-lighter)" />
        {children}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarGraph
