import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { getSignInSecretCodeQuery } from '../../apollo/query/session'
import FAQ from '../../components/Launcher/FAQ'
import Footer from '../../components/Launcher/Footer/Footer'
import Header from '../../components/Launcher/Header'
import Error from '../../components/Launcher/Error'
import HowToPlay from '../../components/Launcher/HowToPlay'
import Nav from '../../components/Launcher/Nav'
import withApollo from '../../hooks/withApollo'
import LanguageContext from '../../utils/context/LanguageContext'

const SessionLauncher = () => {
  const { translations: t } = useContext(LanguageContext)
  const { signinLinkSecret } = useParams()
  const { loading, data } = useQuery(getSignInSecretCodeQuery, {
    variables: {
      signinLinkSecret,
    },
  })

  if (loading || !data) return null
  if (data.session === null) return <Error />

  const { titles } = t.sections
  return (
    <>
      <Header />
      <section id="sessioncode" className="grid-container fluid">
        <h2 className="c-launcher__page--heading">{titles.sessionCode}</h2>
        <div className="c-launcher__page--container ">
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <span className=" c-launcher__sign-in--code">
              {data.session.code}
            </span>
            <p>
              <span className="text-dark text-bold">{`${t.modals.signin.showConfirm.subtextBold} `}</span>
              {t.modals.signin.showConfirm.subtextAfter}
            </p>
          </div>
        </div>
      </section>
      <Nav />
      <HowToPlay />
      <FAQ />
      <Footer />
    </>
  )
}

export default withApollo(SessionLauncher)
