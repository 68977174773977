import React, { useState, useContext } from 'react'
import { TabTitles, TabContent } from '../../UI/Tabs/Tabs'
import ElementsTab from './Tabs/ElementsTab'
import MediaSettingsTab from './Tabs/MediaSettingsTab'
import { SceneEditorContext } from '../SceneEditorContext'
import { truncate } from '../../../utils/helpers/javascript'

const Sidebar = ({ scenes, scenarioId }) => {
  const [sceneState] = useContext(SceneEditorContext)
  const [showFullDescription, setShowFullDescription] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const titles = ['Elements', 'Advanced settings']
  const content = [
    <ElementsTab key="elements" scenes={scenes} scenarioId={scenarioId} />,
    <MediaSettingsTab key="media-tab" scene={sceneState} />,
  ]
  return (
    <div className="sidebar">
      <div id="description" className="p-3">
        <h3>Description</h3>
        <p className="mb-0 text-dark" style={{ whiteSpace: 'pre-wrap' }}>
          {showFullDescription
            ? sceneState.description
            : truncate(sceneState.description || '', 180, true)}
        </p>
        {sceneState.description?.length > 180 && (
          <p className="mb-0 mt-1">
            {showFullDescription ? (
              <span
                className="text-underline cursor-pointer"
                onClick={() => setShowFullDescription(false)}>
                Show less
              </span>
            ) : (
              <span
                className="text-underline cursor-pointer"
                onClick={() => setShowFullDescription(true)}>
                Show more
              </span>
            )}
          </p>
        )}
      </div>
      <div id="sidebar-tabs" className="sidebar-tabs">
        <TabTitles
          type="inline"
          titles={titles}
          activeTab={activeTab}
          selectHandler={setActiveTab}
        />
        <div className="p-3">
          <TabContent titles={titles} content={content} activeTab={activeTab} />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
