import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../UI/Modal'
import GroupsList from '../GroupsList'
import cache from '../../../apollo/cache'
import { addGroupMutation, getGroupsQuery } from '../../../apollo/query/groups'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import GroupRestrictAccess from '../GroupRestrictAccess'
import ImportGroupsForm from '../ImportGroupsForm'

const AddGroupModal = ({ id, appendTo, purchasedCourses, purchasedScenarios, callback }) => {
  const courseRef = useRef([])
  const scenarioRef = useRef([])
  const [key, setKey] = useState(uuidv4())
  const [importMany, setImportMany] = useState(false)

  const [restrictedAccess, setRestrictedAccess] = useState(false)
  const [values, setValues] = useState({
    name: '',
    startDate: null,
    endDate: null,
  })

  const [addGroup] = useMutation(addGroupMutation, {
    onError: handleApolloError,
    onCompleted: ({ addGroup: { group } }) => {
      addSuccessAlert({ title: 'Group created', subtitle: group.name })

      const { groups } = cache.readQuery({ query: getGroupsQuery })
      cache.writeQuery({ query: getGroupsQuery, data: [...groups, group] })
      callback()
    },
  })

  const resetForm = () => {
    setTimeout(() => {
      setImportMany(false)
      setValues({ name: '' })
      setKey(uuidv4())
    }, 300)
  }

  const submit = () => {
    addGroup({
      variables: {
        name: values.name,
        publishedScenarioIds: scenarioRef.current.map((i) => Number(i)),
        courseIds: courseRef.current.map((i) => Number(i)),
        startDate:
          restrictedAccess && values.startDate ? values.startDate : null,
        endDate: restrictedAccess && values.endDate ? values.endDate : null,
      },
    })

    resetForm()
  }
  const getGroupLists = () => {
    const grouplists = [
      {
        label: 'Courses',
        type: 'course',
        ref: courseRef,
      },
      {
        label: 'Scenarios',
        type: 'scenario',
        ref: scenarioRef,
      },
    ]
    return grouplists.map((g, index) => (
      <div key={index} className="cell small-12">
        <label>{g.label}</label>
        <GroupsList
          type={g.type}
          purchasedItems={g.type === "course" ? purchasedCourses : purchasedScenarios}
          selectionRef={g.ref}
        />
      </div>
    ))
  }

  const getFormContent = () => {
    if (!importMany)
      return (
        <form className="mt-3" key={key}>
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <label
                htmlFor="add-group-title"
                className="c-form__label--required">
                Group title
              </label>
              <input
                type="text"
                defaultValue={values.name}
                autoComplete="off"
                onChange={({ target }) => {
                  setValues({ ...values, name: target.value })
                }}
                name="group-title"
                id="add-group-title"
              />
            </div>
          </div>
          {getGroupLists()}
          <GroupRestrictAccess
            restrictedAccess={restrictedAccess}
            setRestrictedAccess={setRestrictedAccess}
            values={values}
            setValues={setValues}
          />
        </form>
      )
    return (
      <ImportGroupsForm
        modalId={id}
        cancelHandler={resetForm}
        callback={() => {
          resetForm()
          callback()
        }}
      />
    )
  }
  const getSubtitle = () => {
    if (!importMany) return 'Create a group and add courses and scenarios.'
    return 'Upload a TSV or TXT file containing group information'
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons={!importMany}
      closeOnEscape={false}
      headerText="New Group"
      overflowY="visible"
      submitDisabled={
        restrictedAccess &&
        (!values.startDate ||
          !values.endDate ||
          new Date(values.endDate).getTime() / 1000 <
            new Date(values.startDate).getTime() / 1000)
      }
      submitHandler={submit}
      cancelHandler={resetForm}
      footerText={
        !importMany && (
          <span
            className="cursor-pointer text-stable-dark text-underline"
            onClick={() => setImportMany(true)}>
            Click here to import many groups at once.
          </span>
        )
      }
      subtitle={getSubtitle()}>
      {getFormContent()}
    </Modal>
  )
}

export default AddGroupModal
