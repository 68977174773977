import React, { useEffect, useState } from 'react'

const TabTitles = ({ type = 'list', titles, activeTab, selectHandler }) => {
  return (
    <ul
      className={type === 'inline' ? 'o-tabs--inline' : 'o-tabs'}
      data-tabs
      id="tabs">
      {titles.map((title, index) => (
        <li
          key={index}
          onClick={() => selectHandler(index)}
          className={`o-tabs-title${type === 'inline' ? '--inline' : ''} ${
            activeTab === index ? 'is-active' : ''
          }`}>
          {title}
        </li>
      ))}
    </ul>
  )
}

const TabContent = ({ titles, content, activeTab }) => {
  const [tabsLoaded, setTabsLoaded] = useState([])
  useEffect(() => {
    if (activeTab !== null && !tabsLoaded.includes(activeTab))
      setTabsLoaded([...tabsLoaded, activeTab])
  }, [activeTab])
  return (
    <div className="o-tabs-content" data-tabs-content="tabs">
      {titles.map((_, index) => (
        <div
          key={index}
          className={`o-tabs-panel tabs-panel ${
            activeTab === index ? 'is-active' : ''
          }`}>
          {tabsLoaded.includes(index) && content[index]}
        </div>
      ))}
    </div>
  )
}

export { TabTitles, TabContent }
