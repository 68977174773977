import iconHotspot from '../../../../images/scene-icons/hotspot.svg'
import iconMpc from '../../../../images/scene-icons/multiple-choice.svg'
import iconInfo from '../../../../images/scene-icons/info.svg'
import iconDirection from '../../../../images/scene-icons/direction.svg'
import iconTransition from '../../../../images/scene-icons/auto-transition.svg'
import iconEnding from '../../../../images/scene-icons/ending.svg'

const getIconByKind = (kind) => {
  switch (kind) {
    case 'INFORMATION':
      return iconInfo
    case 'HOTSPOT':
      return iconHotspot
    case 'DIRECTION':
      return iconDirection
    case 'MPC':
      return iconMpc
    case 'TRANSITION':
      return iconTransition
    case 'ENDING':
      return iconEnding
    default:
      return null
  }
}
export default getIconByKind
