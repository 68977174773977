import { arrowBubble } from '../../../helpers/aframe-shapes'
import { clamp } from '../../../../../utils/helpers/javascript'

const { AFRAME, THREE } = window

AFRAME.registerComponent('text-bubble', {
  schema: {
    enabled: { default: true },
    radius: { default: 0.05 },
    textOffset: { default: 0.05 },
    paddingX: { default: 0.1 },
    paddingY: { default: 0.2 },
    bottomSpacing: { default: 0.15 },
    width: { default: 1.8 },
    type: { default: 'default' },
  },
  draw() {
    const shape = new THREE.Shape()
    arrowBubble(
      shape,
      -this.data.width / 2,
      -(this.data.height / 2) + this.data.textOffset,
      this.data.width,
      this.data.height + this.data.textOffset,
      this.data.radius || 0.05
    )
    return new THREE.ShapeGeometry(shape)
  },

  update() {
    const textBox = this.self.children[0]
    const bubbleBox = this.self.children[1]
    if (!textBox || !bubbleBox) return null

    let { height } = textBox.getAttribute('geometry')
    if (!height) return null

    height += this.data.paddingY * 2
    this.data.height = clamp(height, 0.3, height)

    this.self.setAttribute(
      'position',
      `0 ${this.data.height / 2 + this.data.bottomSpacing} 0`
    )

    this.bubble = new THREE.Mesh(this.draw(), this.bubbleMaterial)
    this.bubble.position.set(0, 0, 0.01)
    textBox.setAttribute('position', `0 ${this.data.paddingY - 0.02} 0.03`)
    return bubbleBox.setObject3D('bubble', this.bubble)
  },

  onLoad() {
    for (const child of this.self.children) {
      child.addEventListener('componentchanged', (e) => {
        if (e.detail.name === 'geometry') {
          setTimeout(() => this.update(), 0)
        }
        if (e.detail.name === 'text') {
          setTimeout(() => this.update(), 0) //update width
          this.self.children[0].setAttribute(
            'geometry',
            `primitive: plane; width: auto; height: auto;`
          )
          setTimeout(() => this.update(), 0) //update height
        }
      })
    }
  },

  init() {
    this.self = this.el
    this.self.addEventListener('loaded', this.onLoad.bind(this))
  },
})
