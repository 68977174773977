import React, { useState, useEffect, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { FaInfoCircle } from 'react-icons/fa'
import RangeInput from '../../../UI/Form/RangeInput'
import trimIconFrom from '../../../../../../images/icon-trim-start.svg'
import trimIconTo from '../../../../../../images/icon-trim-end.svg'
import { convertMilliSeconds } from '../../../../utils/format'
import { updateMediumTrimMutation } from '../../../../apollo/query/media'
import { handleApolloError } from '../../../../utils/errors'
import { MediumContext } from '../MediumContext'
import DataTooltip from '../../../UI/DataTooltip'

const TrimVideoRow = () => {
  const {
    medium: [medium, setMedium],
    currentVideoRef,
  } = useContext(MediumContext)
  const [trimmedTimes, setTrimmedTimes] = useState({
    min: medium.trimStart || 0,
    max: medium.trimEnd || medium.duration / 1000,
  })

  const [updateMedium] = useMutation(updateMediumTrimMutation, {
    onError: handleApolloError,
  })

  useEffect(() => {
    setTrimmedTimes({
      min: medium.trimStart || 0,
      max: medium.trimEnd || medium.duration / 1000,
    })
  }, [medium])

  const updateTrimmedTimes = (times) => {
    if (times.min === medium.trimStart && times.max === medium.trimEnd) {
      // nothing changed
      return null
    }
    setMedium({ ...medium, trimStart: times.min, trimEnd: times.max })
    return updateMedium({
      variables: {
        id: medium.id,
        name: medium.name,
        trimEnabled: medium.trimEnabled || false,
        trimStart: times.min,
        trimEnd: times.max,
      },
    })
  }

  const trimSettings = () => {
    return (
      <div className="cell auto" data-testid="trimvideo-settings">
        <div
          className="button-group mb-0 no-gaps hollow secondary"
          style={{ display: 'flex', alignItems: 'center' }}>
          <button
            data-testid="trimvideo-button--set-min"
            className="button"
            onClick={() => {
              // get current time
              const { currentTime } = currentVideoRef.current
              if (currentTime < trimmedTimes.max) {
                setTrimmedTimes({ ...trimmedTimes, min: currentTime })
                updateTrimmedTimes({ ...trimmedTimes, min: currentTime })
              }
            }}>
            <img src={trimIconFrom} alt="trim from" />
          </button>
          <button
            data-testid="trimvideo-button--set-max"
            className="button secondary"
            onClick={() => {
              // get current time
              const { currentTime } = currentVideoRef.current
              if (currentTime >= trimmedTimes.min && currentTime !== 0) {
                setTrimmedTimes({ ...trimmedTimes, max: currentTime })
                updateTrimmedTimes({ ...trimmedTimes, max: currentTime })
              }
            }}>
            <img src={trimIconTo} alt="trim to" />
          </button>
        </div>
      </div>
    )
  }

  const trimSlider = () => {
    return (
      <div
        key={medium.id}
        className="cell small-6 auto xxxlarge-7 end"
        data-testid="trimvideo-slider">
        <RangeInput
          minValue={0}
          maxValue={medium.duration / 1000}
          formatLabel={(v) => convertMilliSeconds(Number(v * 1000))}
          step={10}
          value={trimmedTimes}
          noBackground
          showLabels={false}
          showLabelsOnHoverOnly
          setValue={(v, save) => {
            if (v.max * 1000 === 0) {
              v.max = 0.001 // ensure the max can never be 0.000
            }
            setTrimmedTimes(v)
            if (save) {
              updateTrimmedTimes({ min: Number(v.min), max: Number(v.max) })
            }
          }}
        />
      </div>
    )
  }

  const title = `
<span>Specify the beginning and / or ending time for the video.<br/>
This will not affect the video file itself.</span>
  `
  return (
    <div
      data-testid="trimvideo-container"
      className=" ml-3 mr-3 mb-1 mt-0 grid-x c-video__editor--slider-row"
      style={{ alignItems: 'center', minHeight: '48px' }}>
      <div className="cell grid-x small-3 align-center">
        <div className="cell shrink pr-1 o-switch">
          <input
            type="checkbox"
            data-testid="trimvideo-input"
            name="enableTrim"
            checked={medium.trimEnabled || false}
            readOnly
            id="enableTrim"
            onChange={() => {
              updateMedium({
                variables: {
                  id: medium.id,
                  name: medium.name,
                  trimStart: medium.trimStart || 0,
                  trimEnd: medium.trimEnd || Number(medium.duration / 1000),
                  trimEnabled: !medium.trimEnabled,
                },
              })
              setMedium({ ...medium, trimEnabled: !medium.trimEnabled })
            }}
            className="o-switch__input"
          />
          <label htmlFor="enableTrim" className="o-switch__paddle"></label>
        </div>
        <div
          className="cell small auto"
          style={{ fontSize: '14px', marginTop: '2px' }}>
          Trim video
          <DataTooltip position="right" title={title}>
            <FaInfoCircle className="ml-1 text-stable-dark" />
          </DataTooltip>
        </div>
      </div>
      {medium.trimEnabled && trimSettings()}
      {medium.trimEnabled && trimSlider()}
    </div>
  )
}

export default TrimVideoRow
