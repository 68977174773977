import { gql } from '@apollo/client'

const importFileMutation = gql`
  mutation addImport($kind: ImportKind!, $blobId: String!, $groupIds: [ID!]) {
    addImport(kind: $kind, blobId: $blobId, groupIds: $groupIds) {
      import {
        id
        errorMessages
        kind
        progress
        state
      }
    }
  }
`

const pollingImportQuery = gql`
  query Import($id: ID!) {
    imports(id: $id) {
      state
      id
      userCount
      progress
      groups {
        id
      }
      errorMessages
    }
  }
`

export { importFileMutation, pollingImportQuery }
