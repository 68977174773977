import React from 'react'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import DataTooltip from '../UI/DataTooltip'
import { dateFormat } from '../../utils/format'
import ConfigAuthenticatorModal from './ConfigAuthenticatorModal'
import DeleteAuthenticatorModal from './DeleteAuthenticatorModal'
import { addSuccessAlert } from '../../utils/helpers/alerts'

const ConfigAuthenticatorDetail = ({
  otpConfigured,
  otpConfiguredAt,
  otpRequired,
  onChangeConfiguation,
  currentUser,
}) => {
  const renderDeleteButton = () => {
    if (otpRequired)
      return (
        <DataTooltip
          id="disable-2fa"
          title="Two-factor authentication can’t be disabled because you belong to
    an organization that requires it.">
          <a className="text-assertive disabled">
            <FaTrash />
          </a>
        </DataTooltip>
      )
    return (
      <a
        className="text-assertive"
        title="Delete"
        data-open="delete-authenticator-modal">
        <FaTrash />
      </a>
    )
  }

  return (
    <>
      <div id="config-authenticator-detail">
        {!otpConfigured && (
          <button
            type="submit"
            className="o-button o-button--hollow mt-1 mb-0 text-normal text-bold"
            data-open="config-authenticator-modal">
            Configure
          </button>
        )}
        {otpConfigured && (
          <div className="text-normal pl-2 pr-2 pt-1-5 pb-1-5 flex-container align-middle align-justify border-light border-radius">
            <div className="">
              <span>Authenticator app</span>{' '}
              <span className="text-stable-dark">
                • Added on{' '}
                {dateFormat(
                  otpConfiguredAt ?? new Date(),
                  'MMM DD, YYYY',
                  false
                )}
              </span>
            </div>
            <div>
              <a
                className="mr-2 text-stable-dark"
                data-open="config-authenticator-modal"
                title="Edit">
                <FaPencilAlt />
              </a>
              {renderDeleteButton()}
            </div>
          </div>
        )}
      </div>
      <ConfigAuthenticatorModal
        id="config-authenticator-modal"
        appendTo="#config-authenticator-detail"
        submitHandler={() => {
          $('#config-authenticator-modal').foundation('close')
          onChangeConfiguation(true)
        }}
        currentUser={currentUser}
      />
      <DeleteAuthenticatorModal
        id="delete-authenticator-modal"
        appendTo="#config-authenticator-detail"
        onDelete={() => {
          $('#delete-authenticator-modal').foundation('close')
          addSuccessAlert({
            title: 'Successfully deleted an authenticator app',
          })
          onChangeConfiguation(false)
        }}
      />
    </>
  )
}
export default ConfigAuthenticatorDetail
