import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import TooltipConnector from './tooltip-connector'

@inject('diagramStore')
@observer
class Connector extends Component {
  render() {
    const { answer, type } = this.props

    let content = ''
    if (type === 'outgoing') {
      content = answer.letter
    }

    return (
      <div
        className={`
            c-connector
            c-connector--${type}
            c-connector--${answer.getClassName}
            c-connector--${answer.getFillClass()}
          `}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}>
        {content}
        <TooltipConnector answer={answer} type={type} />
      </div>
    )
  }
}

export default Connector
