import React from 'react'

const OauthFields = ({ values, handleChange }) => (
  <>
    {values.id && (
      <>
        <label htmlFor="oauthClientId">Client ID</label>
        <div className="flex-container">
          <input
            type="text"
            name="oauthClientId"
            id="oauthClientId"
            onChange={handleChange}
            defaultValue={values.oauthClientId}
            disabled
            className="input--disabled mr-1"
          />

          <div className="o-button__copy--container">
            <input
              type="radio"
              name="o-button-copy"
              onChange={() => {
                navigator.clipboard.writeText(values.oauthClientId)
              }}
            />
            <button
              type="button"
              className="o-button button hollow secondary text-medium">
              Copy value
            </button>
            <button className="o-button button hollow success">
              <span>Copied!</span>
            </button>
          </div>
        </div>

        <label htmlFor="oauthClientSecret">Client Secret</label>
        <div className="flex-container">
          <input
            type="text"
            name="oauthClientSecret"
            id="oauthClientSecret"
            onChange={handleChange}
            defaultValue={values.oauthClientSecret}
            disabled
            className="input--disabled"
          />

          <div className="o-button__copy--container">
            <input
              type="radio"
              name="o-button-copy"
              onChange={() => {
                navigator.clipboard.writeText(values.oauthClientSecret)
              }}
            />
            <button
              type="button"
              className="o-button button hollow secondary text-medium">
              Copy value
            </button>
            <button className="o-button button hollow success">
              <span>Copied!</span>
            </button>
          </div>
        </div>
      </>
    )}

    <p>Select the features that need to be enabled.</p>

    <label data-testid="label" className="o-checkbox">
      <input
        data-testid="checkbox"
        type="checkbox"
        onChange={handleChange}
        defaultChecked={values.oauthScimEnabled}
        name="oauthScimEnabled"
      />
      <span></span>SCIM
    </label>

    <label data-testid="label" className="o-checkbox">
      <input
        data-testid="checkbox"
        type="checkbox"
        onChange={handleChange}
        defaultChecked={values.oauthOrganizationApiEnabled}
        name="oauthOrganizationApiEnabled"
      />
      <span></span>Organization API
    </label>
  </>
)
export default OauthFields
