import { gql } from '@apollo/client'

export const addCameraLocationMutation = gql`
  mutation addCameraLocation(
    $scenarioId: ID!
    $name: String!
    $description: String!
  ) {
    addCameraLocation(
      scenarioId: $scenarioId
      name: $name
      description: $description
    ) {
      cameraLocation {
        id
        name
        description
      }
    }
  }
`

export const updateCameraLocationMutation = gql`
  mutation updateCameraLocation(
    $id: ID!
    $name: String!
    $description: String!
  ) {
    updateCameraLocation(id: $id, name: $name, description: $description) {
      cameraLocation {
        id
        name
        description
      }
    }
  }
`

export const updateCameraLocationsOrderMutation = gql`
  mutation updateCameraLocationsOrder($id: ID!, $cameraLocationIds: [ID!]!) {
    updateCameraLocationsOrder(id: $id, cameraLocationIds: $cameraLocationIds) {
      scenario {
        id
      }
    }
  }
`

export const removeCameraLocationMutation = gql`
  mutation removeCameraLocation($id: ID!) {
    removeCameraLocation(id: $id) {
      id
    }
  }
`
