import React, { Component } from 'react'
import { Provider } from 'mobx-react'
import Canvas from './canvas'
import DiagramStore from './stores/diagram'

export default class Diagram extends Component {
  render() {
    window.diagramStore = new DiagramStore(
      JSON.parse(this.props.cards),
      this.props.authenticity_token,
      this.props.urls,
      this.props.camera_locations
    )
    return (
      <Provider diagramStore={window.diagramStore}>
        <Canvas style={{ position: 'relative' }} />
      </Provider>
    )
  }
}
