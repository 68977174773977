import { gql } from '@apollo/client'

const getAttemptDetailQuery = gql`
  query getAttemptDetail($id: ID!) {
    attempt(id: $id) {
      id
      sequence
      startedAt
      highscore
      stars
      duration
      completed
      osName
      browserName
      deviceType
      deviceBrand
      deviceModel
      user {
        name
      }
      publishedScenario {
        id
        name
        version
        scoringSystem {
          kind
          pointsPerStar
        }
      }
      events {
        recordedAt
        kind
        scene {
          id
          number
          name
          elements {
            id
            timerMinutes
            timerSeconds
            kind
          }
        }
        answer {
          id
          number
          name
          kind
          points
          linkToEnding
          linkToId
          randomizedLinkToIds
        }
      }
    }
  }
`

const getAttemptsQuery = gql`
  query getAttempts(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
  ) {
    attempts(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      attempts {
        id
        startedAt
        anonymized
        highscore
        sequence
        stars
        testing
        completed
        duration
        osName
        browserName
        deviceType
        deviceBrand
        deviceModel
        user {
          id
          email
          firstName
          lastName
          anonymous
        }
        publishedScenario {
          name
        }
      }
    }
  }
`

const exportAttemptsMutation = gql`
  mutation exportAttempts(
    $kind: AttemptExportKind!
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
  ) {
    exportAttempts(
      kind: $kind
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      success
    }
  }
`

const getAttemptMetricsDayQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $metric: AttemptsMetric!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: $metric
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: DAY) {
        id
        name
        value
      }
    }
  }
`
const getAttemptMetricsHourQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $metric: AttemptsMetric!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: $metric
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: HOUR) {
        id
        name
        value
      }
    }
  }
`
const getAttemptMetricsMinutesQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $metric: AttemptsMetric!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: $metric
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: MINUTE) {
        id
        name
        value
      }
    }
  }
`

const getAttemptsFilterDataQuery = gql`
  query getAttemptsFilterData(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
  ) {
    attempts(fromDateTime: $fromDateTime, toDateTime: $toDateTime, filter: {}) {
      users {
        id
        name
        email
        anonymous
      }
      scenarios {
        id
        name
      }
      groups {
        id
        name
      }
    }
  }
`

export {
  getAttemptsQuery,
  getAttemptDetailQuery,
  exportAttemptsMutation,
  getAttemptMetricsDayQuery,
  getAttemptMetricsHourQuery,
  getAttemptMetricsMinutesQuery,
  getAttemptsFilterDataQuery,
}
