import React, { Fragment, useState, useRef, useEffect } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { FaPlus, FaGripVertical } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import SearchableDropdown from '../Menu/SearchableDropdown'
import { clamp, moveIndex, truncate } from '../../../utils/helpers/javascript'

const DraggableList = ({
  list,
  setListHandler,
  newList,
  getDropdownItemsHandler,
  dropdownItems,
  buttonText,
  loading,
  useDefaultSuffix = true,
  suffix = () => {},
}) => {
  const VERTICAL_ITEM_SIZE = 55

  const order = useRef(list.map((_, i) => i))

  const [showSelector, setShowSelector] = useState(false)

  // Animation styles
  const setStyles = (array, active, originalIndex, curIndex, y) => (index) => {
    const activeStyle = {
      top: curIndex * VERTICAL_ITEM_SIZE + y,
      transform: 'scale(1.05)',
      boxShadow: '0px 2px 3px rgba(0,0,0,0.06)',
      zIndex: list.length + 1,
      color: 'black',
      cursor: 'grabbing',
      immediate: (n) => n === 'y' || n === 'zIndex',
    }
    const passiveStyle = {
      top: array.indexOf(index) * VERTICAL_ITEM_SIZE,
      boxShadow: '0px 0px 0px rgba(0,0,0,0)',
      cursor: 'grab',
      transform: 'scale(1)',
      zIndex: list.length - array.indexOf(index),
      immediate: false,
    }
    return active && index === originalIndex ? activeStyle : passiveStyle
  }

  // Animation handler
  const [springs, setSprings] = useSprings(
    list.length,
    setStyles(order.current)
  )

  // Animation update handler
  useEffect(() => {
    setSprings(list.length, setStyles(order.current))
    newList.current = order.current.map((i) => list[i])
  }, [list])

  // Animation connector
  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(
      Math.round((curIndex * VERTICAL_ITEM_SIZE + y) / VERTICAL_ITEM_SIZE),
      0,
      list.length - 1
    )

    const newOrder = moveIndex(order.current, curIndex, curRow)
    setSprings(setStyles(newOrder, active, originalIndex, curIndex, y))

    if (!active) {
      order.current = newOrder
      newList.current = order.current.map((i) => list[i])
    }
  })

  // Add item to list
  const add = (item) => {
    setListHandler([...list, { value: item.value, id: item.id }])
    // Add an extra index to the current order list
    order.current.push(order.current.length)
  }

  // Delete item from list
  const remove = (itemId, orderValue) => {
    const newOrder = order.current.reduce((a, value) => {
      if (value !== Number(orderValue)) {
        if (value > Number(orderValue)) {
          a.push(value - 1)
        } else {
          a.push(value)
        }
      }
      return a
    }, [])
    order.current = newOrder
    setListHandler(list.filter((i) => i.id !== itemId))
  }

  return (
    <Fragment key={uuidV4()}>
      <div
        className="o-draggable__list--container"
        style={{
          position: 'relative',
          display: 'block',
          height: list.length * VERTICAL_ITEM_SIZE,
        }}>
        {springs.map((animationProps, i) => (
          <animated.div key={i} style={animationProps}>
            <animated.div
              {...bind(i)}
              style={{
                flexGrow: '1',
                display: 'inline-flex',
                alignItems: 'center',
              }}>
              <span className="o-draggable__list--prefix">
                <FaGripVertical />
              </span>
              <span className="o-draggable__list--item" style={{ flexGrow: 1 }}>
                {truncate(list[i].value, 60, true)}
              </span>
            </animated.div>
            {useDefaultSuffix && (
              <span
                className="o-draggable__list--suffix"
                order={i}
                id={list[i].id}>
                <FiX
                  style={{ margin: '10', marginRight: '20px' }}
                  size="16"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const parent = e.currentTarget.parentNode
                    const currentId = parent.getAttribute('id')
                    const currentOrder = parent.getAttribute('order')
                    remove(currentId, currentOrder)
                  }}
                />
              </span>
            )}
            {suffix?.(list[i])}
          </animated.div>
        ))}
      </div>
      <div>
        <button
          className="button hollow secondary text-bold mb-1 mt-1"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            getDropdownItemsHandler()
            setShowSelector(true)
          }}>
          <FaPlus className="mr-1" />
          {buttonText}
        </button>
        {showSelector && (
          <SearchableDropdown
            placeholder="Search..."
            clickHandler={(item) => {
              add(item)
            }}
            loading={loading}
            hideHandler={() => setShowSelector(false)}
            notFoundTitle="Nothing to see here..."
            notFoundText="there is nothing (left) to add.."
            data={dropdownItems.filter((item) => {
              const ids = list.map(({ id }) => Number(id))
              return !ids.includes(Number(item.id))
            })}
          />
        )}
      </div>
    </Fragment>
  )
}

export default DraggableList
