/* eslint-disable global-require */
import React, { Fragment, useContext } from 'react'
import AppStoreLinks from './AppStoreLinks'
import arrow from '../../../../images/arrow-line.svg'
import bgImg from '../../../../images/product-line.png'
import LanguageContext from '../../utils/context/LanguageContext'

const HowToPlay = () => {
  const { translations: t } = useContext(LanguageContext)
  const createHowToList = () => {
    const howTo = t.sections.howToPlay || []
    return howTo.map((item, i) => {
      if (i === 0) {
        return (
          <Fragment key={i}>
            <div className="c-launcher__explanation-list cell small-12 large-auto">
              <div style={{ margin: '0 auto' }}>
                <div className="c-launcher__explanation-list--prefix">
                  {i + 1}
                </div>
                <div className="c-launcher__explanation-list--content shrink">
                  <h3>{item.title}</h3>
                  <p>{item.explanation}</p>
                </div>
              </div>
            </div>
            <div
              className="show-for-small-only"
              style={{ width: '100%', marginBottom: '30px' }}>
              <AppStoreLinks />
            </div>
          </Fragment>
        )
      }
      return (
        <div
          className="c-launcher__explanation-list cell small-12 large-auto"
          key={i}>
          <div style={{ margin: '0 auto' }}>
            <div className="c-launcher__explanation-list--prefix">{i + 1}</div>
            <div className="c-launcher__explanation-list--content shrink">
              <h3>{item.title}</h3>
              <p>{item.explanation}</p>
            </div>
          </div>
        </div>
      )
    })
  }

  const { titles } = t.sections
  return (
    <section id="howtoplay" className="grid-container fluid">
      <h2 className="c-launcher__page--heading">{titles.howtoplay}</h2>
      <div className="grid-x">
        <div
          id="explanation"
          className="cell grid-x medium-5 large-12"
          style={{ maxWidth: '1400px', margin: '0 auto' }}>
          {createHowToList()}
        </div>
        <div className="cell grid-x medium-auto large-12 no-margin show-for-medium c-launcher__page--container ">
          <div style={{ position: 'relative' }} className="cell large-1">
            <img
              src={arrow}
              alt="line"
              className="c-launcher__mobile-link--arrow "
            />
          </div>
          <div>
            <AppStoreLinks />
          </div>
          <div className="cell large-auto"></div>
          <div className=" show-for-large cell h-100 large-7 c-launcher__company--image--container ">
            <div
              className="c-launcher__company--image"
              style={{
                backgroundImage: `url("${bgImg}")`,
              }}></div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default HowToPlay
