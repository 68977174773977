import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import useShowVideoEditor from '../../../hooks/useShowVideoEditor'
import DataTooltip from '../../../../UI/DataTooltip'
import { useReactFlow } from 'reactflow'
import { setCentralNodeAndSelectIt } from '../../../helpers/nodeHelper'

const SceneToNumberLabel = ({ linkedNode, className }) => {
  const showVideoEditor = useShowVideoEditor()
  const reactFlow = useReactFlow()
  const handleClick = (e) => {
    e.stopPropagation()
    setTimeout(() => {
      setCentralNodeAndSelectIt(reactFlow, linkedNode, 1)
    }, 110)
  }
  return (
    <Link
      to={`/scenes/${linkedNode.data.id}${showVideoEditor ? '/video' : ''}`}
      className="cursor-pointer"
      onClick={handleClick}>
      <DataTooltip position="top" title={linkedNode.data.name}>
        <span
          className={`o-label o-label--stable-dark text-white text-small cursor-pointer ${className}`}>
          <FaArrowRight />
          &nbsp;
          {linkedNode.data.number}
        </span>
      </DataTooltip>
    </Link>
  )
}

export default SceneToNumberLabel
