import React, { useState } from 'react'
import { truncate } from '../../../../utils/helpers/javascript'
import scenarioBg from '../../../../../../images/default/scenario.png'
import Accordion from '../../../UI/Accordion'

const CourseDescription = ({ description }) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <p className="mb-3 mt-3">
      {showMore ? `${description} ` : `${truncate(description, 200, true)} `}
      {description.length > 200 && (
        <span
          className="text-underline cursor-pointer"
          onClick={() => setShowMore(!showMore)}>{`Read ${
          showMore ? 'less' : 'more'
        }`}</span>
      )}
    </p>
  )
}
const ScenarioCard = ({ scenario }) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <div key={scenario.id} className="cell small-6 mb-3">
      <div className="o-card--image container mb-2">
        <div className="aspect-ratio">
          <img src={scenario.imageUrl || scenarioBg} />
        </div>
      </div>

      <h3 className="mb-0">{scenario.name}</h3>
      <p>
        {showMore
          ? `${scenario.description} `
          : `${truncate(scenario.description, 200, true)} `}
        {scenario.description.length > 200 && (
          <span
            className="text-underline cursor-pointer"
            onClick={() => setShowMore(!showMore)}>{`Read ${
            showMore ? 'less' : 'more'
          }`}</span>
        )}
      </p>
    </div>
  )
}

const ProductContentTab = ({ product }) => {
  const [showMore, setShowMore] = useState(false)

  const accordionHeaders = product.courses.reduce((a, course) => {
    const { length } = course.scenarios
    a.push({
      title: course.name,
      suffix: length ? `${length} scenario${length === 1 ? '' : 's'}` : '',
    })
    return a
  }, [])

  const accordionContent = product.courses.reduce((a, course) => {
    a.push(
      <>
        <CourseDescription description={course.description} />
        <div className="grid-x grid-margin-x">
          {course.scenarios.map((scenario) => (
            <ScenarioCard key={scenario.id} scenario={scenario} />
          ))}
        </div>
      </>
    )
    return a
  }, [])
  return (
    <>
      <div className="grid-x grid-margin-x mb-3">
        <div className="cell" id="product-info">
          <h2 className="text-bold">{product.name}</h2>
          <p>
            {showMore
              ? product.description
              : truncate(product.description, 200, true)}
            {'  '}
            {product.description.length > 200 && (
              <>
                <span
                  className=" text-underline cursor-pointer"
                  onClick={() => setShowMore(!showMore)}>
                  Read {showMore ? 'less' : 'more'}
                </span>
              </>
            )}
          </p>
        </div>{' '}
      </div>

      {!!product.courses.length && (
        <div className="cell mb-3">
          <h3 className="text-bold">
            {product.courses.length} Course{product.courses.length > 1 && 's'}
          </h3>
          <Accordion
            id="product-content"
            headers={accordionHeaders}
            content={accordionContent}
          />
        </div>
      )}

      {!!product.scenarios.length && (
        <div className="cell mb-3">
          <h3 className="text-bold mb-3">
            {product.scenarios.length} Scenario
            {product.scenarios.length > 1 && 's'}
          </h3>
          {product.scenarios.length && (
            <div className="grid-x grid-margin-x">
              {product.scenarios.map((scenario) => (
                <ScenarioCard key={scenario.id} scenario={scenario} />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default ProductContentTab
