import React from 'react'
import { useQuery } from '@apollo/client'
import Header from './Header'

import { getScenarioWithAnswersQuery } from '../../../apollo/query/scenarios'
import withApollo from '../../../hooks/withApollo'
import Player from '../../UI/VideoPlayer/player'
import Sliders from './Sliders'
import Sidebar from './Sidebar'

import { SceneEditorProvider } from '../SceneEditorContext'
import { VideoProvider } from '../VideoContext'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { MediumProvider } from '../../Media/Modal/MediumContext'
import EditorControlsRow from './EditorControlsRow'

const VideoEditor = ({ scenarioID, sceneID, mediumID }) => {
  const {
    data: scenarioData,
    loading: loadingScenario,
    error: scenarioError,
  } = useQuery(getScenarioWithAnswersQuery, {
    variables: {
      id: scenarioID,
    },
  })

  if (loadingScenario)
    return (
      <Delay>
        <div className="c-video__editor--loading--page">
          <LoadingSpinner text="Loading video editor" />
        </div>
      </Delay>
    )

  if (scenarioError) return <h1>Oops.. Something went wrong </h1>

  if (!scenarioData) return <p>No data</p>

  return (
    <SceneEditorProvider sceneID={sceneID}>
      <VideoProvider>
        <MediumProvider>
          <div className="c-video__editor--layout">
            <Header
              scenes={scenarioData.scenarios[0].scenes}
              scenarioID={scenarioData.scenarios[0].id}
              scenarioName={scenarioData.scenarios[0].name}
            />
            <div className="c-video__editor--container">
              <div className="c-video__editor--main">
                <div>
                  <div className="c-video__editor--player">
                    <div className="c-video__editor--player--video min-h">
                      <Player
                        mediumID={mediumID}
                        showControls={false}
                        scenarioData={scenarioData.scenarios[0]}
                      />
                    </div>
                  </div>
                </div>
                <div className="c-video__editor--bottom-controls">
                  <div className="c-video__editor--bottom-controls--sliders">
                    <EditorControlsRow />
                    <Sliders />
                  </div>
                </div>
                <div className="c-video__editor--sidebar">
                  <div className="scroll-wrapper">
                    <div className="scroll-container">
                      <Sidebar
                        scenes={scenarioData.scenarios[0].scenes}
                        scenarioId={scenarioID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediumProvider>
      </VideoProvider>
    </SceneEditorProvider>
  )
}
export default withApollo(VideoEditor)
