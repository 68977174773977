import React, { useRef, useState, useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { FaCheck, FaEllipsisH } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import { updateMediumMutation } from '../../../../apollo/query/media'
import Dropdown from '../../../UI/Menu/Dropdown'
import { MediumContext } from '../MediumContext'

const MediumTitle = ({ setBlockNavigation }) => {
  const {
    medium: [medium],
  } = useContext(MediumContext)
  const [edit, setEdit] = useState(false)
  const [updateMedium] = useMutation(updateMediumMutation, {})
  const titleRef = useRef()

  const blockNavigation = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation()
      e.preventDefault()
      titleRef.current.innerText = medium.name
      setEdit(false)
    }
    if (e.key === 'ArrowLeft') {
      e.stopPropagation()
    }
    if (e.key === 'ArrowRight') {
      e.stopPropagation()
    }
  }

  useEffect(() => {
    if (edit) {
      setBlockNavigation(true)
      document.addEventListener('keydown', blockNavigation, true)
    }
    return () => {
      setBlockNavigation(false)
      document.removeEventListener('keydown', blockNavigation, true)
    }
  }, [edit])

  const updateTitle = () => {
    if (titleRef.current.innerText !== medium.name) {
      updateMedium({
        variables: {
          name: titleRef.current.innerText,
          id: medium.id,
          trimStart: medium.trimStart,
          trimEnd: medium.trimEnd,
          trimEnabled: medium.trimEnabled,
        },
      })
    }
    if (edit) setEdit(false)
  }

  const selectionHandler = (clear) => {
    const selection = window.getSelection()
    if (clear) return selection.removeAllRanges()

    if (!window.getSelection().toString()) {
      const range = document.createRange()
      range.selectNodeContents(titleRef.current)
      selection.removeAllRanges()
      selection.addRange(range)
    }

    // fix to ensure focus is set
    return setTimeout(() => {
      if (titleRef.current) {
        titleRef.current.focus()
      }
    }, 0)
  }

  return (
    <div
      data-testid="medium-title"
      className="grid-x border-bottom"
      style={{ paddingRight: '54px' }}>
      <div className="cell auto grid-x o-modal--header  o-modal--header--hoverable">
        <h2
          id="title"
          suppressContentEditableWarning={true}
          data-testid="title-header"
          onClick={() => {
            setEdit(true)
            selectionHandler()
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              updateTitle()
            }
          }}
          spellCheck={false}
          contentEditable={edit}
          className={`text-bold cell auto ${edit ? 'editing' : ''}`}
          ref={titleRef}>
          {medium.name}
        </h2>
        {edit && (
          <div data-testid="buttons" className="cell shrink">
            <button
              data-testid="success-button"
              className="button mb-0 primary ml-1"
              onClick={updateTitle}>
              <FaCheck />
            </button>
            <button
              data-testid="cancel-button"
              onClick={() => {
                titleRef.current.innerText = medium.name
                setEdit(false)
              }}
              className="button hollow secondary mb-0 ml-1">
              <FiX />
            </button>
          </div>
        )}

        <Dropdown
          offset={{ top: '2px', left: '20px' }}
          alignment="right"
          id="download-file"
          button={
            <button
              className="ml-1 mb-0 button hollow secondary"
              data-open="download-file">
              <FaEllipsisH />
            </button>
          }>
          <li
            data-testid="download-file"
            className={`o-dropdown__list-item text-normal`}
            onClick={(e) => {
              e.preventDefault()
              window.location.href = medium.originalUrl
            }}>
            Download original file
          </li>
        </Dropdown>
      </div>
    </div>
  )
}

export default MediumTitle
