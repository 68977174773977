import React from 'react'

const EmptyPage = ({ type, icon, linktext, clickHandler }) => {
  return (
    <>
      <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
        <span className="text-center">
          <span data-testid="empty-page-icon" className="o-table__empty--icon">
            {icon}
          </span>
          <h2
            data-testid="type-title"
            className="text-bold text-dark">{`No ${type} yet!`}</h2>
          <span
            data-testid="link-handler"
            className="text-normal text-stable-dark text-underline cursor-pointer"
            onClick={clickHandler}>
            {linktext}
          </span>
        </span>
      </div>
    </>
  )
}
export default EmptyPage
