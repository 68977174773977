import React, { useEffect, useState } from 'react'
import { FaShieldAlt } from 'react-icons/fa'
import ConfigAuthenticatorDetail from './ConfigAuthenticatorDetail'

const ConfigAuthenticator = ({ currentUser }) => {
  const [otpConfigured, setOtpConfigured] = useState()
  useEffect(() => {
    if (currentUser) setOtpConfigured(currentUser.otpConfigured)
  }, [currentUser.otpConfigured])

  if (!currentUser) return <></>

  const handleChangeConfiguation = (newOtpConfigured) => {
    setOtpConfigured(newOtpConfigured)
  }

  return (
    <>
      {currentUser.otpRequired && (
        <div className="border-royal p-2 flex-container align-top border-radius text-royal text-normal mb-3">
          <FaShieldAlt style={{ marginTop: '3px' }} />
          <span className="ml-1 text-dark">
            Two-factor authentication can’t be disabled because you belong to an
            organization that requires it.
          </span>
        </div>
      )}
      <label>
        Authenticator app{' '}
        {otpConfigured && (
          <span className="o-label o-label--success text-white text-small">
            CONFIGURED
          </span>
        )}
      </label>
      <p className="mb-1">
        Use an authentication app or browser extension to get two-factor
        authentication codes when prompted.
      </p>
      <ConfigAuthenticatorDetail
        otpConfigured={otpConfigured}
        otpConfiguredAt={currentUser.otpConfiguredAt}
        otpRequired={currentUser.otpRequired}
        onChangeConfiguation={handleChangeConfiguation}
        currentUser={currentUser}
      />
    </>
  )
}

export default ConfigAuthenticator
