import React from 'react'
import { FaChevronDown, FaColumns } from 'react-icons/fa'
import Dropdown from './Menu/Dropdown'

const TableColumnsDropdown = ({
  content,
  icon = <FaColumns style={{ marginRight: '8px' }} />,
}) => (
  <span className="mr-1">
    <Dropdown
      id="column-selector"
      alignment="right"
      offset={{ top: '2px', left: '2px' }}
      button={
        <button
          data-testid="dropdownbutton"
          data-open="column-selector"
          className="mb-0 hollow button secondary">
          {icon}
          <FaChevronDown style={{ paddingTop: '2px', color: '#333' }} />
        </button>
      }>
      {content()}
    </Dropdown>
  </span>
)
export default TableColumnsDropdown
