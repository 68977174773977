/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const TraineeOptionDropdown = ({
  editHandler,
  deleteHandler,
  isTrainee,
  setCurrentTrainee,
  isOwner,
  id,
  isRestricted,
  isAnonymous,
  isMe,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { visit } = window.Turbolinks

  const showEditTraineeOption = () => {
    if (isAnonymous) {
      return (
        <li className="o-dropdown__list-item disabled text-normal">
          Edit trainee
          <small className="float-right">
            <label
              className="o-label--item__disabled"
              style={{ fontWeight: '300' }}>
              Anonymous
            </label>
          </small>
        </li>
      )
    }
    if (isRestricted) {
      return (
        <li className="o-dropdown__list-item disabled text-normal">
          Edit trainee
          <small className="float-right">
            <label
              className="o-label--item__disabled"
              style={{ fontWeight: '300' }}>
              LMS managed
            </label>
          </small>
        </li>
      )
    }
    return (
      <li
        className="o-dropdown__list-item text-normal text-dark"
        data-open="edit-trainee-modal"
        onClick={editHandler}>
        Edit trainee
      </li>
    )
  }
  const showDeleteTraineeOption = () => {
    if (isMe)
      return (
        <li className="o-dropdown__list-item text-normal disabled">
          Delete trainee
        </li>
      )
    if (isOwner)
      return (
        <li className="o-dropdown__list-item text-normal disabled">
          Delete trainee{' '}
          <small className="float-right">
            <label
              className="o-label--item__disabled"
              style={{ fontWeight: '300' }}>
              OWNER
            </label>
          </small>
        </li>
      )
    return (
      <li
        className="o-dropdown__list-item text-normal text-dark"
        data-open="delete-trainee-modal"
        onClick={deleteHandler}>
        Delete trainee
      </li>
    )
  }

  if (isHovered) {
    return (
      <Dropdown
        id={`user-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`user-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <>
          {showEditTraineeOption()}
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => visit(`activity?tab=attempts&userIds=${id}`)}>
            View training attempts
          </li>
          {!isAnonymous && (
            <>
              <hr className="mb-1 mt-1"></hr>
              {isTrainee && !isRestricted && (
                <>
                  <li
                    className="o-dropdown__list-item text-normal text-dark"
                    onClick={setCurrentTrainee}
                    data-open="get-trainee-code-modal">
                    Help trainee to sign in Warp VR App
                  </li>
                  <hr className="mb-1 mt-1"></hr>
                </>
              )}
              {showDeleteTraineeOption()}
            </>
          )}
        </>
      </Dropdown>
    )
  }
  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`user-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default TraineeOptionDropdown
