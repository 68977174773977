import React, { useEffect, useState } from 'react'
import Modal from '../UI/Modal'
import { FaThumbsUp } from 'react-icons/fa'

const UpdateCredentialModal = ({
  id,
  appendTo,
  onSubmit,
  selectedCredential,
  showSuccessNotice,
}) => {
  const [values, setValues] = useState({ nickname: '' })
  useEffect(() => {
    if (selectedCredential) setValues(selectedCredential)
  }, [selectedCredential])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(values)
  }

  return (
    <Modal id={id} appendTo={appendTo} headerText="Add passkey">
      <form className="mt-3" onSubmit={handleSubmit}>
        {showSuccessNotice && (
          <div className="border-energized p-2 flex-container align-middle border-radius text-energized text-normal mb-2">
            <FaThumbsUp style={{ marginTop: '3px' }} />
            <span className="ml-1 text-dark">Passkey successfully added</span>
          </div>
        )}
        <label htmlFor="add-cred-title" className="c-form__label--required">
          Give passkey a name
        </label>
        <input
          required
          type="text"
          value={values.nickname}
          name="nickname"
          id="add-cred-title"
          onChange={({ target }) =>
            setValues({ ...values, nickname: target.value })
          }
        />

        <div className="flex-container align-right mt-3">
          <button
            className="button primary wide"
            type="submit"
            disabled={!values.nickname}>
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  )
}
export default UpdateCredentialModal
