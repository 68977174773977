import React, { useEffect } from 'react'

const PlayerRedirect = ({ path }) => {
  let playerSubdomain

  switch (window.location.host) {
    case 'app.warp.studio':
      playerSubdomain = 'play'
      break
    case 'staging.warp.studio':
      playerSubdomain = 'player-staging'
      break
    default:
      playerSubdomain = 'test'
  }

  useEffect(() => {
    window.location.href = `https://${playerSubdomain}.warpvr.com/${path}`
  }, [])
}

export default PlayerRedirect
