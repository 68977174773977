import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Table from '../../Table'
import LoadingSpinner from '../../UI/LoadingSpinner'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import { dateFormat, sortOnKey } from '../../../utils/format'
import TableHeader from '../../UI/TableHeader'
import { filterColumnsHandler, sortHandler } from '../../tableFunctions'
import TableColumnsDropdown from '../../UI/TableColumnsDropdown'
import { default as S } from '../../../utils/lang/en.json'
import Checkbox from '../../UI/Form/Checkbox'
import { getAllPlanHistories } from '../../../apollo/query/planHistories'
import { FaArrowRight, FaImage } from 'react-icons/fa'
import TableSearchBar from '../../UI/Table/TableSearchBar'
import FilterEmptyState from '../../UI/Menu/FilterEmptyState'

const tableHeaders = [
  'name',
  'id',
  'createdAt',
  'planChange',
  'periodChange',
  'currencyChange',
  'additionalPublishedScenariosChange',
  'additionalWorkspacesChange',
]

const PlanHistories = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'createdAt',
    },
    filteredColumns: [],
  })

  // Data & render states
  const { loading, data } = useQuery(getAllPlanHistories)

  const tableLeftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        setSearchQuery={setSearchQuery}
      />
    </>
  )

  const tableRightBar = () => {
    const content = () => {
      return tableHeaders.map((item, i) => {
        const disabled = i === 0
        return (
          <li
            key={i}
            className={`mb-0 text-normal o-dropdown__list-item ${
              disabled ? 'disabled' : ''
            }`}
            onClick={(e) => {
              if (!disabled) {
                e.preventDefault()
                filterColumnsHandler(item, preferences, setPreferences)
              }
            }}>
            <Checkbox
              style={{ marginBottom: '0px', cursor: 'default' }}
              disabled={disabled}
              checked={!preferences.filteredColumns.includes(item)}
              value={S.table.planHistories.headers[item]}
            />
          </li>
        )
      })
    }

    return <TableColumnsDropdown content={content} />
  }

  const renderBillingPeriod = (v) => v ? S.dataTypes.transform.billing_period[v] : '—'
  const getArrowRight = () => (
    <>
      &nbsp;
      <FaArrowRight size={8} />
      &nbsp;
    </>
  )

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    let rows = data.allPlanHistories

    if (searchQuery.length)
      rows = rows.filter(({ client }) => {
        return client.name.toLowerCase().includes(searchQuery.toLowerCase())
      })

    if (!rows.length && !showEmptyState) setShowEmptyState(true)
    if (rows.length && showEmptyState) setShowEmptyState(false)

    rows = rows.map((planHistory) => ({
      name: planHistory.client.name,
      id: planHistory.client.id,
      createdAt: {
        value: planHistory.createdAt,
        render: dateFormat(planHistory.createdAt),
      },
      planChange: {
        value: `${planHistory.planChange.changeFrom.name}-${planHistory.planChange?.changeTo?.name}`,
        render: (
          <>
            {planHistory.planChange.changeFrom.name}
            {planHistory.planChange.changeFrom.name &&
              planHistory.planChange.changed && (
                <>
                  {getArrowRight()}
                  <span className="text-assertive">
                    {planHistory.planChange.changeTo.name ? planHistory.planChange.changeTo.name : 'Managed'}
                  </span>
                </>
              )}
          </>
        ),
      },
      periodChange: {
        value: `${planHistory.periodChange.changeFrom}-${planHistory.periodChange.changeTo}`,
        render: (
          <>
            {renderBillingPeriod(planHistory.periodChange.changeFrom)}
            {planHistory.periodChange.changeFrom &&
              planHistory.periodChange.changed && (
                <>
                  {getArrowRight()}
                  <span className="text-assertive">
                    {renderBillingPeriod(planHistory.periodChange.changeTo)}
                  </span>
                </>
              )}
          </>
        ),
      },
      currencyChange: {
        value: `${planHistory.currencyChange.changeFrom}-${planHistory.currencyChange.changeTo}`,
        render: (
          <>
            {planHistory.currencyChange.changeFrom}
            {planHistory.currencyChange.changeFrom &&
              planHistory.currencyChange.changed && (
                <>
                  {getArrowRight()}
                  <span className="text-assertive">
                    {planHistory.currencyChange.changeTo ? planHistory.currencyChange.changeTo : '—'}
                  </span>
                </>
              )}
          </>
        ),
      },
      additionalPublishedScenariosChange: {
        value: `${planHistory.additionalPublishedScenariosChange.changeFrom}-${planHistory.additionalPublishedScenariosChange.changeTo}`,
        render: (
          <>
            {planHistory.additionalPublishedScenariosChange.changeFrom}
            {planHistory.additionalPublishedScenariosChange.changeFrom !==
              null &&
              planHistory.additionalPublishedScenariosChange.changed && (
                <>
                  {getArrowRight()}
                  <span className="text-assertive">
                    {planHistory.additionalPublishedScenariosChange.changeTo ? planHistory.additionalPublishedScenariosChange.changeTo : '—'}
                  </span>
                </>
              )}
          </>
        ),
      },
      additionalWorkspacesChange: {
        value: `${planHistory.additionalWorkspacesChange.changeFrom}-${planHistory.additionalWorkspacesChange.changeTo}`,
        render: (
          <>
            {planHistory.additionalWorkspacesChange.changeFrom}
            {planHistory.additionalWorkspacesChange.changeFrom !== null &&
              planHistory.additionalWorkspacesChange.changed && (
                <>
                  {getArrowRight()}
                  <span className="text-assertive">
                    {planHistory.additionalWorkspacesChange.changeTo ? planHistory.additionalWorkspacesChange.changeTo : '—'}
                  </span>
                </>
              )}
          </>
        ),
      },
    }))

    rows = sortOnKey([...rows], value, isAscending)

    return rows
  }

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  return (
    <div id="planHistories">
      <div className="grid-container fluid">
        <TableHeader
          title="Plan history"
          leftBar={tableLeftBar()}
          rightBar={tableRightBar()}
        />
      </div>
      <div
        className="o-table--page-container grid-container fluid"
        style={{ top: '130px' }}>
        {showEmptyState ? (
          <FilterEmptyState
            type="plan histories"
            icon={<FaImage />}
            clickHandler={() => {
              setSearchQuery('')
              setShowEmptyState(false)
            }}
          />
        ) : (
          <Table
            data={sortedData()}
            placeholderRows={1}
            sortOn={preferences.sortOn}
            sortHandler={(value) => {
              sortHandler(value, preferences, setPreferences)
            }}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            type="planHistories"
          />
        )}
      </div>
    </div>
  )
}

export default withApollo(PlanHistories)
