import React from 'react'
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from '.'
import { getHexOpacityArray } from '../../../utils/helpers/color'
import colors from '../../Editor/helpers/colors'

const PIE_PROPS = {
  dataKey: 'value',
  nameKey: 'name',
  cx: '50%',
  cy: '50%',
  innerRadius: 91,
  outerRadius: 113,
}

const PieGraph = ({ data }) => {
  const getCells = () => {
    const hexOpacityArray = getHexOpacityArray(data.length)
    return data.map((entry, index) => {
      return (
        <Cell
          key={`cell-${index}`}
          fill={`${colors.assertive}${hexOpacityArray[index]}`}
        />
      )
    })
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="border-radius p-1-5 text-white text-normal flex-container align-justify align-middle"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
          <div>{payload[0].name}:</div>
          <div className="text-bold ml-1">{payload[0].value}</div>
        </div>
      )
    }

    return null
  }

  const renderPie = () => {
    if (data.length === 0)
      return <Pie data={[{ name: '', value: 1 }]} {...PIE_PROPS} fill="#DDD" />
    return (
      <>
        <Pie startAngle={-630} data={data} {...PIE_PROPS}>
          {getCells()}
        </Pie>

        <Tooltip content={<CustomTooltip />} cursor={{ fill: '#ddd' }} />
      </>
    )
  }

  return (
    <ResponsiveContainer width={'99.9%'} height={230}>
      <PieChart>{renderPie()}</PieChart>
    </ResponsiveContainer>
  )
}

export default PieGraph
