import React, { useState } from 'react'
import Modal from '../../../UI/Modal'
import NumberInput from '../../../UI/Form/NumberInput'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import RadioButton from '../../../UI/Form/RadioButton'

const RandomizerModal = ({ appendTo }) => {
  const [element, setElement] = useState()
  const [randomizedCount, setRandomizedCount] = useState()
  const { updateElement } = useAddUpdateElement()

  const handleSubmit = () => {
    const diff = randomizedCount - element.randomizedLinkToIds.length
    updateElement({
      variables: {
        ...element,
        linkToId: null,
        linkToEnding: false,
        randomizedLinkToIds: [
          ...element.randomizedLinkToIds.slice(0, randomizedCount),
          ...(diff > 0 ? Array(diff).fill('') : []),
        ],
      },
    })
  }

  return (
    <Modal
      id="randomizer-modal"
      appendTo={appendTo}
      headerText="Randomizer answer"
      useDefaultButtons
      textAssertive
      submitHandler={handleSubmit}
      width={400}
      onClose={() => setRandomizedCount(0)}
      onOpen={(e) => {
        const element = $(e.target).data().element
        setElement(element)
        setRandomizedCount(element.randomizedLinkToIds.length)
      }}>
      <form className="mt-3 mb-1" onSubmit={handleSubmit}>
        <div className="o-form__group">
          <label className="o-form__label">Randomize this answer</label>
          <div>
            <RadioButton
              id="radio-randomizer-no"
              label="No"
              checked={!randomizedCount}
              clickHandler={() => {
                setRandomizedCount(0)
              }}
              name="randomizedLinkToIds"
              labelClassName="ml-0"
            />
          </div>
          <RadioButton
            id="radio-randomizer-yes"
            label="Yes"
            checked={!!randomizedCount}
            clickHandler={() => {
              setRandomizedCount(
                element.randomizedLinkToIds.length
                  ? element.randomizedLinkToIds.length
                  : 2
              )
            }}
            name="randomizedLinkToIds"
            value="2"
            labelClassName="ml-0"
          />
        </div>

        {!!randomizedCount && (
          <div className="o-form__group mt-3">
            <label className="o-form__label">
              Number of scenes to select randomly from
            </label>
            <div className="flex-container align-middle">
              <NumberInput
                value={randomizedCount}
                changeHandler={setRandomizedCount}
                step={1}
                min={1}
              />
              <span className="ml-1">scenes</span>
            </div>
          </div>
        )}
      </form>
    </Modal>
  )
}
export default RandomizerModal
