const { AFRAME } = window

AFRAME.registerComponent('mpc', {
  schema: {
    elementSize: { default: 'NORMAL' },
    answerElementSize: { default: 'NORMAL' },
  },
  positionButtons() {
    const buttons = Array.from(this.self.children).reverse()
    for (const button of buttons) {
      const { height } = button.getAttribute('geometry')
      const positionY = this.currentHeight + height / 2 + this.spacing
      button.setAttribute('position', `0 ${positionY} 0`)

      this.currentHeight += height + this.spacing
    }
  },
  childLoading(child) {
    if (child.getAttribute('geometry')) {
      const { height } = child.getAttribute('geometry')
      if (height) this.childrenLoaded += 1
    }

    if (this.childrenLoaded === this.el.children.length) {
      this.doneLoading = true
      this.positionButtons()
    }
  },
  update() {
    if (this.doneLoading) {
      this.childrenLoaded = 0
      this.currentHeight = 0
      for (const child of this.self.children) {
        this.childLoading(child)
      }
    }
    let x = this.data.elementSize === 'WIDE' ? 3.405 : 2.45
    const y = this.data.answerElementSize === 'WIDE' ? 0.21 : 0.19
    if (this.data.answerElementSize === 'WIDE') x += 1.4
    if (this.data.elementSize === 'WIDE') x += 0.05
    this.self.setAttribute('position', `${x} ${y} 0`)
  },
  init() {
    this.self = this.el
    this.currentHeight = 0 // sets starting height for buttons in mpclist
    this.spacing = 0.075 // space between buttons
    this.childrenLoaded = 0 // counter to verify how many children have height
    this.doneLoading = false // ensure al children are attached and loaded

    this.self.setAttribute('rotation', '0 -24 0')

    this.self.addEventListener('update-required', () => this.update())

    this.self.addEventListener('child-attached', (e) => {
      // Required listener for navigation prev / next scene
      e.detail.el.addEventListener('button-loaded', () => {
        this.childLoading(e.detail.el)
      })
    })

    this.self.addEventListener('loaded', () => {
      // Required listener for refreshing page
      for (const child of this.self.children) {
        child.addEventListener('button-loaded', () => {
          this.childLoading(child)
        })
      }
    })

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes.length > 0) this.onChildAdded()
          if (mutation.removedNodes.length > 0) this.onChildRemoved()
        }
      }
    })

    observer.observe(this.self, { childList: true })
  },

  onChildAdded() {
    this.update()
  },

  onChildRemoved() {
    this.update()
  },
})
