import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../../UI/Modal'
import { updateProductMutation } from '../../../../apollo/query/products'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'

const PublishProductModal = ({ id, appendTo, product, callback }) => {

  const [publishProduct] = useMutation(updateProductMutation, {
    onError: handleApolloError,
    onCompleted: () => addSuccessAlert({
      title: `${product.name} published succesfully`,
      subtitle: 'This product is in the Warp Store.',
    }),
  })

  const submit = () => {
    const vars = { ...product }
    const courseIds = vars.courses.map(({ course }) => Number(course.id))
    const scenarioIds = vars.scenarios.map(({ scenario }) => Number(scenario.id))
    delete vars.courses
    publishProduct({
      variables: {
        ...vars,
        courseIds,
        scenarioIds,
        state: 'READY',
      },
    })
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      closable
      headerText="Publish to the Warp Store"
      cancelHandler={() => setTimeout(() => callback(), 300)}
      submitHandler={submit}
      subtitle="Change status from ‘Prepared for sale’ to ‘In Warp Store’">
      <p className="mt-3">
        When publishing this product to the Warp Store, it is made available for
        purchase. Changing to this state is irreversible.
      </p>
    </Modal>
  )
}

export default PublishProductModal
