import React, { useState, useEffect } from 'react'
import { getRotation } from '../../helpers/controls'
import TextBubble from './partials/TextBubble'
import Button from './partials/Button'

const MultipleChoice = ({ id, scene, state }) => {
  const [loading, setLoading] = useState(true)
  const [buttons, setButtons] = useState([])

  let BOX_WIDTH = 2
  let BUBBLE_WIDTH = 1.8
  let BUTTON_WIDTH = 2.75
  if (scene.elementSize === 'WIDE') {
    BUBBLE_WIDTH *= 2
    BOX_WIDTH *= 2
  }
  if (scene.answerElementSize === 'WIDE') BUTTON_WIDTH *= 2

  const getMpcAnswers = () => {
    const btns = scene.answers.map((answer, index) => {
      return (
        <Button
          type="mpc"
          key={`btn-${index}`}
          textValue={answer.name}
          id={`Answer-${index}`}
          width={BUTTON_WIDTH}
        />
      )
    })
    setButtons(btns)
    if (loading && btns) setLoading(false)
  }
  useEffect(() => {
    if (scene) {
      setLoading(true)
      getMpcAnswers()
    }
  }, [scene])

  return (
    <a-entity
      className={state.showElements ? '' : 'hidden'}
      hideable-element={`visible: ${state.showElements}`}
      rotation={`${getRotation({ x: scene.anchorX, y: scene.anchorY })} 0`}>
      <a-collision-box id={id} rotation="0 5 0" width={BOX_WIDTH}>
        <TextBubble
          type="mpc"
          textValue={scene.question}
          id="sceneQuestion"
          width={BUBBLE_WIDTH}
          bottomSpacing={0.1}
        />
        <a-entity
          id="mpc-list"
          mpc={`elementSize: ${scene.elementSize}; answerElementSize: ${scene.answerElementSize}`}>
          {buttons}
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default MultipleChoice
