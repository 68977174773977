import { gql } from '@apollo/client'

const getCoursesQuery = gql`
  query courses {
    courses {
      name
      description
      id
      createdAt
      publishedScenarios {
        index
        scenario {
          name
          id
        }
      }
    }
  }
`

const addCourseMutation = gql`
  mutation addCourse($name: String!, $description: String!) {
    addCourse(name: $name, description: $description) {
      course {
        id
        name
        description
        createdAt
      }
    }
  }
`

const getPurchasedCoursesQuery = gql`
  query purchasedCourses {
    purchasedCourses {
      id
      name
    }
  }
`

const updateCourseMutation = gql`
  mutation updateCourse($id: ID!, $name: String!, $description: String!) {
    updateCourse(id: $id, name: $name, description: $description) {
      course {
        id
        name
        description
        createdAt
      }
    }
  }
`

const updateCourseScenariosMutation = gql`
  mutation updateCourseScenarios($id: ID!, $publishedScenarioIds: [Int!]!) {
    updateCourseScenarios(
      id: $id
      publishedScenarioIds: $publishedScenarioIds
    ) {
      course {
        id
        publishedScenarios {
          index
          scenario {
            name
            id
          }
        }
      }
    }
  }
`

const deleteCourseMutation = gql`
  mutation removeCourse($id: ID!) {
    removeCourse(id: $id) {
      id
    }
  }
`
export {
  getCoursesQuery,
  getPurchasedCoursesQuery,
  addCourseMutation,
  updateCourseMutation,
  updateCourseScenariosMutation,
  deleteCourseMutation,
}
