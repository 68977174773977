import React from 'react'
import { useMutation } from '@apollo/client'
import { handleApolloError } from '../../utils/errors'
import { addSuccessAlert } from '../../utils/helpers/alerts'
import { removeWorkspaceMutation } from '../../apollo/query/workspaces'
import Modal from '../UI/Modal'

const DeleteWorkspaceModal = ({
  id,
  appendTo,
  workspaceId,
  submitCallback,
  cancelCallback,
}) => {
  const [deleteWorkspace] = useMutation(removeWorkspaceMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({
        icon: 'success',
        title: `Workspace successfully deleted`,
      })
      submitCallback()
    },
  })

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this workspace?`}
      subtitle="Any scenarios, courses, trainees and attempt data in this workspace will also be deleted. This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={cancelCallback}
      submitHandler={() => deleteWorkspace({ variables: { id: workspaceId } })}
    />
  )
}
export default DeleteWorkspaceModal
