import React, { useState } from 'react'
import { FaWallet, FaExchangeAlt } from 'react-icons/fa'
import { useQuery } from '@apollo/client'
import TableHeader from '../../components/UI/TableHeader'
import TableColumnsDropdown from '../../components/UI/TableColumnsDropdown'
import PurchaseOptionDropdown from '../../components/Store/PurchaseOptionDropdown'
import {
  sortHandler,
  filterColumnsHandler,
} from '../../components/tableFunctions'
import usePreferences from '../../hooks/usePreferences'
import Table from '../../components/Table'
import withApollo from '../../hooks/withApollo'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { getSellerTransactionsQuery } from '../../apollo/query/transactions'
import Checkbox from '../../components/UI/Form/Checkbox'

import { default as S } from '../../utils/lang/en.json'
import { sortOnKey, dateFormat, convertValuta } from '../../utils/format'
import TableSearchBar from '../../components/UI/Table/TableSearchBar'
import TableFilters from '../../components/UI/Table/TableFilters'
import TableAddButton from '../../components/UI/Table/TableAddButton'
import AddTransactionModal from '../../components/Store/Transactions/Modal/AddTransactionModal'
import CancelSubscriptionModal from '../../components/Store/Transactions/Modal/CancelSubscriptionModal'
import { adminGetAllPurchasesQuery } from '../../apollo/query/admin/store'
import Delay from '../../utils/helpers/Delay'
import FilterEmptyState from '../../components/UI/Menu/FilterEmptyState'

const Transactions = ({ adminPanel }) => {
  const [preferences, setPreferences] = usePreferences('transactions')
  const [showEmptyState, setShowEmptyState] = useState(false)

  const [currentPurchase, setCurrentPurchase] = useState(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [filterOption, setFilterOption] = useState({
    state: '',
    plan: '',
    licenseType: '',
  })

  const { data, loading } = useQuery(
    adminPanel ? adminGetAllPurchasesQuery : getSellerTransactionsQuery
  )

  if (loading || !data || !preferences)
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )

  let tableHeaders = [
    'productName',
    'buyingClientName',
    'productKind',
    'totalPrice',
    'productLicenseType',
    'productLicenseDuration',
    'purchasedLicenseCount',
    'usedLicenseCount',
    'subscriptionState',
    'createdAt',
  ]

  if (adminPanel) {
    tableHeaders = [
      'productName',
      'sellingClientName',
      'buyingClientName',
      'productKind',
      'totalPrice',
      'productLicenseType',
      'productLicenseDuration',
      'purchasedLicenseCount',
      'usedLicenseCount',
      'subscriptionState',
      'createdAt',
    ]
  }
  const sortedData = () => {
    let rows = adminPanel
      ? data.allPurchases.map((product) => ({
          ...product,
          sellingClientName: product.sellingClient.name,
          buyingClientName: product.buyingClient.name,
        }))
      : data.purchases.map((product) => ({
          ...product,
          buyingClientName: product.buyingClient.name,
        }))

    if (filterOption.state) {
      rows = rows.filter(({ state }) => state === filterOption.state)
    }
    if (filterOption.plan) {
      rows = rows.filter(({ productKind: plan }) => plan === filterOption.plan)
    }
    if (filterOption.licenseType) {
      rows = rows.filter(
        ({ productLicenseType }) =>
          productLicenseType === filterOption.licenseType
      )
    }
    const filtered = rows.filter((p) => {
      const filter = `${p.productName} ${p.buyingClient.name} ${p.sellingClient.name}`
      return filter.toLowerCase().includes(searchQuery.toLowerCase())
    })
    if (!filtered.length && !showEmptyState) setShowEmptyState(true)
    if (filtered.length && showEmptyState) setShowEmptyState(false)

    return sortOnKey(
      filtered,
      preferences.sortOn.value,
      preferences.sortOn.isAscending
    ).map((p) => ({
      id: p.id,
      productName: p.productName,

      sellingClientName: p.sellingClient.name,
      buyingClientName: p.buyingClient.name,
      productKind: {
        value: p.productKind,
        render: S.store.kind[p.productKind],
      },
      totalPrice: {
        value: p.totalPrice,
        render: convertValuta(p.totalPrice),
      },
      productLicenseType: {
        value: p.productLicenseType,
        render: S.store.licenseType[p.productLicenseType],
      },
      productLicenseDuration: {
        value: p.productLicenseDuration,
        render: `${p.productLicenseDuration} month${
          p.productLicenseDuration === 1 ? '' : 's'
        }`,
      },
      purchasedLicenseCount: p.purchasedLicenseCount,
      usedLicenseCount: p.usedLicenseCount,
      subscriptionState: {
        value: p.subscriptionState,
        render: p.subscriptionState
          ? S.store.subscription[p.subscriptionState]
          : '-',
      },
      createdAt: dateFormat(p.createdAt),
    }))
  }
  const loadModals = () => {
    const appendTo = '#transactions'

    return (
      <>
        <AddTransactionModal
          id="add-transaction-modal"
          appendTo={appendTo}
          adminPanel={adminPanel}
        />
        <CancelSubscriptionModal
          id="cancel-subscription-modal"
          appendTo={appendTo}
          purchaseId={currentPurchase}
          adminPanel={adminPanel}
        />
      </>
    )
  }

  const filters = [
    {
      text: 'Pricing plan',
      type: 'plan',
      options: S.store.kind,
    },
    {
      text: 'License type',
      type: 'licenseType',
      options: S.store.licenseType,
    },
  ]
  const leftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        setSearchQuery={setSearchQuery}
      />
      <TableFilters
        filters={filters}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
      />
    </>
  )
  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0

      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.transactions.headers[item]}
          />
        </li>
      )
    })
  }

  const renderOptions = (id) => {
    const { subscriptionState } = (
      adminPanel ? data.allPurchases : data.purchases
    ).find((i) => i.id === id)

    if (subscriptionState === 'ACTIVE') {
      return (
        <PurchaseOptionDropdown
          id={id}
          currentPurchaseHandler={(i) => setCurrentPurchase(i)}
        />
      )
    }
  }

  if (
    (adminPanel && !data.allPurchases.length) ||
    (!adminPanel && !data.purchases.length)
  ) {
    return (
      <div id="transactions">
        {loadModals()}
        <div className="grid-container fluid">
          <TableHeader
            length={0}
            title="Transaction"
            leftBar={leftBar()}
            rightBar={
              <>
                <TableColumnsDropdown content={content} />
                <TableAddButton
                  dataOpen="add-transaction-modal"
                  title="Add transaction"
                />
              </>
            }
          />
        </div>
        <div style={{ display: 'grid', placeItems: 'center', height: '80vh' }}>
          <span className="text-center">
            <span className="o-table__empty--icon ">
              <FaExchangeAlt />
            </span>
            <h2 className="text-bold text-dark" onClick={() => {}}>
              {`There are no transactions`}
            </h2>
          </span>
        </div>
      </div>
    )
  }
  return (
    <div id="transactions">
      {loadModals()}
      <div className="grid-container fluid">
        <TableHeader
          length={[...sortedData()].length}
          title="Transaction"
          leftBar={leftBar()}
          rightBar={
            <>
              <TableColumnsDropdown content={content} />
              <TableAddButton
                dataOpen="add-transaction-modal"
                title="Add transaction"
              />
            </>
          }
        />
      </div>

      <div className="o-table--page-container grid-container fluid">
        {showEmptyState ? (
          <FilterEmptyState
            type="transactions"
            icon={<FaWallet />}
            clickHandler={() => {}}
          />
        ) : (
          <Table
            data={sortedData()}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            sortOn={preferences.sortOn}
            sortHandler={(value) =>
              sortHandler(value, preferences, setPreferences)
            }
            type="transactions"
            rowSuffix={(i) => {
              return renderOptions(i)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default withApollo(Transactions)
