import React, { useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { addErrorAlert, addSuccessAlert } from '../../../utils/helpers/alerts'
import { changeOwnerMutation } from '../../../apollo/query/workspaces'
import { handleApolloError } from '../../../utils/errors'
import { getCurrentClientSettingsQuery } from '../../../apollo/query/settings'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useManagers from '../../../hooks/useManagers'

const OwnerTab = () => {
  const formRef = useRef()
  const [currentUser, , currentRole, refetchRole] = useCurrentUser()
  const { data: managers, error } = useManagers()
  const { data: currentClientData } = useQuery(getCurrentClientSettingsQuery)

  const [changeOwner, { loading: editLoading }] = useMutation(
    changeOwnerMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        addSuccessAlert({ title: 'Owner has been updated' })
        refetchRole()
      },
    }
  )

  if (!managers || !currentClientData || !currentRole) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const { currentClient } = currentClientData
  const canChangeOwner = currentUser?.administrator || currentRole === 'OWNER'

  const handleError = () => {
    addErrorAlert({
      title: 'Something went wrong',
      subtitle: 'Please try again.',
    })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const elements = formRef.current.elements
    try {
      changeOwner({
        variables: {
          newOwnerId: elements['newOwnerId'].value,
        },
      })
    } catch (ex) {
      handleError()
    }
  }

  return (
    <div className="row pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Workspace owner</h2>
        <p>
          Change the owner of this workspace. Owners can change the subscription
          and manage workspaces. Only owners can change this settings.
        </p>
      </div>
      <form
        ref={formRef}
        onSubmit={handleFormSubmit}
        className="medium-7 small-12 columns mt-0">
        <label>Owner</label>
        <select
          name="newOwnerId"
          required
          defaultValue={
            managers.find((m) => m.name === currentClient.ownerName).id
          }
          disabled={!canChangeOwner}>
          {managers.map((m) => (
            <option key={m.id} value={m.id}>
              {m.name}
            </option>
          ))}
        </select>

        <button
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal ${
            editLoading && 'o-button--loading'
          }`}
          disabled={!canChangeOwner || editLoading}
          aria-hidden="true">
          Confirm
        </button>
      </form>
    </div>
  )
}
export default withApollo(OwnerTab)
