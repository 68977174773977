import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import GroupsList from '../GroupsList'
import useThrottle from '../../../hooks/useThrottle'
import { handleApolloError } from '../../../utils/errors'
import { updateGroupMutation } from '../../../apollo/query/groups'
import { addSuccessAlert } from '../../../utils/helpers/alerts'

const BulkEditGroupModal = ({
  id,
  appendTo,
  purchasedCourses,
  purchasedScenarios,
  addContent,
  groups,
  callback,
}) => {
  const coursesRef = useRef()
  const scenariosRef = useRef()
  const [createQueue, createTask] = useThrottle()

  const [updateGroup] = useMutation(updateGroupMutation, {
    onError: handleApolloError,
  })

  const clear = () => {
    coursesRef.current = null
    scenariosRef.current = null
  }
  const submit = () => {
    if (addContent) {
      const newGroups = groups.reduce((a, g) => {
        const courseIds = [
          ...new Set([
            ...g.courses.map((c) => Number(c.id)),
            ...coursesRef.current.map((i) => Number(i)),
          ]),
        ]
        const publishedScenarioIds = [
          ...new Set([
            ...g.publishedScenarios.map((s) => Number(s.id)),
            ...scenariosRef.current.map((i) => Number(i)),
          ]),
        ]
        const newGroup = {
          courseIds,
          publishedScenarioIds,
          id: g.id,
          name: g.name,
          publicLinkEnabled: g.publicLinkEnabled,
          publicLinkDomains: g.publicLinkDomains,
          startDate: g.startDate,
          endDate: g.endDate,
        }
        a.push(newGroup)
        return a
      }, [])

      createQueue(
        newGroups.map((group) =>
          createTask(() => updateGroup({ variables: group }))
        )
      ).then(() => {
        addSuccessAlert({
          title: 'Group changes have been saved',
        })
        clear()
        callback()
      })
    } else {
      const newGroups = groups.reduce((a, g) => {
        const courseIds = g.courses.reduce((b, c) => {
          if (!coursesRef.current.includes(c.id)) {
            b.push(Number(c.id))
          }
          return b
        }, [])

        const publishedScenarioIds = g.publishedScenarios.reduce((b, s) => {
          if (!scenariosRef.current.includes(s.id)) {
            b.push(Number(s.id))
          }
          return b
        }, [])

        const newGroup = {
          courseIds,
          publishedScenarioIds,
          id: g.id,
          name: g.name,
          publicLinkEnabled: g.publicLinkEnabled,
          publicLinkDomains:g.publicLinkDomains,
          startDate: g.startDate,
          endDate: g.endDate,
        }
        a.push(newGroup)
        return a
      }, [])

      createQueue(
        newGroups.map((group) =>
          createTask(() => updateGroup({ variables: group }))
        )
      ).then(() => {
        addSuccessAlert({ title: 'Group changes have been saved' })
        clear()
        callback()
      })
    }
  }
  const cancel = () => {
    setTimeout(clear, 300)
  }

  const getGroupLists = () => {
    const grouplists = [
      {
        label: 'Courses',
        type: 'course',
        ref: coursesRef,
        list: [],
      },
      {
        label: 'Scenarios',
        type: 'scenario',
        ref: scenariosRef,
        list: [],
      },
    ]
    return grouplists.map((g, index) => (
      <div key={index} className="cell small-12">
        <label>{g.label}</label>
        <GroupsList
          type={g.type}
          purchasedItems={g.type === "course" ? purchasedCourses : purchasedScenarios}
          selectionRef={g.ref}
          currentList={g.list}
        />
      </div>
    ))
  }
  const title = () => {
    if (addContent) return 'Add courses and scenarios to groups'
    return 'Remove courses and scenarios from groups'
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      headerText={title()}
      footerText={
        addContent
          ? 'Trainees will be notified by email in case of new course or scenario access.'
          : ''
      }
      overflowY="visible"
      submitHandler={submit}
      cancelHandler={cancel}>
      <form className="mt-3">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">{getGroupLists()} </div>
        </div>
      </form>
    </Modal>
  )
}

export default BulkEditGroupModal
