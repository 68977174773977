import React from 'react'
import { FaChevronDown } from 'react-icons/fa'
import Dropdown from '../../../../UI/Menu/Dropdown'

const OPTIONS = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'WIDE', label: 'Wide' },
]

const SizeDropdown = ({
  element,
  options,
  label = 'Label size',
  onChange,
  name,
}) => {
  options = options || OPTIONS
  const handleChange = (e) => {
    const { labelSize } = e.target.dataset
    onChange(labelSize)
    $(`#${name}-menu-${element.id}`).foundation('close')
  }

  return (
    <Dropdown
      id={`${name}-menu-${element.id}`}
      alignment="right"
      offset={{ left: '20px', top: '0px' }}
      minWidth={200}
      button={
        <button
          className="o-button o-button--square-small o-button--secondary o-button--no-active o-button--no-hover  flex-container align-middle mb-0 pl-1 pr-1 w-auto"
          data-toggle={`${name}-menu-${element.id}`}>
          <span className="text-bold">
            {options.find((o) => o.value === element[name]).label}
          </span>
          <FaChevronDown className="ml-1 text-normal" />
        </button>
      }>
      <>
        <li className="o-dropdown__item o-dropdown__item--inactive text-small text-lighter text-stable-dark">
          {label}
        </li>
        {options.map((option) => (
          <li
            key={option.value}
            data-label-size={option.value}
            className={`o-dropdown__list-item text-normal flex-container align-middle 
              ${option.value === element[name] ? 'text-assertive' : 'text-dark'}`}
            onClick={handleChange}>
            {option.label}
          </li>
        ))}
      </>
    </Dropdown>
  )
}

export default SizeDropdown
