const useThrottle = (workers = 8) => {
  const createTask = (task) => () => {
    // Task should be a function
    return Promise.resolve(task())
  }
  const createQueue = (tasks, maxNumOfWorkers = workers) => {
    let numOfWorkers = 0
    let taskIndex = 0

    return new Promise((done) => {
      const handleResult = (index) => (result) => {
        tasks[index] = result
        numOfWorkers -= 1
        // eslint-disable-next-line no-use-before-define
        getNextTask()
      }

      const getNextTask = () => {
        if (numOfWorkers < maxNumOfWorkers && taskIndex < tasks.length) {
          tasks[taskIndex]()
            .then(handleResult(taskIndex))
            .catch(handleResult(taskIndex))
          taskIndex += 1
          numOfWorkers += 1
          getNextTask()
        } else if (numOfWorkers === 0 && taskIndex === tasks.length) {
          done(tasks)
        }
      }

      getNextTask()
    })
  }

  return [createQueue, createTask]
}

export default useThrottle
