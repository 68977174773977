import { gql } from '@apollo/client'

const getIntegrationsQuery = gql`
  query getIntegrations {
    integrations(onlyTrainees: false) {
      id
      name
      kind
      anonymous
    }
  }
`
const getIntegrationsWithScenariosQuery = gql`
  query getIntegrationsWithScenarios {
    integrations(onlyTrainees: true) {
      id
      name
      kind
      ltiKey
      ltiSecret
      ltiPath
      scenarios {
        name
        createdAt
        ltiPath
        scormPath
        purchased
      }
    }
  }
`

const addIntegrationMutation = gql`
  mutation addIntegration(
    $name: String!
    $kind: IntegrationKind!
    $anonymous: Boolean
    $ocnClientId: String
    $ocnClientSecret: String
    $ocnProviderId: String
    $ocnTokenEndpoint: String
    $ocnServiceEndpoint: String
    $ocnLaunchUrl: String
    $oauthScimEnabled: Boolean
    $oauthOrganizationApiEnabled: Boolean
  ) {
    addIntegration(
      name: $name
      kind: $kind
      anonymous: $anonymous
      ocnClientId: $ocnClientId
      ocnClientSecret: $ocnClientSecret
      ocnProviderId: $ocnProviderId
      ocnTokenEndpoint: $ocnTokenEndpoint
      ocnServiceEndpoint: $ocnServiceEndpoint
      ocnLaunchUrl: $ocnLaunchUrl
      oauthScimEnabled: $oauthScimEnabled
      oauthOrganizationApiEnabled: $oauthOrganizationApiEnabled
    ) {
      integration {
        id
        kind
        name
        anonymous
        ocnClientId
        ocnClientSecret
        ocnProviderId
        ocnTokenEndpoint
        ocnServiceEndpoint
        ocnLaunchUrl
        apiKey
        oauthClientId
        oauthClientSecret
        oauthScimEnabled
        oauthOrganizationApiEnabled
        createdAt
      }
    }
  }
`

const updateIntegrationMutation = gql`
  mutation updateIntegration(
    $id: ID!
    $name: String!
    $anonymous: Boolean
    $ocnClientId: String
    $ocnClientSecret: String
    $ocnProviderId: String
    $ocnTokenEndpoint: String
    $ocnServiceEndpoint: String
    $ocnLaunchUrl: String
    $oauthScimEnabled: Boolean
    $oauthOrganizationApiEnabled: Boolean
  ) {
    updateIntegration(
      id: $id
      name: $name
      anonymous: $anonymous
      ocnClientId: $ocnClientId
      ocnClientSecret: $ocnClientSecret
      ocnProviderId: $ocnProviderId
      ocnTokenEndpoint: $ocnTokenEndpoint
      ocnServiceEndpoint: $ocnServiceEndpoint
      ocnLaunchUrl: $ocnLaunchUrl
      oauthScimEnabled: $oauthScimEnabled
      oauthOrganizationApiEnabled: $oauthOrganizationApiEnabled
    ) {
      integration {
        id
        kind
        name
        anonymous
        ocnClientId
        ocnClientSecret
        ocnProviderId
        ocnTokenEndpoint
        ocnServiceEndpoint
        ocnLaunchUrl
        apiKey
        oauthClientId
        oauthClientSecret
        oauthScimEnabled
        oauthOrganizationApiEnabled
        createdAt
      }
    }
  }
`

const deleteIntegrationMutation = gql`
  mutation removeIntegration($id: ID!) {
    removeIntegration(id: $id) {
      id
    }
  }
`

export {
  getIntegrationsWithScenariosQuery,
  getIntegrationsQuery,
  addIntegrationMutation,
  updateIntegrationMutation,
  deleteIntegrationMutation,
}
