const { AFRAME } = window

AFRAME.registerComponent('hideable-element', {
  schema: {
    visible: { type: 'boolean', default: false },
  },
  update() {
    this.self.object3D.visible = this.data.visible
  },
  init() {
    this.self = this.el
  },
})
