import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import { FaTimes } from 'react-icons/fa'
import useFoundation from '../../../hooks/useFoundation'
import {
  updateScenarioMutation,
  updateScenarioImageMutation,
} from '../../../apollo/query/scenarios'
import { default as S } from '../../../utils/lang/en.json'
import { addSuccessAlert, addErrorAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import { validateImageExtension } from '../../../utils/validation/validate'

const EditScenarioModal = ({ id, appendTo, scenarios }) => {
  const [scenarioId, setScenarioId] = useState(null)
  const [scenario, setScenario] = useState(null)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [previewImage, setPreviewImage] = useState('')
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (scenarios) {
      setScenario(scenarios[0])
    }
  }, [scenarios])

  useEffect(() => {
    if (scenario) {
      setTitle(scenario.name)
      setScenarioId(scenario.id)
      setDescription(scenario.description)
      setPreviewImage(scenario.imageUrl)
      setImage(scenario.imageUrl)
    }
  }, [scenario])

  const editNotificationHandler = (s) => {
    addSuccessAlert({
      title: S.notifications.scenarios.edit,
      subtitle: `Changes to '${s.name}' were saved succesfully`,
    })
  }

  const [updateScenario] = useMutation(updateScenarioMutation, {
    onError: handleApolloError,
    update: (_, { data: d }) => {
      editNotificationHandler(d.updateScenario.scenario)
    },
  })
  const [updateScenarioWithImage] = useMutation(updateScenarioImageMutation, {
    onError: handleApolloError,
    update: (_, { data: d }) => {
      editNotificationHandler(d.updateScenario.scenario)
    },
  })

  const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

  const handleEditScenario = () => {
    if (!title) return null

    if (image !== scenario.imageUrl) {
      const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
      upload.create((error, blob) => {
        if (error) throw new Error(error.message)
        updateScenarioWithImage({
          variables: {
            id: scenario.id,
            name: title,
            description,
            blobId: blob.signed_id || '',
          },
        })
      })
    } else {
      updateScenario({
        variables: {
          id: scenario.id,
          name: title,
          description,
        },
      })
    }
    return $('#editScenarioModal').foundation('close')
  }

  const ref = useFoundation()

  return (
    <div
      ref={ref}
      className="reveal o-modal--scenario"
      data-animation-in="fade-in"
      data-animation-out="fade-out"
      data-close-on-click={false}
      id={id}
      data-append-to={appendTo}
      data-reveal>
      <div key={scenarioId} className="o-modal--scenario">
        <div className="grid-x">
          <div className="cell grid-x small-12">
            <div className="o-modal--header">
              <h2 className="text-bold">Edit scenario</h2>
              <span className="o-modal--close-button" data-close={id}>
                <FaTimes />
              </span>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url("${previewImage}")`,
            }}
            className="cell o-modal--content o-modal__scenario--image border-none">
            <label
              htmlFor="image-upload"
              className="button dark shadow dark-transparent">
              Change cover image
              <input
                type="file"
                id="image-upload"
                hidden
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  const file = event.target.files[0]
                  if (validateImageExtension(file)) {
                    setPreviewImage(URL.createObjectURL(file))
                    setImage(file)
                  } else {
                    addErrorAlert({
                      title: `Invalid image file type: ${file.type}`,
                      subtitle: 'Please upload a png or jpeg image',
                      timeout: 6000,
                    })
                  }
                }}
              />
            </label>
          </div>
        </div>
        <form
          className="cell o-modal--content border-none text-stable-dark"
          onSubmit={handleEditScenario}>
          <label>
            Scenario title <span className="text-assertive">*</span>
          </label>
          <input
            defaultValue={title}
            type="text"
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Scenario description</label>
          <textarea
            defaultValue={description}
            rows="6"
            onChange={(e) => setDescription(e.target.value)}
          />
        </form>
        <div className="cell o-modal--footer">
          <button
            data-close="editScenarioModal"
            className="hollow button secondary wide">
            Cancel
          </button>
          <button
            className="button primary wide"
            onClick={() => {
              handleEditScenario()
            }}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}
export default EditScenarioModal
