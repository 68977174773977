import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuidV4 } from 'uuid'
import Modal from '../../UI/Modal'
import {
  addCourseMutation,
  getCoursesQuery,
  updateCourseScenariosMutation,
} from '../../../apollo/query/courses'
import CourseScenariosList from '../CourseScenariosList'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const AddCourseModal = ({ id, appendTo }) => {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const scenariosListInOrderRef = useRef()
  const [scenariosListKey, setScenariosListKey] = useState(uuidV4())

  const cleanup = () => {
    setTimeout(() => {
      // ensure modal closes before removing values
      nameRef.current.value = ''
      descriptionRef.current.value = ''
      setScenariosListKey(uuidV4())
    }, 300)
  }

  const [updateCourseScenarios] = useMutation(updateCourseScenariosMutation, {
    onError: handleApolloError,
  })

  const [addCourse] = useMutation(addCourseMutation, {
    onError: handleApolloError,
    onCompleted: async ({ addCourse: { course } }) => {
      if (scenariosListInOrderRef.current.length) {
        await updateCourseScenarios({
          variables: {
            id: course.id,
            publishedScenarioIds: scenariosListInOrderRef.current.map(
              (scenario) => Number(scenario.id)
            ),
          },
        })
      }
      addSuccessAlert({ title: 'Course created' })
      cleanup()
    },
    refetchQueries: [
      {
        query: getCoursesQuery,
      },
    ],
  })

  return (
    <Modal
      id={id}
      key="add-course"
      appendTo={appendTo}
      overflowY="visible"
      headerText="New course"
      useDefaultButtons
      cancelHandler={cleanup}
      submitHandler={() => {
        if (!nameRef.current.value) return
        addCourse({
          variables: {
            name: nameRef.current.value,
            description: descriptionRef.current.value || '',
          },
        })
      }}>
      <form className="mt-3">
        <label>
          Course title <span className="text-assertive">*</span>
        </label>
        <input type="text" ref={nameRef} required />
        <label> Course description </label>
        <textarea ref={descriptionRef} rows="8" />
        <CourseScenariosList
          key={scenariosListKey}
          newList={scenariosListInOrderRef}
        />
        <button
          type="submit"
          disabled
          className="o-button__submit--hidden"
          aria-hidden="true"
        />
      </form>
    </Modal>
  )
}

export default AddCourseModal
