import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaBug, FaImage, FaTrophy } from 'react-icons/fa'
import { getAttemptsQuery } from '../../apollo/query/attempts'
import Checkbox from '../../components/UI/Form/Checkbox'
import { default as S } from '../../utils/lang/en.json'
import TableColumnsDropdown from '../../components/UI/TableColumnsDropdown'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import FilterEmptyState from '../../components/UI/Menu/FilterEmptyState'
import Table from '../../components/Table'
import withApollo from '../../hooks/withApollo'
import { convertDuration, dateFormat, sortOnKey } from '../../utils/format'
import Stars from '../../components/UI/Stars/Stars'
import {
  filterColumnsHandler,
  sortHandler,
} from '../../components/tableFunctions'
import TrainingAttemptDetail from '../../components/Activity/TrainingAttemptDetail'
import HeaderFilters from '../../components/Activity/HeaderFilters'
import ExportAttemptModal from '../../components/Activity/ExportAttemptModal'
import ActivityContext from '../../utils/context/ActivityContext'
import { getDeviceTypeString } from '../../components/Activity/DeviceTypeHelper'
import usePreferences from '../../hooks/usePreferences'

const MAX_RECORDS_COUNT = 1000

const NUMBER_OF_ITEMS = 100

const TABLE_HEADERS = [
  'startedAt',
  'traineeName',
  'email',
  'scenarioTitle',
  'deviceType',
  'attemptNo',
  'completed',
  'duration',
  'stars',
]

const INITIAL_FILTERS = {
  userIds: [],
  deviceTypes: [],
  scenarioIds: [],
  period: '-30_days',
}

const TrainingAttempts = () => {
  const { apiVars, searchParams, setSearchParams } = useContext(ActivityContext)
  const [limit, setLimit] = useState(NUMBER_OF_ITEMS)
  const [hasMore, setHasMore] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [selectedAttemptId, setSelectedAttemptId] = useState()
  const [preferences, setPreferences] = usePreferences('attempts', 'startedAt')
  const [renderData, setRenderData] = useState([])

  const { loading, data } = useQuery(getAttemptsQuery, {
    variables: apiVars,
  })

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    let rows = data.attempts.attempts
    setHasMore(rows.length > limit)

    if (!rows.length && !showEmptyState) setShowEmptyState(true)
    if (rows.length && showEmptyState) setShowEmptyState(false)

    rows = rows.map((attempt) => ({
      id: attempt.id,
      startedAt: {
        value: attempt.startedAt,
        render: (
          <span>
            {dateFormat(attempt.startedAt)}
            {attempt.highscore && (
              <span className="ml-1 o-label o-label--highscore">
                <FaTrophy className="u-pull-left" /> HIGHSCORE
              </span>
            )}
            {attempt.testing && (
              <span className="ml-1 o-label o-label--test">
                <FaBug className="u-pull-left" /> TEST
              </span>
            )}
          </span>
        ),
      },
      traineeName: attempt.anonymized
        ? 'Anonymous'
        : attempt.user.firstName || attempt.user.lastName
          ? `${attempt.user.firstName} ${attempt.user.lastName ?? ''}`
          : 'Unknown',
      email: {
        value: attempt.anonymized ? '-' : attempt.user.email,
        render: (
          <>
            {attempt.anonymized ? (
              '-'
            ) : (
              <div className="flex-container" title={attempt.user.email}>
                <div className="text-truncate" style={{ maxWidth: '80px' }}>
                  {attempt.user.email.split('@')[0]}
                </div>
                @
                <div className="text-truncate" style={{ maxWidth: '80px' }}>
                  {attempt.user.email.split('@')[1]}
                </div>
              </div>
            )}
          </>
        ),
      },
      scenarioTitle: attempt.publishedScenario.name,
      deviceType: {
        value: attempt.deviceType,
        render: <>{getDeviceTypeString(attempt.deviceType)}</>,
      },
      attemptNo: attempt.sequence,
      completed: attempt.completed ? 'Yes' : 'No',
      duration: {
        value: attempt.duration,
        render: (
          <span>
            {attempt.completed ? convertDuration(attempt.duration) : '-'}
          </span>
        ),
      },
      stars: {
        value: attempt.stars,
        render: (
          <span>{attempt.stars ? <Stars value={attempt.stars} /> : '-'}</span>
        ),
      },
    }))

    setRenderData(sortOnKey(rows, value, isAscending).slice(0, limit))

    return rows
  }

  const hideDetail = () => setSelectedAttemptId(null)
  const handleScape = (e) => e.keyCode == 27 && hideDetail()

  useEffect(() => {
    if (preferences && data) {
      sortedData()
    }
  }, [preferences, limit, data])

  useEffect(() => {
    document.addEventListener('keydown', handleScape)
    return () => document.removeEventListener('keydown', handleScape)
  }, [])

  if (loading || !data || !preferences) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const showTablePrefix = () => {
    return (
      <div className="o-table__row o-table__placeholder--cell text-stable-dark text-center bg-stable-lightest">
        There are more than 1.000 lines.&nbsp;
        <span
          type="button"
          className="text-stable-dark text-underline cursor-pointer"
          data-open="training-attempts-modal">
          Export to show the full attempts.
        </span>
      </div>
    )
  }

  const getTableRightBar = () => {
    const content = () => {
      return TABLE_HEADERS.map((item, i) => {
        const disabled = i === 0
        return (
          <li
            key={i}
            className={`mb-0 text-normal o-dropdown__list-item ${
              disabled ? 'disabled' : ''
            }`}
            onClick={(e) => {
              if (!disabled) {
                e.preventDefault()
                filterColumnsHandler(item, preferences, setPreferences)
              }
            }}>
            <Checkbox
              style={{ marginBottom: '0px', cursor: 'default' }}
              disabled={disabled}
              checked={!preferences.filteredColumns.includes(item)}
              value={S.table.trainingAttempts.headers[item]}
            />
          </li>
        )
      })
    }

    return (
      <>
        <TableColumnsDropdown content={content} />
        <span className="cell shrink mr-1" title="Export">
          <button
            className="button hollow secondary text-bold mb-1 mt1"
            data-open="training-attempts-modal">
            Export
          </button>
        </span>
      </>
    )
  }

  return (
    <div id="trainingAttempts">
      <TrainingAttemptDetail
        attemptId={selectedAttemptId}
        onClose={hideDetail}
      />

      <HeaderFilters
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        title="Training attempts"
        rightBar={getTableRightBar()}
        apiVars={apiVars}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <div
        className="o-table--page-container grid-container fluid"
        style={{ top: '130px' }}>
        {showEmptyState ? (
          <FilterEmptyState
            type="training attempts"
            icon={<FaImage />}
            removeButotn={
              <span className="text-normal text-stable-dark">
                <span
                  data-testid="link"
                  className="text-underline cursor-pointer"
                  onClick={() => {
                    setShowEmptyState(false)
                    setSearchParams({
                      ...INITIAL_FILTERS,
                      period: searchParams.period,
                      tab: 'attempts',
                    })
                  }}>
                  Remove all filters
                </span>{' '}
                or change the period
              </span>
            }
          />
        ) : (
          <Table
            data={renderData}
            placeholderRows={1}
            sortOn={preferences.sortOn}
            sortHandler={(value) => {
              sortHandler(value, preferences, setPreferences)
            }}
            headers={TABLE_HEADERS.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            type="trainingAttempts"
            loadMore={hasMore}
            loadMoreHandler={() => setLimit(limit + NUMBER_OF_ITEMS)}
            tablePrefix={
              data.attempts.attempts.length >= MAX_RECORDS_COUNT &&
              showTablePrefix()
            }
            rowHandler={setSelectedAttemptId}
            handleEntireRow
            selectedItems={[selectedAttemptId]}
          />
        )}
      </div>

      <ExportAttemptModal
        id="training-attempts-modal"
        filterOptions={apiVars}
        appendTo="#trainingAttempts"
      />
    </div>
  )
}

export default withApollo(TrainingAttempts)
