import React, { useContext } from 'react'

import { SceneEditorContext } from '../../SceneEditorContext'

import HotspotSettings from './Partials/HotspotSettings'
import ElementCard from './Partials/ElementCard'

const ElementsTab = ({ scenes, scenarioId }) => {
  const [sceneState] = useContext(SceneEditorContext)

  return (
    <>
      {sceneState.kind === 'HOTSPOT' && <HotspotSettings />}
      {sceneState.question && (
        <ElementCard type="QUESTION" scenes={scenes} scenarioId={scenarioId} />
      )}
      {['DIRECTION', 'HOTSPOT'].includes(sceneState.kind) &&
        !sceneState.question && (
          <ElementCard
            type="QUESTION"
            scenes={scenes}
            scenarioId={scenarioId}
          />
        )}
      {sceneState.answers &&
        sceneState.answers.map((_, i) => (
          <ElementCard
            key={i}
            type="ANSWER"
            index={i}
            scenes={scenes}
            scenarioId={scenarioId}
          />
        ))}
    </>
  )
}
export default ElementsTab
