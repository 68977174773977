import React from 'react'
import Select from '../UI/Form/Select'

const TraineeDetailSelectRole = ({ selected, changeHandler, disabled }) => {
  const roleOptions = [
    ['USER', 'Trainee'],
    ['WRITER', 'Writer'],
    ['MANAGER', 'Manager'],
    ['INSTRUCTOR', 'Instructor'],
    ['PUBLISHER', 'Publisher'],
  ]
  if (selected === 'OWNER') {
    roleOptions.push(['OWNER', 'Owner'])
  }

  return (
    <Select
      disabled={selected === 'OWNER' || disabled}
      values={roleOptions}
      selected={selected}
      changeHandler={changeHandler}
    />
  )
}
export default TraineeDetailSelectRole
