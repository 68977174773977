import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPublicLinkSessionQuery } from '../../apollo/query/session'
import About from '../../components/Launcher/About'
import FAQ from '../../components/Launcher/FAQ'
import Footer from '../../components/Launcher/Footer/Footer'
import Header from '../../components/Launcher/Header'
import HowToPlay from '../../components/Launcher/HowToPlay'
import Error from '../../components/Launcher/Error'
import Nav from '../../components/Launcher/Nav'
import TabView from '../../components/Launcher/TabView'
import CourseSignIn from '../../components/Launcher/SignIn/CourseSignIn'
import ScenarioSignIn from '../../components/Launcher/SignIn/ScenarioSignIn'
import withApollo from '../../hooks/withApollo'
import { isBrightColor } from '../../utils/helpers/color'
import PlayerRedirect from '../../utils/helpers/PlayerRedirect'

const CourseScenarioLauncher = () => {
  const [isCourse, setIsCourse] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { groupSecret: publicLinkSecret } = useParams()
  const { loading, data } = useQuery(getPublicLinkSessionQuery, {
    variables: {
      publicLinkSecret,
    },
  })

  if (loading || !data) return null
  if (data.publicLink === null) return <Error />

  const { client, courses, scenarios, securityType, securityDomains, anonymousSession } =
    data.publicLink

  // Anonymous Public Links may be redirected immediately to the Web Player
  if (securityType === 'ANONYMOUS' && anonymousSession.verificationCode !== null) {
    const path = `#/p/${anonymousSession.verificationCode}`
    return <PlayerRedirect path={path} />
  }

  // Check if we should show black or white text
  const textBlack = isBrightColor(client.primaryColor)

  const allCoursesAndScenarios = [...courses, ...scenarios]

  const selectFirstSectionType = () => {
    const courses_and_scenarios = []
    if (courses) {
      courses.forEach((course, i) => {
        courses_and_scenarios.push({
          name: course.name,
          type: 'course',
          render: (
            <CourseSignIn
              key={`course${i}`}
              client={client}
              course={course}
              security={{ type: securityType, domains: securityDomains }}
              buttonColor={textBlack ? 'black' : 'white'}
              scenariosCount={course.scenarios.length}
            />
          ),
        })
      })
    }
    if (scenarios) {
      scenarios.forEach((scenario, i) => {
        courses_and_scenarios.push({
          name: scenario.name,
          type: 'scenario',
          render: (
            <ScenarioSignIn
              key={`scenario${i}`}
              client={client}
              scenario={scenario}
              security={{ type: securityType, domains: securityDomains }}
              buttonColor={textBlack ? 'black' : 'white'}
            />
          ),
        })
      })
    }

    return (
      <section id="scenarioCourseInfo">
        <TabView
          client={client}
          data={courses_and_scenarios}
          setSelectedIndex={setSelectedIndex}
          setIsCourse={setIsCourse}
        />
      </section>
    )
  }
  return (
    <>
      <Header />
      {selectFirstSectionType()}
      <Nav />
      <About
        data={allCoursesAndScenarios[selectedIndex]}
        description={allCoursesAndScenarios[selectedIndex].description}
        security={{
          type: data.publicLink.securityType,
          domain: data.publicLink.securityDomain,
        }}
        isCourse={isCourse}
        buttonColor={textBlack ? 'black' : 'white'}
      />
      <HowToPlay />
      <FAQ />
      <Footer />
    </>
  )
}

export default withApollo(CourseScenarioLauncher)
