import React, { useContext, useEffect, useState } from 'react'
import { FaChevronUp } from 'react-icons/fa'
import LanguageContext from '../../utils/context/LanguageContext'

const Nav = () => {
  const { translations: t } = useContext(LanguageContext)
  const [menuOptions, setMenuOptions] = useState([])
  const [showToTop, setShowToTop] = useState(false)
  const [isActive, setIsActive] = useState('')

  const scrollToComponent = (id) => {
    const element = document.querySelector(id)
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const scrollDistance = (nodes) => {
    const currentNode =
      nodes.find((node) => window.scrollY >= node.offsetTop - 200) ||
      nodes[nodes.length - 1]

    if (window.scrollY > 0 && !showToTop) {
      setShowToTop(true)
    } else {
      setShowToTop(false)
    }

    const id = `#${currentNode?.getAttribute('id')}`
    if (isActive !== id) setIsActive(id)
  }

  useEffect(() => {
    const { addEventListener, removeEventListener } = document
    const nodeList = [...document.querySelectorAll('section[id]')].reverse()
    addEventListener('scroll', () => scrollDistance(nodeList), true)

    return () => {
      removeEventListener('scroll', () => scrollDistance(), true)
    }
  }, [])

  useEffect(() => {
    if (!menuOptions.length) {
      setMenuOptions([...document.querySelectorAll('section[id]')])
    }
  }, [menuOptions])

  if (!menuOptions) return null

  const createMenuItems = () => {
    return menuOptions.map((option, i) => {
      if (i === 0) return null
      const id = option.getAttribute('id')
      const text = t.sections.navItem[id]
      const showAsActiveClassName = isActive === `#${id}` ? ' active' : ''
      return (
        <span
          key={i}
          data-name={`#${id}`}
          className={`c-launcher__nav--list-item ${showAsActiveClassName}`}
          onClick={() => {
            setIsActive(`#${id}`)
            scrollToComponent(i === 0 ? '#header' : `#${id}`)
          }}>
          {text}
        </span>
      )
    })
  }

  return (
    <nav className="grid-container c-launcher__nav--container fluid ">
      <div className="c-launcher__nav">
        <div className="c-launcher__nav--menu-container medium-6 large-3">
          {createMenuItems()}
        </div>
        <span
          className="c-launcher__nav--list-item c-launcher__nav--back-to-top"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            setIsActive('')
          }}>
          {showToTop && (
            <>
              <span className="show-for-medium" title={`${t.backTo} ${t.top}`}>
                {`${t.backTo} ${t.top}`}
              </span>
              <FaChevronUp />
              <span
                className="show-for-small-only"
                title={`${t.backTo} ${t.top}`}>
                {t.top}
              </span>
            </>
          )}
        </span>
      </div>
    </nav>
  )
}

export default Nav
