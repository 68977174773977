import React from 'react'

const RadioButton = ({
  id,
  label,
  clickHandler,
  name,
  checked,
  disabled,
  labelClassName = '',
  value = 'on'
}) => (
  <li
    style={{
      listStyle: 'none',
      display: 'inline-block',
      alignItems: 'center',
    }}>
    <input
      data-testid="input"
      type="radio"
      id={id}
      className="o-radio__custom"
      name={name}
      onChange={clickHandler}
      checked={checked}
      disabled={disabled}
      value={value}
    />
    <label
      data-testid="label"
      htmlFor={id}
      className={`o-radio__custom--label ${labelClassName} ${disabled ? 'disabled' : ''} ${
        checked ? 'checked' : ''
      }`}>
      {label}
    </label>
  </li>
)

export default RadioButton
