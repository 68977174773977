import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../UI/Modal'
import { disableOtpMutation } from '../../apollo/query/user'

const DeleteAuthenticatorModal = ({ id, appendTo, onDelete }) => {
  const [disableOtp, { loading }] = useMutation(disableOtpMutation)
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this authenticator app?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      submitDisabled={loading}
      submitHandler={() => {
        disableOtp().then(onDelete)
      }}
    />
  )
}
export default DeleteAuthenticatorModal
