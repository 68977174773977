import React, { useEffect, useRef } from 'react'
import { isIntersecting } from '../../../utils/helpers/javascript'

let mouseDownX
let mouseDownY
const SortableContainer = ({
  dragHandle = ':scope > *',
  onOrderChange = () => {},
  children,
}) => {
  const ref = useRef()
  const dragged = useRef(null)
  const destElemRef = useRef(null)

  const getAllRows = () => {
    return [...ref.current.children].filter(
      (el) => !el.classList.contains('o-sortable__row--placeholder')
    )
  }

  const moveRow = (x, y) => {
    const dragElem = dragged.current
    if (dragElem) {
      dragElem.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)'

      let dPos = dragElem.getBoundingClientRect(),
        currStartY = dPos.y,
        currEndY = currStartY + dPos.height,
        rows = getAllRows()

      for (let i = 0; i < rows.length; i++) {
        let rowElem = rows[i],
          rowSize = rowElem.getBoundingClientRect(),
          rowStartY = rowSize.y,
          rowEndY = rowStartY + rowSize.height
        if (
          dragElem !== rowElem &&
          isIntersecting(currStartY, currEndY, rowStartY, rowEndY) &&
          Math.abs(currStartY - rowStartY) < rowSize.height / 2
        ) {
          const placeOnTopOfFirstElement = i === 0 && currStartY < rowStartY
          rowElem.classList.add('o-sortable__row--dragover')
          if (placeOnTopOfFirstElement)
            rowElem.classList.add('o-sortable__row--dragover-top')
          destElemRef.current = rows[i]
        } else {
          rowElem.classList.remove('o-sortable__row--dragover')
          rowElem.classList.remove('o-sortable__row--dragover-top')
        }
      }
    }
  }

  const handleMouseDown = (e) => {
    if (!e.target.matches(dragHandle) && !e.target.closest(dragHandle))
      return false
    const row = e.target.closest('.o-sortable__row')
    if (!row) return false
    dragged.current = row
    mouseDownX = e.clientX
    mouseDownY = e.clientY + 50

    for (var i = 0; i < row.children.length; i++) {
      let cell = row.children[i]
      cell.style.width = getComputedStyle(cell).width
      cell.style.height = getComputedStyle(cell).height
    }

    row.classList.add('dragging')
    const clonedRow = row.cloneNode(true)
    clonedRow.classList.add('o-sortable__row--placeholder')
    clonedRow.classList.remove('dragging')
    row.parentNode.insertBefore(clonedRow, row)

    moveRow(0, -row.getBoundingClientRect().height - 5)
  }

  const handleMouseMove = (e) => {
    if (!dragged.current) return false
    const tr = dragged.current

    const mouseX = e.clientX - mouseDownX
    const mouseY = e.clientY - mouseDownY

    if (mouseX < 0 || mouseX > tr.getBoundingClientRect().width) {
      return false
    }
    moveRow(mouseX, mouseY)
  }

  const handleMouseUp = () => {
    const row = dragged.current
    if (row) {
      row.classList.remove('dragging')

      if (destElemRef.current) {
        const allRows = getAllRows()
        const sourceIndex = Array.from(allRows).indexOf(row)
        let destIndex = Array.from(allRows).indexOf(destElemRef.current) + 1

        if (
          destElemRef.current.classList.contains(
            'o-sortable__row--dragover-top'
          )
        )
          destIndex--
        else destIndex -= destIndex > sourceIndex ? 1 : 0

        if (sourceIndex !== destIndex) onOrderChange(sourceIndex, destIndex)
      }

      moveRow(0, 0)
      const placeholder = row.parentNode.querySelector(
        ':scope > .o-sortable__row--placeholder'
      )
      if (placeholder) placeholder.remove()
    }

    destElemRef.current = null
    dragged.current = null
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  useEffect(() => {
    ;[
      ...ref.current.querySelectorAll(':scope > *:not(.o-sortable__row)'),
    ].forEach((elem) => {
      elem.classList.add('o-sortable__row')
    })
  }, [children])

  return (
    <div
      ref={ref}
      onMouseMove={handleMouseMove}
      className="o-sortable-container">
      {children}
    </div>
  )
}

export default SortableContainer
