import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import { addErrorAlert, addSuccessAlert } from '../../../utils/helpers/alerts'
import {
  exportActivityQuery,
  exportScenarioActivityQuery,
} from '../../../apollo/query/activities'

const ExportModal = ({ id, appendTo, scenarioId, title }) => {
  const onCompleted = (data) => {
    if (data?.exportActivity.success)
      addSuccessAlert({ title: 'Export is in progress' })
    else addErrorAlert({ title: 'Something went wrong' })
  }
  const [exportScenarioActivity] = useMutation(exportScenarioActivityQuery, {
    onCompleted,
  })

  const [exportActivity] = useMutation(exportActivityQuery, {
    onCompleted,
  })

  const handleExport = () => {
    if (scenarioId) exportScenarioActivity({ variables: { scenarioId } })
    else exportActivity()
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={title}
      submitHandler={handleExport}
      useDefaultButtons>
      <div className="text-stable-dark pt-3 pb-3 text-normal">
        Exports are made in the background. You are notified by email when
        finished.
      </div>
    </Modal>
  )
}
export default ExportModal
