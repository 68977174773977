// Component for launcher logic

import ClipboardJS from 'clipboard'

var deviceOs; // device that opens launcher, either ios / android / desktop
var detectionEnabled = true;
var supportedStore = 'public';
var supportedDevices = 'all';

function detectDeviceOs() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    deviceOs = 'android';
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    deviceOs = 'ios';
  } else {
    deviceOs = 'desktop';
  }
}

function toggleDevice(device) {
  $("#enter_code").hide();
  $("#start_playing_other_device").hide();
  $("#start_playing_this_device").hide();

  $("#device_this").removeClass("device__choice--active");
  $("#device_other").removeClass("device__choice--active");
  $("#device_smartphone").removeClass("device__choice--active");
  $("#device_headset").removeClass("device__choice--active");

  if (supportedStore === 'private') {
    $("#device_mdm_description").show();
    $("#device_this_description").hide();
    $("#device_other_description").hide();
    $("#device_headset_description").hide();
    $("#device_smartphone_description").hide();
  }

  switch(device) {
    case 'this':
      $("#device_this").addClass("device__choice--active");
      $("#start_playing_this_device").show();
      if (supportedStore === 'public') {
        $("#device_this_description").show();
        $("#device_other_description").hide();
      }
      break;
    case 'other':
      $("#device_other").addClass("device__choice--active");
      $("#enter_code").show();
      $("#start_playing_other_device").show();
      if (supportedStore === 'public') {
        $("#device_this_description").hide();
        $("#device_other_description").show();
      }
      break;
    case 'smartphone':
      $("#device_smartphone").addClass("device__choice--active");
      $("#enter_code").show();
      $("#start_playing_other_device").show();
      if (supportedStore === 'public') {
        $("#device_smartphone_description").show();
        $("#device_headset_description").hide();
      }
      break;
    case 'headset':
      $("#device_headset").addClass("device__choice--active");
      $("#enter_code").show();
      $("#start_playing_other_device").show();
      if (supportedStore === 'public') {
        $("#device_smartphone_description").hide();
        $("#device_headset_description").show();
      }

      break;
  }
}

function enableThisAndOtherDevice() {
  $("#device_this").show();
  $("#device_other").show();
  toggleDevice('this');

  if (deviceOs === 'ios') {
    $("#device_this_android").hide();
    $("#device_android_download").hide();
    $("#device_this_subtitle").html('iOS');
  } else {
    $("#device_this_ios").hide();
    $("#device_ios_download").hide();
    $("#device_this_subtitle").html('Android');
  }

  $("#device_this").on('click', function() { toggleDevice('this') });
  $("#device_other").on('click', function() { toggleDevice('other') });
}

function enableMobileAndOculusDevice() {
  switch(supportedDevices) {
    case 'all':
      $("#device_smartphone").show();
      $("#device_headset").show();
      toggleDevice('smartphone');
      break;
    case 'mobile':
      $("#device_smartphone").show();
      $("#device_headset").hide();
      toggleDevice('smartphone');
      break;
    case 'headset':
      $("#device_smartphone").hide();
      $("#device_headset").show();
      toggleDevice('headset');
      break;
  }

  $("#device_smartphone").on('click', function() { toggleDevice('smartphone') });
  $("#device_headset").on('click', function() { toggleDevice('headset') });
}

function enableMoreButton() {
  $("#more_button").on('click', function() {
    $("#more_button").hide();
    $("#more_pane").show("fast");
  });
}

function enableCopyButton() {
  var clipboard = new ClipboardJS('#copy');
  clipboard.on('success', function(e) {
    $(e.trigger).html('Copied!')
  });
}

export function disableDeviceDetect() {
  detectionEnabled = false;
}

export function configureSupportedDevices(device) {
  supportedDevices = device;
}

export function configureSupportedStore(store) {
  supportedStore = store;
}

export function init() {
  enableMoreButton();
  enableCopyButton();

  if (detectionEnabled) {
    detectDeviceOs();
  } else {
    deviceOs = 'desktop';
  }

  if (deviceOs === "desktop") {
    enableMobileAndOculusDevice();
  } else {
    enableThisAndOtherDevice();
  }
}
