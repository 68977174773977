const features = [
  {
    title: 'Add more published scenario slots',
    value: 'PUBLISHED_SCENARIOS',
  },
  {
    title: 'Add more workspaces',
    value: 'WORKSPACES',
  },
  {
    title: 'Advances user roles',
    value: 'ROLES',
  },
  {
    title: 'Activity log',
    value: 'ACTIVITY',
  },
  {
    title: 'Courses',
    value: 'COURSES',
  },
  {
    title: 'Enterprise features',
    value: 'ENTERPRISE',
  },
]

export default features