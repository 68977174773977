import moment from 'moment'

export const getDatesDiff = (
  startDate,
  endDate,
  groupBy = 'day',
  dateFormat = 'YYYY-MM-DD',
) => {
  startDate = moment(startDate)
  endDate = moment(endDate)
  if (groupBy === 'day') {
    endDate = endDate.set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    startDate = startDate.set({
      hours: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  } else if (groupBy === 'hour') {
    endDate = endDate.set({ minute: 0, second: 0, millisecond: 0 })
    startDate = startDate.set({ minute: 0, second: 0, millisecond: 0 })
  } else if (groupBy === 'minute') {
    endDate = endDate.set({ second: 0, millisecond: 0 })
    startDate = startDate.set({ second: 0, millisecond: 0 })
  }
  const diff = moment(endDate).diff(moment(startDate), `${groupBy}s`) + 1

  const dates = []
  for (let i = 0; i < diff; i++) {
    const nextDate = moment(startDate).add(i, groupBy)
    dates.push(nextDate.format(dateFormat))
  }
  return dates
}
