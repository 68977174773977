import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import cache from '../../../apollo/cache'
import {
  duplicateScenarioMutation,
  getScenariosQuery,
} from '../../../apollo/query/scenarios'
import useFoundation from '../../../hooks/useFoundation'
import { sortOnKey } from '../../../utils/format'
import { default as S } from '../../../utils/lang/en.json'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const DuplicateScenarioModal = ({
  id,
  appendTo,
  scenarios,
  clients,
  currentClient,
}) => {
  const [key, setKey] = useState(uuidv4())
  const [names, setNames] = useState([])
  const [current, setCurrent] = useState([])
  const [clientId, setClientId] = useState(currentClient.id)

  const [duplicateScenario] = useMutation(duplicateScenarioMutation, {
    update: (_, { data }) => {
      if (currentClient.id === clientId) {
        const newScenario = data.duplicateScenario.scenario
        const { scenarios: currentScenarios } = cache.readQuery({
          query: getScenariosQuery,
        })
        if (newScenario) {
          cache.writeQuery({
            query: getScenariosQuery,
            data: {
              scenarios: [...currentScenarios, newScenario],
            },
          })
        }
      }
    },
    onError: handleApolloError,
    onCompleted: (data) => {
      addSuccessAlert({
        title: S.notifications.scenarios.duplicate,
        subtitle: data.duplicateScenario?.name,
      })
    },
  })

  useEffect(() => {
    if (scenarios) {
      setKey(uuidv4())
      setCurrent(scenarios)
      const newNames = scenarios?.map((scenario) => `Copy of ${scenario?.name}`)
      setNames(newNames)
    }
  }, [scenarios])

  const createCells = () => {
    return (
      <form className="o-modal--content small-12" key={key}>
        {current.map((scenario, i) => {
          return (
            <div key={scenario.id}>
              <label className="c-form__label--required">{`New scenario title for '${scenario.name}'`}</label>
              <input
                type="text"
                defaultValue={names[i]}
                onChange={(e) => {
                  const newNames = [...names]
                  newNames[i] = e.target.value
                  setNames(newNames)
                }}
              />
            </div>
          )
        })}
        {clients && (
          <div className="small-12">
            <label>
              {`Duplicate scenario${
                current.length !== 1 ? 's' : ''
              } to another workspace`}
            </label>
            <select
              className="mb-1"
              defaultValue={currentClient.id}
              onChange={(e) => setClientId(e.target.value)}>
              {[
                { id: currentClient.id, name: 'Keep in the current workspace' },
                ...sortOnKey(
                  [...clients.filter((c) => c.id !== currentClient.id)],
                  'name',
                  false
                ),
              ].map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
            <p>
              You can duplicate to any workspace where you are an owner,
              manager, publisher or writer.
            </p>
          </div>
        )}
      </form>
    )
  }

  const handleDuplicateScenarios = async () => {
    current.forEach((scenario, i) => {
      duplicateScenario({
        variables: {
          id: scenario.id,
          name: names[i],
          clientId,
        },
      })
    })
    $('#duplicateScenarioModal').foundation('close')
  }

  const ref = useFoundation()

  if (!current) return null
  return (
    <div
      ref={ref}
      id={id}
      className="reveal o-modal--scenario"
      data-animation-in="fade-in"
      data-animation-out="fade-out"
      data-close-on-click={false}
      data-append-to={appendTo}
      data-reveal>
      <div className="o-modal--scenario">
        <div className="grid-x">
          <div className="cell">
            <div className="o-modal--header mb-0 border-bottom">
              <h2 className="text-bold">Duplicate scenario</h2>
              <span className="text-dark o-modal--close-button" data-close={id}>
                <FaTimes />
              </span>
            </div>
          </div>
          {createCells()}
        </div>
        <div className="cell o-modal--footer">
          <button
            className="hollow button secondary wide"
            data-close="duplicateScenarioModal">
            Cancel
          </button>
          <button
            className="button primary wide"
            onClick={() => {
              handleDuplicateScenarios()
            }}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default DuplicateScenarioModal
