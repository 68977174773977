import React, { useContext } from 'react'
import PieGraph from '../UI/Graphs/PieGraph'
import Bullet from './Bullet'
import colors from '../Editor/helpers/colors'
import { getHexOpacityArray } from '../../utils/helpers/color'
import ActivityContext from '../../utils/context/ActivityContext'
import { gql, useQuery } from '@apollo/client'
import EmptyParagraph from './EmptyParagraph'
import Loading from './Loading'

const getCompletionAttemptsQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: COUNT
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: COMPLETION) {
        id
        name
        value
      }
    }
  }
`

const CompletionRate = ({ attemptsMetricsData }) => {
  const { apiVars } = useContext(ActivityContext)
  const { data: completionMetrics } = useQuery(getCompletionAttemptsQuery, {
    variables: apiVars,
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })
  const completion =
    completionMetrics?.attemptsMetrics?.grouped?.map((c) => ({
      name: c.id === 'false' ? 'Not completed' : 'Completed',
      value: Number(c.value),
    })) ?? []

  const hexOpacityArray = getHexOpacityArray(completion?.length)
  return (
    <>
      <h2 className="text-bold mb-2">Completion rate</h2>
      <div className="border-light border-radius">
        <div className="pt-3 pb-3 border-light-bottom">
          <PieGraph data={completion} />
        </div>
        {completion?.map((entity, index) => (
          <div
            key={entity.name}
            className={`text-normal pt-2 pb-2 pl-3 pr-3 flex-container align-middle align-justify ${
              index < completion.length - 1 ? 'border-light-bottom' : ''
            }`}>
            <div>
              <Bullet color={`${colors.assertive}${hexOpacityArray[index]}`} />
              <span className="ml-1">{entity.name}</span>
            </div>
            <span className="text-bold">{entity.value}</span>
          </div>
        ))}
        {completion?.length === 0 && <EmptyParagraph />}
        {!completion && <Loading />}
      </div>
    </>
  )
}

export default CompletionRate
