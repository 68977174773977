/* eslint-disable global-require */
import React, { useContext } from 'react'
import LanguageContext from '../../utils/context/LanguageContext'
import iconGoogle from '../../../../images/icon-google-play2.svg'
import iconApple from '../../../../images/icon-apple.svg'
import iconOculus from '../../../../images/icon-oculus-colored.svg'
import iconPico from '../../../../images/icon-pico-colored.svg'

const AppStoreLinks = ({ grid = false }) => {
  const { translations: t } = useContext(LanguageContext)

  const stores = t.stores || []
  const final = stores.map((item, i) => {
    let icon
    switch (item.icon) {
      case 'icon-google-play2':
        icon = iconGoogle
        break
      case 'icon-apple':
        icon = iconApple
        break
      case 'icon-oculus-colored':
        icon = iconOculus
        break
      case 'icon-pico-colored':
        icon = iconPico
        break
      default:
        return null
    }
    return (
      <div
        className={
          grid
            ? 'small-12 medium-6 columns c-launcher__mobile-link--small-container'
            : ''
        }
        key={i}>
        <a
          key={i}
          href={item.url}
          target="_blank"
          rel="noreferrer"
          className={`${
            grid
              ? 'cell c-launcher__mobile-link--small'
              : ' c-launcher__mobile-link'
          }  `}>
          <img
            src={icon}
            alt="icon"
            className="c-launcher__mobile-link--icon"
            color="6fba18"
          />
          <div>
            <h4 className="c-launcher__mobile-link--heading">{item.store}</h4>
            <p className="c-launcher__mobile-link--subtext">{item.text}</p>
          </div>
        </a>
      </div>
    )
  })
  if (grid)
    return (
      <div
        id="mobile-links"
        className="grid-x c-launcher__mobile-link--container">
        {final}
      </div>
    )
  return (
    <div
      id="mobile-links"
      className="cell medium-6 c-launcher__mobile-link--container">
      {final}
    </div>
  )
}

export default AppStoreLinks
