import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { FaPhotoFilm } from 'react-icons/fa6'
import DragAndDrop from '../UI/DragAndDrop'

const MediaEmptyState = ({ setUploadMediaItems, isLink }) => {
  if (isLink)
    return (
      <div
        data-testid="media-empty-state-container"
        className="o-table--page-container grid-container fluid">
        <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
          <span className="text-center">
            <span
              className="o-table__empty--icon"
              style={{ width: '200px', height: '200px' }}>
              <FaPhotoFilm style={{ width: '80px' }} />
            </span>
            <h2 className="text-bold text-dark">
              There are no media files yet!
            </h2>
            <span className="text-stable-dark">
              Go to media page and upload your media files there.
            </span>
          </span>
        </div>
      </div>
    )
  return (
    <div
      data-testid="media-empty-state-container"
      className="o-table--page-container grid-container fluid">
      <DragAndDrop
        uploadHandler={(files) => {
          setUploadMediaItems(files)
        }}>
        <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
          <span className="text-center">
            <span className="o-table__empty--icon ">
              <FaCloudUploadAlt />
            </span>
            <h2 className="text-bold text-dark">
              Drag and drop files anywhere to upload
            </h2>
            <span className="text-stable-dark cursor-pointer">
              Or use the{' '}
              <span
                htmlFor="upload-media"
                onClick={() => document.querySelector('#upload-media').click()}
                className="text-underline text-assertive">
                Upload media
              </span>{' '}
              button to select files
              <input
                type="file"
                multiple
                id="upload-media"
                accept="video/mp4, .mp4"
                hidden
                onChange={(e) => {
                  setUploadMediaItems([...e.target.files])
                }}
              />
            </span>
          </span>
        </div>
      </DragAndDrop>
    </div>
  )
}
export default MediaEmptyState
