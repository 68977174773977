import { useMutation } from '@apollo/client'
import { updateCameraLocationMutation } from '../../../apollo/query/cameraLocations'
import { handleApolloError } from '../../../utils/errors'

const useUpdateCameraLocation = () => {
  const [_updateCameraLocation] = useMutation(updateCameraLocationMutation, {
    // onError: handleApolloError,
  })

  const updateCameraLocation = ({ variables, ...rest }) => {
    _updateCameraLocation({
      ...rest,
      variables: {
        ...variables,
        description: variables.description ?? '',
      },
    })
  }
  return { updateCameraLocation }
}

export default useUpdateCameraLocation
