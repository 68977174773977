import React from 'react'
import ActivityGraph from './ActivityGraph'
import Bullet from './Bullet'
import colors from '../Editor/helpers/colors'

const ScenariosTab = ({ data }) => {
  return (
    <div className="grid-container fluid">
      <ActivityGraph
        data={data?.attemptsMetrics?.grouped ?? []}
        renderTooltip={(value) => (
          <div className="flex-container align-middle align-justify">
            <span>
              <Bullet color={colors.assertive} />
              <span className="ml-1">Scenarios</span>
            </span>
            <span className="text-bold ml-3 pl-2">{value}</span>
          </div>
        )}
      />
    </div>
  )
}

export default ScenariosTab
