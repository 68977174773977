import React, { useContext } from 'react'
import {
  convertMilliSeconds,
  convertResolution,
  convertSize,
  convertMediaType,
  dateFormat,
} from '../../../utils/format'
import { MediumContext } from './MediumContext'
import LoadingSpinner from '../../UI/LoadingSpinner'
import VideoTrimIcon from '../../../utils/helpers/VideoTrimIcon'
import DataTooltip from '../../UI/DataTooltip'

const FileInformation = () => {
  const {
    medium: [medium],
    time: [, setTime],
    controls: [controls, setControls],
    currentVideoRef,
  } = useContext(MediumContext)

  if (!medium) return <LoadingSpinner dotsOnly />

  const jumpToTimeHandler = (timeInSeconds) => {
    setTime(timeInSeconds * 1000)
    currentVideoRef.current.currentTime = timeInSeconds
    if (controls.isPlaying) {
      currentVideoRef.current.pause()
      setControls({ ...controls, isPlaying: false })
    }
  }
  const trimmedVideoWell = () => (
    <div data-testid="trimmed" className="callout--settings mb-2 p-2">
      <div className="cell grid-x align-middle mb-2 ">
        <div className="cell shrink mr-1">
          <VideoTrimIcon noBackground />
        </div>
        <div className=" cell auto">
          <h3 className="text-primary mb-0">Video is trimmed</h3>
        </div>
      </div>
      <p className=" text-dark mb-1">
        From
        <a
          data-testid="trimmed-link--start"
          className="text-underline text-stable-dark float-right"
          onClick={() => jumpToTimeHandler(medium.trimStart)}>
          <DataTooltip position="left" title="Jump to">
            <span className="cursor-pointer">
              {convertMilliSeconds(medium.trimStart * 1000)}
            </span>
          </DataTooltip>
        </a>
      </p>
      <p className="text-dark mb-0">
        Until
        <a
          data-testid="trimmed-link--end"
          className="text-underline text-stable-dark float-right"
          onClick={() => jumpToTimeHandler(medium.trimEnd)}>
          <DataTooltip position="left" title="Jump to">
            <span className="cursor-pointer">
              {convertMilliSeconds(medium.trimEnd * 1000)}
            </span>
          </DataTooltip>
        </a>
      </p>
    </div>
  )
  const row = (title, value) => (
    <p className="text-dark">
      {title} <span className="float-right text-stable-dark">{value}</span>
    </p>
  )

  return (
    <div className="o-modal__column--information">
      <div data-testid="fileinfopane" className="o-modal--header-h3">
        <h3 className="text-bold">File information</h3>
      </div>
      <div data-testid="fileinfopane-rows">
        {medium.trimEnabled && trimmedVideoWell()}
        {row('Type', convertMediaType(medium.stereoscopic, medium.kind))}
        {row('Length', convertMilliSeconds(medium.duration))}
        {row('Size', convertSize(medium.size))}
        {row('Framerate', `${medium.fps} FPS`)}
        {row('Highest resolution', convertResolution(medium.width))}
        {row('Date uploaded', dateFormat(medium.createdAt))}
      </div>
    </div>
  )
}

export default FileInformation
