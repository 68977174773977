import React, { useContext } from 'react'
import Dropdown from '../../../UI/Menu/Dropdown'
import { FaStar } from 'react-icons/fa'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import SetCustomPointModal from './SetCustomPointModal'
import { getPointClass } from '../../helpers/elementHelper'

const POINTS = [0, 1, 2]

const AnswerPointsDropdown = ({ element }) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const { updateElement } = useAddUpdateElement()
  const scoringSystemTitle =
    scenario.scoringSystem.kind === 'SUBTRACT'
      ? 'Subtracting points'
      : 'Adding points'

  const setPoints = (e) => {
    const points = Number(e.currentTarget.dataset.points)
    updateElement({
      variables: {
        ...element,
        points,
      },
    })
  }

  const handleSetPoint = (points) => {
    updateElement({
      variables: {
        ...element,
        points,
      },
    })
  }

  const renderPointsList = () => {
    return POINTS.map((point, index) => {
      const active = element.points === point
      const label = point === 1 ? 'point' : 'points'

      return (
        <li
          className="o-dropdown__list-item"
          key={point}
          data-points={point}
          onClick={setPoints}>
          <span
            className={`o-dropdown__link ${active ? 'text-assertive' : 'text-dark'}`}>
            <span
              className={`o-bullet o-bullet--${getPointClass(index, scenario.scoringSystem.kind)} u-small-no-ml`}
            />
            {point} {label}
          </span>
        </li>
      )
    })
  }

  return (
    <>
      <Dropdown
        id={`answer-points-menu-${element.id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        minWidth={200}
        button={
          <ButtonGroup.Button
            className="o-button o-button--square-small o-button--light o-button--no-active text-bold flex-container align-middle mb-0"
            data-toggle={`answer-points-menu-${element.id}`}
            disabled={scenario.scoringSystem.kind === 'DISABLED'}>
            <FaStar className="u-color-stable-dark" />
          </ButtonGroup.Button>
        }>
        <>
          <li className="o-dropdown__item o-dropdown__item--inactive text-small text-lighter text-stable-dark">
            {scoringSystemTitle}
          </li>
          {renderPointsList()}
          <li className="o-dropdown__divider" />
          <li
            className="o-dropdown__list-item"
            data-open={`set-custom-point-modal-${element.id}`}>
            <span
              className={`o-dropdown__link ${POINTS.includes(element.points) ? 'text-dark' : 'text-assertive'}`}>
              <span
                className={`o-bullet 
                  o-bullet--${getPointClass(POINTS.length + 1, scenario.scoringSystem.kind)} u-small-no-ml`}
              />
              Set custom points
            </span>
          </li>
        </>
      </Dropdown>

      <SetCustomPointModal
        id={`set-custom-point-modal-${element.id}`}
        appendTo="#sceneario-editor-sidebar"
        submitCallback={handleSetPoint}
        element={element}
      />
    </>
  )
}

export default AnswerPointsDropdown
