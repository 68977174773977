import React, { useEffect, useState } from 'react'
import LanguageContext from './LanguageContext'
import nl from '../lang/Launcher/nl.json'
import en from '../lang/Launcher/en.json'

const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState('nl')
  const [translations, setTranslations] = useState()
  const languages = { en, nl }

  // initialize current language based on browser
  useEffect(() => {
    const lang = window.navigator.userLanguage || window.navigator.language
    const ln = lang.split('-')[0]
    if (languages[ln]) {
      setLanguage(ln)
    } else {
      setLanguage('en')
    }
  }, [])

  // update i18n on language change
  useEffect(() => {
    setTranslations(languages[language])
  }, [language])

  return (
    <LanguageContext.Provider
      value={{
        language,
        languages,
        translations,
        setLanguage,
      }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContextProvider
