import React from 'react'

const Bullet = ({ color }) => {
  return (
    <span
      style={{
        display: 'inline-block',
        width: '12px',
        height: '12px',
        borderRadius: '5px',
        backgroundColor: color,
      }}
    />
  )
}

export default Bullet
