import React, { createContext, useState, useRef, useEffect } from 'react'

const MediumContext = createContext()

const MediumProvider = ({ medium: mediumProp, children }) => {
  const [controls, setControls] = useState({
    isPlaying: false,
    isMuted: false,
    volume: 1,
    previousVolume: null,
  })
  const [time, setTime] = useState(0)
  const [medium, setMedium] = useState(mediumProp)
  const store = {
    medium: [medium, setMedium],
    controls: [controls, setControls],
    time: [time, setTime],
    currentVideoRef: useRef({ currentTime: 0 }),
  }
  useEffect(() => {
    if (mediumProp) setMedium(mediumProp)
  }, [mediumProp])
  return (
    <MediumContext.Provider value={store}>{children}</MediumContext.Provider>
  )
}

export { MediumContext, MediumProvider }
