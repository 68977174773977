import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { getScenarioWithElementsQuery } from '../../../apollo/query/scenarios'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { HashRouter } from 'react-router-dom'

export const ScenarioEditorContext = React.createContext({
  scenario: {},
})

const ScenarioEditorProvider = ({ scenarioId, children }) => {
  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(
    getScenarioWithElementsQuery,
    {
      variables: {
        id: scenarioId,
      },
      skip: !scenarioId,
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    // Start polling during publishing
    if (data) {
      const statuses = data?.scenarios.flatMap((scenario) => [
        ...scenario.statuses,
        ...(scenario.testScenario ? [scenario.testScenario.status] : []),
      ])
      const isPublishing =
        statuses.includes('PUBLISHING') ||
        statuses.includes('PUBLISHING_FOR_TESTING')

      if (isPublishing) startPolling(5000)
      if (!isPublishing) stopPolling()
    }
  }, [data])

  if (loading || !data)
    return (
      <Delay>
        <LoadingSpinner text="Loading scenario editor" />
      </Delay>
    )

  if (error) return <h1>Oops.. Something went wrong </h1>

  return (
    <ScenarioEditorContext.Provider
      value={{ scenario: data.scenarios[0], refetch }}>
      <HashRouter>{children}</HashRouter>
    </ScenarioEditorContext.Provider>
  )
}

export default ScenarioEditorProvider
