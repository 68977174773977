import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import useFileUploader from '../../../hooks/useFileUploader'
import { handleApolloError } from '../../../utils/errors'
import {
  addSuccessAlert,
  addErrorAlert,
  removeAlert,
} from '../../../utils/helpers/alerts'
import {
  pollingImportQuery,
  importFileMutation,
} from '../../../apollo/query/imports'

const ImportTSVFile = ({
  modalId,
  kind,
  shouldStartUploading,
  hasFileSelected,
  callback,
  groupIds = [],
}) => {
  const [uploading, setUploading] = useState(false)
  const [importId, setImportId] = useState(false)
  const [selectedFiles, setFiles] = useState([])
  const [uploadItems, setUploadItems] = useFileUploader()
  const [notificationId, setNotificationId] = useState()
  const [importNotificationId, setImportNotificationId] = useState()

  const { data: importData, startPolling, stopPolling } = useQuery(
    pollingImportQuery,
    {
      variables: {
        id: importId,
      },
    }
  )

  const [importGroups, { loading, error }] = useMutation(importFileMutation, {
    onError: handleApolloError,
    onCompleted: ({ addImport }) => {
      // uploading is complete, now wait for import to finish.
      setImportId(addImport.import.id)
      $(`#${modalId}`).foundation('close')
    },
  })

  const openInputFileHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector('#browse-files').click()
  }
  const importHandler = () => {
    setUploading(true)
    uploadItems.forEach((file) => {
      importGroups({
        variables: {
          kind,
          groupIds,
          blobId: file.blobId,
        },
      })
    })
  }

  useEffect(() => {
    // now we are importing, if all states are complete, we can stop polling
    if (importData) {
      const states = importData.imports.map(({ state }) => state)
      if (states.every((state) => state === 'COMPLETED')) {
        // success
        removeAlert(importNotificationId)
        removeAlert(notificationId)
        const alertId = addSuccessAlert({
          title: `Finished adding ${kind.toLowerCase()}`,
          icon: 'success',
          timeout: 6000,
        })
        setNotificationId(alertId)
        stopPolling()
        callback()
      } else if (states.some((state) => state === 'FAILED')) {
        // failed
        removeAlert(notificationId)
        removeAlert(importNotificationId)
        const errors = importData.imports.map(({ errorMessages: em }) => em)
        stopPolling()
        addErrorAlert({
          title: 'Oops, something went wrong',
          subtitle: errors[0].join(',  '),
          icon: 'error',
          timeout: false,
        })
        callback()
      } else if (importData.imports[0].progress) {
        // in progress
        removeAlert(notificationId)
        removeAlert(importNotificationId)

        const alertId = addSuccessAlert({
          title: `Importing ${kind.toLowerCase()}`,
          subtitle: `Progress: ${importData.imports[0].progress}%`,
          icon: 'progress',
          timeout: false,
        })
        setImportNotificationId(alertId)
      }
    }
  }, [importData])

  useEffect(() => {
    if (
      uploadItems.length &&
      !uploading &&
      uploadItems.every((file) => file.blobId)
    ) {
      importHandler()
    }
  }, [uploadItems])

  useEffect(() => {
    if (shouldStartUploading) setUploadItems(selectedFiles)
  }, [shouldStartUploading])

  useEffect(() => {
    if (uploading && loading) {
      const alertId = addSuccessAlert({
        title: `Uploading ${kind.toLowerCase()}`,
        subtitle: "You will be notified when it's finished",
        icon: 'upload',
        timeout: false,
      })
      setNotificationId(alertId)
    }
    if (error) {
      if (notificationId) {
        removeAlert(notificationId)
        setNotificationId(null)
      }
    }
  }, [loading, error])

  useEffect(() => {
    if (importId) {
      setTimeout(() => {
        startPolling(kind === 'GROUPS' ? 200 : 1000)
      }, 2000)
    }
  }, [importId])

  return (
    <>
      <label className="c-form__label--required">Upload TSV or TXT file</label>
      <div className="grid-x grid-margin-x">
        <div className="cell auto mr-0">
          <input
            type="text"
            className="cursor-pointer"
            disabled
            onClick={openInputFileHandler}
            value={selectedFiles[0]?.name}
          />
          <input
            type="file"
            id="browse-files"
            hidden
            accept=".txt,.tsv"
            onChange={({ target: { files } }) => {
              setFiles([...files])
              hasFileSelected(files.length)
            }}
          />
        </div>
        <div className="cell shrink">
          <button
            className="button wide secondary hollow text-bold"
            onClick={openInputFileHandler}>
            Browse
          </button>
        </div>
      </div>
    </>
  )
}
export default ImportTSVFile
