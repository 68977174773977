const features = [
  { value: 'CDN', name: 'Configure custom CDN' },
  { value: 'ORGANIZATION_API', name: 'Organization API' },
  { value: 'STORE_SELLER', name: 'Sell courses in Warp Store' },
  { value: 'EXPORT_TRAINEE_INFO', name: 'Export trainee info to Data Studio' },
  {
    value: 'ENTERPRISE_SECURITY',
    name: 'High security requirements for managers',
  },
  { value: 'VOICE', name: 'Voice control settings' },
  { value: 'OAUTH', name: 'OAuth integrations' },
  { value: 'OCN', name: 'OCN integrations' },
  { value: 'MPC_ARROWS', name: 'Show arrows next to MPC answers' },
  { value: 'ANONYMOUS_PUBLIC_LINK', name: 'Anonymous public links' },
  {
    value: 'ANONYMIZE_ATTEMPTS',
    name: 'Automatic deletion of attempts and trainees',
  },
  { value: 'HYBRID_NEW_FLOW', name: 'Hybrid new Flow' },
  { value: 'BHVR', name: 'BHVR theme' },
  { value: 'VIDEO_TARBALLS', name: 'Legacy video archives' },
]

export default features
