import React, { useEffect, useState } from 'react'

const BillingContactForm = ({ billingValues, onSubmit, loading }) => {
  const [values, setValues] = useState(billingValues)

  useEffect(() => {
    setValues(billingValues)
  }, [billingValues])

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(values)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="medium-6 small-12 columns">
          <label>
            First name
            <span className="text-assertive"> *</span>
          </label>
          <input
            type="text"
            required
            name="billingFirstName"
            value={values.billingFirstName}
            onChange={handleChange}
          />
        </div>
        <div className="medium-6 small-12 columns">
          <label>
            Last name
            <span className="text-assertive"> *</span>
          </label>
          <input
            type="text"
            required
            name="billingLastName"
            value={values.billingLastName}
            onChange={handleChange}
          />
        </div>
      </div>

      <label>
        Email address
        <span className="text-assertive"> *</span>
      </label>
      <input
        type="email"
        required
        name="billingEmail"
        value={values.billingEmail}
        onChange={handleChange}
      />

      <div className="flex-container align-right mt-3">
        <button
          className="button primary wide o-button--loading"
          type="submit"
          disabled={loading}>
          Confirm
        </button>
      </div>
    </form>
  )
}

export default BillingContactForm
