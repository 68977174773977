import React from 'react'

const LmsFields = ({ values, handleChange }) => {
  const handleChangeAnonymous = ({ target }) => {
    handleChange({ target: { name: target.name, value: target.value === '1' } })
  }

  return (
    <>
      <div className="mt-1">
        <label htmlFor="anonymous">Keep trainee information?</label>
        <label className="o-radio">
          <input
            type="radio"
            name="anonymous"
            onChange={handleChangeAnonymous}
            value="0"
            defaultChecked={!values.anonymous}
          />
          <span></span>
          Yes, store names and email addresses of trainees in Warp Studio
        </label>
        <label className="o-radio">
          <input
            type="radio"
            name="anonymous"
            value="1"
            onChange={handleChangeAnonymous}
            defaultChecked={values.anonymous}
          />
          <span></span>
          No, anonymize names and email addresses of trainees
        </label>
      </div>
    </>
  )
}

export default LmsFields
