import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const CourseOptionDropdown = ({ id, setCurrentCourse, courses }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (isHovered) {
    return (
      <Dropdown
        id={`course-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`course-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => {
              setCurrentCourse(courses.find((i) => i.id === id))
              $('#edit-course-modal').foundation('open')
            }}>
            Edit course
          </li>
          {!!courses.find((i) => i.id === id) && (
            <>
              <hr className="mb-1 mt-1"></hr>
              <li
                className="o-dropdown__list-item text-normal text-dark"
                onClick={() => {
                  setCurrentCourse(courses.find((i) => i.id === id))
                  $('#delete-course-modal').foundation('open')
                }}>
                Delete course
              </li>
            </>
          )}
        </>
      </Dropdown>
    )
  }
  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`course-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default CourseOptionDropdown
