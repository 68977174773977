function setCorrectOrder() {
  $('[data-fields-to-order]')
    .not(":has([name*='_destroy'][value='1'])")
    .each(function(index) {
      $(this)
        .find('[data-order]')
        .val(index + 1);

      var html = $(this)
        .find('[data-answer-label]')
        .html();

      var label = String.fromCharCode(parseInt(97 + index)).toUpperCase();
      var replacement = 'Answer ' + label;

      if (html.startsWith('Label for direction')) {
        replacement = 'Label for direction ' + label;
      }

      if (html.startsWith('Label for hotspot')) {
        replacement = 'Label for hotspot ' + label;
      }

      $(this)
        .find('[data-answer-label]')
        .html(replacement);
    });
}

export function init() {
  // add sortable and save ordering of elements
  if ($('[data-cocoon-with-order]').length > 0) {
    setCorrectOrder();

    $('[data-cocoon-with-order]')
      .on('cocoon:after-insert', function() {
        setCorrectOrder();
      })
      .on('cocoon:after-remove', function() {
        setCorrectOrder();
      });
  }
}
