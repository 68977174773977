export const init = () => {
  $('.o-action-list').each(function() {
    var parentWidth = $(this).parent();
    var rightEdge = parentWidth.position().left + parentWidth.width();
    var elementWidth = $(this).outerWidth();
    var windowWidth = $(window).width();

    if (rightEdge + elementWidth > windowWidth) {
      $(this).addClass('o-action-list--inner-hover');
    } else {
      $(this).removeClass('o-action-list--inner-hover');
    }
  });
}
