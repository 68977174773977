import React from 'react'
import ButtonGroup from '../../../../UI/Form/ButtonGroup/ButtonGroup'

const ElementSizeButtonGroup = ({ onChange, active }) => {
  return (
    <ButtonGroup>
      <ButtonGroup.Button
        onClick={() => onChange('NORMAL')}
        active={!active || active === 'NORMAL'}>
        Normal
      </ButtonGroup.Button>
      <ButtonGroup.Button
        onClick={() => onChange('WIDE')}
        active={active === 'WIDE'}
        last>
        Wide
      </ButtonGroup.Button>
    </ButtonGroup>
  )
}

export default ElementSizeButtonGroup
