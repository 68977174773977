import React from 'react'
import { useMutation } from '@apollo/client'
import { default as S } from '../../../utils/lang/en.json'
import { deleteMediumMutation } from '../../../apollo/query/media'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import Modal from '../../UI/Modal'

const DeleteMediumModal = ({ id, appendTo, mediumIds = [], callback }) => {
  const [deleteMedium] = useMutation(deleteMediumMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      callback()
      addSuccessAlert({ title: 'Media deleted' })
    },
  })

  let title
  if (mediumIds.length === 1) {
    title = `${S.strings.confirmDeleteTextSingle} file`
  } else {
    title = `${S.strings.confirmDeleteText} files`
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      textAssertive
      closable={false}
      headerText={title}
      subtitle="This action cannot be undone"
      submitButtonText="Yes, I'm sure"
      useDefaultButtons
      submitHandler={() => {
        mediumIds.forEach((i) => {
          deleteMedium({ variables: { id: i } })
        })
      }}
    />
  )
}
export default DeleteMediumModal
