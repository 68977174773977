const validateImageExtension = (file) => {
  const { type } = file
  const allowedFiletypes = ['image/png', 'image/jpeg']
  return allowedFiletypes.includes(type)
}

const validateFileMaxSize = (file, maxSizeInMB) => {
  const fileSizeInMB = (file.size / 1024 / 1024).toFixed(4)
  return fileSizeInMB < maxSizeInMB
}

const validateNotEmpty = (value) => {
  return value.trim().length !== 0
}

const validatePassword = (value) => {
  const lengthValidation = value.length >= 12
  const containsLowerCase = /(?=[a-z])/.test(value)
  const containsUpperCase = /(?=[A-Z])/.test(value)
  const containsDigit = /\d/.test(value)
  const allChecksPassed =
    lengthValidation && containsLowerCase && containsUpperCase && containsDigit
  return {
    allChecksPassed,
    checks: {
      lengthValidation,
      containsLowerCase,
      containsUpperCase,
      containsDigit,
    },
  }
}

const validatePasswordsMatch = (value1, value2) => {
  return value1 === value2
}

export {
  validateImageExtension,
  validateNotEmpty,
  validatePassword,
  validatePasswordsMatch,
  validateFileMaxSize,
}
