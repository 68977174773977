import React, { useContext, useEffect, useState } from 'react'
import TableFilters from '../UI/Table/TableFilters'
import { useQuery } from '@apollo/client'
import {
  getPurchasedScenariosQuery,
  getScenarioNameQuery,
} from '../../apollo/query/scenarios'
import PeriodDropdown from './PeriodDropdown'
import TableHeader from '../UI/TableHeader'
import { dateFormat } from '../../utils/format'
import { getAttemptsFilterDataQuery } from '../../apollo/query/attempts'
import ActivityContext from '../../utils/context/ActivityContext'
import NumberInput from '../UI/Form/NumberInput'
import { getUserNameByIdQuery } from '../../apollo/query/user'

// remove duplicates
const deviceTypeOptions = {
  SMARTPHONE: 'Smartphone',
  TABLET: 'Tablet',
  HEADSET: 'VR headset',
  DESKTOP: 'Desktop',
}

const HeaderFilters = ({
  showEmptyState,
  setShowEmptyState,
  title,
  rightBar = <></>,
}) => {
  const { apiVars, searchParams, setSearchParams } = useContext(ActivityContext)
  const [more, setMore] = useState(
    searchParams.scores[0] > searchParams.scores[1] ? 0 : 1
  )
  const [score, setScore] = useState(
    (more
      ? Number(searchParams.scores[0])
      : Number(searchParams.scores[0]) + 1) ?? 0
  )

  const [filters, setFilters] = useState([])
  const { data: purchasedScenariosData } = useQuery(getPurchasedScenariosQuery)
  const { data: attemptsFilterData } = useQuery(getAttemptsFilterDataQuery, {
    variables: {
      fromDateTime: apiVars.fromDateTime,
      toDateTime: apiVars.toDateTime,
    },
  })
  const { data: scenarioSelected } = useQuery(getScenarioNameQuery, {
    variables: {
      id: searchParams.scenarioIds[0],
    },
    skip:
      !searchParams.scenarioIds?.[0] ||
      !attemptsFilterData?.attempts ||
      attemptsFilterData.attempts.scenarios.find(
        (s) => s.id === searchParams.scenarioIds[0]
      ),
  })

  const { data: traineeSelected } = useQuery(getUserNameByIdQuery, {
    variables: {
      id: searchParams.userIds[0],
    },
    skip:
      !searchParams.userIds?.[0] ||
      !attemptsFilterData?.attempts ||
      attemptsFilterData.attempts.users.find(
        (s) => s.id === searchParams.userIds[0]
      ),
  })

  const renderScoreFilter = () => {
    const handleSubmit = (e) => {
      e.preventDefault()
      const scores = more
        ? [...Array(5 - score).keys()].map((a) => a + score + 1)
        : [...Array(score).keys()].reverse()
      setSearchParams({
        ...searchParams,
        scores,
      })
    }

    return (
      <form className="pt-1 mt-0" onSubmit={handleSubmit}>
        <div className="pl-2 pr-2">
          <div className="text-stable-dark mb-1 text-small">Score is</div>
          <div>
            <label className="o-radio">
              <input
                type="radio"
                name="more"
                value="1"
                checked={more}
                onChange={() => setMore(1)}
              />
              <span></span>
              more than
            </label>
            <label className="o-radio">
              <input
                type="radio"
                name="more"
                value="0"
                checked={!more}
                onChange={() => setMore(0)}
              />
              <span></span>
              less than
            </label>
            <label>
              <NumberInput
                value={score}
                min={0}
                step={1}
                max={5}
                changeHandler={(v) => {
                  setScore(v)
                }}
              />
            </label>
          </div>
        </div>
        <div className="flex-container align-right w-100 mt-1 pt-1 border-top pl-2 pr-2">
          <button
            className="o-button text-normal text-bold o-button--auto-width o-button--auto-height"
            disabled={more ? score === 5 : score === 0}
            type="submit">
            Confirm
          </button>
        </div>
      </form>
    )
  }

  const fillFilters = () => {
    const { scenarios, users, groups } = attemptsFilterData.attempts
    setFilters([
      {
        text: 'Scenario',
        type: 'scenarioIds',
        options: [...scenarios, ...(scenarioSelected?.scenarios ?? [])].reduce(
          (scenarios, scenario) => {
            const { id, name } = scenario
            scenarios[id] = {
              value: name,
              render: purchasedScenariosData.purchasedScenarios.some(
                (p) => p.id === id
              ) ? (
                <div>
                  {name}
                  <label className="float-right o-label--custom mb-0 o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                    PURCHASED
                  </label>
                </div>
              ) : (
                name
              ),
            }
            return scenarios
          },
          {}
        ),
        searchable: true,
        multiple: true,
        searchPlaceholder: 'Search by scenario title',
      },
      {
        text: 'Trainee',
        type: 'userIds',
        options: [...users, ...(traineeSelected?.users ?? [])].reduce(
          (users, user) => {
            const { id, name, email, anonymous } = user
            users[id] = anonymous ? 'Anonymous' : name ? name : email
            return users
          },
          {}
        ),

        searchable: true,
        multiple: true,
        searchPlaceholder: 'Search by trainee name',
      },
      {
        text: 'Group',
        type: 'groupIds',
        options: groups.reduce((groups, group) => {
          const { id, name } = group
          groups[id] = name
          return groups
        }, {}),
        searchable: true,
        multiple: true,
        searchPlaceholder: 'Search by group name',
      },
      {
        text: 'Completed',
        type: 'completed',
        options: { yes: 'Yes', no: 'No' },
        headerText: 'Show completed attempts',
      },
      {
        text: 'Device type',
        type: 'deviceTypes',
        options: deviceTypeOptions,
        multiple: true,
        headerText: 'Select device type',
      },
      {
        text: 'Score',
        type: 'score',
        options: null,
        footer: renderScoreFilter(),
      },
      // {
      //   text: 'Testing',
      //   type: 'testing',
      //   options: {
      //     include: 'All',
      //     exclude: 'Exclude testing attempts',
      //     only: 'Only show testing attempts',
      //   },
      //   headerText: 'Show testing attempts',
      //   hasAllOption: false,
      // },
    ])
  }

  useEffect(() => {
    if (attemptsFilterData && purchasedScenariosData) {
      fillFilters()
    }
  }, [
    attemptsFilterData,
    purchasedScenariosData,
    score,
    more,
    scenarioSelected,
    traineeSelected,
  ])

  const getTableRightBar = () => {
    return (
      <>
        {rightBar}
        <PeriodDropdown
          selected={
            searchParams.periodDateStart
              ? [
                  new Date(searchParams.periodDateStart),
                  new Date(searchParams.periodDateEnd),
                ]
              : searchParams.period
          }
          setSelected={(filter) => {
            if (typeof filter === 'object') {
              const periodDateStart = dateFormat(filter[0], 'MM-DD-YY', false)
              const periodDateEnd = dateFormat(filter[1], 'MM-DD-YY', false)
              setSearchParams({
                ...searchParams,
                periodDateStart,
                periodDateEnd,
                period: null,
              })
            } else
              setSearchParams({
                ...searchParams,
                period: filter,
                periodDateStart: null,
                periodDateEnd: null,
              })
          }}
        />
      </>
    )
  }

  const getTableLeftBar = () => {
    if (!searchParams || !attemptsFilterData) return 'Loading'
    return (
      <TableFilters
        filters={filters}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        filterOption={searchParams}
        setFilterOption={setSearchParams}
        type="menu"
      />
    )
  }

  return (
    <div className="grid-container fluid" style={{ paddingTop: '2.5rem' }}>
      <TableHeader
        title={title}
        leftBar={getTableLeftBar()}
        rightBar={getTableRightBar()}
      />
    </div>
  )
}
export default HeaderFilters
