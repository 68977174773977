import { gql } from '@apollo/client'

const getCurrentClientSettingsQuery = gql`
  query currentClient {
    currentClient {
      id
      name
      appTitle
      appDescription
      primaryColor
      secondaryColor
      logoVrUrl
      logoMobileUrl
      locale
      emailIntro
      planBillingCurrency
      planAdditionalWorkspaces
      planAdditionalPublishedScenarios
      planBillingPeriod
      workspaceManagedByClientName
      workspaceKind
      publishedScenariosCount
      originalScenariosCount
      planUsedWorkspaces
      planUsedPublishedScenarios
      ownerName
      createdAt
      features
      canAddWorkspaces
      settings
      plan {
        name
        priceEur
        priceUsd
        includedWorkspaces
        includedPublishedScenarios
        legacy
        features
      }
    }
  }
`

const getIntegrationsQuery = gql`
  query getIntegrations {
    integrations(onlyTrainees: false) {
      id
      kind
      name
      anonymous
      ocnClientId
      ocnClientSecret
      ocnProviderId
      ocnTokenEndpoint
      ocnServiceEndpoint
      ocnLaunchUrl
      apiKey
      oauthClientId
      oauthClientSecret
      oauthScimEnabled
      oauthOrganizationApiEnabled
      createdAt
    }
  }
`

export { getCurrentClientSettingsQuery, getIntegrationsQuery }
