import React, { Suspense, lazy, useState } from 'react'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import useAddUpdateScene from '../../FlowV2/hooks/useAddUpdateScene'
import useSelectedNode from '../../FlowV2/hooks/useSelectedNode'

const Media = lazy(() => import('../../../pages/Media'))

const MediaModal = ({
  id,
  appendTo,
  scenarioId,
  isLink,
  selectedId,
  onLinkClickCallback = () => {},
}) => {
  const [load, setLoad] = useState(false)
  const { updateLinkMediumToScene } = useAddUpdateScene()
  const selectedNode = useSelectedNode()


  const handleLinkClick = (mediumId) => {
    updateLinkMediumToScene(mediumId, selectedNode.id).then(() => {
      addSuccessAlert({ title: 'Video has been linked to scene' })
      onLinkClickCallback(mediumId)
    })
    $(`#${id}`).foundation('close')
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      width="90%"
      closable={false}
      onOpen={() => setLoad(true)}>
      <div
        className="bg-light pt-3 pb-3 border-radius"
        style={{ height: '80vh' }}>
        <div className="h-100" style={{ position: 'relative' }}>
          <Suspense fallback={<div>Loading...</div>}>
            {load && (
              <Media
                isLink={isLink}
                scenarioId={scenarioId}
                selectedId={selectedId}
                onLinkClick={handleLinkClick}
              />
            )}
          </Suspense>
        </div>
      </div>
    </Modal>
  )
}
export default MediaModal
