import React from 'react'
import { FaPlus, FaQuestion } from 'react-icons/fa'
import AddScenarioModal from '../../components/Scenarios/Modal/AddScenarioModal'
import TableHeader from '../../components/UI/TableHeader'
import emptyIcon from '../../../../images/empty-icon.svg'
import HelpDropdown from '../../components/Layout/HelpDropdown'

const ScenariosEmptyPage = () => (
  <div
    id="scenarios"
    style={{
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
    }}>
    <AddScenarioModal id="addScenarioModal" appendTo="#scenarios" />
    <div className="grid-container fluid">
      <TableHeader
        title="Scenario"
        length={0}
        rightBar={
          <span className="cell shrink" style={{ zIndex: '2' }}>
            <button
              data-open="addScenarioModal"
              className="button primary mb-0 cursor-pointer">
              <FaPlus className="mr-1" /> New Scenario
            </button>
          </span>
        }
      />
    </div>
    <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
      <div className="text-center">
        <div
          className="o-table__empty--icon"
          style={{ width: '200px', height: '200px' }}>
          <img src={emptyIcon} width="100px" alt="Empty scene" />
        </div>
        <h2 className="text-bold text-dark">No scenarios yet</h2>
        <p>
          Create your first scenario now. Need guidance? <br />
          Click the help-icon and explore our resources.
        </p>
        <div className="flex-container align-middle">
          <button
            className="o-button flex-container align-middle text-normal text-bold mr-1"
            data-toggle="addScenarioModal">
            <FaPlus className="mr-1" />
            New Scenario
          </button>
          <HelpDropdown
            id="user-help-menu"
            button={
              <button
                className="o-button o-button--light flex-container align-middle text-normal text-bold"
                data-toggle="user-help-menu">
                <FaQuestion className="mr-1" />
                Explore our resources
              </button>
            }
          />
        </div>
      </div>
    </div>
  </div>
)

export default ScenariosEmptyPage
