import { useMemo } from 'react'
import { useLocation } from 'react-router'

const useShowVideoEditor = () => {
  const location = useLocation()
  const showVideoEditor = useMemo(() => {
    return location.pathname.includes('/video')
  }, [location.pathname])
  return showVideoEditor
}

export default useShowVideoEditor
