import React, { Component } from 'react'
import { observer } from 'mobx-react'

@observer
class AnswerMenu extends Component {
  openCustomScoreModal() {
    this.props.answer.openCustomScoreUrl()
  }

  openCustomRandomizedScenesModal() {
    this.props.answer.openCustomRandomizedScenesUrl()
  }

  setPoints(points, e) {
    e.stopPropagation()
    const { answer } = this.props
    answer.points = points
    answer.save()
  }

  pointOptions() {
    const scoringSystem = this.props.answer.scoring_system
    const color = [
      'stable-dark',
      'royal',
      scoringSystem === 'add' ? 'energized' : 'assertive',
      'balanced',
    ]
    const points = [0, -1, -2]

    if (this.props.answer.custom_score != null) {
      points.push(this.props.answer.custom_score)
    }
    return points.map((point, index) => {
      const active =
        Math.abs(this.props.answer.points) === Math.abs(point)
          ? 'o-dropdown__item o-dropdown__item--active'
          : 'o-dropdown__item'

      const label = Math.abs(point) === 1 ? 'point' : 'points'
      return (
        <li
          className={active}
          key={point}
          onClick={(e) => this.setPoints(point, e)}>
          <span className="o-dropdown__link">
            <span
              className={`o-bullet o-bullet--${color[index]} u-small-no-ml`}
            />
            {Math.abs(point)} {label}
          </span>
        </li>
      )
    })
  }

  setRandomized(number, e) {
    e.stopPropagation()
    const { answer } = this.props
    answer.randomized = number
  }

  randomizeOptions() {
    const options = [0, 2, 3]

    return options.map((number) => {
      const active =
        this.props.answer.randomized + 1 === number
          ? 'o-dropdown__item o-dropdown__item--active'
          : 'o-dropdown__item'

      return (
        <li
          className={active}
          key={number}
          onClick={(e) => this.setRandomized(number - 1, e)}>
          <span className="o-dropdown__link">
            {number === 0 ? "Don't randomize" : `${number} scenes`}
          </span>
        </li>
      )
    })
  }

  render() {
    const { answer } = this.props

    return (
      <ul
        className="o-dropdown o-dropdown--left"
        style={{
          width: 230,
          left: -28,
          top: 15,
          display: this.props.display ? 'block' : 'none',
        }}>
        <li className="o-dropdown__item o-dropdown__item--label">
          {answer.letter}. {answer.name}
        </li>
        {answer.scoring_system !== 'disabled' && (
          <li
            className="o-dropdown__item o-dropdown__item--open-dropdown opens-right"
            style={{ position: 'relative' }}>
            <a className="o-dropdown__link">
              <span className="o-dropdown__link-aside">
                {Math.abs(answer.points)}
              </span>
              Points
            </a>
            <ul
              className="o-dropdown o-dropdown--left"
              style={{ width: 230, top: 0 }}>
              <li className="o-dropdown__item o-dropdown__item--label">
                {answer.scoring_system === 'add'
                  ? 'Adding points'
                  : 'Subtracting points'}
              </li>
              {this.pointOptions()}
              <li className="o-dropdown__divider" />
              <li
                className="o-dropdown__item"
                onClick={() => this.openCustomScoreModal()}>
                <span className="o-dropdown__link">Set custom points</span>
              </li>
            </ul>
          </li>
        )}
        <li
          className="o-dropdown__item o-dropdown__item--open-dropdown opens-right"
          style={{ position: 'relative' }}>
          <a className="o-dropdown__link">
            <span className="o-dropdown__link-aside">
              {answer.randomized > 0 ? answer.randomized + 1 : 'No'}
            </span>
            Randomize
          </a>
          <ul
            className="o-dropdown o-dropdown--left"
            style={{ width: 230, top: 0 }}>
            <li className="o-dropdown__item o-dropdown__item--label o-dropdown__item--shuffle">
              Randomize answer to
            </li>
            {this.randomizeOptions()}
            <li className="o-dropdown__divider" />
            <li
              className="o-dropdown__item"
              onClick={() => this.openCustomRandomizedScenesModal()}>
              <span className="o-dropdown__link">Set custom number</span>
            </li>
          </ul>
        </li>
      </ul>
    )
  }
}

export default AnswerMenu
