import React from 'react'
import Copy from '../../UI/Copy/Copy'

const LtiCredentialBox = ({ name, ltiKey, ltiSecret, ltiPath }) => {
  const rows = [
    { title: 'Consumer Key', value: ltiKey },
    { title: 'Shared secret', value: ltiSecret },
    { title: 'URL', value: `${window.location.origin}${ltiPath}` },
  ]

  return (
    <div className="mt-3 border border-radius u-border-stable">
      <div className="p-2">
        <h3>Generic Credendials</h3>
        <p className="mb-0">
          {`Copy and paste these credentials to configure a LTI 1.1 provider in ${name}. This only has to be done once.`}
        </p>
      </div>
      <div className="p-1 pb-0 border-top">
        <table className="scroll">
          <tbody>
            {rows.map((row) => (
              <tr key={row.value}>
                <td className="pb-0">
                  <h4 className="text-bold mb-0 u-no-wrap">{row.title}</h4>
                </td>
                <td className="pb-0">
                  <p className="mb-0 u-no-wrap">{row.value}</p>
                </td>
                <td className="pb-0 w-100">
                  <Copy value={row.value} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LtiCredentialBox
