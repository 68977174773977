import React, { useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'
import { getCurrentClientSettingsQuery } from '../../../apollo/query/settings'
import { addErrorAlert, addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import LogoUploader from './LogoUploader'
import ColorCard from './ColorCard'
import { updateClientBrandingMutation } from '../../../apollo/query/clients'

const BrandingTab = () => {
  const { loading, data, error } = useQuery(getCurrentClientSettingsQuery)
  const formRef = useRef()
  const [updateBrandingMeta, { loading: updating }] = useMutation(
    updateClientBrandingMutation,
    {
      onError: handleApolloError,
      refetchQueries: [
        {
          query: getCurrentClientSettingsQuery,
        },
      ],
    }
  )
  const [updateBrandingDetails] = useMutation(updateClientBrandingMutation, {
    onError: handleApolloError,
    refetchQueries: [
      {
        query: getCurrentClientSettingsQuery,
      },
    ],
  })

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const handleError = () => {
    addErrorAlert({
      title: 'Something went wrong',
      subtitle: 'Please try again.',
    })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const elements = formRef.current.elements
    try {
      updateBrandingMeta({
        variables: {
          appTitle: elements['appTitle'].value,
          appDescription: elements['appDescription'].value || '',
        },
      })
      addSuccessAlert({ title: 'Branding details have been updated' })
    } catch (ex) {
      handleError()
    }
  }

  const handleUpdateLogos = async (key, value) => {
    try {
      await updateBrandingDetails({
        variables: {
          [key]: value,
        },
      })
      addSuccessAlert({ title: 'Branding logos have been updated' })
    } catch (ex) {
      handleError()
    }
  }

  const handleUpdateColors = async (key, value) => {
    try {
      await updateBrandingDetails({
        variables: {
          [key]: value,
        },
      })
      addSuccessAlert({ title: 'Branding colors have been updated' })
    } catch (ex) {
      handleError()
    }
  }

  return (
    <>
      <div className="row border-bottom pb-3 mb-3">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Branding details</h2>
          <p>
            Set a company name and description that will be displayed with your
            scenarios and courses in the Warp VR app.
          </p>
        </div>
        <form
          ref={formRef}
          onSubmit={handleFormSubmit}
          className="xlarge-6 medium-7 small-12 columns mt-0">
          <label>Company name</label>
          <input
            type="text"
            name="appTitle"
            required
            defaultValue={data.currentClient.appTitle}
          />

          <label> Company description </label>
          <textarea
            name="appDescription"
            placeholder="Write a short description about your company or organization. Trainees can see this description."
            rows="8"
            defaultValue={data.currentClient.appDescription}
          />

          <button
            type="submit"
            className="o-button o-button--assertive float-right mt-1 o-button--loading button primary text-normal"
            disabled={updating}
            aria-hidden="true">
            Confirm
          </button>
        </form>
      </div>

      <div className="row border-bottom pb-2 mb-3" id="branding-logos">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Branding logos</h2>
          <p>
            Upload two logos that are displayed with your scenarios and courses.
            The logos with white background is used in several places. The logo
            with black background is used in the nadir (the ‘floor’ in any VR
            scenario).
          </p>
        </div>
        <div className="medium-6 small-12 columns">
          <div className="row">
            <LogoUploader
              id="logo-uploader"
              uri={data.currentClient.logoMobileUrl}
              onUploadCompleted={(value) =>
                handleUpdateLogos('logoMobileBlobId', value)
              }
            />
            <LogoUploader
              type="nadir"
              id="nadir-logo-uploader"
              title="Logo in nadir"
              uri={data.currentClient.logoVrUrl}
              onUploadCompleted={(value) =>
                handleUpdateLogos('logoVrBlobId', value)
              }
            />
          </div>
        </div>
      </div>

      <div className="row pb-3" id="branding-colors">
        <div className="xlarge-3 medium-4 small-12  columns">
          <h2 className="text-bold">Branding colors</h2>
          <p>
            Decide primary and secondary colors that are used for your
            organization in the Warp VR app. Primary colors are used for primary
            actions, such as buttons and interactive elements. Secondary colors
            are used as highlights, such as scoring and stars.
          </p>
        </div>
        <div className="medium-6 small-12 columns">
          <div className="row">
            <ColorCard
              id="primary-color-picker"
              title="Primary color"
              defaultColor={data.currentClient.primaryColor}
              onSubmit={(value) => handleUpdateColors('primaryColor', value)}
            />
            <ColorCard
              id="secondary-color-picker"
              title="Secondary color"
              defaultColor={data.currentClient.secondaryColor}
              onSubmit={(value) => handleUpdateColors('secondaryColor', value)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default withApollo(BrandingTab)
