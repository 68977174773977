import React from 'react'
import { FaCut } from 'react-icons/fa'

const VideoTrimIcon = ({ noBackground }) => (
  <label
    className={`o-label--custom  text-bold text-stable-dark ${
      noBackground ? '' : 'o-label--stable-lighter'
    }`}
    style={{
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      padding: '4px 10px',
    }}>
    <FaCut />
  </label>
)

export default VideoTrimIcon
