import { numberToCurrency } from './javascript'

const ANNUAL_BILLING_DISCOUNT = 0.9 // 10 percent discount
const getAdditionalPrice = (
  key = 'workspace',
  planBillingCurrency,
  count = 1,
  annual = true
) => {
  const prices = {
    EUR: {
      workspace: 100,
      publishedScenario: 50,
    },
    USD: {
      workspace: 120,
      publishedScenario: 60,
    },
  }
  const currency = planBillingCurrency ?? 'EUR'

  return numberToCurrency(
    prices[currency][key] *
      parseInt(count) *
      (annual ? ANNUAL_BILLING_DISCOUNT * 12 : 1),
    currency
  )
}

const getPlanPrice = (currency, prices = {}, annual = false) => {
  currency = currency ?? 'EUR'
  const currencyToPrice = {
    EUR: 'priceEur',
    USD: 'priceUsd',
  }
  return numberToCurrency(
    prices[currencyToPrice[currency]] *
      (annual ? ANNUAL_BILLING_DISCOUNT * 12 : 1),
    currency
  )
}

export { getAdditionalPrice, getPlanPrice }
