import { useQuery } from '@apollo/client'
import React from 'react'
import { getNotifications } from '../../../apollo/query/store'
import withApollo from '../../../hooks/withApollo'

const AlertList = () => {
  const { data } = useQuery(getNotifications)
  return (
    <div id="alert-container" className="alert-container">
      {data?.notifications}
    </div>
  )
}

export default withApollo(AlertList)
