import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../../UI/Modal'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import NumberInput from '../../../UI/Form/NumberInput'

const SetCustomPointModal = ({
  id,
  appendTo,
  element,
  submitCallback,
  cancelCallback,
}) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const scoringSystemTitle =
    scenario.scoringSystem.kind === 'SUBTRACT'
      ? 'Subtracting points'
      : 'Adding points'

  const [points, setPoints] = useState(element.points)

  useEffect(() => {
    setPoints(element.points)
  }, [element.points])

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="Set custom points"
      subtitle={`Current scoring system set to ${scoringSystemTitle}`}
      useDefaultButtons
      textAssertive
      cancelHandler={cancelCallback}
      submitHandler={() => submitCallback(Number(points))}
      width={400}>
      <form>
        <div className="o-form__group mt-3 mb-1">
          <label className="o-form__label" htmlFor="points">
            Number of points for this answer
          </label>
          <div className="flex-container">
            <NumberInput value={points} changeHandler={setPoints} step={1} min={1}/>
          </div>
        </div>
      </form>
    </Modal>
  )
}
export default SetCustomPointModal
