import React, { useState } from 'react'
import { TabContent } from '../../components/UI/Tabs/Tabs'
import withApollo from '../../hooks/withApollo'
import PlanHistories from '../../components/Admin/Workspaces/PlanHistories'
import AdminWorkspaces from '../../components/Admin/Workspaces/AdminWorkspaces'

const Workspaces = () => {
  const [activeTab, setActiveTab] = useState(0)

  const titles = ['workspaces', 'planHistories']

  const content = [
    <AdminWorkspaces key="workspaces-tab" />,
    <PlanHistories key="planHistories-tab" />,
  ]

  return (
    <>
      <div
        className="c-sub-menu"
        style={{ marginTop: '-2.5rem', marginBottom: '2.5rem' }}>
        <div className="grid-container fluid">
          <div className="c-sub-menu__inner">
            <ul className="c-sub-menu__list">
              <li
                className={`c-sub-menu__list-item ${
                  activeTab === 0 && 'active'
                }`}>
                <span
                  className="c-sub-menu__list-link"
                  onClick={() => setActiveTab(0)}>
                  Workspaces
                </span>
              </li>

              <li
                className={`c-sub-menu__list-item ${
                  activeTab === 1 && 'active'
                }`}>
                <span
                  className="c-sub-menu__list-link"
                  onClick={() => setActiveTab(1)}>
                  Plan history
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <TabContent titles={titles} content={content} activeTab={activeTab} />
    </>
  )
}

export default withApollo(Workspaces)
