import React, { Component } from 'react'
import { observer } from 'mobx-react'
import Connector from './connector'
import AnswerMenu from '../answer-menu'

@observer
class OutgoingConnector extends Component {
  handleEnter = () => {
    this.props.answer.store.activeAnswer = this.props.answer
  }

  handleLeave = () => {
    this.props.answer.store.activeAnswer = null
  }

  showIndex = () => {
    if (this.props.answer.store.activeAnswer === this.props.answer) {
      return 1500
    }

    if (
      this.props.answer.fromScene.isDragging ||
      this.props.answer.fromScene.isFlipped
    ) {
      return 1001
    }

    return 1000 - this.props.answer.priority - this.props.answer.randomizedIndex
  }

  render() {
    const { answer } = this.props
    const coordinates = answer.fromAnswerPosition()

    return (
      <div
        onClick={() => {
          answer.store.activeElement = answer
          answer.showLetterMenu = !answer.showLetterMenu
          answer.showMenu = false
        }}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
        style={{
          width: 0,
          height: 0,
          top: coordinates[1],
          left: coordinates[0] - 12,
          zIndex: this.showIndex(),
          position: 'absolute',
        }}>
        <Connector answer={answer} type="outgoing" />

        {!answer.isRandomized && (
          <AnswerMenu answer={answer} display={answer.showLetterMenu} />
        )}
      </div>
    )
  }
}

export default OutgoingConnector
