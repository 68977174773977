import React from 'react'
import { getRotation } from '../../../Editor/helpers/controls'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import useHasHotspot from '../../../FlowV2/hooks/useHasHotspot'

const Tooltip = ({ id, element, state }) => {
  const [, currentClient] = useCurrentUser()
  const hasHotspot = useHasHotspot()
  
  if (!element || !currentClient) return null

  const rotation = `${getRotation({ x: element.anchorX ?? 0, y: element.anchorY ?? -10 })} 0`

  let labelWidth = 1.8
  if (element.labelSize === 'WIDE') labelWidth *= 2
  const collisionWidth = labelWidth + 0.5

  return (
    <>
      <a-entity
        hideable-element={`visible: ${state.showElements}`}
        rotation={`${rotation} 0`}>
        <a-collision-box id={id} width={collisionWidth} scroll-header-offset={hasHotspot ? 260 : 125}>
          <TextBubble
            id={`L${id}`}
            textValue={element.label || 'Tooltip'}
            width={labelWidth}
          />
        </a-collision-box>
      </a-entity>
    </>
  )
}
export default Tooltip
