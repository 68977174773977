import React, { useRef, useState } from 'react'
import Modal from '../UI/Modal'
import OTP from 'otp'
import QRCode from 'qrcode'
import { useMutation } from '@apollo/client'
import Copy from '../UI/Copy/Copy'
import { enableOtpMutation } from '../../apollo/query/user'
import { addErrorAlert, addSuccessAlert } from '../../utils/helpers/alerts'

const OTP_LENGTH = 6
const OTP_CONFIG = {
  name: 'Warp VR Studio',
}

const ConfigAuthenticatorModal = ({
  id,
  appendTo,
  submitHandler,
  currentUser,
}) => {
  const formRef = useRef()
  const [secret, setSecret] = useState()
  const [enableOtp, { loading }] = useMutation(enableOtpMutation, {
    onError: () => {
      addErrorAlert({
        title:
          'The code you entered is invalid. Please ensure it contains exactly 6 digits and only includes numbers. Please try again.',
      })
    },
    onCompleted: () => {
      submitHandler()
      addSuccessAlert({ title: 'Successfully added an authenticator app' })
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    const otp = formRef.current.elements['otp'].value
    if (otp.length !== OTP_LENGTH || isNaN(Number(otp)))
      return addErrorAlert({
        title:
          'The code you entered is invalid. Please ensure it contains exactly 6 digits and only includes numbers. Please try again.',
      })
    enableOtp({
      variables: {
        secret,
        otp,
      },
    })
  }
  const resetForm = () => formRef.current.reset()

  const generateOtp = () => {
    const otpInstance = new OTP(OTP_CONFIG)
    const label = `${OTP_CONFIG.name}:${currentUser.email}`
    const secret = otpInstance.secret.slice(0, -1) // need to chop the trailing = character
    const url = `otpauth://totp/${encodeURIComponent(
      label
    )}?secret=${secret}&issuer=${encodeURIComponent(OTP_CONFIG.name)}`
    QRCode.toCanvas(document.querySelector('#qrcode'), url)
    setSecret(secret)
  }

  const handleOpening = () => {
    resetForm()
    generateOtp()
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="Configure two-factor authentication"
      submitHandler={handleSubmit}
      onOpen={handleOpening}
      buttons={
        <>
          <button
            className="hollow button secondary wide"
            data-close={id}
            onClick={resetForm}>
            Cancel
          </button>
          <button
            type="submit"
            className="button primary wide"
            disabled={loading}
            onClick={handleSubmit}>
            Confirm
          </button>
        </>
      }>
      <p className="mt-3 mb-2">
        Authenticator apps and browser extensions like 1Password, Authy,
        Microsoft Authenticator, etc. generate one-time passwords that are used
        as a second factor to verify your identity when prompted during sign-in.
      </p>
      <h3>1. Scan the QR code</h3>
      <p className="mb-2">
        Use an authenticator app or browser extension to scan. Learn more.
      </p>
      <canvas id="qrcode" style={{ marginLeft: '-15px', marginTop: '-15px' }} />
      <p className="mb-1">
        Unable to scan? Use this setup key to manually configure your
        authenticator app.
      </p>
      <div className="mb-2 flex-container align-middle text-normal">
        <div
          className="mr-1"
          style={{ maxWidth: '70%', wordWrap: 'break-word' }}>
          {secret}
        </div>
        <Copy value={secret} />
      </div>
      <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
        <input
          autoComplete="false"
          name="hidden"
          type="text"
          style={{ display: 'none' }}
        />
        <label>2. Verify the code from the app</label>
        <input
          type="text"
          placeholder="XXXXXX"
          minLength={6}
          maxLength={6}
          required
          autoComplete="one-time-code"
          className="w-auto"
          name="otp"
        />
      </form>
    </Modal>
  )
}

export default ConfigAuthenticatorModal
