import React from 'react'
import PublishedScenarios from './PublishedScenarios'
import SubscriptionDetail from './SubscriptionDetail'
import WorkspaceSlots from './WorkspaceSlots'

const SubscriptionTab = ({ currentClient }) => {
  return (
    <>
      <SubscriptionDetail currentClient={currentClient} />

      {!currentClient.plan.legacy && (
        <>
          <PublishedScenarios currentClient={currentClient} />
          <WorkspaceSlots currentClient={currentClient} />
        </>
      )}
    </>
  )
}
export default SubscriptionTab
