import React, { useState } from 'react'
import DataTooltip from '../../UI/DataTooltip'

const TraineeUserGroup = ({ count, names }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (count <= 0) return '-'

  const formatName = (value) => `<li style="text-align:start;">${value}</li>`

  if (isHovered) {
    const title = `
    <div style="padding:10px;">
   <span className="text-white" style="text-align:start;font-weight:700;">Member of ${count} group${
      count === 1 ? '' : 's'
    }</span>
    <ul style="margin-right:10px; margin-bottom:0; margin-top:10px;">
   ${names
     .sort()
     .map((name) => formatName(name))
     .join('')}
    </ul>
    </div>
      `

    return (
      <DataTooltip title={title}>
        <span
          onMouseLeave={() => setIsHovered(false)}
          data-testid="hovered"
          className="text-underline">
          {count} group{count === 1 ? '' : 's'}
        </span>
      </DataTooltip>
    )
  }

  return (
    <span
      data-testid="hoverable"
      className="text-underline"
      onMouseEnter={() => setIsHovered(true)}>
      {count} group{count === 1 ? '' : 's'}
    </span>
  )
}

export default TraineeUserGroup
