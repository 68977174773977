import * as componentOpenTab from './component-open-tab'
import * as componentOrderCocoon from './component-order-cocoon'
import * as componentSelect from './component-select'
import * as componentPublishSelect from './component-publish-select'
import * as componentAppBundleId from './component-app-bundle-id'

export function openOnLoad() {
  if ($('[data-modal-on-load]').length) {
    $('#modal [data-modal-content]').html(
      $('[data-modal-on-load]').html()
    );
    $('[data-modal-on-load]').remove();
    $('#modal').foundation('open');
  }
}

export function init() {
  $(document).on('click', '[data-modal][data-modal!=false]', function(e) {
    e.preventDefault();

    const isFluid = $(this).data('fluid');

    $.get($(this).attr('href'), function(data) {
      $('#modal [data-modal-content]').html(data);

      if (isFluid) {
        $('#modal .c-modal').addClass('c-modal--fluid');
      } else {
        $('#modal .c-modal').removeClass('c-modal--fluid');
      }

      // Open Foundation modal
      $('#modal').foundation('open');

      // Trigger foundation again after opening modal (so tabs work etc.)
      $('[data-modal-content]').foundation();

      // Trigger selectTab
      componentOpenTab.init();
      componentOrderCocoon.init();
      componentSelect.init();
      componentPublishSelect.init();
      componentAppBundleId.init();
    });
  });
}
