import { gql } from '@apollo/client'
import { PURCHASE_FIELDS, PRODUCT_FIELDS } from '../fragments'

const adminGetAllProductsQuery = gql`
  ${PRODUCT_FIELDS}
  query products {
    allProducts {
      ...ProductFields
    }
  }
`
const adminGetAllPurchasesQuery = gql`
  ${PURCHASE_FIELDS}
  query adminGetAllPurchases {
    allPurchases {
      ...PurchaseFields
    }
  }
`

const adminAddPurchaseMutation = gql`
  ${PURCHASE_FIELDS}

  mutation adminAddPurchase(
    $sellingClientId: ID!
    $buyingClientId: ID!
    $productId: ID!
    $invoicedBy: PurchaseInvoicedBy
    $purchasedLicenseCount: Int!
  ) {
    addPurchase(
      sellingClientId: $sellingClientId
      buyingClientId: $buyingClientId
      productId: $productId
      purchasedLicenseCount: $purchasedLicenseCount
      invoicedBy: $invoicedBy
    ) {
      purchase {
        ...PurchaseFields
      }
    }
  }
`

const adminUpdateCommissionMutation = gql`
  ${PRODUCT_FIELDS}
  mutation updateCommission($id: ID!, $commission: Int!) {
    updateProductCommission(id: $id, commission: $commission) {
      product {
        ...ProductFields
      }
    }
  }
`

const adminCancelPurchaseSubscriptionMutation = gql`
  ${PURCHASE_FIELDS}
  mutation adminCancelPurchaseSubscription($id: ID!) {
    cancelPurchaseSubscription(id: $id) {
      purchase {
        ...PurchaseFields
      }
    }
  }
`

export {
  adminGetAllProductsQuery,
  adminGetAllPurchasesQuery,
  adminAddPurchaseMutation,
  adminUpdateCommissionMutation,
  adminCancelPurchaseSubscriptionMutation,
}
