import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { kindFormatter } from '../../../utils/helpers/integration'
import Modal from '../../../components/UI/Modal'
import { getIntegrationsQuery } from '../../../apollo/query/settings'
import { handleApolloError } from '../../../utils/errors'
import LmsFields from './LmsFields'
import ApiFields from './ApiFields'
import OcnFields from './OcnFields'
import OauthFields from './OauthFields'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import cache from '../../../apollo/cache'
import DeleteIntegrationModal from './DeleteIntegrationModal'
import {
  addIntegrationMutation,
  updateIntegrationMutation,
} from '../../../apollo/query/integrations'

const kindHasTwoSteps = ['API', 'OAUTH']

const AddEditIntegrationModal = ({ id, appendTo, callback, defaultValues }) => {
  const [values, setValues] = useState(defaultValues)

  const handleCompleted = (integration) => {
    const { integrations } = cache.readQuery({ query: getIntegrationsQuery })
    cache.writeQuery({
      query: getIntegrationsQuery,
      data: { integrations: [...integrations, integration] },
    })
    callback()
  }

  const handleAdd = ({ addIntegration: { integration } }) => {
    addSuccessAlert({
      title: 'Integration was added',
      subtitle: integration.name,
    })
    handleCompleted(integration)
    setValues(integration)
    if (kindHasTwoSteps.includes(values.kind)) {
      setTimeout(() => {
        $(`#${id}`).foundation('open')
      }, 300)
    } else resetForm()
  }

  const handleUpdate = ({ updateIntegration: { integration } }) => {
    addSuccessAlert({
      title: 'Integration was updated',
      subtitle: integration.name,
    })
    handleCompleted(integration)
    resetForm()
  }

  const [addIntegration] = useMutation(addIntegrationMutation, {
    onError: handleApolloError,
    onCompleted: handleAdd,
  })

  const [updateIntegration] = useMutation(updateIntegrationMutation, {
    onError: handleApolloError,
    onCompleted: handleUpdate,
  })

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    })
  }

  const resetForm = () => {
    setTimeout(() => {
      setValues({ name: '' })
    }, 300)
  }

  const submit = () => {
    if (values.id) {
      updateIntegration({
        variables: values,
      })
    } else
      addIntegration({
        variables: values,
      })
  }

  const getFormContent = () => {
    const formFields = {
      LTI: <LmsFields values={values} handleChange={handleChange} />,
      SCORM: <LmsFields values={values} handleChange={handleChange} />,
      OAUTH: <OauthFields values={values} handleChange={handleChange} />,
      OCN: <OcnFields values={values} handleChange={handleChange} />,
      API: (
        <>
          {values.id && (
            <ApiFields values={values} handleChange={handleChange} />
          )}
        </>
      ),
    }

    const getDeleteLink = () => {
      if (!defaultValues.id) return <></>
      return (
        <div className="mt-2">
          <a
            href="#"
            className="text-underline text-normal"
            data-open="delete-integration-modal"
            onClick={(e) => e.preventDefault()}>
            Delete this integration
          </a>
        </div>
      )
    }

    return (
      <form id="user-form">
        <div className="row">
          <label className="c-form__label--required" htmlFor="integration-name">
            Name of the application you want to integrate with
          </label>
          <input
            type="text"
            name="name"
            id="integration-name"
            onChange={handleChange}
            value={values.name}
          />
        </div>
        {formFields[values.kind]}
        {getDeleteLink()}
      </form>
    )
  }

  const getModalBanner = () => {
    if (!defaultValues.id) return <></>
    return (
      <div className="pt-3 pb-3 pl-3 pr-3 border-bottom row align-middle">
        <div className="columns medium-5 text-bold pl-0 text-normal">
          Integration Type
        </div>
        <div className="columns medium-7 text-stable-dark text-normal">
          {kindFormatter(defaultValues.kind)}
        </div>
      </div>
    )
  }

  const getHeaderText = () =>
    values.id
      ? `Edit ${defaultValues.name}`
      : `Add ${kindFormatter(values.kind)} integration`

  useEffect(() => {
    // default values for adding a lms(Scorm and Lti)
    if (!defaultValues.id && ['LTI', 'SCORM'].includes(defaultValues.kind)) {
      defaultValues = {
        anonymous: false,
        ...defaultValues,
      }
    }
    setValues(defaultValues)
  }, [defaultValues])

  return (
    <>
      <Modal
        id={id}
        appendTo={appendTo}
        closeOnEscape={false}
        useDefaultButtons
        headerText={getHeaderText()}
        overflowY="visible"
        vOffset="50"
        banner={getModalBanner()}
        submitButtonText={
          !kindHasTwoSteps.includes(values.kind) || values.id
            ? 'Confirm'
            : 'Next step'
        }
        submitDisabled={!values.name}
        submitHandler={submit}
        cancelHandler={resetForm}>
        {getFormContent()}
      </Modal>

      <DeleteIntegrationModal
        id="delete-integration-modal"
        integrationId={values.id}
        appendTo={appendTo}
        submitCallback={callback}
        cancelCallback={() => $(`#${id}`).foundation('open')}
      />
    </>
  )
}

export default AddEditIntegrationModal
