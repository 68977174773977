import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { getSceneQuery } from '../../apollo/query/scenes'

const SceneEditorContext = React.createContext([
  {},
  () => {
    // this function is intentionally left empty
  },
])
const SceneEditorProvider = (props) => {
  const [state, setState] = useState({
    video: {
      duration: 0,
      fps: 29,
      play4kUrl: '',
      volume: 0,
    },
    answers: [],
    questionFadeIn: 0,
    videoLoopEnabled: true,
    videoLoop: 0,
    showQuestionInLookDirection: 0,
  })
  const { data } = useQuery(getSceneQuery, {
    variables: {
      id: props.sceneID,
    },
    onCompleted: ({ scene }) => {
      document.title = `${scene.number} | Warp Studio`
    },
  })
  useEffect(() => {
    if (data && data.scene) {
      if (data.scene.video === null) {
        setState({
          ...data.scene,
          video: { duration: 0, fps: 29, play4kUrl: '', volume: 0 },
        })
      } else {
        setState(data.scene)
      }
    }
  }, [data])

  return (
    <SceneEditorContext.Provider value={[state, setState]}>
      {props.children}
    </SceneEditorContext.Provider>
  )
}

export { SceneEditorContext, SceneEditorProvider }
