import React, { useRef } from 'react'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'
import { handleApolloError } from '../../../utils/errors'
import { useMutation, useQuery } from '@apollo/client'
import { getCurrentClientSettingsQuery } from '../../../apollo/query/settings'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { updateClientEmailsMutation } from '../../../apollo/query/clients'

const EmailTab = () => {
  const { loading, data, error } = useQuery(getCurrentClientSettingsQuery)
  const formRef = useRef()
  const [updateEmails] = useMutation(updateClientEmailsMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({ title: 'Email settings were updated' })
    },
    refetchQueries: [
      {
        query: getCurrentClientSettingsQuery,
      },
    ],
  })

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    updateEmails({
      variables: {
        locale: formRef.current.elements['locale'].value,
        emailIntro: formRef.current.elements['emailIntro'].value || '',
      },
    })
  }

  return (
    <div className="row pb-3 mb-3">
      <div className="xlarge-3 medium-4 small-12 columns">
        <h2 className="text-bold">Email settings</h2>
        <p>
          Change the language of the emails your trainees receive. You can also
          add a small introduction text at the of the these emails.&nbsp;
          <a
            className="text-normal text-stable-dark text-underline"
            target="_blank"
            href="https://help.warpvr.com/organization-settings/qtJLeZHXC5TPNkXEcohX5m/email-settings/fxtgwD5ywcFtmTUWemDS7w">
            More info here.
          </a>
        </p>
      </div>
      <form
        ref={formRef}
        onSubmit={handleFormSubmit}
        className="medium-7 small-12 columns mt-0">
        <label>Language</label>
        <select name="locale" required defaultValue={data.currentClient.locale}>
          <option value={'EN'}>English</option>
          <option value={'NL'}>Dutch</option>
          <option value={'ES'}>Spanish</option>
        </select>

        <div className="flex-container align-middle align-justify">
          <label>Introduction text</label>
          <p className="mb-1">Max 500 characters</p>
        </div>
        <textarea
          name="emailIntro"
          rows="8"
          defaultValue={data.currentClient.emailIntro}
          maxLength={500}
        />

        <button
          type="submit"
          className="o-button o-button--assertive float-right mt-1 o-button--loading button primary text-normal"
          aria-hidden="true">
          Confirm
        </button>
      </form>
    </div>
  )
}
export default withApollo(EmailTab)
