import React from 'react'

const FilterEmptyState = ({ type, icon, clickHandler, removeButotn }) => {
  return (
    <>
      <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
        <span className="text-center">
          <span data-testid="icon-wrapper" className="o-table__empty--icon ">
            {icon}
          </span>
          <h2 className="text-bold text-dark">
            {`No ${type} match your filters`}
          </h2>
          {removeButotn ?? (
            <span
              data-testid="link"
              className="text-normal text-stable-dark text-underline cursor-pointer"
              onClick={clickHandler}>
              Remove all filters
            </span>
          )}
        </span>
      </div>
    </>
  )
}

export default FilterEmptyState
