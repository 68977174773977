import React, { useContext } from 'react'
import LanguageContext from '../../../utils/context/LanguageContext'
import SignInModal from '../Modal/SignInModal'

const CourseSignIn = ({
  client,
  course,
  security,
  scenariosCount,
  buttonColor,
}) => {
  const { translations: t } = useContext(LanguageContext)
  return (
    <div id="coursesignin" className="grid-container fluid">
      <SignInModal
        id="signinmodal"
        domains={security.domains}
        appendTo="#scenarioCourseInfo"
      />
      <div className="grid-x c-launcher__page--container">
        <div
          className="grid-y cell medium-5 large-6"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div className="cell c-launcher__company--container">
            <div
              className="c-launcher__company-icon c-launcher__company-icon--large-round"
              style={{
                backgroundImage: `url("${client.imageUrl}")`,
              }}
            />
            <h2 className="text-bold mb-0">{client.name}</h2>
          </div>
          <div className="cell">
            <h1 className="c-launcher__page--banner">{course.name}</h1>
            <p className="text-dark text-bold">
              {t.sections.about.containsScenariosShort} {scenariosCount}{' '}
              {scenariosCount === 1
                ? t.sections.about.scenario
                : t.sections.about.scenarios}
            </p>
          </div>
          <div className="cell mt-3 mb-3">
            <p>
              {course.description.slice(0, 250)}
              {course.description.length > 250 && (
                <>
                  ...
                  <br />
                  <u
                    className="cursor-pointer"
                    onClick={() => {
                      const element = document.querySelector('#about')
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }}>
                    {t.readMore}
                  </u>
                </>
              )}
            </p>
          </div>
          <div className="cell">
            <button
              onClick={() => $('#signinmodal').foundation('open')}
              className="c-launcher__sign-in--button small-12  button"
              style={{
                color: buttonColor,
                backgroundColor: client.primaryColor,
              }}>
              {t.buttonValues.course}
            </button>
          </div>
        </div>
        <div
          className={`cell h-100 medium-6 large-5 medium-offset-1 c-launcher__company--image--container `}
          style={{ flexDirection: 'column' }}>
          <div
            className={`c-launcher__company--image mb-1 ${
              scenariosCount > 1 ? 'no-border-bottom' : ''
            }`}
            style={{
              backgroundImage: `url("${course.scenarios[0].imageUrl}")`,
            }}></div>
          {scenariosCount > 1 && (
            <div
              className="c-launcher__company--image no-border-top"
              style={{
                backgroundImage: `url("${course.scenarios[1]?.imageUrl}")`,
              }}></div>
          )}
        </div>
      </div>
    </div>
  )
}
export default CourseSignIn
