import React, { useEffect, useState } from 'react'
import withApollo from '../../../hooks/withApollo'
import Countries from './Country'

const CompanyInfoForm = ({ billingValues, onSubmit, loading }) => {
  const [values, setValues] = useState(billingValues)

  useEffect(() => {
    setValues(billingValues)
  }, [billingValues])

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(values)
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Company name
        <span className="text-assertive"> *</span>
      </label>
      <input
        type="text"
        required
        name="billingName"
        value={values.billingName}
        onChange={handleChange}
      />

      <div className="row">
        <div className="medium-6 small-12 columns">
          <label>VAT number / Tax ID</label>
          <input
            type="text"
            name="billingVatNumber"
            value={values.billingVatNumber}
            onChange={handleChange}
          />
        </div>
        <div className="medium-6 small-12 columns">
          <label>Reference / PO number</label>
          <input
            type="text"
            name="billingReference"
            value={values.billingReference}
            onChange={handleChange}
          />
        </div>
      </div>

      <label>
        Street<span className="text-assertive"> *</span>
      </label>
      <input
        type="text"
        required
        name="billingStreet"
        value={values.billingStreet}
        onChange={handleChange}
      />

      <div className="row">
        <div className="medium-6 small-12 columns">
          <label>
            Postal code
            <span className="text-assertive"> *</span>
          </label>
          <input
            type="text"
            required
            name="billingPostalcode"
            value={values.billingPostalcode}
            onChange={handleChange}
          />
        </div>
        <div className="medium-6 small-12 columns">
          <label>
            City
            <span className="text-assertive"> *</span>
          </label>
          <input
            type="text"
            required
            name="billingCity"
            value={values.billingCity}
            onChange={handleChange}
          />
        </div>
      </div>

      <label>Country</label>
      <Countries value={values.billingCountry} onChange={handleChange}/>

      <div className="flex-container align-right mt-3">
        <button
          className="button primary wide o-button--loading"
          type="submit"
          disabled={loading}>
          Confirm
        </button>
      </div>
    </form>
  )
}

export default withApollo(CompanyInfoForm)
