import { useMutation } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FaQuestionCircle } from 'react-icons/fa'
import {
  addUserToGroupsMutation,
  removeUserFromGroupsMutation,
} from '../../../apollo/query/groups'
import { updateUserMutation } from '../../../apollo/query/user'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import DataTooltip from '../../UI/DataTooltip'
import Modal from '../../UI/Modal'
import TraineeDetailGroupsList from '../Forms/TraineeDetailGroupsList'
import TraineeDetailSelectRole from '../TraineeDetailSelectRole'
import useCurrentUser from '../../../hooks/useCurrentUser'

const EditTraineeModal = ({
  id,
  appendTo,
  currentTrainee,
  callbackHandler,
}) => {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const [role, setRole] = useState(currentTrainee.role)
  const [key, setKey] = useState(uuidv4)
  const employeeNumberRef = useRef()
  const groupIdsRef = useRef()
  const [currentUser] = useCurrentUser()

  const [updateTrainee] = useMutation(updateUserMutation, {
    onError: handleApolloError,
    onCompleted: () => addSuccessAlert({ title: 'Trainee updated' }),
  })
  const [addGroupsMutation] = useMutation(addUserToGroupsMutation, {
    onError: handleApolloError,
  })
  const [removeGroupsMutation] = useMutation(removeUserFromGroupsMutation, {
    onError: handleApolloError,
  })

  useEffect(() => {
    setRole(currentTrainee.role)
  }, [currentTrainee])

  if (!currentTrainee) return null

  const saveHandler = async () => {
    let vars = {
      userId: currentTrainee.id,
      employeeNumber: employeeNumberRef.current.value,
    }
    if (!currentTrainee.activated) {
      vars = {
        ...vars,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
      }
    }
    if (role !== 'OWNER' && role !== currentTrainee.role) {
      vars = { ...vars, role }
    }
    const existingGroupIds = currentTrainee.groups.map((group) => group.id)
    const addGroups = groupIdsRef.current.filter(
      (addGroupId) => !existingGroupIds.includes(addGroupId)
    )
    const removeGroups = currentTrainee.groups.reduce((a, group) => {
      if (!groupIdsRef.current.includes(group.id)) {
        a.push(group.id)
      }
      return a
    }, [])

    await addGroupsMutation({
      variables: {
        userIds: [currentTrainee.id],
        groupIds: addGroups,
      },
    })

    await removeGroupsMutation({
      variables: { userIds: [currentTrainee.id], groupIds: removeGroups },
    })

    await updateTrainee({
      variables: { ...vars },
    })

    callbackHandler()

    setTimeout(() => setKey(uuidv4()), 300)
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      overflowY="visible"
      headerText="Edit trainee"
      useDefaultButtons
      cancelHandler={() => setTimeout(() => setKey(uuidv4()), 300)}
      submitHandler={saveHandler}>
      <div key={key}>
        {currentTrainee.activated ? (
          <div id="existing-user" className="mt-3">
            <div className=" mb-3">
              <div className="grid-x grid-margin-x">
                <p className="text-bold text-dark cell shrink">Email address</p>
                <p className="cell shrink ">{currentTrainee.email}</p>{' '}
                <div className="cell mb-0 auto text-stable-dark">
                  <DataTooltip title="This trainee is already registered. Changing the email can only be done by the trainee itself.">
                    <FaQuestionCircle />
                  </DataTooltip>
                </div>
              </div>
              <div className="grid-x grid-margin-x">
                <p className="mb-0 cell shrink text-dark text-bold">
                  Trainee name
                </p>
                <p className="mb-0 cell shrink">{currentTrainee.name}</p>{' '}
                <div className="cell mb-0 auto text-stable-dark">
                  <DataTooltip title="This trainee is already registered. Changing the name can only be done by the trainee itself.">
                    <FaQuestionCircle />
                  </DataTooltip>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id="email-banner" className="mt-3">
            <div className="mb-3">
              <div className="grid-x grid-margin-x">
                <p className="mb-0 text-bold text-dark cell shrink">
                  Email address
                </p>
                <p className="mb-0 cell shrink mr-1 ">{currentTrainee.email}</p>
                <div className="cell mb-0 auto text-stable-dark">
                  <DataTooltip title="Email address cannot be changed.">
                    <FaQuestionCircle />
                  </DataTooltip>
                </div>
              </div>
            </div>
          </div>
        )}
        <form className="mt-3">
          {!currentTrainee.activated && (
            <>
              <div className="grid-x grid-margin-x">
                <div className="cell small-6">
                  <label className="c-form__label--required">First name</label>
                  <input
                    ref={firstNameRef}
                    defaultValue={currentTrainee.firstName}
                    type="text"
                    className="cell auto"
                    required
                  />
                </div>
                <div className="cell small-6">
                  <label className="c-form__label--required">Last name</label>
                  <input
                    ref={lastNameRef}
                    defaultValue={currentTrainee.lastName}
                    type="text"
                    className="cell auto"
                    required
                  />
                </div>
              </div>
            </>
          )}
          <div className="grid-x grid-margin-x">
            <div className="cell small-6">
              <label>Employee No</label>
              <input
                ref={employeeNumberRef}
                defaultValue={currentTrainee.employeeNumber}
                type="text"
                className="cell auto"
              />
            </div>
            <div className="cell small-6">
              <label>Select Role</label>
              <TraineeDetailSelectRole
                selected={role}
                changeHandler={(value) => {
                  setRole(value)
                }}
                disabled={currentTrainee.id === currentUser?.id}
              />
            </div>
          </div>
          <label>{currentTrainee.name} is member of</label>
          <TraineeDetailGroupsList
            groupRef={groupIdsRef}
            userGroups={currentTrainee.groups}
          />
          {/* Fake submit button to prevent enter... */}
          <button
            type="submit"
            disabled
            style={{ display: 'none' }}
            aria-hidden="true"
          />
        </form>
      </div>
    </Modal>
  )
}
export default EditTraineeModal
