import React from 'react'
import { getRotation } from '../../../Editor/helpers/controls'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'

import arrow from '../../../Editor/Video/assets/arrow.svg'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import useHasHotspot from '../../../FlowV2/hooks/useHasHotspot'

const Direction = ({ id, element, state }) => {
  const [, currentClient] = useCurrentUser()
  const hasHotspot = useHasHotspot()

  if (!element || !currentClient || element.anchorX === null) return null

  const rotation = getRotation({
    x: element.anchorX,
    y: element.anchorY ?? -30,
  })

  let labelWidth = 1.8
  if (element.labelSize === 'WIDE') labelWidth *= 2

  const collisionWidth = labelWidth + 0.5

  return (
    <>
      <a-assets>
        <img id="direction" src={arrow} />
      </a-assets>
      <a-entity
        hideable-element={`visible: ${state.showElements}`}
        rotation={`${rotation} 0`}>
        <a-collision-box
          id={id}
          width={collisionWidth}
          scroll-header-offset={hasHotspot ? 260 : 125}
          class="lock-vertical">
          {element.showLabel !== false && (
            <TextBubble
              id={`L${id}`}
              textValue={element.label || 'Direction'}
              width={labelWidth}
            />
          )}

          <a-entity position="0 -0.5 0.01">
            <a-image src="#direction" color={currentClient.primaryColor} />
          </a-entity>
        </a-collision-box>
      </a-entity>
    </>
  )
}
export default Direction
