import React, { Fragment, useContext, useState } from 'react'
import {
  ELEMENT_TYPES,
  INTERACTIVE_ELEMENT_TYPES,
  VIDEO_ELEMENT_TYPES,
  selectSidebarElement,
  unSelectSidebarElements,
} from '../../helpers/elementHelper'
import { setNode } from '../../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'
import ElementCardDropdown from './ElementCardDropdown'
import TransparentInput from './TransparentInput'
import { capitalized } from '../../../../utils/helpers/javascript'
import {
  FaArrowRight,
  FaFlagCheckered,
  FaPlus,
  FaQuestionCircle,
  FaRandom,
} from 'react-icons/fa'
import TimerDropdowns from './TimerDropdowns'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import useSelectedNode from '../../hooks/useSelectedNode'
import AnswerPointsDropdown from './AnswerPointsDropdown'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import DataTooltip from '../../../UI/DataTooltip'
import SizeDropdown from '../../../Editor/Video/Tabs/Partials/SizeDropdown'
import config from '../../config/config'
import {
  moveCameraToPosition,
  unSelectCollistionBox,
} from '../../../Editor/helpers/controls'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import ElementLetterLabel from '../FlowEditor/elements/ElementLetterLabel'
import SceneToNumberLabel from '../FlowEditor/elements/SceneToNumberLabel'
import PointLabel from '../FlowEditor/elements/PointLabel'

const moveCameraToElement = (domElement) => {
  const targetRotation = domElement.parentNode.getAttribute('rotation')
  moveCameraToPosition(targetRotation.x, targetRotation.y)
}

const selectCollisionBox = (element) => {
  const id = `${element.kind}-${element.id}`
  const collisionBox = document.querySelector(`a-collision-box#${id}`)
  unSelectSidebarElements()
  unSelectCollistionBox()

  if (collisionBox) {
    setTimeout(() => {
      collisionBox.emit('intersect')
      collisionBox.emit('is-selected', { isSelected: true, scroll: false })
      moveCameraToElement(collisionBox)
    }, 0)
  } else selectSidebarElement(`element-${element.kind}-${element.id}--card`)
}

const Settings = ({ element, labelElements = [] }) => {
  const { updateElement } = useAddUpdateElement()
  const selectedNode = useSelectedNode()
  const reactFlow = useReactFlow()

  const getSettingsToggle = (text, id, checked, update, title) => {
    return (
      <div className="c-video__editor--elements--settings--row switch-settings">
        <div className="o-switch">
          <input
            type="checkbox"
            name={id}
            id={id}
            checked={checked}
            onChange={update}
            className="o-switch__input"
          />
          <label htmlFor={id} className="o-switch__paddle"></label>
        </div>
        <span className="ml-1">{text}</span>
        <span className="ml-0-5">
          {title && (
            <DataTooltip title={title}>
              <FaQuestionCircle className="text-stable" />
            </DataTooltip>
          )}
        </span>
      </div>
    )
  }
  const showVideoEditor = useShowVideoEditor()

  if (!showVideoEditor) return null

  const handleSizeChange = (labelSize, elements, name = 'labelSize') => {
    Promise.all(
      elements.map((el) =>
        updateElement({
          variables: {
            ...el,
            [name]: labelSize,
          },
        })
      )
    ).then((responses) => {
      const newNode = {
        ...selectedNode,
        selected: true,
        data: {
          ...selectedNode.data,
          elements: [
            ...(selectedNode.data.elements.filter(
              (el) => !responses.find((r) => r.id === el.id)
            ) ?? []),
            ...responses,
          ],
        },
      }
      setNode(reactFlow, newNode)
    })
  }

  const renderLabelSize = (
    label = 'Label size',
    labelElements,
    options,
    name = 'labelSize'
  ) => (
    <div className="flex-container align-middle align-justify">
      <div className="mr-1">{label}</div>
      <SizeDropdown
        element={labelElements?.[0] ?? element}
        onChange={(labelSize) =>
          handleSizeChange(
            labelSize,
            labelElements?.length ? labelElements : [element],
            name
          )
        }
        label={label}
        options={options}
        name={name}
      />
    </div>
  )
  const renderLookRowToggle = () => (
    <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
      {getSettingsToggle(
        'Show in look direction',
        'toggleLookDirection',
        element.showInLookDirection,
        () =>
          updateElement({
            variables: {
              ...element,
              showInLookDirection: !element.showInLookDirection,
            },
          }),
        'Position this element where the trainee enters the scene'
      )}
    </div>
  )

  const renderHideLabel = (label = 'Hide label') => {
    return (
      <div className="c-video__editor--elements--settings--row switch-settings">
        <div className="o-switch">
          <input
            type="checkbox"
            name="showLabel"
            id={`showLabel-${element.id}`}
            checked={!element.showLabel}
            onChange={() => {
              updateElement({
                variables: {
                  ...element,
                  showLabel: !element.showLabel,
                },
              })
            }}
            className="o-switch__input"
          />
          <label
            htmlFor={`showLabel-${element.id}`}
            className="o-switch__paddle"></label>
        </div>
        <span className="ml-1">{label}</span>
      </div>
    )
  }

  const settingsMap = {
    HOTSPOT: () => {
      element.hotspotSize = element.hotspotSize || config.defaultHotspotSize
      return (
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
          {renderLabelSize(
            'Hotspot size',
            [],
            [
              { value: 'SMALL', label: 'Small' },
              { value: 'MEDIUM', label: 'Medium' },
              { value: 'LARGE', label: 'Large' },
            ],
            'hotspotSize'
          )}
          {renderLabelSize('Label size')}
        </div>
      )
    },
    BUTTON: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
        {renderLabelSize()}
      </div>
    ),
    DIRECTION: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
        {renderHideLabel()}
        {renderLabelSize()}
      </div>
    ),
    TOOLTIP: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
          {renderLabelSize('Tooltip size')}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    INFORMATION: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
          {renderLabelSize('Information and answer size', [
            element,
            ...labelElements,
          ])}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    MPC: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
          {renderLabelSize('Question size')}
          {renderLabelSize('Answer size', labelElements)}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    TIMER: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
        {renderHideLabel('Hide timer')}
      </div>
    ),
  }
  const kind = element.groupKind || element.kind
  if (!settingsMap?.[kind]) return null
  return (
    <div className="c-video__editor--elements--settings-container">
      {settingsMap[kind]()}
    </div>
  )
}

const Card = ({
  className = '',
  element,
  last,
  parentIndex,
  childIndex,
  parentElement,
  deleteDisabled,
  deleteDisabledText = '',
}) => {
  const [showDropdownButton, setShowDropdownButton] = useState(false)
  const { updateElement } = useAddUpdateElement()
  const { scenario } = useContext(ScenarioEditorContext)
  const reactFlow = useReactFlow()
  const { getNodes } = reactFlow
  const showVideoEditor = useShowVideoEditor()

  const elRow = ELEMENT_TYPES.find((el) => el.type === element.kind)

  if (!elRow) return <></>

  const hideDropdownButton = () => setShowDropdownButton(false)

  const handleChange = ({ target: { value, name } }) => {
    if (element[name] !== value)
      updateElement({
        variables: {
          ...element,
          [name]: value,
        },
      })
  }

  const renderInput = () => {
    if (element.kind === 'TRANSITION') return <div className="pb-0-5" />

    if (element.kind === 'TIMER')
      return (
        <div className="pl-2 pr-2 pb-1 text-normal flex-container align-middle align-justify">
          <span>Set timer to</span>
          <TimerDropdowns element={element} />
        </div>
      )
    return (
      <div className="pl-1 pr-1 pb-1">
        <TransparentInput
          id={`element-${element.id}`}
          type="contentEditable"
          name="label"
          onConfirm={handleChange}
          value={element.label ?? ''}
        />
      </div>
    )
  }

  const renderLabels = () => {
    const renderLetterLabel = () => {
      if (!INTERACTIVE_ELEMENT_TYPES.includes(element.kind)) return <></>
      return (
        <ElementLetterLabel
          className="mr-0-5 mt-0-5"
          index={parentIndex + (childIndex ?? 0) + 1}
        />
      )
    }

    const renderNumberLabel = () => {
      if (!element.linkToId) return <></>
      const linkedNode = getNodes().find((n) => n.id === element.linkToId)
      if (!linkedNode) return <></>
      return (
        <SceneToNumberLabel linkedNode={linkedNode} className="mt-0-5 mr-0-5" />
      )
    }

    const renderPointLabel = () => (
      <PointLabel points={element.points} className="mr-0-5 mt-0-5" />
    )

    const renderRandomizerLabel = () => {
      if (!element.randomizedLinkToIds.length) return <></>
      return (
        <>
          <span
            className={`o-label o-label--balanced text-white mr-0-5 mt-0-5 text-small`}>
            <FaRandom />
            &nbsp;
            {element.randomizedLinkToIds.length}
          </span>
          {element.randomizedLinkToIds.map((sceneId, index) => {
            if (!sceneId) return <Fragment key={`${element.id}--${index}`} />
            const linkedNode = getNodes().find((n) => n.id === sceneId)
            if (!linkedNode)
              return <Fragment key={`${element.id}-${sceneId}-${index}`} />
            return (
              <span
                key={`${element.id}-${sceneId}-${index}`}
                className="o-label o-label--stable-dark text-white mr-0-5 mt-0-5 text-small">
                <FaArrowRight />
                &nbsp;
                {linkedNode.data.number}
              </span>
            )
          })}
        </>
      )
    }

    const renderEndingLabel = () => {
      if (element.linkToEnding)
        return (
          <span className="o-label o-label--dark-light flex-container align-middle mr-0-5 mt-0-5 text-small">
            <FaFlagCheckered className="mr-0-5" />
            Ending
          </span>
        )
      return <></>
    }
    return (
      <span className="ml-1 flex-container flex-wrap">
        {renderLetterLabel()}
        {renderNumberLabel()}
        {renderPointLabel()}
        {renderRandomizerLabel()}
        {renderEndingLabel()}
      </span>
    )
  }

  const getSaveButton = () => {
    const save = () => {
      const videoElement = document.querySelector(
        `#${element.kind}-${element.id}`
      )
      const rotation = videoElement.parentNode.getAttribute('rotation')

      updateElement({
        variables: {
          ...element,
          anchorX: -rotation.y, // V2
          anchorY: rotation.x, // V2
        },
      })
    }
    return (
      <button
        id={`element-${element.kind}-${element.id}--save-button`}
        hidden
        onClick={save}></button>
    )
  }
  const isClickable =
    !element.groupUuid &&
    showVideoEditor &&
    (VIDEO_ELEMENT_TYPES.includes(element.kind) || element.kind === 'TIMER')

  const handleClick = (e) => {
    if (!isClickable || e.currentTarget.classList.contains('active'))
      return false
    selectCollisionBox(parentElement ?? element)
  }

  return (
    <div
      id={
        element.groupUuid
          ? `element-${element.kind}-${element.groupUuid}-${element.id}--card`
          : `element-${element.kind}-${element.id}--card`
      }
      className={`${className}
        ${last ? 'border-0' : ''} 
        ${element.groupUuid && ['QUESTION', 'TOOLTIP'].includes(element.kind) ? 'pt-1-5' : 'pt-1'}
        ${element.groupUuid ? 'border-light-bottom' : 'border-light border-radius mb-2'}
        ${isClickable ? 'cursor-pointer' : ''}
        `}
      onMouseEnter={setShowDropdownButton}
      onMouseLeave={hideDropdownButton}
      onClick={handleClick}
      style={{ transition: 'none' }}>
      <header className="text-small flex-container align-middle align-justify pl-2 pr-2 mb-0-5">
        <div className="flex-container align-top">
          <div
            id={`element-${element.kind}-${element.id}`}
            className="flex-container align-middle mt-0-5">
            {elRow.icon}
            <h4 className="text-normal text-bold mb-0 ml-1 u-capitalize">
              {elRow.title}
            </h4>
          </div>
          {renderLabels()}
        </div>

        <div
          className="flex-container"
          style={{ visibility: showDropdownButton ? 'visible' : 'hidden' }}>
          {(!element.groupUuid ||
            ['ANSWER', 'BUTTON'].includes(element.kind)) && (
            <ButtonGroup className="ml-0-5">
              {scenario.scoringSystem.kind !== 'DISABLED' &&
                INTERACTIVE_ELEMENT_TYPES.includes(element.kind) && (
                  <AnswerPointsDropdown element={element} />
                )}
              <ElementCardDropdown
                element={element}
                onDeleteModalOpen={hideDropdownButton}
                deleteDisabled={deleteDisabled}
                deleteDisabledText={deleteDisabledText}
              />
            </ButtonGroup>
          )}
        </div>
      </header>
      {renderInput()}
      {!element.groupUuid && <Settings element={element} />}
      {getSaveButton()}
    </div>
  )
}

const ElementCard = ({
  element: parentElement,
  answerElements,
  index: parentIndex,
  loading,
  setLoading,
}) => {
  const reactFlow = useReactFlow()
  const selectedNode = useSelectedNode()
  const { addElement } = useAddUpdateElement()
  const [showDropdownButton, setShowDropdownButton] = useState(false)
  const showVideoEditor = useShowVideoEditor()

  if (!parentElement.groupUuid)
    return (
      <Card
        className="c-video__editor--elements--card"
        element={parentElement}
        parentIndex={parentIndex}
      />
    )

  const groupElRow = ELEMENT_TYPES.find(
    (el) => el.type === parentElement.groupKind
  )
  if (!groupElRow) return <></>

  const hideDropdownButton = () => setShowDropdownButton(false)

  const handleAddAnswer = () => {
    setLoading(true)
    addElement(
      {
        variables: {
          sceneId: selectedNode.id,
          groupKind: parentElement.groupKind,
          groupUuid: parentElement.groupUuid,
          kind: 'ANSWER',
        },
      },
      selectedNode
    ).then(({ data }) => {
      const newNode = {
        ...selectedNode,
        selected: true,
        data: {
          ...selectedNode.data,
          elements: [...selectedNode.data?.elements, data.addElement.element],
        },
      }
      setNode(reactFlow, newNode)
      setLoading(false)
    })
  }

  const renderNewAnswerButton = () => {
    if (parentElement.groupKind === 'MPC')
      return (
        <button
          className="o-button o-button--light o-button--square-small flex-container align-middle mb-0 pb-0-5 pt-0-5 pr-1 pl-1 mr-0-5 w-auto"
          onClick={handleAddAnswer}
          disabled={loading}
          hidden={!showDropdownButton}>
          <FaPlus className="mr-0-5 text-small" />
          <span className="text-bold text-normal">New answer</span>
        </button>
      )
    return <></>
  }

  const isClickable = showVideoEditor && parentElement.kind !== 'TRANSITION'

  const handleClick = (e) => {
    if (!isClickable || e.currentTarget.classList.contains('active'))
      return false
    selectCollisionBox(parentElement)
  }

  return (
    <div
      id={`element-${parentElement.kind}-${parentElement.id}--card`}
      className={`border-light border-radius mb-2 c-video__editor--elements--card 
        ${isClickable ? 'cursor-pointer' : ''}`}
      onMouseEnter={setShowDropdownButton}
      onMouseLeave={hideDropdownButton}
      onClick={handleClick}>
      <header className="flex-container align-middle align-justify text-stable-dark p-1 pl-1-5 pr-1-5 border-light-bottom">
        <div className="flex-container align-middle">
          {groupElRow.icon}
          <h4 className="text-normal text-bold mb-0 ml-1">
            {capitalized(groupElRow.title)}
          </h4>
        </div>
        <div>
          {renderNewAnswerButton()}
          <span
            style={{ visibility: showDropdownButton ? 'visible' : 'hidden' }}>
            <ButtonGroup>
              <ElementCardDropdown
                element={parentElement}
                onDeleteModalOpen={hideDropdownButton}
              />
            </ButtonGroup>
          </span>
        </div>
      </header>
      <Card element={parentElement} parentIndex={parentIndex} />
      {answerElements.map((el, index) => (
        <Card
          key={el.id}
          element={el}
          last={index === answerElements.length - 1}
          parentIndex={parentIndex}
          childIndex={index}
          parentElement={parentElement}
          deleteDisabled={
            answerElements.length === 1 && parentElement.groupKind === 'MPC'
          }
          deleteDisabledText="A multiple choice element needs at least one answer"
        />
      ))}
      <Settings element={parentElement} labelElements={answerElements} />
    </div>
  )
}

export default ElementCard
