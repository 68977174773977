import React from 'react'
import Modal from '../UI/Modal'
import { FaUpLong } from 'react-icons/fa6'
import { GrCommand } from 'react-icons/gr'
import { isIOS } from '../../utils/helpers/browser'
import useFoundation from '../../hooks/useFoundation'

const CTRL_ICON = isIOS ? <GrCommand /> : 'Ctrl'
const SHORTCUTS = [
  {
    title: 'Select scenes',
    shortcuts: ['Shift'],
  },
  { title: 'Zoom in', shortcuts: [CTRL_ICON, '+'] },
  { title: 'Zoom out', shortcuts: [CTRL_ICON, '-'] },
  { title: 'Zoom to 100%', shortcuts: [CTRL_ICON, '0'] },
  { title: 'Go to start scene', shortcuts: [CTRL_ICON, 'S'] },
  { title: 'Search scene', shortcuts: [CTRL_ICON, 'K'] },
  {
    title: 'Previous scene',
    shortcuts: [CTRL_ICON, <FaUpLong />, '<'],
  },
  { title: 'Next scene', shortcuts: [CTRL_ICON, <FaUpLong />, '>'] },
]

const KeyboardShortcutsModal = () => {
  const ref = useFoundation()

  return (
    <div ref={ref}>
      <Modal
        id="keyboard-shortcuts-modal"
        appendTo="body"
        headerText="Keyboard shortcuts">
        <label className="mt-3">Flow editor</label>
        <div className="text-normal pb-4">
          {SHORTCUTS.map((shortcut) => (
            <div
              key={shortcut.title}
              className="flex-container align-justify align-middle mt-1-5">
              <div>{shortcut.title}</div>
              <div className="flex-container align-middle text-stable-dark">
                {shortcut.shortcuts.map((key, index) => (
                  <div
                    key={index}
                    className="ml-0-5 border border-radius bg-light pt-0-5 pb-0-5 pl-1 pr-1 text-bold flex-container align-middle align-center"
                    style={{ minWidth: '32px', minHeight: '32px' }}>
                    {key}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}
export default KeyboardShortcutsModal
