import React from 'react'
import TextBubble from './partials/TextBubble'
import Button from './partials/Button'
import { getRotation } from '../../helpers/controls'

const Information = ({ id, scene, state }) => {
  if (!scene) return null
  let WIDTH = 2.6
  if (scene.elementSize === 'WIDE') WIDTH *= 2

  return (
    <a-entity
      hideable-element={`visible: ${state.showElements}`}
      rotation={`${getRotation({ x: scene.anchorX, y: scene.anchorY })} 0`}>
      <a-collision-box id={id} width={WIDTH + 0.5}>
        <TextBubble textValue={scene.question} id="question" width={WIDTH} />
        <a-entity position="0 0.1 0.01">
          <Button textValue={scene.answers[0].name} width={WIDTH} id="button" />
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default Information
