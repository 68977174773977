import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../../UI/Modal'
import { updateProductMutation } from '../../../../apollo/query/products'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'

const ArchiveProductModal = ({ id, appendTo, product, callback }) => {
  const [publishProduct] = useMutation(updateProductMutation, {
    onError: handleApolloError,
    onCompleted: () => addSuccessAlert({ title: 'Product archived' }),
  })

  const submit = () => {
    const vars = { ...product }
    const courseIds = vars.courses.map(({ course }) => Number(course.id))
    delete vars.courses

    const scenarioIds = vars.scenarios.map(({ scenario }) =>
      Number(scenario.id)
    )
    delete vars.scenarios

    publishProduct({
      variables: {
        ...vars,
        courseIds,
        scenarioIds,
        state: 'ARCHIVED',
      },
    })
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      closable
      headerText="Archive product"
      cancelHandler={callback}
      submitHandler={submit}
      subtitle="Change status from ‘In Warp Store’ to ‘Archived’">
      <p className="mt-3">
        When archiving this product, it will no longer be available for purchase
        in the Warp Store. Available or active licenses can still be used until
        they are used or expired.{' '}
      </p>
      <p className="text-bold">Changing to this state is irreversible.</p>
    </Modal>
  )
}

export default ArchiveProductModal
