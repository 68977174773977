import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../UI/Modal'
import { TabTitles, TabContent } from '../../UI/Tabs/Tabs'
import ProductContentTab from './Tabs/ProductContentTab'
import PurchasedLicensesTab from './Tabs/PurchasedLicensesTab'

const PurchasedProductInfoModal = ({
  id,
  appendTo,
  product,
  showLicenseTab = false,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    if (product) setSelectedTab(showLicenseTab ? 1 : 0)
  }, [product])

  useEffect(() => {
    setSelectedTab(showLicenseTab ? 1 : 0)
  }, [showLicenseTab])

  if (!product) return <Modal id={id} appendTo={appendTo} />

  const getSubtitle = () => {
    return `Created by ${product.client.name} • ${
      product.courses.length
        ? `${product.courses.length} course${
            product.courses.length > 1 ? 's' : ''
          }`
        : ''
    } ${product.courses.length && product.scenarios.length ? ' and ' : ''} ${
      product.scenarios.length
        ? `${product.scenarios.length} scenario${
            product.scenarios.length > 1 ? 's' : ''
          }`
        : ''
    } `
  }
  const titles = ['Product content', 'Purchased licenses']

  const content = [
    <ProductContentTab key="productContent" product={product} />,
    <PurchasedLicensesTab key="purchasedLicenses" product={product} />,
  ]

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      width={1100}
      subtitle={getSubtitle()}
      headerText={product.name}>
      <div className="mt-3 grid-x grid-margin-x" key={uuidv4()}>
        <div className="cell small-3">
          <TabTitles
            titles={titles}
            activeTab={selectedTab}
            selectHandler={setSelectedTab}
          />
        </div>
        <div className="cell small-9">
          <TabContent
            titles={titles}
            content={content}
            activeTab={selectedTab}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PurchasedProductInfoModal
