import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import useShowVideoEditor from './useShowVideoEditor'

let debounceTimeout

const useKeyboardShortcuts = (shortcuts) => {
  const location = useLocation()
  const showVideoEditor = useShowVideoEditor()
  const showFlowDiagram =
    (location.pathname === '/' || location.pathname.includes('/scenes/')) &&
    !showVideoEditor &&
    window.location.pathname.includes('/scenarios/')
  const pressedKeys = useRef(new Set())

  useEffect(() => {
    const normalizeKey = (key) => {
      key = key.toLowerCase()
      return key === 'meta' ? 'control' : key
    }

    const handleKeyDown = (event) => {
      if (!showFlowDiagram || !window.location.pathname.includes('/scenarios/'))
        return false
      const { key, metaKey, ctrlKey } = event

      // Add the normalized key and modifier keys to the array
      const normalizedKey = normalizeKey(key)
      // if (pressedKeys.current.includes(normalizedKey)) return false

      pressedKeys.current.forEach((k) => {
        if (
          (metaKey || ctrlKey) &&
          k !== normalizedKey &&
          !['control', 'meta', 'shift'].includes(k)
        ) {
          pressedKeys.current.delete(k)
        }
      })

      pressedKeys.current.add(normalizedKey)

      shortcuts.forEach((shortcut) => {
        const keysMatch = shortcut.keys.every((k) =>
          pressedKeys.current.has(normalizeKey(k))
        )
        if (keysMatch) {
          event.preventDefault()
          if (debounceTimeout) return // Prevent multiple triggers
          debounceTimeout = setTimeout(() => (debounceTimeout = null), 300)
          shortcut.action()
        }
      })

    }

    const handleKeyUp = (e) => {
      // Remove the last key from the array
      // pressedKeys.current.pop()
      const { key } = e
      const normalizedKey = normalizeKey(key)
      if (['control', 'alt'].includes(normalizedKey)) {
        pressedKeys.current.clear()
      }
    }

    if (shortcuts?.length && showFlowDiagram) {
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('keyup', handleKeyUp)
    }

    if (!showFlowDiagram) {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [shortcuts, location])

  useEffect(() => {
    pressedKeys.current.clear()
  }, [shortcuts])

  return null
}

export default useKeyboardShortcuts
