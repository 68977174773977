import React, { useState, useRef, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import {
  getCurrentClientQuery,
  updateClientBrandingMutation,
} from '../../../apollo/query/clients'
import placeholderLogo from '../../../../../images/default/logo.png'
import RangeInput from '../../../components/UI/Form/RangeInput'
import {
  getBlobfromDataURL,
  getFileFromBlob,
} from '../../../utils/helpers/files'
import {
  validateFileMaxSize,
  validateImageExtension,
} from '../../../utils/validation/validate'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

const ActivateOrganisationForm = ({ isActive }) => {
  const appTitleRef = useRef()
  const appDescriptionRef = useRef()
  const SIZE = 200
  const BORDERWIDTH = 5
  const editor = useRef()
  const fileUploader = useRef()
  const [image, setImage] = useState()
  const [scale, setScale] = useState(1)

  const [getCurrentClient, { loading, data }] = useLazyQuery(
    getCurrentClientQuery
  )

  const [updateClientBranding] = useMutation(updateClientBrandingMutation)

  useEffect(() => {
    if (isActive) getCurrentClient()
  }, [isActive])

  const handleNavigation = () => (window.location.href = '/')

  const handleSave = (e) => {
    e.preventDefault()
    if (image) {
      const blob = getBlobfromDataURL(editor.current.getImage().toDataURL())
      const fileToUpload = getFileFromBlob(blob, image.name, 'image/png')
      const upload = new DirectUpload(fileToUpload, DIRECT_UPLOAD_URL)

      upload.create((uploadError, b) => {
        if (uploadError) throw new Error(uploadError.message)
        updateClientBranding({
          variables: {
            appTitle: appTitleRef.current.value,
            appDescription: appDescriptionRef.current.value || ' ',
            logoMobileBlobId: b.signed_id,
          },
          onCompleted: handleNavigation,
        })
      })
    } else {
      updateClientBranding({
        variables: {
          appTitle: appTitleRef.current.value,
          appDescription: appDescriptionRef.current.value || ' ',
        },
        onCompleted: handleNavigation,
      })
    }
  }

  const handleDrop = (f) => setImage(f[0])

  if (loading) return `Loading..`

  return (
    <>
      <form data-testid="activate-organisation-form">
        <h4 className="text-normal text-bold" htmlFor="organisation-name">
          Organization name
        </h4>
        <p className="mb-1">
          The organization name can be seen by trainees in the Warp VR app.
        </p>
        <input
          ref={appTitleRef}
          type="text"
          name="organisation-name"
          data-testid="organisation-name-input"
          id=""
          placeholder="Organization name as shown in the app"
          defaultValue={data?.currentClient.appTitle}
        />
        <label className="text-normal text-bold" htmlFor="description">
          Description
        </label>
        <p className="mb-1">
          Write a short description about your organization.
        </p>
        <textarea
          ref={appDescriptionRef}
          name="description"
          data-testid="description-textarea"
          id=""
          rows="5"
          placeholder="Description as shown in the app"
          defaultValue={data?.currentClient.appDescription}
          style={{ resize: 'vertical' }}></textarea>
        <label htmlFor="organization-image">Logo</label>
        <div className="grid-x grid-margin-x align-middle">
          <Dropzone onDrop={handleDrop} noClick noKeyboard>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{ width: `${SIZE + BORDERWIDTH * 2}px` }}
                className="cell shrink"
                id="organisation-image">
                <AvatarEditor
                  ref={editor}
                  borderRadius={SIZE}
                  width={SIZE}
                  height={SIZE}
                  border={BORDERWIDTH}
                  color={[0, 0, 0, 0.6]}
                  scale={0 + Number(scale)}
                  image={image || placeholderLogo}
                />
                <input
                  {...getInputProps()}
                  type="file"
                  id="file"
                  ref={fileUploader}
                  style={{ display: 'none' }}
                  accept="image/jpeg,image/png"
                  multiple={false}
                  onChange={(event) => {
                    const file = event.target.files[0]
                    if (!validateImageExtension(file)) {
                      alert(
                        'unsupported file type, please upload a png or jpeg image'
                      )
                      return null
                    }
                    if (!validateFileMaxSize(file, 5)) {
                      alert('image file too big, image should be less than 5MB')
                      return null
                    }
                    if (image && !file) return null
                    return setImage(file)
                  }}
                />
              </div>
            )}
          </Dropzone>
          <div className="cell auto">
            <button
              className="button secondary hollow wide mb-0"
              onClick={(e) => {
                e.preventDefault()
                fileUploader.current.click()
              }}>
              {`${image ? 'Change' : 'Upload'}`} logo
            </button>{' '}
            <div className="text-stable-dark mt-2">
              <ol style={{ listStyle: 'none', marginLeft: '0' }}>
                <li>
                  Upload a logo of at least{' '}
                  <span className="text-bold">350x350 pixels</span>.
                </li>
                <li>
                  Image should be{' '}
                  <span className="text-bold">less than 5MB</span> in size.
                </li>
                <li>
                  Only <span className="text-bold">.png</span> and{' '}
                  <span className="text-bold">.jpg</span> imagetypes are
                  allowed.
                </li>
              </ol>
            </div>
          </div>
        </div>{' '}
        <div style={{ width: `${SIZE + BORDERWIDTH * 2}px` }}>
          <RangeInput
            showLabels={false}
            minValue={0.2}
            maxValue={3}
            value={scale}
            setValue={setScale}
            noBackground
          />
        </div>
        <button
          data-testid="submit-button"
          className="button primary wide float-right"
          onClick={handleSave}>
          Launch Warp Studio
        </button>
      </form>
    </>
  )
}
export default ActivateOrganisationForm
