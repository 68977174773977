export function init() {
  var item = $('[data-side-menu-content]');
  var offset = $(window).scrollTop() + 'px';

  if (item.length) {
    var itemOffset = item.offset().top + 'px';
    var height = '100vh - ' + itemOffset + ' - 40px + ' + offset;
    item.attr('style', 'max-height:calc(' + height + ');');
  }
}
