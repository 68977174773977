import React, { useState, useContext, useRef } from 'react'
import { FaQuestionCircle, FaCheck, FaTimes } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import DataTooltip from '../../../UI/DataTooltip'
import RangeInput from '../../../UI/Form/RangeInput'
import {
  convertResolution,
  convertMilliSeconds,
} from '../../../../utils/format'
import { SceneEditorContext } from '../../SceneEditorContext'
import { updateSceneMutation } from '../../../../apollo/query/scenes'
import { handleApolloError } from '../../../../utils/errors'
import { moveCameraToPosition } from '../../helpers/controls'
import { MediumContext } from '../../../Media/Modal/MediumContext'

const MediaSettingsTab = ({ scene }) => {
  const {
    medium: [medium],
  } = useContext(MediumContext)
  const [sceneState, setSceneState] = useContext(SceneEditorContext)
  const [isEditingNorthOffset, setIsEditingNorthOffset] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const northOffsetInputRef = useRef()

  const [updateScene] = useMutation(updateSceneMutation, {
    onError: handleApolloError,
  })

  if (!medium) return null
  const getMediaInfo = () => {
    const data = [
      { name: 'File name', item: scene.video.name },
      { name: 'Length', item: convertMilliSeconds(scene.video.duration) },
      { name: 'Framerate', item: `${scene.video.fps} FPS` },
      {
        name: 'Highest resolution',
        item: convertResolution(scene.video.width),
      },
    ]

    return data.map((i, index) => (
      <li key={index} className="mb-1 mt-1" style={{ listStyle: 'none' }}>
        <span>{i.name}</span>
        <span className="text-stable float-right">{i.item}</span>
      </li>
    ))
  }

  const getTrimInfo = () => {
    if (medium.trimEnabled) {
      const data = [
        {
          name: 'Video is trimmed',
          item: <FaCheck className="text-success" />,
        },
        {
          name: 'From',
          item: convertMilliSeconds(medium.trimStart * 1000 || 0),
        },
        {
          name: 'Until',
          item: convertMilliSeconds(medium.trimEnd * 1000 || medium.duration),
        },
        {
          name: 'Trimmed length',
          item: convertMilliSeconds(
            (medium.trimEnd * 1000 || Number(medium.duration)) -
              medium.trimStart * 1000
          ),
        },
      ]
      return data.map((i, index) => (
        <li key={index} className="mb-1 mt-1" style={{ listStyle: 'none' }}>
          <span>{i.name}</span>
          <span className="text-stable float-right">{i.item}</span>
        </li>
      ))
    }
    return (
      <>
        <li className="mb-1 mt-1 " style={{ listStyle: 'none' }}>
          <span>Video is trimmed</span>
          <span className="text-stable float-right">
            <FaTimes className="text-assertive" />
          </span>
        </li>
        <div className=" text-stable-dark ">
          Trimming is done per video. You can change the trim settings for this
          video from the media page.
        </div>
      </>
    )
  }

  const validateNorthOffsetInput = (value) => {
    const regex = /^([-]?[1-9]\d*|0|-0)$/
    if (value) {
      if (!value.match(regex)) {
        if (!value.match(/^([-]?1-9])/)) {
          setWarningMessage('Value is not valid, cannot start with a leading 0')
        } else {
          setWarningMessage('Value is not valid, please use whole degrees only')
        }
        setShowWarning(true)
        return false
      }
    }
    if (Number(value) < -180 || Number(value) > 180) {
      setWarningMessage('Value should be between -180˚ and 180˚')
      setShowWarning(true)
      return false
    }
    setShowWarning(false)
    return true
  }
  const handleNorthOffset = (e) => {
    if (Number(e.target.value) === sceneState.northOffset) {
      return setIsEditingNorthOffset(false)
    }
    const isValid = validateNorthOffsetInput(e.target.value)
    if (isValid) {
      setSceneState(() => ({
        ...sceneState,
        northOffset: e.target.value,
      }))
      updateScene({
        variables: {
          ...scene,
          northOffset: Math.floor(e.target.value),
        },
      })
      moveCameraToPosition(0, -e.target.value, true, false)
      setShowWarning(false)
      setWarningMessage('')
      setIsEditingNorthOffset(false)
    }
  }

  const getNorthOffsetCoords = () => {
    if (!isEditingNorthOffset)
      return (
        <div
          className="cell shrink text-normal"
          style={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
          }}
          onClick={() => setIsEditingNorthOffset(!isEditingNorthOffset)}>
          {Math.floor(sceneState.northOffset)}°
        </div>
      )
    return (
      <div
        className="cell shrink text-normal"
        style={{
          height: '30px',
          padding: '0 10px',
          fontSize: '14px',
        }}>
        <input
          className="c-video__input--north-offset no-arrows"
          type="number"
          min="-180"
          max="180"
          autoFocus={isEditingNorthOffset}
          onFocus={(e) => e.target.select()}
          ref={northOffsetInputRef}
          defaultValue={sceneState.northOffset}
          onBlur={handleNorthOffset}
          onSubmit={handleNorthOffset}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleNorthOffset(e)
            if (e.key === 'Escape') {
              setIsEditingNorthOffset(false)
            }
          }}
        />
      </div>
    )
  }
  return (
    <div className="tab">
      <div id="controls">
        <h3>Controls</h3>
        <div
          className="grid-x border-none"
          style={{ height: '30px', alignItems: 'center' }}>
          <div className="cell small-6 text-normal">
            Set initial orientation{' '}
            <DataTooltip
              position="right"
              title="Setting the initial orientation determines where trainees look at when they first enter a scene.">
              <FaQuestionCircle className="text-stable" />
            </DataTooltip>
          </div>
          <div className="cell small-2 grid-x text-stable">
            <div className="o-switch mr-2">
              <input
                type="checkbox"
                name="enableNorthOffset"
                id="enableNorthOffset"
                className="o-switch__input"
                checked={sceneState.northOffsetEnabled || false}
                onChange={() => {
                  updateScene({
                    variables: {
                      ...sceneState,
                      northOffsetEnabled: !sceneState.northOffsetEnabled,
                    },
                  })
                }}
              />
              <label
                htmlFor="enableNorthOffset"
                className="o-switch__paddle"></label>
            </div>
          </div>
          {sceneState.northOffsetEnabled && getNorthOffsetCoords()}
          {showWarning && (
            <div className="cell small-12">
              <label
                className={`o-slider__label text-assertive  mt-0 o-slider__invalid`}>
                {warningMessage}
              </label>
            </div>
          )}
        </div>
        <div className="cell grid-x mb-1" style={{ height: '48px' }}>
          <div className="cell small-12">
            {sceneState.northOffsetEnabled && (
              <RangeInput
                minValue={0}
                maxValue={360}
                showLabels={false}
                value={180 + sceneState.northOffset}
                noBackground={true}
                setValue={(v, save) => {
                  moveCameraToPosition(0, -v - 180, true, false)

                  if (isEditingNorthOffset) {
                    setShowWarning(false)
                    setIsEditingNorthOffset(false)
                  }
                  setSceneState(() => ({
                    ...sceneState,
                    northOffset: Math.floor(v - 180),
                  }))
                  if (save) {
                    updateScene({
                      variables: {
                        ...scene,
                        northOffsetEnabled: sceneState.northOffsetEnabled,
                        northOffset: Math.floor(v - 180),
                      },
                    })
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div id="media-information" className="pt-3 border-bottom">
        <h3>Media information</h3>
        <div className="list mb-2 text-normal">
          {getMediaInfo()}
          <br />
          {getTrimInfo()}
        </div>
      </div>
    </div>
  )
}

export default MediaSettingsTab
