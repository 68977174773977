import React from 'react'
import multiScene from '../../../../../../images/multi-scene.svg'
import { FaClone, FaTrash } from 'react-icons/fa'
import { useAllSelectedNodes } from '../../hooks/useSelectedNode'
import DeleteSceneModal from './DeleteSceneModal'
import useDuplicateNodes from '../../hooks/useDuplicateNodes'

const SidebarMulti = () => {
  const selectedNodes = useAllSelectedNodes()
  const duplicateNodes = useDuplicateNodes()

  const handleDuplicate = async (e) => {
    e.preventDefault()
    duplicateNodes(selectedNodes)
  }

  return (
    <aside
      id="sceneario-editor-sidebar"
      className="flex-container flex-dir-column align-middle text-center">
      <div
        style={{
          width: '200px',
          height: '200px',
          backgroundColor: 'var(--main-bg-color)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '120px',
        }}>
        <img src={multiScene} width="100px" alt="Empty scene" />
      </div>
      <h2 className="mt-2 text-bold">{selectedNodes.length} scenes selected</h2>
      <div className="flex-container align-middle align-justify">
        <button
          className="o-button mr-1 o-button--light text-normal text-bold"
          onClick={handleDuplicate}>
          <FaClone className="mr-0-5" /> Duplicate scenes
        </button>
        <button
          className="o-button o-button--light text-normal text-bold"
          data-open="delete-scene-modal">
          <FaTrash className="text-assertive mr-0-5" /> Delete scenes
        </button>
      </div>
      <div id="sceneario-editor-sidebar-modals" />
      <DeleteSceneModal
        id="delete-scene-modal"
        appendTo="#sceneario-editor-sidebar-modals"
        sceneId={selectedNodes.map((n) => n.id)}
      />
    </aside>
  )
}

export default SidebarMulti
