import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const MediaOptionsDropdown = ({
  viewHandler,
  downloadHandler,
  copyHandler,
  replaceHandler,
  deleteHandler,
  id,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  if (isHovered) {
    return (
      <Dropdown
        id={`media-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`media-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <li
          data-testid="list-item-viewhandler"
          className="o-dropdown__list-item text-dark text-normal"
          onClick={viewHandler}>
          View file
        </li>
        <li
          data-testid="list-item-downloadhandler"
          className="o-dropdown__list-item text-dark text-normal"
          onClick={downloadHandler}>
          Download original file
        </li>
        <hr className="mt-1 mb-1" />
        <li
          data-testid="list-item-copyhandler"
          className="o-dropdown__list-item text-dark text-normal"
          onClick={copyHandler}>
          Copy file
        </li>
        <li
          className="o-dropdown__list-item text-dark text-normal"
          onClick={replaceHandler}>
          Replace file
        </li>
        <hr className="mt-1 mb-1" />
        <li
          data-testid="list-item-deletehandler"
          className="o-dropdown__list-item text-dark text-normal"
          onClick={deleteHandler}>
          Delete file
        </li>
      </Dropdown>
    )
  }
  return (
    <button
      data-testid="non-hovered-button"
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`user-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}
export default MediaOptionsDropdown
