import React, { useRef, useState, useEffect } from 'react'
import { useDirectStyle, directstyled } from 'direct-styled'
import { useDrag } from 'react-use-gesture'
import { clamp } from '../../../utils/helpers/javascript'
import DataTooltip from '../DataTooltip'

const RangeInput = ({
  minValue,
  maxValue,
  value,
  setValue,
  updateOnDrag = true,
  reversed,
  formatLabel,
  redThumb = false,
  noBackground = false,
  noContainer = false,
  showLabels = true,
  showLabelsOnHoverOnly = false,
  redRange = null,
  thumbTooltip = '',
  showThumbTooltip = false,
}) => {
  const barRef = useRef(null)
  const [current, setCurrent] = useState(value)
  const [isRange, setIsRange] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [thumbStyle, setThumbStyle] = useDirectStyle()
  const [thumbStyleMin, setThumbStyleMin] = useDirectStyle()
  const [thumbInfoStyle, setThumbInfoStyle] = useDirectStyle()
  const [thumbInfoMinStyle, setThumbInfoMinStyle] = useDirectStyle()
  const [barStyle, setBarStyle] = useDirectStyle()
  const [isMounted, setIsMounted] = useState(true)

  const getProgress = (barRect, clientX, min, max) => {
    const currentValue = clamp(
      ((clientX - barRect.left) / barRect.width) * maxValue,
      Number(min),
      Number(max)
    ).toFixed(3)

    const progress = (currentValue / maxValue) * 100
    return { currentValue, progress }
  }

  const setStyles = (progress, setMin = false) => {
    if (setMin) {
      setThumbStyleMin({
        left: `${progress}%`,
        zIndex: progress > 80 ? '4' : '3',
      })
      setThumbInfoMinStyle({
        left: `${progress}%`,
      })
      const progressMax = (current.max / maxValue) * 100
      setBarStyle({
        background: `linear-gradient(to right,
                      #ccc 0%, #ccc ${progress}%,
                      #26272c ${progress}%, #26272c ${progressMax}%,
                      #ccc ${progressMax}%, #ccc 100%)`,
      })
    } else {
      setThumbStyle({
        left: `${progress}%`,
        zIndex: progress < 20 ? '4' : '3',
      })
      setThumbInfoStyle({
        left: `${progress}%`,
      })
      if (isRange) {
        const progressMin = (current.min / maxValue) * 100
        setBarStyle({
          background: `linear-gradient( to right,
                        #ccc 0%, #ccc ${progressMin}%,
                        #26272c ${progressMin}%, #26272c ${progress}%,
                        #ccc ${progress}%, #ccc 100%
        )`,
        })
      } else if (reversed) {
        setBarStyle({
          background: `linear-gradient( to right,
          #ccc 0%, #ccc ${progress}%,
          #26272c ${progress}%, #26272c 100%)`,
        })
      } else if (noBackground) {
        setBarStyle({ background: '#ccc' })
      } else {
        setBarStyle({
          background: `linear-gradient( to right,
            #26272c 0%, #26272c ${progress}%,
            #ccc ${progress}%, #ccc 100%)`,
        })
      }
    }
  }

  const bindMin = useDrag(
    ({ xy, first, last, event }) => {
      event.preventDefault()

      const { currentValue, progress } = getProgress(
        barRef.current.getBoundingClientRect(),
        xy[0],
        minValue,
        current.max
      )

      if (first) setDragging(true)

      if (dragging && updateOnDrag) setValue({ ...current, min: currentValue })
      setCurrent({ ...current, min: currentValue })

      if (last) {
        setValue(current, true)
        setDragging(false)
      } else {
        setStyles(progress, true)
      }
    },
    {
      event: { passive: false, capture: true },
    }
  )

  const bind = useDrag(
    ({ xy, first, last, event }) => {
      event.preventDefault()
      const { currentValue, progress } = getProgress(
        barRef.current.getBoundingClientRect(),
        xy[0],
        isRange ? current.min : minValue,
        maxValue
      )

      if (first) setDragging(true)

      if (dragging && updateOnDrag)
        setValue(isRange ? { ...current, max: currentValue } : currentValue)

      setCurrent(isRange ? { ...current, max: currentValue } : currentValue)

      if (last) {
        setValue(current, true)
        setDragging(false)
      } else {
        setStyles(progress)
      }
    },
    { event: { passive: false, capture: true } }
  )

  useEffect(() => {
    if (typeof value === 'object') {
      setIsRange(true)
      setStyles((value.max / maxValue) * 100)
      setStyles((value.min / maxValue) * 100, true)
      setCurrent(value)
    } else {
      setStyles((value / maxValue) * 100 || 0)
      setCurrent(value)
    }
  }, [value, maxValue, minValue, dragging])

  useEffect(() => {
    if (isMounted)
      $(barRef.current.querySelector('[data-tooltip]')).foundation(
        showThumbTooltip ? 'show' : 'hide'
      )
  }, [showThumbTooltip, isMounted])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div
      className={`range__input ${noContainer ? 'no-container' : ''}`}
      ref={barRef}>
      <directstyled.div className="track" style={barStyle}>
        {redRange?.[0] && redRange?.[1] && (
          <div
            className="red-range"
            style={{
              left: `${(redRange[0] / maxValue) * 100}%`,
              width: `${((redRange[1] - redRange[0]) / maxValue) * 100}%`,
            }}
          />
        )}
      </directstyled.div>

      {isRange && (
        <>
          <directstyled.div
            {...bindMin()}
            className={`range__input--thumb ${redThumb ? 'red-thumb' : ''}`}
            style={thumbStyleMin}
          />
          {(showLabels || (showLabelsOnHoverOnly && dragging)) && (
            <directstyled.div
              className="range__input--thumb-info"
              style={thumbInfoMinStyle}>
              {formatLabel ? formatLabel(current.min) : current.min}{' '}
            </directstyled.div>
          )}
        </>
      )}
      <directstyled.div
        {...bind()}
        className={`range__input--thumb ${redThumb ? 'red-thumb' : ''}`}
        style={thumbStyle}>
        <DataTooltip
          id=""
          hOffset={22}
          vOffset={9}
          title={thumbTooltip}
          position="right"
        />
      </directstyled.div>
      {(showLabels || (showLabelsOnHoverOnly && dragging)) && (
        <directstyled.div
          className="range__input--thumb-info"
          style={thumbInfoStyle}>
          {formatLabel
            ? formatLabel(current.max || current)
            : current.max || current}
        </directstyled.div>
      )}
      {showLabels && (
        <div className="range__input--range-info">
          <div>{formatLabel ? formatLabel(minValue) : minValue}</div>
          <div>{formatLabel ? formatLabel(maxValue) : maxValue}</div>
        </div>
      )}
    </div>
  )
}
export default RangeInput
