import React, { useState } from 'react'
import { userActivitiesQuery } from '../../apollo/query/user'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../UI/LoadingSpinner'
import Table from '../Table'
import TableHeader from '../UI/TableHeader'
import { sortHandler } from '../tableFunctions'
import { dateFormat, sortOnKey } from '../../utils/format'
import { actionFormatter } from '../../utils/helpers/activity'
import { capitalized } from '../../utils/helpers/javascript'
import { useQuery } from '@apollo/client'

const tableHeaders = ['createdAt', 'action', 'info', 'browserOs', 'location']

const SecurityTab = () => {
  const { data, loading } = useQuery(userActivitiesQuery)
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'createdAt',
    },
    filteredColumns: [],
  })

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    const rows = [...data.userActivities]
    let activities = sortOnKey(rows, value, isAscending)

    return activities.map((activity) => ({
      createdAt: dateFormat(activity.createdAt),
      action: {
        value: activity.action,
        render: actionFormatter(activity.action),
      },
      info: {
        render: activity.metadata.map((metadata) => (
          <span key={metadata.key} className="mr-1">
            <b>{capitalized(metadata.key)}</b>: {metadata.value}
          </span>
        )),
      },
      browserOs: activity.browserName
        ? `${activity.browserName} ${
            activity.browserVersion?.split('.')[0]
          } on ${activity.osName} ${activity.osFullVersion}`
        : '-',
      location: activity.geoCity
        ? `${capitalized(activity.geoCity)}, ${capitalized(
            activity.geoCountry
          )}`
        : '-',
    }))
  }

  return (
    <>
      <TableHeader title="Security log" />
      <Table
        data={sortedData()}
        headers={tableHeaders.filter(
          (i) => !preferences.filteredColumns.includes(i)
        )}
        sortOn={preferences.sortOn}
        sortHandler={(value) => {
          sortHandler(value, preferences, setPreferences)
        }}
        type="activities"
      />
    </>
  )
}
export default SecurityTab
