import React, { useContext } from 'react'
import PieGraph from '../UI/Graphs/PieGraph'
import Bullet from './Bullet'
import colors from '../Editor/helpers/colors'
import { getHexOpacityArray } from '../../utils/helpers/color'
import Stars from '../UI/Stars/Stars'
import { gql, useQuery } from '@apollo/client'
import ActivityContext from '../../utils/context/ActivityContext'
import Loading from './Loading'
import EmptyParagraph from './EmptyParagraph'

const getStarsAttemptsQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
  ) {
    attemptsMetrics(
      metric: COUNT
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: STARS) {
        id
        name
        value
      }
    }
  }
`

const ActivityStars = ({ attemptsMetricsData }) => {
  const { apiVars } = useContext(ActivityContext)
  const { data: starsMetrics } = useQuery(getStarsAttemptsQuery, {
    variables: apiVars,
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })
  let stars =
    starsMetrics?.attemptsMetrics?.grouped
      ?.map((c) => ({
        name: `${c.id > 0 ? c.id : 'No'} stars`,
        stars: Number(c.id),
        value: Number(c.value),
      }))
      ?.sort((a, b) => b.stars - a.stars) ?? []

  const hexOpacityArray = getHexOpacityArray(stars?.length)

  return (
    <>
      <div className="mb-2 flex-container align-justify align-middle">
        <h2 className="text-bold mb-0">Stars</h2>
        <span className="text-normal text-stable-dark">
          Showing only completed attempts
        </span>
      </div>
      <div className="border-light border-radius">
        <div className="pt-3 pb-3 border-light-bottom">
          <PieGraph data={stars} />
        </div>
        {stars?.map((entity, index) => (
          <div
            key={entity.name}
            className={`text-normal pt-2 pb-2 pl-3 pr-3 flex-container align-middle align-justify ${
              index < stars.length - 1 ? 'border-light-bottom' : ''
            }`}>
            <div className="flex-container align-middle align-justify">
              <Bullet color={`${colors.assertive}${hexOpacityArray[index]}`} />
              <div className="flex-container align-middle align-justify ml-1">
                {entity.stars > 0 && (
                  <>
                    <Stars value={entity.stars} />
                    <span className="ml-1">{entity.name}</span>
                  </>
                )}
                {entity.stars === 0 && 'No stars'}
              </div>
            </div>
            <span className="text-bold">{entity.value}</span>
          </div>
        ))}
        {stars?.length === 0 && <EmptyParagraph />}
        {!stars && <Loading />}
      </div>
    </>
  )
}

export default ActivityStars
