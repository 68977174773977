import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaProductHunt, FaBoxOpen } from 'react-icons/fa'
import withApollo from '../../hooks/withApollo'
import usePreferences from '../../hooks/usePreferences'
import Table from '../../components/Table'
import { getProductsQuery } from '../../apollo/query/products'
import Delay from '../../utils/helpers/Delay'
import Checkbox from '../../components/UI/Form/Checkbox'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import TableHeader from '../../components/UI/TableHeader'
import { default as S } from '../../utils/lang/en.json'
import TableColumnsDropdown from '../../components/UI/TableColumnsDropdown'
import ProductOptionDropdown from '../../components/Store/ProductOptionDropdown'
import { dateFormat, sortOnKey } from '../../utils/format'
import ProductState from '../../components/Store/Products/ProductState'
import ProductGroups from '../../components/Store/Products/ProductGroups'
import AddProductModal from '../../components/Store/Products/Modal/AddProductModal'
import DeleteProductModal from '../../components/Store/Products/Modal/DeleteProductModal'
import EditProductModal from '../../components/Store/Products/Modal/EditProductModal'
import PublishProductModal from '../../components/Store/Products/Modal/PublishProductModal'
import ArchiveProductModal from '../../components/Store/Products/Modal/ArchiveProductModal'
import {
  filterColumnsHandler,
  sortHandler,
} from '../../components/tableFunctions'
import TableSearchBar from '../../components/UI/Table/TableSearchBar'
import TableFilters from '../../components/UI/Table/TableFilters'
import TableAddButton from '../../components/UI/Table/TableAddButton'
import EditCommissionModal from '../../components/Store/Products/Modal/EditCommissionModal'
import { adminGetAllProductsQuery } from '../../apollo/query/admin/store'
import FilterEmptyState from '../../components/UI/Menu/FilterEmptyState'
import ProductScenarios from '../../components/Store/Products/ProductScenarios'

const Products = ({ adminPanel = false }) => {
  const [preferences, setPreferences] = usePreferences('products')
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filterOption, setFilterOption] = useState({
    state: '',
    plan: '',
    licenseType: '',
  })

  const [currentProduct, setCurrentProduct] = useState(null)

  const { data, loading, refetch } = useQuery(
    adminPanel ? adminGetAllProductsQuery : getProductsQuery
  )

  let tableHeaders = [
    'name',
    'state',
    'courses',
    'scenarios',
    'kind',
    'licenseType',
    'licenseDuration',
    'price',
    'createdAt',
  ]
  if (adminPanel) {
    tableHeaders = [
      'name',
      'sellingClientName',
      'state',
      'courses',
      'scenarios',
      'kind',
      'licenseType',
      'licenseDuration',
      'price',
      'commission',
      'createdAt',
    ]
  }

  if (loading || !preferences || !data) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const sortedData = () => {
    let rows = adminPanel
      ? data.allProducts.map((product) => {
          return {
            ...product,
            sellingClientName: product.client.name,
          }
        })
      : data.products

    if (filterOption.state) {
      rows = rows.filter(({ state }) => state === filterOption.state)
    }
    if (filterOption.plan) {
      rows = rows.filter(({ kind: plan }) => plan === filterOption.plan)
    }
    if (filterOption.licenseType) {
      rows = rows.filter(
        ({ licenseType }) => licenseType === filterOption.licenseType
      )
    }
    const filtered = rows.filter((p) => {
      const filter = `${p.name} ${p.client.name}`
      return filter.toLowerCase().includes(searchQuery.toLowerCase())
    })
    if (!filtered.length && !showEmptyState) setShowEmptyState(true)
    if (filtered.length && showEmptyState) setShowEmptyState(false)

    return sortOnKey(
      filtered,
      preferences.sortOn.value,
      preferences.sortOn.isAscending
    ).map((p) => ({
      id: p.id,
      name: p.name,
      sellingClientName: p.client.name,
      state: {
        value: p.state,
        render: <ProductState state={p.state} hasImage={!!p.imageUrl} />,
      },
      courses: {
        value: p.courses.length,
        render: (
          <ProductGroups
            count={p.courses.length}
            names={p.courses.map(({ course }) => course.name)}
          />
        ),
      },
      scenarios: {
        value: p.scenarios.length,
        render: (
          <ProductScenarios
            count={p.scenarios.length}
            names={p.scenarios.map(({ scenario }) => scenario.name)}
          />
        ),
      },
      kind: {
        value: p.kind,
        render: S.store.kind[p.kind],
      },
      licenseType: {
        value: p.licenseType,
        render: S.store.licenseType[p.licenseType],
      },

      licenseDuration: {
        value: p.licenseDuration,
        render: `${p.licenseDuration} month${
          p.licenseDuration === 1 ? '' : 's'
        }`,
      },
      price: {
        value: p.price,
        render: `€ ${p.price}`,
      },
      commission: {
        value: p.commission,
        render: `${p.commission} %`,
      },
      createdAt: dateFormat(p.createdAt),
    }))
  }

  const loadModals = () => {
    const appendTo = '#products'
    if (adminPanel) {
      return (
        <EditCommissionModal
          id="edit-commission-modal"
          appendTo={appendTo}
          productId={currentProduct}
          product={data.allProducts.find(({ id }) => id === currentProduct)}
        />
      )
    }
    return (
      <>
        <AddProductModal
          id="add-product"
          appendTo={appendTo}
          callback={refetch}
        />
        <EditProductModal
          id="edit-product-modal"
          appendTo={appendTo}
          callback={() => setCurrentProduct(null)}
          product={data.products.find(({ id }) => id === currentProduct)}
        />
        <ArchiveProductModal
          id="archive-product-modal"
          appendTo={appendTo}
          callback={() => setCurrentProduct(null)}
          product={data.products.find(({ id }) => id === currentProduct)}
        />
        <PublishProductModal
          id="publish-product-modal"
          appendTo={appendTo}
          callback={() => {
            setCurrentProduct(null)
          }}
          product={data.products.find(({ id }) => id === currentProduct)}
        />

        <DeleteProductModal
          callback={() => {
            setCurrentProduct(null)
            setSelectedItems([])
          }}
          id="delete-product-modal"
          products={data.products}
          appendTo={appendTo}
          ids={currentProduct ? [currentProduct] : selectedItems}
        />
      </>
    )
  }

  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    return setSelectedItems(newSelection)
  }

  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0

      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.products.headers[item]}
          />
        </li>
      )
    })
  }

  const filters = [
    {
      text: 'Status',
      type: 'state',
      options: S.store.products.state,
    },
    {
      text: 'Pricing plan',
      type: 'plan',
      options: S.store.kind,
    },
    {
      text: 'License type',
      type: 'licenseType',
      options: S.store.licenseType,
    },
  ]
  const leftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setSearchQuery={setSearchQuery}
        setShowEmptyState={setShowEmptyState}
      />
      <TableFilters
        filters={filters}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        setFilterOption={setFilterOption}
        filterOption={filterOption}
      />
    </>
  )

  const renderAdminOptions = (id) => {
    return (
      <ProductOptionDropdown
        id={id}
        adminPane={true}
        currentProductHandler={(i) => setCurrentProduct(i)}
      />
    )
  }
  const renderOptions = (id) => {
    const { state } = data.products.find((i) => i.id === id)
    if (state === 'ARCHIVED') return null
    return (
      <ProductOptionDropdown
        id={id}
        currentProductHandler={(i) => setCurrentProduct(i)}
        canBePublished={state === 'PREPARED'}
        isArchived={state === 'ARCHIVED'}
        isPublished={state === 'READY'}
      />
    )
  }

  const tableHeader = () => (
    <div className="grid-container fluid">
      <TableHeader
        length={[...sortedData()].length}
        title="Product"
        leftBar={leftBar()}
        rightBar={
          <>
            <TableColumnsDropdown content={content} />
            {!adminPanel && (
              <TableAddButton dataOpen="add-product" title="Add product" />
            )}
          </>
        }
      />
    </div>
  )
  if (
    (adminPanel && !data.allProducts.length) ||
    (!adminPanel && !data.products.length)
  ) {
    return (
      <div id="products">
        {loadModals()}
        {tableHeader()}
        <div style={{ display: 'grid', placeItems: 'center', height: '80vh' }}>
          <span className="text-center">
            <span className="o-table__empty--icon ">
              <FaBoxOpen />
            </span>
            <h2 className="text-bold text-dark" onClick={() => {}}>
              {`You don't have any products yet`}
            </h2>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div id="products">
      {loadModals()}
      {tableHeader()}
      <div className="o-table--page-container grid-container fluid">
        {showEmptyState ? (
          <FilterEmptyState
            type="products"
            icon={<FaProductHunt />}
            clickHandler={() => {
              setSearchQuery('')
              setSelectedItems([])
              setShowEmptyState(false)
              setFilterOption({
                state: '',
                plan: '',
                licenseType: '',
              })
            }}
          />
        ) : (
          <Table
            data={sortedData()}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            sortOn={preferences.sortOn}
            sortHandler={(value) => {
              sortHandler(value, preferences, setPreferences)
            }}
            selectedItems={selectedItems}
            checkboxHandler={checkboxHandler}
            selectAllHandler={(ids) => setSelectedItems(ids)}
            type="products"
            rowSuffix={(i) => {
              if (adminPanel) return renderAdminOptions(i)
              return renderOptions(i)
            }}
          />
        )}
      </div>
    </div>
  )
}
export default withApollo(Products)
