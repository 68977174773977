import React, { useEffect, useRef } from 'react'
import { Handle, Position, useEdges, useReactFlow } from 'reactflow'
import {
  useRemoveNewNodePlaceholder,
  selectNodes,
  setNode,
} from '../../../helpers/nodeHelper'
import useAddUpdateScene from '../../../hooks/useAddUpdateScene'
import { newEdgeId } from '../../../helpers/edgeHelper'

const NewNode = (node) => {
  const { data, xPos, yPos } = node
  const removeNewNodePlaceholder = useRemoveNewNodePlaceholder()
  const reactFlow = useReactFlow()
  const edges = useEdges()
  const inputRef = useRef()

  const { addScene } = useAddUpdateScene()
  const connectedEdge = edges.find((e) => e.id === newEdgeId)

  const handleAddScene = (selectNode = false) => {
    addScene({
      name: inputRef.current.value,
      scenarioId: data.scenarioId,
      canvasX: xPos,
      canvasY: yPos,
      selected: selectNode,
      filming: true,
    })
  }

  const handleEnter = () => handleAddScene(true)

  const handlePaneClick = () => {
    if (inputRef.current.value) handleAddScene()
    else removeNewNodePlaceholder()
  }

  useEffect(() => {
    document.addEventListener('reactflowPaneClick', handlePaneClick)
    return () =>
      document.removeEventListener('reactflowPaneClick', handlePaneClick)
  }, [])

  const handleChange = (e) => {
    setNode(reactFlow, {
      ...node,
      position: {
        x: xPos,
        y: yPos,
      },
      data: { ...data, name: e.target.value, elements: [] },
    })
  }

  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus()
    }, 0)

    const handleKeydown = (event) => {
      if (event.key === 'Escape') removeNewNodePlaceholder()
      if (event.key === 'Enter') handleEnter()
    }
    window.addEventListener('keydown', handleKeydown)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [node])

  return (
    <div className="o-scene o-scene--new">
      <input
        type="text"
        placeholder="Give scene a title or leave empty"
        ref={inputRef}
        className="tracking-tighter"
        onChange={handleChange}
      />
      {connectedEdge && (
        <Handle
          type="target"
          position={Position.Left}
          id={`e${data.id}`}
          className="o-scene__handle"
        />
      )}
    </div>
  )
}

export default NewNode
