
function setItems(element, placeholder) {
  var target = $(element).attr('data-toggle');
  var select = $('#' + target);

  var newHtml = placeholder;

  if (select.length > 0) {
    var all = select.find('input[type="checkbox"]');
    var items = select.find('input[type="checkbox"]:checked');
    var results = items
      .parent()
      .map(function() {
        return $(this).text().trim();
      })
      .get()
      .join(', ');

    if (items.length === all.length) {
      results = $(element).attr('data-select-all');
    }

    if (items.length > 0) {
      newHtml = results;
    }
  }

  $(element).html(newHtml);
}

export function init() {
  $('.o-select[data-toggle]').each(function() {
    var element = $(this);
    var placeholder = $(this).text();
    setItems(element, placeholder);

    // Set up listener on associated inputs
    var select = '#' + $(this).attr('data-toggle') + ' input';
    $(document).on('load change', select, function() {
      setItems(element, placeholder);
    });
  });
}
