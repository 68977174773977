import React from 'react'
import { FaPuzzlePiece } from 'react-icons/fa'

const IntegrationsEmptyState = ({ renderDesc, hidden }) => {
  const organisationSettingsLink = () => (
    <a
      href="/settings/integrations"
      className="text-underline cursor-pointer u-color-stable-dark">
      workspace settings
    </a>
  )
  const getDesc = () =>
    renderDesc || (
      <span className="text-stable-dark">
        Go to {organisationSettingsLink()} to add your first integration
      </span>
    )

  return (
    <div
      data-testid="empty-group-wrapper"
      className="o-empty-state"
      hidden={hidden}>
      <div className="flex-container align-center">
        <span className="text-center">
          <span className="o-table__empty--icon">
            <div
              style={{
                display: 'flex',
                position: 'relative',
                left: '9px',
                bottom: '6px',
              }}>
              <FaPuzzlePiece size="100" />
            </div>
          </span>
          <h3 className="text-bold text-dark">
            You don't have any active integrations
          </h3>
          {getDesc()}
        </span>
      </div>
    </div>
  )
}
export default IntegrationsEmptyState
