import React, { Suspense, useContext, useEffect } from 'react'
import ScenarioEditorProvider, {
  ScenarioEditorContext,
} from '../components/FlowV2/context/ScenarioEditorProvider'
import withApollo from '../hooks/withApollo'
import { Route, Link, Routes, useLocation } from 'react-router-dom'
import {
  EDITOR_TABS,
  useEditorActiveTab,
} from '../components/FlowV2/helpers/editorHelper'
import { ReactFlowProvider } from 'reactflow'
import FlowEditor from '../components/FlowV2/tabs/FlowEditor'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import TestPublishButtons from '../components/FlowV2/components/FlowEditor/Publish/TestPublishButtons'
import DeleteElementModal from '../components/FlowV2/components/Sidebar/DeleteElementModal'
import RandomizerModal from '../components/FlowV2/components/Sidebar/RandomizerModal'

const ScenarioEditor = () => {
  const activeTab = useEditorActiveTab()
  const { scenario } = useContext(ScenarioEditorContext)
  const location = useLocation()

  useEffect(() => {
    // Add a trailing slash to the URL if it's missing
    const regex = /^\/scenarios\/\d+$/
    if (regex.test(window.location.pathname))
      window.history.replaceState(null, null, window.location.pathname + '/')
  }, [])

  if (!scenario) return null

  return (
    <div id="scenario-editor">
      <div className="c-sub-menu" style={{ marginTop: '-2.5rem' }}>
        <div className="grid-container fluid flex-container align-middle align-justify">
          <div className="c-sub-menu__inner">
            <div className="c-sub-menu__filter">
              <p>{scenario.name}</p>
            </div>
            <ul className="c-sub-menu__list">
              {EDITOR_TABS.map((tab) => (
                <li
                  key={tab.key}
                  className={`c-sub-menu__list-item ${
                    activeTab.key === tab.key ? 'active' : ''
                  }`}>
                  <Link to={`/${tab.key}`} className="c-sub-menu__list-link">
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <TestPublishButtons />
        </div>
      </div>

      <div
        hidden={EDITOR_TABS.some(
          (tab) => tab.isRoute && location.pathname.includes(tab.key)
        )}>
        <FlowEditor />
      </div>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {EDITOR_TABS.filter((tab) => tab.isRoute).map((tab) => (
            <Route key={tab.key} path={`${tab.key}/*`} element={tab.content} />
          ))}
        </Routes>
      </Suspense>

      <div id="scenario-modals" />

      <DeleteElementModal appendTo="#scenario-modals" />
      <RandomizerModal appendTo="#scenario-modals" />
    </div>
  )
}

const ScenarioEditorWrapper = ({ scenarioId }) => {
  return (
    <ScenarioEditorProvider scenarioId={scenarioId}>
      <ReactFlowProvider>
        <ScenarioEditor scenarioId={scenarioId} />
      </ReactFlowProvider>
    </ScenarioEditorProvider>
  )
}

export default withApollo(ScenarioEditorWrapper)
