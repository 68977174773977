import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import { useQuery } from '@apollo/client'
import Dropdown from '../UI/Menu/Dropdown'
import useCurrentUser from '../../hooks/useCurrentUser'
import { clientsQuery } from '../../apollo/query/user'

const WorkspaceOptionDropdown = ({ id, onClick, deleteTemporaryDisabled }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [currentUser] = useCurrentUser()

  const { data: clientsData } = useQuery(clientsQuery, {
    skip: !currentUser,
  })

  const handleClick = (e) => {
    e.preventDefault()
    const { action } = e.target.dataset
    if (action === 'delete' && deletedDisabled) return false
    onClick(action)
  }

  if (!currentUser || (!currentUser.administrator && !clientsData)) return null

  const clients = clientsData?.clients

  if (isHovered) {
    return (
      <Dropdown
        id={`workspace-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`workspace-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <>
          <li
            className={`o-dropdown__list-item text-normal ${
              !clients?.find((c) => c.id === id)
                ? 'o-dropdown__item--inactive text-stable'
                : 'text-dark'
            }`}
            data-action="switch"
            onClick={handleClick}>
            Switch to workspace
          </li>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            data-action="edit"
            onClick={handleClick}>
            Edit workspace
          </li>
          {deleteTemporaryDisabled && (
            <>
              <li className="o-dropdown__divider" />
              <li
                className={`o-dropdown__list-item text-normal text-stable text-small`}
                style={{ maxWidth: '300px', whiteSpace: 'normal' }}>
                There is no support yet for deleting workspaces. Contact us at
                support@warpvr.com when you would like a workspace to be
                deleted.
              </li>
            </>
          )}
        </>
      </Dropdown>
    )
  }
  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`workspace-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default WorkspaceOptionDropdown
