import React from 'react'
import { FaImage, FaPlus } from 'react-icons/fa'
import AddCourseModal from '../../components/Courses/Modal/AddCourseModal'
import TableHeader from '../../components/UI/TableHeader'

const CoursesEmptyPage = () => (
  <div
    id="courses"
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}>
    <AddCourseModal id="addCourseModal" appendTo="#courses" />
    <div className="grid-container fluid">
      <TableHeader
        length={0}
        title="Course"
        rightBar={
          <span className="cell shrink" style={{ zIndex: '2' }}>
            <button
              data-open="addCourseModal"
              className="button primary mb-0 cursor-pointer">
              <FaPlus className="mr-1" /> New course
            </button>
          </span>
        }
      />
    </div>
    <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
      <span className="text-center">
        <span className="o-table__empty--icon ">
          <FaImage />
        </span>
        <h2 className="text-bold text-dark" onClick={() => {}}>
          {`You don't have any courses yet`}
        </h2>
        <span
          className="text-normal text-stable-dark text-underline cursor-pointer"
          onClick={() => $('#addCourseModal').foundation('open')}>
          Create your first course now!
        </span>
      </span>
    </div>
  </div>
)

export default CoursesEmptyPage
