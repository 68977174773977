import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({
  clickHandler,
  checked,
  value,
  disabled,
  preventDefault,
  style,
  truncate,
}) => {
  return (
    <label
      data-testid="label"
      style={style}
      className={`o-checkbox ${truncate ? 'text-truncate' : ''} ${
        disabled ? 'o-checkbox--disabled' : ''
      }`}>
      <input
        data-testid="checkbox"
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          if (preventDefault) {
            e.preventDefault()
          }
          clickHandler?.(e.target.checked)
        }}
      />
      {/* below span is required for layout.. */}
      <span></span>
      {value}
    </label>
  )
}
export default Checkbox

Checkbox.propTypes = {
  clickHandler: PropTypes.func,
  checked: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
}
