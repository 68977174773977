import React from 'react'
import {
  FaArrowUp,
  FaBars,
  FaComment,
  FaCommentAlt,
  FaDotCircle,
  FaInfo,
  FaMinus,
  FaRandom,
  FaRedo,
  FaStopwatch,
} from 'react-icons/fa'

export const ELEMENT_TYPES = [
  { type: 'MPC', title: 'Multiple choice', icon: <FaBars /> },
  { type: 'INFORMATION', title: 'Information', icon: <FaInfo /> },
  { type: 'DIRECTION', title: 'Direction', icon: <FaArrowUp /> },
  { type: 'HOTSPOT', title: 'Hotspot', icon: <FaDotCircle /> },
  { type: 'BUTTON', title: 'Button', icon: <FaMinus /> },
  { type: 'QUESTION', title: 'Question', icon: <FaCommentAlt />, hide: true },
  { type: 'ANSWER', title: 'Answer', icon: <FaMinus />, hide: true },
  { type: 'TIMER', title: 'Timer', icon: <FaStopwatch /> },
  { type: 'TRANSITION', title: 'Auto-transition', icon: <FaRedo /> },
  { type: 'TOOLTIP', title: 'Tooltip', icon: <FaComment /> },
  { type: 'RANDOMIZER', title: 'Randomizer', icon: <FaRandom />, hide: true },
]

export const INTERACTIVE_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TIMER',
  'TRANSITION',
]

export const GROUP_ELEMENT_TYPES = [
  { type: 'INFORMATION', subEls: ['TOOLTIP', 'BUTTON'] },
  { type: 'MPC', subEls: ['QUESTION', 'ANSWER'] },
]

export const GROUP_PARENT_ELEMENT_TYPES = ['QUESTION', 'TOOLTIP']
export const GROUP_CHILD_ELEMENT_TYPES = ['ANSWER', 'BUTTON']

export const VIDEO_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TOOLTIP',
]

export const getLetterFromNumber = (number) => {
  let result = ''
  while (number > 0) {
    let remainder = (number - 1) % 26
    result = String.fromCharCode(65 + remainder) + result
    number = Math.floor((number - 1) / 26)
  }
  return result
}

export const getPointClass = (points, scoringSystem) => {
  const classes = [
    'stable-dark',
    'royal',
    scoringSystem === 'SUBTRACT' ? 'assertive' : 'energized',
  ]
  if (scoringSystem === 'DISABLED') return classes[0]
  if (points > 2) return 'balanced'

  return classes[points ?? 0]
}

export const getPointColor = (points, scoringSystem) => {
  const colors = [
    '#93969f',
    '#e8c60a',
    scoringSystem === 'SUBTRACT' ? '#e7461a' : '#70c8b8',
  ]
  if (scoringSystem === 'DISABLED') return colors[0]
  if (points > 2) return '#548ab9'
  return colors[points ?? 0]
}

export const getElementFromSourceHandle = (reactFlow, nodeId, handleId) =>
  reactFlow
    .getNode(nodeId)
    .data.elements?.find((e) => e.id === handleId.split('-')?.[1])

export const getConnectedElementsToScene = (scenes, sceneId) => {
  return scenes
    .map((scene) => scene.elements)
    .flat()
    .filter(
      (el) =>
        el.linkToId === sceneId || el.randomizedLinkToIds?.includes(sceneId)
    )
}

export const getDisplayedNodeElements = (nodeData) => {
  let nextParentIndex = 0
  if(!nodeData?.elements) return []

  const allAnswerElements = nodeData.elements.filter(
    (el) => GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
  )

  let elements = nodeData.elements
    .filter((el) => !allAnswerElements.find((aEl) => aEl.id === el.id))
    .sort((a, b) => a.id - b.id)

  return elements.map((el) => {
    let answerElements = allAnswerElements.filter(
      (a) => a.groupUuid === el.groupUuid
    )
    const elementHasLetter =
      INTERACTIVE_ELEMENT_TYPES.includes(el.kind) ||
      (el.groupUuid && answerElements.length)

    const parentIndex = nextParentIndex
    nextParentIndex +=
      (elementHasLetter ? 1 : 0) +
      (answerElements.length ? answerElements.length - 1 : 0)

    answerElements = answerElements.map((ae, childIndex) => ({
      ...ae,
      letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(ae.kind)
        ? getLetterFromNumber(parentIndex + (childIndex ?? 0) + 1)
        : '',
    })).sort((a, b) => a.id - b.id)

    return {
      ...el,
      index: parentIndex,
      letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(el.kind)
        ? getLetterFromNumber(parentIndex + 1)
        : '',
      answerElements,
    }
  })
}

export const selectSidebarElement = (id) => {
  const sidebarCard = document.querySelector(`#${id}`)
  if (sidebarCard) sidebarCard.classList.add('active')
}

export const unSelectSidebarElements = () => {
  ;[
    ...document.querySelectorAll('.c-video__editor--elements--card.active'),
  ].forEach((el) => el.classList.remove('active'))
}

export const getLatestNumber = (node, groupUuid) => {
  if (!node) return 0

  const elements = node.data.elements

  if (groupUuid) {
    const lastGroupElement = elements
      .filter(
        (el) =>
          el.groupUuid === groupUuid &&
          GROUP_CHILD_ELEMENT_TYPES.includes(el.kind)
      )
      ?.slice(-1)?.[0]
    if (lastGroupElement) return lastGroupElement.number ?? 0
  }

  return (
    elements
      .filter((el) => INTERACTIVE_ELEMENT_TYPES.includes(el.kind))
      ?.slice(-1)?.[0]?.number ?? 0
  )
}

export const fillElementDefaultValues = (element) => ({
  ...element,
  showInLookDirection: element.showInLookDirection ?? false,
  label: element.label ?? '',
})
