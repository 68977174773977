import React from 'react'
import { capitalized, padZero } from '../../../../../utils/helpers/javascript'
import { ELEMENT_TYPES } from '../../../helpers/elementHelper'
import RandomizerLinks from './RandomizerLinks'
import LinkElement from './LinkElement'
import { useEdges } from 'reactflow'

const NodeElementCard = ({
  element: parentElement,
  nodeData,
  allSourceHandles,
  answerElements,
  index: parentIndex,
  isExport,
}) => {
  const edges = useEdges()

  const renderCard = (element, parentIndex, childIndex) => {
    const elRow = ELEMENT_TYPES.find((el) => el.type === element.kind)
    if (!elRow) return <></>

    const renderLabel = () => {
      if (element.kind === 'TIMER')
        return `Timer is set to ${padZero(element.timerMinutes ?? 0)}:${padZero(element.timerSeconds ?? 0)}`
      return element.label ? element.label : `${capitalized(elRow.title)}...`
    }

    const sourceHandle = allSourceHandles?.find(
      (h) => h.id === `e${nodeData.id}-${element.id}`
    )
    const sourceHandleIsConnected = edges?.find(
      (e) => e.sourceHandle === `e${nodeData.id}-${element.id}`
    )

    return (
      <div key={element.id}>
        <div
          className={`flex-container align-middle align-justify ${isExport ? 'pb-1 pl-1-5 pr-1-5' : 'pb-1-5 pl-2 pr-2-5'}`}>
          <div className="flex-container align-middle">
            <span className="o-scene__elements--icon">{elRow.icon}</span>
            <h4 className="text-normal mb-0 ml-1">{renderLabel()}</h4>
          </div>
          <LinkElement
            sourceHandle={sourceHandle}
            isConnected={sourceHandleIsConnected || element.linkToEnding}
            linkToEnding={element.linkToEnding}
            parentIndex={parentIndex}
            childIndex={childIndex}
            element={element}
            isExport={isExport}
          />
        </div>
        {element.randomizedLinkToIds.map((sceneId, index) => (
          <RandomizerLinks
            key={`${sceneId}-${index}`}
            sceneId={sceneId ?? ''}
            index={index}
            allSourceHandles={allSourceHandles}
            nodeData={nodeData}
            element={element}
            parentIndex={parentIndex}
            childIndex={childIndex}
            isExport={isExport}
          />
        ))}
      </div>
    )
  }

  if (!parentElement.groupUuid) return renderCard(parentElement, parentIndex)

  const groupElRow = ELEMENT_TYPES.find(
    (el) => el.type === parentElement.groupKind
  )
  if (!groupElRow) return <></>

  return (
    <div>
      <header
        className={`flex-container align-middle text-stable-dark ${isExport ? 'pb-1 pl-1-5' : 'pb-1-5 pl-2'}`}>
        <span className="o-scene__elements--icon">{groupElRow.icon}</span>
        <h4 className="text-normal text-bold mb-0 ml-1">
          {capitalized(groupElRow.title)}
        </h4>
      </header>
      {renderCard(parentElement, parentIndex)}
      {answerElements.map((el, index) => renderCard(el, parentIndex, index))}
    </div>
  )
}

export default NodeElementCard
