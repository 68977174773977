import React, { useContext } from 'react'
import { BaseEdge, getBezierPath, Position } from 'reactflow'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import { getPointColor } from '../../../helpers/elementHelper'
import useSelectedEdges from '../../../hooks/useSelectedEdges'
import { isMultiSelection } from '../../../helpers/nodeHelper'

const DefaultEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data = { points: 0 },
}) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const selectedEdges = useSelectedEdges()

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Right,
    targetX,
    targetY,
    targetPosition: Position.Left,
  })

  const isSelected =
    !isMultiSelection() &&
    selectedEdges.find((e) => e.id === id) &&
    data.selectType !== 'hover'

  const style = {
    stroke: isSelected
      ? '#26272c'
      : getPointColor(data.points, scenario.scoringSystem.kind),
    strokeWidth: 2,
  }

  return <BaseEdge id={id} path={edgePath} style={style} />
}

export default DefaultEdge
