import React from 'react'

const TraineeUserName = ({ name, isCurrentUser, isAnonymous }) => {
  if (isAnonymous) return <span className="text-stable">Anonymous</span>
  if (!name || name === ' ')
    return (
      <span data-testid="unknown" className="text-stable">
        Unknown
      </span>
    )
  if (isCurrentUser) {
    return (
      <>
        <span data-testid="current-user" className="mr-2">
          {name}
        </span>
        <label className="o-label--custom o-label--stable-lighter text-small text-bold text-stable-dark">
          YOU
        </label>
      </>
    )
  }
  return name
}

export default TraineeUserName
