import React, { useRef, useContext } from 'react'
import { FaVolumeMute, FaVolumeDown, FaVolumeUp } from 'react-icons/fa'
import { MediumContext } from '../../MediumContext'

const VolumeSlider = () => {
  const volumeRange = useRef()
  const {
    controls: [controls, setControls],
    currentVideoRef,
  } = useContext(MediumContext)

  const getVolumeIcon = () => {
    if (controls.isMuted) return <FaVolumeMute data-testid="mute-icon" />
    if (controls.volume < 0.8) return <FaVolumeDown data-testid="down-icon" />
    return <FaVolumeUp data-testid="up-icon" />
  }

  const updateVideoRef = (volume) => {
    currentVideoRef.current.volume = volume
    currentVideoRef.current.muted = volume === 0
  }

  const saveHandler = () => {
    const newVolume = volumeRange.current.value
    if (newVolume !== controls.volume) {
      setControls({
        ...controls,
        isMuted: Number(newVolume) === 0,
        previousVolume: controls.volume,
        volume: newVolume,
      })
      updateVideoRef(newVolume)
    }
  }

  const toggleMuteHandler = () => {
    if (controls.isMuted) {
      setControls({
        ...controls,
        isMuted: false,
        volume: controls.previousVolume,
      })
      updateVideoRef(controls.previousVolume)
    } else {
      volumeRange.current.hidden = true
      volumeRange.current.classList.remove('is-visible')
      setControls({
        ...controls,
        isMuted: true,
        previousVolume: controls.volume,
        volume: 0,
      })
      updateVideoRef(0)
    }
  }

  return (
    <div id="volume-control" className="volume-slider-container">
      <button
        className="button mb-0 secondary hollow"
        id="volume"
        data-testid="volume-button"
        onDoubleClick={toggleMuteHandler}
        onClick={() => {
          const { hidden } = volumeRange.current
          volumeRange.current.hidden = !hidden
          if (volumeRange.current.hidden) {
            volumeRange.current.classList.remove('is-visible')
          } else {
            volumeRange.current.classList.add('is-visible')
          }
        }}>
        {getVolumeIcon()}
      </button>
      <label className="progress-slider volume-slider" id="volume-container">
        <input
          data-testid="volume-range"
          type="range"
          ref={volumeRange}
          min={0}
          max={1}
          step={0.05}
          hidden
          defaultValue={controls.volume || 0}
          onMouseUp={saveHandler}
        />
      </label>
    </div>
  )
}
export default VolumeSlider
