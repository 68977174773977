import React from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import { updateDeviceMutation } from '../../../apollo/query/devices'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'

const DeleteSharedDeviceModal = ({
  id,
  appendTo,
  ids,
  devices,
  clearSelectedItems,
  clearCurrentDeviceId,
}) => {
  const [updateDevice] = useMutation(updateDeviceMutation, {
    onError: handleApolloError,
  })
  const deleteSharedDevice = (deviceId) => {
    const deviceToDelete = devices.find((d) => d.id === deviceId)
    updateDevice({
      variables: {
        ...deviceToDelete,
        registration: 'UNREGISTERED',
      },
      onCompleted: () => {
        addSuccessAlert({ title: 'Deletion successful' })
      },
    })
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete ${
        ids.length === 1 ? 'this device' : 'these devices'
      }?`}
      subtitle="This action cannot be undone"
      useDefaultButtons
      textAssertive
      cancelHandler={clearCurrentDeviceId}
      submitButtonText="Yes, I'm sure"
      submitHandler={() => {
        ids.forEach((deviceId) => {
          deleteSharedDevice(deviceId)
        })
        clearSelectedItems()
        clearCurrentDeviceId()
      }}></Modal>
  )
}
export default DeleteSharedDeviceModal
