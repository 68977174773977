import { PropTypes } from 'prop-types'
import React, { useState } from 'react'
import { FiX } from 'react-icons/fi'

const Toast = ({
  count,
  itemTypeText,
  dismissable = true,
  closeHandler,
  isClosing,
  children,
}) => {
  const [fixed, setFixed] = useState(null)

  if (count === 0 && !isClosing) return null
  const itemCount = `${count} ${itemTypeText}`

  if (count && isClosing && !fixed) {
    setFixed(itemCount)
    setTimeout(() => {
      setFixed(null)
    }, 300)
  }
  return (
    <div className="o-toast-container">
      <div
        className={`o-toast ${dismissable ? 'cursor-pointer' : ''} ${
          isClosing ? 'dismiss' : ''
        }`}>
        <div
          className="o-toast--text"
          onClick={() => {
            if (!dismissable) return
            closeHandler()
          }}>
          <p>{fixed || itemCount} selected</p>
          {dismissable ? (
            <p>
              <FiX />
            </p>
          ) : null}
        </div>
        {children}
      </div>
    </div>
  )
}
export default Toast

Toast.propTypes = {
  count: PropTypes.number,
  itemTypeText: PropTypes.string.isRequired,
  dismissable: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func,
  isClosing: PropTypes.bool,
  children: PropTypes.any,
}
