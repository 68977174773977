import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { SceneEditorContext } from '../../../SceneEditorContext'
import { updateSceneMutation } from '../../../../../apollo/query/scenes'
import { handleApolloError } from '../../../../../utils/errors'

const HotspotSettings = () => {
  const [sceneState] = useContext(SceneEditorContext)

  const [updateScene] = useMutation(updateSceneMutation, {
    onError: handleApolloError,
  })
  return (
    <div className="c-video__editor--elements--settings">
      <h3>Hotspot settings</h3>
      <div className="cell grid-x c-video__editor--elements--settings--row">
        <div className="cell auto text-normal">Hide labels</div>
        <div className="cell shrink">
          <div className="o-switch">
            <input
              type="checkbox"
              name="hideHotspotLabels"
              id="hideHotspotLabels"
              checked={sceneState.hideHotspotLabelsVr}
              onChange={() => {
                updateScene({
                  variables: {
                    ...sceneState,
                    hideHotspotLabelsVr: !sceneState.hideHotspotLabelsVr,
                    hint: sceneState.hideHotspotLabelsVr
                      ? false
                      : sceneState.hint,
                  },
                })
              }}
              className="o-switch__input"
            />
            <label
              htmlFor="hideHotspotLabels"
              className="o-switch__paddle"></label>
          </div>
        </div>
      </div>
      {sceneState.hideHotspotLabelsVr && (
        <div className="cell grid-x c-video__editor--elements--settings--row">
          <div
            className={`cell text-normal ${
              sceneState.hint ? 'shrink' : 'auto'
            }`}>
            {'Hide complete hotspot'}&nbsp;
          </div>
          {sceneState.hint && (
            <>
              <div className="cell text-normal shrink">for</div>
              <div
                className="cell small shrink ml-1 mr-1"
                style={{ width: '50px' }}>
                <input
                  name="giveHintAfter"
                  id="giveHintAfter"
                  className="no-arrows m-0"
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    height: 'auto',
                    padding: '2px 10px',
                    lineHeight: '1',
                  }}
                  type="number"
                  min="1"
                  step="1"
                  max={sceneState.video.duration}
                  defaultValue={sceneState.giveHintAfter}
                  onChange={(e) => {
                    updateScene({
                      variables: {
                        ...sceneState,
                        giveHintAfter: Math.floor(e.target.value),
                      },
                    })
                  }}
                />
              </div>
              <div className="cell auto text-normal">seconds</div>
            </>
          )}
          <div className="cell shrink">
            <div className="o-switch">
              <input
                className="o-switch__input"
                type="checkbox"
                name="hideHotspotElements"
                id="hideHotspotElements"
                checked={sceneState.hint}
                onChange={() => {
                  updateScene({
                    variables: {
                      ...sceneState,
                      hint: !sceneState.hint,
                    },
                  })
                }}
              />{' '}
              <label
                htmlFor="hideHotspotElements"
                className="o-switch__paddle"></label>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HotspotSettings
