import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'

const Accordion = ({ id, headers, content }) => {
  const [activeTab, setActiveTab] = useState(null)

  const activeHandler = (index) => {
    if (activeTab === index) return setActiveTab(null)
    return setActiveTab(index)
  }

  const createHeader = (header, index) => {
    const { title, suffix } = header

    return (
      <div
        key={index}
        className="o-accordion--header"
        onClick={() => activeHandler(index)}>
        <span
          className={`o-accordion--prefix ${
            activeTab === index ? 'is-active' : ''
          }`}>
          <FaChevronDown />
        </span>
        <div className="o-accordion--title">{title}</div>
        <div className="o-accordion--suffix">{suffix}</div>
      </div>
    )
  }
  const createContent = (contentItem, index) => (
    <div
      id={`accordion-content-${index}`}
      className={`o-accordion--content ${
        activeTab === index ? 'is-active' : ''
      }`}>
      {contentItem}
    </div>
  )
  const createRows = () => {
    return headers.map((header, index) => (
      <div key={`accordion-row-${index}`} className="o-accordion--row">
        {createHeader(header, index)}
        {createContent(content[index], index)}
      </div>
    ))
  }
  return (
    <div id={`accordion-${id}`} className="o-accordion--container">
      {createRows()}
    </div>
  )
}
export default Accordion
