import React, { useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaDotCircle } from 'react-icons/fa'
import Dropdown from '../../../../UI/Menu/Dropdown'
import useSelectedNode from '../../../../FlowV2/hooks/useSelectedNode'
import useAddUpdateScene from '../../../../FlowV2/hooks/useAddUpdateScene'

const HotspotSettings = () => {
  const selectedNode = useSelectedNode()
  const { data: sceneState } = selectedNode
  const containerEl = document.querySelector('#sceneario-editor-sidebar')

  const { updateSceneSettings } = useAddUpdateScene()

  const updateSeconds = (e) => {
    const { timerSeconds } = e.target.dataset
    updateSceneSettings({
      ...sceneState,
      hotspotHintAfter: Math.floor(timerSeconds),
    })
    $(`#hotspot-sec-menu-${sceneState.id}`).foundation('close')
  }

  let seconds = [5, 10, 15, 20, 30, 40, 50].filter((s) =>
    sceneState.video?.duration ? s < sceneState.video.duration : s
  )

  const [isSticked, setIsSticked] = useState(false)
  const settingsRef = useRef(null)

  const handleScroll = () => {
    const containerRect = containerEl.getBoundingClientRect()
    const settingsRect = settingsRef.current.getBoundingClientRect()
    const headerElHeight = containerEl
      .querySelector(':scope > header')
      .getBoundingClientRect().height
    if (
      settingsRect.top - headerElHeight <= containerRect.top ||
      settingsRect.bottom > containerRect.bottom
    ) {
      setIsSticked(true)
    } else {
      setIsSticked(false)
    }
  }

  useEffect(() => {
    containerEl.addEventListener('scroll', handleScroll)
    return () => {
      containerEl.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      ref={settingsRef}
      className={`c-video__editor--hotspot--settings text-normal pr-3 pl-3 pb-2 pt-2 bg-light
        ${isSticked ? 'border-light-bottom ' : ''}`}>
      <div className="border-light border-radius">
        <h4 className="flex-container align-middle text-bold text-normal mb-0 pt-1-5 pr-2 pl-2 pb-1-5">
          <FaDotCircle />
          <span className="ml-1">Settings for all hotspots</span>
        </h4>
        <div>
          <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
            <div className="c-video__editor--elements--settings--row switch-settings">
              <div className="o-switch">
                <input
                  type="checkbox"
                  name="hideHotspotLabels"
                  id="hideHotspotLabels"
                  checked={sceneState.hideHotspots}
                  onChange={() => {
                    updateSceneSettings({
                      ...sceneState,
                      hideHotspots: !sceneState.hideHotspots,
                      hotspotHintEnabled: sceneState.hideHotspots
                        ? false
                        : !!sceneState.hotspotHintEnabled,
                    })
                  }}
                  className="o-switch__input"
                />
                <label
                  htmlFor="hideHotspotLabels"
                  className="o-switch__paddle"></label>
              </div>
              <span className="ml-1">Hide labels</span>
            </div>
          </div>

          {sceneState.hideHotspots && (
            <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
              <div className="c-video__editor--elements--settings--row switch-settings flex-container align-middle align-justify">
                <div className="flex-container align-middle">
                  <div className="o-switch">
                    <input
                      type="checkbox"
                      name="hideHotspotElements"
                      id="hideHotspotElements"
                      checked={sceneState.hotspotHintEnabled}
                      onChange={() => {
                        updateSceneSettings({
                          ...sceneState,
                          hotspotHintEnabled: !sceneState.hotspotHintEnabled,
                        })
                      }}
                      className="o-switch__input"
                    />
                    <label
                      htmlFor="hideHotspotElements"
                      className="o-switch__paddle"></label>
                  </div>
                  <span className="ml-1">Hide all hotspots</span>
                </div>
                {sceneState.hotspotHintEnabled && (
                  <div className="flex-container align-middle">
                    <span className="mr-0-5">Hide for</span>
                    <Dropdown
                      id={`hotspot-sec-menu-${sceneState.id}`}
                      alignment="right"
                      offset={{ left: '20px', top: '0px' }}
                      minWidth={200}
                      button={
                        <button
                          className="o-button o-button--square-small o-button--secondary
                        o-button--no-active o-button--no-hover  flex-container align-middle mb-0 pl-1 pr-1 w-auto"
                          data-toggle={`hotspot-sec-menu-${sceneState.id}`}>
                          <span className="text-bold">
                            {sceneState.hotspotHintAfter ?? 0} sec
                          </span>
                          <FaChevronDown className="ml-1 text-normal" />
                        </button>
                      }>
                      <>
                        <li className="o-dropdown__item o-dropdown__item--inactive text-small text-lighter text-stable-dark">
                          Hide for
                        </li>
                        {seconds.map((value) => (
                          <li
                            key={value}
                            data-timer-seconds={value}
                            className={`o-dropdown__list-item text-normal flex-container align-middle
                            ${value === sceneState.hotspotHintAfter ? 'text-assertive' : 'text-dark'}`}
                            onClick={updateSeconds}>
                            {value} seconds
                          </li>
                        ))}
                      </>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HotspotSettings
