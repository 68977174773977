import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import withApollo from '../../../hooks/withApollo'
import FlowDiagram from '../components/FlowEditor/FlowDiagram'
import SearchModal from '../components/FlowEditor/SearchModal'
import VideoEditor from '../../EditorV2/Video/VideoEditor'
import { Route, Routes } from 'react-router'
import useShowVideoEditor from '../hooks/useShowVideoEditor'

const FlowEditor = () => {
  const showVideoEditor = useShowVideoEditor()

  return (
    <div>
      <div id="flow-container">
        <div className="w-100" style={{ position: 'relative' }}>
          <FlowDiagram visible={!showVideoEditor} />
          <Routes>
            <Route path={`/scenes/:sceneId/video`} element={<VideoEditor />} />
          </Routes>
        </div>
        <Sidebar />
      </div>
      <SearchModal />
    </div>
  )
}

export default withApollo(FlowEditor)
