import React from 'react'
import {
  FaTimes,
  FaCheck,
  FaQuestion,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaTrash,
  FaCloudUploadAlt,
  FaEllipsisH,
  FaUndo,
} from 'react-icons/fa'
import { removeAlert } from '../../../utils/helpers/alerts'

const Alert = ({ id, icon, title, subtitle, type, dismissable, timeout }) => {
  const deleteNotification = () => removeAlert(id)

  if (timeout) {
    setTimeout(() => {
      deleteNotification()
    }, timeout)
  }

  const getIcon = () => {
    switch (icon) {
      case 'success':
        return <FaCheck />
      case 'undo':
        return <FaUndo />
      case 'thrash':
        return <FaTrash />
      case 'upload':
        return <FaCloudUploadAlt />
      case 'progress':
        return <FaEllipsisH />
      case 'warning':
        return <FaQuestion />
      case 'not_allowed':
        return <FaExclamationCircle />
      default:
        switch (type) {
          case 'success':
            return <FaCheck />
          case 'warning':
            return <FaExclamationTriangle />
          default:
            return null
        }
    }
  }

  return (
    <div className={`alert alert-${type} grid-container `}>
      <div className="grid-x grid-x-padding">
        <span className="alert-icon cell medium-shrink">{getIcon()}</span>
        <span className="cell medium-auto">
          <h3 className={`text-bold ${subtitle ? '' : 'mb-0'}`}>{title}</h3>
          {subtitle && <span className="alert-subtitle">{`${subtitle}`}</span>}
        </span>
        {dismissable ? (
          <span
            className="alert-close cursor-pointer"
            onClick={deleteNotification}>
            <FaTimes />
          </span>
        ) : null}
      </div>
    </div>
  )
}

export default Alert
