import { gql } from '@apollo/client'
import {
  SCENE_FIELDS,
  SCENARIO_FIELDS,
  SCENE_WITH_ELEMENTS_FIELDS,
  SCENARIO_DETAIL_FIELDS,
} from './fragments'
const getScenariosQuery = gql`
  ${SCENARIO_FIELDS}

  query Scenarios {
    scenarios {
      ...ScenarioFields
      lastAttemptAt
    }
  }
`

const getScenarioNameQuery = gql`
  query Scenarios($id: ID!) {
    scenarios(id: $id) {
      id
      name
      url
    }
  }
`

const getScenarioWithAnswersQuery = gql`
  ${SCENE_FIELDS}
  ${SCENARIO_FIELDS}
  query getScenarioWithAnswers($id: ID!) {
    scenarios(id: $id) {
      ...ScenarioFields
      scenes {
        ...SceneFields
      }
      client {
        logoVrUrl
      }
    }
  }
`

const getScenarioWithElementsQuery = gql`
  ${SCENARIO_DETAIL_FIELDS}
  ${SCENE_WITH_ELEMENTS_FIELDS}
  query Scenarios($id: ID!) {
    scenarios(id: $id) {
      ...ScenarioDetailFields
      scenes {
        ...SceneWithElementsFields
      }
      client {
        logoVrUrl
      }
    }
  }
`

const getDraftScenariosQuery = gql`
  query draftScenarios {
    scenarios {
      id
      name
    }
  }
`

const getPublishedScenariosQuery = gql`
  query publishedScenarios {
    publishedScenarios {
      id
      name
    }
  }
`

const getPurchasedScenariosQuery = gql`
  query purchasedScenarios {
    purchasedScenarios {
      id
      name
    }
  }
`

const addScenarioMutation = gql`
  ${SCENARIO_FIELDS}

  mutation addScenario(
    $name: String!
    $description: String!
    $blobId: String!
    $legacyFlow: Boolean
  ) {
    addScenario(
      name: $name
      description: $description
      blobId: $blobId
      legacyFlow: $legacyFlow
    ) {
      scenario {
        ...ScenarioFields
      }
    }
  }
`

const updateScenarioMutation = gql`
  ${SCENARIO_FIELDS}

  mutation updateScenario($id: ID!, $name: String!, $description: String!) {
    updateScenario(id: $id, name: $name, description: $description) {
      scenario {
        ...ScenarioFields
      }
    }
  }
`

const updateScenarioImageMutation = gql`
  ${SCENARIO_FIELDS}

  mutation updateScenario(
    $id: ID!
    $name: String!
    $description: String!
    $blobId: String!
  ) {
    updateScenario(
      id: $id
      name: $name
      description: $description
      blobId: $blobId
    ) {
      scenario {
        ...ScenarioFields
      }
    }
  }
`

const deleteScenarioMutation = gql`
  mutation removeScenario($id: ID!) {
    removeScenario(id: $id) {
      id
    }
  }
`

const duplicateScenarioMutation = gql`
  ${SCENARIO_FIELDS}

  mutation duplicateScenario($id: ID!, $name: String!, $clientId: ID) {
    duplicateScenario(id: $id, name: $name, clientId: $clientId) {
      scenario {
        ...ScenarioFields
      }
    }
  }
`

const unpublishScenarioMutation = gql`
  mutation unpublishScenario($id: ID!) {
    unpublishScenario(id: $id) {
      id
    }
  }
`

const unpublishTestScenarioMutation = gql`
  mutation unpublishTestScenario($id: ID!) {
    unpublishTestScenario(id: $id) {
      id
    }
  }
`

const publishTestScenarioMutation = gql`
  mutation publishTestScenario(
    $id: ID!
    $testerIds: [ID!]!
    $message: String!
  ) {
    publishTestScenario(id: $id, testerIds: $testerIds, message: $message) {
      testScenario {
        id
        name
        version
        status
        createdAt
        message
        testers {
          id
          name
          email
        }
      }
    }
  }
`

const updateScenarioTestersMutation = gql`
  mutation updateScenarioTesters($id: ID!, $testerIds: [ID!]!) {
    updateScenarioTesters(id: $id, testerIds: $testerIds) {
      testScenario {
        id
        name
        version
        status
        createdAt
        message
        testers {
          id
          name
          email
        }
      }
    }
  }
`

const migrateScenarioMutation = gql`
  mutation migrateScenario($id: ID!) {
    migrateScenario(id: $id) {
      success
    }
  }
`

const migrateAllScenariosMutation = gql`
  mutation migrateAllScenarios {
    migrateAllScenarios {
      success
    }
  }
`

const publishScenarioMutation = gql`
  mutation publishScenario($id: ID!, $courseIds: [ID!]!, $groupIds: [ID!]!) {
    publishScenario(id: $id, courseIds: $courseIds, groupIds: $groupIds) {
      publishedScenario {
        id
      }
    }
  }
`
const updateScenarioSettingsMutation = gql`
  mutation updateScenarioSettings(
    $id: ID!
    $hideNadir: Boolean!
    $voiceEnabled: Boolean!
    $locale: String!
  ) {
    updateScenarioSettings(
      id: $id
      hideNadir: $hideNadir
      voiceEnabled: $voiceEnabled
      locale: $locale
    ) {
      scenario {
        id
        hideNadir
      }
    }
  }
`
const updateScenarioPdfMutation = gql`
  mutation updateScenarioPdf($id: ID!, $name: String!, $blobId: String) {
    updateScenarioPdf(id: $id, name: $name, blobId: $blobId) {
      scenario {
        id
        hideNadir
        name
      }
    }
  }
`
const updateScenarioScoringSystemMutation = gql`
  mutation updateScenarioScoringSystem(
    $id: ID!
    $scoringSystem: ScoringSystemInput!
  ) {
    updateScenarioScoringSystem(id: $id, scoringSystem: $scoringSystem) {
      scenario {
        id
      }
    }
  }
`

const updateScenarioFilmingMutation = gql`
  mutation updateScenarioFilming($id: ID!, $filmingGrouping: FilmingGrouping!) {
    updateScenarioFilming(id: $id, filmingGrouping: $filmingGrouping) {
      scenario {
        id
        name
        filmingGrouping
      }
    }
  }
`

export {
  getScenariosQuery,
  getScenarioWithAnswersQuery,
  getScenarioWithElementsQuery,
  getDraftScenariosQuery,
  getPublishedScenariosQuery,
  updateScenarioImageMutation,
  addScenarioMutation,
  updateScenarioMutation,
  unpublishScenarioMutation,
  unpublishTestScenarioMutation,
  publishTestScenarioMutation,
  updateScenarioTestersMutation,
  duplicateScenarioMutation,
  deleteScenarioMutation,
  getPurchasedScenariosQuery,
  getScenarioNameQuery,
  migrateScenarioMutation,
  migrateAllScenariosMutation,
  publishScenarioMutation,
  updateScenarioSettingsMutation,
  updateScenarioPdfMutation,
  updateScenarioScoringSystemMutation,
  updateScenarioFilmingMutation,
}
