import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import cache from '../../../apollo/cache'
import {
  getCurrentClientBillingQuery,
  updateClientBillingMutation,
} from '../../../apollo/query/clients'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import BillingContactForm from './BillingContactForm'
import CompanyInfoForm from './CompanyInfoForm'

const BillingTab = () => {
  const { loading, data, error } = useQuery(getCurrentClientBillingQuery)
  const [billingValues, setBillingValues] = useState()

  const [updateBillingContact, { loading: updatingBillingContact }] =
    useMutation(updateClientBillingMutation, {
      onError: handleApolloError,
      onCompleted: () =>
        addSuccessAlert({
          title: 'Billing contact information has been changed',
        }),
      refetchQueries: [
        {
          query: getCurrentClientBillingQuery,
        },
      ],
    })

  const [updateCompanyInfo, { loading: updatingCompanyInfo }] = useMutation(
    updateClientBillingMutation,
    {
      onError: handleApolloError,
      onCompleted: () =>
        addSuccessAlert({ title: 'Company information has been changed' }),
      refetchQueries: [
        {
          query: getCurrentClientBillingQuery,
        },
      ],
    }
  )

  useEffect(() => {
    if (data) {
      const { currentClient } = data
      let billingValues = { ...currentClient }
      Object.keys(billingValues).forEach((key) => (billingValues[key] ??= ''))
      setBillingValues(billingValues)
    }
  }, [data])

  if (error) throw new Error(error.message)

  if (loading || !billingValues) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  const handleBillingContactSubmit = (values) => {
    updateBillingContact({ variables: { ...billingValues, ...values } })
  }

  const handleCompanyInfoSubmit = (values) => {
    updateCompanyInfo({ variables: { ...billingValues, ...values } })
  }

  return (
    <>
      <div className="row">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Payment method</h2>
          <p>
            Pay through a bank transfer. Pay within 30 days after receiving the
            invoice
          </p>
        </div>

        <div className="large-6 medium-8 small-12 columns">
          <div className="mb-2">
            <label className="mb-1">Payment method</label>
            <div className="flex-container text-normal align-justify align-middle">
              Bank transfer
            </div>
          </div>
        </div>
      </div>

      <div className="row border-top pt-3 mt-2">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Billing contact</h2>
          <p>
            The billing contact will be mentioned on the invoice and will
            receive all invoices by email.
          </p>
        </div>

        <div className="large-6 medium-8 small-12 columns">
          <BillingContactForm
            billingValues={billingValues}
            onSubmit={handleBillingContactSubmit}
            loading={updatingBillingContact}
          />
        </div>
      </div>

      <div className="row border-top pt-3 mt-2">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Company info</h2>
          <p>
            Find all required fields. Fill in your VAT number for VAT free
            invoices(only for EU countries). Fill in a reference(such as a PO
            number) in case you need one on the invoice.
          </p>
        </div>

        <div className="large-6 medium-8 small-12 columns">
          <CompanyInfoForm
            billingValues={billingValues}
            onSubmit={handleCompanyInfoSubmit}
            loading={updatingCompanyInfo}
          />
        </div>
      </div>
    </>
  )
}
export default BillingTab
