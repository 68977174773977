import React from 'react'
import { useMutation } from '@apollo/client'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import Modal from '../../../components/UI/Modal'
import { deleteIntegrationMutation } from '../../../apollo/query/integrations'

const DeleteIntegrationModal = ({ id, appendTo, integrationId, submitCallback, cancelCallback }) => {
  const [deleteIntegration] = useMutation(deleteIntegrationMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({
        icon: 'success',
        title: `Integration successfully deleted`,
      })
      submitCallback()
    },
  })

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={cancelCallback}
      submitHandler={() =>
        deleteIntegration({ variables: { id: integrationId } })
      }
    />
  )
}
export default DeleteIntegrationModal
