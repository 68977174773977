import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import { deleteGroupMutation } from '../../../apollo/query/groups'
import { addSuccessAlert, removeAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import useThrottle from '../../../hooks/useThrottle'

const DeleteGroupModal = ({
  id,
  appendTo,
  ids,
  callback,
  clearSelectedItems,
}) => {
  const count = useRef(0)
  const [single, setSingle] = useState(null)
  const [notificationId, setNotificationId] = useState(null)
  const [createQueue, createTask] = useThrottle()

  const showAlert = () => {
    removeAlert(notificationId)
    const alertId = addSuccessAlert({
      icon: single ? 'success' : 'progress',
      title: single ? `Group successfully deleted` : `Deleting groups`,
      subtitle: single
        ? ''
        : `Progress: ${((count.current / ids.length) * 100).toFixed(0)}%`,
    })
    setNotificationId(alertId)
  }
  const [deleteGroup] = useMutation(deleteGroupMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      count.current += 1
      showAlert()
      if (count.current === ids.length) {
        if (!single) {
          addSuccessAlert({
            title: 'Deleted all groups',
          })
        }
        clearSelectedItems()
        callback()
        // reset count after callback
        count.current = 0
      }
    },
  })

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete ${
        ids.length === 1 ? 'this group' : 'these groups'
      }?`}
      subtitle="This action cannot be undone. Scenarios and courses will not be deleted."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={callback}
      submitHandler={() => {
        if (ids.length === 1) {
          setSingle(true)
          deleteGroup({ variables: { id: ids[0] } })
        } else {
          createQueue(
            ids.map((i) =>
              createTask(() => deleteGroup({ variables: { id: i } }))
            )
          )
        }
      }}
    />
  )
}
export default DeleteGroupModal
