import React, { useState } from 'react'
import DataTooltip from '../../UI/DataTooltip'

const GroupCourseScenarioTooltip = ({ count, names, type }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (count <= 0) return '-'

  const formatName = (value) => `<li style="text-align:start;">${value}</li>`
  if (isHovered) {
    const title = `
    <div style="padding:10px;">
      <span className="text-white" style="text-align:start;font-weight:700;">Access to ${count} ${type}${
      count === 1 ? '' : 's'
    }</span>
      <ul style="margin-right:10px; margin-bottom:0; margin-top:10px;">
      ${names
        .sort((a, b) => a.localeCompare(b))
        .map((name) => formatName(name))
        .join('')}
      </ul>
      </div>
    `

    return (
      <DataTooltip title={title}>
        <span className="text-underline cursor-default">
          {count} {type}
          {count === 1 ? '' : 's'}
        </span>
      </DataTooltip>
    )
  }

  return (
    <span
      className="text-underline cursor-default"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {count} {type}
      {count === 1 ? '' : 's'}
    </span>
  )
}

export default GroupCourseScenarioTooltip
