import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { FaCopy, FaCheck } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../UI/Modal'
import { updateGroupMutation } from '../../../apollo/query/groups'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import useCurrentUser from '../../../hooks/useCurrentUser'

const LinkSettingsModal = ({ id, appendTo, group, callback }) => {
  const formRef = useRef()
  const domainRef = useRef()
  const domainsList = useRef([])
  const [key, setKey] = useState(uuidv4())
  const [isCopied, setIsCopied] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })

  const [, currentClient] = useCurrentUser()

  const [updateGroup] = useMutation(updateGroupMutation, {
    onError: handleApolloError,
  })

  useEffect(() => {
    if (group) {
      setKey(uuidv4())
      domainsList.current = group.publicLinkDomains || []
    }
  }, [group])

  if (!group) return <Modal id={id} appendTo={appendTo} />

  const clientHaveAnonymousPublicLink = currentClient.features.includes(
    'ANONYMOUS_PUBLIC_LINK'
  )

  const publicLinkHandler = (checked, publicLinkSecurityType) => {
    if (!group) return null

    const newPublicLinkSecurityType =
      publicLinkSecurityType ??
      group.publicLinkSecurityType ??
      (clientHaveAnonymousPublicLink ? 'ANONYMOUS' : 'EMAIL')

    return updateGroup({
      variables: {
        id: group.id,
        name: group.name,
        publishedScenarioIds: group.publishedScenarios.map(({ id: i }) =>
          Number(i)
        ),
        courseIds: group.courses.map(({ id: i }) => Number(i)),
        publicLinkSecurityType: newPublicLinkSecurityType,
        publicLinkEnabled: checked,
        publicLinkDomains: domainsList.current,
        startDate: group.startDate,
        endDate: group.endDate,
      },
      onCompleted: () => {
        addSuccessAlert({
          title: 'Saved',
          subtitle: 'The public link settings have been saved',
        })
      },
    })
  }

  const link = (
    <a
      href="	https://help.warpvr.com/users-and-groups/public-registration-link"
      target="_blank"
      rel="noreferrer"
      className="text-stable-dark text-underline">
      More info
    </a>
  )

  const showCopyPublicLink = () => {
    let buttonType = 'secondary'
    let buttonContent = (
      <>
        <FaCopy className="mr-1" /> Copy
      </>
    )
    if (isCopied) {
      buttonType = 'success'
      buttonContent = (
        <>
          <FaCheck className="mr-1 text-success" /> Copied!
        </>
      )
    }
    return (
      <div className="grid-x input-group">
        <input
          className="cell auto mr-0 input-group-field text-stable-dark"
          type="text"
          name="url"
          id="publicLinkUrl"
          disabled
          defaultValue={group.publicLinkUrl}
          onClick={() => {
            navigator.clipboard.writeText(group.publicLinkUrl)
          }}
        />{' '}
        <button
          className={`cell shrink mb-0 mr-0 button input-group-button hollow ${buttonType}`}
          onClick={(e) => {
            e.preventDefault()
            navigator.clipboard.writeText(group.publicLinkUrl)
            setIsCopied(true)
          }}>
          <span
            style={{
              height: '100%',
              display: 'flex',
              justifyItems: 'center',
            }}>
            {buttonContent}
          </span>
        </button>
      </div>
    )
  }

  const handleDomains = () => {
    let shouldSave =
      domainRef.current.value !== group.publicLinkDomains.join(', ')

    setError({ ...error, show: false })
    const domains = domainRef.current.value.split(',').reduce((a, domain) => {
      let trimmed = domain.trim()
      if (trimmed[0] === '@') trimmed = trimmed.substr(1)
      a.push(trimmed)
      return a
    }, [])

    // validation
    domains.forEach((domain) => {
      if (domain.includes('@') || !domain.includes('.')) {
        setError({
          show: true,
          message: `Invalid domain ${domain}`,
        })
        shouldSave = false
      }
      if (!domain) {
        setError({
          show: true,
          message: 'A domain is required',
        })
        shouldSave = false
      }
    })

    domainsList.current = domains.map((a) => a.trim())

    if (shouldSave) publicLinkHandler(true)
  }

  const updatePublicLinkSecurity = () => {
    const { elements } = formRef.current
    if (elements['check-domain']?.checked && elements['check-email']?.checked)
      return publicLinkHandler(true, 'DOMAIN')

    if (elements['check-email']?.checked)
      return publicLinkHandler(true, 'EMAIL')

    if (clientHaveAnonymousPublicLink)
      return publicLinkHandler(true, 'ANONYMOUS')

    return publicLinkHandler(true, 'EMAIL')
  }

  const showLimitAccess = () => {
    return (
      <>
        <label>Access settings</label>

        <label
          htmlFor="check-email"
          className={'o-checkbox'}
          hidden={!clientHaveAnonymousPublicLink}>
          Users needs to register with their email address to get access
          <input
            defaultChecked={
              !group.publicLinkSecurityType ||
              ['EMAIL', 'DOMAIN'].includes(group.publicLinkSecurityType) ||
              !clientHaveAnonymousPublicLink
            }
            type="checkbox"
            name="check-email"
            id="check-email"
            onChange={updatePublicLinkSecurity}
          />
          <span></span>
        </label>

        {(!clientHaveAnonymousPublicLink ||
          ['EMAIL', 'DOMAIN'].includes(group.publicLinkSecurityType)) && (
          <label htmlFor="check-domain" className="o-checkbox">
            Limit access to a specific email domain
            <input
              defaultChecked={group.publicLinkSecurityType === 'DOMAIN'}
              onChange={updatePublicLinkSecurity}
              type="checkbox"
              name="check-domain"
              id="check-domain"
            />
            <span></span>
          </label>
        )}

        {group.publicLinkSecurityType === 'DOMAIN' && (
          <div className="mt-2">
            <label htmlFor="email-domains">Email domains</label>
            <div className="input-group mb-1">
              <span className="text-bold input-group-label o-input-group-label">
                @
              </span>
              <input
                ref={domainRef}
                autoComplete="off"
                spellCheck="false"
                className="input-group-field text-stable-dark"
                type="text"
                placeholder="domain1.com, *-domain2.com"
                defaultValue={
                  domainsList?.current?.join(', ') ||
                  group.publicLinkDomains.join(', ')
                }
                name="email-domains"
                id=""
                onBlur={handleDomains}
              />
            </div>
            {error.show && (
              <p className="text-assertive text-bold">{error.message}</p>
            )}
            <span className="text-normal text-stable-dark mb-1">{`Don't use the @. Separate multiple domains with a comma.`}</span>
          </div>
        )}
      </>
    )
  }
  const submit = () => {
    setTimeout(() => {
      setIsCopied(false)
    }, 300)
    setKey(uuidv4())
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      closable={false}
      buttons={
        <button
          onClick={submit}
          data-close={id}
          className="button wide primary">
          Close
        </button>
      }
      submitHandler={submit}
      headerText="Public share settings"
      subtitle={
        <span>Create a public link to easily share this group. {link}</span>
      }
      cancelHandler={() => {
        setTimeout(() => {
          setIsCopied(false)
        }, 300)

        setKey(uuidv4())
        callback()
      }}>
      <form key={key} className="mt-3" ref={formRef}>
        <div className="grid-x input-group" style={{ alignItems: 'center' }}>
          <div className="small-12">
            <p className="text-normal">
              When enabled, everyone with this link can view general course and
              scenario information and can get playback access.
            </p>
          </div>
          <div className="cell small-10">
            <label htmlFor="publicLinkEnabled" className="mb-0">
              Registration web link
            </label>
          </div>
          <div
            className="cell small-2 input-group"
            style={{ justifyContent: 'flex-end' }}>
            <div className="o-switch">
              <input
                type="checkbox"
                name="enablePublicLink"
                id="publicLinkEnabled"
                className="o-switch__input"
                defaultChecked={group.publicLinkEnabled}
                onChange={({ target }) => {
                  publicLinkHandler(target.checked)
                }}
              />
              <label htmlFor="publicLinkEnabled" className="o-switch__paddle">
                <span />
              </label>
            </div>
          </div>
        </div>
        {group.publicLinkEnabled && group.publicLinkUrl && showCopyPublicLink()}
        {group.publicLinkEnabled && showLimitAccess()}
      </form>
    </Modal>
  )
}

export default LinkSettingsModal
