import React, { useState } from 'react'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from 'react-icons/fa'

const Social = () => {
  const [hover, setHover] = useState(null)
  const socialMedia = [
    {
      kind: 'LinkedIn',
      icon: <FaLinkedinIn />,
      url: 'https://www.linkedin.com/company/warpvr',
      hoverColor: '#0e76a8',
    },
    {
      kind: 'Facebook',
      icon: <FaFacebookF />,
      url: 'https://www.facebook.com/warpindustries/',
      hoverColor: '#3b5998',
    },
    {
      kind: 'Instagram',
      icon: <FaInstagram />,
      url: 'https://www.instagram.com/warp_vr',
      hoverColor: '#C13584',
    },
    {
      kind: 'Youtube',
      icon: <FaYoutube />,
      url: 'https://www.youtube.com/channel/UCpXmC1NyDYnmDmWpdaVGs8w',
      hoverColor: '#FF0000',
    },
  ]

  const createSocialButtons = () => {
    return socialMedia.map((item, i) => (
      <div
        key={i}
        className="c-launcher__footer--social"
        onMouseOver={() => setHover(item.kind)}
        onMouseOut={() => setHover(null)}
        onClick={() => window.open(item.url)}
        style={hover === item.kind ? { color: item.hoverColor } : {}}>
        {item.icon}
      </div>
    ))
  }
  return <div id="socialmedia-buttons">{createSocialButtons()}</div>
}
export default Social
