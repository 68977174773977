import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import omitDeep from 'omit-deep'
import {
  updateUserPreferencesMutation,
  userPreferencesQuery,
} from '../apollo/query/user'

const usePreferences = (type, defaultValue = 'createdAt', skip = false) => {
  const DEFAULT_SETTINGS = {
    showTable: false,
    filteredColumns: [],
    sortOn: {
      isAscending: true,
      value: defaultValue,
    },
  }

  const [preferences, setLocalPreferences] = useState(null)

  useQuery(userPreferencesQuery, {
    skip,
    onCompleted: (data) => {
      if (data.currentUser.preferences[type]) {
        setLocalPreferences(data.currentUser.preferences[type])
      } else {
        setLocalPreferences(DEFAULT_SETTINGS)
      }
    },
  })

  const [saveUserPreferences] = useMutation(updateUserPreferencesMutation, {
    variables: {
      [type]: omitDeep(JSON.parse(JSON.stringify(preferences)), '__typename'),
    },
  })

  useEffect(() => {
    if (preferences) {
      saveUserPreferences()
    }
  }, [preferences])

  const setPreferences = (p) => {
    setLocalPreferences(p)
  }

  return [preferences, setPreferences]
}
export default usePreferences
