import React, { useContext, useRef } from 'react'
import { useReactFlow } from 'reactflow'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import Dropdown from '../../../UI/Menu/Dropdown'
import { useLazyQuery } from '@apollo/client'
import {
  FaClone,
  FaEllipsisH,
  FaHome,
  FaLink,
  FaTrash,
  FaUnlink,
} from 'react-icons/fa'
import DeleteSceneModal from './DeleteSceneModal'
import MediaModal from '../../../Media/Modal/MediaModal'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import useAddUpdateScene from '../../hooks/useAddUpdateScene'
import { getMediaByScenarioIdQuery } from '../../../../apollo/query/media'
import useDuplicateNodes from '../../hooks/useDuplicateNodes'

const SidebarDropdown = ({ node }) => {
  const reactFlow = useReactFlow()
  const { deleteElements, setNodes } = reactFlow
  const nodes = reactFlow.getNodes()
  const { updateUnlinkMediumToScene } = useAddUpdateScene()
  const {
    scenario: { id: scenarioId },
  } = useContext(ScenarioEditorContext)
  const selectedMediaId = useRef(null)

  const duplicateNodes = useDuplicateNodes()
  const { updateSceneDetails } = useAddUpdateScene()

  const [getMediaByScenarioId] = useLazyQuery(getMediaByScenarioIdQuery, {
    fetchPolicy: 'network-only',
    variables: {
      scenarioId: scenarioId,
    },
  })

  const handleChangeStartScene = () => {
    const homeNode = nodes.find((n) => n.data.start)
    updateSceneDetails({
      id: node.data.id,
      start: true,
    }).then(() => {
      setNodes([
        ...nodes.filter((n) => n.id !== node.id || n.id !== homeNode?.id),
        { ...homeNode, data: { ...homeNode.data, start: false } },
        { ...node, data: { ...node.data, start: true } },
      ])
    })
  }

  const handleDuplicateScene = () => {
    duplicateNodes([node])
  }

  const handleDeleteScene = () => {
    deleteElements({
      nodes: [{ id: node.id }],
    })
  }

  const handleUnlink = async () => {
    let mediumId = selectedMediaId.current
    if (!mediumId) {
      const {
        data: { media: selectedMedia },
      } = await getMediaByScenarioId()
      mediumId = selectedMedia.find((m) =>
        m.linkedToScenes.find((s) => s.id === node.id)
      )?.id
    }

    updateUnlinkMediumToScene(mediumId)
    selectedMediaId.current = null
  }

  const onLinkClickCallback = (mediumId) => {
    selectedMediaId.current = mediumId
  }

  return (
    <>
      <Dropdown
        id={`sidebar-menu`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        minWidth={260}
        button={
          <ButtonGroup.Button
            className="pt-1-5 pb-1-5 o-button--light"
            data-toggle="sidebar-menu">
            <FaEllipsisH className="u-color-stable-dark" />
          </ButtonGroup.Button>
        }>
        <>
          <li
            className={`o-dropdown__list-item text-normal flex-container align-middle ${
              node.data.start
                ? 'o-dropdown__item--inactive text-stable'
                : 'text-dark'
            }`}
            onClick={handleChangeStartScene}>
            <FaHome className="mr-1" />
            Make start scene
          </li>

          <li
            className="o-dropdown__list-item text-normal flex-container align-middle text-dark"
            onClick={handleDuplicateScene}>
            <FaClone className="mr-1" />
            Duplicate scene
          </li>

          <li className="o-dropdown__divider" />

          <li
            className="o-dropdown__list-item text-normal flex-container align-middle text-dark"
            data-open="media-modal">
            <FaLink className="mr-1" />
            {node.data.hasVideo ? 'Change media file' : 'Link media file'}
          </li>

          {node.data.hasVideo && (
            <li
              className="o-dropdown__list-item text-normal flex-container align-middle text-dark"
              onClick={handleUnlink}>
              <FaUnlink className="mr-1" />
              Unlink media file
            </li>
          )}

          <li className="o-dropdown__divider" />

          <li
            data-open="delete-scene-modal"
            className={`o-dropdown__list-item text-normal flex-container align-middle ${
              node.data.start
                ? 'o-dropdown__item--inactive text-stable'
                : 'text-dark'
            }`}>
            <FaTrash className="mr-1 text-assertive" />
            Delete scene
          </li>
        </>
      </Dropdown>

      <MediaModal
        id="media-modal"
        appendTo="#sceneario-editor-sidebar"
        isLink={true}
        scenarioId={scenarioId}
        sceneId={node.id}
        selectedId={node.data.video?.id}
        onLinkClickCallback={onLinkClickCallback}
      />

      <DeleteSceneModal
        id="delete-scene-modal"
        appendTo="#sceneario-editor-sidebar"
        submitCallback={handleDeleteScene}
        sceneId={node.id}
      />
    </>
  )
}

export default SidebarDropdown
