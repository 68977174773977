import React from 'react'

const EmptyParagraph = () => {
  return (
    <p className="text-center text-italic text-stable-dark mb-5 mt-5">
      There are not enough training attempts to show this list
    </p>
  )
}

export default EmptyParagraph
