import React from 'react'
import { FaKey } from 'react-icons/fa'

const CoursesUpgradeNeededPage = () => {
  return (
    <div
      data-testid="empty-group-wrapper"
      className="o-empty-state"
      style={{ maxWidth: '500px' }}>
      <div className="flex-container align-center">
        <span className="text-center">
          <span className="o-table__empty--icon">
            <div className="flex-container align-center m-2">
              <FaKey size="150" />
            </div>
          </span>
          <h3 className="text-bold text-dark">
            Upgrade your plan to get courses
          </h3>
          <p className="text-stable-dark">
            Go to the subscription page and upgrade your plan to get access to
            courses
          </p>
        </span>
      </div>
    </div>
  )
}

export default CoursesUpgradeNeededPage
