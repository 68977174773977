import React from 'react'

const OcnFields = ({ values, handleChange }) => (
  <>
    <p>Provide the following information for your OCN service</p>

    <label htmlFor="ocnClientId">Client ID</label>
    <input
      type="text"
      name="ocnClientId"
      id="ocnClientId"
      onChange={handleChange}
      defaultValue={values.ocnClientId}
    />
    <label htmlFor="ocnClientSecret">Client Secret</label>
    <input
      type="text"
      name="ocnClientSecret"
      id="ocnClientSecret"
      onChange={handleChange}
      defaultValue={values.ocnClientSecret}
    />
    <label htmlFor="ocnTokenEndpoint">Token endpoint</label>
    <input
      type="text"
      name="ocnTokenEndpoint"
      id="ocnTokenEndpoint"
      onChange={handleChange}
      defaultValue={values.ocnTokenEndpoint}
    />
    <label htmlFor="ocnServiceEndpoint">Service endpoint</label>
    <input
      type="text"
      name="ocnServiceEndpoint"
      id="ocnServiceEndpoint"
      onChange={handleChange}
      defaultValue={values.ocnServiceEndpoint}
    />
    <label htmlFor="ocnProviderId">Provider ID</label>
    <input
      type="text"
      name="ocnProviderId"
      id="ocnProviderId"
      onChange={handleChange}
      defaultValue={values.ocnProviderId}
    />
    <label htmlFor="ocnLaunchUrl">Launch URL</label>
    <input
      type="text"
      name="ocnLaunchUrl"
      id="ocnLaunchUrl"
      onChange={handleChange}
      defaultValue={values.ocnLaunchUrl}
    />
  </>
)
export default OcnFields
