import React from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { default as S } from '../../../utils/lang/en.json'
import Filter from '../../UI/Menu/Filter'

const SearchBar = ({ filterOption, filterHandler, filterData }) => {
  const { roles } = S.dataTypes.user
  const getRole = (f) => Object.keys(roles).find((key) => roles[key] === f)

  return (
    <>
      <span className="cell shrink mr-1 large-order-2">
        <Filter
          text="Role"
          icon={<FaChevronDown />}
          selected={roles[filterOption.role] || 'All'}
          filterHandler={(f) => filterHandler(getRole(f), 'role')}
          filterOptions={['All', ...Object.values(roles)]}
        />
      </span>
      <span className="cell shrink large-order-2 mr-1">
        <Filter
          text="Groups"
          icon={<FaChevronDown />}
          selected={filterOption.group || 'All'}
          filterHandler={(f) => filterHandler(f, 'group')}
          filterOptions={['All', ...filterData.group.map(({ name }) => name)]}
          searchable
          searchPlaceholder="Search by group name..."
        />
      </span>
      {filterData.lms.length ? (
        <span className="cell shrink large-order-2 mr-1">
          <Filter
            text="LMS"
            icon={<FaChevronDown />}
            selected={filterOption.lms || 'All'}
            filterHandler={(f) => filterHandler(f, 'lms')}
            filterOptions={['All', ...filterData.lms.map(({ name }) => name)]}
          />
        </span>
      ) : null}
    </>
  )
}

export default SearchBar
