import React from 'react'
import useSelectedNode from '../../hooks/useSelectedNode'

const Nadir = () => {
  const { data: sceneState } = useSelectedNode()

  const getRotation = () => {
    if (sceneState.northOffsetEnabled)
      return `-90 -${Number(sceneState.northOffset)} 0`
    return '-90 0 0'
  }
  return (
    <a-entity position="0 -5.2 0">
      <a-circle segments="180" src="#client-vr-logo" rotation={getRotation()} />
    </a-entity>
  )
}
export default Nadir
