import { PropTypes } from 'prop-types'
import React from 'react'

const TableHeader = ({ title, length, leftBar, rightBar }) => (
  <span className="grid-x o-table__title pb-2 mb-0">
    <span className="cell small-12 medium-shrink mr-3 mb-1">
      <h2 className="cell medium-shrink mb-0 mr-1 pt-1">
        {length} {length === 1 || length === undefined ? title : `${title}s`}
      </h2>
    </span>
    <span className="cell small-12 medium-auto grid-x mb-1">{leftBar}</span>
    <span className="cell small-12 medium-shrink grid-x mb-1">{rightBar}</span>
  </span>
)
export default TableHeader

TableHeader.propTypes = {
  titleBar: PropTypes.any,
  leftBar: PropTypes.any,
  rightBar: PropTypes.any,
}
