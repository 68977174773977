import React from 'react'
import { getRotation } from '../../../Editor/helpers/controls'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import config from '../../../FlowV2/config/config'
import useSelectedNode from '../../../FlowV2/hooks/useSelectedNode'

const HOTSPOT_SIZES = {
  SMALL: 0.23,
  MEDIUM: 0.43,
  LARGE: 0.63,
}
const OPACITY = {
  ring: 1,
  circle: 0.6,
}

const Hotspot = ({ id, element, state }) => {
  const [, currentClient] = useCurrentUser()
  const { data: scene } = useSelectedNode()
  if (
    !scene ||
    !element ||
    !currentClient ||
    element.anchorX === null ||
    element.anchorY === null
  )
    return null

  element.hotspotSize = element.hotspotSize || config.defaultHotspotSize

  const rotation = getRotation({
    x: element.anchorX,
    y: element.anchorY,
  })

  let labelWidth = 1.8
  if (element.labelSize === 'WIDE') labelWidth *= 2
  const collisionWidth = labelWidth + 0.5

  return (
    <a-entity
      rotation={`${rotation} 0`}
      hideable-element={`visible: ${state.showHotspots}`}>
      <a-collision-box
        id={id}
        width={collisionWidth}
        scroll-header-offset={260}>
        <a-entity>
          {/* Hotspot Label */}
          {!scene.hideHotspots && (
            <a-entity
              position={`0 ${HOTSPOT_SIZES[element.hotspotSize] + 0.1} 0`}>
              <TextBubble
                textValue={element.label || 'Hotspot'}
                id={`H${id}`}
                type="hotspot"
                width={labelWidth}
              />
            </a-entity>
          )}
          {/* Hotspot Ring */}
          <a-entity
            geometry={`primitive: ring; radiusInner: ${
              HOTSPOT_SIZES[element.hotspotSize] - 0.03
            }; radiusOuter: ${HOTSPOT_SIZES[element.hotspotSize]};`}
            material={`color: ${currentClient.primaryColor}; shader: flat;
              opacity: ${OPACITY.ring};`}
          />
          {/* Hotspot Circle */}
          <a-circle
            opacity={OPACITY.circle}
            radius={HOTSPOT_SIZES[element.hotspotSize]}
            material={`
              color: ${currentClient.primaryColor};
              shader: flat;
              `}></a-circle>
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default Hotspot
