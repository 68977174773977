import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'
import DataTooltip from '../UI/DataTooltip'

const OptionsDropdown = ({
  id,
  currentScenario,
  setCurrentScenarios,
  getClients,
  canViewAttempts,
  migrateFlowHandler,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { visit } = window.Turbolinks

  if (isHovered) {
    const published = currentScenario.statuses.includes('PUBLISHED')
    const testing = currentScenario.statuses.includes('TESTING')
    const legacyFlow = currentScenario.legacyFlow

    return (
      <Dropdown
        id={`scenario-options-dropdown-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-open={`scenario-options-dropdown-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => {
              visit(`scenarios/${id}`)
            }}>
            Open flow editor
          </li>
          {legacyFlow && migrateFlowHandler && (
            <li
              className="o-dropdown__list-item text-normal text-dark"
              onClick={() => {
                migrateFlowHandler()
                setCurrentScenarios([currentScenario])
              }}>
              Update to new Flow
            </li>
          )}
          <hr className="mb-0-5 mt-0-5"></hr>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => {
              setCurrentScenarios([currentScenario])
              $('#editScenarioModal').foundation('open')
            }}>
            Edit scenario
          </li>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => {
              setCurrentScenarios([currentScenario])
              $('#duplicateScenarioModal').foundation('open')
              getClients()
            }}>
            Duplicate scenario
          </li>
          <hr className="mb-0-5 mt-0-5"></hr>
          {canViewAttempts && (
            <li
              className="o-dropdown__list-item text-normal text-dark"
              onClick={() => visit(`activity?tab=attempts&scenarioIds=${id}`)}>
              View training attempts
            </li>
          )}
          {canViewAttempts && <hr className="mb-0-5 mt-0-5"></hr>}
          {published && (
            <li
              className="o-dropdown__list-item text-normal text-dark"
              onClick={() => {
                setCurrentScenarios([currentScenario])
                $('#unpublishScenario').foundation('open')
              }}>
              Unpublish
            </li>
          )}
          {testing && (
            <li
              className="o-dropdown__list-item text-normal text-dark"
              onClick={() => {
                setCurrentScenarios([currentScenario])
                $('#unpublishTestScenario').foundation('open')
              }}>
              Stop testing
            </li>
          )}
          <li
            className={`${
              published ? 'disabled text-stable-dark' : 'text-dark'
            } o-dropdown__list-item text-normal text-dark`}
            onClick={() => {
              if (!published) {
                setCurrentScenarios([currentScenario])
                $('#deleteModal').foundation('open')
              }
            }}>
            Delete scenario
            {published ? (
              <small className="float-right">
                <DataTooltip
                  position="right"
                  title="Published scenarios cannot be deleted.">
                  <label className="o-label--item__disabled">UNAVAILABLE</label>
                </DataTooltip>
              </small>
            ) : (
              ''
            )}
          </li>
        </>
      </Dropdown>
    )
  }
  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`scenario-options-dropdown-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}
export default OptionsDropdown
