import { useContext } from 'react'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'
import { useEditorActiveTab } from '../helpers/editorHelper'

const useDocumentTitle = () => {
  const { scenario } = useContext(ScenarioEditorContext)
  const activeTab = useEditorActiveTab()

  return (placeholder) => {
    document.title = `${scenario.name} | ${activeTab.title}${placeholder ? ` | ${placeholder} ` : ' '}| Warp Studio`
  }
}
export default useDocumentTitle
