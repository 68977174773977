import { gql } from '@apollo/client'

const getCredentialsQuery = gql`
  query credentials {
    credentials {
      id
      nickname
      externalId
      createdAt
    }
  }
`

const registerCredentialMutation = gql`
  mutation registerCredential {
    registerCredential {
      options
    }
  }
`

const confirmRegisterCredentialMutation = gql`
  mutation confirmRegisterCredential(
    $nickname: String!
    $publicKeyCredential: String!
  ) {
    confirmRegisterCredential(
      nickname: $nickname
      publicKeyCredential: $publicKeyCredential
    ) {
      credential {
        id
        externalId
        nickname
        createdAt
      }
    }
  }
`

const updateCredentialMutation = gql`
  mutation updateCredential($id: ID!, $nickname: String!) {
    updateCredential(id: $id, nickname: $nickname) {
      credential {
        id
        nickname
      }
    }
  }
`

const removeCredentialMutation = gql`
  mutation removeCredential($id: ID!) {
    removeCredential(id: $id) {
      id
    }
  }
`

export {
  getCredentialsQuery,
  registerCredentialMutation,
  confirmRegisterCredentialMutation,
  updateCredentialMutation,
  removeCredentialMutation,
}
