import React, { createContext, useState } from 'react'

const VideoContext = createContext([
  {},
  () => {
    // this function is intentionally left empty
  },
])

const VideoProvider = (props) => {
  const [state, setState] = useState({})
  return (
    <VideoContext.Provider value={[state, setState]}>
      {props.children}
    </VideoContext.Provider>
  )
}

export { VideoContext, VideoProvider }
