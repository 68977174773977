import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'

const HistoryFeatureFlagError = () => (
  <div
    className="border-energized p-2 flex-container align-middle text-normal border-radius"
    style={{ maxWidth: '680px' }}>
    <FaInfoCircle className="mr-1 text-energized text-xxlarge" />
    <span>
      History is not part of your subscription. Contact us at{' '}
      <a href="mailto:support@warpvr.com" className="text-underline">
        support@warpvr.com
      </a>{' '}
      to upgrade.
    </span>
  </div>
)

export default HistoryFeatureFlagError
