import React, { useState } from 'react'
import Modal from '../../../components/UI/Modal'
import ColorPicker from '../../../components/UI/ColorPicker/ColorPicker'
import { isBrightColor } from '../../../utils/helpers/color'

const ColorCard = ({ title, onSubmit, id, defaultColor = '#EEEEEE' }) => {
  const [color, setColor] = useState(defaultColor)

  const handleSubmit = () => onSubmit(color)
  const handleColorChange = (value) => setColor(value)
  const handleCancel = () => setColor(defaultColor)

  return (
    <>
      <div className="pr-2 pl-1 mb-1">
        <div style={{ width: '180px' }}>
          <label>{title}</label>
          <div
            className={`w-100 mt-1 border aspect-square flex-container align-center align-middle text-bold border-radius`}
            style={{
              backgroundColor: defaultColor,
              color: isBrightColor(color) ? 'black' : 'white',
            }}>
            {defaultColor.toUpperCase()}
          </div>
          <button
            data-open={id}
            className="o-button o-button--secondary w-100 o-file-input o-file-input--basic mt-1 text-bold">
            Edit color
          </button>
        </div>
      </div>

      <Modal
        id={id}
        appendTo="#branding-colors"
        useDefaultButtons
        headerText={`Edit ${title.toLowerCase()}`}
        submitHandler={handleSubmit}
        cancelHandler={handleCancel}
        width={312}>
        <div className="mb-2 mt-0">
          <div className="mb-2">
            <ColorPicker color={color} onChange={handleColorChange} />
          </div>
          <div className="pr-3 pl-3 mt-1">
            <label>Hex Code</label>
            <input
              type="text"
              value={color}
              onChange={(e) => setColor(e.target.value.toUpperCase())}
              placeholder="Hex Code"
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
export default ColorCard
