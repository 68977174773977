import React, { useContext } from 'react'
import { FaStar } from 'react-icons/fa'
import { getPointClass } from '../../../helpers/elementHelper'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'

const PointLabel = ({ points, className }) => {
  const { scenario } = useContext(ScenarioEditorContext)
  if (scenario.scoringSystem.kind === 'DISABLED' || points === 0)
    return <></>

  return (
    <span
      className={`o-label o-label--${getPointClass(points, scenario.scoringSystem.kind)} text-white text-small ${className}`}>
      <FaStar />
      &nbsp;
      {points}
    </span>
  )
}

export default PointLabel
