import React from 'react'
import { FaHome } from 'react-icons/fa'

const HomeLabel = ({ className }) => {
  return (
    <span className={`o-label o-label--success text-white ${className}`}>
      <FaHome />
    </span>
  )
}

export default HomeLabel
