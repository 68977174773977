import { useMutation } from '@apollo/client'
import React, { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../UI/Modal'
import {
  addUserToGroupsMutation,
  removeUserFromGroupsMutation,
} from '../../../apollo/query/groups'
import TraineeDetailGroupsList from '../Forms/TraineeDetailGroupsList'

const ManageTraineeGroupsModal = ({ id, appendTo, currentTrainee }) => {
  const groupIdsRef = useRef()
  const [key, setKey] = useState(uuidv4())

  const [addGroupsMutation] = useMutation(addUserToGroupsMutation)
  const [removeGroupsMutation] = useMutation(removeUserFromGroupsMutation)

  const submitHandler = () => {
    const existingGroupIds = currentTrainee.groups.map((group) => group.id)
    const addGroups = groupIdsRef.current.filter(
      (addGroupId) => !existingGroupIds.includes(addGroupId)
    )
    const removeGroups = currentTrainee.groups.reduce((a, group) => {
      if (!groupIdsRef.current.includes(group.id)) {
        a.push(group.id)
      }
      return a
    }, [])
    addGroupsMutation({
      variables: {
        userIds: [currentTrainee.id],
        groupIds: addGroups,
      },
    })
    removeGroupsMutation({
      variables: { userIds: [currentTrainee.id], groupIds: removeGroups },
    })

    setTimeout(() => setKey(uuidv4()), 300)
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      overflowY="visible"
      cancelHandler={() => setTimeout(() => setKey(uuidv4()), 300)}
      headerText="Manage group membership"
      footerText="Trainees will be notified by email in case of new course or scenario access."
      submitHandler={submitHandler}>
      <form className="mt-3" key={key}>
        <label>{currentTrainee.name} is member of</label>
        <TraineeDetailGroupsList
          groupRef={groupIdsRef}
          userGroups={currentTrainee.groups}
        />
        {/* Fake submit button to prevent enter... */}
        <button
          type="submit"
          disabled
          style={{ display: 'none' }}
          aria-hidden="true"
        />
      </form>
    </Modal>
  )
}

export default ManageTraineeGroupsModal
