import React, { useEffect } from 'react'
import Modal from '../../UI/Modal'
import useMediaUploader from '../../../hooks/useMediaUploader'

const ReplaceMediumModal = ({ id, appendTo, medium }) => {
  const [uploadMediaItems, setUploadMediaItems] = useMediaUploader()
  const cancelHandler = () => {}

  const handleSelect = (e) => {
    const file = e.target.files[0]
    file.mediumId = medium.id
    file.trimEnabled = medium.trimEnabled
    file.trimStart = medium.trimStart
    file.trimEnd = medium.trimEnd
    setUploadMediaItems([file])
  }

  useEffect(() => {
    if (uploadMediaItems.every((file) => file.state === 'COMPLETED'))
      $(`#${id}`).foundation('close')
  }, [uploadMediaItems])

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      overflowY="visible"
      closable={false}
      useFooterBorder
      buttons={
        <>
          {uploadMediaItems.length === 0 && (
            <>
              <button
                className="hollow button secondary wide"
                data-close={id}
                onClick={cancelHandler}>
                Cancel
              </button>
              <button
                type="submit"
                className="button primary wide o-file-input--basic">
                <input
                  type="file"
                  accept="video/mp4, .mp4"
                  id="replace-media"
                  onChange={handleSelect}
                />
                Select new file
              </button>
            </>
          )}
          {uploadMediaItems.length > 0 && (
            <div>
              <h2 className="text-normal text-bold mb-1">
                We are currently uploading your file
              </h2>
              <p className="text-stable-dark mb-2 text-normal">
                Please don’t leave this page. The popup will close automatically
                when uploading is complete.
              </p>
              {uploadMediaItems.map((file) => (
                <div
                  key={file.id}
                  className="border border-radius p-2 flex-container align-justify align-middle">
                  <div className="text-normal">{file.name}</div>
                  <div className="o-label o-label--assertive text-small border-radius text-white">
                    UPLOADING {file.progress}%
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      }>
      <div className="pt-3">
        <h2 className="text-assertive text-bold">
          Are you sure you want to replace this file?
        </h2>
        <p className="text-stable-dark mb-3">
          The current file will be deleted and replaced with the new file. This
          action cannot be undone. If possible, trim, fade-in and video-loop
          times will be saved. You are advised to check these times in the video
          editor after upload. Links to scenes will always be saved.
        </p>
      </div>
    </Modal>
  )
}

export default ReplaceMediumModal
