import React from 'react'
import { getRotation } from '../../helpers/controls'
import TextBubble from './partials/TextBubble'
import useCurrentUser from '../../../../hooks/useCurrentUser'

const Hotspot = ({ id, scene, state }) => {
  const [, currentClient] = useCurrentUser()

  if (!scene || !currentClient) return null

  let WIDTH = 1.8
  if (scene.elementSize === 'WIDE') WIDTH *= 2

  const HOTSPOT_SIZES = {
    SMALL: 0.23,
    MEDIUM: 0.43,
    LARGE: 0.63,
  }
  const OPACITY = {
    ring: 1,
    circle: 0.6,
  }

  const getAnswers = () => {
    return scene.answers.map((answer, index) => {
      const rotation = getRotation({
        x: answer.anchorX,
        y: answer.anchorY,
      })
      let answerWidth = 1.8
      if (answer.elementSize === 'WIDE') answerWidth *= 2

      return (
        <a-entity
          key={index}
          rotation={`${rotation} 0`}
          hideable-element={`visible: ${state.showElements}`}>
          <a-collision-box id={`${id}-A${index}`} width={answerWidth}>
            <a-entity>
              {/* Hotspot Label */}
              {!scene.hideHotspotLabelsVr && (
                <a-entity
                  position={`0 ${HOTSPOT_SIZES[answer.hotspotSize] + 0.1} 0`}>
                  <TextBubble
                    textValue={answer.name}
                    id={`A${index}`}
                    type="hotspot"
                    width={answerWidth}
                  />
                </a-entity>
              )}
              {/* Hotspot Ring */}
              <a-entity
                geometry={`primitive: ring; radiusInner: ${
                  HOTSPOT_SIZES[answer.hotspotSize] - 0.03
                }; radiusOuter: ${HOTSPOT_SIZES[answer.hotspotSize]};`}
                material={`color: ${currentClient.primaryColor}; shader: flat; 
              opacity: ${OPACITY.ring};`}
              />
              {/* Hotspot Circle */}
              <a-circle
                opacity={OPACITY.circle}
                radius={HOTSPOT_SIZES[answer.hotspotSize]}
                material={`
              color: ${currentClient.primaryColor};
              shader: flat;
              `}></a-circle>
            </a-entity>
          </a-collision-box>
        </a-entity>
      )
    })
  }

  return (
    <>
      <a-entity
        hideable-element={`visible: ${state.showElements}`}
        rotation={`${getRotation({ x: scene.anchorX, y: scene.anchorY })} 0`}>
        {scene.question && (
          <a-collision-box id={id} width={WIDTH}>
            <TextBubble
              textValue={scene.question}
              id="question"
              width={WIDTH}
            />
          </a-collision-box>
        )}
      </a-entity>
      {getAnswers()}
    </>
  )
}
export default Hotspot
