import React, { useEffect, useState } from 'react'
import {
  FaListUl,
  FaThLarge,
  FaChevronDown,
  FaEllipsisH,
  FaUpload,
  FaVideoSlash,
  FaLink,
  FaTimes,
} from 'react-icons/fa'
import { useQuery } from '@apollo/client'

import { getMediaByScenarioIdQuery } from '../apollo/query/media'
import useMediaUploader from '../hooks/useMediaUploader'
import withApollo from '../hooks/withApollo'
import Checkbox from '../components/UI/Form/Checkbox'
import { default as S } from '../utils/lang/en.json'
import Modal from '../components/UI/Modal'
import Toast from '../components/UI/Notifications/Toast'
import DragAndDrop from '../components/UI/DragAndDrop'
import Filter from '../components/UI/Menu/Filter'
import { getStoreQuery } from '../apollo/query/store'
import { updateStore } from '../utils/helpers/graphql'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import Table from '../components/Table'
import {
  convertMilliSeconds,
  convertResolution,
  convertSize,
  convertMediaType,
  dateFormat,
  sortOnKey,
} from '../utils/format'
import Grid from '../components/Grid'
import Delay from '../utils/helpers/Delay'
import MediaEmptyState from '../components/Media/MediaEmptyState'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import UploadLabel from '../components/Media/UploadLabel'
import TableColumnsDropdown from '../components/UI/TableColumnsDropdown'
import useCurrentUser from '../hooks/useCurrentUser'
import usePreferences from '../hooks/usePreferences'
import { filterColumnsHandler, sortHandler } from '../components/tableFunctions'
import TableSearchBar from '../components/UI/Table/TableSearchBar'
import MediaOptionsDropdown from '../components/Media/MediaOptionsDropdown'
import DeleteMediumModal from '../components/Media/Modal/DeleteMediumModal'
import CopyMediumModal from '../components/Media/Modal/CopyMediumModal'
import Dropdown from '../components/UI/Menu/Dropdown'
import MediaSceneTooltip from '../components/Media/Tooltips/MediaSceneTooltip'
import MediumModal from '../components/Media/Modal/MediumModal'
import { MediumProvider } from '../components/Media/Modal/MediumContext'
import VideoTrimIcon from '../utils/helpers/VideoTrimIcon'
import DataTooltip from '../components/UI/DataTooltip'
import ReplaceMediumModal from '../components/Media/Modal/ReplaceMediumModal'

const Media = ({
  isLink,
  scenarioId,
  selectedId,
  onLinkClick = () => {},
  deleteCallback = () => {},
  linkCallback = () => {}, // when a scene is linked inside medium modal
  unlinkCallback = () => {}, // when a scene is unlinked inside medium modal
  openSceneCallback,
}) => {
  const [preferences, setPreferences] = usePreferences('media')
  const [currentUser] = useCurrentUser()

  const [mediumId, setMediumId] = useState(null)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [showEmptyScenario, setShowEmptyScenario] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [dismissToast, setDismissToast] = useState(false)
  const [filesUploading, setFilesUploading] = useState(false)
  const [uploadMediaItems, setUploadMediaItems] = useMediaUploader()

  const { loading, data, startPolling, stopPolling, refetch } = useQuery(
    getMediaByScenarioIdQuery,
    {
      variables: {
        scenarioId: scenarioId,
      },
    }
  )

  const { data: store } = useQuery(getStoreQuery)

  window.onbeforeunload = () => {
    if (uploadMediaItems.length) {
      return ''
    }
    return null
  }

  useEffect(() => {
    refetch()
  }, [uploadMediaItems, scenarioId])

  useEffect(() => {
    // Start polling during uploading
    if (data) {
      const isUploading = !data.media.every(
        ({ state }) => state === 'COMPLETED'
      )

      if (isUploading) startPolling(1000 * 5)
      if (!isUploading) {
        stopPolling()
        setFilesUploading(false)
      }
    }
  }, [data])

  useEffect(() => {
    if (filesUploading) {
      // file upload was finished..
      setTimeout(() => {
        startPolling(2 * 1000)
      }, 1000)
    }
  }, [filesUploading])

  useEffect(() => {
    const interval = setInterval(() => {
      const hasIncompleteMedia = data.media
        .filter((medium) => medium.name)
        .some((item) => ['QUEUED', 'PROGRESSING'].includes(item.state))
      if (hasIncompleteMedia) refetch()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [data])

  if (!store) return null

  // loading user & preferences
  if (!preferences || !currentUser) {
    return <LoadingSpinner text="Loading user..." />
  }

  // loading media items
  if (loading) {
    return (
      <Delay>
        <LoadingSpinner text="Fetching media items..." />
      </Delay>
    )
  }

  const { mediaStore } = store

  const getUploadData = () => {
    return uploadMediaItems.map((item) => {
      return {
        id: item.id,
        loadingIcon: item.state === 'UPLOADING' && <FaUpload />,
        subtitle: <UploadLabel item={item} showFileName={false} />,
        name: {
          value: item.name,
          render: <UploadLabel item={item} />,
        },
      }
    })
  }

  const uploadFilesHandler = (files) => {
    setFilesUploading(true)
    setUploadMediaItems(
      files.map((file) => {
        file.sid = scenarioId
        return file
      })
    )
  }

  const dismissToastHandler = () => {
    setDismissToast(true)
    setTimeout(() => {
      setDismissToast(false)
    }, 300)
  }

  const tableHeaders = [
    'name',
    'kind',
    'duration',
    'linkedToScenes',
    'size',
    'width',
    'createdAt',
  ]

  const getNameLabel = (item) => {
    if (item.state !== 'COMPLETED') return <UploadLabel item={item} />
    if (item.trimEnabled)
      return (
        <>
          <span className="mr-2">{item.name}</span>
          <span className="float-right">
            <DataTooltip title="Video is trimmed">
              <VideoTrimIcon />
            </DataTooltip>
          </span>
        </>
      )
    return null
  }

  const sortedData = () => {
    if (!data?.media) return []

    const { value, isAscending } = preferences.sortOn
    const { filterOption } = mediaStore
    const stereoscopicFilter = filterOption.split(' ')[0].toLowerCase()

    const scenarioList = sortOnKey([...data.media], value, isAscending).reduce(
      (array, item) => {
        array.push({
          id: item.id,
          imageUrl: item.previewUrl,
          name: {
            value: item.name,
            render: getNameLabel(item),
          },
          kind: {
            value: item.stereoscopic,
            render: convertMediaType(item.stereoscopic, item.kind),
          },
          duration: convertMilliSeconds(item.duration) || '-',
          linkedToScenes: <MediaSceneTooltip scenes={item.linkedToScenes} />,
          size: convertSize(item.size),
          state: {
            value: item.state,
            render: item.state !== 'COMPLETED' && (
              <UploadLabel item={item} showFileName={false} />
            ),
          },
          width: convertResolution(item.width),
          createdAt: dateFormat(item.createdAt),
          subtitle: convertMediaType(item.stereoscopic, item.kind),
        })
        return array
      },
      []
    )

    // scenario is empty
    if (!scenarioList.length && !showEmptyScenario) setShowEmptyScenario(true)
    if (scenarioList.length && showEmptyScenario) setShowEmptyScenario(false)

    // filter searchQuery
    const filtered = scenarioList.filter((medium) => {
      return medium.name.value.toLowerCase().includes(searchQuery.toLowerCase())
    })

    // filter stereoscopic
    const final = filtered.reduce((a, item) => {
      if (
        !stereoscopicFilter ||
        item.kind.value === (stereoscopicFilter === 'stereo')
      ) {
        a.push(item)
      }
      return a
    }, [])

    if (!final.length && !showEmptyState) setShowEmptyState(true)
    if (final.length && showEmptyState) setShowEmptyState(false)

    if (uploadMediaItems) {
      const uploading = getUploadData()
      final.unshift(...uploading)
    }
    return final
  }

  const openHandler = (id) => {
    const ids = uploadMediaItems.map((i) => i.id)
    if (!ids.includes(id)) {
      setMediumId(id)
      $('#mediumModal').foundation('open')
      $('#mediumModal').on('closed.zf.reveal', () => {
        // stop playing:
        setMediumId(null)
      })
    }
  }
  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    if (!newSelection.length) dismissToastHandler()
    return setSelectedItems(newSelection)
  }

  const content = () => {
    const list = tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={
            !disabled
              ? (e) => {
                  e.preventDefault()
                  filterColumnsHandler(item, preferences, setPreferences)
                }
              : null
          }>
          <Checkbox
            style={{ marginBottom: '0px', cursor: 'default' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.media.headers[item]}
          />
        </li>
      )
    })
    return <>{list}</>
  }

  const tableSearchBar = () => {
    return (
      <>
        <span className="cell shrink large-auto mr-1 large-order-2">
          <Filter
            text="Type"
            icon={<FaChevronDown />}
            filterHandler={(filter) => {
              updateStore(mediaStore, {
                filterOption: filter === 'All' ? '' : filter,
              })
            }}
            selected={mediaStore.filterOption || 'All'}
            filterOptions={['All', 'Mono 360° Video', 'Stereo 360° Video']}
          />
        </span>
        <TableSearchBar
          searchQuery={searchQuery}
          setShowEmptyState={setShowEmptyState}
          showEmptyState={showEmptyState}
          setSearchQuery={setSearchQuery}
        />
      </>
    )
  }

  const optionsDropdownHandler = (id) => {
    if (isLink) {
      const media = data.media.find((m) => m.id === id)
      if (!['COMPLETED', 'PROGRESSING', 'QUEUED'].includes(media?.state))
        return null
      if (selectedId === id)
        return (
          <label className="o-label--custom o-label--stable-lighter text-bold text-stable-dark">
            Linked
          </label>
        )
      return (
        <button
          className="o-button o-button--auto-width o-button--auto-height text-small text-bold"
          onClick={() => onLinkClick(id)}
          style={{
            ...(preferences.showTable
              ? {}
              : {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateY(calc(-50% - 17.5px)) translateX(-50%)',
                }),
          }}>
          <FaLink className="mr-0-5" />
          Link
        </button>
      )
    }
    return (
      <MediaOptionsDropdown
        viewHandler={() => {
          setMediumId(id)
          $('#mediumModal').foundation('open')
          $('#mediumModal').on('closed.zf.reveal', () => {
            // stop playing:
            setMediumId(null)
          })
        }}
        downloadHandler={() => {
          const item = data.media.find((m) => m.id === id)
          window.location.href = item.originalUrl
        }}
        copyHandler={() => {
          setMediumId(id)
          $('#copyMediumModal').foundation('open')
        }}
        replaceHandler={() => {
          setMediumId(id)
          $('#replaceleModal').foundation('open')
        }}
        deleteHandler={() => {
          setMediumId(id)
          $('#deleteSingleModal').foundation('open')
        }}
        id={id}></MediaOptionsDropdown>
    )
  }

  const renderUploadButton = () => {
    return (
      <span className="cell shrink">
        <button
          onClick={() => {
            document.querySelector('#upload-media').click()
          }}
          className={`button primary mb-0`}
          title="Upload Media">
          <input
            type="file"
            multiple
            id="upload-media"
            accept="video/mp4, .mp4"
            hidden
            onChange={(e) => {
              uploadFilesHandler([...e.target.files])
            }}
          />

          <FaUpload />
        </button>
      </span>
    )
  }

  const modalCloseButton = () => {
    return (
      <span
        className="flex-container align-middle text-stable-dark pr-1 pl-2 cursor-pointer"
        data-close="media-modal">
        <FaTimes />
      </span>
    )
  }

  const tableButtonBar = (
    <>
      <span className="cell shrink">
        {preferences.showTable ? (
          <TableColumnsDropdown content={content} />
        ) : null}
      </span>
      <span className="cell shrink button-group o-button-group no-gaps mr-1">
        <button
          id="toggle-view-grid"
          className={`button hollow secondary
              ${!preferences.showTable ? 'active' : ''}`}
          onClick={() => {
            setPreferences({
              ...preferences,
              showTable: false,
            })
          }}>
          <FaThLarge />
        </button>
        <button
          id="toggle-view-table"
          className={`button hollow secondary
            ${preferences.showTable ? 'active' : ''}`}
          onClick={() => {
            setPreferences({
              ...preferences,
              showTable: true,
            })
          }}>
          <FaListUl />
        </button>
      </span>
      {!isLink && renderUploadButton()}
      {isLink && modalCloseButton()}
    </>
  )

  const getPageContent = () => {
    if (showEmptyState)
      return (
        <FilterEmptyState
          type="media files"
          icon={<FaVideoSlash />}
          clickHandler={() => {
            setSearchQuery('')
            updateStore(mediaStore, { filterOption: '' })
            setShowEmptyState(false)
          }}
        />
      )
    if (preferences.showTable) {
      return (
        <Table
          data={sortedData()}
          headers={tableHeaders.filter(
            (i) => !preferences.filteredColumns.includes(i)
          )}
          selectable={!isLink}
          selectedItems={selectedItems}
          selectAllHandler={(ids) => setSelectedItems(ids)}
          sortOn={preferences.sortOn}
          sortHandler={(value) =>
            sortHandler(value, preferences, setPreferences)
          }
          rowHandler={!isLink && openHandler}
          rowSuffix={optionsDropdownHandler}
          checkboxHandler={checkboxHandler}
          type="media"
        />
      )
    }
    return (
      <Grid
        data={sortedData()}
        selectedItems={selectedItems}
        selectable={!isLink}
        cardHandler={openHandler}
        checkboxHandler={checkboxHandler}
        cardMenu={optionsDropdownHandler}
      />
    )
  }

  const getTitle = () => {
    if (isLink) return 'Link media file'
    const { length } = [...sortedData()]
    if (length === 1) return '1 Media file'
    return `${length} Media files`
  }

  const tableHeader = (
    <div
      className={`grid-container fluid ${isLink ? 'pr-3 pl-3 pb-2-5 border-bottom mb-2' : ''}`}>
      <span className={`grid-x o-table__title ${isLink ? 'mb-0' : ''}`}>
        <span className="cell small-12 medium-shrink flex-container align-middle mr-3">
          <h2
            data-testid="table-header-title"
            className="cell medium-shrink mb-0 mr-1">
            {getTitle()}
          </h2>
        </span>
        <span className="cell small-12 medium-auto grid-x">
          {tableSearchBar()}
        </span>
        <span className="cell small-12 medium-shrink grid-x">
          {tableButtonBar}
        </span>
      </span>
    </div>
  )

  const toastContent = (
    <Dropdown
      id="dropup-toast"
      position="top"
      alignment="right"
      offset={{ left: '17px' }}
      button={
        <button
          className="cursor-pointer h-100 outline-none"
          data-toggle="dropup-toast">
          <FaEllipsisH />
        </button>
      }>
      <>
        <li
          className="o-dropdown__list-item"
          data-open="copyMultipleMediumModal">
          Copy files to...
        </li>
        <hr className="mt-1 mb-1" />
        <li className="o-dropdown__list-item" data-open="deleteMultipleModal">
          Delete files
        </li>
      </>
    </Dropdown>
  )
  const loadModals = (
    <>
      <Modal id="mediumModal" appendTo="#media" width="90%">
        <MediumProvider>
          <MediumModal
            mediumId={mediumId}
            data-close
            setMediumId={setMediumId}
            mediumIds={[...sortedData()].reduce((a, m) => {
              if (['COMPLETED', 'PROGRESSING'].includes(m.state?.value))
                a.push(m.id)
              return a
            }, [])}
            linkCallback={linkCallback}
            unlinkCallback={unlinkCallback}
            openSceneCallback={openSceneCallback}
          />
        </MediumProvider>
      </Modal>
      <CopyMediumModal
        id="copyMediumModal"
        appendTo="#media"
        currentScenario={scenarioId}
        mediumIds={[mediumId]}
        callback={() => {
          setSelectedItems([])
          refetch()
        }}
      />
      <CopyMediumModal
        id="copyMultipleMediumModal"
        appendTo="#media"
        currentScenario={scenarioId}
        mediumIds={[...selectedItems]}
        callback={() => {
          setSelectedItems([])
          refetch()
        }}
      />
      <DeleteMediumModal
        id="deleteMultipleModal"
        appendTo="#media"
        mediumIds={[...selectedItems]}
        callback={() => {
          deleteCallback([...selectedItems])
          refetch()
          setSelectedItems([])
        }}
      />
      <DeleteMediumModal
        id="deleteSingleModal"
        appendTo="#media"
        mediumIds={[mediumId]}
        callback={() => {
          deleteCallback([mediumId])
          refetch()
          setMediumId(null)
        }}
      />
      <ReplaceMediumModal
        id="replaceleModal"
        appendTo="#media"
        medium={data.media.find((m) => m.id === mediumId)}
        callback={() => {
          refetch()
          setSelectedItems([])
        }}
      />
    </>
  )

  if (showEmptyScenario) {
    let c = (
      <MediaEmptyState
        setUploadMediaItems={uploadFilesHandler}
        isLink={isLink}
      />
    )
    if (uploadMediaItems.length) {
      c = (
        <div className="grid-container fluid">
          <Table
            data={getUploadData()}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            selectable
            selectedItems={selectedItems}
            selectAllHandler={(ids) => setSelectedItems(ids)}
            sortOn={preferences.sortOn}
            placeholderRows={uploadMediaItems.length}
            sortHandler={(value) =>
              sortHandler(value, preferences, setPreferences)
            }
            type="media"
          />
        </div>
      )
    }

    return (
      <div id="media">
        {loadModals}
        {tableHeader}
        {c}
      </div>
    )
  }

  const pageContent = (
    <>
      {tableHeader}
      <div
        className={`grid-container fluid o-table--page-container ${isLink ? 'pl-3 pr-3' : ''}`}
        style={preferences.showTable ? {} : { overflow: 'hidden' }}>
        {getPageContent()}
      </div>
    </>
  )

  return (
    <div id="media" className="h-100">
      {loadModals}
      {isLink ? (
        pageContent
      ) : (
        <DragAndDrop uploadHandler={uploadFilesHandler}>
          {pageContent}
        </DragAndDrop>
      )}

      <Toast
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'files' : 'file'}
        dismissable
        closeHandler={() => {
          dismissToastHandler()
          setTimeout(() => {
            setSelectedItems([])
          }, 10)
        }}
        isClosing={dismissToast}>
        {toastContent}
      </Toast>
    </div>
  )
}
export default withApollo(Media)
