import { useMemo } from 'react'
import { useReactFlow } from 'reactflow'

const useSelectedEdges = () => {
  const reactFlow = useReactFlow()
  const edges = reactFlow.getEdges()
  const selectedEdges = useMemo(() => edges.filter((n) => n.selected), [edges])

  return selectedEdges ?? []
}

export default useSelectedEdges
