import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

@inject('diagramStore')
@observer
class MoreMenu extends Component {
  render() {
    const { card } = this.props
    const { cameraLocations } = card.store
    return (
      card.showMoreMenu && (
        <div
          onMouseEnter={() => {
            card.showMoreMenu = true
            this.props.diagramStore.activeElement = card
          }}
          style={{
            position: 'absolute',
            width: card.type === 'scene' ? 235 : 185,
            paddingTop: 5,
            top: card.y,
            left: card.x,
            zIndex: card.baseZIndex + 1000,
          }}>
          <ul className="o-dropdown o-dropdown--right" data-dropdown-menu>
            {card.type === 'scene' && !card.start && (
              <li className="o-dropdown__item">
                <span
                  className="o-dropdown__link"
                  onClick={() => {
                    card.start = true
                    card.save()
                  }}>
                  Make start scene
                </span>
              </li>
            )}
            {card.type === 'scene' && (
              <li className="o-dropdown__item">
                <span
                  className="o-dropdown__link"
                  onClick={() => card.duplicate()}>
                  Duplicate scene
                </span>
              </li>
            )}
            {card.type === 'scene' && (
              <li className="o-dropdown__item">
                <span
                  className="o-dropdown__link"
                  onClick={() => card.change_scene_kind()}>
                  Change scene type
                </span>
              </li>
            )}
            {card.type === 'scene' && (
              <li className="o-dropdown__divider" role="menuitem" />
            )}
            {card.type === 'scene' && (
              <li
                className="o-dropdown__item o-dropdown__item--open-dropdown opens-right"
                style={{ position: 'relative' }}>
                <span className="o-dropdown__link">
                  <span className="o-dropdown__link-aside" />
                  Camera location
                </span>
                <ul
                  className="o-dropdown o-dropdown--left"
                  style={{ width: 230, top: 0 }}>
                  {cameraLocations.map((location, key) => {
                    return (
                      <li
                        className={
                          location.id === card.camera_location_id
                            ? 'o-dropdown__item o-dropdown__item--active'
                            : 'o-dropdown__item'
                        }
                        key={key}
                        onClick={() =>
                          card.change_camera_location(location.id)
                        }>
                        <span className="o-dropdown__link">
                          {location.name}
                        </span>
                      </li>
                    )
                  })}
                  {cameraLocations.length > 0 && (
                    <li className="o-dropdown__divider" />
                  )}
                  {card.camera_location_id && (
                    <li className="o-dropdown__item">
                      <span
                        className="o-dropdown__link"
                        onClick={() => card.change_camera_location(null)}>
                        Remove camera location
                      </span>
                    </li>
                  )}
                  <li className="o-dropdown__item">
                    <span
                      className="o-dropdown__link"
                      onClick={() => card.create_new_camera_location()}>
                      Add new camera location
                    </span>
                  </li>
                </ul>
              </li>
            )}
            {card.type === 'scene' && (
              <li className="o-dropdown__divider" role="menuitem" />
            )}
            <li className="o-dropdown__item">
              <span className="o-dropdown__link" onClick={() => card.delete()}>
                Delete {card.type === 'scene' ? 'scene' : 'ending'}
              </span>
            </li>
          </ul>
        </div>
      )
    )
  }
}

export default MoreMenu
