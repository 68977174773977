import React, { useState } from 'react'
import DataTooltip from '../../UI/DataTooltip'

const ProductGroups = ({ count, names }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (count <= 0) return '-'

  const formatName = (value) => `<li style="text-align:start;">${value}</li>`
  if (isHovered) {
    const title = `
    <div style="padding:10px;">
   <span className="text-white" style="text-align:start;font-weight:700;">Including ${count} course${
      count === 1 ? '' : 's'
    }</span>
    <ul style="margin-right:10px; margin-bottom:0; margin-top:10px;">
   ${names.map((name) => formatName(name)).join('')}
    </ul>
    </div>
      `
    return (
      <DataTooltip title={title}>
        <span className="text-underline">
          {count} course{count === 1 ? '' : 's'}
        </span>
      </DataTooltip>
    )
  }
  return (
    <span
      className="text-underline"
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {count} course{count === 1 ? '' : 's'}
    </span>
  )
}

export default ProductGroups
