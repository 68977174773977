import React, { useContext, useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import {
  FaCommentAlt,
  FaMapMarker,
  FaDotCircle,
  FaRedo,
  FaBars,
  FaArrowUp,
  FaChevronRight,
  FaPencilAlt,
  FaExclamationTriangle,
  FaQuestionCircle,
} from 'react-icons/fa'
import { SceneEditorContext } from '../../../SceneEditorContext'
import {
  updateSceneMutation,
  updateAnswerMutation,
} from '../../../../../apollo/query/scenes'
import { handleApolloError } from '../../../../../utils/errors'
import { moveCameraToPosition } from '../../../helpers/controls'
import DataTooltip from '../../../../UI/DataTooltip'
import { capitalized } from '../../../../../utils/helpers/javascript'
import getIconByKind from '../../../../../utils/helpers/getIconByKind'
import { validateNotEmpty } from '../../../../../utils/validation/validate'
import ElementSizeButtonGroup from './ElementSizeButtonGroup'
import ButtonGroup from '../../../../UI/Form/ButtonGroup/ButtonGroup'

const moveCameraToElement = (elementId) => {
  const targetRotation = document
    .querySelector(elementId)
    .parentNode.getAttribute('rotation')
  moveCameraToPosition(targetRotation.x, targetRotation.y)
}

const ElementCard = ({ type, index, scenes, scenarioId }) => {
  const [elementId, setElementId] = useState()
  const [videoElementId, setVideoElementId] = useState()
  const [isEditingAnswerRow, setIsEditingAnswerRow] = useState(null)
  const [isEditingQuestion, setIsEditingQuestion] = useState(false)
  const [warningMessage, setWarningMessage] = useState()
  const editRef = useRef()
  const [sceneState] = useContext(SceneEditorContext)
  const [updateScene] = useMutation(updateSceneMutation, {
    onError: handleApolloError,
  })
  const [updateAnswer] = useMutation(updateAnswerMutation, {
    onError: handleApolloError,
  })

  useEffect(() => {
    const suffix = type === 'ANSWER' ? `-A${index}` : ''
    if (!elementId) {
      setElementId(`element-${sceneState.kind}-${sceneState.id}${suffix}`)
    }
    if (!videoElementId) {
      setVideoElementId(`${sceneState.kind}-${sceneState.id}${suffix}`)
    }
  }, [elementId, videoElementId])

  const getAnswerIcon = () => {
    switch (sceneState.kind) {
      case 'HOTSPOT':
        return <FaDotCircle className="mr-1" />
      case 'TRANSITION':
        return <FaRedo className="mr-1" />
      case 'INFORMATION':
      case 'MPC':
        return <FaBars className="mr-1" />
      case 'DIRECTION':
        return <FaArrowUp className="mr-1" />
      default:
        return null
    }
  }

  const toggleEditAnswerRow = (e, i) => {
    e.preventDefault()
    e.stopPropagation()
    setIsEditingAnswerRow(i)
  }
  const toggleIsEditingQuestion = (e, v) => {
    e.preventDefault()
    e.stopPropagation()
    setIsEditingQuestion(v)
  }

  const editField = (value) => {
    const submitHandler = () => {
      const { value: v } = editRef.current
      if (
        validateNotEmpty(v) ||
        (type === 'QUESTION' &&
          ['DIRECTION', 'HOTSPOT'].includes(sceneState.kind))
      ) {
        if (type === 'ANSWER') {
          updateAnswer({
            variables: {
              ...sceneState.answers[index],
              name: v,
            },
          })
        } else {
          updateScene({
            variables: {
              ...sceneState,
              question: v,
            },
          })
        }
        setIsEditingQuestion(false)
      } else {
        setWarningMessage('This field cannot be empty')
        editRef.current.focus()
      }
    }
    if (type === 'ANSWER') {
      return (
        <form
          className="m-0"
          onSubmit={(e) => {
            e.preventDefault()
            submitHandler()
          }}>
          <div className="cell pt-2">
            <input
              type="text"
              placeholder="Description missing"
              name="edit-field"
              onKeyPress={(e) => {
                if (e.key === 'Enter') submitHandler()
              }}
              id="edit-question"
              defaultValue={value}
              ref={editRef}
            />
          </div>
          <div
            className="cell grid-x align-right"
            style={{ display: 'inline-flex', alignItems: 'center' }}>
            <div className="cell auto text-assertive text-normal">
              {warningMessage && (
                <>
                  <FaExclamationTriangle className="mr-1" /> {warningMessage}
                </>
              )}
            </div>
            <button
              type="cancel"
              onClick={(e) => {
                toggleIsEditingQuestion(e, false)
                setWarningMessage(null)
              }}
              className="cell mb-0 button secondary hollow small small-2">
              Cancel
            </button>
            <button
              type="submit"
              className="cell ml-1 mb-0 button primary small small-2">
              Confirm
            </button>
          </div>
        </form>
      )
    }
    if (type === 'QUESTION') {
      return (
        <form
          className="m-0"
          onSubmit={(e) => {
            e.preventDefault()
            submitHandler()
          }}>
          <div className="cell pt-2">
            <textarea
              ref={editRef}
              className="c-video__editor--textarea"
              placeholder="Description missing"
              onKeyPress={(e) => {
                if (e.key === 'Enter') submitHandler()
              }}
              rows="3"
              type="text"
              name="edit-field"
              id=""
              defaultValue={value}
            />
          </div>
          <div className="cell grid-x align-right">
            <div
              className="cell grid-x align-right"
              style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div className="cell auto text-assertive text-normal">
                {warningMessage && (
                  <>
                    <FaExclamationTriangle className="mr-1" /> {warningMessage}
                  </>
                )}
              </div>

              <button
                onClick={() => {
                  setIsEditingQuestion(false)
                  setWarningMessage(null)
                }}
                className="cell mb-0 button secondary hollow small small-2">
                Cancel
              </button>
              <button
                type="submit"
                className="cell ml-1 mb-0 button primary small small-2">
                Confirm
              </button>
            </div>
          </div>
        </form>
      )
    }
    return null
  }
  const getSettingsToggle = (text, id, checked, update, title) => {
    return (
      <div className="cell grid-x c-video__editor--elements--settings--row switch-settings">
        <div className="cell auto">
          {text}
          {title && (
            <DataTooltip title={title}>
              {' '}
              <FaQuestionCircle className="text-stable" />
            </DataTooltip>
          )}
        </div>
        <div className="cell shrink">
          <div className="o-switch">
            <input
              type="checkbox"
              name={id}
              id={id}
              checked={checked}
              onChange={update}
              className="o-switch__input"
            />
            <label htmlFor={id} className="o-switch__paddle"></label>
          </div>
        </div>
      </div>
    )
  }

  const getSettings = () => {
    switch (sceneState.kind) {
      case 'HOTSPOT':
        if (type === 'QUESTION') return null
        const handleUpdateAnswer = (value) => {
          updateAnswer({
            variables: {
              ...sceneState.answers[index],
              hotspotSize: value,
            },
          })
        }
        return (
          <>
            <div className="cell grid-x  c-video__editor--elements--settings--row">
              <div className="cell small-4">Hotspot size</div>
              <div className="cell small-8 flex-container align-right">
                <ButtonGroup>
                  <ButtonGroup.Button
                    onClick={() => handleUpdateAnswer('SMALL')}
                    active={
                      sceneState.answers[index]?.hotspotSize.toUpperCase() ===
                      'SMALL'
                    }>
                    Small
                  </ButtonGroup.Button>
                  <ButtonGroup.Button
                    onClick={() => handleUpdateAnswer('MEDIUM')}
                    active={
                      sceneState.answers[index]?.hotspotSize.toUpperCase() ===
                      'MEDIUM'
                    }>
                    Medium
                  </ButtonGroup.Button>
                  <ButtonGroup.Button
                    onClick={() => handleUpdateAnswer('LARGE')}
                    active={
                      sceneState.answers[index]?.hotspotSize.toUpperCase() ===
                      'LARGE'
                    }
                    last>
                    Large
                  </ButtonGroup.Button>
                </ButtonGroup>
              </div>
            </div>

            <div className="flex-container align-middle align-justify c-video__editor--elements--settings--row w-100">
              <div>Label size</div>
              <ElementSizeButtonGroup
                active={sceneState.answers[index].elementSize}
                onChange={(v) =>
                  updateAnswer({
                    variables: {
                      ...sceneState.answers[index],
                      elementSize: v,
                    },
                  })
                }
              />
            </div>
          </>
        )

      case 'DIRECTION':
        if (type === 'QUESTION') return null
        return (
          <>
            {getSettingsToggle(
              'Hide label',
              `direction-${index}`,
              sceneState.answers[index]?.hideInVr,
              () => {
                updateAnswer({
                  variables: {
                    ...sceneState.answers[index],
                    hideInVr: !sceneState.answers[index].hideInVr,
                  },
                })
              }
            )}
            <div className="flex-container align-middle align-justify c-video__editor--elements--settings--row w-100">
              <div>Label size</div>
              <ElementSizeButtonGroup
                active={sceneState.answers[index].elementSize}
                onChange={(v) =>
                  updateAnswer({
                    variables: {
                      ...sceneState.answers[index],
                      elementSize: v,
                    },
                  })
                }
              />
            </div>
          </>
        )
      case 'INFORMATION':
        return (
          <div className="flex-container align-middle align-justify">
            <div>Information and answer size</div>
            <ElementSizeButtonGroup
              active={sceneState.elementSize}
              onChange={(v) =>
                updateScene({
                  variables: {
                    ...sceneState,
                    elementSize: v,
                  },
                })
              }
            />
          </div>
        )
      case 'MPC':
        return (
          <>
            <div className="flex-container align-middle align-justify c-video__editor--elements--settings--row w-100">
              <div>Question size</div>
              <ElementSizeButtonGroup
                active={sceneState.elementSize}
                onChange={(v) =>
                  updateScene({
                    variables: {
                      ...sceneState,
                      elementSize: v,
                    },
                  })
                }
              />
            </div>
            <div className="flex-container align-middle align-justify">
              <div>Answer size</div>
              <ElementSizeButtonGroup
                active={sceneState.answerElementSize}
                onChange={(v) =>
                  updateScene({
                    variables: {
                      ...sceneState,
                      answerElementSize: v,
                    },
                  })
                }
              />
            </div>
          </>
        )
      default:
        return null
    }
  }

  const getLinkTo = (id) => {
    if (!id)
      return (
        <span
          className="ml-1 text-italic"
          style={{ display: 'inline-flex', alignItems: 'center' }}>
          Missing link
        </span>
      )

    const linked = scenes.find((s) => s.id === id)
    if (!linked)
      return (
        <p className="text-normal text-dark mb-0">
          Link to
          <span className="text-stable-dark text-normal">
            <span className="ml-1">
              <img src={getIconByKind('ENDING')} />
            </span>
          </span>
        </p>
      )

    return (
      <p className="text-normal text-dark mb-0">
        Link to
        <span className="text-stable-dark text-normal">
          <DataTooltip delay={300} position="bottom" title={`${linked.name}`}>
            <span
              className="cursor-pointer"
              onClick={() => {
                window.Turbolinks.visit(
                  `/scenarios/${scenarioId}/cards/${id}/editor`
                )
              }}>
              <span
                className="ml-1 h-100"
                style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span className="scene-icon--default hoverable">
                  <span>{linked.number}</span>
                </span>
                <FaChevronRight className="text-dark" />
              </span>
            </span>
          </DataTooltip>
        </span>
      </p>
    )
  }

  const submitAnswer = (e, i) => {
    e.preventDefault()
    if (validateNotEmpty(editRef.current.value)) {
      updateAnswer({
        variables: {
          ...sceneState.answers[i],
          name: editRef.current.value,
        },
      })
      setIsEditingAnswerRow(null)
    }
  }
  const getAnswerRows = () => {
    return sceneState.answers.map((answer, i) => (
      <div
        key={answer.id}
        className="c-video__editor--elements--card--answer-row">
        <div className="cell grid-x">
          <div
            id={`element-${sceneState.kind}-${sceneState.id}`}
            style={{ display: 'inline-flex', alignItems: 'center' }}
            className="cell auto">
            {getAnswerIcon()}
            <h3 className="mb-0">Answer</h3>{' '}
            <span
              className="edit-button"
              onClick={(e) => toggleEditAnswerRow(e, i)}>
              <FaPencilAlt
                className="cursor-pointer"
                style={{ marginRight: '4px' }}
              />{' '}
              Edit
            </span>
          </div>
          {answer.linkToId && (
            <div className="cell shrink">{getLinkTo(answer.linkToId)}</div>
          )}
        </div>
        {isEditingAnswerRow === i ? (
          <form className="m-0" onSubmit={(e) => submitAnswer(e, i)}>
            <div className="cell pt-2">
              <input
                type="text"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    submitAnswer(e, i)
                  }
                }}
                name="edit-answer-row"
                id="edit-answer-row"
                defaultValue={answer.name}
                ref={editRef}
              />
            </div>

            <div
              className="cell grid-x align-right"
              style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div className="cell auto text-assertive text-normal">
                {warningMessage && (
                  <>
                    <FaExclamationTriangle className="mr-1" /> {warningMessage}
                  </>
                )}
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setIsEditingAnswerRow(false)
                  setWarningMessage(null)
                }}
                className="cell mb-0 button secondary hollow small small-2">
                Cancel
              </button>
              <button
                type="submit"
                className="cell ml-1 mb-0 button primary small small-2">
                Confirm
              </button>
            </div>
          </form>
        ) : (
          <div
            className="text-normal pt-1 cursor-pointer"
            onClick={(e) => toggleEditAnswerRow(e, i)}>
            {answer.name}
          </div>
        )}
      </div>
    ))
  }
  const getTitle = () => {
    const getTitleByKind = () => {
      if (sceneState.kind === 'MPC') return 'Answer'
      if (sceneState.kind === 'TRANSITION') return 'Auto transition'
      return capitalized(sceneState.kind)
    }
    let content = {}
    switch (type) {
      case 'QUESTION':
        content = {
          title: 'Information',
          icon: <FaCommentAlt className="mr-1" />,
        }
        break
      case 'ANSWER':
        content = {
          title: getTitleByKind(),
          icon: getAnswerIcon(),
          linkToId: sceneState.answers[index].linkToId,
        }
        break
      default:
        return null
    }
    return (
      <div className="cell grid-x">
        <div
          id={`${elementId}--header`}
          className="cell auto c-video__editor--elements--heading"
          style={{ display: 'flex', width: '100%' }}>
          {content.icon}
          <h3 className="mb-0">{content.title}</h3>

          {sceneState.kind !== 'TRANSITION' && (
            <>
              <span
                className="map-marker"
                onClick={() => moveCameraToElement(`#${videoElementId}`)}>
                <DataTooltip title="Show element in the video">
                  <FaMapMarker className="cursor-pointer" />
                </DataTooltip>
              </span>
              <span
                className="edit-button"
                onClick={(e) => toggleIsEditingQuestion(e, true)}>
                <FaPencilAlt
                  className="cursor-pointer"
                  style={{ marginRight: '4px' }}
                />{' '}
                Edit
              </span>
            </>
          )}
        </div>
        {content.linkToId && (
          <div className="cell shrink">{getLinkTo(content.linkToId)}</div>
        )}
      </div>
    )
  }
  const getContent = () => {
    if (sceneState.kind === 'TRANSITION') return null
    if (type === 'QUESTION') {
      return isEditingQuestion ? (
        editField(sceneState.question)
      ) : (
        <div
          className="text-normal pt-2 cursor-pointer"
          onClick={(e) => toggleIsEditingQuestion(e, true)}>
          {sceneState.question || (
            <span className="text-stable">Element is hidden</span>
          )}
        </div>
      )
    }
    if (type === 'ANSWER') {
      return isEditingQuestion ? (
        editField(sceneState.answers[index].name)
      ) : (
        <div
          className="text-normal pt-2"
          onClick={(e) => toggleIsEditingQuestion(e, true)}>
          {sceneState.answers[index].name}
        </div>
      )
    }
    return null
  }
  if (['MPC', 'INFORMATION'].includes(sceneState.kind) && type === 'ANSWER')
    return null

  const getSaveButton = () => {
    const save = () => {
      const element = document.querySelector(`#${videoElementId}`)
      const rotation = element.parentNode.getAttribute('rotation')

      if (type === 'ANSWER') {
        let { kind } = sceneState
        if (kind === 'HOTSPOT') {
          kind = `${kind}_${sceneState.answers[index].hotspotSize}`
        }
        updateAnswer({
          variables: {
            ...sceneState.answers[index],
            anchorX: -rotation.y,
            anchorY: rotation.x,
          },
        })
      } else {
        updateScene({
          variables: {
            ...sceneState,
            anchorX: -rotation.y,
            anchorY: rotation.x,
          },
        })
      }
    }
    return (
      <button
        id={`${elementId}--save-button`}
        style={{ display: 'none' }}
        onClick={() => save()}></button>
    )
  }

  const getFooter = () => {
    // INFORMATION and TRANSITION Answers don't have settings to apply
    // return no footer
    if (
      type === 'ANSWER' &&
      ['INFORMATION', 'TRANSITION'].includes(sceneState.kind)
    ) {
      return null
    }
    return (
      <>
        <div className="c-video__editor--elements--card--footer">
          {type === 'QUESTION' && (
            <>
              {getSettingsToggle(
                'Show in look direction',
                'toggleLookDirection',
                sceneState.showQuestionInLookDirection,
                () =>
                  updateScene({
                    variables: {
                      ...sceneState,
                      showQuestionInLookDirection:
                        !sceneState.showQuestionInLookDirection,
                    },
                  }),
                'Position this element where the trainee enters the scene'
              )}
              {['HOTSPOT'].includes(sceneState.kind) && (
                <div className="flex-container align-middle align-justify c-video__editor--elements--settings--row w-100">
                  <div>Information size</div>
                  <ElementSizeButtonGroup
                    active={sceneState.elementSize}
                    onChange={(v) =>
                      updateScene({
                        variables: {
                          ...sceneState,
                          elementSize: v,
                        },
                      })
                    }
                  />
                </div>
              )}
            </>
          )}
          {getSettings()}
        </div>
      </>
    )
  }

  return (
    <div id={elementId} className="c-video__editor--elements--container">
      <div
        id={`${elementId}--card`}
        className="c-video__editor--elements--card">
        <div className="c-video__editor--elements--card--content">
          {getTitle()}
          {getContent()}
          {getSaveButton()}
        </div>
        {['MPC', 'INFORMATION'].includes(sceneState.kind) && (
          <div className="c-video__editor--elements--card--answer-wrapper">
            {getAnswerRows()}
          </div>
        )}
        {getFooter()}
      </div>
    </div>
  )
}
export default ElementCard
