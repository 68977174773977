import { gql, useQuery } from '@apollo/client'

const getMembersQuery = gql`
  query listMembers {
    members: users(role: MEMBER) {
      id
      name
      email
      role
      otpConfigured
    }
  }
`

const useMembers = (opt) => {
  const { data, loading } = useQuery(getMembersQuery, opt)

  if (opt.skip) return { data: null }
  if (loading) return { data: null, loading: true }

  return { data: data.members, loading: false }
}

export default useMembers
