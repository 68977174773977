import React from 'react'
import Modal from '../UI/Modal'

const CredentialDeleteModal = ({ id, appendTo, onDelete, onClose }) => {
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this passkey?`}
      subtitle="You will no longer be able to use it to sign-in to your account."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      submitHandler={onDelete}
      onClose={onClose}
    />
  )
}
export default CredentialDeleteModal
