import React from 'react'
import { FaUsers } from 'react-icons/fa'

const GroupsEmptyState = () => {
  return (
    <div
      data-testid="empty-group-wrapper"
      className="o-table--page-container grid-container fluid">
      <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
        <span className="text-center">
          <span className="o-table__empty--icon">
            <FaUsers />
          </span>
          <h2 className="text-bold text-dark">No groups yet!</h2>
          <span className="text-stable-dark">
            Start by{' '}
            <span
              data-testid="link"
              className="text-underline text-assertive cursor-pointer"
              data-open="add-group-modal">
              creating a group
            </span>
          </span>
        </span>
      </div>
    </div>
  )
}

export default GroupsEmptyState
