import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../UI/Modal'
import SearchableDropdown from '../../../UI/Menu/SearchableDropdown'
import { useReactFlow } from 'reactflow'
import { setCentralNodeAndSelectIt } from '../../helpers/nodeHelper'
import HomeLabel from './elements/HomeLabel'
import SceneNumberLabel from './elements/SceneNumberLabel'

const SearchModal = () => {
  const reactFlow = useReactFlow()
  const nodes = reactFlow.getNodes()
  const inputRef = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus()
    }
  }, [show, inputRef.current])

  const data = nodes.map((node) => ({
    render: (
      <div className="text-normal text-bold flex-container align-middle">
        {node.data.start && <HomeLabel className="mr-0-5" />}
        <SceneNumberLabel number={node.data.number} className="mr-1" />
        {node.data.name}
      </div>
    ),
    value: `${node.data.number} ${node.data.name}`,
    id: node.id,
  }))

  return (
    <Modal
      id="flow-search-modal"
      appendTo="#flow-container"
      width={600}
      closable={false}
      closeOnEscape
      onOpen={() => {
        setShow(true)
      }}
      onClose={() => setShow(false)}>
      <SearchableDropdown
        placeholder="Search on scene number or title..."
        data={data}
        show={show}
        style={{ width: '600px' }}
        minLength={1}
        position=""
        hideHandler={() => $('#flow-search-modal').foundation('close')}
        keyboardNavigation
        ref={inputRef}
        clickHandler={(node) => {
          $('#flow-search-modal').foundation('close')
          setCentralNodeAndSelectIt(
            reactFlow,
            nodes.find((n) => n.id === node.id),
            1
          )
        }}
        sortOnSearchKey="value"
      />
    </Modal>
  )
}

export default SearchModal
