import React, { useContext } from 'react'
import { FaMapPin } from 'react-icons/fa'
import FilmingLocationsDropdown from './FilmingLocationsDropdown'
import { updateSceneFilmingMutation } from '../../../../apollo/query/scenes'
import { useMutation } from '@apollo/client'
import { handleApolloError } from '../../../../utils/errors'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import ManageLocationsModal from '../Filming/ManageLocationsModal'
import { setNode } from '../../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'
import useSelectedNode from '../../hooks/useSelectedNode'
import FilmingScenesRow from './FilmingScenesRow'

const FilmingTab = () => {
  const selectedNode = useSelectedNode()
  const scene = selectedNode.data
  const { refetch } = useContext(ScenarioEditorContext)
  const reactFlow = useReactFlow()
  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const handleUpdateFilming = () => {
    const newData = {
      useSameVideoAsId: scene.filming ? null : scene.useSameVideoAsId,
      filming: !scene.filming,
    }
    updateSceneFilming({
      variables: {
        id: scene.id,
        cameraLocationId: scene.filming ? scene.cameraLocation?.id : null,
        ...newData,
      },
    })
    setNode(reactFlow, {
      ...selectedNode,
      data: {
        ...scene,
        ...newData,
        cameraLocation: scene.filming ? scene.cameraLocation : null,
      },
    })
  }

  return (
    <div className="p-3">
      <div className="border-light border-radius text-normal">
        <header className="pl-2 pr-2 pt-1-5 pb-1-5 flex-container align-middle align-justify border-light-bottom">
          <h3 className="text-bold flex-container align-middle mb-0 text-normal">
            <FaMapPin className="mr-1" />
            Filming location
          </h3>
          <button
            className="o-button o-button--light o-button--auto-width o-button--auto-height text-bold text-normal"
            data-open="manage-locations-tab-modal">
            Manage all
          </button>
        </header>
        <div className="pl-2 pr-2 pb-1-5 pt-1-5 flex-container align-middle align-justify border-light-bottom">
          Does scene need filming?
          <div className="o-switch">
            <input
              type="checkbox"
              data-testid="ending-input"
              name="linkToId"
              checked={scene.filming || false}
              readOnly
              id={`filming-${scene.id}`}
              className="o-switch__input"
            />
            <label
              onClick={handleUpdateFilming}
              htmlFor={`filming-${scene.id}`}
              className="o-switch__paddle"></label>
          </div>
        </div>

        {scene.filming ? (
          <FilmingLocationsDropdown />
        ) : (
          <FilmingScenesRow />
        )}
      </div>

      <ManageLocationsModal
        appendTo="#flow-container"
        id="manage-locations-tab-modal"
        deleteModalId="delete-location-tab-modal"
        onDeleteLocation={refetch}
        onUpdateLocation={refetch}
      />
    </div>
  )
}
export default FilmingTab
