import React, { useState, useEffect } from 'react'
import Checkbox from '../UI/Form/Checkbox'

const GroupRestrictAccess = ({
  group,
  values,
  setValues,
  restrictedAccess,
  setRestrictedAccess,
}) => {
  const [showErrorMessages, setShowErrorMessages] = useState(false)
  const [invalidFields, setInvalidFields] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const verifyDates = (start, end) => {
    if (!start && end) {
      setErrorMessage('Please enter a start date')
      setInvalidFields(['access-from'])
      return false
    }
    if (start && !end) {
      setErrorMessage('Please enter a end date')
      setInvalidFields(['access-to'])
      return false
    }
    if (new Date(end).getTime() / 1000 < new Date(start).getTime() / 1000) {
      setErrorMessage('End date cannot be before start date')
      setInvalidFields(['access-from', 'access-to'])
      return false
    }
    setInvalidFields([])
    return true
  }

  useEffect(() => {
    const isValid = verifyDates(values.startDate, values.endDate)
    setShowErrorMessages(!isValid)
  }, [values])

  const showFields = () => {
    const fields = [
      {
        id: 'access-from',
        changeHandler: ({ target }) => {
          if (!values.endDate) {
            setValues({
              ...values,
              startDate: target.value,
              endDate: target.value,
            })
          } else {
            setValues({ ...values, startDate: target.value })
          }
        },
        text: 'Give trainees access from',
        defaultValue: group ? group.startDate : values.startDate,
      },
      {
        id: 'access-to',
        changeHandler: ({ target }) => {
          setValues({ ...values, endDate: target.value })
        },
        text: 'Access until',
        defaultValue: group ? group.endDate : values.endDate,
      },
    ]

    return fields.map((f) => (
      <div key={f.id} className="cell small-6">
        <label htmlFor={f.id} className="c-form__label--required">
          {f.text}
        </label>
        <input
          required
          className={
            invalidFields.includes(f.id) ? 'input--required--force' : ''
          }
          type="date"
          name={f.id}
          defaultValue={f.defaultValue}
          id={f.id}
          onChange={f.changeHandler}
        />
      </div>
    ))
  }
  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12">
        <label>Group options</label>
        <Checkbox
          checked={restrictedAccess}
          clickHandler={() => setRestrictedAccess(!restrictedAccess)}
          value="Set access period"
        />
      </div>
      {restrictedAccess && showFields()}
      {restrictedAccess && showErrorMessages && (
        <small className="cell small-12 text-assertive">{errorMessage}</small>
      )}
    </div>
  )
}

export default GroupRestrictAccess
