import React, { useEffect, useRef, useState } from 'react'
import { useReactFlow } from 'reactflow'
import TransparentInput from './TransparentInput'
import { setNode } from '../../helpers/nodeHelper'
import useAddUpdateScene from '../../hooks/useAddUpdateScene'

const MAX_HEIGHT = 135
const MAX_MORE_HEIGHT = 2000
const MIN_HEIGHT = 58

const DescriptionField = ({ scene, selectedNode }) => {
  const reactFlow = useReactFlow()
  const [showMoreDesc, setShowMoreDesc] = useState()
  const [heightOver, setHeightOver] = useState()
  const [value, setValue] = useState('')
  const inputRef = useRef()
  const [focused, setFocused] = useState(false)
  const { updateSceneDetails } = useAddUpdateScene()

  const getScrollHeight = () => inputRef.current?.scrollHeight

  useEffect(() => {
    if (selectedNode) setValue(selectedNode.data.description)
  }, [selectedNode])

  useEffect(() => {
    if (!focused) {
      setTimeout(() => {
        // calculate height after ending of transition
        setHeightOver(getScrollHeight() > MAX_HEIGHT + 10)
      }, 100)
    }
  }, [focused, scene])

  if (!selectedNode) return null

  const handleChangeDescription = ({ target: { value } }) => {
    const { id, description } = selectedNode.data
    if (value !== description) {
      updateNodeDescription(value)
      updateSceneDetails({
        id,
        description: value,
      })
    }
  }

  const updateNodeDescription = (value) => {
    setNode(reactFlow, {
      ...selectedNode,
      data: { ...selectedNode.data, description: value },
    })
  }

  const calculateMaxHeight = () => {
    if (showMoreDesc) return `${MAX_MORE_HEIGHT}px`
    if (focused) return 'none'
    return `${value ? MAX_HEIGHT : MIN_HEIGHT}px`
  }

  return (
    <>
      <div
        style={{
          maxHeight: calculateMaxHeight(),
          overflow: 'hidden',
          transition: 'all .2s',
          height: 'auto',
        }}>
        <TransparentInput
          id={`description-${selectedNode.id}`}
          name="label"
          type="contentEditable"
          placeholder="Add actors, filming locations or anything else that will describe this scene. This information is never shown to trainees."
          onConfirm={handleChangeDescription}
          value={value}
          maxLength="500"
          ref={inputRef}
          focusedMinHeight={102}
          multiline
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() =>
            setTimeout(() => {
              setFocused(false)
              setShowMoreDesc(false)
            }, 300)
          }
        />
      </div>
      {heightOver && !focused && (
        <button
          className="cursor-pointer text-underline text-stable-dark text-small mt-1 pl-1 bg-transparent tracking-tighter"
          onClick={() => setShowMoreDesc(!showMoreDesc)}>
          {showMoreDesc ? 'Show less' : 'Show more'}
        </button>
      )}
    </>
  )
}

export default DescriptionField
