import React, { Component } from 'react'
import { runInAction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { DraggableCore } from 'react-draggable'
import Scene from './card/scene'
import Ending from './card/ending'

@inject('diagramStore')
@observer
class Card extends Component {
  startDrag = (_, dragInfo) => {
    const { card } = this.props
    card.startDrag(dragInfo)
    card.isDragging = true
  }

  handleDrag = (_, dragInfo) => {
    const { card } = this.props
    card.showMoreMenu = false

    runInAction('move card', () => {
      card.handleDrag(dragInfo)
    })
  }

  stopDrag = (e, dragInfo) => {
    const { card } = this.props
    card.stopDrag(e, dragInfo)
    card.isDragging = false
  }

  render() {
    const { card } = this.props
    return (
      <DraggableCore
        onDrag={this.handleDrag}
        onStart={this.startDrag}
        grid={[5, 5]}
        bounds="parent"
        disabled={card.store.selection.isSpaceDragging}
        position={{ x: card.x, y: card.y }}
        onStop={this.stopDrag}
        cancel=".o-action-list">
        <div
          style={{
            width: card.cardWidth,
            height: card.cardHeight,
            top: card.y,
            left: card.x,
            display: 'block',
            float: 'left',
            position: 'absolute',
          }}
          className={`draggable-scene${
            card.isHighlighted() ? ' react-draggable-drop-on-active' : ''
          }${card.shouldDrawLine ? ' react-draggable-dragging' : ''}${
            card.isDragging ? ' is-dragging' : ''
          }`}>
          {card.type === 'scene' && <Scene scene={card} />}
          {card.type === 'ending' && <Ending ending={card} />}
        </div>
      </DraggableCore>
    )
  }
}

export default Card
