// Component to replace any onclick="" attributes with data-* attributes
export const init = () => {
  $('[data-close-window]').on('click', function() {
    window.close();
  });

  $('[data-open-window]').on('click', function() {
    const location = $(this).attr('data-open-window');
    window.open(location, 'TestScenario');
  });

  $('[data-reload]').on('click', function() {
    window.location.reload();
  });

  $('[data-flip]').on('click', function() {
    window.diagramStore.flipAllCards();
  });

  $('[data-home]').on('click', function() {
    window.diagramStore.scrollToHome();
  });
}
