import React from 'react'
import { default as S } from '../../../utils/lang/en.json'

const ProductState = ({ state, hasImage }) => {
  let className

  switch (state) {
    case 'INCOMPLETE':
      className = 'royal'
      break
    case 'PREPARED':
      className = 'balanced'
      break
    case 'READY':
      className = 'energized'
      break
    case 'ARCHIVED':
      className = 'stable'
      break
    default:
      return null
  }
  if (!hasImage) className = 'royal'

  return (
    <label className={`o-label--status o-label--${className}`}>
      {hasImage
        ? S.store.products.state[state]
        : S.store.products.state.INCOMPLETE}
    </label>
  )
}
export default ProductState
