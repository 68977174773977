/**
 * Select tab on click
 */

export function init() {
  $("[data-tabs-select]").on("click", function(e) {
    //e.preventDefault(); do not preventDefault, because of clickable <label>

    // get targetted tab
    var target = $(this).attr("data-tabs-select");

    // get wrapping element for tab (id of tabs component)
    var tabs = $(target)
      .closest("[data-tabs-content]")
      .attr("data-tabs-content");

    // Strip off first character (is a '#')
    target = target.slice(1);

    // select the tab
    $("#" + tabs).foundation("selectTab", target);
  });

  $("[data-ending]").on("click", function(e) {
    // prevent double clicks adding multiple endings
    $(this).off('click');

    // add ending to react component
    window.diagramStore.addEnding(
      $("#card_x").val(),
      $("#card_y").val(),
      $("#incoming_answer_id").val(),
      $("#incoming_answer_randomized_index").val()
    );
  });
}
