import { gql } from '@apollo/client'

const getCurrentClientQuery = gql`
  query currentClient {
    currentClient {
      id
      name
      appTitle
      appDescription
    }
  }
`
const getClientsNamesQuery = gql`
  query clients {
    clients {
      id
      name
    }
  }
`

const getClientsQuery = gql`
  query clients {
    clients {
      id
      name
      createdAt
      lastActivityAt
      features
      workspaceKind
      workspaceManagedByClientName
      planBillingPeriod
      planAdditionalPublishedScenarios
      planAdditionalWorkspaces
      statistics {
        traineeCount
        memberCount
        enrolledTraineeCount
        draftScenarioCount
        publishedScenarioCount
        testScenarioCount
        suspendedScenarioCount
        originalSceneCount
        publishedSceneCount
        groupCount
        courseCount
        attemptCount
        lastMonthAttemptCount
        testAttemptCount
        lastMonthTestAttemptCount
        last3mActiveTraineeCount
        mediumCount
        originalStorageByteSize
        processedStorageByteSize
        publishedStorageByteSize
        lastMonthDraftSceneCount
        lastWeekDraftSceneCount
        lastMonthPublishedScenarioCount
        lastMonthMediumCount
        lastWeekActiveMemberCount
        lastMonthActiveMemberCount
      }
    }
  }
`

const getCurrentClientBillingQuery = gql`
  query currentClientBilling {
    currentClient {
      billingFirstName
      billingLastName
      billingEmail
      billingReference
      billingName
      billingStreet
      billingPostalcode
      billingCity
      billingCountry
      billingVatNumber
    }
  }
`

const updateClientBrandingMutation = gql`
  mutation updateClientBranding(
    $appTitle: String
    $appDescription: String
    $primaryColor: String
    $secondaryColor: String
    $logoVrBlobId: String
    $logoMobileBlobId: String
  ) {
    updateClientBranding(
      appTitle: $appTitle
      appDescription: $appDescription
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      logoVrBlobId: $logoVrBlobId
      logoMobileBlobId: $logoMobileBlobId
    ) {
      client {
        id
        appTitle
        appDescription
        primaryColor
        secondaryColor
        locale
        emailIntro
      }
    }
  }
`

const updateClientEmailsMutation = gql`
  mutation updateClientEmails($locale: Locale!, $emailIntro: String!) {
    updateClientEmails(locale: $locale, emailIntro: $emailIntro) {
      client {
        appTitle
        appDescription
        primaryColor
        secondaryColor
        logoVrUrl
        logoMobileUrl
        locale
        emailIntro
      }
    }
  }
`

const updateClientPlanMutation = gql`
  mutation updateClientPlan(
    $additionalPublishedScenarios: Int!
    $additionalWorkspaces: Int!
  ) {
    updateClientPlan(
      additionalPublishedScenarios: $additionalPublishedScenarios
      additionalWorkspaces: $additionalWorkspaces
    ) {
      client {
        planAdditionalWorkspaces
        planAdditionalPublishedScenarios
      }
    }
  }
`

const updateClientBillingMutation = gql`
  mutation updateClientBilling(
    $billingFirstName: String!
    $billingLastName: String!
    $billingEmail: String!
    $billingName: String!
    $billingStreet: String!
    $billingPostalcode: String!
    $billingCity: String!
    $billingCountry: String!
    $billingVatNumber: String!
    $billingReference: String!
  ) {
    updateClientBilling(
      billingFirstName: $billingFirstName
      billingLastName: $billingLastName
      billingEmail: $billingEmail
      billingName: $billingName
      billingStreet: $billingStreet
      billingPostalcode: $billingPostalcode
      billingCity: $billingCity
      billingCountry: $billingCountry
      billingVatNumber: $billingVatNumber
      billingReference: $billingReference
    ) {
      client {
        billingFirstName
        billingLastName
        billingEmail
        billingName
        billingStreet
        billingPostalcode
        billingCity
        billingCountry
        billingVatNumber
        billingReference
      }
    }
  }
`

const getBuyingClientsQuery = gql`
  query getBuyingClients {
    clients: buyingClients {
      id
      name
    }
  }
`

const switchClientMutation = gql`
  mutation switchClient($id: ID!) {
    switchClient(id: $id) {
      client {
        id
        name
      }
    }
  }
`

const updateClientSettingsMutation = gql`
  mutation updateClientSettings($settings: [Setting!]!) {
    updateClientSettings(settings: $settings) {
      client {
        id
        name
        settings
      }
    }
  }
`

export {
  getCurrentClientQuery,
  getClientsQuery,
  getClientsNamesQuery,
  getCurrentClientBillingQuery,
  getBuyingClientsQuery,
  updateClientBrandingMutation,
  updateClientEmailsMutation,
  updateClientPlanMutation,
  updateClientBillingMutation,
  switchClientMutation,
  updateClientSettingsMutation,
}
