import React, { useContext } from 'react'
import LanguageContext from '../../../utils/context/LanguageContext'
import SignInModal from '../Modal/SignInModal'

const ScenarioSignIn = ({ client, scenario, security, buttonColor }) => {
  const { translations: t } = useContext(LanguageContext)
  return (
    <div id="scenariosignin" className="grid-container fluid ">
      <SignInModal
        id="signinmodal"
        domain={security.domain}
        appendTo="#scenarioCourseInfo"
      />
      <div className="grid-x c-launcher__page--container">
        <div
          className={`grid-y cell medium-5 large-6 small-order-2 medium-order-1`}>
          <div className="cell c-launcher__company--container">
            <div
              className="c-launcher__company-icon c-launcher__company-icon--large-round"
              style={{ backgroundImage: `url("${client.imageUrl}")` }}
            />
            <h2 className="text-bold mb-0">{client.name}</h2>
          </div>
          <div className="cell">
            <h1 className="c-launcher__page--banner">{scenario.name}</h1>
          </div>
          <div className="cell mt-3 mb-3">
            <p>
              {scenario.description.slice(0, 250)}
              {scenario.description.length > 250 && (
                <>
                  ...
                  <br />
                  <u
                    className="cursor-pointer"
                    onClick={() => {
                      const element = document.querySelector('#about')
                      element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }}>
                    {t.readMore}
                  </u>
                </>
              )}
            </p>
          </div>
          <div className="cell">
            <button
              onClick={() => $('#signinmodal').foundation('open')}
              className="c-launcher__sign-in--button small-12 text-bold button mb-1"
              style={{
                color: buttonColor,
                backgroundColor: client.primaryColor,
              }}>
              {t.buttonValues.scenario}
            </button>
          </div>
        </div>
        <div
          className={`cell h-100 medium-6 large-5 medium-offset-1 c-launcher__company--image--container small-order-1 medium-order-2`}>
          <div
            className="c-launcher__company--image"
            style={{
              backgroundImage: `url("${scenario.imageUrl}")`,
            }}></div>
        </div>
      </div>
    </div>
  )
}

export default ScenarioSignIn
