import React from 'react'
import { FaRandom } from 'react-icons/fa'
import LinkElement from './LinkElement'
import { useEdges } from 'reactflow'

const RandomizerLinks = ({
  index,
  allSourceHandles,
  nodeData,
  element,
  parentIndex,
  childIndex,
  isExport,
  sceneId,
}) => {
  const edges = useEdges()
  const sourceHandle = allSourceHandles?.find(
    (h) => h.id === `e${nodeData.id}-${element.id}-${index}`
  )
  const sourceHandleIsConnected = !!edges?.find(
    (e) => e.sourceHandle === `e${nodeData.id}-${element.id}-${index}`
  )

  return (
    <div
      className={`flex-container align-middle align-justify text-stable-dark 
        ${isExport ? 'pb-1 pl-1-5 pr-1-5 ml-2' : 'pl-2 ml-2-5 pr-2-5 pb-1-5'}`}>
      <div className="flex-container align-middle">
        <span className="o-scene__elements--randomizer-icon" />
        <FaRandom className="o-scene__elements--icon text-bold" />
        <h4 className="text-normal text-bold mb-0 ml-1">Random</h4>
      </div>
      <LinkElement
        sourceHandle={sourceHandle}
        isConnected={sourceHandleIsConnected}
        parentIndex={parentIndex}
        childIndex={childIndex}
        element={{ ...element, linkToId: sceneId }}
        isExport={isExport}
        isRandomizer
      />
    </div>
  )
}

export default RandomizerLinks
