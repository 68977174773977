import React from 'react'
import { FaCheck, FaCopy } from 'react-icons/fa'

const Copy = ({ value }) => {
  return (
    <div className="o-button__copy--container">
      <input
        type="radio"
        name="o-button-copy"
        onChange={() => {
          navigator.clipboard.writeText(value)
        }}
      />
      <button className="button hollow secondary mr-2">
        <FaCopy />
        <span className="ml-1">Copy</span>
      </button>

      <button className="button hollow success">
        <FaCheck className="text-success" />
        <span className="ml-1">Copied!</span>
      </button>
    </div>
  )
}

export default Copy
