import React, { useContext } from 'react'
import LanguageContext from '../../utils/context/LanguageContext'

const FAQ = () => {
  const { translations: t } = useContext(LanguageContext)

  const createFAQs = () => {
    const FAQs = t.sections.FAQ || []
    return FAQs.map((item, i) => (
      <div className="cell small-12 medium-6 c-launcher__FAQ--box" key={i}>
        <h4 className="c-launcher__FAQ--heading">{item.question}</h4>
        <p>{item.answer}</p>
      </div>
    ))
  }

  const { titles } = t.sections
  return (
    <section id="faq" className="grid-container fluid">
      <h2 className="c-launcher__page--heading">{titles.faq}</h2>
      <div className="no-margin c-launcher__FAQ--container">
        <div className="grid-x grid-x-padding">{createFAQs()}</div>
      </div>
    </section>
  )
}

export default FAQ
