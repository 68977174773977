import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { getCurrentClientSettingsQuery } from '../../apollo/query/settings'
import ManagedByClientPage from '../../components/Settings/ManagedByPage'
import BillingTab from '../../components/Settings/Subscription/BillingTab'
import SubscriptionTab from '../../components/Settings/Subscription/SubscriptionTab'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { TabContent, TabTitles } from '../../components/UI/Tabs/Tabs'
import withApollo from '../../hooks/withApollo'
import Delay from '../../utils/helpers/Delay'
import useCurrentUser from '../../hooks/useCurrentUser'

const Subscription = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { loading, data, error } = useQuery(getCurrentClientSettingsQuery)
  const [, , currentRole] = useCurrentUser()

  if (loading || !currentRole) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)
  const { currentClient } = data

  if (!currentClient.plan || !(currentRole == 'OWNER' || currentRole == 'MANAGER'))
    return (
      <ManagedByClientPage
        workspaceManagedByClientName={
          currentClient.workspaceManagedByClientName
        }
      />
    )

  const titles = ['Subscription', 'Billing details']

  const content = [
    <SubscriptionTab key="subscription-tab" currentClient={currentClient} />,
    <BillingTab key="billing-tab" />,
  ]

  return (
    <div id="subscription-settings" className="grid-container fluid row">
      <div className="columns small-12 large-3 xxlarge-2 pl-0">
        <TabTitles
          titles={titles}
          activeTab={activeTab}
          selectHandler={setActiveTab}
        />
      </div>
      <div className="columns">
        <TabContent titles={titles} content={content} activeTab={activeTab} />
      </div>
    </div>
  )
}

export default withApollo(Subscription)
