import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'
import DataTooltip from '../UI/DataTooltip'

const GroupOptionDropdown = ({
  id,
  copyLinkHandler,
  publicLinkEnabled,
  isPublicLinkModalDisabled,
  setCurrentGroup,
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const showPublicLinkField = () => {
    let cn = 'o-dropdown__list-item text-normal text-dark'
    if (isCopied) cn += ' bg-success'

    return (
      <li
        className={cn}
        onClick={() => {
          copyLinkHandler()
          setIsCopied(true)
          setTimeout(() => setIsCopied(false), 2000)
        }}>
        <span>
          Copy public link
          {isCopied && (
            <label className="o-label--item__disabled">COPIED</label>
          )}
        </span>
      </li>
    )
  }

  if (isHovered) {
    return (
      // Dropdown
      <Dropdown
        id={`group-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`group-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <li
          className="o-dropdown__list-item text-normal text-dark"
          data-toggle="edit-group-modal"
          onClick={() => setCurrentGroup(id)}>
          Edit group
        </li>
        {publicLinkEnabled && showPublicLinkField()}
        <li
          className={`${
            isPublicLinkModalDisabled
              ? 'disabled text-stable-dark'
              : 'text-dark'
          } o-dropdown__list-item text-normal`}
          data-toggle={`${!isPublicLinkModalDisabled && 'link-settings-modal'}`}
          onClick={() => !isPublicLinkModalDisabled && setCurrentGroup(id)}>
          Public share settings
          {isPublicLinkModalDisabled ? (
            <small className="float-right">
              <DataTooltip
                position="right"
                title="Add a course or scenario to enable registration links">
                <label className="o-label--item__disabled">UNAVAILABLE</label>
              </DataTooltip>
            </small>
          ) : null}
        </li>
        <hr className="mb-1 mt-1"></hr>
        <li
          className="o-dropdown__list-item text-normal text-dark"
          data-open="delete-group-modal"
          onClick={() => setCurrentGroup(id)}>
          Delete group
        </li>
      </Dropdown>
    )
  }

  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`group-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}
export default GroupOptionDropdown
