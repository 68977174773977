import React, { useContext, useRef, useEffect } from 'react'
import RangeInput from '../../UI/Form/RangeInput'
import { MediumContext } from '../../Media/Modal/MediumContext'
import { convertMilliSeconds } from '../../../utils/format'
import useSelectedNode from '../../FlowV2/hooks/useSelectedNode'

const EditorTimeSlider = () => {
  const {
    controls: [controls],
    time: [time, setTime],
    currentVideoRef,
  } = useContext(MediumContext)

  const updateInterval = useRef(null)
  const selectedNode = useSelectedNode()
  const { video } = selectedNode.data

  const intervalHandler = () => {
    let currentTimeValue = currentVideoRef.current.currentTime
    if (video.trimEnabled) {
      currentTimeValue = Number(currentTimeValue) - Number(video.trimStart)
      if (currentVideoRef.current.currentTime >= video.trimEnd) {
        // reached endTimes
        currentVideoRef.current.pause()
        currentVideoRef.current.dispatchEvent(new CustomEvent('ended-on-trim'))
      }
    }
    setTime(currentTimeValue * 1000) // setTime is in ms
  }

  useEffect(() => {
    if (controls.isPlaying && video.state === 'COMPLETED') {
      updateInterval.current = setInterval(intervalHandler, 1000 / video.fps)
    }
    return () => clearInterval(updateInterval.current)
  }, [controls.isPlaying, video.id])

  useEffect(() => {
    setTime(0)
  }, [video?.id])

  if (!video) return null

  const updateSliderHandler = (value) => {
    let actualValue = value / 1000
    if (actualValue * 1000 === 0) actualValue = 0.001
    if (video.trimEnabled) {
      const startVideoTime = (
        Number(actualValue) + Number(video.trimStart)
      ).toFixed(2)
      currentVideoRef.current.currentTime = startVideoTime
      setTime(actualValue * 1000)
    } else {
      currentVideoRef.current.currentTime = Number(actualValue)
      setTime(actualValue * 1000)
    }
  }

  return (
    <div id="time-slider-editor" className="cell small-6 auto large-7 end">
      <RangeInput
        redThumb
        noBackground
        showLabelsOnHoverOnly
        formatLabel={(v) => convertMilliSeconds(v)}
        minValue={0}
        maxValue={
          video.trimEnabled
            ? (video.trimEnd - video.trimStart) * 1000 || video.duration
            : Number(video.duration)
        }
        showLabels={false}
        value={time}
        setValue={updateSliderHandler}
      />
    </div>
  )
}

export default EditorTimeSlider
