import React, { useState } from 'react'
import Filter from '../Menu/Filter'
import { FaChevronDown, FaPlus, FaTimes } from 'react-icons/fa'
import { sortOnKey } from '../../../utils/format'

const getFilterKeyByValue = (object, value) => {
  return Object.keys(object).find(
    (key) => (object[key]?.value ?? object[key]) === value
  )
}

const TableFilters = ({
  filters,
  showEmptyState,
  setShowEmptyState,
  setFilterOption,
  filterOption,
  type = 'dropdown',
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const createFilterDropdowns = () => {
    return filters.map((filter) => {
      const fo = filterOption[filter.type]
      const selected = fo
        ? typeof fo === 'object'
          ? fo.filter((s) => !!s).length
            ? fo
                .filter((s) => !!s)
                .map((f) => filter.options[f]?.value ?? filter.options[f])
            : ['All']
          : filter.options[fo] ?? 'All'
        : null

      const filterOptions = filter.options ? Object.values(filter.options) : []
      const filterContent = (
        <Filter
          text={filter.text}
          searchable={filter.searchable}
          multiple={filter.multiple}
          selected={selected?.value ?? selected}
          type={type}
          filterHandler={(fh) => {
            if (showEmptyState) setShowEmptyState(false)
            setFilterOption(
              filter === 'All'
                ? {
                    ...filterOption,
                    [filter.type]: filter.multiple ? [] : '',
                  }
                : {
                    ...filterOption,
                    [filter.type]: filter.multiple
                      ? fh.map((ff) => getFilterKeyByValue(filter.options, ff))
                      : getFilterKeyByValue(filter.options, fh),
                  }
            )
          }}
          filterOptions={
            filter.options
              ? [
                  ...(filter.hasAllOption !== false ? ['All'] : []),
                  ...(filterOptions[0]?.value
                    ? sortOnKey(filterOptions, 'value')
                    : filterOptions.sort()),
                ]
              : filterOptions
          }
          dropdownPosition={type === 'menu' ? 'right' : 'bottom'}
          dropdownAlignment={type === 'menu' ? 'top' : 'left'}
          headerText={filter.headerText}
          searchPlaceholder={filter.searchPlaceholder}
          footer={filter.footer}
        />
      )

      if (type === 'menu')
        return (
          <React.Fragment key={filter.text}> {filterContent}</React.Fragment>
        )

      return (
        <span key={filter.text} className="cell shrink large-order-2 mr-1">
          {filterContent}
        </span>
      )
    })
  }

  if (type !== 'menu') return <>{createFilterDropdowns()}</>

  const removeFilter = (filter) => {
    setFilterOption({
      ...filterOption,
      [filter.type]: null,
    })
  }

  const renderSelectedFilters = () => {
    if (!filters?.length) return <></>
    return Object.entries(filterOption).map(([key, _value]) => {
      const value = typeof _value === 'object' ? _value[0] : _value

      if (!value) return <React.Fragment key={key}></React.Fragment>

      const filter = filters.find((f) => f.type === key)
      if (!filter) return <React.Fragment key={key}></React.Fragment>

      const filterItem = filter.options[value]

      return (
        <div
          key={key}
          className="pl-1-5 pr-1-5 pt-1 pb-1 bg-gray-background border-radius text-normal mr-1 flex-container align-middle mb-1">
          <span>
            {filter.text} <span className="text-stable-dark">is</span>{' '}
            {filterItem?.render ?? filterItem}
            {typeof _value === 'object' && _value.length > 1 ? (
              <span className="text-stable-dark">
                {' '}
                and {_value.length - 1} more
              </span>
            ) : (
              ''
            )}
          </span>
          <FaTimes
            className="ml-1 cursor-pointer"
            onClick={() => removeFilter(filter)}
          />
        </div>
      )
    })
  }

  return (
    <>
      {renderSelectedFilters()}
      <div
        style={{ position: 'relative' }}
        onMouseEnter={setShowDropdown}
        onMouseLeave={() => setShowDropdown(false)}>
        <button
          onClick={setShowDropdown}
          data-toggle="filter-menu"
          className="cursor-pointer secondary hollow button mb-0">
          <FaPlus />
          &nbsp; <span className="text-bold">Filter</span>
          <FaChevronDown className="ml-1" color="#000" />
        </button>
        <div
          className={`dropdown-pane ${showDropdown ? 'is-open' : ''}`}
          id="filter-menu">
          <ul className="o-dropdown o-dropdown__list">
            {createFilterDropdowns()}
          </ul>
        </div>
      </div>
    </>
  )
}

export default TableFilters
