import React, { Component } from 'react'
import { observer } from 'mobx-react'

@observer
class Answer extends Component {
  render() {
    const { answer } = this.props

    if (answer.toScene == null && !answer.shouldDrawLine) {
      return <g />
    }

    const [x1, y1, x2, y2] = answer
      .fromAnswerPosition()
      .concat(answer.toAnswerPosition())

    let curveFactor = Math.max(Math.abs(y2 - y1) / 2, (x2 - x1) / 2)
    if (x1 > x2) {
      curveFactor = Math.abs(x2 - x1) / 2 + Math.abs(y2 - y1) / 2
    }

    return (
      <path
        className={`c-drawboard__line c-drawboard__line--${answer.getClassName}`}
        d={`M${x1},${y1 + 2} C${x1 + curveFactor},${y1 + 2}  ${
          x2 - curveFactor - answer.dimensions.width / 2
        },${y2 + 2} ${x2 - answer.dimensions.width / 2},${y2 + 2}`}
      />
    )
  }
}

export default Answer
