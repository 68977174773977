import React from 'react'
import Card from './UI/Cards/Card'
import Checkbox from './UI/Form/Checkbox'

const Grid = ({
  data,
  selectedItems,
  selectable,
  cardHandler,
  checkboxHandler,
  cardMenu,
}) => {
  const getSubtitle = (item) => {
    if (typeof item.state === 'object') {
      return item.state.render
    }
    return item.state || item.subtitle.render || item.subtitle
  }
  const createCards = () => {
    return data.map((item) => {
      return (
        <div
          key={item.id}
          className="cell small-12 medium-6 large-4 xxlarge-3 xxxlarge-2">
          <Card
            id={item.id}
            clickHandler={() => !item.isDisabled && cardHandler(item.id)}
            imageUrl={item.imageUrl}
            imageOverlay={item.imageOverlay}
            isDisabled={item.isDisabled}
            hoverValue={item.name.value}
            loadingIcon={item.loadingIcon || null}
            menu={() => !item.isDisabled && cardMenu && cardMenu(item.id)}
            title={
              selectable ? (
                <>
                  <Checkbox
                    checked={selectedItems.includes(item.id)}
                    clickHandler={() => checkboxHandler(item.id)}
                    disabled={item.isDisabled}
                  />
                  <h2
                    className="text-truncate o-card--link cursor-pointer"
                    onClick={() => !item.isDisabled && cardHandler(item.id)}>
                    {item.name.value || item.name}
                  </h2>
                </>
              ) : (
                <h2
                  className="text-truncate cursor-pointer"
                  onClick={() => !item.isDisabled && cardHandler(item.id)}>
                  {item.name.value || item.name}
                </h2>
              )
            }
            subtitle={getSubtitle(item)}
          />
        </div>
      )
    })
  }

  return (
    <div id="grid-view" className="grid-container full grid--overflow h-100">
      <div className="grid-x grid-margin-x">{createCards()}</div>
      <div className="margin--footer"></div>
    </div>
  )
}

export default Grid
