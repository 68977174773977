import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { getIntegrationsWithScenariosQuery } from '../../apollo/query/integrations'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { TabContent, TabTitles } from '../../components/UI/Tabs/Tabs'
import withApollo from '../../hooks/withApollo'
import Delay from '../../utils/helpers/Delay'
import IntegrationsEmptyState from '../../components/Trainees/Integrations/IntegrationsEmptyState'
import ScenariosList from '../../components/Trainees/Integrations/ScenariosList'
import LtiCredentialBox from '../../components/Trainees/Integrations/LtiCredentialBox'
import { kindFormatter } from '../../utils/helpers/integration'

const Integrations = () => {
  const { loading, data, error } = useQuery(getIntegrationsWithScenariosQuery)
  const [activeTab, setActiveTab] = useState(0)

  if (loading) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)
  if (!data.integrations.length) return <IntegrationsEmptyState />

  // After this point we do have data.integrations
  let titles = []

  const tabDesc = {
    LTI: {
      text: 'Copy and paste a scenario specific url for each published scenario to',
      url: 'https://help.warpvr.com/lms--lxp-integration/vV4PDvwqFPcHMYn8GHaE3Z/integrate-with-lti-11/3Pogk49YUzHyRbuzWSvcua',
    },
    SCORM: {
      text: 'Download a package for every published scenario and upload this package to',
      url: 'https://help.warpvr.com/lms--lxp-integration/vV4PDvwqFPcHMYn8GHaE3Z/integrate-with-scorm-12/6k2RwhMQLEoehcJ7z3ugz3',
    },
  }

  const getTabContent = () => {
    return data.integrations.map((integration, i) => {
      const { name, kind } = integration
      titles.push(name)

      return (
        <>
          <div id={`tabTitle-${i}`} className="o-table__title">
            <h2>
              {`${name} `}
              <span className="o-table__heading--sub text-stable-dark">
                • {kindFormatter(kind)}
              </span>
            </h2>
          </div>
          <p id={`tabDescription-${i}`}>
            {`${tabDesc[kind].text} ${name}. `}
            <a
              href={tabDesc[kind].url}
              target="_blank"
              className="text-stable-dark text-underline">
              More info
            </a>
          </p>
          {kind === 'LTI' && (
            <LtiCredentialBox
              name={integration.name}
              ltiKey={integration.ltiKey}
              ltiSecret={integration.ltiSecret}
              ltiPath={integration.ltiPath}
            />
          )}
          <ScenariosList
            scenarios={integration.scenarios}
            kind={integration.kind}
          />
        </>
      )
    })
  }

  return (
    <div id="integrations-table" className="grid-container fluid row">
      <div className="columns small-12 large-3 xxlarge-2 pl-0">
        <TabTitles
          titles={titles}
          activeTab={activeTab}
          selectHandler={setActiveTab}
        />
      </div>
      <div className="columns">
        <TabContent
          titles={titles}
          content={getTabContent()}
          activeTab={activeTab}
        />
      </div>
    </div>
  )
}

export default withApollo(Integrations)
