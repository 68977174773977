import React from 'react'

const SelectMore = ({
  visibleIds,
  allIds,
  currentFilterUserCount,
  selectedTrainees,
  selectMoreHandler,
}) => {
  if (
    selectedTrainees.length > visibleIds.length ||
    visibleIds.every((id) => selectedTrainees.includes(id))
  ) {
    return (
      <div
        className="text-stable-dark"
        style={{
          display: 'flex',
          height: '50px',
          fontSize: '14px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'RGBA(221,221,221, 0.2)',
          borderBottom: '1px solid #cccccc',
        }}>
        {visibleIds.every((id) => selectedTrainees.includes(id)) ? 'All ' : ''}
        {selectedTrainees.length} trainees
        {selectedTrainees.length === visibleIds.length
          ? ' on this page '
          : ''}{' '}
        are selected.
        {selectedTrainees.length !== allIds.length && (
          <span
            style={{ color: '#e7461a' }}
            className="ml-1 text-underline cursor-pointer mb-0"
            onClick={() => selectMoreHandler(allIds)}>
            Select all {currentFilterUserCount} trainees
          </span>
        )}
      </div>
    )
  }
  return null
}

export default SelectMore
