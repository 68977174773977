import $ from 'jquery'
import { Foundation } from 'foundation-sites'
import { Buffer } from 'buffer'

// Setup React components
import ReactOnRails from 'react-on-rails'
import Diagram from '../src/javascript/components/flow/diagram'
import ScenarioEditor from '../src/javascript/pages/ScenarioEditor'
import Media from '../src/javascript/pages/Media'
import Trainees from '../src/javascript/pages/Trainees'
import SharedDevices from '../src/javascript/pages/SharedDevices'
import MediumModal from '../src/javascript/components/Media/Modal/MediumModal'
import Courses from '../src/javascript/pages/Courses'
import Groups from '../src/javascript/pages/Groups'
import Purchased from '../src/javascript/pages/Scenarios/Purchased'
import AdminWorkspaces from '../src/javascript/pages/Admin/Workspaces'
import Scenarios from '../src/javascript/pages/Scenarios'
import Products from '../src/javascript/pages/Store/Products'
import Transactions from '../src/javascript/pages/Store/Transactions'
import Router from '../src/javascript/app/Router'
import AlertList from '../src/javascript/components/UI/Notifications/AlertList'
import VideoEditor from '../src/javascript/components/Editor/Video/VideoEditor'
import UserRegistration from '../src/javascript/pages/UserRegistration/UserRegistration'
import Integrations from '../src/javascript/pages/Trainees/Integrations'
import GeneralSettings from '../src/javascript/pages/Settings/General'
import IntegrationsSettings from '../src/javascript/pages/Settings/Integrations'
import Activities from '../src/javascript/pages/Settings/Activities'
import Subscription from '../src/javascript/pages/Settings/Subscription'
import Workspaces from '../src/javascript/pages/Workspaces/Workspaces'
import Plans from '../src/javascript/pages/Admin/Plans'
import Activity from '../src/javascript/pages/Activity/Activity'
import SignIn from '../src/javascript/pages/SignIn/SignIn'
import ProfileSettings from '../src/javascript/pages/Profile/ProfileSettings'
import ResetPassword from '../src/javascript/pages/ResetPassword/ResetPassword'
import UpdatePassword from '../src/javascript/pages/UpdatePassword/UpdatePassword'
import HelpDropdown from '../src/javascript/components/Layout/HelpDropdown'

// import our stylesheets after vendored so overrides work
import '../src/stylesheets/application.scss'

// Components
import * as componentActionList from '../src/javascript/app/component-action-list'
import * as componentMenu from '../src/javascript/app/component-menu'
import * as componentOpenModal from '../src/javascript/app/component-open-modal'
import * as componentSnackbar from '../src/javascript/app/component-snackbar'
import * as componentOpenTab from '../src/javascript/app/component-open-tab'
import * as componentZoom from '../src/javascript/app/component-zoom'
import * as componentOrderCocoon from '../src/javascript/app/component-order-cocoon'
import * as componentSelect from '../src/javascript/app/component-select'
import * as componentSearch from '../src/javascript/app/component-search'
import * as componentSideMenu from '../src/javascript/app/component-side-menu'
import * as componentPublishSelect from '../src/javascript/app/component-publish-select'
import * as componentClick from '../src/javascript/app/component-click'
import * as componentLauncher from '../src/javascript/app/component-launcher'

require.context('../images', true)

// jQuery needs to be exposed like this
// in order to be used in Rails' XHR responses
window.jQuery = $
window.$ = $

// expose componentLauncher for LTI
window.componentLauncher = componentLauncher

import Rails from '@rails/ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

require('@nathanvda/cocoon')

function initApplication() {
  $(document).foundation() // Load all foundation functions
  $(window).trigger('load.zf.sticky') // Trigger sticky so it works after turbolinks load

  window.Foundation = Foundation
  window.componentOpenTab = componentOpenTab
  window.componentOrderCocoon = componentOrderCocoon
  window.Buffer = Buffer // needed in otp

  componentActionList.init()
  componentSnackbar.init()
  componentOpenTab.init()
  componentZoom.init()
  componentOrderCocoon.init()
  componentMenu.init()
  componentSelect.init()
  componentSearch.init()
  componentOpenModal.openOnLoad()
  componentSideMenu.init()
  componentPublishSelect.init()
  componentClick.init()
  componentLauncher.init()

  $(window).on(
    'resize',
    window.Foundation.util.throttle(() => {
      componentActionList.init()
    }, 300)
  )

  $(window).on(
    'scroll',
    window.Foundation.util.throttle(() => {
      componentSideMenu.init()
    }, 100)
  )
}

window.initApplication = initApplication

/**
 * Set Foundation defaults
 */

Foundation.Dropdown.defaults.closeOnClick = true
Foundation.Dropdown.defaults.hover = true
Foundation.Dropdown.defaults.hoverPane = true
Foundation.Dropdown.defaults.hoverDelay = 500
Foundation.Tooltip.defaults.triggerClass = '' // set to empty
Foundation.Reveal.defaults.animationIn = 'fade-in scale-in-down'
Foundation.Reveal.defaults.animationOut = 'fade-out scale-out-up'

ReactOnRails.register({ Diagram })
ReactOnRails.register({ ScenarioEditor })
ReactOnRails.register({ Media })
ReactOnRails.register({ Router })
ReactOnRails.register({ MediumModal })
ReactOnRails.register({ Scenarios })
ReactOnRails.register({ Trainees })
ReactOnRails.register({ SharedDevices })
ReactOnRails.register({ Courses })
ReactOnRails.register({ Purchased })
ReactOnRails.register({ Groups })
ReactOnRails.register({ Products })
ReactOnRails.register({ Transactions })
ReactOnRails.register({ AdminWorkspaces })
ReactOnRails.register({ VideoEditor })
ReactOnRails.register({ UserRegistration })
ReactOnRails.register({ AlertList })
ReactOnRails.register({ Integrations })
ReactOnRails.register({ GeneralSettings })
ReactOnRails.register({ IntegrationsSettings })
ReactOnRails.register({ Activities })
ReactOnRails.register({ Subscription })
ReactOnRails.register({ Workspaces })
ReactOnRails.register({ Plans })
ReactOnRails.register({ Activity })
ReactOnRails.register({ SignIn })
ReactOnRails.register({ ProfileSettings })
ReactOnRails.register({ ResetPassword })
ReactOnRails.register({ UpdatePassword })
ReactOnRails.register({ HelpDropdown })

$(document).on('turbolinks:load', () => {
  initApplication()
  document.getElementById('dropdown')
})

$(window).on('load', () => {
  $(window).trigger('load.zf.sticky') // Trigger sticky so it works after turbolinks load
  componentOpenModal.init()
  componentOpenModal.openOnLoad()
  componentSideMenu.init()
})

$(document).on('turbolinks:click', () => {
  Foundation.Motion.animateOut($('.c-workarea'), 'fade-out')
})

$(document).on('turbolinks:before-render', () => {
  Foundation.Motion.animateIn($('.c-workarea'), 'fade-in')
})

// after form in modal is submitted, turbolinks visits redirect location,
// but does not close modal. Close it before visit to avoid page being stuck
$(document).on('turbolinks:before-visit', () => {
  jQuery('#modal').foundation('close')
  jQuery('html').removeClass('is-reveal-open')
  jQuery('html').removeClass('zf-has-scroll')
})
