import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  addWorkspaceMutation,
  getWorkspacesQuery,
  updateWorkspaceMutation,
} from '../../apollo/query/workspaces'
import { addSuccessAlert } from '../../utils/helpers/alerts'
import Modal from '../UI/Modal'
import { handleApolloError } from '../../utils/errors'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../UI/LoadingSpinner'
import useCurrentUser from '../../hooks/useCurrentUser'
import { getCurrentClientSettingsQuery } from '../../apollo/query/settings'
import { getClientsQuery } from '../../apollo/query/clients'
import DataTooltip from '../UI/DataTooltip'

const initialValues = {
  name: '',
  kind: 'NORMAL',
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
  ownerEmployeeNr: '',
  planBillingCurrency: 'EUR',
}
const AddEditWorkspaceModal = ({
  id,
  appendTo,
  defaultValues,
  onClose: onCloseCallback,
  onSubmit,
}) => {
  const [currentUser] = useCurrentUser()
  const [formValues, setFormValues] = useState(initialValues)
  const [ownerIsMe, setOwnerIsMe] = useState(true)

  const { data: currentClientData } = useQuery(getCurrentClientSettingsQuery)
  const { data: clientsData } = useQuery(getClientsQuery)

  const [addWorkspace, { loading: addLoading }] = useMutation(
    addWorkspaceMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        $(`#${id}`).foundation('close')
        addSuccessAlert({ title: 'Workspace created' })
        setFormValues(initialValues)
        onSubmit()
      },
      refetchQueries: [
        {
          query: getWorkspacesQuery,
        },
      ],
    }
  )

  const [updateWorkspace, { loading: editLoading }] = useMutation(
    updateWorkspaceMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        $(`#${id}`).foundation('close')
        addSuccessAlert({ title: 'Workspace updated' })
        setFormValues(initialValues)
        onSubmit()
      },
      refetchQueries: [
        {
          query: getWorkspacesQuery,
        },
      ],
    }
  )

  const hasStoreSellerFlag = () =>
    currentClientData.currentClient.features.includes('STORE_SELLER')

  const formatDefaultValues = () => ({
    ...defaultValues,
    kind: defaultValues.workspaceKind,
  })

  useEffect(() => {
    if (currentClientData)
      setFormValues(
        (defaultValues ? formatDefaultValues() : null) || {
          ...initialValues,
          kind: currentClientData.currentClient.canAddWorkspaces
            ? 'NORMAL'
            : 'DISTRIBUTION',
        }
      )
  }, [defaultValues, currentClientData])

  if (!currentUser || !currentClientData || !clientsData) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (editing) {
      updateWorkspace({
        variables: {
          id: formValues.id,
          name: formValues.name,
          kind: formValues.kind,
          planBillingCurrency: formValues.planBillingCurrency,
        },
      })
    } else {
      addWorkspace({ variables: formValues })
    }
  }

  const onClose = () => {
    setTimeout(() => {
      setFormValues(initialValues)
    }, 500)
    onCloseCallback()
  }

  const handleOwner = () => {
    if (!ownerIsMe) {
      setFormValues({
        ...formValues,
        ownerFirstName: '',
        ownerLastName: '',
        ownerEmail: '',
        ownerEmployeeNr: '',
      })
    }
    setOwnerIsMe(!ownerIsMe)
  }

  const { currentClient } = currentClientData
  const editing = !!formValues.id

  return (
    <Modal
      id={id}
      key={id}
      appendTo={appendTo}
      overflowY="visible"
      headerText={`${editing ? 'Edit' : 'New'} workspace`}
      onClose={onClose}>
      <form className="mt-3" onSubmit={handleSubmit}>
        <label>
          Workspace name <span className="text-assertive">*</span>
        </label>
        <input
          type="text"
          required
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />

        {hasStoreSellerFlag() && !editing && (
          <>
            <label>Workspace type</label>
            <label className="o-radio">
              <input
                type="radio"
                name="kind"
                value={
                  defaultValues?.workspaceKind === 'BILLING'
                    ? 'BILLING'
                    : 'NORMAL'
                }
                disabled={
                  !currentClient.canAddWorkspaces &&
                  !(
                    defaultValues?.id &&
                    ['NORMAL', 'BILLING'].includes(defaultValues.workspaceKind)
                  )
                }
                checked={['NORMAL', 'BILLING'].includes(formValues.kind)}
                required
                onChange={handleChange}
              />
              <span></span>
              Creation
            </label>
            <label className="o-radio mb-2">
              <input
                type="radio"
                name="kind"
                value="DISTRIBUTION"
                required
                checked={formValues.kind === 'DISTRIBUTION'}
                onChange={handleChange}
                disabled={formValues.originalScenariosCount > 0}
              />
              {formValues.originalScenariosCount > 0 ? (
                <DataTooltip title="You can not change this workspace into a distibution workspace. Delete all scenarios first, before changing this workspace.">
                  <span></span>
                </DataTooltip>
              ) : (
                <span></span>
              )}
              Distribution
              <em className="text-stable">
                &nbsp;- Scenario creation is not possible with this workspace
                type.
              </em>
            </label>
          </>
        )}

        {!editing && (
          <>
            <label>Workspace owner</label>
            <label className="o-radio">
              <input
                name="owner"
                type="radio"
                required
                checked={ownerIsMe}
                onChange={handleOwner}
              />
              <span></span>
              Me <em className="text-stable-dark">- {currentUser.email}</em>
            </label>
            <label className="o-radio mb-3">
              <input
                name="owner"
                type="radio"
                required
                checked={!ownerIsMe}
                onChange={handleOwner}
              />
              <span></span>
              Someone else
            </label>

            {!ownerIsMe && (
              <>
                <div className="row">
                  <div className="small-6 columns pl-0">
                    <label>
                      First name <span className="text-assertive">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="ownerFirstName"
                      value={formValues.ownerFirstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="small-6 columns pr-0">
                    <label>
                      Last name <span className="text-assertive">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="ownerLastName"
                      value={formValues.ownerLastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="small-6 columns pl-0">
                    <label>
                      Employee email address{' '}
                      <span className="text-assertive">*</span>
                    </label>
                    <input
                      type="email"
                      required
                      name="ownerEmail"
                      value={formValues.ownerEmail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="small-6 columns pr-0">
                    <label>Employe no</label>
                    <input
                      type="text"
                      name="ownerEmployeeNr"
                      value={formValues.ownerEmployeeNr}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div className="flex-container align-right pb-3 pt-2">
          <button
            type="button"
            className="hollow button secondary wide mr-1 mb-0"
            data-close={id}>
            Cancel
          </button>
          <button
            type="submit"
            className="button primary wide mb-0 o-button--loading"
            disabled={addLoading || editLoading}>
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default AddEditWorkspaceModal
