import React, { useContext } from 'react'
import bgImg from '../../../../images/product-line.png'
import logo from '../../../../images/default/logo.png'
import LanguageContext from '../../utils/context/LanguageContext'

const Error = () => {
  const { translations: t } = useContext(LanguageContext)

  const { errors } = t
  return (
    <section id="error" className="grid-container fluid">
      <div className="grid-x c-launcher__page--container">
        <div className="cell small-12 large-6">
          <div
            className="c-launcher__company-icon"
            onClick={() => {
              window.open('https://warpvr.com')
            }}>
            <img src={logo} />
          </div>
          <h1 className="c-launcher__page--banner mb-3">
            {errors.notFound.title}
          </h1>
          <p>
            {errors.notFound.message}
            <a href="/play" className="text-underline">
              {errors.notFound.link}
            </a>
          </p>
        </div>
        <div className=" show-for-medium cell h-100 large-6 c-launcher__company--image--container ">
          <div
            className="c-launcher__company--image"
            style={{
              backgroundImage: `url("${bgImg}")`,
            }}></div>
        </div>
      </div>
    </section>
  )
}

export default Error
