import React, { useContext, useState } from 'react'
import EmptyParagraph from './EmptyParagraph'
import { gql, useQuery } from '@apollo/client'
import Loading from './Loading'
import ActivityContext from '../../utils/context/ActivityContext'
import { convertDuration } from '../../utils/format'
import { FaPlus } from 'react-icons/fa'

const getGroupsAttemptsQuery = gql`
  query getAttemptMetrics(
    $fromDateTime: LocalDatetime!
    $toDateTime: LocalDatetime!
    $filter: AttemptsFilterInput!
    $metric: AttemptsMetric!
  ) {
    attemptsMetrics(
      metric: $metric
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      filter: $filter
    ) {
      total
      grouped(groupKind: GROUP) {
        id
        name
        value
      }
    }
  }
`

const TOTAL_LENGTH = 10
const INITAL_LENGTH = 5

const MostActiveGroups = ({ metric = 'COUNT', attemptsMetricsData }) => {
  const [showMore, setShowMore] = useState(false)
  const { apiVars } = useContext(ActivityContext)
  const { data: groupsMetrics } = useQuery(getGroupsAttemptsQuery, {
    variables: { ...apiVars, metric },
    skip: !Number(attemptsMetricsData?.attemptsMetrics?.total),
  })

  let groups = groupsMetrics?.attemptsMetrics?.grouped ?? []
  groups = groups.slice(0, TOTAL_LENGTH)

  return (
    <>
      <h2 className="text-bold mb-2">Most active groups</h2>
      <div className="border-light border-radius">
        <table className="hover unstriped mb-0 border-none">
          <thead>
            <tr className="text-stable-dark border-light-bottom">
              <th
                className="text-center pt-1-5 pb-1-5 text-normal"
                width="60px">
                #
              </th>
              <th className="text-normal">Group</th>
              <th className="text-normal text-right pr-2-5" colSpan="2">
                {metric === 'DURATION' ? 'Playing time' : 'Attempts'}
              </th>
            </tr>
          </thead>
          <tbody>
            {groups?.map((group, index) => (
              <tr
                className={
                  index < groups.length - 1 ? 'border-light-bottom' : ''
                }
                key={group.id}
                hidden={!showMore && index > INITAL_LENGTH - 1}>
                <td className="text-center pt-2 pb-2 text-normal" width="60px">
                  {index + 1}
                </td>
                <td className="text-normal">{group.name}</td>
                <td className="text-normal text-right pr-2-5">
                  <span
                    className="u-ib text-bold ml-1-5 text-right"
                    style={{ minWidth: '23px' }}>
                    {metric === 'DURATION'
                      ? convertDuration(group.value)
                      : group.value}
                  </span>
                </td>
              </tr>
            ))}

            {groups?.length > INITAL_LENGTH && !showMore && (
              <tr>
                <td
                  className="cursor-pointer text-bold text-normal text-stable-dark pt-1-5 pb-1-5"
                  colSpan="3"
                  onClick={() => setShowMore(true)}>
                  <div className="flex-container align-middle align-center">
                    <FaPlus /> &nbsp; Show more
                  </div>
                </td>
              </tr>
            )}
            {groups?.length === 0 && (
              <tr>
                <td colSpan="3">
                  <EmptyParagraph />
                </td>
              </tr>
            )}
            {!groups && (
              <tr>
                <td colSpan="3">
                  <Loading />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MostActiveGroups
