import { observable, reaction, computed } from 'mobx'
import axios from 'axios'

export default class CardStore {
  @observable id = 0

  @observable x = 0

  @observable y = 0

  @observable draggable_id = 0

  @observable incomingAnswers = []

  @observable isFlipped = false

  @observable isDragging = false

  @observable showMoreMenu = false

  @observable isSelected = false

  hasMoved = false

  store = null

  realPosition = {
    x: 0,
    y: 0,
  }

  constructor(store, jsonObject) {
    this.store = store
    this.id = jsonObject.id
    this.x = jsonObject.x
    this.y = jsonObject.y
    this.draggable_id = jsonObject.draggable_id
    this.has_video = jsonObject.has_video

    // Sort incomingAnswers based on y-coordinate of fromScene
    reaction(
      () => [
        this.incomingAnswers.length,
        this.incomingAnswers.map((answer) => answer.fromScene.y),
      ],
      () => {
        this.incomingAnswers.replace(
          this.incomingAnswers.slice().sort((a, b) => {
            const diff = a.fromScene.y - b.fromScene.y
            if (diff !== 0) {
              return diff
            }
            if (a.priority === b.priority) {
              return a.randomizedIndex - b.randomizedIndex
            }
            return a.priority - b.priority
          })
        )
      },
      { compareStructural: true }
    )
  }

  assignProperties(props, object) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop of props) {
      this[prop] = object[prop]
    }
  }

  create(link_to_id = null, link_to_randomized_index = null) {
    let url = `${this.store.urls.cards_url}.js`
    if (link_to_id != null) {
      url += `?incoming_answer_id=${link_to_id}`
    }
    if (link_to_randomized_index !== null && link_to_randomized_index !== 0) {
      url += `&incoming_answer_randomized_index=${link_to_randomized_index}`
    }

    axios
      .post(url, {
        card: this.asJson(),
        authenticity_token: this.store.authenticity_token,
      })
      .then((response) => {
        eval(response.data)
      })
  }

  save(needsToUpdate = true) {
    axios
      .patch(`${this.store.urls.cards_url}/${this.id}.js`, {
        card: this.asJson(),
        authenticity_token: this.store.authenticity_token,
      })
      .then((response) => {
        if (needsToUpdate) {
          eval(response.data)
        }
        return this.store.hideAllMenus()
      })
  }

  delete() {
    if (this.start) {
      window.alert(
        'You cannot delete a start scene. Please choose another scene as start scene and try again'
      )
    } else {
      const href = `${this.store.urls.delete_card_url.replace(-1, this.id)}.js`
      const confirm = window.confirm(
        `Are you sure to delete this ${this.type}?`
      )

      if (confirm === true) {
        axios
          .delete(href, {
            params: {
              authenticity_token: this.store.authenticity_token,
            },
          })
          .then((response) => {
            eval(response.data)
          })
      }
    }
  }

  duplicate() {
    const href = `${this.store.urls.duplicate_card_url.replace(-1, this.id)}.js`

    axios
      .post(href, {
        authenticity_token: this.store.authenticity_token,
      })
      .then((response) => {
        eval(response.data)
      })
  }

  change_scene_kind() {
    const href = `${this.store.urls.change_scene_kind_url.replace(-1, this.id)}`
    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  change_camera_location(location_id) {
    const href = `${this.store.urls.change_camera_location_url.replace(
      -1,
      this.id
    )}`
    axios
      .patch(href, {
        authenticity_token: this.store.authenticity_token,
        card: {
          camera_location_id: location_id,
        },
      })
      .then((response) => {
        eval(response.data)
      })
  }

  create_new_camera_location() {
    const href = `${this.store.urls.new_camera_location_url}?couple_to_card=${this.id}`
    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  resetState(elem) {
    if (elem !== this) {
      // this.isFlipped = false; // Allow multiple cards to be flipped or not
    }
    this.showMoreMenu = false
  }

  startDrag() {
    if (!this.isSelected) {
      this.store.activeElement = this
    }
    this.isDragging = true
    this.realPosition.x = this.x
    this.realPosition.y = this.y
  }

  handleDrag(dragInfo) {
    if (this.isSelected) {
      this.store.selection.moveSelectedCards(dragInfo)
    }

    this.realPosition.x += dragInfo.deltaX / this.store.zoomFactor
    this.realPosition.y += dragInfo.deltaY / this.store.zoomFactor

    this.hasMoved = this.hasMoved || (dragInfo.deltaX !== 0 || dragInfo.deltaY !== 0)

    if (
      this.realPosition.x >= 0 &&
      this.realPosition.x <= this.store.canvas.width
    ) {
      this.x = this.realPosition.x
    } else {
      // eslint-disable-next-line no-unused-expressions
      this.realPosition.x < 0 ? 0 : this.store.canvas.width
    }

    if (
      this.realPosition.y >= 0 &&
      this.realPosition.y <= this.store.canvas.height
    ) {
      this.y = this.realPosition.y
    } else {
      // eslint-disable-next-line no-unused-expressions
      this.realPosition.y < 0 ? 0 : this.store.canvas.height
    }
  }

  stopDrag(e, dragInfo) {
    this.isDragging = false

    if (!this.hasMoved) {
      this.store.activeElement = this

      if (this.isWithinBoundaries(dragInfo.lastX, dragInfo.lastY)) {
        if (e.shiftKey) {
          this.isSelected = !this.isSelected
          if (this.isSelected) {
            this.store.activeElement = this.store.selection
            this.store.selection.selectionActive = true
          }
        } else if (this.type === 'scene') {
          this.flip()
        }
      }
    } else if (this.isSelected) {
      this.store.selection.saveSelectedCards()
    } else {
      this.hasMoved = false
      this.save(false)
    }
  }

  removeIncomingAnswer(answer) {
    this.incomingAnswers = this.incomingAnswers.filter((a) => {
      return !(
        a.id === answer.id && a.randomizedIndex === answer.randomizedIndex
      )
    })
  }

  addIncomingAnswer(answer) {
    this.removeIncomingAnswer(answer)
    this.incomingAnswers.push(answer)
    this.store.highlightedScene = null
  }

  isWithinBoundaries(x, y) {
    const realX = x / this.store.zoomFactor
    const realY = y / this.store.zoomFactor

    return (
      realX >= this.x &&
      realX <= this.x + this.cardWidth &&
      realY >= this.y &&
      realY <= this.y + this.cardHeight
    )
  }

  get cardHeight() {
    return this.dimensions.height
  }

  get cardWidth() {
    return this.dimensions.width
  }

  @computed
  get x2() {
    return this.x + this.cardWidth
  }

  @computed
  get y2() {
    return this.y + this.cardHeight
  }

  @computed
  get baseZIndex() {
    const base = this.priority > 0 ? this.priority * 2 : 300
    if (this.store.activeElement === this || this.incomingAnswerIsActive()) {
      return base + 310
    }
    return base + 1
  }

  incomingAnswerIsActive() {
    // eslint-disable-next-line no-restricted-syntax
    for (const answer of this.incomingAnswers) {
      if (answer === this.store.activeElement) {
        return true
      }
    }
    return false
  }

  isWithinCoordinates({ x1, x2, y1, y2 }) {
    const notInX = Math.max(x1, x2) < this.x || Math.min(x1, x2) > this.x2

    const notInY = Math.max(y1, y2) < this.y || Math.min(y1, y2) > this.y2

    return !notInX && !notInY
  }

  flip() {
    this.isFlipped = !this.isFlipped
  }

  isHighlighted() {
    return (
      (this.store.highlightedScene != null &&
        this.store.highlightedScene.id === this.id) ||
      this.isSelected
    )
  }
}
