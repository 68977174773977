import React, { useEffect, useState } from 'react'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { FaShieldAlt } from 'react-icons/fa'
import ConfigAuthenticatorDetail from '../../../components/Profile/ConfigAuthenticatorDetail'

const TwoFactorsForm = ({ handleNext, setTotalSteps }) => {
  const [currentUser, , currentRole] = useCurrentUser()
  const [otpConfigured, setOtpConfigured] = useState()

  useEffect(() => {
    if (currentUser) setOtpConfigured(currentUser.otpConfigured)
  }, [currentUser])

  useEffect(() => {
    if (currentRole === 'OWNER') setTotalSteps(5)
  }, [currentRole])

  if (!currentUser) return <></>

  return (
    <div id="two-factors-form">
      {currentUser.otpRequired && (
        <div className="border-royal p-2 flex-container align-top border-radius text-royal text-normal mb-3">
          <FaShieldAlt style={{ marginTop: '3px' }} />
          <span className="ml-1 text-dark tracking-tighter">
            Two-factor authentication has to be enabled because you belong to an
            organization that requires it.
          </span>
        </div>
      )}
      <h4 className="mb-1 text-bold">
        Two-factor authentication{' '}
        {otpConfigured && (
          <span className="o-label o-label--success text-white text-small">
            CONFIGURED
          </span>
        )}
      </h4>

      <p className="mb-2">
        Two-factor authentication adds an additional layer of security to your
        account by requiring more than just a password to sign in.
      </p>

      <ConfigAuthenticatorDetail
        otpConfigured={otpConfigured}
        otpConfiguredAt={currentUser.otpConfiguredAt}
        otpRequired={currentUser.otpRequired}
        onChangeConfiguation={setOtpConfigured}
        currentUser={currentUser}
      />

      <button
        className="o-button text-bold w-100 mt-4"
        onClick={handleNext}
        disabled={!otpConfigured}>
        Next
      </button>

      {!currentUser.otpRequired && (
        <>
          <p className="mt-4 text-stable-dark mb-0">
            Or skip this step. You can always set up two-factor authentication
            later from your profile settings.
          </p>
          <button
            className="button hollow secondary text-bold w-100 mt-4"
            onClick={handleNext}>
            Skip this step
          </button>
        </>
      )}
    </div>
  )
}

export default TwoFactorsForm
