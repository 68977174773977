import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import MoreMenu from '../more-menu'

@inject('diagramStore')
@observer
class Ending extends Component {
  render() {
    const { ending } = this.props

    return (
      <div
        onMouseLeave={() => {
          ending.showMoreMenu = false
        }}>
        <ul
          className={classNames('o-action-list o-action-list--top', {
            'o-action-list--top-persistant': ending.showMoreMenu,
          })}
          style={{ zIndex: ending.baseZIndex + 15 }}>
          <li className="o-action-list__item">
            <a
              className="o-action-list__button o-action-list__button--eye"
              onClick={() => ending.show()}
            />
          </li>
          <li className="o-action-list__item" style={{ position: 'relative' }}>
            <a
              className="o-action-list__button o-action-list__button--more"
              onClick={() => {
                ending.showMoreMenu = !ending.showMoreMenu
              }}
            />
            <MoreMenu card={ending} />
          </li>
        </ul>
        <div
          className="c-dark-card c-dark-card--on-diagram"
          style={{ zIndex: ending.baseZIndex + 8, position: 'relative' }}>
          <h2 className="c-dark-card__title">Ending</h2>
        </div>
      </div>
    )
  }
}

export default Ending
