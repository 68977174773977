import React, { createContext, useEffect, useState } from 'react'
import { getPeriodEndDateTime, getPeriodStartDateTime } from '../format'
import { cleanObjectFalsyValues } from '../helpers/javascript'
import moment from 'moment'
import usePreferences from '../../hooks/usePreferences'

const INITIAL_FILTERS = {
  userIds: [],
  deviceTypes: [],
  scenarioIds: [],
  groupIds: [],
  period: '-30_days',
}

const isEmpty = (v) => {
  return (
    v === null ||
    v === undefined ||
    typeof v == 'undefined' ||
    (typeof v == 'string' && v.length == 0) ||
    (typeof v == 'object' && v.length == 0)
  )
}

const getSearchParamsFromUrl = (preferences = {}) => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  return {
    ...INITIAL_FILTERS,
    ...params,
    period: params.period ?? preferences.period ?? INITIAL_FILTERS.period,
    userIds: params.userIds ? params.userIds.split(',') : [],
    groupIds: params.groupIds ? params.groupIds.split(',') : [],
    scenarioIds: params.scenarioIds ? params.scenarioIds.split(',') : [],
    deviceTypes: params.deviceTypes ? params.deviceTypes.split(',') : [],
    scores: params.scores ? params.scores.split(',') : [0],
  }
}

const getApiVars = (preferences) => {
  const params = getSearchParamsFromUrl(preferences)

  const filter = Object.entries({
    userIds: isEmpty(params.userIds.filter((u) => u))
      ? null
      : params.userIds.map(Number),
    groupIds: isEmpty(params.groupIds.filter((u) => u))
      ? null
      : params.groupIds.map(Number),
    deviceTypes: isEmpty(params.deviceTypes) ? null : params.deviceTypes,
    completed: isEmpty(params.completed) ? null : params.completed === 'yes',
    scenarioIds: isEmpty(params.scenarioIds.filter((u) => u))
      ? null
      : params.scenarioIds.map(Number),
    scores: isEmpty(params.scores.filter((u) => u))
      ? null
      : params.scores.map(Number),
    testing:
      isEmpty(params.testing) || params.testing === 'include'
        ? null
        : params.testing === 'only',
  }).reduce(
    (a, [k, v]) => (v === null || v === undefined ? a : ((a[k] = v), a)),
    {}
  )

  return {
    filter,
    fromDateTime: params.periodDateStart
      ? new Date(params.periodDateStart)
      : getPeriodStartDateTime(params.period, new Date()),
    toDateTime: params.periodDateEnd
      ? moment(params.periodDateEnd).set({ hours: 23, minute: 59, second: 59 })
      : getPeriodEndDateTime(params.period),
  }
}

const ActivityContext = createContext({
  searchParams: getSearchParamsFromUrl(),
  apiVars: getApiVars(),
})

export const ActivityContextProvider = ({ children }) => {
  const [apiVars, setApiVars] = useState()
  const [searchParams, _setSearchParams] = useState()
  const [preferences, setPreferences] = usePreferences('attempts')

  const setApiFilters = () => {
    setApiVars(getApiVars(preferences))
    _setSearchParams(getSearchParamsFromUrl(preferences))
  }

  const setSearchParams = (filter) => {
    setPreferences({
      ...preferences,
      period: filter.period,
    })
    const searchParams = new URLSearchParams(
      cleanObjectFalsyValues(filter, true)
    ).toString()
    history.pushState(null, '', `${window.location.pathname}?${searchParams}`)
    setApiFilters()
  }

  useEffect(() => {
    if (preferences && !apiVars) setApiFilters()
  }, [preferences])

  if (!preferences || !searchParams || !apiVars) return <></>

  return (
    <ActivityContext.Provider
      value={{
        apiVars,
        searchParams,
        setSearchParams,
      }}>
      {children}
    </ActivityContext.Provider>
  )
}

export default ActivityContext
