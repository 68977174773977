import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FaQuestionCircle } from 'react-icons/fa'
import { default as S } from '../../../../utils/lang/en.json'
import { dateFormat, sortOnKey } from '../../../../utils/format'
import DataTooltip from '../../../UI/DataTooltip'

const PurchasedLicensesTab = ({ product }) => {
  const { licenseType, kind } = product.purchases[0]
  const [totalCount, setTotalCount] = useState(0)
  const { licenseDuration } = product.purchases[product.purchases.length - 1]

  useEffect(() => {
    if (product && product.purchases) {
      const n = product.purchases.reduce(
        (a, b) => {
          const x = {
            availableLicenseCount:
              a.availableLicenseCount + b.availableLicenseCount,
            activeLicenseCount: a.activeLicenseCount + b.activeLicenseCount,
            usedLicenseCount: a.usedLicenseCount + b.usedLicenseCount,
            expiredLicenseCount: a.expiredLicenseCount + b.expiredLicenseCount,
          }
          const sum =
            x.availableLicenseCount +
            a.activeLicenseCount +
            a.usedLicenseCount +
            a.expiredLicenseCount
          return { ...x, sum }
        },
        {
          availableLicenseCount: 0,
          activeLicenseCount: 0,
          usedLicenseCount: 0,
          expiredLicenseCount: 0,
        }
      )
      setTotalCount(n)
    }
  }, [product])

  const createBars = (purchases) => {
    return purchases.map((purchase) => {
      const barItems = [
        {
          type: 'available',
          count: purchase.availableLicenseCount,
          color: 'energized',
        },
        {
          type: 'active',
          count: purchase.activeLicenseCount,
          color: 'royal',
        },
        {
          type: 'used',
          count: purchase.usedLicenseCount,
          color: 'stable-dark',
        },
        {
          type: 'expired',
          count: purchase.expiredLicenseCount,
          color: 'stable-light',
        },
      ]
      const total = barItems.reduce((a, { count }) => {
        return a + count
      }, 0)
      const calculatePercentages = barItems.map(({ count }) => {
        if (!count) return count
        let percentage = (count / total) * 100
        if (percentage < 5) percentage = 5
        return percentage
      })
      const totalPercent = calculatePercentages.reduce((a, b) => a + b, 0)

      const createBarItems = () => {
        return barItems.reduce((a, item, index) => {
          if (!item.count) return a
          a.push(
            <div
              key={index}
              style={{
                width: `${(calculatePercentages[index] / totalPercent) * 100}%`,
              }}
              className={`o-bar__item o-bar__item--${item.color}`}>
              <DataTooltip
                fullWidth={true}
                title={`${item.count} ${item.type} license${
                  item.count === 1 ? '' : 's'
                }`}>
                <div className={`o-bar__count ${item.color}`}>{item.count}</div>
              </DataTooltip>
            </div>
          )
          return a
        }, [])
      }
      return (
        <div
          key={purchase.id}
          className="o-bar__row--title border-bottom p-2 cell grid-x">
          <div className="cell small-3">
            {dateFormat(purchase.purchaseDate, 'DD MMM YYYY', false)}
            <p className="o-bar__row--date mb-0">{`Expires ${dateFormat(
              purchase.expirationDate,
              'DD MMM YYYY',
              false
            )}`}</p>
          </div>
          <div className="o-bar__row cell auto">
            <div className="o-bar o-bar__container">{createBarItems()}</div>
          </div>
        </div>
      )
    })
  }
  const tooltipTitle = `
  <ul style="text-align:left;margin-bottom:0;">
  <li><b>Available licenses</b> have not yet been used and are still available.</li>
  <li><b>Active licenses</b> are currently in use.</li>
  <li><b>Used licenses</b> have been used, and have passed the license duration.</li>
  <li><b>Expired licenses</b> have passed the expiration date without being used.</li>
  </ul>
    `
  return (
    <div key={uuidv4()}>
      <h2 className="text-bold">Purchased Licenses</h2>
      <p>{`${
        S.store.licenseType[licenseType]
      } license with a ${licenseDuration} month${
        licenseDuration === 1 ? '' : 's'
      } duration.`}</p>
      <div className="mt-3" id="transaction-container">
        <div className="grid-y">
          <div className="o-bar__legenda border-bottom p-1 cell grid-x">
            <div className="cell auto"></div>
            <div className="cell shrink mr-2">
              <span className="o-bullet o-bullet--energized mr-1"></span>
              {totalCount.availableLicenseCount} Available
            </div>
            <div className="cell shrink mr-2">
              <span className="o-bullet o-bullet--royal mr-1"></span>
              {totalCount.activeLicenseCount} Active
            </div>
            <div className="cell shrink mr-2">
              <span className="o-bullet o-bullet--stable-dark mr-1"></span>
              {totalCount.usedLicenseCount} Used
            </div>
            <div className="cell shrink mr-2">
              <span className="o-bullet o-bullet--stable-light mr-1"></span>
              {totalCount.expiredLicenseCount} Expired
            </div>
            <div className="cell shrink">
              <DataTooltip title={tooltipTitle}>
                <FaQuestionCircle className="text-stable-dark" />
              </DataTooltip>
            </div>
          </div>
          {kind === 'SUBSCRIPTION' && (
            <div className="o-bar--row p-2 border-bottom o-bar--container o-bar__row--subscription">
              {
                product.subscriptions[0].state === 'ACTIVE' ?
                `You have a subscription on this product. Renews on ${dateFormat(product.subscriptions[0].renewalDate, 'DD MMM YYYY')} with ${product.subscriptions[0].renewalLicenseCount} licenses.` :
                `The subscription on this product has been cancelled`
              }
            </div>
          )}
          {createBars(
            sortOnKey([...product.purchases], 'expirationDate', true)
          )}
        </div>
      </div>
    </div>
  )
}

export default PurchasedLicensesTab
