const languageDetect = {
  languages: [
    {
      cyrillic: [
        ['0x400', '0x4ff'], // cyrillic
        ['0x500', '0x527'], // cyrillic-supplement
      ],
    },
    {
      latin: [
        ['0x0', '0x7F'], // basic-latin
        ['0x80', '0xff'], // latin-1-supplement
      ],
    },
    { thai: ['0xe01', '0xe5b'] }, // thai
    {
      japanese: [
        ['0x3041', '0x309f'], // hiragana
        ['0x30a0', '0x30ff'], // katakana
        ['0x31f0', '0x31ff'], // katakana-phonetic-extensions
        ['0x2e80', '0x2ef3'], // cjk-radicals-supplement
        ['0x3000', '0x303f'], // cjk-symbols-and-punctuation
        ['0x31c0', '0x31e3'], // cjk-strokes
        ['0x3200', '0x32fe'], // enclosed-cjk-letters-and-months
        ['0x3300', '0x33ff'], // cjk-compatibility
        ['0x3400', '0x4db5'], // cjk-unified-ideographs-extension-a
        ['0x4e00', '0x9fcb'], // cjk-unified-ideographs
        ['0xf900', '0xfad9'], // cjk-compatibility-ideographs
        ['0xfe30', '0xfe4f'], // cjk-compatibility-forms
        ['0x20000', '0x2a6d6'], // cjk-unified-ideographs-extension-b
        ['0x2a700', '0x2b734'], // cjk-unified-ideographs-extension-c
        ['0x2b740', '0x2b81d'], // cjk-unified-ideographs-extension-d
        ['0x2f800', '0x2fa1d'], // cjk-compatibility-ideographs-supplement
      ],
    },
  ],
  getEstimate(range, string) {
    const isInRange = (min, max, value) => {
      return Number(value) >= Number(min) && Number(value) <= Number(max)
    }
    return string.split('').reduce((count, char) => {
      if (isInRange(range[0], range[1], char.codePointAt(0))) return count + 1
      return count
    }, 0)
  },
  detect(string, amount) {
    const estimates = []

    for (const language of this.languages) {
      const values = Object.values(language)[0]
      let charsInRange = 0
      if (typeof values[0] !== 'string') {
        charsInRange = values.reduce((count, range) => {
          return count + this.getEstimate(range, string)
        }, 0)
      } else {
        charsInRange = this.getEstimate(values, string)
      }
      if (charsInRange > 0) {
        estimates.push([Object.keys(language)[0], charsInRange / string.length])
      }
    }

    estimates.sort((a, b) => b[1] - a[1])

    if (!estimates.length) estimates.push(['latin', 0])
    if (amount) return estimates.slice(0, amount)

    return estimates
  },
}

export default languageDetect
