import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../../UI/Modal'
import { adminUpdateCommissionMutation } from '../../../../apollo/query/admin/store'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'

const EditCommissionModal = ({ id, appendTo, productId, product }) => {
  const [commission, setCommission] = useState(30)

  useEffect(() => {
    if (product) {
      setCommission(product.commission)
    }
  }, [product])

  const [updateCommission] = useMutation(adminUpdateCommissionMutation, {
    onError: handleApolloError,
    onCompleted: () => addSuccessAlert({ title: 'Commission updated' })
  })

  const submit = (e) => {
    if (commission <= 0) {
      e.preventDefault()
      e.stopPropagation()
    }
    updateCommission({
      variables: {
        commission,
        id: productId,
      },
    })
    return setTimeout(() => {
      setCommission(30)
    }, 300)
  }
  const cancel = () => {
    setTimeout(() => {
      setCommission(30)
    }, 300)
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      width={310}
      useDefaultButtons
      submitHandler={submit}
      cancelHandler={cancel}
      headerText="Edit commission">
      <form className="mt-3">
        <div className="cell small-12">
          <label htmlFor="commission">Commission</label>
        </div>
        <div className="grid-x grid-margin-x">
          <div className="cell auto ">
            <input
              min="0"
              step="1"
              required
              type="number"
              id="commission"
              value={commission}
              onChange={({ target }) =>
                setCommission(Number(target.value.replace(/[^\d]+/g, '')))
              }
            />
          </div>
          <div className="cell shrink ml-0">
            <span className="button ml-0  hollow secondary text-dark">%</span>
          </div>
        </div>
      </form>
    </Modal>
  )
}
export default EditCommissionModal
