const { clamp } = require('../../../../../utils/helpers/javascript')

const { AFRAME } = window

AFRAME.registerComponent('collider-check', {
  dependencies: ['raycaster'],
  toggleCameraControls(enabled) {
    this.camera.setAttribute('look-controls', 'enabled', enabled)
    this.cameraControlsEnabled = enabled
  },

  unSelectAll() {
    ;[...document.querySelectorAll('a-collision-box.selected')].forEach(
      (el) => {
        el.emit('intersect-cleared')
        el.emit('is-selected', { isSelected: false })
        el.shouldSave = false
      }
    )
  },

  onMouseDown(event) {
    document.querySelector('body').classList.add('text-no-selection')
    this.unSelectAll()
    if (!this.hit) {
      return
    }

    this.isDirectionElement = this.hit.id.includes('DIRECTION')
    this.hit.emit('is-selected', { isSelected: true, scroll: true })
    this.hit.emit('intersect')
    this.toggleCameraControls(false)
    this.mouseDown = true
    this.cord.mousedown.x = (event.clientX / window.innerWidth) * 2 - 1
    this.cord.mousedown.y = -(event.clientY / window.innerHeight) * 2 + 1
  },

  onMouseMove(event) {
    const speed = 100
    if (!this.hit || !this.mouseDown) {
      return
    }
    this.shouldSave = true
    this.isDragging = true
    this.cord.mousemove.x = (event.clientX / window.innerWidth) * 2 - 1
    this.cord.mousemove.y = -(event.clientY / window.innerHeight) * 2 + 1
    const dX = this.cord.mousedown.x - this.cord.mousemove.x
    const dY = this.cord.mousedown.y - this.cord.mousemove.y
    const current = this.hit.parentNode.getAttribute('rotation')
    const ySpeed = speed * (1 + Math.abs(current.x / 60))
    if (
      this.isDirectionElement &&
      (this.hit.id.includes('-A') ||
        this.hit.classList.contains('lock-vertical'))
    ) {
      // We are dealing with a direction answer element. only rotate on x axis
      this.hit.parentNode.setAttribute('rotation', {
        x: clamp(current.x, -90, 90),
        y: current.y + dX * ySpeed,
      })
    } else {
      this.hit.parentNode.setAttribute('rotation', {
        x: clamp(current.x - dY * (speed * 0.8), -90, 90),
        y: current.y + dX * ySpeed,
      })
    }
    this.cord.mousedown.x = clamp(this.cord.mousemove.x, -90, 90)
    this.cord.mousedown.y = this.cord.mousemove.y
  },
  onMouseUp() {
    document.querySelector('body').classList.remove('text-no-selection')
    if (!this.hit) {
      return
    }
    this.toggleCameraControls(true)
    this.mouseDown = false
    this.isDragging = false
    if (this.hit && this.shouldSave) this.hit.emit('save-position')
    this.raytracer.setAttribute('raytracer', 'objects: .clickable')
    if (this.isCleared) {
      this.hit.emit('intersect-cleared')
      this.hit = null

      this.isCleared = false
    }
  },
  onIntersection(event) {
    if (!this.isDragging) {
      const [hit] = event.detail.els
      this.hit = hit
      this.hit.emit('intersect')
      this.raytracer.setAttribute('raytracer', 'objects: .selected')
    }
  },
  onIntersectionCleared(event) {
    const cleared = event.detail.clearedEls[0]
    if (cleared) {
      cleared.emit('intersect-cleared')

      if (this.isDragging) this.isCleared = cleared
      if (!this.isDragging) {
        this.hit = null
      }
    }
  },
  handleClickOutside(event) {
    // Get the component element
    const componentElement = document.getElementById('your-component-id')

    // Check if the clicked element is inside the component
    if (componentElement && !componentElement.contains(event.target)) {
      // The click was outside the component
      // Handle this case here
    }
  },
  init() {
    // Scene elements
    this.raytracer = this.el
    this.scene = document.querySelector('a-scene')
    this.camera = document.querySelector('a-camera')

    // movement coordinates
    this.cord = {
      mousedown: {
        x: null,
        y: null,
      },
      mousemove: {
        x: null,
        y: null,
      },
    }

    // state
    this.mouseDown = false
    this.isDragging = false
    this.isCleared = false
    this.isDirectionElement = false
    this.cameraControlsEnabled = true
    this.shouldSave = false

    // events
    this.scene.addEventListener('loaded', () => {
      this.scene.addEventListener(
        'selectstart',
        (e) => e.preventDefault(),
        false
      )

      this.scene.addEventListener('mousedown', this.onMouseDown.bind(this))
      this.scene.addEventListener('mousemove', this.onMouseMove.bind(this))
      this.scene.addEventListener('mouseup', this.onMouseUp.bind(this))

      this.raytracer.addEventListener(
        'raycaster-intersection',
        this.onIntersection.bind(this)
      )
      this.raytracer.addEventListener(
        'raycaster-intersection-cleared',
        this.onIntersectionCleared.bind(this)
      )
    })
  },
  remove() {
    // clean up
    this.scene.removeEventListener('mousedown', this.onMouseDown.bind(this))
    this.scene.removeEventListener('mousemove', this.onMouseMove.bind(this))
    this.scene.removeEventListener('mouseup', this.onMouseUp.bind(this))
    this.unSelectAll()

    const activeSidebarCard = document.querySelector(
      '.scroll-container .active'
    )
    if (activeSidebarCard) activeSidebarCard.classList.remove('active')
  },
})
