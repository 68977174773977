import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import {
  addGroupMutation,
  getGroupNamesQuery,
} from '../../../apollo/query/groups'
import { handleApolloError } from '../../../utils/errors'
import { sortOnKey } from '../../../utils/format'
import DataTooltip from '../../UI/DataTooltip'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'

const TraineeDetailGroupsList = ({ groupRef, userGroups }) => {
  const getNames = (group) => {
    const courseNames = group.courses.map(({ name }) => name)
    const scenarioNames = group.publishedScenarios.map(({ name }) => name)

    return [...scenarioNames, ...courseNames].join(', ')
  }
  const parsedData = (group) => {
    return {
      render: group.name,
      value: group.name,
      id: group.id,
      count: {
        courses: group.courses.length,
        scenarios: group.publishedScenarios.length,
      },
      names: {
        courses: group.courses.map(({ name }) => name),
        scenarios: group.publishedScenarios.map(({ name }) => name),
      },
      allNames: getNames(group),
    }
  }
  const [groupList, setGroupList] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [show, setShow] = useState(false)
  const [searchKey, setSearchKey] = useState('')

  const [getGroups, { loading }] = useLazyQuery(getGroupNamesQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ groups }) => {
      const ids = groupList.map((group) => group.id)
      const groupsArray = groups.reduce((array, group) => {
        if (!group.automated && !ids.includes(group.id))
          array.push(parsedData(group))
        return array
      }, [])
      setAllGroups(sortOnKey(groupsArray, 'render', false))
    },
  })

  const [addNewGroup] = useMutation(addGroupMutation, {
    onError: handleApolloError,
    onCompleted: ({ addGroup: { group } }) => {
      group = parsedData(group)
      addGroup(group)
      setAllGroups(sortOnKey([...allGroups, group], 'render', false))
      setShow(false)
      setSearchKey('')
    },
  })

  useEffect(() => {
    groupRef.current = groupList.map(({ id }) => id)
  }, [groupList])

  useEffect(() => {
    if (userGroups) {
      setGroupList(userGroups.map((group) => parsedData(group)))
    }
  }, [userGroups])

  const removeGroup = (id) => {
    const newGroupList = groupList.filter((group) => group.id !== id)
    setGroupList(newGroupList)
  }

  const addGroup = (group) => {
    setGroupList([...groupList, group])
  }

  const getCount = (count) => {
    const text = []
    if (count.scenarios)
      text.push(
        `${count.scenarios} ${count.scenarios === 1 ? 'scenario' : 'scenarios'}`
      )
    if (count.courses)
      text.push(
        `${count.courses} ${count.courses === 1 ? 'course' : 'courses'}`
      )
    return text.join(', ')
  }

  const formatName = (value) => `<li style="text-align:start;">${value}</li>`

  const getTitle = (group, type) => {
    const { scenarios, courses } = group.count
    let count

    if (type === 'scenarios') {
      if (scenarios === 0) return ''
      count = `Access to ${scenarios} scenario${scenarios === 1 ? '' : 's'}`
    }
    if (type === 'courses') {
      if (courses === 0) return ''
      count = `Access to ${courses} course${courses === 1 ? '' : 's'} `
    }
    const list = group.names[type]
      .sort()
      .map((name) => formatName(name))
      .join(' ')
    const listStyle = `
      margin-right: 10px; 
      margin-bottom: ${scenarios > 0 && courses > 0 ? '20px' : '0'};
      margin-top: 10px;
    `

    return `
      <span className="text-white" style="text-align:start;font-weight:700;">
       ${count}
     </span>
     <ul style="${listStyle}">
       ${list}
     </ul>`
  }

  const showDetailGroupList = () => {
    if (!groupList.length)
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            height: '100px',
            border: '1px solid #dddddd',
            borderRadius: '5px',
          }}>
          <p className="text-italic mb-0">No groups selected</p>
        </div>
      )
    return (
      <ul className="o-modal__video--list">
        {groupList.map((group) => {
          const title = `
            <div style="padding:10px; text-align:start;">
              ${getTitle(group, 'scenarios')} 
              ${getTitle(group, 'courses')}
            </div>
            `
          return (
            <li key={group.id} style={{ padding: '0 20px' }}>
              <div
                style={{
                  flexGrow: '1',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}>
                <span style={{ flexGrow: '1' }}> {group.render}</span>
                <span className="text-underline text-stable-dark">
                  <DataTooltip title={title}>
                    {getCount(group.count)}
                  </DataTooltip>
                </span>
                <span
                  className="cursor-pointer"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={() => removeGroup(group.id)}>
                  <FiX style={{ marginLeft: '10px' }} size="16" />
                </span>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  const footer = (
    <div
      className="flex-container align-middle padding-vertical-1 text-stable-dark"
      onClick={() =>
        addNewGroup({
          variables: {
            name: searchKey,
            publishedScenarioIds: [],
            courseIds: [],
          },
        })
      }>
      <FaPlus className="mr-1" />
      <div>
        <span className="text-bold">{'Create a new group – '}</span>
        <span className="text-dark">{searchKey}</span>
      </div>
    </div>
  )

  return (
    <div>
      {showDetailGroupList()}
      <div style={{ marginTop: '20px', marginBottom: '5px' }}>
        <button
          className="button hollow secondary text-bold mb-1 mt1"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            getGroups()
            setShow(true)
          }}>
          <FaPlus className="mr-1" /> Select group
        </button>
        {show && (
          <SearchableDropdown
            placeholder="Search or create a group"
            clickHandler={(group) => {
              addGroup(group)
              setShow(false)
            }}
            hideHandler={() => setShow(false)}
            loading={loading}
            notFoundTitle="No groups found"
            notFoundText={
              <small>
                {`You don't have any groups to add or you have linked this
                  user to all groups`}
              </small>
            }
            data={allGroups}
            onChangeSearchKey={setSearchKey}
            footer={searchKey && footer}
          />
        )}
      </div>
    </div>
  )
}
export default TraineeDetailGroupsList
