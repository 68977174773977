import { useEffect, useState } from 'react'
import { DirectUpload } from '@rails/activestorage'
import { v4 as uuidv4 } from 'uuid'

const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

const useFileUploader = () => {
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const updateFile = (file) => {
    setFiles((state) => state.map((f) => (f.id === file.id ? file : f)))
  }

  const uploadDelegate = (file) => {
    const onStartUpload = (xhr) => {
      xhr.upload.addEventListener('progress', (e) => {
        file.progress = Math.round((100 * e.loaded) / e.total)
        updateFile(file)
      })
    }

    return { directUploadWillStoreFileWithXHR: onStartUpload }
  }

  const uploadFile = (file) => {
    file.state = 'UPLOADING'
    updateFile(file)

    // Start uploading
    const upload = new DirectUpload(
      file,
      DIRECT_UPLOAD_URL,
      uploadDelegate(file)
    )
    upload.create((error, blob) => {
      if (error) {
        file.error = error
        updateFile(file)
      } else {
        file.blobId = blob.signed_id
        updateFile(file)
      }
      setIsUploading((state) => !state)
    })
  }

  useEffect(() => {
    const next = files.find((f) => f.state === 'QUEUED_FOR_UPLOADING')
    if (next) {
      uploadFile(next)
    }
  }, [isUploading])

  const handleUploadFiles = (f) => {
    const filesToUpload = f.map((file) => {
      file.id = uuidv4()
      file.error = null
      file.state = 'QUEUED_FOR_UPLOADING'
      file.progress = 0
      return file
    })
    setFiles((state) => [...state, ...filesToUpload])

    if (!isUploading) {
      return setIsUploading((state) => !state)
    }

    return null
  }

  return [files, handleUploadFiles, isUploading]
}
export default useFileUploader
