import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../../UI/Modal'
import {
  adminGetAllProductsQuery,
  adminAddPurchaseMutation,
  adminGetAllPurchasesQuery,
} from '../../../../apollo/query/admin/store'
import {
  getBuyingClientsQuery,
  getClientsNamesQuery,
} from '../../../../apollo/query/clients'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import { sortOnKey } from '../../../../utils/format'
import { getProductsQuery } from '../../../../apollo/query/products'
import {
  addPurchaseMutation,
  getSellerTransactionsQuery,
} from '../../../../apollo/query/transactions'
import { getWorkspacesQuery } from '../../../../apollo/query/workspaces'

const AddTransactionModal = ({ id: modalId, appendTo, adminPanel }) => {
  const [key, setKey] = useState(uuidv4())
  const [values, setValues] = useState({
    soldBy: null,
    soldTo: null,
    productId: null,
    licenses: null,
  })
  const { data, loading, error } = useQuery(
    adminPanel ? adminGetAllProductsQuery : getProductsQuery
  )
  const { data: clients, error: buyingClientsError } = useQuery(
    adminPanel ? getClientsNamesQuery : getBuyingClientsQuery
  )
  const {
    data: workspacesData,
    loading: workspacesLoading,
    error: workspacesError,
  } = useQuery(getWorkspacesQuery)

  const addTransactionSuccessAlert = () =>
    addSuccessAlert({ title: 'Transaction added' })

  const [addPurchase] = useMutation(addPurchaseMutation, {
    update: (cache, { data: { addPurchase } }) => {
      const { purchases } = cache.readQuery({
        query: getSellerTransactionsQuery,
      })
      if (purchases && addPurchase.purchase) {
        cache.writeQuery({
          query: getSellerTransactionsQuery,
          data: {
            purchases: [...purchases, addPurchase.purchase],
          },
        })
      }
    },
    onCompleted: addTransactionSuccessAlert,
    onError: handleApolloError,
  })

  const [addAdminPurchase] = useMutation(adminAddPurchaseMutation, {
    update: (cache, { data: { addPurchase } }) => {
      const { allPurchases } = cache.readQuery({
        query: adminGetAllPurchasesQuery,
      })
      if (allPurchases && addPurchase.purchase) {
        cache.writeQuery({
          query: adminGetAllPurchasesQuery,
          data: {
            allPurchases: [...allPurchases, addPurchase.purchase],
          },
        })
      }
    },
    onCompleted: addTransactionSuccessAlert,
    onError: handleApolloError,
  })

  if (error || workspacesError || buyingClientsError)
    throw new Error(error.message)

  if (loading || !data || !clients || workspacesLoading)
    return (
      <Modal
        id={modalId}
        appendTo={appendTo}
        useDefaultButtons
        closeOnEscape={false}
      />
    )

  const getOrganisations = () => {
    return data.allProducts.reduce((a, { client: { name, id } }) => {
      const ids = a.map(({ id: i }) => i)
      if (!ids.includes(id)) a.push({ name, id })
      return a
    }, [])
  }

  const getProducts = () => {
    const options = []
    options.push(
      <option disabled key="selectProduct" value="">
        Select product
      </option>
    )

    if (
      (adminPanel && !values.soldBy) ||
      (!adminPanel && !data.products.length)
    )
      return options

    const temp = data[adminPanel ? 'allProducts' : 'products'].reduce(
      (a, product) => {
        if (
          product.state === 'READY' &&
          (!adminPanel || product.client.id === values.soldBy) //This condition should be checked only on admin panel
        ) {
          a.push(product)
        }
        return a
      },
      []
    )

    const o = sortOnKey(temp, 'name', false).map((product) => (
      <option key={product.id} value={product.id}>
        {product.name}
      </option>
    ))

    return [...options, ...o]
  }

  const getClients = () => {
    if (!clients.clients) return []
    const temp = sortOnKey(
      [
        ...clients.clients,
        ...workspacesData.workspaces.filter(
          (w) => !clients.clients.find((c) => c.id === w.id)
        ),
      ],
      'name',
      false
    )
    return temp.reduce((a, i) => {
      if (i.id !== values.soldBy) {
        a.push(
          <option key={i.id} disabled={i.id === values.soldBy} value={i.id}>
            {i.name}
          </option>
        )
      }
      return a
    }, [])
  }
  const clear = () => {
    setTimeout(() => {
      setKey(uuidv4())
      setValues({ soldBy: null, soldTo: null, productId: null, licenses: null })
    }, 300)
  }
  const validateFields = (e) => {
    const { soldBy, productId, soldTo, licenses } = values
    if (adminPanel && !soldBy)
      document.querySelector('#sold-by').classList.add('input--required')
    if (!productId)
      document.querySelector('#select-product').classList.add('input--required')
    if (!soldTo)
      document.querySelector('#sold-to').classList.add('input--required')
    if (!licenses)
      document.querySelector('#licenses-sold').classList.add('input--required')

    if ((adminPanel && !soldBy) || !productId || !soldTo || !licenses) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    return true
  }
  const submit = (e) => {
    const isValid = validateFields(e)
    if (isValid) {
      const variables = {
        buyingClientId: values.soldTo,
        productId: values.productId,
        purchasedLicenseCount: values.licenses,
      }
      if (adminPanel) {
        variables.sellingClientId = values.soldBy
        addAdminPurchase({
          variables,
        })
      } else {
        addPurchase({
          variables,
        })
      }
      setTimeout(() => clear(), 300)
    }
  }

  const getSupportEmailLink = () => (
    <a className="u-color-stable-dark" href="mailto:support@warpvr.com">
      support@warpvr.com
    </a>
  )

  return (
    <Modal
      id={modalId}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      cancelHandler={clear}
      submitHandler={submit}
      headerText="New transaction">
      <form className="mt-3" key={key}>
        {adminPanel && (
          <div className="cell small-12">
            <label htmlFor="sold-by" className="c-form__label--required">
              Sold by
            </label>
            <select
              name="select"
              id="sold-by"
              required
              value={values.soldBy || ''}
              onChange={({ target }) =>
                setValues({
                  ...values,
                  soldBy: target.value,
                  productId: null,
                  soldTo: null,
                })
              }>
              <option value="" disabled>
                Select an organisation
              </option>
              {sortOnKey(getOrganisations(), 'name', false).map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="cell small-12">
          <label
            htmlFor="select-product"
            defaultValue="selectProduct"
            className="c-form__label--required">
            Select product
          </label>
          <select
            name="select"
            id="select-product"
            value={values.productId || ''}
            required
            onChange={({ target }) => {
              setValues({ ...values, productId: target.value })
            }}>
            {getProducts()}
          </select>
        </div>
        <div className="row">
          <div className="cell small-6 pr-1">
            <label htmlFor="sold-to" className="c-form__label--required">
              Sold to
            </label>
            <select
              name="select"
              value={values.soldTo || ''}
              id="sold-to"
              required
              onChange={({ target }) =>
                setValues({ ...values, soldTo: target.value })
              }>
              <option disabled value="">
                Select buyer
              </option>
              {getClients()}
            </select>
          </div>
          <div className="cell small-6 pl-1">
            <label htmlFor="licenses-sold" className="c-form__label--required">
              Licenses sold
            </label>
            <input
              type="number"
              name="licenses"
              id="licenses-sold"
              required
              value={values.licenses || ''}
              onChange={({ target }) =>
                setValues({ ...values, licenses: Number(target.value) })
              }
            />
          </div>
        </div>
      </form>
      {!adminPanel && (
        <p>Contact {getSupportEmailLink()} when a workspace is not listed</p>
      )}
    </Modal>
  )
}
export default AddTransactionModal
