import React from 'react'
import ConfigAuthenticator from './ConfigAuthenticator'
import Credentials from './Credentials'
import useCurrentUser from '../../hooks/useCurrentUser'
import { FaShieldAlt } from 'react-icons/fa'
import PasswordForm from '../UI/Form/PasswordForm'
import { addSuccessAlert } from '../../utils/helpers/alerts'

const PasswordTab = () => {
  const [currentUser] = useCurrentUser()

  if (!currentUser) return null

  if (currentUser.ssoManaged)
    return (
      <div className="border-royal p-2 flex-container align-top border-radius text-royal xlarge-9 medium-10 text-normal">
        <FaShieldAlt style={{ marginTop: '3px' }} />
        <span className="ml-1 text-dark">
          Because your organization uses Single Sign On (SSO), there are no
          password and authentication settings required for your account.
        </span>
      </div>
    )

  return (
    <>
      <div className="row border-bottom pb-3 mb-3">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Change password</h2>
          <p>
            Make sure your new password is at least 15 characters including a
            number and a lowercase letter.
          </p>
        </div>
        <div className="xlarge-6 medium-8 small-12 columns">
          <PasswordForm
            showCurrentPassword
            onCompleted={() =>
              addSuccessAlert({ title: 'Password was changed!' })
            }
          />
        </div>
      </div>

      <div className="row border-bottom pb-3 mb-3">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Two-factor authentication</h2>
          <p>
            Two-factor authentication adds an additional layer of security to
            your account by requiring more than just a password to sign in.
          </p>
        </div>
        <div className="xlarge-6 medium-8 small-12 columns">
          <ConfigAuthenticator currentUser={currentUser} />
        </div>
      </div>

      <div className="row border-bottom pb-3 mb-3">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Passkeys</h2>
          <p>
            Passkeys are a password replacement that validates your identity
            using touch, facial recognition, a device password, or a PIN.
            Passkeys can be used for sign-in as a simple and secure alternative
            to your password and two-factor credentials.
          </p>
        </div>
        <div className="xlarge-6 medium-8 small-12 columns">
          <Credentials currentUser={currentUser} />
        </div>
      </div>
    </>
  )
}
export default PasswordTab
