import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useRef, useState } from 'react'
import { FaArrowRight, FaExclamationTriangle, FaUndo } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import { addUserToGroupsMutation } from '../../../apollo/query/groups'
import {
  addUserMutation,
  deleteUsersMutation,
  getUserByEmailQuery,
  updateUserMutation,
} from '../../../apollo/query/user'
import { handleApolloError } from '../../../utils/errors'
import LoadingSpinner from '../../UI/LoadingSpinner'
import Modal from '../../UI/Modal'
import ImportTraineesForm from '../Forms/ImportTraineesForm'
import TraineeDetailGroupsList from '../Forms/TraineeDetailGroupsList'
import TraineeDetailSelectRole from '../TraineeDetailSelectRole'

const AddTraineeModal = ({ id, appendTo, importHandler, callback }) => {
  // Form state management
  const [key, setKey] = useState(uuidv4())
  const [importMany, setImportMany] = useState(false)

  // Add trainee management
  const groupIdsRef = useRef()
  const [existingTrainee, setExistingTrainee] = useState(false)
  const [step, setStep] = useState(0)
  const [values, setValues] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    employeeNumber: '',
    role: 'USER', // Default add trainees as USER
  })

  const [addUser] = useMutation(addUserMutation, {
    onError: handleApolloError,
    onCompleted: ({ addUser: { user } }) => {
      setValues({
        ...values,
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        email: user.email,
      })
      if (user?.activated) {
        setExistingTrainee(user)
      }
    },
  })

  const [addUserToGroups] = useMutation(addUserToGroupsMutation, {
    onError: handleApolloError,
  })
  const [deleteUsers] = useMutation(deleteUsersMutation, {
    onError: handleApolloError,
  })
  const [getUserByEmail] = useLazyQuery(getUserByEmailQuery, {
    onCompleted: async ({ users }) => {
      if (!users.length) {
        setExistingTrainee(null)
        await addUser({
          variables: {
            ...values,
          },
        })
        return setStep(1)
      }
      return setExistingTrainee(users[0])
    },
  })

  const [updateTrainee] = useMutation(updateUserMutation, {
    onError: handleApolloError,
  })
  const clear = () => {
    // Timeout is needed to wait for form to close, before resetting state
    setTimeout(() => {
      setKey(uuidv4())
      setImportMany(false)
      setExistingTrainee(null)
      setValues({
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        role: 'USER',
      })
      setStep(0)
    }, 300)
  }

  const resetForm = () => {
    setValues({ ...values, email: '' })
    setExistingTrainee(null)
    document.querySelector('#emailInput').focus()
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (!values.email) return null
    return getUserByEmail({
      variables: {
        email: values.email.toLowerCase(),
      },
    })
  }

  const updateUserHandler = async (e) => {
    if (!existingTrainee && (!values.firstName || !values.lastName)) {
      if (!values.firstName)
        document
          .querySelector('#firstNameInput')
          .classList.add('input--required')
      if (!values.lastName)
        document
          .querySelector('#lastNameInput')
          .classList.add('input--required')
      e.preventDefault()
      e.stopPropagation()
      return null
    }
    if (groupIdsRef.current.length) {
      await addUserToGroups({
        variables: {
          userIds: [values.userId],
          groupIds: groupIdsRef.current,
        },
      })
    }
    updateTrainee({
      variables: {
        ...values,
      },
    })
    callback()
    return clear()
  }

  const cancelHandler = () => {
    deleteUsers({
      variables: {
        ids: [values.userId],
      },
    })
    clear()
  }
  const addUserButton = () => {
    if (existingTrainee)
      return (
        <button
          className="cell shrink button primary mb-0"
          onClick={(e) => {
            e.preventDefault()
            resetForm()
          }}>
          <FaUndo />
        </button>
      )
    return (
      <button className="cell shrink button primary mb-0" type="submit">
        <FaArrowRight />
      </button>
    )
  }
  const addUserByEmail = () => (
    <form onSubmit={submitHandler}>
      <div className="grid-x grid-margin-x">
        <label
          htmlFor="emailInput"
          className="cell small-12 c-form__label--required">
          Email
        </label>
        <input
          type="email"
          name="Email"
          id="emailInput"
          className="cell auto mr-0 mb-0"
          required
          onFocus={() => !!existingTrainee && resetForm()}
          onChange={({ target }) => {
            setValues({ ...values, email: target.value })
          }}
        />
        {addUserButton()}
      </div>
      <div className="cell small-12 text-assertive mt-0" id="emailHelpText">
        {!!existingTrainee && (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
            }}>
            <FaExclamationTriangle style={{ marginRight: '10px' }} />
            This trainee already exists in your organisation.
          </span>
        )}
      </div>
      <div className="clearfix o-modal--footer pl-0">
        <div className="w-100">
          <small>
            <span
              className="cursor-pointer text-stable-dark text-underline"
              onClick={() => setImportMany(true)}>
              Or upload many trainees at once.
            </span>
          </small>
        </div>
      </div>
    </form>
  )

  const getAdditionalInformation = () => {
    return (
      <form>
        <div className="mb-3">
          <div className="grid-x grid-margin-x">
            <p className="text-bold text-dark cell shrink">Email address</p>
            <p className="cell shrink">{values.email}</p>
          </div>
        </div>
        <div className="grid-x grid-margin-x">
          <div className="cell small-6">
            <label
              htmlFor="firstNameInput"
              className={!existingTrainee ? 'c-form__label--required' : ''}>
              First name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstNameInput"
              className="cell auto"
              disabled={!!existingTrainee?.firstName}
              defaultValue={values.firstName}
              onChange={({ target: { value } }) =>
                setValues({ ...values, firstName: value })
              }
              required={!existingTrainee}
            />
          </div>
          <div className="cell small-6">
            <label
              htmlFor="lastNameInput"
              className={!existingTrainee ? 'c-form__label--required' : ''}>
              Last name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastNameInput"
              className="cell auto"
              disabled={!!existingTrainee?.lastName}
              defaultValue={values.lastName}
              onChange={({ target: { value } }) =>
                setValues({ ...values, lastName: value })
              }
              required={!existingTrainee}
            />
          </div>
        </div>
        <div className="grid-x grid-margin-x">
          <div className="cell small-6">
            <label htmlFor="employeeNumberInput">Employee No</label>
            <input
              type="text"
              name="employeeNumber"
              id="employeeNumberInput"
              className="cell auto"
              defaultValue={values.employeeNumber}
              onChange={({ target: { value } }) =>
                setValues({ ...values, employeeNumber: value })
              }
            />
          </div>
          <div className="cell small-6">
            <label htmlFor="roleSelector">Select Role</label>
            <TraineeDetailSelectRole
              selected={values.role}
              changeHandler={(value) => setValues({ ...values, role: value })}
            />
          </div>
        </div>
        <label>Add trainee to groups</label>
        <TraineeDetailGroupsList
          groupRef={groupIdsRef}
          userGroups={existingTrainee?.groups}
        />
      </form>
    )
  }

  const createForms = () => {
    if (importMany) {
      return (
        <ImportTraineesForm
          modalId={id}
          cancelHandler={clear}
          callback={importHandler}
        />
      )
    }
    if (step === 0) return addUserByEmail()
    if (step === 1) return getAdditionalInformation()
    return <LoadingSpinner dotsOnly />
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="New trainee"
      subtitle="Add new trainee by email or import many at once."
      useDefaultButtons={!importMany && step === 1}
      submitHandler={updateUserHandler}
      cancelHandler={cancelHandler}
      closeOnEscape={false}
      overflowY="visible">
      <span key={key}>{createForms()}</span>
    </Modal>
  )
}

export default AddTraineeModal
