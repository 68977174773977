import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaImage, FaTrash } from 'react-icons/fa'
import Table from '../components/Table'
import Toast from '../components/UI/Notifications/Toast'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import TableHeader from '../components/UI/TableHeader'
import withApollo from '../hooks/withApollo'
import Delay from '../utils/helpers/Delay'
import Checkbox from '../components/UI/Form/Checkbox'
import { default as S } from '../utils/lang/en.json'
import { sortOnKey, dateFormat } from '../utils/format'
import { getCoursesQuery } from '../apollo/query/courses'
import AddCourseModal from '../components/Courses/Modal/AddCourseModal'
import EditCourseModal from '../components/Courses/Modal/EditCourseModal'
import DeleteCourseModal from '../components/Courses/Modal/DeleteCourseModal'
import CoursesEmptyPage from './Courses/CoursesEmptyPage'
import useCurrentUser from '../hooks/useCurrentUser'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import TableColumnsDropdown from '../components/UI/TableColumnsDropdown'
import CourseOptionDropdown from '../components/Courses/CourseOptionDropdown'
import usePreferences from '../hooks/usePreferences'
import { filterColumnsHandler, sortHandler } from '../components/tableFunctions'
import TableSearchBar from '../components/UI/Table/TableSearchBar'
import TableAddButton from '../components/UI/Table/TableAddButton'
import GroupCourseScenarioTooltip from '../components/Groups/Tooltips/GroupCourseScenarioTooltip'
import { getCurrentPlanQuery } from '../apollo/query/plans'
import CoursesUpgradeNeededPage from './Scenarios/CoursesUpgradeNeededPage'

const Courses = () => {
  const { loading: currentPlanLoading, data: currentPlanData } =
    useQuery(getCurrentPlanQuery)
  const coursesFeatureEnabled =
    currentPlanData && currentPlanData.currentPlan.features.includes('COURSES')

  const [preferences, setPreferences] = usePreferences(
    'courses',
    'name',
    !coursesFeatureEnabled
  )
  const [selectedItems, setSelectedItems] = useState([])
  const [currentCourse, setCurrentCourse] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [dismissToast, setDismissToast] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState(false)

  const [currentUser] = useCurrentUser()

  const { loading, data } = useQuery(getCoursesQuery, {
    skip: !coursesFeatureEnabled,
  })

  if (
    currentPlanLoading ||
    loading ||
    (coursesFeatureEnabled && (!data || !preferences || !currentUser))
  )
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )

  if (!coursesFeatureEnabled) return <CoursesUpgradeNeededPage />

  const tableHeaders = ['name', 'scenarios', 'createdAt']

  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.courses.headers[item]}
          />
        </li>
      )
    })
  }

  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    return setSelectedItems(() => newSelection)
  }

  const sortedData = () => {
    const filtered = [...data.courses].filter((course) => {
      const searchData = `${course.name} ${course.publishedScenarios.map(
        (s) => s.scenario.name
      )}`
      return searchData.toLowerCase().includes(searchQuery.toLowerCase())
    })

    const rows = filtered || [...data.courses]

    if (!rows.length && !showEmptyState) setShowEmptyState(true)
    if (rows.length && showEmptyState) setShowEmptyState(false)

    const parsedData = rows.map((course) => {
      return {
        id: course.id,
        name: course.name,
        description: course.description,
        scenarios: {
          value: course.publishedScenarios.length,
          render: (
            <GroupCourseScenarioTooltip
              count={course.publishedScenarios.length}
              names={course.publishedScenarios.map(
                (scenario) => scenario.scenario.name
              )}
              type="scenario"
            />
          ),
        },
        createdAt: dateFormat(course.createdAt),
      }
    })

    return sortOnKey(
      parsedData,
      preferences.sortOn.value,
      preferences.sortOn.isAscending
    )
  }

  const renderOptions = (id) => {
    return (
      <CourseOptionDropdown
        id={id}
        setCurrentCourse={(c) => setCurrentCourse(c)}
        courses={data.courses}
      />
    )
  }

  const loadModals = () => {
    const appendTo = '#courses'
    return (
      <>
        <AddCourseModal id="add-course-modal" appendTo={appendTo} />
        <EditCourseModal
          id="edit-course-modal"
          appendTo={appendTo}
          course={currentCourse}
          setCourse={(c) => setCurrentCourse(c)}
        />
        <DeleteCourseModal
          id="delete-course-modal"
          appendTo={appendTo}
          ids={currentCourse.id ? [currentCourse.id] : selectedItems}
          clearSelectedItems={() =>
            currentCourse.id ? setCurrentCourse({}) : setSelectedItems([])
          }
        />
      </>
    )
  }

  if (!data.courses.length) return <CoursesEmptyPage />

  return (
    <div id="courses">
      {loadModals()}
      <div className="grid-container fluid">
        <TableHeader
          title="Course"
          length={[...sortedData()].length}
          leftBar={
            <TableSearchBar
              searchQuery={searchQuery}
              showEmptyState={showEmptyState}
              setSearchQuery={setSearchQuery}
              setShowEmptyState={setShowEmptyState}
            />
          }
          rightBar={
            <>
              <TableColumnsDropdown content={content} />
              <TableAddButton dataOpen="add-course-modal" title="New course" />
            </>
          }
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        {showEmptyState ? (
          <FilterEmptyState
            type="courses"
            icon={<FaImage />}
            clickHandler={() => {
              setSearchQuery('')
              setSelectedItems([])
              setShowEmptyState(false)
            }}
          />
        ) : (
          <Table
            data={sortedData()}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            sortOn={preferences.sortOn}
            sortHandler={(value) =>
              sortHandler(value, preferences, setPreferences)
            }
            selectedItems={selectedItems}
            selectable
            checkboxHandler={checkboxHandler}
            selectAllHandler={(ids) => setSelectedItems(ids)}
            type="courses"
            rowSuffix={(i) => renderOptions(i)}
          />
        )}
      </div>

      <Toast
        dismissable
        isClosing={dismissToast}
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'courses' : 'course'}
        closeHandler={() => {
          setDismissToast(true)
          setTimeout(() => {
            setDismissToast(false)
          }, 300)
          setTimeout(() => {
            setSelectedItems([])
          }, 10)
        }}>
        <button className="danger" data-open="delete-course-modal">
          <FaTrash />
        </button>
      </Toast>
    </div>
  )
}

export default withApollo(Courses)
