import DiagramStore from '../../flow/stores/diagram'
import config from '../config/config'
import { setCentralNodeAndSelectIt } from './nodeHelper'

export const fitView = (reactFlow) => reactFlow.fitView(config.fitViewOptions)

const getZooms = (showVideoEditor) =>
  config[showVideoEditor ? 'videoZooms' : 'zooms']

const getZoomIndex = (zoomIn, camera, reactFlow) => {
  const zooms = getZooms(!!camera)
  const currentZoom = getCurrentZoom(camera, reactFlow)

  let zoomIndex = zooms.indexOf(currentZoom)
  if (zoomIndex === -1) {
    const nearestZoom = zooms.reduce((prevZoom, currZoom) => {
      if (
        zoomIn &&
        currZoom > currentZoom &&
        (currZoom < prevZoom || prevZoom <= currentZoom)
      )
        return currZoom

      if (
        !zoomIn &&
        currZoom < currentZoom &&
        (currZoom > prevZoom || prevZoom >= currentZoom)
      )
        return currZoom

      return prevZoom
    }, currentZoom)

    zoomIndex = zooms.indexOf(Number(nearestZoom.toFixed(2)))
  } else {
    zoomIndex = zoomIndex + (zoomIn ? 1 : -1)
  }
  return zoomIndex
}

const getCurrentZoom = (camera, reactFlow) => {
  return camera ? Number(camera.getAttribute('zoom')) || 1 : reactFlow.getZoom()
}

const getNextZoom = (zoomIn = true, camera, reactFlow) => {
  const zooms = getZooms(!!camera)
  return (
    zooms[getZoomIndex(zoomIn, camera, reactFlow)] ??
    (zoomIn ? zooms.slice(-1)[0] : zooms[0])
  )
}

const zoomTo = (reactFlow, nextZoom, camera) => {
  if (camera) {
    camera.setAttribute(
      'animation',
      `property: zoom; to: ${nextZoom}; loop: false; dur: 200`
    )
    setTimeout(() => {
      camera.removeAttribute('animation')
      camera.setAttribute('zoom', nextZoom)
    }, 200)
  } else {
    reactFlow.zoomTo(nextZoom, {
      duration: config.zoomDuration,
    })
  }
}

export const zoomIn = (reactFlow, showVideoEditor) => {
  const camera = showVideoEditor ? document.querySelector('#a-camera') : null
  const nextZoom = getNextZoom(true, camera, reactFlow)

  const currentZoom = getCurrentZoom(camera, reactFlow)
  if (currentZoom === nextZoom) return currentZoom

  zoomTo(reactFlow, nextZoom, camera)
  return nextZoom
}

export const zoomOut = (reactFlow, showVideoEditor) => {
  const camera = showVideoEditor ? document.querySelector('#a-camera') : null
  const nextZoom = getNextZoom(false, camera, reactFlow)

  const currentZoom = getCurrentZoom(camera, reactFlow)
  if (currentZoom === nextZoom) return currentZoom

  zoomTo(reactFlow, nextZoom, camera)
  return nextZoom
}

export const zoomToDefault = (reactFlow, showVideoEditor) => {
  const camera = showVideoEditor ? document.querySelector('#a-camera') : null
  zoomTo(reactFlow, 1, camera)
  return 1
}

export const getSceneNumbers = (reactFlow) =>
  reactFlow
    .getNodes()
    .filter((n) => n.data.number)
    .map((n) => Number(n.data.number))
    .sort((a, b) => a - b)

const getSelectedNode = (reactFlow) =>
  reactFlow.getNodes().find((n) => n.selected)

export const getPrevNode = (reactFlow) => {
  const selectedNode = getSelectedNode(reactFlow)
  if (!selectedNode) return false
  const sceneNumbers = getSceneNumbers(reactFlow)
  const selectedNodeIndex = sceneNumbers.indexOf(selectedNode.data.number)
  const prevSceneNumber = sceneNumbers.at(selectedNodeIndex - 1)
  return reactFlow.getNodes().find((n) => n.data.number === prevSceneNumber)
}

export const getNextNode = (reactFlow) => {
  const selectedNode = getSelectedNode(reactFlow)
  if (!selectedNode) return false
  const sceneNumbers = getSceneNumbers(reactFlow)
  const selectedNodeIndex = sceneNumbers.indexOf(selectedNode.data.number)
  const nextSceneNumber =
    sceneNumbers[(selectedNodeIndex + 1) % sceneNumbers.length]
  return reactFlow.getNodes().find((n) => n.data.number === nextSceneNumber)
}

export const getHomeNode = (reactFlow) =>
  reactFlow.getNodes().find((node) => node.data.start)

export const saveCanvasState = (reactFlow, viewport) => {
  const { x, y, zoom } = viewport || reactFlow.getViewport()

  const state = {
    zoom: zoom,
    midPoint: {
      x,
      y,
    },
  }
  const scenarioUrl = window.location.pathname.slice(0, -1)
  window.localStorage.setItem(scenarioUrl, JSON.stringify(state))
}

export const getCanvasState = () => {
  const scenarioUrl = window.location.pathname.slice(0, -1)
  let state = window.localStorage.getItem(scenarioUrl)
  if (state) {
    state = JSON.parse(state)
    if (state.zoom) {
      return {
        x: state.midPoint.x,
        y: state.midPoint.y,
        zoom: state.zoom,
      }
    }
  }
  return null
}

export const goToHomeScene = (reactFlow) => {
  setCentralNodeAndSelectIt(reactFlow, getHomeNode(reactFlow))
}

export const goToNextScene = (reactFlow) => {
  const nextNode = getNextNode(reactFlow)
  if (!nextNode) return false
  setCentralNodeAndSelectIt(reactFlow, nextNode)
}

export const goToPrevScene = (reactFlow) => {
  const prevNode = getPrevNode(reactFlow)
  if (!prevNode) return false
  setCentralNodeAndSelectIt(reactFlow, prevNode)
}
