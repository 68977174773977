import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { FaSearch } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../LoadingSpinner'
import { sortOnKey } from '../../../utils/format'

const SearchableDropdown = forwardRef(
  (
    {
      placeholder,
      position = 'left',
      style,
      loading,
      data,
      clickHandler,
      show = true,
      notFoundTitle,
      notFoundText,
      hideHandler,
      onChangeSearchKey,
      footer,
      minLength = 0,
      keyboardNavigation,
      sortOnSearchKey,
    },
    outerRef
  ) => {
    const [filtered, setFiltered] = useState([])
    const ref = useRef(null)
    const inputRef = useRef(null)
    const myref = useRef()
    useImperativeHandle(outerRef, () => inputRef.current, [])

    const handleClickOutside = (e) => {
      if (ref.current?.contains(e.target)) {
        return null
      }
      e.preventDefault()
      return show ? hideHandler?.() : null
    }

    const changeHandler = (value) => {
      let newFilter = data

      if (value.length >= minLength) {
        newFilter = data.filter((item) => {
          const words = value.trim().toLowerCase().split(' ')
          const searchable = item.value.toString().toLowerCase()
          return (
            searchable.includes(value) ||
            words.every((word) => searchable.includes(word))
          )
        })
        if (sortOnSearchKey) newFilter = sortOnKey(newFilter, sortOnSearchKey)
      }

      setFiltered(newFilter)

      if (onChangeSearchKey) onChangeSearchKey(value)
    }

    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'Escape':
          e.preventDefault()
          e.stopPropagation()
          if (inputRef.current.value === '') {
            hideHandler?.()
          }
          inputRef.current.value = ''
          inputRef.current?.focus()
          changeHandler('')
          break

        case 'ArrowDown':
          if (keyboardNavigation) {
            const nextItem =
              ref.current.querySelector(
                '.o-dropdown__search--list--item.focus + div'
              ) ??
              ref.current.querySelector(
                '.o-dropdown__search--list--item:focus + div'
              )
            if (nextItem) nextItem.focus()
            return ref.current
              .querySelector('.o-dropdown__search--list--item.focus')
              ?.classList?.remove('focus')
          }
          break

        case 'ArrowUp':
          if (keyboardNavigation) {
            ref.current
              .querySelector('.o-dropdown__search--list--item.focus')
              ?.classList?.remove('focus')
            const prevItem = ref.current.querySelector(
              '.o-dropdown__search--list--item:has(+ .o-dropdown__search--list--item:focus)'
            )
            if (prevItem) prevItem.focus()
          }
          break

        case 'Enter':
          if (keyboardNavigation) {
            const focusedItem = ref.current.querySelector(
              '.o-dropdown__search--list--item.focus, .o-dropdown__search--list--item:focus'
            )
            if (focusedItem) {
              const index = Array.from(focusedItem.parentNode.children).indexOf(
                focusedItem
              )
              // weired bug: filtered is undefined here but ref is fine
              const selectedItem = myref.current[index]
              clickHandler(selectedItem)
            }
          }
          break
      }
    }

    const addRemoveEvents = (add) => {
      if (add) {
        setTimeout(() => {
          document.addEventListener('click', handleClickOutside)
        }, 0)
        document.addEventListener('keydown', handleKeyPress)
      } else {
        document.removeEventListener('click', handleClickOutside)
        document.removeEventListener('keydown', handleKeyPress)
      }
    }

    useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      addRemoveEvents(show)
      return () => {
        addRemoveEvents(false)
      }
    }, [show])

    useEffect(() => {
      if (inputRef.current?.value) {
        changeHandler(inputRef.current.value)
      } else {
        setFiltered(data)
      }
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }, [data])

    useEffect(() => {
      if (ref.current && filtered.length) {
        ref.current
          .querySelector('.o-dropdown__search--list--item:first-child')
          ?.classList?.add('focus')
      }
    }, [filtered, ref.current])

    useEffect(() => {
      myref.current = filtered
    }, [filtered])

    if (!show) return null

    const createRows = () => {
      const searchKey = inputRef.current?.value ?? ''
      const rendered = [...filtered].map((item, i) => (
        <div
          data-testid={`rendered-${i}`}
          tabIndex="0"
          key={i}
          onClick={() => {
            clickHandler(item)
          }}
          onKeyDown={(e) => e.key === 'Enter' && clickHandler(item)}
          className="cursor-pointer o-dropdown__search--list--item text-normal text-dark">
          {item.render}
        </div>
      ))
      if (!rendered.length) {
        if (searchKey) {
          return (
            <div className="o-dropdown__search--empty--container ">
              <span className=" text-center">
                <div className="text-stable-dark text-bold">
                  No results for{' '}
                  <span className="text-dark text-bold">
                    {inputRef.current.value}
                  </span>
                </div>
                <small className="text-stable-dark">
                  Try searching on other keywords
                </small>
              </span>
            </div>
          )
        }
        return (
          <Delay timeout="300">
            <div className="o-dropdown__search--empty--container ">
              <span className="text-stable-dark text-center">
                <div className="text-stable-dark text-bold mb-1">
                  {notFoundTitle || 'No scenes'}
                </div>
                {notFoundText || (
                  <>
                    <small>You have linked all scenes or this scenario</small>
                    <br />
                    <small> does not have any scenes</small>
                  </>
                )}
              </span>
            </div>
          </Delay>
        )
      }
      return <>{rendered}</>
    }
    return (
      <div id="search-dropdown" ref={ref} className="o-dropdown__search--outer">
        <div
          style={style}
          className={`o-dropdown__search--container arrow-${position}`}
          tabIndex="-1">
          <span
            className={`o-dropdown__search--input 
              ${inputRef.current?.value?.length < minLength ? 'border-none' : ''}`}>
            <span className="o-search o-search-input input-group border-none">
              <span
                data-testid="search-icon"
                className="o-search-input--prefix"
                onClick={(e) => {
                  e.stopPropagation()
                  inputRef.current.focus()
                }}>
                <FaSearch />
              </span>
              <input
                className="pl-0"
                autoComplete="off"
                data-testid="input-field"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  inputRef.current.focus()
                }}
                ref={inputRef}
                type="text"
                defaultValue={inputRef.current?.value}
                onChange={(e) => changeHandler(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && filtered.length === 1) {
                    clickHandler(filtered[0])
                  }
                }}
                placeholder={placeholder}></input>
              {inputRef.current?.value !== '' && (
                <span
                  data-testid="suffix-icon"
                  className="o-search-input--inline-suffix"
                  onClick={(e) => {
                    e.stopPropagation()
                    inputRef.current.value = ''

                    setFiltered(data)
                    return inputRef.current.focus()
                  }}>
                  <FiX />
                </span>
              )}
            </span>
          </span>
          {(!inputRef.current ||
            inputRef.current?.value?.length >= minLength) && (
            <div
              data-testid="list-items"
              className="o-dropdown__search--list"
              tabIndex="-1">
              {loading && !data ? (
                <Delay timeout="1000">
                  <LoadingSpinner dotsOnly scale="0.5" />
                </Delay>
              ) : (
                createRows()
              )}
            </div>
          )}
          {footer && (
            <div
              data-testid="footer"
              className="o-dropdown__search--footer cursor-pointer text-normal border-top">
              {footer}
            </div>
          )}
        </div>
      </div>
    )
  }
)

export default SearchableDropdown
