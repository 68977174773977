import React, { useContext } from 'react'
import LanguageContext from '../../utils/context/LanguageContext'
import ScenariosCarousel from './ScenariosCarousel'

const About = ({
  data,
  description,
  isCourse = false,
  buttonColor = 'white',
}) => {
  const { client } = data
  const { translations: t } = useContext(LanguageContext)
  const { titles } = t.sections

  return (
    <section id="about" className="border-bottom">
      <div className="grid-container fluid mt0">
        <div
          className="c-launcher__page--container grid-x"
          style={{ paddingTop: '90px' }}>
          <div className="cell large-6">
            <h2 className="c-launcher__about--heading">
              {isCourse ? titles.aboutCourse : titles.aboutScenario}
            </h2>
            <p>{description}</p>
            <button
              onClick={() => $('#signinmodal').foundation('open')}
              className="c-launcher__sign-in--button button small-12 mb-2"
              style={{
                marginTop: '60px',
                color: buttonColor,
                backgroundColor: client.primaryColor,
              }}>
              {isCourse ? t.buttonValues.course : t.buttonValues.scenario}
            </button>
          </div>
          <div className="cell large-5 large-offset-1 c-launcher__company-info">
            <div
              className="c-launcher__company-icon c-launcher__company-icon--round"
              style={{ backgroundImage: `url("${client.imageUrl}")` }}
            />
            <h3>{client.name}</h3>
            <p>{client.description}</p>
            {client.contactEmail && (
              <a
                href={`mailto:${client.contactEmail || 'support@warpvr.com'}`}
                className="c-launcher__company-info--link">
                Contact {client.name}
              </a>
            )}
          </div>
        </div>
      </div>
      {isCourse && <ScenariosCarousel scenarios={data.scenarios} />}
    </section>
  )
}
export default About
