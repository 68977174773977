import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Table from '../../components/Table'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import withApollo from '../../hooks/withApollo'
import Delay from '../../utils/helpers/Delay'
import { sortOnKey } from '../../utils/format'
import { getAllPlansQuery } from '../../apollo/query/plans'
import TableHeader from '../../components/UI/TableHeader'
import {
  filterColumnsHandler,
  sortHandler,
} from '../../components/tableFunctions'
import AddEditPlanModal from '../../components/Admin/Plans/AddEditPlanModal'
import TableAddButton from '../../components/UI/Table/TableAddButton'
import PlanOptionDropdown from '../../components/Admin/Plans/PlanOptionDropdown'
import DeletePlanModal from '../../components/Admin/Plans/DeletePlanModal'
import features from '../../components/Admin/Plans/features'
import TableColumnsDropdown from '../../components/UI/TableColumnsDropdown'
import { default as S } from '../../utils/lang/en.json'
import Checkbox from '../../components/UI/Form/Checkbox'

const Plans = () => {
  const tableHeaders = [
    'name',
    'clientCount',
    'legacy',
    'priceEur',
    'priceUsd',
    'includedPublishedScenarios',
    'includedWorkspaces',
    ...features.map((f) => f.value),
  ]

  const [selectedPlan, setSelectedPlan] = useState()
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'name',
    },
    filteredColumns: [],
  })

  // Data & render states
  const { loading, data } = useQuery(getAllPlansQuery)

  const tableRightBar = () => {
    const content = () => {
      return tableHeaders.map((item, i) => {
        const disabled = i === 0
        return (
          <li
            key={i}
            className={`mb-0 text-normal o-dropdown__list-item ${
              disabled ? 'disabled' : ''
            }`}
            onClick={(e) => {
              if (!disabled) {
                e.preventDefault()
                filterColumnsHandler(item, preferences, setPreferences)
              }
            }}>
            <Checkbox
              style={{ marginBottom: '0px', cursor: 'default' }}
              disabled={disabled}
              checked={!preferences.filteredColumns.includes(item)}
              value={S.table.plans.headers[item]}
            />
          </li>
        )
      })
    }

    return (
      <>
        <TableColumnsDropdown content={content} />
        <TableAddButton dataOpen="add-edit-plan-modal" title="Add plan" />
      </>
    )
  }

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    const plans = [...data.plans].map((plan) => ({
      id: plan.id,
      name: plan.name,
      clientCount: plan.clientCount,
      legacy: {
        value: plan.legacy,
        render: plan.legacy ? 'Yes' : 'No',
      },
      priceEur: plan.priceEur,
      priceUsd: plan.priceUsd,
      includedPublishedScenarios: plan.includedPublishedScenarios,
      includedWorkspaces: plan.includedWorkspaces,
      ...features.reduce(
        (acc, f) => ({
          ...acc,
          [f.value]: plan.features.includes(f.value) ? 'Yes' : 'No',
        }),
        {}
      ),
    }))

    return sortOnKey(plans, value, isAscending).slice(0)
  }

  if (loading || !data) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const loadModals = () => {
    const appendTo = '#plans'

    return (
      <>
        <AddEditPlanModal
          id="add-edit-plan-modal"
          appendTo={appendTo}
          selectedPlan={selectedPlan}
          onClose={() => setSelectedPlan(null)}
        />
        <DeletePlanModal
          id="delete-plan-modal"
          appendTo={appendTo}
          planId={selectedPlan?.id}
          onClose={() => setSelectedPlan(null)}
        />
      </>
    )
  }

  const handleEditPlan = (id) => {
    setSelectedPlan(data.plans.find((p) => p.id === id))
  }

  const handleDeletePlan = (id) => {
    setSelectedPlan(data.plans.find((p) => p.id === id))
  }

  const renderOptions = (id) => {
    return (
      <PlanOptionDropdown
        id={id}
        disableDelete={data.plans.find((p) => p.id === id).clientCount > 0}
        onEdit={handleEditPlan}
        onDelete={handleDeletePlan}
      />
    )
  }

  return (
    <div id="plans">
      <div className="grid-container fluid">
        <TableHeader
          length={data.plans.length}
          title="Plan"
          rightBar={tableRightBar()}
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        <Table
          data={sortedData()}
          placeholderRows={1}
          sortOn={preferences.sortOn}
          sortHandler={(value) => {
            sortHandler(value, preferences, setPreferences)
          }}
          headers={tableHeaders.filter(
            (i) => !preferences.filteredColumns.includes(i)
          )}
          type="plans"
          rowSuffix={(i) => renderOptions(i)}
        />
      </div>
      {loadModals()}
    </div>
  )
}

export default withApollo(Plans)
