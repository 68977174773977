import React, { useRef, useState } from 'react'
import TraineeDetailGroupsList from './TraineeDetailGroupsList'

import ImportTSVFile from '../../UI/Form/ImportTSVFile'

const ImportTraineesForm = ({ modalId, cancelHandler, callback }) => {
  const groupRef = useRef()
  const [shouldStartUploading, setShouldStartUploading] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)
  return (
    <form className="mt-3">
      <p>
        Provide a Tab-Separated-Values (TSV or TXT) file with column names
        FirstName, LastName, Email,EmployeeNo,Group and a row for each trainee.{' '}
        <a
          className=" text-stable-dark text-underline"
          href="https://help.warpvr.com/users-and-groups/unSXebY8nSD6Dfjd5awAx6/import-trainees/kUa89dwsA4CcduF4Fc35At"
          target="_blank"
          rel="noreferrer">
          Need help?
        </a>
      </p>

      <ImportTSVFile
        modalId={modalId}
        kind="TRAINEES"
        shouldStartUploading={shouldStartUploading}
        hasFileSelected={setFileSelected}
        callback={callback}
        groupIds={groupRef.current}
      />

      <label>Add trainees to groups</label>
      <TraineeDetailGroupsList groupRef={groupRef} />
      <div className="clearfix o-modal--footer pb-0 pr-0">
        <button
          className="mb-0 hollow button secondary wide"
          onClick={(e) => {
            e.preventDefault()
            cancelHandler()
          }}
          data-close={modalId}>
          Cancel
        </button>
        <button
          type="submit"
          data-close={modalId}
          disabled={!fileSelected}
          className="mb-0 button primary wide"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShouldStartUploading(true)
          }}>
          Confirm
        </button>
      </div>
    </form>
  )
}
export default ImportTraineesForm
