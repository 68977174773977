import React, { useEffect, useState } from 'react'
import useFoundation from '../../../hooks/useFoundation'

const Dropdown = ({
  button,
  id,
  position = 'bottom',
  alignment = 'left',
  hover = true,
  offset,
  children,
  minWidth = 300,
  ...rest
}) => {
  const menuRef = useFoundation()
  const [arrowStyle, setArrowStyle] = useState({})

  const calculateArrowPosition = (position) => {
    let style = {}
    switch (position) {
      case 'bottom':
        style = {
          top: '-14px',
          bottom: 'auto',
          transform: 'rotate(0deg)',
        }
        break

      case 'top':
        style = {
          top: 'auto',
          bottom: '-14px',
          transform: 'rotate(180deg)',
        }
        break

      case 'left':
        style = {
          top: '14px',
          left: '-22px',
          transform: 'rotate(-90deg)',
        }
        break

      default:
        style = {
          top: '10px',
          left: '-22px',
          transform: 'rotate(-90deg)',
        }
        break
    }

    if (alignment === 'right') {
      style = {
        ...style,
        left: 'auto',
        right: '25px',
      }
    } else if (alignment === 'center') {
      style = {
        ...style,
        left: 'calc(50% - 15px)',
        right: 'auto',
      }
    }

    setArrowStyle(style)
  }

  useEffect(() => {
    calculateArrowPosition(position)
  }, [children]) // Re-run this effect when `children` changes.

  return (
    <span className="h-100" {...rest}>
      {button}
      <div
        ref={menuRef}
        className="dropdown-pane auto-size"
        style={{ minWidth: `${minWidth}px`, width: '100% !important' }}
        data-testid="dropdown"
        data-close-on-click
        data-hover={hover}
        data-position={position}
        data-alignment={alignment}
        id={id}
        data-dropdown>
        <ul
          data-testid="list"
          className="o-dropdown o-dropdown__list o-dropdown--no-arrow"
          style={{ ...offset, position: 'relative' }}>
          {children}
          <span
            data-testid="arrow"
            className="o-dropdown__arrow"
            style={arrowStyle}
          />
        </ul>
      </div>
    </span>
  )
}
export default Dropdown
