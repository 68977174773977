import React from 'react'

const SceneNumberLabel = ({ number, className = '' }) => {
  return (
    <span className={`o-label o-label--stable-dark text-white ${className}`}>
      {number}
    </span>
  )
}

export default SceneNumberLabel
