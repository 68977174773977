import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { exportUsersMutation } from '../../../apollo/query/user'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const ExportTraineesModal = ({ id, appendTo, userIds }) => {
  const [key, setKey] = useState(uuidv4())

  const [exportTrainees] = useMutation(exportUsersMutation, {
    onError: handleApolloError,
  })

  const submitHandler = () => {
    exportTrainees({
      variables: {
        kind: 'TRAINEES',
        userIds,
      },
    })
    addSuccessAlert({
      title: 'The export of trainees is being made',
      subtitle: 'You are notified by email when finished',
      icon: 'progress',
      timeout: false,
    })

    setKey(uuidv4())
  }

  const subtitle = () => {
    return (
      <span>
        Create a CSV export of selected trainees.{' '}
        <a
          className="text-stable-dark text-underline"
          href="https://help.warpvr.com/new-to-warp-studio/training-analytics"
          target="_blank"
          rel="noreferrer">
          Need help?
        </a>
      </span>
    )
  }
  return (
    <Modal
      id={id}
      headerText="Export selected trainees"
      subtitle={subtitle()}
      appendTo={appendTo}
      footerText="Exports are made in the background. You are notified by email when finished."
      submitHandler={submitHandler}
      cancelHandler={() => {
        setTimeout(() => setSelectedValue(0), 200)
        setKey(uuidv4())
      }}
      useDefaultButtons>
      <form key={key}>
        <fieldset>
          <p>
            If you are looking to export attempt data, go to your{' '}
            <a
              href="/activity?tab=attempts"
              className="text-stable-dark text-underline"
              target="_blank">
              Training attempts
            </a>{' '}
            and export the data from there.
          </p>
        </fieldset>
      </form>
    </Modal>
  )
}

export default ExportTraineesModal
