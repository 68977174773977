import { observable, computed } from 'mobx'
import CardStore from '../card'

class SceneStore extends CardStore {
  @observable kind = null

  @observable kind_formatted = null

  @observable name = null

  @observable question = null

  @observable priority = null

  @observable start = false

  @observable answers = []

  @observable type = 'scene'

  dimensions = {
    width: 270,
    height: 190,
  }

  constructor(store, jsonObject) {
    super(store, jsonObject)
    this.assignProperties(
      [
        'kind',
        'kind_formatted',
        'name',
        'question',
        'priority',
        'start',
        'camera_location_id',
      ],
      jsonObject
    )
  }

  edit() {
    const href = `${this.store.urls.edit_card_url.replace(-1, this.id)}`

    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  show() {
    window.location.href = `${this.store.urls.show_card_url.replace(-1, this.id)}`
  }

  test_scenario() {
    window.open(
      `${this.store.urls.test_from_scene_url}?start_at=${this.id}&reset=true`,
      'TestScenario' // always use same window to test (to prevent weird things from happening)
    )
  }

  @computed
  get linkedAnswers() {
    return this.answers.filter((answer) => answer.toScene != null)
  }

  @computed
  get unlinkedAnswers() {
    return this.answers.filter((answer) => answer.toScene == null)
  }

  @computed
  // eslint-disable-next-line class-methods-use-this
  get getEndings() {
    return 0
  }

  @computed
  get isRandomized() {
    return this.answers.some((answer) => answer.randomized > 0)
  }

  @computed
  get numberOfAnswers() {
    let n = 0

    this.answers.forEach((answer) => {
      n += 1 + answer.randomizedAnswers.length
    })

    return n
  }

  @computed
  get cardHeight() {
    if (this.numberOfAnswers > 4) {
      return (this.numberOfAnswers + 2) * 25
    }
    return this.dimensions.height
  }

  get cardWidth() {
    return this.dimensions.width
  }

  answerOffset(id) {
    let realPriority = 1
    const sortedAnswers = this.answers.slice().sort((a, b) => {
      return a.priority > b.priority
    })

    // eslint-disable-next-line no-restricted-syntax
    for (const answer of sortedAnswers) {
      if (id !== answer.id) {
        realPriority += 1 + answer.randomizedAnswers.length
      } else {
        break
      }
    }

    const baseOffset = this.cardHeight / (this.numberOfAnswers + 1)
    return baseOffset * realPriority
  }

  isEmpty() {
    return this.id < 0
  }

  asJson() {
    return {
      x: this.x,
      y: this.y,
      draggable_type: 'Scene',
      draggable_id: this.draggable_id,
      draggable_attributes: {
        name: this.name,
        question: this.question,
        kind: this.kind,
        start: this.start,
      },
    }
  }
}

export default SceneStore
