import React, { useContext } from 'react'
import { dateFormat } from '../../utils/format'
import colors from '../Editor/helpers/colors'
import { Bar, BarGraph, Tooltip, XAxis, YAxis } from '../UI/Graphs'
import { getDatesDiff } from '../../utils/date'
import ActivityContext from '../../utils/context/ActivityContext'

const FULL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DAY_FORMAT = 'YYYY-MM-DD'
const GRAPH_DATE_FORMAT = 'DD MMM'
const GRAPH_TIME_FORMAT = 'HH:mm'
const TOOLTIP_DATE_FORMAT = 'dddd DD MMM, YYYY'
const TOOLTIP_TIME_FORMAT = 'HH:mm'

const renderDataRow = (d, dateTimeFormat) => {
  return {
    id: d.id,
    value: parseInt(d.value),
    date: dateFormat(new Date(d.id), dateTimeFormat, false),
  }
}

const getDiffs = (start, end, period) => {
  if (period === '1-hour')
    return getDatesDiff(start, end, 'minute', FULL_DATETIME_FORMAT)
  if (period === '24-hours')
    return getDatesDiff(start, end, 'hour', FULL_DATETIME_FORMAT)

  return getDatesDiff(start, end, 'day', DAY_FORMAT)
}

const periodHasHour = (period) => ['1-hour', '24-hours'].includes(period)

const CustomTooltip = ({
  active,
  payload,
  label,
  dateTimeFormat,
  renderTooltip,
  period,
}) => {
  let tooltipDateTimeFormat = TOOLTIP_DATE_FORMAT
  if (periodHasHour(period)) tooltipDateTimeFormat = TOOLTIP_TIME_FORMAT

  if (active && payload?.length) {
    return (
      <div
        className="border-radius p-1-5 text-white text-normal"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
        <div className="text-bold mb-1">
          {dateTimeFormat === GRAPH_TIME_FORMAT
            ? label
            : dateFormat(payload[0].payload.id, tooltipDateTimeFormat, false)}
        </div>
        {renderTooltip(payload[0].value)}
      </div>
    )
  }

  return null
}

const fillEmptyDates = (_data, range, dateTimeFormat, period) => {
  let data = []
  ;[
    ..._data,
    {
      id: dateFormat(
        range[1],
        periodHasHour(period) ? FULL_DATETIME_FORMAT : DAY_FORMAT,
        false
      ),
      value: 0,
    },
  ].forEach((_row, index) => {
    const row = renderDataRow(_row, dateTimeFormat)
    let prevRow,
      datesDiff = []

    if (index === 0) {
      const prevDate = dateFormat(
        range[0],
        periodHasHour(period) ? FULL_DATETIME_FORMAT : DAY_FORMAT,
        false
      )
      prevRow = renderDataRow(
        {
          id: prevDate,
          value: 0,
        },
        dateTimeFormat
      )
      datesDiff = getDiffs(prevRow.id, row.id, period)
    } else {
      prevRow = renderDataRow(_data[index - 1], dateTimeFormat)
      datesDiff = getDiffs(prevRow.id, row.id, period)
    }

    datesDiff.slice(1).forEach((date) => {
      data.push(
        renderDataRow(
          {
            id: date,
            value: date === row.id ? row.value : 0,
          },
          dateTimeFormat
        )
      )
    })
  })
  return data
}

const ActivityGraph = React.memo(
  ({
    data: _data,
    renderTooltip,
    yAxisTickFormatter = () => '',
    margin = {
      left: -65,
      bottom: 5,
    },
  }) => {
    const { searchParams, apiVars } = useContext(ActivityContext)

    let dateTimeFormat = GRAPH_DATE_FORMAT
    if (periodHasHour(searchParams.period)) dateTimeFormat = GRAPH_TIME_FORMAT

    let data = fillEmptyDates(
      _data,
      [apiVars.fromDateTime, apiVars.toDateTime],
      dateTimeFormat,
      searchParams.period
    )

    return (
      <BarGraph margin={margin} data={data}>
        <XAxis
          dataKey="date"
          stroke="var(--stable-light)"
          tick={{
            fill: 'var(--stable-dark)',
            fontSize: 14,
            fontWeight: 500,
            transform: 'translate(0, 20)',
          }}
          tickSize={0}
          tickFormatter={(v) => v}
        />
        <YAxis
          dataKey="value"
          axisLine={false}
          tick={{ fill: 'var(--stable)', fontSize: 14, fontWeight: 500 }}
          tickSize={0}
          type="number"
          domain={[0, 'auto']}
          interval="preserveStartEnd"
          tickFormatter={yAxisTickFormatter}
          allowDecimals={false}
        />
        <Tooltip
          content={
            <CustomTooltip
              renderTooltip={renderTooltip}
              dateTimeFormat={dateTimeFormat}
              period={searchParams.period}
            />
          }
          cursor={{ fill: 'transparent' }}
        />
        <Bar dataKey="value" fill={colors.assertive} radius={[8, 8, 0, 0]} />
      </BarGraph>
    )
  }
)

export default ActivityGraph
