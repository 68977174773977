import colors from '../../../helpers/colors'
import { scrollIntoViewWithOffset } from '../../../../../utils/helpers/javascript'
import { roundedRect } from '../../../helpers/aframe-shapes'
import {
  selectSidebarElement,
  unSelectSidebarElements,
} from '../../../../FlowV2/helpers/elementHelper'

const { AFRAME, THREE } = window

AFRAME.registerComponent('collisionbox', {
  schema: {
    enabled: { default: true },
    width: { type: 'number', default: 2 },
    height: { type: 'number', default: 2.2 },
    radius: { type: 'number', default: 0.1 },
    opacity: { type: 'number', default: 0 },
    color: { type: 'color', default: colors.royal },
  },
  draw(extraSpacing) {
    // draw element
    const roundedRectShape = new THREE.Shape()
    const bubblePosition = this.self.children[0].getAttribute('position')

    roundedRect(
      roundedRectShape,
      -this.data.width / 2 - extraSpacing / 2 + bubblePosition.x,
      -this.data.height / 2 - extraSpacing / 2,
      this.data.width + extraSpacing,
      this.data.height + extraSpacing,
      this.data.radius
    )
    if (roundedRectShape) return new THREE.ShapeGeometry(roundedRectShape)
    return null
  },
  update() {
    if (this.data.enabled) {
      if (this.data.opacity === 0) {
        this.planeMesh.visible = false
        this.borderMesh.visible = false
      } else {
        this.planeMesh.visible = true
        this.planeMesh.material.opacity = this.data.opacity
        this.borderMesh.visible = true
      }
    }
  },
  onIntersected() {
    this.data.opacity = 0.2
    this.mouseOver = true
    this.update()
  },
  onIntersectedCleared() {
    this.mouseOver = false
    if (!this.isSelected) {
      this.data.opacity = 0
      this.update()
    }
  },
  onSave() {
    const saveButton = document.querySelectorAll(
      `#element-${this.self.id}--save-button`
    )[0]
    saveButton.click()
  },
  onSelect(e) {
    this.isSelected = e.detail.isSelected
    if (!this.mouseOver && !this.isSelected) {
      this.data.opacity = 0
      this.self.classList.remove('selected')
      unSelectSidebarElements(`element-${this.self.id}--card`)
      this.update()
    }

    if (this.isSelected) {
      this.self.classList.add('selected')
      const sidebarCard = document.querySelector(
        `#element-${this.self.id}--card`
      )
      const sidebarElement = document.querySelector(`#element-${this.self.id}`)
      if (e.detail.scroll) {
        this.data.scrollHeaderOffset =
          this.self.getAttribute('scroll-header-offset') || 10
        scrollIntoViewWithOffset(sidebarElement, this.data.scrollHeaderOffset)
      }
      selectSidebarElement(`element-${this.self.id}--card`)
      sidebarCard.classList.add('active')
    }
  },
  drawBorder() {
    this.planeMesh = new THREE.Mesh(this.draw(0), this.material.plane)
    this.borderMesh = new THREE.Mesh(this.draw(0.08), this.material.border)
    this.borderMesh.position.set(0, 0, -0.05)
    this.self.setObject3D('plane', this.planeMesh)
    this.self.setObject3D('border', this.borderMesh)
  },
  init() {
    this.mouseOver = false
    this.isSelected = false
    this.self = this.el
    this.self.setAttribute('position', '0 0 -7')
    this.material = {
      plane: new THREE.MeshBasicMaterial({
        color: new THREE.Color(this.data.color),
        transparent: true,
        side: THREE.FrontSide,
        opacity: this.data.opacity,
      }),
      border: new THREE.MeshBasicMaterial({
        transparent: true,
        color: new THREE.Color(this.data.color),
        side: THREE.FrontSide,
        opacity: 1,
      }),
    }
    this.drawBorder()
    this.self.classList.add('clickable')

    this.self.addEventListener('is-selected', this.onSelect.bind(this))
    this.self.addEventListener('save-position', this.onSave.bind(this))
    this.self.addEventListener('intersect', this.onIntersected.bind(this))
    this.self.addEventListener(
      'intersect-cleared',
      this.onIntersectedCleared.bind(this)
    )

    this.self.addEventListener('componentchanged', (e) => {
      if (e.detail.name === 'collisionbox') {
        this.drawBorder()
        this.onIntersectedCleared()
      }
    })
    this.self.children[0].addEventListener('componentchanged', (e) => {
      if (e.detail.name === 'position') {
        setTimeout(() => {
          this.drawBorder()
          this.onIntersectedCleared()
        }, 100)
      }
    })
  },
})

AFRAME.registerPrimitive('a-collision-box', {
  defaultComponents: {
    collisionbox: {},
  },
  mappings: {
    enabled: 'collisionbox.enabled',
    width: 'collisionbox.width',
    height: 'collisionbox.height',
    radius: 'collisionbox.radius',
    color: 'collisionbox.color',
    opacity: 'collisionbox.opacity',
  },
})
