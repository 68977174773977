import React, { useState } from 'react'
import { FaImage } from 'react-icons/fa'
import Table from '../../../components/Table'
import { sortHandler } from '../../../components/tableFunctions'
import Copy from '../../../components/UI/Copy/Copy'
import FilterEmptyState from '../../../components/UI/Menu/FilterEmptyState'
import TableSearchBar from '../../../components/UI/Table/TableSearchBar'
import TableHeader from '../../../components/UI/TableHeader'
import { dateFormat, sortOnKey } from '../../../utils/format'

// Replace with ENV variable
const BASE_URL = window.location.origin

const ScenariosList = ({ scenarios, kind }) => {
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'createdAt',
    },
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmptyState, setShowEmptyState] = useState(false)

  if (!scenarios) return <>You don't have any scenarios yet </>

  const isLti = kind === 'LTI'
  const path =  isLti ? 'ltiPath' : 'scormPath'
  const tableHeaders = ['name', 'createdAt', path]

  if (isLti) tableHeaders.push('copyButton')

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn

    let filtered = [...scenarios].filter((scenario) => {
      return scenario.name.toLowerCase().includes(searchQuery.toLowerCase())
    })

    const data = sortOnKey(filtered || [...scenarios], value, isAscending)
    if (!data.length && !showEmptyState) setShowEmptyState(true)
    if (data.length && showEmptyState) setShowEmptyState(false)

    const getPathRender = (url) => {
      if (isLti) return url

      return (
        <a href={url} target="_blank" className="text-underline">
          Download package
        </a>
      )
    }

    return data.map((scenario) => {
      const url = `${BASE_URL}${scenario[path]}`

      const formatted = {
        name: {
          value: scenario.name,
          render: scenario.purchased ? (
            <span>
              {scenario.name}
              <label className="o-label--custom mb-0 o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                PURCHASED
              </label>
            </span>
          ) : (
            scenario.name
          ),
        },
        id: scenario.id,
        createdAt: dateFormat(scenario.createdAt),
        [path]: {
          value: url,
          render: getPathRender(url),
        },
      }

      if (isLti) return { ...formatted, copyButton: <Copy value={url} /> }
      return formatted
    })
  }

  const tableSearchBar = () => (
    <TableSearchBar
      searchQuery={searchQuery}
      showEmptyState={showEmptyState}
      setSearchQuery={setSearchQuery}
      setShowEmptyState={setShowEmptyState}
    />
  )

  return (
    <div id={`scenarios-${kind}`} className="mt-3">
      <div className="grid-container fluid p-0">
        <TableHeader
          title="Scenario"
          length={[...sortedData()].length}
          leftBar={tableSearchBar()}
        />
      </div>

      {showEmptyState && (
        <FilterEmptyState
          type="scenarios"
          icon={<FaImage />}
          clickHandler={() => {
            setSearchQuery('')
          }}
        />
      )}

      {!showEmptyState && preferences && (
        <Table
          data={sortedData()}
          headers={tableHeaders}
          sortOn={preferences.sortOn}
          sortHandler={(value) => {
            if (['name', 'createdAt'].includes(value))
              sortHandler(value, preferences, setPreferences)
          }}
          type="integrationScenarios"
        />
      )}
    </div>
  )
}
export default ScenariosList
