import React, { useEffect, useState } from 'react'
import useSelectedNode from '../../hooks/useSelectedNode'
import { useLazyQuery, useQuery } from '@apollo/client'
import LoadingSpinner from '../../../UI/LoadingSpinner'
import { dateFormat, sortOnKey } from '../../../../utils/format'
import { renderActionValues } from '../../../../utils/helpers/activity'
import { getCurrentPlanQuery } from '../../../../apollo/query/plans'
import HistorySortDropdown from './HistorySortDropdown'
import { getWorkspaceHistoryQuery } from '../../../../apollo/query/workspaces'
import HistoryFeatureFlagError from '../../tabs/Settings/History/HistoryFeatureFlagError'

const HistoryTab = ({ show }) => {
  const { data: currentPlanData } = useQuery(getCurrentPlanQuery)
  const enterpriseEnabled =
    currentPlanData?.currentPlan.features.includes('ENTERPRISE')

  const selectedNode = useSelectedNode()
  const [getHistory, { data: activitiesData, loading }] = useLazyQuery(
    getWorkspaceHistoryQuery,
    {
      variables: { sceneId: selectedNode.id },
    }
  )
  const [data, setData] = useState([])

  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: false,
      value: 'createdAt',
    },
  })

  useEffect(() => {
    if (currentPlanData && enterpriseEnabled && show) getHistory()
  }, [show, currentPlanData])

  useEffect(() => {
    if (activitiesData) {
      console.log(preferences)
      const newData = sortOnKey(
        [...activitiesData.activities],
        preferences.sortOn.value,
        preferences.sortOn.isAscending
      )
      console.log(newData)
      setData(newData)
    }
  }, [activitiesData, preferences])

  if (loading || !currentPlanData) return <LoadingSpinner />

  const renderActivities = () => {
    if (!enterpriseEnabled) return <HistoryFeatureFlagError />

    return data.map((activity) => {
      const actionValue = renderActionValues(activity.action, activity.metadata)
      return (
        <div className="activity">
          <img
            className="avatar"
            width="30px"
            height="30px"
            lazy="true"
            src={activity.user.avatar.medium}
          />
          <div className="details">
            <time>
              {dateFormat(activity.createdAt, 'DD MMM, YYYY', false)}
              &nbsp;at&nbsp;{dateFormat(activity.createdAt, 'HH:mm', false)}
            </time>
            <div>
              <span className="text-bold">{activity.user.name}</span>{' '}
              {activity.description}
            </div>
            {actionValue && (
              <p className="mt-1 mb-0 p-1 border border-radius">
                {actionValue}
              </p>
            )}
          </div>
        </div>
      )
    })
  }

  console.log('data', data)
  return (
    <section id="scene-history" className="p-3">
      <header className="flex-container align-middle align-justify">
        <h3>History</h3>
        <HistorySortDropdown
          setSelected={(value) =>
            setPreferences({
              sortOn: { ...preferences.sortOn, isAscending: value === 'asc' },
            })
          }
          selected={preferences.sortOn.isAscending ? 'asc' : 'desc'}
        />
      </header>
      <div className="mt-2">{renderActivities()}</div>
    </section>
  )
}
export default HistoryTab
