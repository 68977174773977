import React, { useState } from 'react'
import {
  validatePassword,
  validatePasswordsMatch,
} from '../../../utils/validation/validate'
import { useMutation } from '@apollo/client'
import { FaCheck } from 'react-icons/fa'
import { handleApolloError } from '../../../utils/errors'
import {
  updatePasswordMutation,
  updatePasswordWithToken,
} from '../../../apollo/query/user'
import withApollo from '../../../hooks/withApollo'

const INITIAL_VALUES = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
}
const PasswordForm = ({
  token,
  showCurrentPassword,
  onCompleted,
  submitButtonText = 'Confirm',
  button,
}) => {
  const [updating, setUpdating] = useState()
  const [formValues, setFormValues] = useState(INITIAL_VALUES)
  const [updatePassword] = useMutation(
    token ? updatePasswordWithToken : updatePasswordMutation,
    {
      onError: handleApolloError,
      onCompleted: () => {
        setFormValues(INITIAL_VALUES)
        validate(INITIAL_VALUES)
        onCompleted()
      },
    }
  )
  const [showError, setShowError] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(false)
  const [isValid, setIsvalid] = useState({
    allChecksPassed: false,
    checks: {
      lengthValidation: false,
      containsDigit: false,
      containsLowerCase: false,
      containsUpperCase: false,
    },
  })

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (isValid && passwordsMatch) {
      setUpdating(true)
      await updatePassword({
        variables: { ...formValues, ...(token ? { token } : {}) },
      })
      setUpdating(false)
    }
  }

  const handleChange = ({ target }) => {
    const newFormValues = { ...formValues, [target.name]: target.value }
    setFormValues(newFormValues)
    validate(newFormValues)
  }

  const validate = (formValues) => {
    setIsvalid(validatePassword(formValues.password))
    if (formValues.passwordConfirmation) {
      const match = validatePasswordsMatch(
        formValues.password,
        formValues.passwordConfirmation
      )
      setPasswordsMatch(match)
      setShowError(!match)
    }
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className="mt-0"
      data-testid="password-form">
      {showCurrentPassword && (
        <>
          <label>Old password</label>
          <input
            type="password"
            name="currentPassword"
            required
            value={formValues.currentPassword}
            onChange={handleChange}
          />
        </>
      )}

      <label>New password</label>
      <input
        data-testid="password-input"
        type="password"
        minLength="12"
        autoComplete="new-password"
        id="password-input"
        onChange={handleChange}
        onBlur={handleChange}
        value={formValues.password}
        name="password"
        required
      />

      <div className="text-stable-dark text-normal">
        <div className="mb-1">Password should contain at least:</div>
        <ul style={{ listStyle: 'none', marginLeft: '0' }}>
          <li>
            <FaCheck
              className={`mr-1 ${
                isValid.checks.lengthValidation ? 'text-success' : ''
              }`}
            />
            12 characters
          </li>
          <li>
            <FaCheck
              className={`mr-1 ${
                isValid.checks.containsUpperCase ? 'text-success' : ''
              }`}
            />
            1 uppercase character
          </li>
          <li>
            <FaCheck
              className={`mr-1 ${
                isValid.checks.containsLowerCase ? 'text-success' : ''
              }`}
            />
            1 lowercase character
          </li>
          <li>
            <FaCheck
              className={`mr-1 ${
                isValid.checks.containsDigit ? 'text-success' : ''
              }`}
            />
            1 digit
          </li>
        </ul>
      </div>
      <label htmlFor="password-input-validation">Retype new password</label>
      <input
        data-testid="password-validation-input"
        type="password"
        minLength="12"
        autoComplete="new-password"
        value={formValues.passwordConfirmation}
        onBlur={(e) => {
          handleChange(e)
          setShowError(
            !validatePasswordsMatch(formValues.password, e.target.value)
          )
        }}
        onChange={(e) => {
          handleChange(e)
          const isMatch = validatePasswordsMatch(
            formValues.password,
            e.target.value
          )

          setPasswordsMatch(isMatch)
          if (showError) setShowError(!isMatch)
        }}
        name="passwordConfirmation"
        id="password-input-validation"
      />
      {showError && (
        <p className="mb-1 text-normal text-assertive text-bold">
          {`Passwords don't match`}
        </p>
      )}

      {button?.(isValid, passwordsMatch) ?? (
        <button
          data-testid="submit-button"
          type="submit"
          className={`o-button o-button--assertive float-right mt-1 button primary text-normal mb-0 ${
            updating ? 'o-button--loading' : ''
          }`}
          disabled={
            !(isValid.allChecksPassed && passwordsMatch) ||
            (showCurrentPassword && !formValues.currentPassword) ||
            updating
          }>
          {submitButtonText}
        </button>
      )}
    </form>
  )
}

export default withApollo(PasswordForm)
