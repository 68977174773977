import { useMutation } from '@apollo/client'
import React, { useRef } from 'react'
import cache from '../../../apollo/cache'
import { getUsersQuery, deleteUsersMutation } from '../../../apollo/query/user'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const DeleteTraineeModal = ({ id, appendTo, ids, clearSelectedItems }) => {
  const count = useRef()

  const showAlert = () => {
    addSuccessAlert({
      title: `Deleting ${ids.length} trainees`,
      subtitle: 'This happens in the background',
      icon: 'success',
    })
  }
  const [deleteUsers] = useMutation(deleteUsersMutation, {
    optimisticResponse: () => {
      showAlert()
      const { users: currentUsers } = cache.readQuery({ query: getUsersQuery })
      const newUsers = currentUsers.filter((user) => {
        return !ids.includes(user.id)
      })
      cache.writeQuery({
        query: getUsersQuery,
        data: {
          users: newUsers,
        },
      })
      clearSelectedItems(newUsers)
    },
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({
        title: 'Finished deleting trainees',
        icon: 'success',
      })
      clearSelectedItems()
    },
  })

  if (!ids) return null

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete ${
        ids.length === 1 ? 'this trainee' : 'these trainees'
      }?`}
      subtitle="This action cannot be undone."
      textAssertive
      useDefaultButtons
      submitButtonText="Yes, I'm sure"
      submitHandler={() => {
        count.current = ids.length
        deleteUsers({ variables: { ids } })
      }}
    />
  )
}
export default DeleteTraineeModal
