import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../../UI/Menu/Dropdown'

const PlanOptionDropdown = ({ id, disableDelete, onEdit, onDelete }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (isHovered) {
    return (
      <Dropdown
        id={`plan-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`plan-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <li
          data-open="add-edit-plan-modal"
          className="o-dropdown__list-item text-normal text-dark"
          onClick={() => onEdit(id)}>
          Edit plan
        </li>
        <li className="o-dropdown__divider"></li>
        <li
          data-open="delete-plan-modal"
          className={`o-dropdown__list-item text-normal  ${
            disableDelete
              ? 'o-dropdown__item--inactive o-dropdown__link--inactive'
              : 'text-dark'
          }`}
          onClick={() => onDelete(id)}>
          Delete plan
        </li>
      </Dropdown>
    )
  }

  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`plan-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default PlanOptionDropdown
