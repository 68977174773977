import React from 'react'
import { getRotation } from '../../helpers/controls'
import TextBubble from './partials/TextBubble'

import arrow from '../assets/arrow.svg'
import useCurrentUser from '../../../../hooks/useCurrentUser'

const Direction = ({ id, scene, state }) => {
  const [, currentClient] = useCurrentUser()

  if (!scene || !currentClient) return null

  const getAnswers = () => {
    return scene.answers.map((answer, index) => {
      const rotation = getRotation({
        x: answer.anchorX,
        y: -30,
      })
      let answerWidth = 1.8
      if (answer.elementSize === 'WIDE') answerWidth *= 2

      return (
        <a-entity
          hideable-element={`visible: ${state.showElements}`}
          key={index}
          rotation={`${rotation} 0`}>
          <a-collision-box id={`${id}-A${index}`} class="lock-vertical">
            {!answer.hideInVr && (
              <TextBubble
                textValue={answer.name}
                id={`A${index}`}
                width={answerWidth}
              />
            )}

            <a-entity position="0 -0.5 0.01">
              <a-image src="#direction" color={currentClient.primaryColor} />
            </a-entity>
          </a-collision-box>
        </a-entity>
      )
    })
  }
  return (
    <>
      <a-assets>
        <img id="direction" src={arrow} />
      </a-assets>
      <a-entity
        hideable-element={`visible: ${state.showElements}`}
        rotation={`${getRotation({ x: scene.anchorX, y: scene.anchorY })} 0`}>
        {scene.question && (
          <a-collision-box id={id}>
            <TextBubble textValue={scene.question} id="question" />
          </a-collision-box>
        )}
      </a-entity>
      {getAnswers()}
    </>
  )
}
export default Direction
