import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaChevronDown, FaPuzzlePiece, FaCheck } from 'react-icons/fa'
import colors from '../../components/Editor/helpers/colors'
import IntegrationsEmptyState from '../../components/Trainees/Integrations/IntegrationsEmptyState'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import Dropdown from '../../components/UI/Menu/Dropdown'
import TableHeader from '../../components/UI/TableHeader'
import withApollo from '../../hooks/withApollo'
import Delay from '../../utils/helpers/Delay'
import { kindFormatter } from '../../utils/helpers/integration'
import useCurrentUser from '../../hooks/useCurrentUser'
import { getIntegrationsQuery } from '../../apollo/query/settings'
import AddEditIntegrationModal from '../../components/Settings/Integrations/AddEditIntegrationModal'

const getDesc = (kind, desc, anonymous) => {
  if (desc) return desc
  return `${kindFormatter(kind)} ${anonymous ? ' • Trainees anonymized' : ''}`
}

const getIcon = (kind) => {
  if (kind === 'API')
    return (
      <div
        style={{
          width: '30px',
          height: '30px',
          borderColor: colors.assertive,
          borderWidth: 7,
          borderRadius: '50%',
          borderStyle: 'solid',
        }}
      />
    )
  return <FaPuzzlePiece size={30} />
}

const Card = ({ name, desc, kind, anonymous, active }) => {
  return (
    <div className="flex-container text-dark">
      <div className="mr-2 o-card__integration--icon">
        {getIcon(kind)}
        <span className="o-card__integration--kind">{kind}</span>
        {active && (
          <span className="o-card__integration--verified">
            <FaCheck size={8} />
          </span>
        )}
      </div>
      <div className="flex-container align-center flex-dir-column">
        <h2 className="text-bold mb-0">{name}</h2>
        <p className="mb-0">{getDesc(kind, desc, anonymous)}</p>
      </div>
    </div>
  )
}

const Integrations = () => {
  const { loading, data, error, refetch } = useQuery(getIntegrationsQuery)
  const [formValues, setFormValues] = useState({ name: '' })
  const [, currentClient] = useCurrentUser()

  if (loading || !currentClient) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const getFeatures = () => {
    const features = [
      {
        name: kindFormatter('SCORM'),
        kind: 'SCORM',
        desc: 'Use SCORM 1.2 to connect to almost any LMS.',
      },
      {
        name: kindFormatter('LTI'),
        kind: 'LTI',
        desc: 'Use LTI 1.1 to connect to almost any LMS.',
      },
    ]

    if (currentClient.features.includes('ORGANIZATION_API'))
      features.push({
        name: 'Warp VR API',
        kind: 'API',
        desc: 'Built your own integration through the Warp VR API',
      })

    if (currentClient.features.includes('OAUTH'))
      features.push({
        name: kindFormatter('OAUTH'),
        kind: 'OAUTH',
        desc: 'Built your own integration through the OAUTH',
      })

    if (currentClient.features.includes('OCN'))
      features.push({
        name: kindFormatter('OCN'),
        kind: 'OCN',
        desc: 'Built your own integration through the OCN',
      })

    return features
  }

  const getAddDropdown = (button) => {
    const features = getFeatures()

    return (
      <Dropdown
        id="new-integration-selector"
        alignment="right"
        offset={{ top: '2px', left: '2px' }}
        button={button}>
        {features.map((item) => (
          <li
            key={item.kind}
            data-testid={`add-${item.kind}`}
            className="o-dropdown__list-item pt-2 pb-2">
            <a
              href="#"
              data-open="add-integration-modal"
              onClick={(e) => {
                e.preventDefault()
                setFormValues({ kind: item.kind, name: '' })
              }}
              className="text-left">
              <Card name={item.name} kind={item.kind} desc={item.desc} />
            </a>
          </li>
        ))}
      </Dropdown>
    )
  }

  const getTableButtonBar = () => {
    return (
      <span className="cell shrink mb-0">
        {getAddDropdown(
          <button
            data-testid="dropdownbutton"
            data-open="new-integration-selector"
            className="mb-0 button primary">
            New integration
            <FaChevronDown className="ml-1" style={{ color: '#FFF' }} />
          </button>
        )}
      </span>
    )
  }

  const showEmptyState = () => {
    return !data.integrations.length
  }

  return (
    <div id="integrations-settings">
      {!showEmptyState() && (
        <div className="grid-container fluid grid--overflow h-100">
          <TableHeader
            title="Active integration"
            length={data.integrations.length}
            rightBar={getTableButtonBar()}
          />
          <div className="grid-x grid-margin-x">
            {data.integrations.map((item) => (
              <button
                className="cell small-12 medium-6 large-4 xxlarge-3 xxxlarge-2 mb-4 cursor-pointer text-left"
                data-open="add-integration-modal"
                onClick={() => setFormValues({ ...item })}
                key={item.id}>
                <div className="p-3 border border-radius">
                  <Card
                    name={item.name}
                    kind={item.kind}
                    anonymous={item.anonymous}
                    active
                  />
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      <IntegrationsEmptyState
        hidden={!showEmptyState()}
        renderDesc={getAddDropdown(
          <span
            data-open="new-integration-selector"
            className="text-underline cursor-pointer text-stable-dark">
            Add your first integration now
          </span>
        )}
      />

      <AddEditIntegrationModal
        id="add-integration-modal"
        appendTo="#integrations-settings"
        defaultValues={formValues}
        callback={refetch}
      />
    </div>
  )
}
export default withApollo(Integrations)
