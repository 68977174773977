import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import Modal from '../../UI/Modal'
import {
  addPlanMutation,
  getAllPlansQuery,
  updatePlanMutation,
} from '../../../apollo/query/plans'
import features from './features'

const initialValues = {
  name: '',
  legacy: null,
  priceEur: null,
  priceUsd: null,
  includedPublishedScenarios: null,
  includedWorkspaces: null,
  features: [],
}
const AddEditPlanModal = ({ id: modalId, appendTo, selectedPlan, onClose }) => {
  const [key, setKey] = useState(uuidv4())
  const [values, setValues] = useState(initialValues)

  const [addPlan] = useMutation(addPlanMutation, {
    onError: handleApolloError,
    onCompleted: async () => {
      $(`#${modalId}`).foundation('close')
      addSuccessAlert({ title: 'Plan was created' })
    },
    refetchQueries: [
      {
        query: getAllPlansQuery,
      },
    ],
  })

  const [updatePlan] = useMutation(updatePlanMutation, {
    onError: handleApolloError,
    onCompleted: async () => {
      $(`#${modalId}`).foundation('close')
      addSuccessAlert({ title: 'Plan was updated' })
    },
    refetchQueries: [
      {
        query: getAllPlansQuery,
      },
    ],
  })

  const clear = () => {
    setTimeout(() => {
      setKey(uuidv4())
    }, 300)
    onClose()
  }

  const submit = () => {
    if (values.id)
      updatePlan({
        variables: values,
      })
    else
      addPlan({
        variables: values,
      })
    setTimeout(() => clear(), 300)
  }

  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]:
        target.type === 'number' ? Number(target.value) : target.value,
    })
  }

  const handleFeatures = ({ target }) => {
    const { checked, value } = target
    if (checked)
      return setValues({ ...values, features: [...values.features, value] })
    return setValues({
      ...values,
      features: values.features.filter((f) => f !== value),
    })
  }

  const renderFeatures = () => {
    return features.map((feature) => (
      <label className="o-checkbox" key={feature.value}>
        {feature.title}
        <input
          checked={values.features.includes(feature.value)}
          type="checkbox"
          name="features[]"
          value={feature.value}
          onChange={handleFeatures}
        />
        <span></span>
      </label>
    ))
  }

  useEffect(() => {
    setValues(selectedPlan ?? initialValues)
  }, [selectedPlan])

  return (
    <Modal
      id={modalId}
      appendTo={appendTo}
      closeOnEscape={false}
      useDefaultButtons
      cancelHandler={clear}
      submitHandler={submit}
      submitDisabled={
        !values.name
      }
      headerText={values.id ? 'Edit plan' : 'New plan'}>
      <form className="mt-3" key={key}>
        <div className="cell small-12">
          <label htmlFor="plan-name" className="c-form__label--required">
            Plan name
          </label>
          <input
            type="text"
            name="name"
            id="plan-name"
            required
            value={values.name || ''}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <div className="cell small-6 pr-1">
            <label htmlFor="priceEur">Monthly price EUR</label>
            <input
              type="number"
              name="priceEur"
              id="priceEur"
              value={values.priceEur || ''}
              onChange={handleChange}
            />
          </div>
          <div className="cell small-6">
            <label htmlFor="priceUsd">Monthly price USD</label>
            <input
              type="number"
              name="priceUsd"
              id="priceUsd"
              value={values.priceUsd || ''}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="cell small-6 pr-1">
            <label htmlFor="includedPublishedScenarios">
              Published scenario slots included
            </label>
            <input
              type="number"
              name="includedPublishedScenarios"
              id="includedPublishedScenarios"
              value={values.includedPublishedScenarios || ''}
              onChange={handleChange}
            />
          </div>
          <div className="cell small-6">
            <label htmlFor="includedWorkspaces">Workspaces included</label>
            <input
              type="number"
              name="includedWorkspaces"
              id="includedWorkspaces"
              value={values.includedWorkspaces || ''}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="cell small-12">
          <label className="mb-2">Features included in plan</label>
          {renderFeatures()}
        </div>
      </form>
    </Modal>
  )
}
export default AddEditPlanModal
