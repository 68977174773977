import React from 'react'
import ReactDatePicker from 'react-datepicker'

const DatePicker = ({ ...props }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}>
      <ReactDatePicker {...props} />
    </div>
  )
}

export default DatePicker
