import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  adminAddClientBillingMutation,
  adminAddClientDistributionMutation,
  adminUpdateClientDistributionMutation,
  adminUpdateClientBillingMutation,
  getAdminClientsQuery,
} from '../../apollo/query/workspaces'
import { addSuccessAlert } from '../../utils/helpers/alerts'
import Modal from '../UI/Modal'
import { handleApolloError } from '../../utils/errors'
import useCurrentUser from '../../hooks/useCurrentUser'
import { getCurrentClientSettingsQuery } from '../../apollo/query/settings'
import { getAllPlansQuery } from '../../apollo/query/plans'
import NumberInput from '../UI/Form/NumberInput'
import features from './features'
import { sortOnKey } from '../../utils/format'

const initialValues = {
  name: '',
  kind: 'BILLING',
  planBillingPeriod: '',
  planBillingCurrency: 'EUR',
  workspaceManagedByClientName: '',
  managedByClientId: '',
  planId: '',
  features: [],
}

const currencies = ['EUR', 'USD']

const subscriptionPeriods = [
  { name: 'Annually', value: 'YEAR' },
  { name: 'Monthly', value: 'MONTH' },
]

const AddEditAdminWorkspaceModal = ({
  id,
  appendTo,
  defaultValues,
  onSubmit,
  onClose: onCloseCallback,
}) => {
  const [currentUser] = useCurrentUser()
  const [formValues, setFormValues] = useState(initialValues)
  const [selectedPlan, setSelectedPlan] = useState()
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const { data, refetch } = useQuery(getAdminClientsQuery)
  const { data: clientData } = useQuery(getCurrentClientSettingsQuery)
  const { data: planData } = useQuery(getAllPlansQuery)

  const [addWorkspace, { loading: addLoading }] = useMutation(
    formValues.kind === 'BILLING'
      ? adminAddClientBillingMutation
      : adminAddClientDistributionMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        $(`#${id}`).foundation('close')
        addSuccessAlert({ title: 'Workspace created' })
        refetch()
        onSubmit()
      },
      refetchQueries: [
        {
          query: getAdminClientsQuery,
        },
      ],
    }
  )

  const [updateWorkspace, { loading: editLoading }] = useMutation(
    formValues.kind === 'BILLING'
      ? adminUpdateClientBillingMutation
      : adminUpdateClientDistributionMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        $(`#${id}`).foundation('close')
        addSuccessAlert({ title: 'Workspace updated' })
        refetch()
        onSubmit()
      },
      refetchQueries: [
        {
          query: getAdminClientsQuery,
        },
      ],
    }
  )

  useEffect(() => {
    setFormValues(
      (defaultValues
        ? {
            ...defaultValues,
            kind: defaultValues.workspaceKind,
            planId: defaultValues?.plan?.id,
            ...(defaultValues.workspaceManagedByClientName
              ? {
                  managedByClientId: clients.find(
                    (c) => c.name === defaultValues.workspaceManagedByClientName
                  ).id,
                }
              : {}),
          }
        : null) || initialValues
    )
  }, [defaultValues])

  useEffect(() => {
    if (planData) {
      if (defaultValues?.id && defaultValues?.plan?.id)
        setSelectedPlan(
          planData.plans.find((p) => p.id === defaultValues?.plan?.id)
        )
      else setSelectedPlan(planData.plans[0])
    }
  }, [planData, defaultValues])

  useEffect(() => {
    setSubmitDisabled(
      !formValues.name ||
        (!formValues.id &&
          (!formValues.ownerFirstName ||
            !formValues.ownerLastName ||
            !formValues.ownerEmail))
    )
  }, [formValues])

  if (!currentUser || !data || !clientData || !planData) {
    return <></>
  }

  const { clients } = data
  const billingWorkspaces = sortOnKey(
    clients.filter((c) => c.workspaceKind === 'BILLING'),
    'name'
  )

  const handleChange = (e) => {
    const { name, value } = e.target
    let newValues = { ...formValues, [name]: value }
    switch (name) {
      case 'planId':
        setSelectedPlan(planData.plans.find((p) => p.id === value))
        newValues.planBillingPeriod = subscriptionPeriods[0].value
        newValues.planBillingCurrency = currencies[0]
        break
      case 'kind':
        if (value === 'BILLING') {
          const defaultPlan = planData.plans[0]
          newValues.planId = defaultPlan.id
          newValues.planBillingPeriod = subscriptionPeriods[0].value
          newValues.planBillingCurrency = currencies[0]
          newValues.planAdditionalWorkspaces = 0
          newValues.planAdditionalPublishedScenarios = 0
          setSelectedPlan(planData.plans[0])

          delete newValues.managedByClientId
        } else {
          delete newValues.planId
          delete newValues.planBillingPeriod
          delete newValues.planBillingCurrency
          delete newValues.planAdditionalWorkspaces
          delete newValues.planAdditionalPublishedScenarios
          newValues.managedByClientId = clients[0].id
        }
        break
    }
    setFormValues(newValues)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formValues.id) return updateWorkspace({ variables: formValues })

    addWorkspace({ variables: formValues })
  }

  const handleFeature = ({ target }) => {
    const { checked, value } = target
    if (checked)
      return setFormValues({
        ...formValues,
        features: [...formValues.features, value],
      })
    return setFormValues({
      ...formValues,
      features: formValues.features.filter((f) => f !== value),
    })
  }

  const renderFeatures = () => {
    return (
      <>
        <div className="c-form__label--lined ">
          <label>Feature flags</label>
        </div>
        <label>Features included in workspace</label>
        {features.map((feature) => (
          <label className="o-checkbox" key={feature.value}>
            {feature.name}
            <input
              checked={formValues.features.includes(feature.value)}
              type="checkbox"
              name="features[]"
              value={feature.value}
              onChange={handleFeature}
            />
            <span></span>
          </label>
        ))}
      </>
    )
  }

  const getBillingFields = () => {
    if (formValues.kind !== 'BILLING') {
      return (
        <>
          <label>
            Workspace is managed by <span className="text-assertive">*</span>
          </label>
          <label>
            <select
              name="managedByClientId"
              required
              onChange={handleChange}
              value={formValues.managedByClientId}>
              {billingWorkspaces.map((w) => (
                <option key={w.id} value={w.id}>
                  {w.name}
                </option>
              ))}
            </select>
          </label>
        </>
      )
    }

    return (
      <>
        <div className="row">
          <div className="small-12 columns pl-0">
            <label>Plan </label>
            <label>
              <select
                name="planId"
                required
                onChange={handleChange}
                value={formValues.planId}>
                {planData.plans.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-6 columns pl-0">
            <label>Subscription period </label>
            <label>
              <select
                name="planBillingPeriod"
                required
                onChange={handleChange}
                value={formValues.planBillingPeriod || ''}>
                {subscriptionPeriods.map((s) => (
                  <option key={s.value} value={s.value}>
                    {s.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="small-6 columns">
            <label>Currency</label>
            <label>
              <select
                name="planBillingCurrency"
                required
                onChange={handleChange}
                value={formValues.planBillingCurrency || 'EUR'}>
                {currencies.map((c) => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>

        <div className="row">
          {selectedPlan &&
            selectedPlan.features.includes('PUBLISHED_SCENARIOS') && (
              <div className="small-6 columns pl-0">
                <label>Additional published scenarios slots</label>
                <label>
                  <NumberInput
                    value={formValues.planAdditionalPublishedScenarios}
                    min={0}
                    changeHandler={(v) =>
                      handleChange({
                        target: {
                          value: v,
                          name: 'planAdditionalPublishedScenarios',
                        },
                      })
                    }
                    step={2}
                  />
                </label>
              </div>
            )}

          {selectedPlan && selectedPlan.features.includes('WORKSPACES') && (
            <div
              className={`small-6 columns ${
                !selectedPlan.features.includes('PUBLISHED_SCENARIOS') && 'pl-0'
              }`}>
              <label>Additional workspaces</label>
              <label>
                <NumberInput
                  value={formValues.planAdditionalWorkspaces}
                  min={0}
                  changeHandler={(v) =>
                    handleChange({
                      target: {
                        value: v,
                        name: 'planAdditionalWorkspaces',
                      },
                    })
                  }
                  step={1}
                />
              </label>
            </div>
          )}
        </div>
      </>
    )
  }

  const getOwnerFields = () => {
    return (
      <>
        <div className="c-form__label--lined ">
          <label>Workspace owner</label>
        </div>

        <div className="row">
          <div className="small-6 columns pl-0">
            <label>
              First name <span className="text-assertive">*</span>
            </label>
            <input
              type="text"
              required
              name="ownerFirstName"
              value={formValues.ownerFirstName}
              onChange={handleChange}
            />
          </div>
          <div className="small-6 columns pr-0">
            <label>
              Last name <span className="text-assertive">*</span>
            </label>
            <input
              type="text"
              required
              name="ownerLastName"
              value={formValues.ownerLastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="small-6 columns pl-0">
            <label>
              Email address <span className="text-assertive">*</span>
            </label>
            <input
              type="email"
              required
              name="ownerEmail"
              value={formValues.ownerEmail}
              onChange={handleChange}
            />
          </div>
          <div className="small-6 columns pr-0">
            <label>Employe no</label>
            <input
              type="text"
              name="ownerEmployeeNr"
              value={formValues.ownerEmployeeNr}
              onChange={handleChange}
            />
          </div>
        </div>
      </>
    )
  }

  const onClose = () => {
    setTimeout(() => {
      setFormValues(initialValues)
    }, 500)
    onCloseCallback()
  }

  return (
    <Modal
      id={id}
      key={id}
      appendTo={appendTo}
      overflowY="visible"
      headerText={`${formValues.id ? 'Edit' : 'New'} workspace`}
      onClose={onClose}>
      <form className="mt-3" onSubmit={handleSubmit}>
        <label>
          Workspace name <span className="text-assertive">*</span>
        </label>
        <input
          type="text"
          required
          name="name"
          value={formValues.name}
          onChange={handleChange}
        />

        <label>Workspace type</label>
        <label className="o-radio">
          <input
            type="radio"
            name="kind"
            value="NORMAL"
            required
            checked={
              formValues.kind === 'NORMAL' || formValues.kind === 'BILLING'
            }
            onChange={() => {
              setFormValues({
                ...formValues,
                kind: formValues.kind === 'BILLING' ? 'BILLING' : 'NORMAL',
              })
            }}
          />
          <span></span>
          Creation
        </label>
        <label className="o-radio mb-3">
          <input
            type="radio"
            name="kind"
            value="DISTRIBUTION"
            required
            checked={formValues.kind === 'DISTRIBUTION'}
            onChange={handleChange}
          />
          <span></span>
          Distribution
          <em className="text-stable-dark">
            &nbsp;- Scenario creation is not possible with this workspace.
          </em>
        </label>

        <div className="c-form__label--lined ">
          <label>Billing workspace</label>
        </div>

        <label>Billing workspace</label>
        <label className="o-radio">
          <input
            name="billing"
            type="radio"
            value="no"
            required
            checked={formValues.kind === 'BILLING'}
            onChange={() => {
              handleChange({
                target: {
                  value: 'BILLING',
                  name: 'kind',
                },
              })
            }}
          />
          <span></span>
          Yes
        </label>
        <label className="o-radio mb-3">
          <input
            value="yes"
            name="billing"
            type="radio"
            required
            checked={formValues.kind !== 'BILLING'}
            onChange={() =>
              handleChange({
                target: {
                  value: 'NORMAL',
                  name: 'kind',
                },
              })
            }
          />
          <span></span>
          No
          <em className="text-stable-dark">
            &nbsp;- Workspace is managed by another billing workspace.
          </em>
        </label>

        {getBillingFields()}
        {!formValues.id && getOwnerFields()}
        {renderFeatures()}

        <div className="flex-container align-right mb-3">
          <button
            type="button"
            className="hollow button secondary wide mr-1 mb-0"
            data-close={id}>
            Cancel
          </button>
          <button
            type="submit"
            className={`button primary wide mb-0 ${
              (addLoading || editLoading) && 'o-button--loading'
            }`}
            disabled={submitDisabled}>
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default AddEditAdminWorkspaceModal
