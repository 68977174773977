const sortHandler = (value, preferences, setPreferences) => {
  const { sortOn } = preferences

  const newUserPreferences = {
    ...preferences,
    sortOn: {
      value,
      isAscending: value === sortOn.value ? !sortOn.isAscending : false,
    },
  }
  setPreferences(newUserPreferences)
}

const filterColumnsHandler = (v, preferences, setPreferences) => {
  const { filteredColumns: f } = preferences
  let newFilteredColumns = [...f]
  if (f.includes(v)) {
    newFilteredColumns = newFilteredColumns.filter((i) => i !== v)
  } else {
    newFilteredColumns.push(v)
  }
  setPreferences({
    ...preferences,
    filteredColumns: newFilteredColumns,
  })
}

export { sortHandler, filterColumnsHandler }
