import React from 'react'
import Dropdown from '../../../UI/Menu/Dropdown'
import { FaChevronDown } from 'react-icons/fa'

const OPTIONS = [
  { value: 'desc', label: 'Newest first' },
  { value: 'asc', label: 'Oldest first' },
]
const HistorySortDropdown = ({ selected = 'desc', setSelected = () => {} }) => {
  return (
    <Dropdown
      id="history-sort-menu"
      alignment="right"
      offset={{ left: '20px', top: '0px' }}
      minWidth={200}
      button={
        <button
          data-toggle="history-sort-menu"
          className="o-button o-button--square-small o-button--light flex-container align-middle mb-0 pl-1 pr-1 w-100 text-normal"
          style={{ justifyContent: 'space-between' }}>
          <span className="text-bold">
            {OPTIONS.find((opt) => opt.value === selected).label}
          </span>
          <FaChevronDown className="ml-1" />
        </button>
      }>
      {OPTIONS.map((opt) => (
        <li
          className={`o-dropdown__list-item flex-container align-middle ${opt.value === selected ? 'text-assertive' : 'text-dark'}`}
          onClick={() => setSelected(opt.value)}>
          {opt.label}
        </li>
      ))}
    </Dropdown>
  )
}

export default HistorySortDropdown
