import React, { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../../UI/Modal'
import GroupsList from '../GroupsList'
import { updateGroupMutation } from '../../../apollo/query/groups'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import GroupRestrictAccess from '../GroupRestrictAccess'

const EditGroupModal = ({
  id,
  appendTo,
  purchasedCourses,
  purchasedScenarios,
  group,
  callback,
}) => {
  const courseRef = useRef([])
  const scenarioRef = useRef([])

  const [key, setKey] = useState(uuidv4())
  const [values, setValues] = useState({
    name: '',
    startDate: null,
    endDate: null,
  })
  const [restrictedAccess, setRestrictedAccess] = useState(false)

  const [updateGroup] = useMutation(updateGroupMutation, {
    onError: handleApolloError,
    onCompleted: () =>
      addSuccessAlert({ title: 'Group changes have been saved' }),
  })

  const submit = () => {
    let { publicLinkEnabled } = group

    if (!courseRef.current.length && !scenarioRef.current.length) {
      publicLinkEnabled = false
    }

    updateGroup({
      variables: {
        publicLinkEnabled,
        id: group.id,
        publishedScenarioIds: scenarioRef.current.map((i) => Number(i)),
        publicLinkDomains: group.publicLinkDomains,
        courseIds: courseRef.current.map((i) => Number(i)),
        name: values.name ? values.name : group.name,
        startDate:
          restrictedAccess && values.startDate ? values.startDate : null,
        endDate: restrictedAccess && values.endDate ? values.endDate : null,
      },
    })

    setTimeout(() => {
      setValues({ name: '' })
      setKey(uuidv4())
    }, 300)
  }

  const cancel = () => {
    setTimeout(callback, 300)
  }

  useEffect(() => {
    if (group) {
      setValues({
        ...values,
        startDate: group.startDate,
        endDate: group.endDate,
      })
      setRestrictedAccess(!!(group.startDate && group.endDate))
      setKey(uuidv4())
    }
  }, [group])
  if (!group)
    return (
      <Modal
        id={id}
        appendTo={appendTo}
        closeOnEscape={false}
        useDefaultButtons
        submitHandler={submit}
        cancelHandler={callback}
      />
    )

  const getGroupLists = () => {
    const grouplists = [
      {
        label: 'Courses',
        type: 'course',
        ref: courseRef,
        list: group.courses,
      },
      {
        label: 'Scenarios',
        type: 'scenario',
        ref: scenarioRef,
        list: group.publishedScenarios,
      },
    ]
    return grouplists.map((g, index) => (
      <div key={index} className="cell small-12">
        <label>{g.label}</label>
        <GroupsList
          type={g.type}
          purchasedItems={g.type === "course" ? purchasedCourses : purchasedScenarios}
          selectionRef={g.ref}
          currentList={g.list}
        />
      </div>
    ))
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      headerText="Edit group"
      footerText="Trainees will be notified by email in case of new course or scenario access."
      overflowY="visible"
      submitDisabled={
        restrictedAccess &&
        (!values.startDate ||
          !values.endDate ||
          new Date(values.endDate).getTime() / 1000 <
            new Date(values.startDate).getTime() / 1000)
      }
      submitHandler={submit}
      cancelHandler={cancel}>
      <form className="mt-3" key={key}>
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <label htmlFor="group-title" className="c-form__label--required">
              Group title
            </label>
            <input
              type="text"
              defaultValue={group.name}
              autoComplete="false"
              onChange={({ target }) => {
                setValues({ ...values, name: target.value })
              }}
              name="group-title"
              id="group-title"
            />
          </div>
        </div>
        {getGroupLists()}
        <GroupRestrictAccess
          restrictedAccess={restrictedAccess}
          setRestrictedAccess={setRestrictedAccess}
          group={group}
          setValues={setValues}
          values={values}
        />
      </form>
    </Modal>
  )
}

export default EditGroupModal
