import React, { useContext } from 'react'
import { SceneEditorContext } from '../../Editor/SceneEditorContext'

const Nadir = () => {
  const [sceneState] = useContext(SceneEditorContext)

  const getRotation = () => {
    if (sceneState.northOffsetEnabled) return `-90 -${Number(sceneState.northOffset)} 0`
    return '-90 0 0'
  }
  return (
    <a-entity position="0 -5.2 0">
      <a-circle segments="180" src="#client-vr-logo" rotation={getRotation()} />
    </a-entity>
  )
}
export default Nadir
