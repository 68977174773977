import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const SharedDevicesOptionDropdown = ({ id, setCurrentDeviceId }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (isHovered)
    return (
      <Dropdown
        id={`shared-devices-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`shared-devices-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        <li
          className="o-dropdown__list-item text-normal text-dark"
          onClick={() => setCurrentDeviceId(id)}
          data-open="edit-device-modal">
          Edit device
        </li>
        <hr className="mb-1 mt-1" />
        <li
          data-open="delete-device-modal"
          className="o-dropdown__list-item text-normal text-dark"
          onClick={() => setCurrentDeviceId(id)}>
          Delete device
        </li>
      </Dropdown>
    )

  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`shared-devices-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}
export default SharedDevicesOptionDropdown
