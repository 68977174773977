import React, { useContext, useEffect, useRef } from 'react'
import RangeInput from '../../../UI/Form/RangeInput'
import { MediumContext } from '../MediumContext'

const TimeSlider = () => {
  const {
    medium: [medium],
    controls: [controls],
    time: [time, setTime],
    currentVideoRef,
  } = useContext(MediumContext)

  const updateInterval = useRef(null)

  useEffect(() => {
    if (controls.isPlaying && medium.state === 'COMPLETED') {
      updateInterval.current = setInterval(() => {
        setTime(currentVideoRef.current.currentTime * 1000)
      }, 1000 / medium.fps)
    }
    return () => clearInterval(updateInterval.current)
  }, [controls.isPlaying, medium.id])

  const updateSliderHandler = (value) => {
    currentVideoRef.current.currentTime = Number(value / 1000)
    if (value * 1000 === 0) setTime(0.001)
    else setTime(value)
  }
  return (
    <div id="slider-time" className="cell small-6 auto xxxlarge-7 end">
      <RangeInput
        minValue={0}
        maxValue={Number(medium.duration)}
        noBackground
        value={time}
        redThumb
        showLabels={false}
        setValue={updateSliderHandler}
      />
    </div>
  )
}
export default TimeSlider
