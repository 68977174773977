import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LanguageContextProvider from '../utils/context/LanguageContextProvider'
import LauncherTemplate from '../pages/Launcher/LauncherTemplate'

import CourseScenarioLauncher from '../pages/Launcher/CourseScenarioLauncher'
import SessionLauncher from '../pages/Launcher/SessionLauncher'

const Router = () => {
  return (
    <LanguageContextProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/p/:groupSecret"
            element={
              <LauncherTemplate>
                <CourseScenarioLauncher />
              </LauncherTemplate>
            }
          />
          <Route
            path="/u/:signinLinkSecret"
            element={
              <LauncherTemplate>
                <SessionLauncher />
              </LauncherTemplate>
            }
          />
        </Routes>
      </BrowserRouter>
    </LanguageContextProvider>
  )
}

export default Router
