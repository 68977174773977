import React from 'react'
import { useMutation } from '@apollo/client'
import {
  getAllPlansQuery,
  removePlanMutation,
} from '../../../apollo/query/plans'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import Modal from '../../UI/Modal'

const DeletePlanModal = ({ id, appendTo, planId, onClose }) => {
 
  const [removePlan] = useMutation(removePlanMutation, {
    onError: handleApolloError,
    onCompleted: async () => {
      $(`#${id}`).foundation('close')
      addSuccessAlert({ title: 'Plan was deleted successfully' })
    },
    refetchQueries: [
      {
        query: getAllPlansQuery,
      },
    ],
  })
  
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this plan?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={onClose}
      submitHandler={() => {
        removePlan({ variables: { id: planId } })
        onClose()
      }}
    />
  )
}
export default DeletePlanModal
