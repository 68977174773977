import React from 'react'
import ActivityGraph from './ActivityGraph'
import Bullet from './Bullet'
import colors from '../Editor/helpers/colors'

const AttemptsTab = ({ data: attemptsMetricsData }) => {
  return (
    <div className="grid-container fluid">
      <ActivityGraph
        data={attemptsMetricsData.attemptsMetrics.grouped}
        renderTooltip={(value) => (
          <div className="flex-container align-middle align-justify">
            <span>
              <Bullet color={colors.assertive} />
              <span className="ml-1">Training attempts</span>
            </span>
            <span className="text-bold ml-3 pl-2">{value}</span>
          </div>
        )}
      />
    </div>
  )
}

export default AttemptsTab
