import React from 'react'
import { default as S } from '../../../utils/lang/en.json'

const TraineeUserRole = ({ role }) => {
  if (role === 'USER') return S.dataTypes.user.roles[role]

  let className
  switch (role) {
    case 'MANAGER':
      className = 'energized'
      break
    case 'OWNER':
      className = 'dark'
      break
    case 'WRITER':
      className = 'balanced'
      break
    case 'INSTRUCTOR':
      className = 'royal'
      break
    case 'PUBLISHER':
      className = 'assertive'
      break
    default:
      return null
  }

  return (
    <label
      data-testid="label"
      className={`o-label--status o-label--${className}`}>
      {S.dataTypes.user.roles[role]}
    </label>
  )
}

export default TraineeUserRole
