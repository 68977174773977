const kindFormatter = (kind) => {
  const kinds = {
    LTI: 'LTI 1.1',
    SCORM: 'SCORM 1.2',
    API: 'API',
    OAUTH: 'OAUTH 2.0',
    OCN: 'OCN (SAP SF)',
  }
  return kinds?.[kind] || ''
}

export { kindFormatter }
