import React, { useContext } from 'react'
import colors from '../../../helpers/colors'
import { SceneEditorContext } from '../../../SceneEditorContext'
import { getRotation } from '../../../helpers/controls'

const NorthOffsetLine = () => {
  const [sceneState] = useContext(SceneEditorContext)
  return (
    <a-entity
      rotation={`${getRotation({ x: sceneState.northOffset, y: 0 })} 0`}>
      <a-entity
        id="northOffsetLine"
        position={`0 0 -49`}
        geometry="primitive: plane; height: 1000; width:0.4;"
        material={`color: ${colors.assertive}; opacity: 0.5`}
      />
    </a-entity>
  )
}
export default NorthOffsetLine
