import React, { Component } from 'react'
import { observer } from 'mobx-react'

@observer
class TooltipConnector extends Component {
  render() {
    const { answer, type } = this.props

    const scene = answer.store.positionWithinCard(
      answer.toConnectorX,
      answer.toConnectorY
    )

    if (type === 'incoming' && answer.isDragging && !scene) {
      return (
        <div
          className="tooltip"
          style={{ width: 400, left: -104 }}
          role="tooltip">
          Release here to create new scene
        </div>
      )
    }
    return null
  }
}

export default TooltipConnector
