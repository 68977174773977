import React, { useContext, useEffect, useRef } from "react"
import { ScenarioEditorContext } from "../../../context/ScenarioEditorProvider"
import { getPointColor } from "../../../helpers/elementHelper"
import { FaFlagCheckered } from "react-icons/fa"

const EndingNode = ({ points }) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const endingRef = useRef(null)
  
  useEffect(() => {
    endingRef.current.style.setProperty(
      '--point-color',
      getPointColor(points, scenario.scoringSystem.kind)
    )
  }, [points])
  
  return (
    <div className="o-scene o-scene--ending" ref={endingRef}>
      <h2 className="o-scene__title">
        <FaFlagCheckered />
        Ending
      </h2>
    </div>
  )
}

export default EndingNode