import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../UI/Modal'
import { createSessionMutation } from '../../../apollo/query/session'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const GetTraineeCodeModal = ({ id, appendTo, currentTrainee }) => {
  const [sessionInfo, setSessionInfo] = useState(null)
  const [multipleOrganisations, setMultipleOrganisations] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const traineeNameOrEmail =
    !!currentTrainee.firstName && !!currentTrainee.lastName
      ? `${currentTrainee.firstName} ${currentTrainee.lastName}`
      : currentTrainee.email

  const [createSession] = useMutation(createSessionMutation, {
    onCompleted: (data) => {
      const { session } = data.createSession
      if (session) {
        setSessionInfo(session)
        setMultipleOrganisations(false)
      }
    },
    onError: (error) => {
      if (error.message === 'trainee is member of multiple workspaces') {
        setMultipleOrganisations(true)
      } else {
        handleApolloError(error)
      }
    },
  })

  const showEmailAlert = () => {
    addSuccessAlert({
      title: 'Mail sent!',
      subtitle: `${traineeNameOrEmail} should get an email shortly`,
    })
  }

  const [sendSessionEmail] = useMutation(createSessionMutation, {
    onError: handleApolloError,
    onCompleted: () => showEmailAlert(),
  })

  const footerText = (
    <button
      className="button hollow secondary mb-0"
      style={{ fontSize: '14px' }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        sendSessionEmail({
          variables: {
            userId: currentTrainee.id,
            email: true,
          },
        })
      }}>
      Email sign in instructions to {traineeNameOrEmail}
    </button>
  )

  const multipleOrganisationsPartial = (
    <>
      <div
        className=" o-alert--danger border-radius p-1"
        style={{ fontSize: '14px' }}>
        Since this user is part of multiple organisations, we cannot provide a
        6-digit code. You can send an email using the button below.
      </div>
      <div className="text-center mt-2">{footerText}</div>
    </>
  )
  const codePartial = (
    <>
      <span id="code" className="c-launcher__sign-in--code code-flex mb-2">
        {sessionInfo?.code}
      </span>
      <p>
        <span className=" text-bold mt-1 text-dark">
          This code is valid for 60 minutes and can only be used once.
        </span>{' '}
        Make sure to only give this code to {traineeNameOrEmail}.
      </p>
      <div className="text-center">
        <h3 className="text-dark text-italic text-striped mb-2">OR</h3>
        {footerText}
      </div>
    </>
  )
  const showContent = () => {
    if (multipleOrganisations) return multipleOrganisationsPartial
    return codePartial
  }
  const clear = () => {
    setTimeout(() => {
      setShowButton(true)
    }, 300)
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="Help trainee to sign in Warp VR app"
      cancelHandler={clear}
      buttons={
        <button className="button primary" onClick={clear} data-close={id}>
          Close
        </button>
      }
      subtitle="Help trainee to sign in to the Warp VR app by giving them this 6-digit code">
      <div className="cell small-12 mt-3 mb-2">
        <label>Choose sign in method</label>
        <p>
          If you click on <span className="text-italic">Generate code</span> you
          will receive a six digit code to give to the trainee. This is useful
          for local sessions. For remote sessions, please send an email to the
          trainee.
        </p>
      </div>
      {showButton ? (
        <div className=" mb-2 cell small-12 text-center">
          <button
            className="button primary wide"
            onClick={() => {
              setMultipleOrganisations(false)
              createSession({
                variables: {
                  userId: currentTrainee.id,
                  email: false,
                },
              })
              setShowButton(false)
            }}>
            Generate code
          </button>
          <div className="text-center">
            <h3 className="text-dark text-italic text-striped mb-2">OR</h3>
            {footerText}
          </div>
        </div>
      ) : (
        showContent()
      )}
    </Modal>
  )
}

export default GetTraineeCodeModal
