import React, { useContext } from 'react'
import useSelectedNode from '../../hooks/useSelectedNode'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { useReactFlow } from 'reactflow'
import { updateSceneFilmingMutation } from '../../../../apollo/query/scenes'
import { handleApolloError } from '../../../../utils/errors'
import { setNode } from '../../helpers/nodeHelper'
import SearchableDropdown from '../../../UI/Menu/SearchableDropdown'
import { useMutation } from '@apollo/client'
import SceneNumberLabel from '../FlowEditor/elements/SceneNumberLabel'

const FilmingScenesDropdown = ({
  hideHandler,
  show,
  selectedScene,
  onUpdateSceneFilming = () => {},
}) => {
  const reactFlow = useReactFlow()
  const selectedNode = selectedScene
    ? reactFlow.getNode(selectedScene.id)
    : useSelectedNode()
  const scene = selectedNode.data
  const { scenario, refetch } = useContext(ScenarioEditorContext)

  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const handleChangeElement = (selectedItem) => {
    const newData = {
      cameraLocationId: null,
      useSameVideoAsId: selectedItem.id,
      filming: scene.filming,
    }
    const newNode = {
      ...selectedNode,
      data: {
        ...scene,
        ...newData,
      },
    }

    updateSceneFilming({
      variables: {
        id: scene.id,
        ...newData,
      },
    }).then(() => {
      onUpdateSceneFilming()
    })

    setNode(reactFlow, newNode)
  }

  return (
    <SearchableDropdown
      placeholder="Search a scene"
      clickHandler={(scene) => {
        hideHandler()
        handleChangeElement(scene)
      }}
      hideHandler={hideHandler}
      notFoundTitle={'No scenes found'}
      notFoundText={<small>You don't have any scenes to add</small>}
      data={scenario.scenes
        .filter((sc) => sc.filming)
        .map((sc) => ({
          ...sc,
          value: sc.name,
          render: (
            <div className="flex-container align-middle text-normal">
              <SceneNumberLabel number={sc.number} className="mr-1" />
              {sc.name}
            </div>
          ),
        }))}
      show={show}
      position="right"
    />
  )
}

export default FilmingScenesDropdown
