import React from 'react'
import { FaRandom } from 'react-icons/fa'
import useFoundation from '../../../hooks/useFoundation'
import { default as S } from '../../../utils/lang/en.json'

const Card = ({
  id,
  imageUrl,
  imageOverlay,
  title,
  hoverValue,
  subtitle,
  content,
  clickHandler,
  loadingIcon,
  isDisabled,
  menu,
}) => {
  const ref = useFoundation()

  const getCardImage = () => {
    const loading = (
      <div className="cursor-pointer loading-dual-ring-container">
        <div className="loading-dual-ring">{loadingIcon || <FaRandom />}</div>
      </div>
    )
    const showImage = () => {
      let cn = 'image-overlay'
      if (isDisabled) cn += ' image-overlay--disabled'
      return (
        <>
          <img className="cursor-pointer" src={imageUrl} loading="lazy" />
          <div className={cn}>
            {imageOverlay}
            <span className="shadow-container"></span>
          </div>
        </>
      )
    }
    if (isDisabled) {
      return (
        <div className="aspect-ratio">
          <span
            ref={ref}
            data-tooltip
            data-position="bottom"
            data-alignment="center"
            data-v-offset="-30"
            title={S.strings.publishingCannotOpen}>
            {imageUrl ? showImage() : loading}
          </span>
        </div>
      )
    }
    return (
      <div className="aspect-ratio">{imageUrl ? showImage() : loading}</div>
    )
  }
  return (
    <div
      ref={ref}
      id={id}
      className={`card o-card ${menu && 'o-card--overflow'}`}>
      <div className="o-card--image container" onClick={clickHandler}>
        {getCardImage()}
      </div>
      <div className="grid-x grid-x-padding">
        <div className=" cell auto">
          <div className="card-divider o-card--title" title={hoverValue}>
            {title}
          </div>
          <h4 className=" o-card--content text-stable-dark">{subtitle}</h4>
        </div>
        {menu && (
          <div id={id} className="cell shrink pt-1">
            {menu(id)}
          </div>
        )}
      </div>
      {content && (
        <div className="card-section o-card--content">
          <p>{content}</p>
        </div>
      )}
    </div>
  )
}

export default Card
