import useSelectedNode from './useSelectedNode'

const useHasHotspot = () => {
  const selectedNode = useSelectedNode()
  if (!selectedNode) return false

  return selectedNode.data.elements.some((el) => el.kind === 'HOTSPOT')
}

export default useHasHotspot
