import React from 'react'

const LoadingSpinner = ({ dotsOnly = false, scale, text, noHeight }) => {
  const loadingTexts = [
    'Please wait...',
    'Fetching data from the server...',
    'We will show results here shortly...',
    'The server seems to be busy, please wait...',
  ]

  const loadText = () => {
    const i = Math.floor(Math.random() * loadingTexts.length)
    return loadingTexts[i]
  }
  let style = {}
  if (scale) {
    style = {
      transform: `scale(${scale})`,
    }
  }
  return (
    <div
      className={`loading__container ${
        noHeight ? '' : 'loading__container--height'
      }`}>
      <div
        data-testid="loading-container"
        className="loading__container--inner"
        style={style}>
        <div className="loading-bars">
          <div></div>
          <div></div>
          <div></div>
        </div>
        {!dotsOnly && (
          <span data-testid="text" className="loading-text text-stable-dark">
            {text || loadText()}
          </span>
        )}
      </div>
    </div>
  )
}

export default LoadingSpinner
