import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import { getCurrentClientSettingsQuery } from '../../../apollo/query/settings'
import useMembers from '../../../hooks/useMembers'
import { updateClientSettingsMutation } from '../../../apollo/query/clients'
import { getCurrentPlanQuery } from '../../../apollo/query/plans'
import { FaInfoCircle } from 'react-icons/fa'

const AuthenticationTab = () => {
  const { data: currentPlanData } = useQuery(getCurrentPlanQuery)
  const enterpriseEnabled =
    currentPlanData?.currentPlan.features.includes('ENTERPRISE')

  const skipMembers = !currentPlanData || !enterpriseEnabled
  const { data: currentClientData } = useQuery(getCurrentClientSettingsQuery)
  const { data: members, error } = useMembers({
    skipMembers,
  })

  const [updateClientSettings, { loading: editLoading }] = useMutation(
    updateClientSettingsMutation,
    {
      onError: handleApolloError,
      onCompleted: async () => {
        addSuccessAlert({ title: 'Two-factor authentication has been updated' })
      },
    }
  )

  if (!currentPlanData || !currentClientData || (!skipMembers && !members)) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  if (error) throw new Error(error.message)

  const { currentClient } = currentClientData
  const allMembersConfiguredOtp =
    skipMembers || members.every((m) => m.otpConfigured)
  const membersWithoutOtp =
    skipMembers || members.filter((m) => !m.otpConfigured)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const elements = e.target.elements
    const checked = !!Number(elements['OTP_REQUIRED'].checked)

    updateClientSettings({
      variables: {
        settings: checked
          ? [...currentClient.settings, 'OTP_REQUIRED']
          : [...currentClient.settings.filter((s) => s !== 'OTP_REQUIRED')],
      },
    })
  }

  return (
    <>
      <div className="row">
        <div className="xlarge-3 medium-4 small-12 columns pl-0">
          <h2 className="text-bold">Two-factor authentication</h2>
          <p>
            Requiring an additional authentication method adds another level of{' '}
            security for your organization. Once enabled. each new member in
            this workspace will have to configure 2FA in the activation process.
          </p>
        </div>
        <div className="medium-6 small-12 columns mt-0">
          {enterpriseEnabled ? (
            <form onSubmit={handleSubmit}>
              <label>
                Require 2FA for everyone in the {currentClient.name} workspace?
              </label>
              <label className="o-checkbox">
                <input
                  type="checkbox"
                  name="OTP_REQUIRED"
                  value="1"
                  defaultChecked={currentClient.settings.includes(
                    'OTP_REQUIRED'
                  )}
                />
                <span></span>
                Yes, 2FA is required for all members
              </label>
              <div className="mt-3">
                <button
                  type="submit"
                  className={`o-button o-button--assertive float-right button primary text-normal mb-0 ${
                    editLoading && 'o-button--loading'
                  }`}
                  disabled={editLoading}
                  aria-hidden="true">
                  Confirm
                </button>
              </div>
            </form>
          ) : (
            <div className="border-energized p-2 flex-container align-top border-radius text-energized xlarge-9 medium-10 text-normal">
              <FaInfoCircle style={{ fontSize: '26px' }} />
              <span className="ml-1 text-dark">
                Upgrade to the Scale plan to get access to 2FA requirement for
                the full {currentClient.name} workspace. Contact{' '}
                <a
                  className="text-underline text-dark"
                  href="mailto:sales@warpvr.com">
                  sales@warpvr.com
                </a>{' '}
                to upgrade.
              </span>
            </div>
          )}
        </div>
      </div>
      {!allMembersConfiguredOtp && (
        <div className="row pb-3 pt-3 mb-3 mt-3 border-top">
          <div className="medium-3 small-12 columns pl-0">
            <h2 className="text-bold">Members do not have 2FA enabled</h2>
            <p>
              Contact these members and inform them activate 2FA for their
              account.
            </p>
          </div>
          <div className="medium-6 small-12 columns mt-0">
            {!allMembersConfiguredOtp && (
              <>
                <div className="border-light border-radius">
                  {membersWithoutOtp.map((m, index) => (
                    <div
                      key={m.id}
                      className={`text-normal pl-2 pr-2 pt-1-5 pb-1-5 ${
                        membersWithoutOtp.length - 1 !== index
                          ? 'border-bottom'
                          : ''
                      }`}>
                      {m.name}
                      <span className="text-stable-dark"> ⋅ {m.email}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div
              className="pt-4 pb-4 mb-1 flex-container align-middle align-center border-light border-radius"
              hidden={!allMembersConfiguredOtp}>
              <span className="text-normal text-italic text-stable">
                All members have activated 2FA...
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default withApollo(AuthenticationTab)
