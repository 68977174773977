import React, { useEffect } from 'react'
import useFoundation from '../../hooks/useFoundation'

const DataTooltip = ({
  position = 'bottom',
  id,
  title,
  width,
  fullWidth = false,
  delay,
  children,
  hOffset = 0,
  vOffset = 0,
}) => {
  const ref = useFoundation()

  useEffect(() => {
    const currentRef = ref.current
    return () => {
      if (currentRef) {
        const tooltipEl = document.getElementById(
          `${currentRef.dataset.toggle}`
        )
        if (tooltipEl) tooltipEl.remove()
      }
    }
  }, [])

  return (
    <span
      id={id}
      className="cursor-default border-none"
      data-tooltip
      style={
        fullWidth
          ? { width: '100%' }
          : {
              width: width ? `${width}px` : 'auto',
            }
      }
      data-allow-html
      data-hover-delay={delay || 200}
      data-click-open
      data-position={position}
      ref={ref}
      title={title}
      data-h-offset={hOffset}
      data-v-offset={vOffset}>
      {children}
    </span>
  )
}
export default DataTooltip
