const getBlobfromDataURL = (dataUrl, type = 'image/png') => {
  const binary = atob(dataUrl.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type })
}

const getFileFromBlob = (blob, name, type) => {
  return new File([blob], name, { type, lastModified: new Date() })
}
export { getBlobfromDataURL, getFileFromBlob }
