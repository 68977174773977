import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'

@inject('diagramStore')
@observer
class Scene extends Component {
  render() {
    const { scene } = this.props
    return (
      <div
        onMouseLeave={() => {
          scene.showMoreMenu = false
        }}>
        <ul
          className={classNames('o-action-list o-action-list--top', {
            'o-action-list--top-persistant': scene.showMoreMenu,
          })}
          style={{ zIndex: scene.baseZIndex + 15 }}>
          <li className="o-action-list__item">
            <a
              className="o-action-list__button o-action-list__button--eye"
              onClick={() => scene.show()}
            />
          </li>
          <li className="o-action-list__item">
            <a
              className="o-action-list__button o-action-list__button--edit"
              onClick={() => scene.edit()}
            />
          </li>
          <li className="o-action-list__item">
            <a
              className="o-action-list__button o-action-list__button--test"
              onClick={() => scene.test_scenario()}
            />
          </li>
          <li className="o-action-list__item" style={{ position: 'relative' }}>
            <a
              className="o-action-list__button o-action-list__button--more"
              onClick={() => {
                this.props.diagramStore.activeElement = scene
                scene.showMoreMenu = !scene.showMoreMenu
              }}
            />
          </li>
        </ul>
        <div
          className={`c-flip c-flip--card ${
            scene.isFlipped && 'c-flip--flipped'
          }`}
          style={{
            zIndex:
              scene.isFlipped || scene.shouldDrawLine
                ? scene.baseZIndex + 10
                : scene.baseZIndex,
          }}>
          <div className="c-flip__holder">
            <div className="c-flip__front">
              <div
                className="c-textual-card c-textual-card--on-diagram"
                style={{ minHeight: scene.cardHeight }}>
                <span
                  className={`o-label o-label--transparent o-label--${scene.kind}`}>
                  <span className="o-label__content">
                    {scene.kind_formatted}
                  </span>
                </span>
                {scene.start && (
                  <span className="o-label o-label--energized o-label--home u-pull-right u-small-mls" />
                )}
                {scene.has_video && (
                  <span className="o-label o-label--royal o-label--video u-pull-right u-small-mls" />
                )}
                {scene.isRandomized && (
                  <span className="o-label o-label--balanced o-label--randomized u-pull-right u-small-mls" />
                )}
                <h2 className="c-textual-card__title c-textual-card__title--extended">
                  {scene.priority && `${scene.priority}.`} {scene.name}
                </h2>
              </div>
            </div>
            <div className="c-flip__back">
              <div
                className="c-textual-card c-textual-card--inverted c-textual-card--on-diagram"
                style={{ minHeight: scene.cardHeight }}>
                {scene.question && scene.kind !== 'transition' && (
                  <h2 className="c-textual-card__title c-textual-card__title--auto-height">
                    {scene.question}
                  </h2>
                )}
                {scene.kind !== 'transition' &&
                  scene.answers.map((answer) => {
                    return (
                      <p key={answer.id}>
                        {answer.letter}. {answer.name}
                      </p>
                    )
                  })}
                {scene.kind === 'transition' && (
                  <span className="o-label o-label--transparent o-label--transition o-label--centered">
                    <span className="o-label__content">
                      {scene.kind_formatted}
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Scene
