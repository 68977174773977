import { gql } from '@apollo/client'
import { PRODUCT_FIELDS } from './fragments'

const getProductsQuery = gql`
  ${PRODUCT_FIELDS}
  query getProducts {
    products {
      ...ProductFields
    }
  }
`

const addProductMutation = gql`
  ${PRODUCT_FIELDS}
  mutation addProduct(
    $name: String!
    $description: String!
    $kind: ProductKind!
    $licenseType: ProductLicenseType!
    $price: Int!
    $licenseDuration: Int!
    $courseIds: [Int!]!
    $scenarioIds: [Int!]!
    $state: ProductState!
    $blobId: String
  ) {
    addProduct(
      name: $name
      description: $description
      kind: $kind
      licenseType: $licenseType
      licenseDuration: $licenseDuration
      price: $price
      state: $state
      courseIds: $courseIds
      scenarioIds: $scenarioIds
      blobId: $blobId
    ) {
      product {
        ...ProductFields
      }
    }
  }
`
const getPurchasedProductsQuery = gql`
  query purchasedProducts {
    purchasedProducts {
      id
      client {
        id
        name
        logoMobileUrl
        expert
      }
      name
      description
      imageUrl
      purchases {
        activeLicenseCount
        availableLicenseCount
        expirationDate
        expiredLicenseCount
        kind
        id
        licenseType
        licenseDuration
        purchaseDate
        state
        usedLicenseCount
      }
      subscriptions {
        licenseDuration
        licenseType
        price
        renewalDate
        renewalLicenseCount
        startDate
        state
      }
      courses {
        scenarios {
          id
          description
          imageUrl
          name
        }
        id
        name
        description
      }
      scenarios {
        id
        name
        description
        imageUrl
      }
    }
  }
`
const updateProductMutation = gql`
  ${PRODUCT_FIELDS}
  mutation updateProduct(
    $id: ID!
    $name: String!
    $description: String!
    $kind: ProductKind!
    $licenseType: ProductLicenseType!
    $price: Int
    $licenseDuration: Int!
    $state: ProductState!
    $blobId: String
    $courseIds: [Int!]!
    $scenarioIds: [Int!]!
  ) {
    updateProduct(
      id: $id
      name: $name
      description: $description
      kind: $kind
      licenseType: $licenseType
      licenseDuration: $licenseDuration
      price: $price
      state: $state
      courseIds: $courseIds
      scenarioIds: $scenarioIds
      blobId: $blobId
    ) {
      product {
        ...ProductFields
      }
    }
  }
`
const removeProductMutation = gql`
  mutation removeProduct($id: ID!) {
    removeProduct(id: $id) {
      id
    }
  }
`
export {
  getProductsQuery,
  addProductMutation,
  updateProductMutation,
  removeProductMutation,
  getPurchasedProductsQuery,
}
