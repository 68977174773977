import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Delay from '../../utils/helpers/Delay'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import { handleApolloError } from '../../utils/errors'
import withApollo from '../../hooks/withApollo'
import {
  getCredentialsQuery,
  registerCredentialMutation,
  confirmRegisterCredentialMutation,
  updateCredentialMutation,
  removeCredentialMutation,
} from '../../apollo/query/credentials'
import * as WebAuthnJSON from '@github/webauthn-json'
import DataTooltip from '../UI/DataTooltip'
import cache from '../../apollo/cache'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { dateFormat } from '../../utils/format'
import { addErrorAlert, addSuccessAlert } from '../../utils/helpers/alerts'
import DeleteCredentialModal from './DeleteCredentialModal'
import UAParser from 'ua-parser-js'
import UpdateCredentialModal from './UpdateCredentialModal'

const PASSKEY_ERROR = 'An error occurred when adding a passkey'

const Credentials = ({ currentUser }) => {
  const [showSuccessNotice, setShowSuccessNotice] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const { data, loading, error } = useQuery(getCredentialsQuery)

  const [registerCredential] = useMutation(registerCredentialMutation, {
    onError: handleApolloError,
  })

  const [confirmRegisterCredential] = useMutation(
    confirmRegisterCredentialMutation,
    {
      onError: handleApolloError,
    }
  )

  const [removeCredential] = useMutation(removeCredentialMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      $('#delete-credential-modal').foundation('close')
      addSuccessAlert({
        title: 'Successfully deleted a passkey',
      })
      cache.writeQuery({
        query: getCredentialsQuery,
        data: {
          credentials: data.credentials.filter((dc) => dc.id !== selectedId),
        },
      })
    },
  })

  const [updateCredential] = useMutation(updateCredentialMutation, {
    onError: handleApolloError,
    onCompleted: (updatedCred) => {
      $('#update-credential-modal').foundation('close')
      addSuccessAlert({
        title: 'Successfully configured a passkey',
      })
      const newCredentials = [...data.credentials]
      const index = newCredentials.findIndex(
        (nc) => nc.id === updatedCred.updateCredential.credential.id
      )
      newCredentials[index] = updatedCred.updateCredential.credential
      cache.writeQuery({
        query: getCredentialsQuery,
        data: {
          credentials: newCredentials,
        },
      })
    },
  })

  const initiateRegisterCredential = async () => {
    const parserResult = new UAParser().getResult()
    const nickname = `${parserResult.browser.name} on ${parserResult.os.name}`
    // calls mutation that requests the parameters to register an authenticator
    registerCredential({
      onCompleted: (regCredData) => {
        const credentialOptions = JSON.parse(
          regCredData.registerCredential.options
        )
        // ask the device to create the authenticator for us
        WebAuthnJSON.create({
          publicKey: credentialOptions,
        })
          .then((credential) => {
            // calls mutations to register the credential
            confirmRegisterCredential({
              variables: {
                nickname: nickname,
                publicKeyCredential: JSON.stringify(credential),
              },
              onCompleted: (newCred) => {
                // add credential to the list
                cache.writeQuery({
                  query: getCredentialsQuery,
                  data: {
                    credentials: [
                      ...data.credentials,
                      newCred.confirmRegisterCredential.credential,
                    ],
                  },
                })
                setShowSuccessNotice(true)
                setSelectedId(newCred.confirmRegisterCredential.credential.id)
                $('#update-credential-modal').foundation('open')
              },
            })
          })
          .catch((error) => {
            addErrorAlert({ title: PASSKEY_ERROR })
          })
      },
    })
  }

  if (loading)
    return (
      <Delay timeout="500">
        <LoadingSpinner text="Searching for credentials" />
      </Delay>
    )
  if (error) handleApolloError(error)

  const passkeysConfigured = !!data?.credentials?.length

  const renderAddButton = () => {
    if (!currentUser.otpConfigured)
      return (
        <DataTooltip
          id="disable-passkey-tooltip"
          title="You can only configure passkeys after you configured two-factor authentication with an authenticator app.
          "
          position="top">
          <div className="inline-block">
            <button
              disabled
              type="button"
              className="o-button o-button--hollow mb-0 text-normal text-bold">
              Add passkey
            </button>
          </div>
        </DataTooltip>
      )
    return (
      <button
        onClick={initiateRegisterCredential}
        type="button"
        className="o-button o-button--hollow mb-0 text-normal text-bold">
        Add passkey
      </button>
    )
  }

  return (
    <>
      <div id="config-credential-detail">
        <label className="mb-1">
          Passkeys{' '}
          {passkeysConfigured && (
            <span className="o-label o-label--success text-white text-small">
              CONFIGURED
            </span>
          )}
        </label>

        <div
          className="pt-4 pb-4 mb-1 flex-container align-middle align-center border-light border-radius"
          hidden={data.credentials.length !== 0}>
          <span className="text-normal text-italic text-bold text-stable">
            No passkeys configured…
          </span>
        </div>
      </div>

      <ul className="m-0 p-0" hidden={data.credentials.length === 0}>
        {data.credentials.map((credential) => {
          return (
            <div
              className="text-normal pl-2 pr-2 pt-1-5 pb-1-5 flex-container align-middle align-justify border-light border-radius mb-1"
              key={credential.externalId}>
              <div>
                <span>{credential.nickname}</span>{' '}
                <span className="text-stable-dark">
                  • Added on{' '}
                  {dateFormat(
                    credential.createdAt ?? new Date(),
                    'MMM DD, YYYY',
                    false
                  )}
                </span>
              </div>
              <div>
                <a
                  className="mr-2 text-stable-dark"
                  data-open="update-credential-modal"
                  title="Edit"
                  onClick={() => {
                    setShowSuccessNotice(false)
                    setSelectedId(credential.id)
                  }}>
                  <FaPencilAlt />
                </a>

                <a
                  className="text-assertive"
                  title="Delete"
                  data-open="delete-credential-modal"
                  onClick={() => setSelectedId(credential.id)}>
                  <FaTrash />
                </a>
              </div>
            </div>
          )
        })}
      </ul>

      {renderAddButton()}

      <DeleteCredentialModal
        id="delete-credential-modal"
        appendTo="#config-credential-detail"
        onDelete={() => removeCredential({ variables: { id: selectedId } })}
        onClose={() => setSelectedId(null)}
      />
      <UpdateCredentialModal
        id="update-credential-modal"
        appendTo="#config-credential-detail"
        selectedCredential={data.credentials.find((c) => c.id === selectedId)}
        onSubmit={(values) => updateCredential({ variables: values })}
        showSuccessNotice={showSuccessNotice}
      />
    </>
  )
}

export default withApollo(Credentials)
