import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import Card from './card'
import Answer from './answer'
import IncomingConnector from './connectors/incoming-connector'
import OutgoingConnector from './connectors/outgoing-connector'
import MoreMenu from './more-menu'

@inject('diagramStore')
@observer
class Canvas extends Component {
  type = 'canvas'

  componentDidMount() {
    const { diagramStore } = this.props

    if (diagramStore.cards.length === 0) {
      jQuery('.o-empty-state').show()
      jQuery('[data-reactroot]').removeClass('c-canvas')
    } else {
      jQuery('.o-empty-state').hide()
      jQuery('[data-reactroot]').addClass('c-canvas')
    }

    diagramStore.setInitialState()
  }

  onCanvasClick = (e) => {
    this.props.diagramStore.saveCanvasState()

    // only turn scenes back when click is on canvas
    if (e.target.nodeName !== 'svg') {
      return
    }
    if (!this.props.diagramStore.selection.isSelecting) {
      this.props.diagramStore.activeElement = this
    }
    // this.props.diagramStore.selection.clickOnCanvas();
  }

  onCanvasDoublClick = (e) => {
    const { diagramStore } = this.props
    // only load popup if canvas has been double clicked
    if (e.target.nodeName !== 'svg') {
      return
    }

    diagramStore.activeElement = { type: 'dblClickMenu' }

    const x = e.clientX - 10
    const y = e.clientY - 170

    diagramStore.addNewCardAt(
      (x + diagramStore.workarea.scrollLeft()) / diagramStore.zoomFactor,
      (y + diagramStore.workarea.scrollTop()) / diagramStore.zoomFactor,
      null,
      'Scene'
    )
  }

  dragStart = (e) => {
    if (
      e.target.nodeName !== 'svg' &&
      !this.props.diagramStore.selection.isSpaceDragging
    ) {
      return
    }
    this.props.diagramStore.selection.dragStart(e)
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.diagramStore.cards.length === 0) {
      jQuery('.o-empty-state').show()
      jQuery('[data-reactroot]').removeClass('c-canvas')
    } else {
      jQuery('.o-empty-state').hide()
      jQuery('[data-reactroot]').addClass('c-canvas')
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.enableSpaceDrag)
    document.addEventListener('keyup', this.disableSpaceDrag)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.enableSpaceDrag)
    document.removeEventListener('keyup', this.disableSpaceDrag)
  }

  enableSpaceDrag = (e) => {
    if (e.code === 'Space') {
      if (e.target === document.body) {
        e.preventDefault()
      }
      this.props.diagramStore.selection.isSpaceDragging = true
    } else if (e.code === 'ShiftLeft' || e.code === 'ShiftRight') {
      // e.preventDefault();
      this.props.diagramStore.selection.isHoldingShift = true
    }
  }

  disableSpaceDrag = () => {
    this.props.diagramStore.selection.isSpaceDragging = false
    this.props.diagramStore.selection.isHoldingShift = false
    this.props.diagramStore.selection.resetSelectionArea()
  }

  render() {
    const { diagramStore } = this.props

    let cursor = 'default'
    if (diagramStore.selection.isSpaceDragging) {
      cursor = 'all-scroll'
    }

    const answers = []
    diagramStore.scenes.forEach((scene) => {
      scene.answers.forEach((answer) => {
        answers.push(answer)
        if (answer.randomizedAnswers.length > 0) {
          answers.push(...answer.randomizedAnswers)
        }
      })
    })

    return (
      <div
        style={{
          transform: `scale(${diagramStore.zoomFactor})`,
          // transition: `transform .5s linear`,
          transformOrigin: `50% 50%`,
        }}>
        <div
          className="c-canvas"
          onDoubleClick={this.onCanvasDoublClick}
          onClick={this.onCanvasClick}
          onMouseDown={this.dragStart}
          style={{
            width: diagramStore.canvas.width,
            height: diagramStore.canvas.height,
            cursor,
            transform: `translateX(${
              (-(1 / diagramStore.zoomFactor - 1) / 2) * window.innerWidth
            }px)`,
            // transition: `transform .5s linear`,
            position: 'absolute',
            top: 0,
          }}>
          <svg
            onDrag={this.drag}
            className="c-drawboard"
            style={{
              width: diagramStore.canvas.width - diagramStore.canvas.padding,
              height: diagramStore.canvas.height - diagramStore.canvas.padding,
            }}>
            {answers.map((answer) => {
              return <Answer answer={answer} key={answer.key} />
            })}
          </svg>

          {diagramStore.cards.map((card) => {
            return <Card key={card.id} card={card} />
          })}

          {answers.map((answer) => {
            return <OutgoingConnector answer={answer} key={answer.key} />
          })}

          {answers.map((answer) => {
            return <IncomingConnector answer={answer} key={answer.key} />
          })}

          {diagramStore.selection.isSelecting &&
            diagramStore.selection.selectableArea.y2 != null && (
              <div
                className="c-canvas__select"
                style={{
                  position: 'absolute',
                  top: Math.min(
                    diagramStore.selection.selectableArea.y1,
                    diagramStore.selection.selectableArea.y2
                  ),
                  left: Math.min(
                    diagramStore.selection.selectableArea.x1,
                    diagramStore.selection.selectableArea.x2
                  ),
                  width: Math.abs(
                    diagramStore.selection.selectableArea.x1 -
                      diagramStore.selection.selectableArea.x2
                  ),
                  height: Math.abs(
                    diagramStore.selection.selectableArea.y1 -
                      diagramStore.selection.selectableArea.y2
                  ),
                }}
              />
            )}
          {/* 
          {diagramStore.doubleClickedCanvas.active && (
            <NewCardMenu
              x={diagramStore.doubleClickedCanvas.x}
              y={diagramStore.doubleClickedCanvas.y}
            />
          )} */}

          {diagramStore.cards.map((card) => {
            return <MoreMenu card={card} key={card.id} />
          })}
        </div>
      </div>
    )
  }
}

export default Canvas
