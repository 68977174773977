import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidV4 } from 'uuid'
import languageDetect from '../../../helpers/languageDetect'
import fonts from '../../../helpers/fonts'

const TextBubble = ({
  textValue,
  id,
  width = 1.8,
  type = 'default',
  bottomSpacing = 0.15,
}) => {
  const [fontUrl, setFontUrl] = useState(fonts.latin)
  const [uuid, setUuid] = useState(uuidV4())
  const textRef = useRef(null)
  useEffect(() => {
    if (textValue) {
      setUuid(uuidV4())
      const guessedLanguage = languageDetect.detect(textValue, 1)[0]
      const certainty = guessedLanguage[1]
      if (certainty < 0.1) {
        setFontUrl(fonts.fallback)
      } else if (fontUrl !== fonts[guessedLanguage[0]]) {
        setFontUrl(fonts[guessedLanguage[0]])
      }
    }
  }, [textValue])

  return (
    <a-entity
      key={uuid}
      id={id}
      ref={textRef}
      text-bubble={`enabled: true; type: ${type}; width: ${width}; bottomSpacing: ${bottomSpacing}`}>
      <a-text
        id={`${id}-text`}
        value={textValue}
        font={fontUrl}
        color="black"
        anchor="center"
        align="center"
        material="shader: flat; visible: false; color: orange;"
        geometry="primitive: plane; width: auto; height: auto;"
        wrap-count={width > 1.8 ? width * 8.4 : 14}
        letter-spacing={-2}
        shader="msdf"
        negate="false"
        width={width - 0.2}
      />
      <a-entity id="textbubblecontainer" />
    </a-entity>
  )
}

export default TextBubble
