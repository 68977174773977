import React, { useEffect, useRef, useState } from 'react'
import { FaArrowUp, FaBan } from 'react-icons/fa'

const DragAndDrop = ({ uploadHandler, children, disabled = false, types }) => {
  const allowedFormats = types || ['mp4']
  const [dragging, setDragging] = useState(false)
  const dropContainer = useRef()
  const dragOverlay = useRef()

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target !== dragOverlay.current || !dragging) {
      setDragging(true)
    }
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target === dragOverlay.current) {
      setDragging(false)
    }
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setDragging(false)
    if (disabled) {
      return null
    }
    const files = [...e.dataTransfer.files]
    if (
      allowedFormats &&
      files.some(
        (file) =>
          !allowedFormats.some((format) =>
            file.name.toLowerCase().endsWith(format)
          )
      )
    ) {
      console.warn(
        `Only the following file extensions are allowed ${allowedFormats.join(
          ', '
        )}`
      )

      return
    }
    return uploadHandler(files)
  }
  useEffect(() => {
    const drop = dropContainer.current
    drop.addEventListener('dragover', handleDragOver)
    drop.addEventListener('dragenter', handleDragEnter)
    drop.addEventListener('dragleave', handleDragLeave)
    drop.addEventListener('drop', handleDrop)
    return () => {
      drop.removeEventListener('dragover', handleDragOver)
      drop.removeEventListener('drop', handleDrop)
      drop.removeEventListener('dragenter', handleDragEnter)
      drop.removeEventListener('dragleave', handleDragLeave)
    }
  })
  if (disabled)
    return (
      <>
        <div className="o-files-drop" ref={dropContainer}>
          {dragging && (
            <div ref={dragOverlay} className="o-files-drop--overlay disabled">
              <span className="o-files-drop--content">
                <FaBan className="o-files-drop--icon" />
                <h2 className="text-bold text-center">
                  Cannot upload into archived
                </h2>
                <p>Please select a scenario first</p>
              </span>
            </div>
          )}
          {children}
        </div>
      </>
    )
  return (
    <>
      <div className="o-files-drop" ref={dropContainer}>
        {dragging && (
          <div ref={dragOverlay} className="o-files-drop--overlay">
            <span className="o-files-drop--content">
              <FaArrowUp className="o-files-drop--icon" />
              <h2 className="text-bold">Drop your files</h2>
              <p>to upload</p>
            </span>
          </div>
        )}
        {children}
      </div>
    </>
  )
}

export default DragAndDrop
