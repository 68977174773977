import React from 'react'
import emptyScene from '../../../../../../images/empty-icon.svg'
import emptySceneArrow from '../../../../../../images/empty-scene-arrow.svg'

const EmptyState = ({ type }) => {
  const renderTitle = () => {
    if (type === 'empty') return 'Come on! Let’s create a scene'
    return 'No scene selected'
  }
  const renderParagraph = () => {
    if (type === 'empty')
      return (
        <>
          Double-click anywhere on the canvas to create your first scene.{' '}
          <a
            href="https://help.warpvr.com/scenes/jjFsB7uc6Zz1fHqDWfkVMs/%F0%9F%96%BC-what-is-a-scene/9zZEguNnRJDy5qPSkrst5d"
            target="_blank"
            className="text-stable-dark text-underline">
            What is a scene?
          </a>
        </>
      )
    return 'Select a scene or double-click anywhere on the canvas to create a scene.'
  }
  return (
    <aside className="flex-container flex-dir-column align-middle text-center">
      <div
        style={{
          width: '200px',
          height: '200px',
          backgroundColor: 'var(--main-bg-color)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '120px',
        }}>
        <img src={emptyScene} width="100px" alt="Empty scene" />
      </div>
      <h2 className="mt-2 text-bold">{renderTitle()}</h2>
      <p style={{ width: '295px' }}>{renderParagraph()}</p>
      {type === 'empty' && (
        <p className="pl-4 flex-container align-left w-100">
          <img
            className="ml-1-5"
            src={emptySceneArrow}
            width="208px"
            alt="Empty scene"
          />
        </p>
      )}
    </aside>
  )
}

export default EmptyState
