import React, { useState } from 'react'
import Filter from '../UI/Menu/Filter'
import DatePicker from '../UI/DatePicker/DatePicker'
import {
  dateFormat,
  getPeriodEndDateTime,
  getPeriodStartDateTime,
} from '../../utils/format'

const periods = [
  { value: '-1_hour', render: 'Last hour' },
  { value: '-24_hours', render: 'Last 24 hours' },
  { value: '-7_days', render: 'Last 7 days' },
  { value: '-30_days', render: 'Last 30 days' },
  { value: '', isDivider: true },
  { value: '0_month', render: 'This month' },
  { value: '-1_month', render: 'Last month' },
  { value: '0_year', render: 'This year' },
  { value: '-1_year', render: 'Last year' },
]

const PeriodDropdown = ({ selected = '-30_days', setSelected }) => {
  const [startDate, setStartDate] = useState(
    typeof selected === 'object'
      ? selected[0]
      : getPeriodStartDateTime(selected)
  )
  const [endDate, setEndDate] = useState(
    typeof selected === 'object' ? selected[1] : getPeriodEndDateTime(selected)
  )
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const footer = () => {
    return (
      <div className="pt-1-5 pb-1 pl-2 pr-2 mt-1 border-top">
        <div className="text-stable-dark mb-1 text-small">
          Or select a custom range
        </div>
        <DatePicker
          selectsRange
          inline
          maxDate={new Date()}
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
        />
        <div className="flex-container align-right w-100 mt-1">
          <button
            className="o-button text-normal text-bold o-button--auto-width o-button--auto-height"
            disabled={!startDate || !endDate}
            onClick={() => {
              if (startDate && endDate) setSelected([startDate, endDate])
            }}>
            Confirm
          </button>
        </div>
      </div>
    )
  }
  return (
    <>
      <Filter
        text="Period"
        selected={
          typeof selected === 'object'
            ? `${dateFormat(startDate, 'DD MMM', false)}-${dateFormat(
                endDate,
                'DD MMM',
                false
              )}`
            : periods.find((p) => p.value === selected).render
        }
        filterHandler={(filter) => {
          setSelected(periods.find((p) => p.value === filter).value)
        }}
        filterOptions={periods}
        dropdownPosition="bottom"
        dropdownAlignment="right"
        footer={footer()}
      />
    </>
  )
}
export default PeriodDropdown
