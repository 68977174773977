import React, { useState } from 'react'
import BrandingTab from '../../components/Settings/General/BrandingTab'
import EmailTab from '../../components/Settings/General/EmailTab'
import { TabContent, TabTitles } from '../../components/UI/Tabs/Tabs'
import OwnerTab from '../../components/Settings/General/OwnerTab'
import AuthenticationTab from '../../components/Settings/General/AuthenticationTab'

const General = () => {
  const [activeTab, setActiveTab] = useState(0)

  const titles = ['Branding', 'Emails', 'Owner', 'Authentication security']

  const content = [
    <BrandingTab key="branding-tab" />,
    <EmailTab key="email-tab" />,
    <OwnerTab key="owner-tab" />,
    <AuthenticationTab key="authentication-tab" />,
  ]

  return (
    <div id="general-settings-table" className="grid-container fluid row">
      <div className="columns small-12 large-3 xxlarge-2 pl-0">
        <TabTitles
          titles={titles}
          activeTab={activeTab}
          selectHandler={setActiveTab}
        />
      </div>
      <div className="columns">
        <TabContent titles={titles} content={content} activeTab={activeTab} />
      </div>
    </div>
  )
}

export default General
