import { observable, reaction, computed } from 'mobx'
import axios from 'axios'

class AnswerStore {
  @observable id = 0

  @observable letter = null

  @observable fromScene = null

  @observable toScene = null

  @observable ending = false

  @observable name = null

  @observable points = 0

  @observable priority = 0

  @observable toConnectorX = 0

  @observable toConnectorY = 0

  @observable isDragging = false

  @observable isAddingNewCard = false

  @observable showMenu = null

  @observable showLetterMenu = null

  @observable scoring_system = null

  @observable custom_score = null

  @observable randomized = 0

  @observable randomizedAnswers = []

  @observable randomizedIndex = 0

  @observable randomizedParent = null

  store = null

  type = 'answer'

  dimensions = {
    width: 24,
    height: 24,
  }

  constructor(store, fromScene, toScene, jsonObject) {
    this.store = store
    this.fromScene = fromScene
    this.toScene = toScene

    this.assignProperties(
      [
        'id',
        'name',
        'ending',
        'points',
        'priority',
        'link_to_id',
        'letter',
        'showMenu',
        'showLetterMenu',
        'scoring_system',
        'custom_score',
      ],
      jsonObject
    )

    this.randomized = jsonObject.randomized_links.length
    jsonObject.randomized_links.forEach((randomizedLink) => {
      const randomizedLinkToScene =
        randomizedLink !== 0 ? this.store.findCardById(randomizedLink) : null

      this.addRandomizedAnswer(randomizedLinkToScene)
    })

    if (toScene != null) {
      toScene.incomingAnswers.push(this)
    }

    ;[this.toConnectorX, this.toConnectorY] = this.toAnswerPosition()

    // Add answer to scene when answer is connected to new scene
    reaction(
      () => [this.toScene],
      () => {
        if (this.toScene != null) {
          this.toScene.addIncomingAnswer(this)
        }
        this.save()
      }
    )

    // Update position of answer when scene is dragged
    reaction(
      () => [
        this.toScene != null && this.toScene.x,
        this.toScene != null && this.toScene.y,
        this.toScene == null && this.fromScene.x,
        this.toScene == null && this.fromScene.y,
      ],
      () => {
        ;[this.toConnectorX, this.toConnectorY] = this.toAnswerPosition()
      }
    )

    // Add or remove randomized answers
    reaction(
      () => [this.randomized],
      () => {
        let difference = this.randomized - this.randomizedAnswers.length
        let i = 0

        if (difference > 0) {
          for (i = 0; i < difference; i++) {
            this.addRandomizedAnswer(null)
          }
        }

        if (difference < 0) {
          difference = Math.abs(difference)
          for (i = 0; i < difference; i++) {
            this.randomizedAnswers.pop()
          }
        }

        this.showLetterMenu = false
        this.save()
      }
    )

    reaction(
      () => [this.points],
      () => {
        this.randomizedAnswers.forEach((randomizedAnswer) => {
          randomizedAnswer.points = this.points
        })
      }
    )

    reaction(
      () => [this.custom_score],
      () => {
        this.randomizedAnswers.forEach((randomizedAnswer) => {
          randomizedAnswer.custom_score = this.custom_score
        })
      }
    )
  }

  assignProperties(props, object) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop of props) {
      this[prop] = object[prop]
    }
  }

  addRandomizedAnswer(toScene) {
    const randomizedAnswer = new AnswerStore(
      this.store,
      this.fromScene,
      toScene,
      {
        id: this.id,
        points: this.points,
        scoring_system: this.scoring_system,
        custom_score: this.custom_score,
        priority: this.priority,
        letter: this.letter,
        randomized_links: [],
      }
    )

    randomizedAnswer.randomizedIndex = this.randomizedAnswers.length + 2
    randomizedAnswer.randomizedParent = this

    this.randomizedAnswers.push(randomizedAnswer)
  }

  fromConnectorOffset() {
    let baseOffset = this.fromScene.answerOffset(this.id)

    if (this.randomizedIndex > 0) {
      // add offset for randomized answers (positioned directly below answer)
      baseOffset += (this.randomizedIndex - 1) * 25
    }

    return baseOffset
  }

  toConnectorOffset() {
    return (
      (this.toScene.cardHeight / (this.toScene.incomingAnswers.length + 1)) *
      this.connector_priority
    )
  }

  fromAnswerPosition() {
    return [
      this.fromScene.x + this.fromScene.cardWidth,
      this.fromConnectorOffset() +
        this.fromScene.y -
        this.dimensions.height / 2,
    ]
  }

  toAnswerPosition() {
    if (this.shouldDrawLine) {
      return [this.toConnectorX, this.toConnectorY]
    }
    if (this.toScene == null) {
      return this.fromAnswerPosition()
    }
    return [
      this.toScene.x,
      this.toConnectorOffset() + this.toScene.y - this.dimensions.height / 2,
    ]
  }

  getFillClass() {
    if (this.toScene != null) {
      return 'filled'
    }
    return 'unfilled'
  }

  @computed
  get key() {
    return `${this.id}-${this.randomizedIndex}`
  }

  @computed
  get getClassName() {
    let classNames
    if (
      this.store.activeAnswer &&
      this.store.activeAnswer.key === this.key &&
      this.store.activeElement !== this
    ) {
      return 'highlight'
    }

    if (this.scoring_system === 'disabled') {
      return 'normal'
    }

    if (this.custom_score != null) {
      return 'custom'
    }
    if (this.scoring_system === 'add') {
      classNames = { '-2': 'best', '-1': 'good', 0: 'normal' }
    } else {
      classNames = { '-2': 'worst', '-1': 'bad', 0: 'normal' }
    }
    return classNames[this.points]
  }

  openCustomScoreUrl() {
    const href = `${this.store.urls.answers_url.replace(
      -1,
      this.fromScene.id
    )}/${this.id}/custom_score/edit`

    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  openCustomRandomizedScenesUrl() {
    const href = `${this.store.urls.answers_url.replace(
      -1,
      this.fromScene.id
    )}/${this.id}/custom_randomized_scene/edit`

    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  @computed
  get shouldDrawLine() {
    return this.isDragging || this.isAddingNewCard
  }

  @computed
  get connector_priority() {
    return (
      this.toScene.incomingAnswers.findIndex((a) => {
        return a.id === this.id && a.randomizedIndex === this.randomizedIndex
      }) + 1
    )
  }

  @computed
  get isRandomized() {
    return this.randomizedIndex > 0
  }

  save() {
    axios
      .patch(
        `${this.store.urls.answers_url.replace(-1, this.fromScene.id)}/${
          this.id
        }.json`,
        {
          answer: this.asJson(),
          authenticity_token: this.store.authenticity_token,
        }
      )
      .then((response) => {
        const new_answer = response.data
        this.custom_score = new_answer.custom_score
      })
  }

  asJson() {
    if (this.randomizedParent != null) {
      return this.randomizedParent.asJson()
    }

    const link_to_id = this.toScene == null ? null : this.toScene.id
    const randomized_links = this.randomizedAnswers.map((randomizedAnswer) => {
      // map empty answers to 0 to avoid rails parameters stripping null values
      return randomizedAnswer.toScene == null ? 0 : randomizedAnswer.toScene.id
    })

    return {
      id: this.id,
      card_id: this.fromScene.id,
      link_to_id,
      points: this.points,
      priority: this.priority,
      custom_score: this.custom_score,
      randomized_links,
    }
  }
}

export default AnswerStore
