import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import Modal from '../../UI/Modal'
import { getScenariosQuery } from '../../../apollo/query/scenarios'
import { duplicateMediumMutation } from '../../../apollo/query/media'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'
import useThrottle from '../../../hooks/useThrottle'

const CopyMediumModal = ({
  id,
  currentScenario,
  appendTo,
  mediumIds = [],
  callback,
}) => {
  const [selectedScenario, setSelectedScenario] = useState()
  const [showScenarioSelector, setShowScenarioSelector] = useState(false)
  const [createQueue, createTask] = useThrottle()

  const multiple = mediumIds?.length > 1

  const { loading, data } = useQuery(getScenariosQuery, {
    onCompleted: (d) => {
      const current = d.scenarios.find((i) => i.id === currentScenario)
      if (current)
        setSelectedScenario({
          value: current.name,
          render: current.name,
          id: current.id,
        })
    },
  })

  const subtitle = (n) => {
    const type = n === 1 ? 'file' : 'files'
    return `Copied ${n} ${type}`
  }

  const title = () => {
    const n = multiple ? 'files' : 'file'
    return `Successfully copied media ${n}`
  }

  const [copy] = useMutation(duplicateMediumMutation, {
    onError: handleApolloError,
  })

  const values = () => [
    ...data.scenarios.reduce((a, sc) => {
      a.push({ value: sc.name, id: sc.id, render: sc.name })
      return a
    }, []),
  ]

  const copyHandler = () => {
    const tasks = mediumIds.map((i) =>
      createTask(() =>
        copy({
          variables: {
            id: i,
            scenarioId: selectedScenario.id,
          },
        })
      )
    )

    createQueue(tasks)
      .then((results) => {
        const filesWithoutErrors = results.filter((result) => !result.errors)

        // Show a success notification for the ones that copied correctly
        if (filesWithoutErrors.length) {
          return addSuccessAlert({
            title: title(),
            subtitle: `${subtitle(filesWithoutErrors.length)} to '${
              data.scenarios.find(
                (scenario) => scenario.id === selectedScenario.id
              ).name
            }'`,
          })
        }
        return null
      })
      .then(() => callback())
  }
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      overflowY="visible"
      closable={false}
      headerText={`Copy file${mediumIds.length === 1 ? '' : 's'}`}
      useDefaultButtons
      submitHandler={copyHandler}>
      <form className="mt-3" data-testid="component-wrapper">
        <label className="text-normal text-stable-dark">To scenario</label>
        {!loading && (
          <div className="cell small-12">
            <button
              data-testid="open-select-button"
              className="button"
              style={{
                width: '100%',
                backgroundColor: '#eee',
                color: 'black',
              }}
              readOnly={true}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowScenarioSelector(true)
              }}>
              <div
                style={{
                  display: 'flex',
                  flexGrow: '1',
                }}>
                <span
                  data-testid="select"
                  className="text-left"
                  style={{ flexGrow: '1' }}>
                  {selectedScenario?.value || 'Select a scenario'}
                </span>
                <FaChevronDown />
              </div>
            </button>
            <SearchableDropdown
              placeholder="Select a scenario"
              show={showScenarioSelector}
              defaultSelected={[...values()].filter(
                (item) => item.id === currentScenario
              )}
              clickHandler={(value) => {
                setSelectedScenario(value)
                setShowScenarioSelector(false)
              }}
              hideHandler={() => setShowScenarioSelector(false)}
              data={[...values()].sort((a, b) =>
                a.value.localeCompare(b.value, 'en', { numeric: true })
              )}
            />
          </div>
        )}
      </form>
    </Modal>
  )
}

export default CopyMediumModal
