import { observable } from 'mobx'
import axios from 'axios'

class SelectionStore {
  @observable isSpaceDragging = false

  @observable isHoldingShift = false

  @observable isSelecting = false

  @observable selectionActive = false

  @observable
  selectableArea = {
    x1: null,
    y1: null,
    x2: null,
    y2: null,
  }

  scrollOffset = {
    top: 0,
    left: 0,
  }

  scrollArea = null

  store = null

  type = 'selection'

  constructor(store) {
    this.store = store
    this.isSelecting = false
  }

  dragStart = (e) => {
    if (!this.isSelecting) {
      if (this.isSpaceDragging) {
        this.selectableArea.x1 = e.pageX + this.store.workarea.scrollLeft()
        this.selectableArea.y1 = e.pageY - 150 + this.store.workarea.scrollTop()
      } else {
        this.selectableArea.x1 =
          (e.pageX + this.store.workarea.scrollLeft()) / this.store.zoomFactor
        this.selectableArea.y1 =
          (e.pageY - 150 + this.store.workarea.scrollTop()) /
          this.store.zoomFactor
      }

      window.document.addEventListener('mousemove', this.drag)
      window.document.addEventListener('mouseup', this.dragStop)
    }
  }

  drag = (e) => {
    e.preventDefault()
    this.store.activeElement = this
    if (!this.isSpaceDragging) {
      this.isSelecting = true
    }

    if (this.isSelecting) {
      this.selectableArea.x2 =
        (e.pageX + this.store.workarea.scrollLeft()) / this.store.zoomFactor
      this.selectableArea.y2 =
        (e.pageY - 150 + this.store.workarea.scrollTop()) /
        this.store.zoomFactor
      this.checkSelectedCards()
    }

    if (this.isSpaceDragging) {
      this.selectableArea.x2 = e.pageX
      this.selectableArea.y2 = e.pageY - 150

      this.store.workarea.scrollLeft(
        this.selectableArea.x1 - this.selectableArea.x2
      )
      this.store.workarea.scrollTop(
        this.selectableArea.y1 - this.selectableArea.y2
      )
    }
  }

  dragStop = () => {
    window.document.removeEventListener('mousemove', this.drag)
    window.document.removeEventListener('mouseup', this.dragStop)
    this.selectionActive = true
    setTimeout(() => {
      this.isSelecting = false
    }, 100)

    this.resetSelectionArea()
  }

  resetSelectionArea() {
    this.selectableArea = {
      x1: null,
      x2: null,
      y1: null,
      y2: null,
    }
  }

  clickOnCanvas() {
    if (this.isSelecting) {
      this.isSelecting = false
    } else if (this.selectionActive) {
      this.selectionActive = false
      this.unselectAllCards()
    }
  }

  checkSelectedCards() {
    if (this.isSelecting) {
      for (const card of this.store.cards) {
        if (card.isWithinCoordinates(this.selectableArea)) {
          card.isSelected = true
        } else {
          card.isSelected = false
        }
      }
    }
  }

  moveSelectedCards(dragInfo) {
    for (const card of this.store.cards) {
      if (card.isSelected) {
        card.x += dragInfo.deltaX / this.store.zoomFactor
        card.y += dragInfo.deltaY / this.store.zoomFactor

        card.hasMoved = card.hasMoved || (dragInfo.deltaX !== 0 || dragInfo.deltaY !== 0)
      }
    }
  }

  unselectAllCards() {
    for (const card of this.store.cards) {
      card.isSelected = false
    }
  }

  saveSelectedCards() {
    const attributes = []
    for (const card of this.store.cards) {
      attributes.push({
        id: card.id,
        x: card.x,
        y: card.y,
      })
      card.hasMoved = false
    }
    axios.patch(`${this.store.urls.scenario_url}.json`, {
      scenario: {
        cards_attributes: attributes,
      },
      authenticity_token: this.store.authenticity_token,
    })
    // TODO catch if failure
  }
}

export default SelectionStore
