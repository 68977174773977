export function init() {
  $(document).on("click", function(e) {
    if (
      $(e.target).data("menu-toggle") === "mobile-menu" ||
      $(e.target).parents("span").data("menu-toggle") === "mobile-menu"
    ) {
      $(this).toggleClass("active");
      $(".c-menu__list").toggleClass("open");
    } else {
      $("[data-menu-toggle]").removeClass("active");
      $(".c-menu__list").removeClass("open");
    }
  });
}

