import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import {
  getCoursesQuery,
  updateCourseMutation,
  updateCourseScenariosMutation,
} from '../../../apollo/query/courses'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'
import CourseScenariosList from '../CourseScenariosList'

const EditCourseModal = ({ id, appendTo, course }) => {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const scenariosListInOrderRef = useRef()

  const [updateCourse] = useMutation(updateCourseMutation, {
    onError: handleApolloError,
  })
  const [updateCourseScenarios] = useMutation(updateCourseScenariosMutation, {
    refetchQueries: [{ query: getCoursesQuery }],
    onError: handleApolloError,
    onCompleted: ({ updateCourseScenarios: uCS }) => {
      addSuccessAlert({ title: 'Course updated' })
    },
  })
  const submitHandler = () => {
    updateCourse({
      variables: {
        id: course.id,
        name: nameRef.current.value,
        description: descriptionRef.current.value,
      },
    })
    updateCourseScenarios({
      variables: {
        id: course.id,
        publishedScenarioIds: scenariosListInOrderRef.current.map((scenario) =>
          Number(scenario.id)
        ),
      },
    })
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText="Edit course"
      overflowY="visible"
      useDefaultButtons
      submitHandler={submitHandler}>
      <form className="mt-3" key={course.id}>
        <label>
          Course title <span className="text-assertive">*</span>
        </label>
        <input type="text" ref={nameRef} required defaultValue={course.name} />
        <label> Course description </label>
        <textarea
          rows="8"
          ref={descriptionRef}
          defaultValue={course.description}
        />
        <CourseScenariosList
          course={course}
          newList={scenariosListInOrderRef}
        />
        <button
          type="submit"
          disabled
          className="o-button__submit--hidden"
          aria-hidden="true"
        />
      </form>
    </Modal>
  )
}

export default EditCourseModal
