import React, { useEffect, useState } from 'react'
import {
  FaChevronLeft,
  FaChevronRight,
  FaHome,
  FaMinus,
  FaPlus,
  FaSearch,
} from 'react-icons/fa'
import {
  ControlButton as DefaultButton,
  Controls as DefaultControls,
  useReactFlow,
  useViewport,
} from 'reactflow'
import { setCentralNodeAndSelectIt } from '../helpers/nodeHelper'
import useSelectedNode from '../hooks/useSelectedNode'
import {
  getHomeNode,
  getNextNode,
  getPrevNode,
  goToHomeScene,
  goToNextScene,
  goToPrevScene,
  saveCanvasState,
  zoomIn,
  zoomOut,
  zoomToDefault,
} from '../helpers/controlsHelper'
import config from '../config/config'

let timer

const ControlsWrapper = ({ position, style = {}, children }) => (
  <DefaultControls
    position={position}
    showZoom={false}
    showInteractive={false}
    showFitView={false}
    style={style}>
    {children}
  </DefaultControls>
)

const ControlButton = ({ ...props }) => <DefaultButton {...props} />

const FlowControls = ({ showVideoEditor }) => {
  const { x, y, zoom } = useViewport()
  const [_currentZoom, _setCurrentZoom] = useState({
    video: 1,
    flow: zoom,
  })

  const updateCanvasPosition = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      saveCanvasState(reactFlow)
    }, config.zoomDuration + 100)
  }

  const currentZoom = showVideoEditor ? _currentZoom.video : _currentZoom.flow
  const setCurrentZoom = (zoom) => {
    _setCurrentZoom({
      video: showVideoEditor ? zoom : currentZoom.video,
      flow: showVideoEditor ? currentZoom.flow : zoom,
    })
    if (!showVideoEditor && x && y) {
      updateCanvasPosition()
    }
  }

  const reactFlow = useReactFlow()
  const selectedNode = useSelectedNode()

  const handleZoomOut = () =>
    setCurrentZoom(zoomOut(reactFlow, showVideoEditor))
  const handleZoomIn = () => setCurrentZoom(zoomIn(reactFlow, showVideoEditor))
  const handleZoomToDefault = () => {
    setCurrentZoom(zoomToDefault(reactFlow, showVideoEditor))
  }

  const handleGoToHomeScene = () => goToHomeScene(reactFlow)

  const handleSearch = () => {
    $('#flow-search-modal').foundation('open')
  }

  const handlePrevScene = () => {
    goToPrevScene(reactFlow)
    if (showVideoEditor) handleZoomToDefault()
  }

  const handleNextScene = () => {
    goToNextScene(reactFlow)
    if (showVideoEditor) handleZoomToDefault()
  }

  useEffect(() => {
    if (showVideoEditor) setCurrentZoom(1)
    else setCurrentZoom(reactFlow.getZoom())
  }, [showVideoEditor])

  useEffect(() => {
    if (!showVideoEditor) {
      setCurrentZoom(zoom)
    }
  }, [zoom, showVideoEditor])

  useEffect(() => {
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div
        id="flow-controls"
        className={`flex-container align-justify w-100 ${showVideoEditor ? 'react-flow__controls-video-editor' : ''}`}>
        <ControlsWrapper position="top-left">
          <ControlButton onClick={handleZoomOut} title="Zoom out">
            <FaMinus />
          </ControlButton>
          <ControlButton
            title="Default zoom"
            onClick={handleZoomToDefault}
            className="tracking-tighter"
            style={{
              pointerEvents: 'pointer',
              width: '52px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}>
            {(currentZoom * 100).toFixed(0)}%
          </ControlButton>
          <ControlButton title="Zoom in" onClick={handleZoomIn}>
            <FaPlus />
          </ControlButton>
        </ControlsWrapper>

        <ControlsWrapper position="top-right">
          <ControlButton
            title="Previous scene"
            onClick={handlePrevScene}
            disabled={!selectedNode}>
            <FaChevronLeft />
          </ControlButton>
          <ControlButton
            title="Next scene"
            onClick={handleNextScene}
            disabled={!selectedNode}>
            <FaChevronRight />
          </ControlButton>
        </ControlsWrapper>

        <ControlsWrapper position="top-right" style={{ marginRight: '111px' }}>
          <ControlButton title="Show start scene" onClick={handleGoToHomeScene}>
            <FaHome />
          </ControlButton>
          <ControlButton title="Search" onClick={handleSearch}>
            <FaSearch />
          </ControlButton>
        </ControlsWrapper>
      </div>
    </>
  )
}

export default FlowControls
