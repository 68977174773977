import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FiX } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa'
import { handleApolloError } from '../../../utils/errors'
import { addSuccessAlert, addErrorAlert } from '../../../utils/helpers/alerts'
import Modal from '../../UI/Modal'
import { updateDeviceMutation } from '../../../apollo/query/devices'
import RadioButton from '../../UI/Form/RadioButton'
import { getGroupsQuery } from '../../../apollo/query/groups'
import LoadingSpinner from '../../UI/LoadingSpinner'
import { sortOnKey } from '../../../utils/format'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'
import Checkbox from '../../UI/Form/Checkbox'

const EditSharedDeviceModal = ({
  id,
  appendTo,
  devices,
  clearCurrentDeviceId,
}) => {
  const [key, setKey] = useState(uuidv4())
  const [signInOption, setSignInOption] = useState()
  const [showChangeName, setShowChangeName] = useState(devices.length === 1)
  const [showGroupDropdown, setShowGroupDropdown] = useState(false)
  const [groups, setGroups] = useState([])
  const [deviceSettings, setDeviceSettings] = useState({
    disableDeleteDownload: false,
    signOutAfterSleep: false,
  })
  const nameRef = useRef()
  const restrictedGroups = ['REGISTERED_ANONYMOUS', 'REGISTERED_GROUP']

  const { data, loading } = useQuery(getGroupsQuery, {
    onError: handleApolloError,
  })

  useEffect(() => {
    if (devices.length !== 0) {
      setSignInOption(devices[0].registration)
      setGroups(devices[0].groups || [])
      setDeviceSettings({
        disableDeleteDownload: devices[0].disableDeleteDownload,
        signOutAfterSleep: !!devices[0].signOutAfterSleep,
      })
      setShowChangeName(devices.length === 1)
    }
  }, [devices])

  const [updateDevice] = useMutation(updateDeviceMutation, {
    onError: handleApolloError,
    onCompleted: ({ updateDevice: { device: d } }) => {
      addSuccessAlert({
        title: `'${d.name}' updated`,
      })
    },
  })

  useEffect(() => {
    setKey(uuidv4())
  }, [devices])

  const submitHandler = (e) => {
    if (restrictedGroups.includes(signInOption) && !groups.length) {
      addErrorAlert({
        title: 'No group selected',
        subtitle: 'Please select a group',
      })
      e.preventDefault()
      e.stopPropagation()
    } else {
      devices.forEach((device) => {
        let variables = {
          ...device,
          registration: signInOption,
          disableDeleteDownload: deviceSettings.disableDeleteDownload,
          signOutAfterSleep:
            signInOption === 'REGISTERED_GROUP'
              ? deviceSettings.signOutAfterSleep
              : false,
          groupIds:
            (restrictedGroups.includes(signInOption) &&
              groups.map((i) => i.id)) ||
            [],
        }
        if (devices.length === 1) {
          variables = { ...variables, name: nameRef.current.value }
        }
        updateDevice({
          variables,
        })
      })
    }
    clearCurrentDeviceId()
  }
  const getGroupsData = () => {
    const filtered = data.groups.reduce((a, group) => {
      if (!groups.find((g) => g.id === group.id)) {
        a.push({
          ...group,
          value: group.name,
          render: group.name,
        })
      }
      return a
    }, [])
    return sortOnKey(filtered, 'name', false)
  }
  const showGroupInfo = () => {
    return (
      <div className="cell small-12 mt-2">
        <label className="c-form__label--required">Select groups</label>
        {signInOption === 'REGISTERED_GROUP' && (
          <p>
            When multiple groups are selected, a specific group can be selected
            to only show a list of trainees from that group. Group selection is
            done in the shared device. Trainees can only access courses and
            scenarios from a specific group. Group sign-in mode works best with
            groups of 50 trainees or less per group.{' '}
            <a
              className="no-decoration text-stable-dark text-underline"
              href="https://help.warpvr.com/shared-devices/set-a-sign-in-mode">
              More info here.
            </a>
          </p>
        )}{' '}
        {signInOption === 'REGISTERED_ANONYMOUS' && (
          <p>
            Select one or more groups to give the anonymous trainee access to
            courses and scenarios.{' '}
            <a
              className="no-decoration text-stable-dark text-underline"
              href="https://help.warpvr.com/shared-devices/set-a-sign-in-mode">
              More info here.
            </a>{' '}
          </p>
        )}
        {loading ? (
          <LoadingSpinner dotsOnly />
        ) : (
          <>
            {groups.length ? (
              <ul className="o-modal__video--list mb-1">
                {sortOnKey([...groups], 'name', false).map((g) => (
                  <li key={g.id} style={{ padding: '0 20px' }}>
                    <div
                      style={{
                        flexGrow: 1,
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}>
                      <span style={{ flexGrow: 1 }}>{g.name}</span>
                      <span
                        className="cursor-pointer"
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={() =>
                          setGroups([
                            ...groups.filter((group) => group.id !== g.id),
                          ])
                        }>
                        <FiX style={{ marginLeft: '10px' }} size="16" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              ''
            )}
            <button
              className="button hollow secondary text-bold"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowGroupDropdown(true)
              }}>
              <FaPlus className="mr-1" />
              Select group
            </button>
            <SearchableDropdown
              placeholder="Please select a group"
              notFoundTitle="No groups"
              notFoundText={<small>You have linked all groups</small>}
              hideHandler={() => setShowGroupDropdown(false)}
              clickHandler={(group) => {
                setGroups([...groups, group])
              }}
              show={showGroupDropdown}
              data={getGroupsData()}
              loading={loading}
            />
          </>
        )}
      </div>
    )
  }

  const options = [
    {
      value: 'REGISTERED_TRAINEE',
      text: ' • Every trainee signs in with their personal 6-digit code',
      title: 'Trainee',
    },
    {
      value: 'REGISTERED_GROUP',
      text: ' • A trainee selects their name from a list to sign in',
      title: 'Group',
    },
    {
      value: 'REGISTERED_ANONYMOUS',
      text: ' • A device is directly signed in using an anonymous trainee',
      title: 'Anonymous',
    },
  ]

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      overflowY="visible"
      useDefaultButtons
      submitHandler={submitHandler}
      cancelHandler={clearCurrentDeviceId}
      submitDisabled={restrictedGroups.includes(signInOption) && !groups.length}
      headerText="Edit device"
      subtitle="Edit device settings for a shared device"
      footerText="Confirming device changes will sign-out the current user">
      <form
        className="mt3"
        key={key}
        onSubmit={(e) => {
          e.preventDefault()
          submitHandler()
          $(`#${id}`).foundation('close')
        }}>
        {showChangeName && (
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <label htmlFor="change-device-name">Change device name</label>
              <input
                ref={nameRef}
                defaultValue={devices[0]?.name}
                type="text"
                name="change-device-name"
                id="change-device-name"
              />
            </div>
          </div>
        )}
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <label>Sign-in mode</label>
            <fieldset className="small-12">
              {options.map(({ value, text, title }, index) => (
                <RadioButton
                  key={index}
                  id={title.toLowerCase()}
                  checked={signInOption === value}
                  clickHandler={() => setSignInOption(value)}
                  label={
                    <div
                      style={{ display: 'inline-flex', flexGrow: 1 }}
                      className="mb-0">
                      <section className="mr-1">{title}</section>
                      <section className="text-stable-dark">{text}</section>
                    </div>
                  }
                />
              ))}
            </fieldset>
            <div className="c-form__label--lined ">
              <label>
                {options.find((option) => option.value === signInOption)?.title}{' '}
                sign in mode
              </label>
            </div>
            {restrictedGroups.includes(signInOption) && showGroupInfo()}
          </div>
        </div>
        <div className="grid-x grid-margin-x">
          <div className=" cell small-12">
            <label>Device settings</label>
            <Checkbox
              value="Hide 'Delete download' button on the scenario screen"
              checked={deviceSettings.disableDeleteDownload}
              clickHandler={() =>
                setDeviceSettings({
                  ...deviceSettings,
                  disableDeleteDownload: !deviceSettings.disableDeleteDownload,
                })
              }
              disabled={false}
            />
            {signInOption === 'REGISTERED_GROUP' && (
              <Checkbox
                value="Sign out trainee when device goes to sleep (only for VR headsets)"
                checked={deviceSettings.signOutAfterSleep}
                clickHandler={() =>
                  setDeviceSettings({
                    ...deviceSettings,
                    signOutAfterSleep: !deviceSettings.signOutAfterSleep,
                  })
                }
                disabled={false}
              />
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditSharedDeviceModal
