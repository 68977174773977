import React from 'react'
import { FaLink } from 'react-icons/fa'
import MediaSettingsTab from '../../../EditorV2/Video/Tabs/MediaSettingsTab'

const MediaTab = ({ selectedNode }) => {
  if (!selectedNode.data.hasVideo)
    return (
      <div className="mt-5 pt-3 flex-container flex-dir-column align-middle">
        <h2 className="mb-2 text-bold">There is no media file linked yet</h2>
        <button
          className="o-button o-button--light text-normal"
          data-open="media-modal">
          <FaLink className="mr-0-5" />
          <span className="text-bold">Link media file</span>
        </button>
      </div>
    )
  return <MediaSettingsTab selectedNode={selectedNode} />
}

export default MediaTab
