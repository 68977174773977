import React, { useContext } from 'react'
import { FaPause, FaPlay } from 'react-icons/fa'
import { MediumContext } from '../../Media/Modal/MediumContext'
import VolumeSlider from '../../Media/Modal/partials/Controls/VolumeSlider'
import TimeIndicator from '../../Media/Modal/partials/TimeIndicator'
import EditorTimeSlider from './EditorTimeSlider'

const EditorControlsRow = () => {
  const {
    medium: [medium],
    controls: [controls, setControls],
    currentVideoRef,
  } = useContext(MediumContext)

  if (!medium)
    return (
      <div className="mb-1 mt-1">
        <div
          className=" grid-x c-video__editor--slider-row border-none text-center"
          style={{ borderRadius: '5px', backgroundColor: '#ddd' }}>
          <div className="cell small-12">
            No video attached
            <button
              className="button primary mb-0 text-normal ml-2 "
              style={{ fontWeight: 500 }}
              onClick={() => {
                const url = window.location.href
                const sceneUrl = url.substring(0, url.lastIndexOf('/'))
                window.Turbolinks.visit(sceneUrl)
              }}>
              Go to this scene to add a video
            </button>
          </div>
        </div>
        <div className="mb-1 mt1 grid-x c-video__editor--slider-row border-none"></div>
        <div className="mb-1 mt1 grid-x c-video__editor--slider-row border-none"></div>
      </div>
    )
  const pauseHandler = () => {
    if (!currentVideoRef.current.paused) {
      currentVideoRef.current.pause()
      setControls({ ...controls, isPlaying: false })
    } else {
      currentVideoRef.current.play()
      setControls({ ...controls, isPlaying: true })
    }
  }
  return (
    <div data-testid="editor-controls-row" key={medium.id}>
      <div className="mb-1 mt-1 grid-x c-video__editor--slider-row border-none">
        <div className="cell small-3 large-2 grid-x">
          <button
            data-testid="play-button"
            style={{
              minWidth: '46px',
              maxWidth: '80px',
            }}
            className="button cell auto secondary hollow mb-0 mr-1"
            onClick={pauseHandler}>
            {controls.isPlaying ? (
              <FaPause className="text-dark" data-testid="is-playing" />
            ) : (
              <FaPlay className="text-dark" data-testid="is-paused" />
            )}
          </button>
          <VolumeSlider />
        </div>
        <TimeIndicator editorControls />
        <EditorTimeSlider />
      </div>
    </div>
  )
}

export default EditorControlsRow
