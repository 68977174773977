var searchInput, searchResults;

function makeInactive() {
  searchResults.empty();
  searchInput.val('');
  searchInput.blur();
  searchInput.removeClass('c-segments-nav__input--active');
}

function scrollToScene(scene) {
  window.diagramStore.scrollTo(scene, true);
}

function addResults(results, selectedIndex) {
  var html = $();
  results = results.sort(function(a, b){
    return a.priority > b.priority;
  });

  results.map(function(result, index) {
    var selected = selectedIndex === index ? 'o-dropdown__item--hover' : '';
    var priority = result.priority === null ? '' : result.priority + '. ';

    html = html.add(
      '<li class="o-dropdown__item ' +
        selected +
        '"> <span class="o-dropdown__link"  data-scene-id="' +
        result.id +
        '">' +
        priority +
        result.name +
        "</span> </li>"
    );
  });

  searchResults.html(html);
}

export function init() {
  var selectedIndex = 0;
  var results = [];

  searchInput = $('[data-keyword-search]');
  searchResults = $('[data-search-results]');

  searchInput.bind('keydown', function(e) {
    if (e.keyCode === '38' || e.keyCode === '40') {
      e.preventDefault();
    }
  });

  searchInput.on('keyup', function(e) {
    if (e.key === 'ArrowUp') {
      if (selectedIndex > 0) {
        selectedIndex--;
        addResults(results, selectedIndex);
      }
    } else if (e.key === 'ArrowDown') {
      if (selectedIndex < results.length - 1) {
        selectedIndex++;
        addResults(results, selectedIndex);
      }
    } else if (e.key === 'Escape') {
      results = [];
      selectedIndex = 0;
      addResults(results, selectedIndex);
      makeInactive();
    } else if (e.key === 'Enter') {
      scrollToScene(results[selectedIndex]);
      makeInactive();
    } else {
      results = window.diagramStore.search($(this).val());
      addResults(results, selectedIndex);
    }
    if ($(this).val().length) {
      $(this).addClass('c-segments-nav__input--active');
    } else {
      $(this).removeClass('c-segments-nav__input--active');
      searchResults.empty();
    }
  });

  if (searchInput.length > 0) {
    searchResults.on('click', '.o-dropdown__item', function(e) {
      e.preventDefault();
      selectedIndex = $(this).index();
      addResults(results, selectedIndex);
      scrollToScene(results[selectedIndex]);
      makeInactive();
    });
  }

  $(document).click(function(e) {
    if ($('.c-segments-nav__input--active').length > 0) {
      var target = e.target;
      if (!$(target).is('[data-keyword-search], [data-search-results]')) {
        makeInactive();
      }
    }
  });
}

