import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { FaSitemap } from 'react-icons/fa'
import { getWorkspacesQuery } from '../../apollo/query/workspaces'
import Table from '../../components/Table'
import LoadingSpinner from '../../components/UI/LoadingSpinner'
import FilterEmptyState from '../../components/UI/Menu/FilterEmptyState'
import TableSearchBar from '../../components/UI/Table/TableSearchBar'
import TableHeader from '../../components/UI/TableHeader'
import withApollo from '../../hooks/withApollo'
import { dateFormat, sortOnKey } from '../../utils/format'
import Delay from '../../utils/helpers/Delay'
import TableAddButton from '../../components/UI/Table/TableAddButton'
import AddEditWorkspaceModal from '../../components/Workspaces/AddEditWorkspaceModal'
import { sortHandler } from '../../components/tableFunctions'
import WorkspaceOptionDropdown from '../../components/Workspaces/WorkspaceOptionDropdown'
import ManagedByClientPage from '../../components/Settings/ManagedByPage'
import { getCurrentClientSettingsQuery } from '../../apollo/query/settings'
import { switchClientMutation } from '../../apollo/query/clients'
import { handleApolloError } from '../../utils/errors'
import DataTooltip from '../../components/UI/DataTooltip'
import { default as S } from '../../utils/lang/en.json'
import useCurrentUser from '../../hooks/useCurrentUser'

const Workspaces = () => {
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [selectedWorkspace, setSelectedWorkspace] = useState()
  const [, , currentRole] = useCurrentUser()

  const { loading, data, error } = useQuery(getWorkspacesQuery)
  const [switchClient] = useMutation(switchClientMutation, {
    onError: handleApolloError,
    onCompleted: async () => {
      window.location.reload()
    },
  })

  const {
    loading: clientLoading,
    data: clientData,
    error: clientError,
    refetch,
  } = useQuery(getCurrentClientSettingsQuery)

  // Data & render states
  const numberOfItems = 100
  const [limit, setLimit] = useState(2 * numberOfItems)

  // Filter workspaces
  const [searchQuery, setSearchQuery] = useState('')
  const [preferences, setPreferences] = useState({
    sortOn: {
      isAscending: true,
      value: 'createdAt',
    },
  })

  if (clientError || error) throw new Error(error.message)

  if (clientLoading || loading || !currentRole) {
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )
  }

  const { currentClient } = clientData

  if (
    !currentClient.plan ||
    !(currentRole == 'OWNER' || currentRole == 'MANAGER')
  )
    return (
      <ManagedByClientPage
        workspaceManagedByClientName={
          currentClient.workspaceManagedByClientName
        }
      />
    )

  const tableHeaders = ['name', 'type', 'publishedScenariosCount', 'createdAt']

  const applyFilterToWorkspaces = (workspaces) => {
    const query = searchQuery.toLowerCase()
    return workspaces.filter((w) => w.name.toLowerCase().includes(query))
  }

  const handleDropdownClick = (action, workspace) => {
    setSelectedWorkspace(workspace)

    const actions = {
      switch: () => switchClient({ variables: { id: workspace.id } }),
      edit: () => $('#add-workspace-modal').foundation('open'),
    }

    actions[action]()
  }

  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    let workspaces = [...data.workspaces, currentClient]

    const filtered = applyFilterToWorkspaces(workspaces)

    workspaces = filtered.slice(0, limit)
    if (!workspaces.length && !showEmptyState) setShowEmptyState(true)
    if (workspaces.length && showEmptyState) setShowEmptyState(false)

    workspaces = filtered.map((workspace) => ({
      name: {
        value: workspace.name,
        render: (
          <div className="flex-container align-middle align-justify pr-2">
            <div>
              {workspace.name}
              {workspace.workspaceKind === 'BILLING' && (
                <label className="o-label--custom o-label--stable-lighter text-small text-bold text-stable-dark ml-1">
                  BILLING WORKSPACE
                </label>
              )}
            </div>
            <WorkspaceOptionDropdown
              id={workspace.id}
              workspace={workspace}
              onClick={(action) => handleDropdownClick(action, workspace)}
              deleteTemporaryDisabled
            />
          </div>
        ),
      },
      type: workspace.workspaceKind
        ? S.dataTypes.transform.workspace_kind[
            workspace.workspaceKind.toString()
          ]
        : '-',
      publishedScenariosCount: workspace.publishedScenariosCount
        ? `${workspace.publishedScenariosCount}
            scenario${workspace.publishedScenariosCount > 1 ? 's' : ''}`
        : '-',
      createdAt: {
        value: workspace.createdAt,
        render: dateFormat(workspace.createdAt),
      },
    }))

    return sortOnKey(workspaces, value, isAscending)
  }

  const clearFilterHandler = () => {
    setSearchQuery('')
    setShowEmptyState(false)
  }

  const leftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setSearchQuery={setSearchQuery}
        setShowEmptyState={setShowEmptyState}
      />
    </>
  )

  const showWorkspaceAddButton = () =>
    !currentClient.plan.legacy && currentClient.workspaceKind === 'BILLING'

  const rightBar = () => {
    if (
      !currentClient.canAddWorkspaces &&
      !currentClient.features.includes('STORE_SELLER')
    )
      return (
        <DataTooltip
          position="bottom"
          title="You do not have any slots left to add a new workspace. Go to subscription page to increase the number of workspace slots.">
          <TableAddButton
            dataOpen="add-workspace-modal"
            onClick={() => setSelectedWorkspace(null)}
            disabled
          />
        </DataTooltip>
      )

    return (
      <TableAddButton
        dataOpen="add-workspace-modal"
        title="New workspace"
        onClick={() => setSelectedWorkspace(null)}
      />
    )
  }

  const pageContent = () => {
    if (showEmptyState) {
      return (
        <FilterEmptyState
          type="workspaces"
          icon={<FaSitemap />}
          clickHandler={clearFilterHandler}
        />
      )
    }
    return (
      <>
        <Table
          data={sortedData()}
          headers={tableHeaders}
          sortOn={preferences.sortOn}
          sortHandler={(value) => {
            sortHandler(value, preferences, setPreferences)
          }}
          type="workspaces"
          placeholderRows={35}
          loadMore={applyFilterToWorkspaces(data.workspaces).length > limit}
          loadMoreHandler={() => setLimit(limit + numberOfItems)}
          tableScroll={false}
        />
        <div>
          <p className="small-12 medium-6 columns mt-1 pl-0">
            Add new workspaces for your organization for a convenient way to
            separate scenarios, trainees and data. First workspace is included
            in your plan.{' '}
            <a
              href="https://help.warpvr.com/organization-settings/qtJLeZHXC5TPNkXEcohX5m/upgrading-and-downgrading/wAPWHdKpKvEM7JQ2nKJHpN"
              target="_blank"
              className="text-underline text-stable-dark">
              More info about upgrading and downgrading.
            </a>
          </p>
        </div>
      </>
    )
  }

  return (
    <>
      <div id="workspaces">
        <div className="grid-container fluid">
          <TableHeader
            title="Workspace"
            length={[...sortedData()].length}
            leftBar={leftBar()}
            rightBar={showWorkspaceAddButton() && rightBar()}
          />
        </div>
        <div className="o-table--page-container grid-container fluid">
          {pageContent()}
        </div>
      </div>

      <AddEditWorkspaceModal
        id="add-workspace-modal"
        appendTo="#workspaces"
        defaultValues={selectedWorkspace}
        onSubmit={() => {
          refetch()
          setTimeout(() => {
            setSelectedWorkspace()
          }, 500)
        }}
        onClose={() => {
          setTimeout(() => {
            setSelectedWorkspace()
          }, 500)
        }}
      />
    </>
  )
}

export default withApollo(Workspaces)
