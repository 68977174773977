import React, { useContext } from 'react'

import logo from '../../../../../images/default/logo.png'
import LanguageContext from '../../../utils/context/LanguageContext'
import Social from './Social'

const Footer = () => {
  const { translations: t } = useContext(LanguageContext)

  return (
    <footer className="grid-container fluid c-launcher__footer">
      <div className="c-launcher__footer--inner grid-x">
        <span className="cell grid-y medium-6">
          <span className="cell grid-x  c-launcher__logo--container">
            <img src={logo} className="c-launcher__logo--image" />
            <h1 className="c-launcher__logo--text">Warp VR</h1>
          </span>
          <span className="cell c-launcher__footer--subtext mb-2">
            Story-based immersive learning
          </span>
          <Social />
          <div className="c-launcher__footer--copyright">
            © Copyright Warp VR
          </div>
        </span>
        <nav className="cell medium-6 text-right grid-y">
          <a
            className="c-launcher__link mb-2"
            href="https://help.warpvr.com/"
            target="_blank">
            Help Center
          </a>
          <a
            className="c-launcher__link"
            href="https://warpvr.com"
            target="_blank">
            {t.goTo} warpvr.com
          </a>
        </nav>
      </div>
    </footer>
  )
}
export default Footer
