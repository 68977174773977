import React, { useState, useRef } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import { v4 as uuidv4 } from 'uuid'
import { FaEuroSign, FaQuestionCircle } from 'react-icons/fa'
import Modal from '../../../UI/Modal'
import RadioButton from '../../../UI/Form/RadioButton'
import DraggableList from '../../../UI/Form/DraggableList'
import { getCoursesQuery } from '../../../../apollo/query/courses'
import { sortOnKey } from '../../../../utils/format'
import { addProductMutation } from '../../../../apollo/query/products'
import DataTooltip from '../../../UI/DataTooltip'
import scenarioBg from '../../../../../../images/default/scenario.png'
import {
  addSuccessAlert,
  addErrorAlert,
} from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'
import { getPublishedScenariosQuery } from '../../../../apollo/query/scenarios'

const AddProductModal = ({ id, appendTo, callback }) => {
  const LICENSE_DURATION_OPTIONS = [1, 2, 3, 6, 12]
  const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'
  const INITIAL_STATE = {
    name: '',
    description: '',
    kind: 'ONE_TIME',
    price: '',
    licenseDuration: 1,
    licenseType: 'USER',
    state: 'INCOMPLETE',
  }
  const newCoursesListRef = useRef([])
  const newScenariosListRef = useRef([])
  const [key, setKey] = useState(uuidv4())
  const [values, setValues] = useState(INITIAL_STATE)
  const [previewImage, setPreviewImage] = useState(scenarioBg)
  const [image, setImage] = useState(null)
  const [coursesList, setCoursesList] = useState([])
  const [selectedCoursesList, setSelectedCoursesList] = useState([])
  const [scenariosList, setScenariosList] = useState([])
  const [selectedScenariosList, setSelectedScenariosList] = useState([])

  const [getCourses, { loading }] = useLazyQuery(getCoursesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const list = data.courses.reduce((a, course) => {
        a.push({
          render: course.name,
          value: course.name,
          id: course.id,
        })
        return a
      }, [])
      setCoursesList(sortOnKey(list, 'value', false))
    },
  })

  const [getScenarios, { scenariosLoading }] = useLazyQuery(getPublishedScenariosQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const list = data.publishedScenarios.reduce((a, scenario) => {
        a.push({
          render: scenario.name,
          value: scenario.name,
          id: scenario.id,
        })
        return a
      }, [])
      setScenariosList(sortOnKey(list, 'value', false))
    },
  })

  const [addProduct] = useMutation(addProductMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({ title: 'Product created' })
      callback()
    },
  })

  const resetState = () => {
    setTimeout(() => {
      setValues(INITIAL_STATE)
      setImage(null)
      setPreviewImage(scenarioBg)
      setKey(uuidv4())
    }, 300)
  }

  const checkState = () => {
    if (
      values.name &&
      values.price &&
      values.description &&
      (selectedCoursesList.length || selectedScenariosList.length) &&
      image
    ) {
      return 'PREPARED'
    }
    return 'INCOMPLETE'
  }

  const submit = (e) => {
    if (!values.name) {
      document
        .querySelector('#add-product-title')
        .classList.add('input--required')
    }
    if (!values.price) {
      document.querySelector('#actual-price').classList.add('input--required')
    }
    if (!values.name || !values.price) {
      e.preventDefault()
      e.stopPropagation()
      return null
    }
    const courseIds = newCoursesListRef.current.map((i) => Number(i.id))
    const scenarioIds = newScenariosListRef.current.map((i) => Number(i.id))
    const newState = checkState()

    if (!image) {
      addProduct({
        variables: {
          ...values,
          courseIds,
          scenarioIds,
          state: newState || values.state,
          blobId: '',
        },
      })
    } else {
      const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
      upload.create((error, blob) => {
        if (error) throw new Error(error.message)
        addProduct({
          variables: {
            ...values,
            courseIds,
            scenarioIds,
            state: newState || values.state,
            blobId: blob?.signed_id || '',
          },
        })
      })
    }

    return resetState()
  }
  const productFields = (
    <>
      <div className="cell small-12">
        <label htmlFor="add-product-title" className="c-form__label--required">
          Product title
        </label>
        <input
          type="text"
          name="product-title"
          id="add-product-title"
          required
          autoComplete="off"
          defaultValue={values.name}
          onChange={({ target }) => {
            setValues({ ...values, name: target.value })
          }}
        />
      </div>
      <div className="cell small-12">
        <label htmlFor="add-product-description">Product description</label>
        <textarea
          rows="6"
          defaultValue={values.description}
          onChange={({ target }) =>
            setValues({ ...values, description: target.value })
          }
        />
      </div>
      <div className="cell small-12">
        <label htmlFor="add-product-courses">Add courses</label>
        <DraggableList
          list={selectedCoursesList}
          setListHandler={setSelectedCoursesList}
          loading={loading}
          newList={newCoursesListRef}
          getDropdownItemsHandler={() => getCourses()}
          dropdownItems={coursesList}
          buttonText="Select courses"
        />
      </div>
      <div className="cell small-12 mt-2">
        <label htmlFor="add-product-courses">Add scenarios</label>
        <DraggableList
          list={selectedScenariosList}
          setListHandler={setSelectedScenariosList}
          loading={scenariosLoading}
          newList={newScenariosListRef}
          getDropdownItemsHandler={() => getScenarios()}
          dropdownItems={scenariosList}
          buttonText="Select scenario"
        />
      </div>
    </>
  )
  const licenseFields = (
    <>
      <div className="cell small-12 mb-3">
        <label htmlFor="license-pricing">Set pricing plan</label>
        <fieldset className="small-12">
          <RadioButton
            id="radio-purchase"
            label={
              <>
                One-time purchase
                <DataTooltip
                  position="right"
                  title="Clients get access to this product until the end of the license duration, by paying a singular amount.">
                  <FaQuestionCircle className="text-stable-dark ml-3" />
                </DataTooltip>
              </>
            }
            checked={values.kind === 'ONE_TIME'}
            clickHandler={() => setValues({ ...values, kind: 'ONE_TIME' })}
            name="select-plan"
          />
          <RadioButton
            id="radio-subscription"
            label={
              <>
                Subscription
                <DataTooltip
                  position="right"
                  title="Clients get access to this product as long as they continue to pay.">
                  <FaQuestionCircle className="text-stable-dark ml-3" />
                </DataTooltip>
              </>
            }
            checked={values.kind === 'SUBSCRIPTION'}
            clickHandler={() => setValues({ ...values, kind: 'SUBSCRIPTION' })}
            name="select-plan"
          />
        </fieldset>
      </div>
      <div className="cell mt-1 small-12">
        <label htmlFor="license-type">Set license type</label>
        <fieldset className="small-12 mb-3">
          <RadioButton
            id="radio-trainee"
            label={
              <>
                Trainee license
                <DataTooltip
                  position="right"
                  title="Each trainee needs a license, but can play on multiple devices.">
                  <FaQuestionCircle className="text-stable-dark ml-3" />
                </DataTooltip>
              </>
            }
            checked={values.licenseType === 'USER'}
            name="license"
            clickHandler={() => setValues({ ...values, licenseType: 'USER' })}
          />
          <RadioButton
            id="radio-device"
            label={
              <>
                Device license
                <DataTooltip
                  position="right"
                  title="Each device needs a license, but multiple trainees can play on one device.">
                  <FaQuestionCircle className="text-stable-dark ml-3" />
                </DataTooltip>
              </>
            }
            checked={values.licenseType === 'DEVICE'}
            name="license"
            clickHandler={() => setValues({ ...values, licenseType: 'DEVICE' })}
          />
        </fieldset>
        <div className="cell mt-1 small-12 grid-y">
          <label htmlFor="actual-price" className="c-form__label--required">
            License price
          </label>
          <div className="cell grid-x">
            <span className="cell mr-1 shrink mb-0 hollow button secondary">
              <FaEuroSign />
            </span>
            <input
              className="cell auto"
              value={values.price}
              onChange={({ target }) =>
                setValues({
                  ...values,
                  price: Number(target.value.replace(/[^\d]+/g, '')),
                })
              }
              placeholder="price in EUR"
              required
              type="text"
              name="price"
              id="actual-price"
            />
          </div>
        </div>
        <div className="cell mt-1 small-12">
          <label htmlFor="duration">License duration</label>
          <select
            name="duration"
            defaultValue={LICENSE_DURATION_OPTIONS.indexOf(
              values.licenseDuration
            )}
            onChange={({ target }) =>
              setValues({ ...values, licenseDuration: Number(target.value) })
            }
            id="license-duration">
            {LICENSE_DURATION_OPTIONS.map((v, i) => (
              <option value={v} key={i}>{`${v} ${
                v === 1 ? 'month' : 'months'
              }`}</option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
  return (
    <Modal
      overflowY="visible"
      id={id}
      width={902}
      appendTo={appendTo}
      useDefaultButtons
      closeOnEscape={false}
      headerText="New product"
      submitHandler={submit}
      cancelHandler={resetState}
      onOpen={() => {
        setSelectedCoursesList([])
        setSelectedScenariosList([])
      }}
      subtitle={<span>Add a product to sell through the Warp Store. </span>}>
      <form key={key} className="mt-0">
        <div className="grid-x">
          <div className="cell auto pt-3 pr-3">
            <label>Cover image</label>
            <div
              className="cell o-modal--content o-modal__scenario--image with-ratio-4-3 border-none"
              style={{
                backgroundImage: `url("${previewImage}")`,
                borderRadius: '5px',
              }}>
              <label
                htmlFor="image-upload-add-product"
                className={`button dark ${image && 'shadow dark-transparent'}`}>
                <input
                  hidden
                  type="file"
                  id="image-upload-add-product"
                  accept="image/png, image/jpeg"
                  onChange={async (event) => {
                    const file = event.target.files[0]
                    if (
                      file.type === 'image/png' ||
                      file.type === 'image/jpeg'
                    ) {
                      setPreviewImage(URL.createObjectURL(file))
                      setImage(file)
                    } else {
                      addErrorAlert({
                        title: 'Please upload a .PNG or .JPG image',
                        subtitle: 'other filetypes are not supported.',
                        timeout: false,
                        dismissable: true,
                      })
                    }
                  }}
                />
                {`${image ? 'Change' : 'Select'} image`}
              </label>
            </div>

            <div className="cell pt-3 ">{productFields}</div>
          </div>
          <div
            className="cell pt-3 pl-2"
            style={{ borderLeft: '1px solid #ddd', width: '300px' }}>
            {licenseFields}
          </div>
        </div>
      </form>
    </Modal>
  )
}
export default AddProductModal
