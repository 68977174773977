import React, { useEffect, useState } from 'react'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import Modal from '../../../UI/Modal'
import {
  ELEMENT_TYPES,
  GROUP_PARENT_ELEMENT_TYPES,
} from '../../helpers/elementHelper'
import useSelectedNode from '../../hooks/useSelectedNode'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'

const DeleteElementModal = ({ appendTo }) => {
  const selectedNode = useSelectedNode()

  const [element, setElement] = useState()
  const { removeElement } = useAddUpdateElement()
  const kind = element
    ? ELEMENT_TYPES.find(
        (el) =>
          el.type ===
          (element.groupKind &&
          GROUP_PARENT_ELEMENT_TYPES.includes(element.kind)
            ? element.groupKind
            : element.kind)
      ).title
    : ''

  const handleSubmit = () => {
    removeElement(element, selectedNode).then(() => {
      addSuccessAlert({
        icon: 'success',
        title: `${kind} successfully deleted`,
      })
    })
  }

  return (
    <Modal
      id="delete-element-modal"
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this ${kind?.toLowerCase()}?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      submitHandler={handleSubmit}
      onOpen={(e) => {
        setElement($(e.target).data().element)
      }}
    />
  )
}
export default DeleteElementModal
