export function init() {
  var target = $('.o-select[data-toggle]').attr('data-toggle');
  var link = $('#' + target + '-selected');
  var input = $('#' + target + '-input');

  $('#' + target + ' a').on("click", function(e) {
    e.preventDefault();

    var value = $(this).attr('data-option-value');
    var label = $(this).html();

    // update form values
    link.html(label);
    input.val(value);

    // set active dropdown item
    $(this).parents('ul.o-dropdown').children('li').removeClass('o-dropdown__item--active');
    $(this).parent('li').addClass('o-dropdown__item--active');

    // close dropdown
    $('#' + target).foundation('close');
  });

  // toggle block on radio button change
  $('input[type=radio][name=scenario-access]').on('change', function(e) {
    var block = $('#change-scenario-access');
    if ($(this).val() === 'change') {
      block.show();
    } else {
      block.hide();
    }
  });
}
