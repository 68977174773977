import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import { FaTimes } from 'react-icons/fa'
import useFoundation from '../../../hooks/useFoundation'
import { addScenarioMutation } from '../../../apollo/query/scenarios'
import scenarioBg from '../../../../../images/default/scenario.png'
import { handleApolloError } from '../../../utils/errors'
import { validateImageExtension } from '../../../utils/validation/validate'
import { addErrorAlert } from '../../../utils/helpers/alerts'
import RadioButton from '../../UI/Form/RadioButton'

const AddScenarioModal = ({ id, hybridNewFlow, appendTo }) => {
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState(null)
  const [previewImage, setPreviewImage] = useState(scenarioBg)
  const [image, setImage] = useState(null)
  const [legacyFlow, setLegacyFlow] = useState(!hybridNewFlow)
  const [addScenario] = useMutation(addScenarioMutation, {
    onError: handleApolloError,
    onCompleted: (data) => {
      window.location.assign(`/scenarios/${data.addScenario.scenario.id}`)
    },
  })

  const DIRECT_UPLOAD_URL = '/rails/active_storage/direct_uploads'

  const handleCreateScenario = () => {
    if (!title) return null
    if (!image) {
      addScenario({
        variables: {
          name: title,
          description: description || '',
          blobId: '',
          legacyFlow: legacyFlow,
        },
      })
      return $('#addScenarioModal').foundation('close')
    }
    const upload = new DirectUpload(image, DIRECT_UPLOAD_URL)
    upload.create((error, blob) => {
      if (error) throw new Error(error.message)
      addScenario({
        variables: {
          name: title,
          description: description || '',
          blobId: blob?.signed_id || '',
          legacyFlow: legacyFlow,
        },
      })
    })
    return $('#addScenarioModal').foundation('close')
  }
  const ref = useFoundation()

  const flowFields = () => {
    return (
      <form className="cell o-modal--content border-none text-stable-dark mt-0">
        <label>Use legacy or new flow?</label>
        <div>
          <RadioButton
            id="radio-legacyflow"
            label="Legacy flow"
            checked={legacyFlow}
            clickHandler={() => {
              setLegacyFlow(true)
            }}
            name="select-flow"
            labelClassName="ml-0"
          />
        </div>
        <div>
          <RadioButton
            id="radio-newflow"
            label="New flow"
            checked={!legacyFlow}
            clickHandler={() => {
              setLegacyFlow(false)
            }}
            name="select-flow"
            labelClassName="ml-0"
          />
        </div>
        {!legacyFlow && (
          <>
            <p className="text-dark mt-2 mb-2">
              The new flow makes creating highly engaging immersive scenarios
              even easier, featuring simplified navigation, the ability to
              combine elements, and much more.
              {/* <br />
          <a
            href="https://youtube.com"
            target="blank"
            rel="noreferrer"
            className="text-underline text-dark">
            Watch this product demo to discover more.
          </a> */}
            </p>

            <p className="border-assertive p-2 text-dark border-radius">
              Scenarios created with the new Flow cannot be played on a Pico G2
              4K or Meta Quest 1 VR headset. This action is irreversible.
            </p>
          </>
        )}
      </form>
    )
  }

  return (
    <div
      ref={ref}
      className="reveal o-modal--scenario"
      data-animation-in="fade-in"
      data-animation-out="fade-out"
      data-close-on-click={false}
      id={id}
      data-append-to={appendTo}
      data-reveal>
      <div className="o-modal--scenario" key="add-scenario">
        <div className="grid-x">
          <div className="cell grid-x small-12">
            <div className="o-modal--header">
              <h2 className="text-bold">New scenario</h2>
              <span className="o-modal--close-button" data-close={id}>
                <FaTimes />
              </span>
            </div>
          </div>
          <div
            className="cell o-modal--content o-modal__scenario--image border-none"
            style={{ backgroundImage: `url("${previewImage}")` }}>
            <label
              htmlFor="image-upload-add-scenario"
              className={`button dark ${image && 'shadow dark-transparent'}`}>
              {`${image ? 'Change' : 'Select'} cover image`}
              <input
                hidden
                type="file"
                id="image-upload-add-scenario"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  const file = event.target.files[0]
                  if (validateImageExtension(file)) {
                    setPreviewImage(URL.createObjectURL(file))
                    setImage(file)
                  } else {
                    addErrorAlert({
                      title: `Invalid image file type: ${file.type}`,
                      subtitle: 'Please upload a png or jpeg image',
                      timeout: 6000,
                    })
                  }
                }}></input>
            </label>
          </div>
          <form className="cell o-modal--content border-none text-stable-dark">
            <label>
              Scenario title
              <span className="text-assertive">*</span>
            </label>
            <input type="text" onChange={(e) => setTitle(e.target.value)} />
            <label>Scenario description</label>
            <textarea
              className="mb-2"
              rows="6"
              onChange={(e) => setDescription(e.target.value)}
            />
          </form>
          {hybridNewFlow && flowFields()}
          <div className="cell o-modal--footer">
            <button
              data-close="addScenarioModal"
              className="button hollow secondary wide">
              Cancel
            </button>
            <button
              className="button primary wide"
              onClick={() => {
                handleCreateScenario()
              }}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddScenarioModal
