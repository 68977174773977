import { useLazyQuery } from '@apollo/client'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaGripVertical, FaPlus } from 'react-icons/fa'
import { v4 as uuidV4 } from 'uuid'
import { FiX } from 'react-icons/fi'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { getPublishedScenariosQuery } from '../../apollo/query/scenarios'
import { sortOnKey } from '../../utils/format'
import { clamp, moveIndex } from '../../utils/helpers/javascript'
import SearchableDropdown from '../UI/Menu/SearchableDropdown'

const CourseScenariosList = ({ course, newList, verticalItemSize }) => {
  const VERTICAL_ITEM_SIZE = verticalItemSize || 55
  const [allScenarios, setAllScenarios] = useState([])
  const [showScenarioSelector, setShowScenarioSelector] = useState(false)
  const [scenariosList, setScenariosList] = useState(
    course?.publishedScenarios?.map(({ scenario }) => ({
      value: scenario.name,
      id: scenario.id,
    })) || []
  )
  // create an order index for draggable list
  const order = useRef(scenariosList.map((_, i) => i))

  const [getScenarios, { loading }] = useLazyQuery(getPublishedScenariosQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const ids = scenariosList.reduce((a, scenario) => {
        a.push(scenario.id)
        return a
      }, [])
      const ps = data.publishedScenarios.reduce((array, publishedScenario) => {
        if (publishedScenario && !ids.includes(publishedScenario.id)) {
          array.push({
            render: publishedScenario.name,
            value: publishedScenario.name,
            id: publishedScenario.id,
          })
        }
        return array
      }, [])
      setAllScenarios(sortOnKey(ps, 'value', false))
    },
  })

  const addScenario = (scenario) => {
    const newScenario = { value: scenario.value, id: scenario.id }
    setScenariosList([...scenariosList, newScenario])
    order.current.push(order.current.length)
  }

  /**
   * The reduce in deleteScenario ensures
   * The order indexes never overflow
   * e.g. if we want to delete orderValue 2 from array [1,0,2,5,4,3] (length = 6)
   * the newOrder will be [1,0,4,3,2] (length = 5)
   */
  const deleteScenario = (scenarioId, orderValue) => {
    const newOrder = order.current.reduce((array, value) => {
      if (value !== Number(orderValue)) {
        if (value > Number(orderValue)) {
          array.push(value - 1)
        } else {
          array.push(value)
        }
      }
      return array
    }, [])
    order.current = newOrder
    setScenariosList(scenariosList.filter((s) => s.id !== scenarioId))
  }

  const setStyles = (array, active, originalIndex, curIndex, y) => (index) => {
    const activeStyle = {
      top: curIndex * VERTICAL_ITEM_SIZE + y,
      transform: `scale(1.05)`,
      boxShadow: `0px 2px 3px rgba(0,0,0,0.06)`,
      zIndex: scenariosList.length + 1,
      color: 'black',
      cursor: 'grabbing',
      immediate: (n) => n === 'y' || n === 'zIndex',
    }
    const passiveStyle = {
      top: array.indexOf(index) * VERTICAL_ITEM_SIZE,
      boxShadow: `0px 2px 3px rgba(0,0,0,0.0)`,
      cursor: 'grab',
      transform: `scale(1)`,
      zIndex: scenariosList.length - array.indexOf(index),
      immediate: false,
    }

    return active && index === originalIndex ? activeStyle : passiveStyle
  }

  const [springs, setSprings] = useSprings(
    scenariosList.length,
    setStyles(order.current)
  )

  useEffect(() => {
    setSprings(scenariosList.length, setStyles(order.current))
    newList.current = order.current.map((i) => scenariosList[i])
  }, [scenariosList])

  const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const curRow = clamp(
      Math.round((curIndex * VERTICAL_ITEM_SIZE + y) / VERTICAL_ITEM_SIZE),
      0,
      scenariosList.length - 1
    )
    const newOrder = moveIndex(order.current, curIndex, curRow)
    setSprings(setStyles(newOrder, active, originalIndex, curIndex, y))

    if (!active) {
      order.current = newOrder
      newList.current = order.current.map((i) => scenariosList[i])
    }
  })

  return (
    <Fragment key={uuidV4()}>
      <label>Scenarios</label>
      <div
        className="o-draggable__list--container"
        style={{
          height: scenariosList.length * VERTICAL_ITEM_SIZE,
        }}>
        {springs.map((animationProps, i) => (
          <animated.div key={i} style={animationProps}>
            <animated.div
              {...bind(i)}
              className="o-draggable__list--container"
              style={{
                flexGrow: 1,
                display: 'inline-flex',
                alignItems: 'center',
              }}>
              <span className="o-draggable__list--prefix">
                <FaGripVertical />
              </span>
              <span className="o-draggable__list--item" style={{ flexGrow: 1 }}>
                {scenariosList[i].value}
              </span>
            </animated.div>
            <span
              className="o-draggable__list--suffix"
              order={i}
              id={scenariosList[i].id}>
              <FiX
                style={{ margin: '10', marginRight: '20px' }}
                size="16"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  const currentOrder =
                    e.currentTarget.parentNode.getAttribute('order')
                  const currentId =
                    e.currentTarget.parentNode.getAttribute('id')
                  deleteScenario(currentId, currentOrder)
                }}
              />
            </span>
          </animated.div>
        ))}
      </div>
      <div style={{ marginBottom: '5px' }}>
        <button
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            getScenarios()
            setShowScenarioSelector(true)
          }}
          className="button hollow secondary text-bold mb-1 mt1">
          <FaPlus className="mr-1" /> Add Scenario
        </button>
        {showScenarioSelector && (
          <SearchableDropdown
            placeholder="Search by scenario title..."
            clickHandler={(scenario) => {
              addScenario(scenario)
              getScenarios()
            }}
            loading={loading}
            hideHandler={() => setShowScenarioSelector(false)}
            notFoundTitle="No published scenarios"
            notFoundText={
              <small>
                There are no published scenarios (left) to add to this course
              </small>
            }
            data={allScenarios}
          />
        )}
      </div>
    </Fragment>
  )
}

export default CourseScenariosList
