import React from 'react'
import Dropdown from '../../../UI/Menu/Dropdown'
import { FaChevronDown } from 'react-icons/fa'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'

const TimerDropdowns = ({ element }) => {
  const { updateElement } = useAddUpdateElement()

  const updateSeconds = (e) => {
    const { timerSeconds } = e.target.dataset
    $(`#timer-sec-menu-${element.id}`).foundation('close')
    handleChangeElement(element.timerMinutes, timerSeconds)
  }

  const updateMinutes = (e) => {
    const { timerMinutes } = e.target.dataset
    $(`#timer-min-menu-${element.id}`).foundation('close')
    handleChangeElement(timerMinutes, element.timerSeconds)
  }

  const handleChangeElement = (timerMinutes, timerSeconds) => {
    updateElement({
      variables: {
        ...element,
        timerMinutes: timerMinutes ? Number(timerMinutes) : 0,
        timerSeconds: timerSeconds ? Number(timerSeconds) : 0,
      },
    })
  }

  return (
    <div className="flex-container align-middle">
      <span className="mr-0-5">
        <Dropdown
          id={`timer-min-menu-${element.id}`}
          alignment="right"
          offset={{ left: '20px', top: '0px' }}
          minWidth={200}
          button={
            <button
              className="o-button o-button--square-small o-button--secondary o-button--no-active 
                o-button--no-hover flex-container align-middle mb-0 pl-1 pr-1 w-auto"
              data-toggle={`timer-min-menu-${element.id}`}>
              <span className="text-bold">{element.timerMinutes ?? 0} min</span>
              <FaChevronDown className="ml-1 text-normal" />
            </button>
          }>
          <>
            <li className="o-dropdown__item o-dropdown__item--inactive text-small text-lighter text-stable-dark">
              Set timer to
            </li>
            {[0, 1, 2, 3, 4, 5].map((value) => (
              <li
                key={value}
                data-timer-minutes={value}
                className={`o-dropdown__list-item text-normal flex-container align-middle 
                  ${value === element.timerMinutes ? 'text-assertive' : 'text-dark'}`}
                onClick={updateMinutes}>
                {value} minutes
              </li>
            ))}
          </>
        </Dropdown>
      </span>
      <Dropdown
        id={`timer-sec-menu-${element.id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        minWidth={200}
        button={
          <button
            className="o-button o-button--square-small o-button--secondary 
              o-button--no-active o-button--no-hover  flex-container align-middle mb-0 pl-1 pr-1 w-auto"
            data-toggle={`timer-sec-menu-${element.id}`}>
            <span className="text-bold">{element.timerSeconds ?? 0} sec</span>
            <FaChevronDown className="ml-1 text-normal" />
          </button>
        }>
        <>
          <li className="o-dropdown__item o-dropdown__item--inactive text-small text-lighter text-stable-dark">
            Set timer to
          </li>
          {[10, 20, 30, 40, 50].map((value) => (
            <li
              key={value}
              data-timer-seconds={value}
              className={`o-dropdown__list-item text-normal flex-container align-middle 
                ${value === element.timerSeconds ? 'text-assertive' : 'text-dark'}`}
              onClick={updateSeconds}>
              {value} seconds
            </li>
          ))}
        </>
      </Dropdown>
    </div>
  )
}

export default TimerDropdowns
