import React from 'react'

const Loading = () => {
  return (
    <p className="text-center text-italic text-stable-dark mb-5 mt-5">
      Loading...
    </p>
  )
}

export default Loading
