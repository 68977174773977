import React, { useEffect, useState } from 'react'
import { FaTimes, FaTrophy } from 'react-icons/fa'
import { useQuery } from '@apollo/client'
import { convertDuration, dateFormat } from '../../utils/format'
import Stars from '../UI/Stars/Stars'
import { getAttemptDetailQuery } from '../../apollo/query/attempts'
import ScenesEvents from './ScenesEvents'
import { DeviceTypeIcon, getDeviceTypeString } from './DeviceTypeHelper'

const TrainingAttemptDetail = ({ attemptId, onClose }) => {
  const [showMore, setShowMore] = useState(false)
  const { data } = useQuery(getAttemptDetailQuery, {
    variables: {
      id: attemptId,
    },
    skip: !attemptId,
  })

  useEffect(() => {
    if (attemptId) setShowMore(false)
  }, [attemptId])

  let attempt = data?.attempt

  return (
    <aside
      className="border-left"
      style={{
        position: 'absolute',
        right: attemptId ? 0 : '-600px',
        width: '600px',
        background: '#FFF',
        height: '100%',
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100% - 22px)',
        overflow: 'visible',
        transition: 'all .2s',
      }}>
      <span
        style={{
          height: '100%',
          width: '21px',
          position: 'absolute',
          inset: 0,
          left: '-21px',
          backgroundImage:
            'linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000000 100%)',
          opacity: 0.025,
        }}></span>
      {data && (
        <>
          <header className="p-3 border-bottom flex-container align-justify">
            <div>
              <h2 className="mb-1 text-bold flex-container align-middle">
                <DeviceTypeIcon type={attempt.deviceType} />
                <span className="ml-1">{dateFormat(attempt.startedAt)}</span>
                {attempt.highscore && (
                  <span className="ml-1 o-label o-label--highscore">
                    <FaTrophy className="u-pull-left" /> HIGHSCORE
                  </span>
                )}
              </h2>
              <div className="text-normal">
                {attempt.user.name}{' '}
                <span className="text-stable-dark">playing</span>{' '}
                {attempt.publishedScenario.name}
              </div>
            </div>
            <span className="o-modal--close-button" onClick={onClose}>
              <FaTimes />
            </span>
          </header>

          <article className="p-3 h-100 max-h-100 grid--overflow">
            <div className="flex-container align-justify mb-1">
              <div className="border border-radius p-2 mr-3 w-100">
                <div className="text-stable-dark mb-1 text-normal">
                  Attempt No
                </div>
                <h2 className="text-bold mb-0">{attempt.sequence}</h2>
              </div>
              <div className="border border-radius p-2 mr-3 w-100">
                <div className="text-stable-dark mb-1 text-normal">
                  Duration
                </div>
                <h2 className="text-bold mb-0">
                  {attempt.completed ? convertDuration(attempt.duration) : '-'}
                </h2>
              </div>
              <div className="border border-radius p-2 w-100">
                <div className="text-stable-dark mb-1 text-normal">Score</div>
                <h2 className="text-bold mb-0">
                  {attempt.stars ? <Stars value={attempt.stars} /> : '-'}
                </h2>
              </div>
            </div>

            <div className="mt-3 text-normal">
              <div className="flex-container align-justify align-middle mb-1-5">
                <span className="text-stable-dark">Trainee</span>
                <span className="text-bold">{attempt.user.name}</span>
              </div>
              <div className="flex-container align-justify align-middle mb-1-5">
                <span className="text-stable-dark">Scenario</span>
                <span className="text-bold">
                  {attempt.publishedScenario.name}
                </span>
              </div>
              {!showMore && (
                <div className="flex-container mb-0-5">
                  <span
                    className="text-stable-dark text-underline cursor-pointer"
                    onClick={() => setShowMore(true)}>
                    Show more...
                  </span>
                </div>
              )}
              {showMore && (
                <>
                  <div className="flex-container align-justify align-middle mb-1-5">
                    <span className="text-stable-dark">Device type</span>
                    <span className="text-bold">
                      {getDeviceTypeString(attempt.deviceType)}
                    </span>
                  </div>
                  {attempt.browserName?.toLowerCase() === 'desktop' && (
                    <div className="flex-container align-justify align-middle mb-1-5">
                      <span className="text-stable-dark">Browser</span>
                      <span className="text-bold">{attempt.browserName}</span>
                    </div>
                  )}
                  <div className="flex-container align-justify align-middle mb-1-5">
                    <span className="text-stable-dark">Version</span>
                    <span className="text-bold">
                      {attempt.publishedScenario.version}
                    </span>
                  </div>
                  <div className="flex-container align-justify align-middle">
                    <span className="text-stable-dark">Scoring method</span>
                    <span className="text-bold">
                      {attempt.publishedScenario.scoringSystem.kind ===
                      'SUBTRACT'
                        ? 'Subtracting points'
                        : 'Adding points'}
                    </span>
                  </div>
                </>
              )}
            </div>

            <h3 className="mt-3">Training attempt</h3>

            <ScenesEvents
              events={attempt.events}
              scoringSystem={attempt.publishedScenario.scoringSystem.kind}
            />
          </article>
        </>
      )}
    </aside>
  )
}
export default TrainingAttemptDetail
