import React from 'react'

const ApiFields = ({ values, handleChange }) => (
  <>
    <div className="c-form__row">
      <label htmlFor="apiKey">The Warp VR API key for this workspace is</label>
      <div className="flex-container">
        <input
          type="text"
          name="apiKey"
          disabled
          onChange={handleChange}
          defaultValue={values.apiKey}
          className="mr-1 input--disabled"
        />
        <div className="o-button__copy--container">
          <input
            type="radio"
            name="o-button-copy"
            onChange={() => {
              navigator.clipboard.writeText(values.apiKey)
            }}
          />
          <button
            type="button"
            className="o-button button hollow secondary text-medium">
            Copy link
          </button>
          <button className="o-button button hollow success">
            <span>Copied!</span>
          </button>
        </div>
      </div>
    </div>
  </>
)

export default ApiFields
