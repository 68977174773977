import React, { useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'

const ProductOptionDropdown = ({
  id,
  adminPane,
  currentProductHandler,
  isPublished,
  isArchived,
  canBePublished,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const adminContent = () => (
    <>
      <li
        className="o-dropdown__list-item text-normal text-dark"
        data-open="edit-commission-modal"
        onClick={() => currentProductHandler(id)}>
        Edit commission
      </li>
    </>
  )
  const content = () => (
    <>
      {!isArchived && (
        <li
          className="o-dropdown__list-item text-normal text-dark"
          data-open="edit-product-modal"
          onClick={() => currentProductHandler(id)}>
          Edit product
        </li>
      )}
      {canBePublished && (
        <li
          data-open="publish-product-modal"
          className="o-dropdown__list-item text-normal text-dark"
          onClick={() => currentProductHandler(id)}>
          Publish to Warp Store
        </li>
      )}
      {!isArchived &&
        (isPublished ? (
          <li
            data-toggle="archive-product-modal"
            onClick={() => currentProductHandler(id)}
            className="o-dropdown__list-item text-normal text-dark">
            Archive product
          </li>
        ) : (
          <>
            <hr className="mt-1 mb-1" />
            <li
              data-toggle="delete-product-modal"
              onClick={() => currentProductHandler(id)}
              className="o-dropdown__list-item text-normal text-dark">
              Delete product
            </li>
          </>
        ))}
    </>
  )

  if (isHovered) {
    return (
      <Dropdown
        id={`product-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`product-menu-${id}`}
            className="cursor-pointer secondary hollow button button-alt mb-0">
            <FaEllipsisH />
          </button>
        }>
        {adminPane ? adminContent() : content()}
      </Dropdown>
    )
  }

  return (
    <button
      className="cursor-pointer secondary hollow button button-alt mb-0"
      data-toggle={`product-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}

export default ProductOptionDropdown
