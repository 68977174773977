import React from 'react'
import { useMutation } from '@apollo/client'
import {
  deleteCourseMutation,
  getCoursesQuery,
} from '../../../apollo/query/courses'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const DeleteCourseModal = ({
  id,
  appendTo,
  ids,
  clearSelectedItems,
}) => {
  const [deleteCourse] = useMutation(deleteCourseMutation, {
    refetchQueries: [{ query: getCoursesQuery }],
    onCompleted: () => {
      addSuccessAlert({ title: 'Course deleted' })
      clearSelectedItems()
    },
    onError: handleApolloError
  })

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete ${
        ids.length === 1 ? 'this course' : 'these courses'
      }?`}
      subtitle="This action cannot be undone. Scenarios will not be deleted."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      submitHandler={() => {
        ids.forEach((courseId) => {
          deleteCourse({
            variables: { id: courseId },
          })
        })
      }}
    />
  )
}
export default DeleteCourseModal
