import React, { Component } from 'react'
import { DraggableCore } from 'react-draggable'
import { observer } from 'mobx-react'
import Connector from './connector'
import AnswerMenu from '../answer-menu'

@observer
class IncomingConnector extends Component {
  shouldToggleMenu = false

  // Start dragging > mark start position to see if it is just a click
  startDrag = () => {
    const { answer } = this.props
    ;[answer.toConnectorX, answer.toConnectorY] = answer.toAnswerPosition()
    this.didClick = true
    answer.store.activeElement = answer
  }

  handleEnter = () => {
    this.props.answer.store.activeAnswer = this.props.answer
  }

  handleLeave = () => {
    this.props.answer.store.activeAnswer = null
  }

  // Dragging the scene
  handleDrag = (_, dragInfo) => {
    const { answer } = this.props

    // Really dragging, so no click to open menu
    this.didClick = false

    answer.isDragging = true
    answer.showMenu = false
    answer.isAddingNewCard = false

    // Disconnect from connected scene
    if (answer.toScene != null) {
      answer.toScene.removeIncomingAnswer(answer)
      answer.toScene = null
    }

    // Update position
    answer.toConnectorX += dragInfo.deltaX / answer.store.zoomFactor
    answer.toConnectorY += dragInfo.deltaY / answer.store.zoomFactor

    // Check if we are hovering a scene
    const scene = answer.store.positionWithinCard(
      answer.toConnectorX,
      answer.toConnectorY
    )
    if (scene) {
      // Yes, highlight that scene
      answer.store.highlightedScene = scene
    } else {
      // No, don't highligh any scene
      answer.store.highlightedScene = null
    }
  }

  // Stopped dragging
  stop = () => {
    const { answer } = this.props
    answer.isDragging = false

    // Still need to open menu?
    if (this.didClick && answer.link_to_id > 0) {
      answer.showMenu = !answer.showMenu
      answer.showLetterMenu = false
    }

    // Check if we are still hovering a scene
    const scene = answer.store.positionWithinCard(
      answer.toConnectorX,
      answer.toConnectorY
    )

    if (scene) {
      answer.isAddingNewCard = false

      // Yes, connect to that scene
      if (scene.id === answer.fromScene.id) {
        // Don't connect to own scene
        answer.toScene = null
      } else {
        answer.toScene = scene
        if (!this.didClick) {
          answer.showMenu = true
        }
      }
    } else {
      // Not hovering, create new scene at selected position
      answer.store.addNewCardAt(
        answer.toConnectorX,
        answer.toConnectorY,
        answer,
        'Scene'
      )
    }

    // Update answer position
    ;[answer.toConnectorX, answer.toConnectorY] = answer.toAnswerPosition()

    // No highlighted scene
    answer.store.highlightedScene = null
  }

  showIndex = () => {
    if (this.props.answer.store.activeAnswer === this.props.answer) {
      return 1500
    }

    if (this.props.answer.toScene != null) {
      return 1000 - this.props.answer.connector_priority
    }
    return 1001
  }

  render() {
    const { answer } = this.props

    const coordinates = answer.toAnswerPosition()

    return (
      <DraggableCore
        onStart={this.startDrag}
        onStop={this.stop}
        onDrag={this.handleDrag}
        grid={[10, 10]}
        disabled={answer.store.selection.isSpaceDragging}
        bounds="parent"
        cancel=".cancel-draggable-scene">
        <div
          onMouseEnter={this.handleEnter}
          onMouseLeave={this.handleLeave}
          style={{
            display: 'block',
            float: 'left',
            zIndex: this.showIndex(),
            width: 0,
            height: 0,
            top: coordinates[1],
            left: coordinates[0] - 12,
            position: 'absolute',
            opacity: answer.toScene != null || answer.shouldDrawLine ? 1 : 0,
          }}>
          <Connector {...this.props} type="incoming" />
          {!answer.isRandomized && (
            <AnswerMenu answer={answer} display={answer.showMenu} />
          )}
        </div>
      </DraggableCore>
    )
  }
}

export default IncomingConnector
