import React from 'react'
import { FaPlus } from 'react-icons/fa'

const TableAddButton = ({ dataOpen, title, onClick = () => {}, disabled }) => (
  <span className="cell shrink" title={title} onClick={onClick}>
    <label
      data-open={dataOpen}
      className={`button primary mb-0 ${disabled && 'disabled'}`}>
      <FaPlus />
    </label>
  </span>
)
export default TableAddButton
