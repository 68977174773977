import { gql, useQuery } from '@apollo/client'
import { sortOnKey } from '../utils/format'

const getManagersOwnersQuery = gql`
  query Users {
    owners: users(role: OWNER) {
      id
      name
    }
    managers: users(role: MANAGER) {
      id
      name
    }
  }
`

const useManagers = () => {
  const { data, loading } = useQuery(getManagersOwnersQuery)

  if (loading) return { data: null, loading: true }

  let { managers, owners } = data
  managers = sortOnKey(
    [
      ...managers,
      ...owners.filter((o) => !managers.some((m) => m.id === o.id)),
    ],
    'name'
  )

  return { data: managers, loading: false }
}

export default useManagers
