import React, { useEffect, useState } from 'react'

const Delay = ({ children, timeout }) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true)
    }, timeout || 500)
    return () => clearTimeout(timer)
  })

  if (!shouldRender) return null
  return <>{children}</>
}
export default Delay
