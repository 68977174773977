import React, { useState } from 'react'
import { TabContent, TabTitles } from '../../components/UI/Tabs/Tabs'
import PasswordTab from '../../components/Profile/PasswordTab'
import ProfileTab from '../../components/Profile/ProfileTab'
import withApollo from '../../hooks/withApollo'
import SecurityTab from '../../components/Profile/SecurityTab'

const ProfileSettings = () => {
  const [activeTab, setActiveTab] = useState(0)

  const titles = ['Profile', 'Password and authentication', 'Security log']

  const content = [
    <ProfileTab key="profile-tab" />,
    <PasswordTab key="password-tab" />,
    <SecurityTab key="security-tab" />,
  ]

  return (
    <div id="profile-settings-table" className="grid-container fluid row">
      <div className="columns small-12 large-3 xxlarge-2 pl-0">
        <TabTitles
          titles={titles}
          activeTab={activeTab}
          selectHandler={setActiveTab}
        />
      </div>
      <div className="columns">
        <TabContent titles={titles} content={content} activeTab={activeTab} />
      </div>
    </div>
  )
}
export default withApollo(ProfileSettings)
