import React from 'react'
import { getPlanPrice } from '../../../utils/helpers/subscription'
import ManagePlanModal from './ManagePlanModal'
import S from '../../../utils/lang/en.json'

const SubscriptionDetail = ({ currentClient }) => {
  const getPlanDesc = () => {
    if (currentClient.plan.legacy)
      return (
        <>
          You are currently in a legacy plan. Contact{' '}
          <a
            href="mailto:support@warpvr.com"
            className="text-stable-dark text-underline">
            support@warpvr.com
          </a>{' '}
          for more information about your plan or to upgrade or downgrade.
        </>
      )

    return (
      <>
        Details about billing period and current plan.{' '}
        <a
          href="https://help.warpvr.com/organization-settings/qtJLeZHXC5TPNkXEcohX5m/upgrading-and-downgrading/wAPWHdKpKvEM7JQ2nKJHpN"
          target="_blank"
          className="text-stable-dark text-underline">
          More info about upgrading and downgrading.
        </a>
      </>
    )
  }

  const getPlanBillingPeriod = () =>
    S.dataTypes.transform.billing_period[currentClient.planBillingPeriod]

  return (
    <>
      <div className="row">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Subscription</h2>
          <p>{getPlanDesc()}</p>
        </div>

        <div className="xlarge-6 medium-8 small-12 columns">
          <div className="mb-2">
            <label className="mb-1">Billing period</label>
            <div className="flex-container text-normal align-justify align-middle">
              <span>{getPlanBillingPeriod()}</span>
            </div>
          </div>
          <div className="mb-2">
            <label className="mb-1">Current plan</label>
            <div className="flex-container text-normal align-justify align-middle">
              <span>{currentClient.plan.name}</span>
              {currentClient.workspaceKind === 'BILLING' &&
                !currentClient.plan.legacy && (
                  <span>
                    {getPlanPrice(
                      currentClient.planBillingCurrency,
                      currentClient.plan,
                      currentClient.planBillingPeriod === 'YEAR'
                    )}{' '}
                    / {currentClient.planBillingPeriod.toLowerCase()}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>

      <ManagePlanModal
        currentClient={currentClient}
        id="manage-plan-modal"
        appendTo="#subscription-settings"
      />
    </>
  )
}

export default SubscriptionDetail
