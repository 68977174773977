import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FaImage, FaPlus } from 'react-icons/fa'
import Table from '../../../components/Table'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'
import withApollo from '../../../hooks/withApollo'
import Delay from '../../../utils/helpers/Delay'
import Checkbox from '../../../components/UI/Form/Checkbox'
import { default as S } from '../../../utils/lang/en.json'
import { convertSize, dateFormat, sortOnKey } from '../../../utils/format'
import { switchClientMutation } from '../../../apollo/query/clients'
import TableHeader from '../../../components/UI/TableHeader'
import TableColumnsDropdown from '../../../components/UI/TableColumnsDropdown'
import TableSearchBar from '../../../components/UI/Table/TableSearchBar'
import TableFilters from '../../../components/UI/Table/TableFilters'
import WorkspaceOptionDropdown from '../../../components/Workspaces/WorkspaceOptionDropdown'
import usePreferences from '../../../hooks/usePreferences'
import {
  filterColumnsHandler,
  sortHandler,
} from '../../../components/tableFunctions'
import FilterEmptyState from '../../../components/UI/Menu/FilterEmptyState'
import AddEditAdminWorkspaceModal from '../../../components/Workspaces/AddEditAdminWorkspaceModal'
import DeleteWorkspaceModal from '../../../components/Workspaces/DeleteWorkspaceModal'
import { handleApolloError } from '../../../utils/errors'
import { getAdminClientsQuery } from '../../../apollo/query/workspaces'

const tableHeaders = [
  'name',
  'id',
  'workspaceKind',
  'billing',
  'workspaceManagedByClientName',
  'planName',
  'planAdditionalPublishedScenarios',
  'planAdditionalWorkspaces',
  'lastActivityAt',
  'createdAt',
  'planBillingPeriod',
  'lastMonthAttemptCount',
  'lastMonthTestAttemptCount',
  'last3mActiveTraineeCount',
  'attemptCount',
  'testAttemptCount',
  'traineeCount',
  'memberCount',
  'enrolledTraineeCount',
  'draftScenarioCount',
  'publishedScenarioCount',
  'testScenarioCount',
  'suspendedScenarioCount',
  'originalSceneCount',
  'publishedSceneCount',
  'groupCount',
  'courseCount',
  'mediumCount',
  'originalStorageByteSize',
  'processedStorageByteSize',
  'publishedStorageByteSize',
  'lastMonthDraftSceneCount',
  'lastWeekDraftSceneCount',
  'lastMonthPublishedScenarioCount',
  'lastMonthMediumCount',
  'lastWeekActiveMemberCount',
  'lastMonthActiveMemberCount',
]

const AdminWorkspaces = () => {
  const [preferences, setPreferences] = usePreferences('clients')

  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useState([])
  const [filterOption, setFilterOption] = useState({
    expert: '',
    planName: '',
    createdByClientName: '',
  })

  const [showEmptyState, setShowEmptyState] = useState(false)
  const [showLoadingIcon, setShowLoadingIcon] = useState(false)
  const [selectedWorkspace, setSelectedWorkspace] = useState()

  // Data & render states
  const numberOfItems = 100
  const [limit, setLimit] = useState(2 * numberOfItems)
  const [hasMore, setHasMore] = useState(false)
  const [renderData, setRenderData] = useState([])
  const [isSortingData, setIsSortingData] = useState(true)
  const [clientCount, setClientCount] = useState(0)

  // User Preferences

  const {
    loading: loadingClients,
    data,
    refetch,
  } = useQuery(getAdminClientsQuery)

  const [switchClient] = useMutation(switchClientMutation, {
    onError: handleApolloError,
    onCompleted: async () => {
      window.location.href = '/home'
    },
  })

  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px', cursor: 'default' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.clients.headers[item]}
          />
        </li>
      )
    })
  }

  const fillFilters = () => {
    if (!data?.clients) return null

    setFilters([
      {
        text: 'Type',
        type: 'workspaceKind',
        options: { NORMAL: 'Creation', DISTRIBUTION: 'Distribution' },
      },
      {
        text: 'Billing',
        type: 'billing',
        options: { yes: 'Yes', no: 'No' },
      },
      {
        text: 'Managed by',
        type: 'workspaceManagedByClientName',
        options: data.clients.reduce((r, c) => {
          const { workspaceManagedByClientName } = c
          r[workspaceManagedByClientName] = workspaceManagedByClientName
          return r
        }, {}),
      },
      {
        text: 'Plan',
        type: 'planName',
        options: data.clients.reduce((r, c) => {
          const planName = c.plan ? c.plan.name : '-'
          r[planName] = planName
          return r
        }, {}),
      },
    ])
  }

  const tableLeftBar = () => (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        setSearchQuery={setSearchQuery}
      />
      <TableFilters
        filters={filters}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
      />
    </>
  )

  const tableRightBar = () => (
    <>
      <TableColumnsDropdown content={content} />
      <span className="cell shrink mr-1" title="Export">
        <a
          href="/admin/clients.csv"
          className="button hollow secondary text-bold mb-1 mt1">
          Export
        </a>
      </span>
      <span className="cell shrink" title="New Client">
        <span data-open="add-workspace-modal" className="button primary mb-0">
          <FaPlus />
        </span>
      </span>
    </>
  )

  const sortedData = () => {
    if (!data?.clients || !preferences?.sortOn) return null

    let rows = data.clients

    if (filterOption.workspaceKind) {
      if (filterOption.workspaceKind === 'NORMAL')
        rows = rows.filter(({ workspaceKind }) =>
          ['NORMAL', 'BILLING'].includes(workspaceKind)
        )
      else
        rows = rows.filter(
          ({ workspaceKind }) => workspaceKind === filterOption.workspaceKind
        )
    }

    if (filterOption.billing) {
      rows = rows.filter(({ workspaceKind }) =>
        filterOption.billing === 'yes'
          ? workspaceKind === 'BILLING'
          : workspaceKind !== 'BILLING'
      )
    }

    if (filterOption.planName) {
      rows = rows.filter(
        ({ plan }) => (plan ? plan.name : '-') === filterOption.planName
      )
    }

    if (filterOption.workspaceManagedByClientName) {
      rows = rows.filter(
        ({ workspaceManagedByClientName }) =>
          workspaceManagedByClientName ===
          filterOption.workspaceManagedByClientName
      )
    }

    rows = rows.filter(({ name }) => {
      return name.toLowerCase().includes(searchQuery.toLowerCase())
    })

    if (!rows.length && !showEmptyState) setShowEmptyState(true)
    if (rows.length && showEmptyState) setShowEmptyState(false)

    setClientCount(rows.length)

    const clientData = rows.map((client) => {
      const planName = client.plan ? client.plan.name : '-'
      const parsed = { ...client, ...client.statistics, planName }
      delete parsed.statistics
      delete parsed.plan
      return parsed
    })

    const { value, isAscending } = preferences.sortOn

    const formattedData = clientData.slice(0, limit).map((c) => ({
      name: c.name,
      id: c.id,
      workspaceKind: c.workspaceKind
        ? `${S.dataTypes.transform.workspace_kind[c.workspaceKind.toString()]}`
        : '-',
      billing: c.workspaceKind === 'BILLING' ? 'Yes' : 'No',
      workspaceManagedByClientName:
        c.workspaceKind === 'BILLING' ? '-' : c.workspaceManagedByClientName,
      planName: c.planName,
      planAdditionalPublishedScenarios: c.planAdditionalPublishedScenarios,
      planAdditionalWorkspaces: c.planAdditionalWorkspaces,
      lastActivityAt: {
        value: c.lastActivityAt,
        render: <span>{dateFormat(c.lastActivityAt)}</span>,
      },
      createdAt: {
        value: c.createdAt,
        render: <span>{dateFormat(c.createdAt)}</span>,
      },
      planBillingPeriod: c.planBillingPeriod
        ? `${
            S.dataTypes.transform.billing_period[c.planBillingPeriod.toString()]
          }`
        : '-',
      lastMonthAttemptCount: c.lastMonthAttemptCount,
      lastMonthTestAttemptCount: c.lastMonthTestAttemptCount,
      last3mActiveTraineeCount: c.last3mActiveTraineeCount,
      attemptCount: c.attemptCount,
      testAttemptCount: c.testAttemptCount,
      traineeCount: c.traineeCount,
      memberCount: c.memberCount,
      enrolledTraineeCount: c.enrolledTraineeCount,
      draftScenarioCount: c.draftScenarioCount,
      publishedScenarioCount: c.publishedScenarioCount,
      testScenarioCount: c.testScenarioCount,
      suspendedScenarioCount: c.suspendedScenarioCount,
      originalSceneCount: c.originalSceneCount,
      publishedSceneCount: c.publishedSceneCount,
      groupCount: c.groupCount,
      courseCount: c.courseCount,
      mediumCount: c.mediumCount,
      originalStorageByteSize: convertSize(c.originalStorageByteSize),
      processedStorageByteSize: convertSize(c.processedStorageByteSize),
      publishedStorageByteSize: convertSize(c.publishedStorageByteSize),
      lastMonthDraftSceneCount: c.lastMonthDraftSceneCount,
      lastWeekDraftSceneCount: c.lastWeekDraftSceneCount,
      lastMonthPublishedScenarioCount: c.lastMonthPublishedScenarioCount,
      lastMonthMediumCount: c.lastMonthMediumCount,
      lastWeekActiveMemberCount: c.lastWeekActiveMemberCount,
      lastMonthActiveMemberCount: c.lastMonthActiveMemberCount,
    }))

    setRenderData(sortOnKey(formattedData, value, isAscending))

    setHasMore(rows.length > limit)
    if (showLoadingIcon) {
      setShowLoadingIcon(false)
    }
    return setIsSortingData(false)
  }

  useEffect(() => {
    const doneLoading = !loadingClients
    if (doneLoading) {
      fillFilters()
      setIsSortingData(true)
    }
  }, [loadingClients])

  // Set Client Count
  useEffect(() => {
    if (data?.clients) {
      setClientCount(data.clients.length)
    }
  }, [data])

  useEffect(() => {
    if (!searchQuery) {
      setShowLoadingIcon(false)
    }
    if (preferences && data) {
      sortedData()
    }
  }, [preferences, limit, searchQuery, filterOption, data])

  if (loadingClients || !data || !preferences) {
    return (
      <Delay>
        <LoadingSpinner dotsOnly />
      </Delay>
    )
  }

  const renderOptions = (id) => {
    const workspace = data.clients.find((c) => c.id === id)
    return (
      <WorkspaceOptionDropdown
        id={id}
        workspace={workspace}
        onClick={(action) => handleDropdownClick(action, workspace)}
      />
    )
  }

  const handleDropdownClick = (action, workspace) => {
    setSelectedWorkspace(workspace)

    const actions = {
      switch: () => switchClient({ variables: { id: workspace.id } }),
      edit: () => $('#add-workspace-modal').foundation('open'),
      delete: () => $('#delete-workspace-modal').foundation('open'),
    }

    actions[action]()
  }

  return (
    <div id="clients">
      <div className="grid-container fluid">
        <TableHeader
          length={clientCount}
          title="Workspace"
          leftBar={tableLeftBar()}
          rightBar={tableRightBar()}
        />
      </div>
      <div
        className="o-table--page-container grid-container fluid"
        style={{ top: '130px' }}>
        {showEmptyState && !isSortingData ? (
          <FilterEmptyState
            type="clients"
            icon={<FaImage />}
            clickHandler={() => {
              setSearchQuery('')
              setFilterOption({
                expert: '',
                planName: '',
              })
              setShowEmptyState(false)
            }}
          />
        ) : (
          <Table
            data={renderData}
            placeholderRows={1}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            sortOn={preferences.sortOn}
            sortHandler={(value) =>
              sortHandler(value, preferences, setPreferences)
            }
            loadMore={hasMore}
            loadMoreHandler={() => setLimit(limit + numberOfItems)}
            type="clients"
            rowSuffix={renderOptions}
          />
        )}
      </div>

      <AddEditAdminWorkspaceModal
        id="add-workspace-modal"
        appendTo="#clients"
        defaultValues={selectedWorkspace}
        onSubmit={() => {
          setTimeout(() => {
            setSelectedWorkspace()
          }, 500)
        }}
        onClose={() => {
          setTimeout(() => {
            setSelectedWorkspace()
          }, 500)
        }}
      />

      {/* Don't remove the wrapper div because when we can not use two modals as siblings */}
      <div>
        <DeleteWorkspaceModal
          id="delete-workspace-modal"
          workspaceId={selectedWorkspace?.id}
          appendTo="#workclientsspaces"
          submitCallback={refetch}
        />
      </div>
    </div>
  )
}

export default withApollo(AdminWorkspaces)
