import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Modal from '../../../UI/Modal'
import {
  removeProductMutation,
  getProductsQuery,
} from '../../../../apollo/query/products'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import { handleApolloError } from '../../../../utils/errors'

const DeleteProductModal = ({ id, appendTo, ids, products, callback }) => {
  const [single, setSingle] = useState(null)
  const [notificationId, setNotificationId] = useState(null)
  const [canBedeleted, setCanBeDeleted] = useState(true)

  useEffect(() => {
    const toBeDeleted = products.filter((product) => ids.includes(product.id))
    setCanBeDeleted(
      toBeDeleted.every(
        (product) => product.state !== 'ARCHIVED' && product.state !== 'READY'
      )
    )
  })

  const showAlert = () => {
    if (!notificationId) {
      const alertId = addSuccessAlert({
        title: `Product${!single ? 's' : ''} successfully deleted`
      })

      setNotificationId(alertId)
    }
  }
  const [deleteProduct] = useMutation(removeProductMutation, {
    refetchQueries: [{ query: getProductsQuery }],
    onError: handleApolloError,
    onCompleted: () => {
      showAlert()
      callback()
    },
  })

  const unableToDeleteProducts = () => {
    return products
      .reduce((a, { id: productId, name, state }) => {
        if (
          ids.includes(productId) &&
          (state === 'ARCHIVED' || state === 'READY')
        )
          a.push(name)
        return a
      }, [])
      .join(', ')
  }
  if (!canBedeleted)
    return (
      <Modal
        id={id}
        appendTo={appendTo}
        textAssertive
        headerText="Unable to delete these products"
        subtitle="Some of the selected products are in the Warp Store or archived. Sellable and archived products cannot be deleted."
        buttons={
          <button className="button hollow secondary wide" data-close={id}>
            Close
          </button>
        }>
        {' '}
        <p className="mt-3">
          Unable to remove{' '}
          <strong className="text-dark">{unableToDeleteProducts()}</strong>
        </p>
      </Modal>
    )
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete ${
        ids.length === 1 ? 'this product' : 'these products'
      }?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={callback}
      submitHandler={() => {
        setSingle(ids.length === 1)
        ids.forEach((productId) => {
          deleteProduct({ variables: { id: productId } })
        })
      }}
    />
  )
}
export default DeleteProductModal
