import { SceneEditorContext } from './SceneEditorContext'
import { VideoContext } from './VideoContext'
import { MediumContext } from '../Media/Modal/MediumContext'

const { useContext } = require('react')

const useVideoPlayer = () => {
  const [scene] = useContext(SceneEditorContext)
  const [state, setState] = useContext(VideoContext)
  const {
    medium: [medium],
    currentVideoRef,
  } = useContext(MediumContext)

  const togglePlay = () => {
    setState((s) => ({ ...s, isPlaying: !state.isPlaying }))
    if (state.isPlaying) state.video.pause()
    if (!state.isPlaying) state.video.play()
  }

  const setVolume = (value) => {
    if (state.video.muted && value > 0) state.video.muted = false
    state.video.volume = value
  }

  const loop = () => {
    if (!scene.videoLoopEnabled) {
      return
    }

    if (medium.trimEnabled) {
      const startVideoLoop = Number(medium.trimStart) + Number(scene.videoLoop)
      currentVideoRef.current.currentTime = startVideoLoop
      currentVideoRef.current.play()
    } else {
      currentVideoRef.current.currentTime = scene.videoLoop
      currentVideoRef.current.play()
    }
  }

  const setShowElements = (value) => {
    setState((s) => ({ ...s, showElements: value }))
  }

  return {
    togglePlay,
    isPlaying: state.isPlaying,
    setVolume,
    setShowElements,
    loop,
  }
}

export default useVideoPlayer
