import React, { useContext, useState } from 'react'
import { TabContent, TabTitles } from '../../../UI/Tabs/Tabs'
import ElementsTab from './ElementsTab'
import { useNodes } from 'reactflow'
import EmptyState from './EmptyState'
import DescriptionField from './DescriptionField'
import SidebarHeader from './SidebarHeader'
import useSelectedNode, {
  useAllSelectedNodes,
} from '../../hooks/useSelectedNode'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import SidebarMulti from './SidebarMulti'
import MediaTab from './MediaTab'
import FilmingTab from './FilmingTab'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import HistoryTab from './HistoryTab'
import useCurrentUser from '../../../../hooks/useCurrentUser'

const getTitles = (currentUser) => {
  const titles = ['Elements', 'Filming', 'Media']
  if (currentUser.administrator) return [...titles, 'History']
  return titles
}

const Sidebar = () => {
  const [currentUser] = useCurrentUser()
  const allNodes = useNodes()
  const node = useSelectedNode()
  const { scenario } = useContext(ScenarioEditorContext)
  const scene = scenario.scenes.find((s) => s.id === node?.id)
  const showVideoEditor = useShowVideoEditor()

  const [activeTab, setActiveTab] = useState(0)
  const selectedNodes = useAllSelectedNodes()

  if (!currentUser) return null

  const content = [
    <ElementsTab key="elements" selectedNode={node} />,
    <FilmingTab />,
    <MediaTab selectedNode={node} />,
    <HistoryTab show={activeTab === 3} />,
  ]

  if (selectedNodes.length === 0 || !allNodes.length)
    return (
      <EmptyState type={selectedNodes.length === 1 ? 'notSelected' : 'empty'} />
    )

  if (selectedNodes.length > 1) return <SidebarMulti />

  return (
    <aside
      id="sceneario-editor-sidebar"
      className={`scroll-container ${showVideoEditor ? 'video-editor-sidebar' : ''}`}>
      <SidebarHeader selectedNode={node} />

      <div className="pt-3 pb-2 pr-3 pl-2">
        <h3 className="pl-1">Description</h3>
        <DescriptionField scene={scene} selectedNode={node} />
      </div>

      <TabTitles
        type="inline"
        titles={getTitles(currentUser)}
        activeTab={activeTab}
        selectHandler={setActiveTab}
      />
      <TabContent
        titles={getTitles(currentUser)}
        content={content}
        activeTab={activeTab}
      />
    </aside>
  )
}

export default Sidebar
