import React, { useState } from 'react'
import { FaRandom, FaVideo } from 'react-icons/fa'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import SidebarDropdown from './SidebarDropdown'
import TransparentInput from './TransparentInput'
import useAddUpdateScene from '../../hooks/useAddUpdateScene'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import HomeLabel from '../FlowEditor/elements/HomeLabel'
import SceneNumberLabel from '../FlowEditor/elements/SceneNumberLabel'
import { Link } from 'react-router-dom'

const SidebarHeader = ({ selectedNode }) => {
  const [focused, setFocused] = useState()
  const [hovered, setHovered] = useState(false)
  const { updateSceneDetails } = useAddUpdateScene()
  const showVideoEditor = useShowVideoEditor()

  if (!selectedNode) return <></>

  const handleCancel = () => {
    setTimeout(() => {
      setFocused(false)
    }, 300)
  }

  const handleChangeTitle = ({ target: { value } }) => {
    if (selectedNode.data.name !== value) updateSceneDetails({ name: value })
  }

  const renderVideoLabel = () => {
    if (!selectedNode.data.hasVideo) return <></>
    return (
      <span className="o-label o-label--royal text-white mr-0-5">
        <FaVideo />
      </span>
    )
  }

  const renderHomeLabel = () => {
    if (!selectedNode.data.start) return <></>
    return <HomeLabel className="mr-0-5" />
  }

  return (
    <header
      className={`p-2 flex-container align-middle align-justify border-light-bottom`}
      style={{ minHeight: '90px' }}>
      <div
        className={`flex-container align-middle w-100 pr-1 pl-1 border-radius 
          ${hovered ? 'bg-stable-lightest' : ''} 
          ${focused ? 'bg-stable-lighter' : ''} 
      `}>
        {renderVideoLabel()}
        {renderHomeLabel()}
        <SceneNumberLabel
          number={selectedNode.data.number}
          className="text-small mb-0"
        />

        <TransparentInput
          id={`title-${selectedNode.id}`}
          type="contentEditable"
          name="name"
          placeholder="Add a title..."
          onConfirm={handleChangeTitle}
          value={selectedNode.data.name}
          className="sidebar__title w-100"
          onCancel={handleCancel}
          onFocus={setFocused}
          onBlur={handleCancel}
          onMouseEnter={setHovered}
          onMouseLeave={() => {
            setHovered(false)
          }}
          minHeight={42}
          focusedMinHeight={42}
        />
      </div>
      <ButtonGroup hidden={!!focused} className="ml-1">
        <Link
          className="pl-1-5 pr-1-5 cursor-pointer text-bold border-radius tracking-tighter flex-container align-middle border-light pt-1-5 pb-1-5 o-button--light text-stable"
          title="Video editor"
          to={`/scenes/${selectedNode.id}${showVideoEditor ? '' : '/video'}`}>
          {!showVideoEditor && <FaVideo className="text-stable-dark" />}
          {showVideoEditor && <FaRandom className="text-stable-dark" />}
        </Link>
        <SidebarDropdown node={selectedNode} />
      </ButtonGroup>
    </header>
  )
}

export default SidebarHeader
