import React from 'react'
import withApollo from '../../../hooks/withApollo'
import { handleApolloError } from '../../../utils/errors'
import { useMutation } from '@apollo/client'
import { activateUserMutation } from '../../../apollo/query/user'
import { resetCache } from '../../../apollo/cache'

const ActivateForm = ({ token, handleNext }) => {
  const [activateUser] = useMutation(activateUserMutation, {
    onError: handleApolloError,
  })

  const activateUserProfile = async (e) => {
    e.preventDefault()
    activateUser({
      variables: {
        token,
        generalAndTermsOfUseAccepted: true,
      },
    }).then(({ data }) => {
      handleNext(data.activateUser.user)
      resetCache()
    })
    handleNext({})
  }

  return (
    <>
      <div className="text-normal mt-3">
        <h4 className="mb-1 text-bold">Terms</h4>
        <p className="mb-4">
          Since this is your first sign in, you need to agree to our terms. By
          clicking next you agree to our{' '}
          <a
            className="text-underline text-stable-dark"
            target="_blank"
            href="https://warp-vr.notion.site/Terms-and-Privacy-9db34c0f2a974c7a89bbab9aac78ff9a">
            General Terms and Conditions
          </a>{' '}
          and{' '}
          <a
            className="text-underline text-stable-dark"
            href="https://warp-vr.notion.site/Terms-and-Privacy-9db34c0f2a974c7a89bbab9aac78ff9a"
            target="_blank">
            Terms of Use
          </a>
          .
        </p>
      </div>
      <button
        className="o-button text-bold w-100"
        onClick={activateUserProfile}>
        Next
      </button>
    </>
  )
}

export default withApollo(ActivateForm)
