import React from 'react'
import {
  FaComment,
  FaKeyboard,
  FaLightbulb,
  FaQuestionCircle,
  FaRocket,
} from 'react-icons/fa'
import Dropdown from '../UI/Menu/Dropdown'
import KeyboardShortcutsModal from './KeyboardShortcutsModal'

const openChat = () => window.$crisp.push(['do', 'chat:open'])

const HelpDropdown = ({ id, button }) => {
  return (
    <>
      <Dropdown
        id={id}
        alignment="right"
        button={button}
        minWidth={240}
        offset={{ left: '20px', top: '0px' }}>
        <>
          <li className="o-dropdown__list-item">
            <a
              href="https://help.warpvr.com"
              target="_blank"
              className="text-dark flex-container align-middle"
              alt="Help Center"
              style={{ display: 'flex' }}>
              <FaQuestionCircle className="mr-1" />
              Help Center
            </a>
          </li>

          <li className="o-dropdown__divider mt-0-5 mb-0-5"></li>

          <li className="o-dropdown__list-item">
            <a
              href="https://www.warpvr.com/knowledge-hub/start"
              target="_blank"
              className="text-dark flex-container align-middle"
              alt="Getting started"
              style={{ display: 'flex' }}>
              <FaRocket className="mr-1 text-dark" />
              Getting started
            </a>
          </li>

          <li className="o-dropdown__list-item">
            <a
              href="https://www.warpvr.com/knowledge-hub/inspiration"
              target="_blank"
              className="text-dark flex-container align-middle"
              style={{ display: 'flex' }}>
              <FaLightbulb className="mr-1" />
              Scenario inspiration
            </a>
          </li>

          <li className="o-dropdown__list-item">
            <a
              data-open="keyboard-shortcuts-modal"
              className="text-dark flex-container align-middle">
              <FaKeyboard className="mr-1" />
              Keyboard shortcuts
            </a>
          </li>

          <li className="o-dropdown__divider mt-0-5 mb-0-5"></li>

          <li className="o-dropdown__list-item">
            <a
              onClick={openChat}
              target="_blank"
              className="text-dark flex-container align-middle"
              style={{ display: 'flex' }}>
              <FaComment className="mr-1" />
              Contact support
            </a>
          </li>
        </>
      </Dropdown>
      <KeyboardShortcutsModal />
    </>
  )
}
export default HelpDropdown
