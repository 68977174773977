import React, { useCallback } from 'react'
import { HexColorPicker } from 'react-colorful'

const ColorPicker = ({ color, onChange }) => {
  const handleChange = useCallback((c) => onChange(c.toUpperCase()), [])
  return (
    <div className="o-colorpicker">
      <HexColorPicker color={color} onChange={handleChange} />
    </div>
  )
}

export default ColorPicker
