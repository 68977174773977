import React, { useState, useEffect } from 'react'
import { v4 as uuidV4 } from 'uuid'
import fonts from '../../../helpers/fonts'
import languageDetect from '../../../helpers/languageDetect'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import { isBrightColor } from '../../../../../utils/helpers/color'

const Button = ({ textValue, id, width = 1.8, type = 'default' }) => {
  const [fontUrl, setFontUrl] = useState(fonts.latin)
  const [uuid, setUuid] = useState(uuidV4())
  const [, currentClient] = useCurrentUser()

  useEffect(() => {
    if (textValue) {
      setUuid(uuidV4())
      const guessedLanguage = languageDetect.detect(textValue, 1)[0]
      const certainty = guessedLanguage[1]
      if (certainty < 0.1) {
        setFontUrl(fonts.fallback)
      } else {
        setFontUrl(fonts[guessedLanguage[0]])
      }
    }
  }, [textValue])

  if (!currentClient) return null

  return (
    <a-entity
      key={uuid}
      id={id}
      button={`type:${type}; width: ${width}`}
      color={currentClient.primaryColor}>
      <a-text
        id={`${id}-text`}
        value={textValue}
        font={fontUrl}
        color={isBrightColor(currentClient.primaryColor) ? 'black' : 'white'}
        anchor="center"
        align="center"
        material="shader: flat; visible: false; color: orange;"
        geometry="primitive: plane; width: auto; height: auto;"
        wrap-count={width > 1.8 ? width * 8.4 : 14}
        width={width - 0.2}
        letter-spacing={-1.5}
        shader="msdf"
        negate="false"
      />
      <a-entity id={`${id}-button`} />
    </a-entity>
  )
}

export default Button
