import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

const STORAGE_KEY = 'inputDrafts'

const TransparentInput = forwardRef(
  (
    {
      id = '',
      onConfirm = () => {},
      onCancel = () => {},
      placeholder = 'Add a label...',
      className = '',
      minHeight = 37,
      focusedMinHeight = 37,
      value,
      multiline = false,
      style = {},
      useDraft = true,
      ...props
    },
    outerRef
  ) => {
    const [hovered, setHovered] = useState(false)
    const [focused, setFocused] = useState(false)
    const innerRef = useRef(null)
    useImperativeHandle(outerRef, () => innerRef.current, [])

    const setValue = (value) => {
      if (props.type === 'contentEditable') {
        innerRef.current.innerHTML = value ?? ''
      } else {
        innerRef.current.value = value
      }
    }

    const handleCancel = () => {
      setValue(value)
      clearDraft()
      onCancel()
    }

    const handleConfirm = (e) => {
      e.stopPropagation()
      const value = innerRef.current.innerHTML
      clearDraft()
      onConfirm({
        target: { name: props.name, value },
      })
      setHovered(false)
      setFocused(false)
      setValue(value)
      innerRef.current.blur()
    }

    const loadDraft = () => {
      const draftValue = localStorage.getItem(STORAGE_KEY)
      return draftValue ? JSON.parse(draftValue) : {}
    }

    const saveDraft = () => {
      const currentValue = innerRef.current.innerHTML
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...loadDraft(), [id]: currentValue })
      )
    }
    const restoreDraft = () => {
      const draft = loadDraft()[id]
      if (draft) {
        if (props.type === 'contentEditable') innerRef.current.innerHTML = draft
        else innerRef.current.value = draft
      }
    }

    const clearDraft = () => {
      const draft = loadDraft()
      delete draft[id]
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...draft }))
    }

    const handleClickOutside = () => {
      const input = innerRef.current
      const currentValue =
        props.type === 'contentEditable' ? input.innerHTML : input.value

      if (value !== currentValue) {
        if (useDraft) saveDraft()
        setValue(value)
      }

      setFocused(false)
    }

    const renderInput = () => {
      const genericProps = {
        placeholder,
        className: `border-0 border-radius mb-0 text-normal text-dark pl-1 tracking-tighter 
          ${hovered ? 'bg-stable-lightest' : ''} 
          ${focused ? 'bg-stable-lighter' : ''} 
          ${multiline ? 'pb-3' : ''}
          ${!hovered && !focused ? 'bg-transparent' : ''} 
          ${className}
        `,
        style: {
          ...(!multiline ? { paddingRight: '60px' } : {}),
          ...style,
        },
        ...props,
        ref: innerRef,
        value,
        onClick: (e) => e.stopPropagation(),
        onKeyPress: (e) => {
          if (e.key === 'Enter' && !multiline) {
            e.preventDefault()
            handleConfirm()
            return false
          }
        },
        onFocus: (e) => {
          setFocused(true)

          // restore draft value from HTML data attribute
          restoreDraft()

          const currentValue =
            props.type === 'contentEditable'
              ? e.target.innerHTML
              : e.target.value
          props?.onFocus?.(currentValue)
        },
      }
      if (props.type === 'contentEditable')
        return (
          <div
            {...genericProps}
            contentEditable="plaintext-only"
            suppressContentEditableWarning={true}
            tabIndex={100}
            style={{
              minHeight: `${focused ? focusedMinHeight : minHeight}px`,
              overflow: 'hidden',
              outline: 'none',
              padding: '0.5rem',
              transition: 'all 0.2s',
              wordBreak: 'break-word',
              ...genericProps.style,
            }}>
            {value}
          </div>
        )

      return (
        <input
          type="text"
          {...genericProps}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleConfirm()
          }}
          style={genericProps.style}
        />
      )
    }

    useEffect(() => {
      setValue(value)
    }, [value])

    useEffect(() => {
      if (focused) {
        document.body.addEventListener('click', handleClickOutside)
      } else {
        setTimeout(() => {
          document.body.removeEventListener('click', handleClickOutside)
        }, 500)
      }
      return () => {
        document.body.removeEventListener('click', handleClickOutside)
      }
    }, [focused])

    return (
      <div
        className="w-100 h-100"
        style={{ position: 'relative', maxHeight: '100%' }}
        hidden={props.hidden}
        onMouseEnter={setHovered}
        onMouseLeave={() => {
          setHovered(false)
        }}>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            bottom: `${props.type === 'contentEditable' ? 1 : 0}px`,
          }}
          className="bg-stable-lighter pl-0-5 text-xxlarge"
          hidden={!focused}>
          <button
            onClick={handleCancel}
            className="text-stable-dark cursor-pointer">
            <FaTimes />
          </button>
          <button
            onClick={handleConfirm}
            className="text-assertive ml-1 cursor-pointer">
            <FaCheckCircle />
          </button>
        </div>
        {renderInput()}
      </div>
    )
  }
)

export default TransparentInput
