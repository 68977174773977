import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FiX } from 'react-icons/fi'

const Input = ({
  changeHandler,
  closeHandler,
  styleComponent,
  placeholder,
  defaultValue,
  collapsable = false,
  prefix = false,
}) => {
  const searchInput = useRef()
  const [visible, setVisible] = useState(!collapsable)

  useEffect(() => {
    if (
      !defaultValue &&
      visible &&
      document.activeElement !== searchInput.current
    ) {
      setVisible(!visible)
    }
  }, [defaultValue])
  const hideElement = {
    width: '0px',
  }

  const showInputHandler = () => {
    if (collapsable) {
      if (visible) {
        searchInput.current.value = ''
        changeHandler('')
      } else {
        setVisible(!visible)
        searchInput.current.focus()
      }
    }
  }

  return (
    <div
      data-testid="wrapper"
      className={
        styleComponent
          ? 'o-search o-search-input input-group'
          : 'o-search input-group'
      }>
      {prefix ? (
        <span className="o-search-input--prefix" onMouseUp={showInputHandler}>
          {prefix}
        </span>
      ) : null}
      <div
        data-testid="container"
        className="o-search-input--container"
        style={visible ? {} : hideElement}>
        <input
          data-testid="input"
          ref={searchInput}
          className="mb-0"
          name="inputField"
          value={defaultValue}
          onBlur={(e) => {
            if (closeHandler && !e.target.value) {
              closeHandler()
            }
          }}
          onChange={(e) => {
            changeHandler(e.target.value)
          }}
          type="text"
          placeholder={placeholder ?? 'Search...'}
        />
        <span
          data-testid="input-suffix"
          className="o-search-input--inline-suffix"
          onClick={(e) => {
            e.preventDefault()
            if (!searchInput.current.value && collapsable) {
              if (closeHandler) {
                closeHandler()
              }
              setVisible(false)
            } else {
              searchInput.current.value = ''
              changeHandler('')
              searchInput.current.focus()
            }
          }}>
          <FiX />
        </span>
      </div>
    </div>
  )
}

export default Input

Input.propTypes = {
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  collapsable: PropTypes.bool,
  prefix: PropTypes.any,
}
