import React from 'react'
import withApollo from '../../hooks/withApollo'
import PasswordForm from '../../components/UI/Form/PasswordForm'

const renderButton = (valid, passwordsMatch) => (
  <button
    type="submit"
    className="o-button text-bold w-100 mt-2"
    disabled={!(valid.allChecksPassed && passwordsMatch)}
    data-testid="submit-button">
    Confirm
  </button>
)

const UpdatePassword = ({ token }) => {
  return (
    <div className="flex-container align-center">
      <div className="pt-4 pb-4 c-form c-form__devise">
        <h1 className="text-bold mb-4 text-center">Change your password</h1>
        <PasswordForm
          token={token}
          showCurrentPassword
          onCompleted={() => (window.location.href = '/')}
          button={renderButton}
        />
      </div>
    </div>
  )
}

export default withApollo(UpdatePassword)
