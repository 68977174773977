import React from 'react'
import { FaListUl } from 'react-icons/fa'

const ActivitiesEmptyState = () => {
  return (
    <div
      data-testid="empty-group-wrapper"
      className="o-table--page-container grid-container fluid">
      <div style={{ display: 'grid', placeItems: 'center', height: '80%' }}>
        <span className="text-center">
          <span className="o-table__empty--icon">
            <FaListUl size={80}/>
          </span>
          <h2 className="text-bold text-dark">No activities yet!</h2>
        </span>
      </div>
    </div>
  )
}

export default ActivitiesEmptyState
