import colors from '../../../helpers/colors'
import { roundedRect } from '../../../helpers/aframe-shapes'

const { AFRAME, THREE } = window

AFRAME.registerComponent('button', {
  schema: {
    color: { default: colors.assertive },
    type: { default: 'default' },
    width: { default: 1.8 },
    height: { default: 0.4 },
    radius: { default: 0.05 },
    paddingY: { default: 0.2 },
  },
  draw() {
    const shape = new THREE.Shape()
    const { width, height, radius } = this.data
    roundedRect(shape, -width / 2, -height / 2, width, height, radius || 0.05)
    return new THREE.ShapeGeometry(shape)
  },

  update() {
    const textBox = this.self.children[0]
    const buttonBox = this.self.children[1]
    if (!textBox || !buttonBox) return null

    const { height } = textBox.getAttribute('geometry')
    if (!height) return null

    this.data.height = height + this.data.paddingY * 2

    this.self.setAttribute('position', `0 ${-this.data.height / 2} 0`)
    this.self.setAttribute(
      'geometry',
      `width: ${this.data.width}; height: ${this.data.height}`
    )

    this.self.setAttribute('material', 'visible:false')
    if (this.self.getAttribute('color'))
      this.material.color = new THREE.Color(this.self.getAttribute('color'))
    this.buttonMesh = new THREE.Mesh(this.draw(), this.material)

    textBox.setAttribute('position', `0 0.05 0.03`)

    buttonBox.setObject3D('button', this.buttonMesh)

    // tell mpc-list to update
    if (this.data.type === 'mpc') {
      this.self.parentNode.emit('update-required')
    }

    return this.self.emit('button-loaded')
  },
  onLoad() {
    this.mesh = new THREE.Mesh()
    this.mesh.position.set(0, 0, -0.001)

    for (const child of this.self.children) {
      child.addEventListener('componentchanged', (e) => {
        if (e.detail.name === 'geometry') {
          setTimeout(() => this.update(), 0)
        }
        if (e.detail.name === 'text') {
          setTimeout(() => this.update(), 0) //update width
          this.self.children[0].setAttribute(
            'geometry',
            `primitive: plane; width: auto; height: auto;`
          )
          setTimeout(() => this.update(), 0) //update height
        }
      })
    }
  },
  init() {
    this.self = this.el
    this.material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(this.data.color),
      side: THREE.FrontSide,
    })
    this.self.addEventListener('loaded', this.onLoad.bind(this))
  },
})
