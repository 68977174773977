import { addErrorAlert } from './helpers/alerts'
import appsignal from './appsignal'

const handleApolloError = (error) => {
  let title
  let icon

  const subtitle = 'Please try again later'

  if (error.graphQLErrors) {
    for (const err of error.graphQLErrors) {
      title = err.message
      switch (err.extensions.code) {
        case 'VALIDATION_FAILED':
          icon = 'warning'
          break
        case 'RECORD_NOT_FOUND':
          icon = 'not_found'
          break
        case 'OPERATION_NOT_ALLOWED':
          icon = 'not_allowed'
          break
        default:
          icon = 'warning'
          break
      }

      appsignal.addBreadcrumb({
        category: 'GraphQL',
        action: 'error',
        metadata: err,
      })
    }

    // Appsignal is only notified on graphQLErrors
    // that should have been avoided
    try {
      appsignal.sendError(error)
    } catch (e) {
      // In development error is not sent but raised,
      // catch and log error
      console.log('Error not sent', e)
    }
  }

  if (error.networkError) {
    // TODO: handle 4xx/5xx errors better
    title = 'Could not connect to the server'
    icon = 'warning'
  }

  addErrorAlert({
    title,
    subtitle,
    icon,
    timeout: 6000,
  })
}

export { handleApolloError }
