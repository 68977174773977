import React from 'react'
import Checkbox from '../../UI/Form/Checkbox'
import TableAddButton from '../../UI/Table/TableAddButton'
import TableColumnsDropdown from '../../UI/TableColumnsDropdown'

const ButtonBar = ({ headers, headersValues, columns, filterHandler }) => {
  const content = () => {
    return headers.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterHandler(item)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px', cursor: 'default' }}
            disabled={disabled}
            checked={!columns.includes(item)}
            value={headersValues[item]}
          />
        </li>
      )
    })
  }
  return (
    <>
      <TableColumnsDropdown content={content} />
      <TableAddButton title="New Trainee" dataOpen="add-trainee-modal" />
    </>
  )
}

export default ButtonBar
