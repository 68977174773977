import React, { useState, useContext } from 'react'
import { FaChevronLeft, FaChevronRight, FaChevronDown } from 'react-icons/fa'
import getIconByKind from '../../../utils/helpers/getIconByKind'
import SearchableDropdown from '../../UI/Menu/SearchableDropdown'
import { sortOnKey } from '../../../utils/format'
import { SceneEditorContext } from '../SceneEditorContext'
import { truncate } from '../../../utils/helpers/javascript'

const Header = ({ scenarioID, scenes }) => {
  const [sceneState] = useContext(SceneEditorContext)
  const [showSceneSelector, setShowSceneSelector] = useState(false)

  const sceneList = sortOnKey(
    scenes.reduce((array, s) => {
      if (s.id === sceneState.id) return array
      array.push({
        ...s,
        value: `${s.number} ${s.name}`,
        render: (
          <>
            <span className="scene-icon--default">
              <span>{s.number}</span>
            </span>
            <img
              className="mr-2"
              style={{ verticalAlign: 'unset' }} // style required to unset foundation markup
              src={getIconByKind(s.kind)}
            />
            <span style={{ flexGrow: 1 }}>{s.name}</span>
            {s.hasVideo && (
              <span
                className="scene-video-icon"
                title="scene is linked to a video"
              />
            )}
          </>
        ),
      })
      return array
    }, []),
    'number',
    false
  )

  const navigateScenes = (next) => {
    const sorted = sortOnKey([...scenes], 'number', false)
    let nextSceneId
    const currentIndex = sorted.findIndex((s) => {
      if (s.number === sceneState.number) return s.id
      return null
    })

    if (next) {
      nextSceneId =
        currentIndex === sorted.length - 1
          ? sorted[0].id
          : sorted[currentIndex + 1].id
    } else {
      nextSceneId =
        currentIndex === 0
          ? sorted[sorted.length - 1].id
          : sorted[currentIndex - 1].id
    }
    window.Turbolinks.visit(
      `/scenarios/${scenarioID}/cards/${nextSceneId}/editor`
    )
  }

  const backButton = (
    <div
      className=" h-100 cursor-pointer align-center"
      onClick={() => {
        const url = window.location.href
        const sceneUrl = url.substring(0, url.lastIndexOf('/'))
        window.Turbolinks.visit(sceneUrl)
      }}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <FaChevronLeft className="mr-1" />
      Back to scenes
    </div>
  )

  const sceneSelector = (
    <div
      id="headertext"
      onClick={() => setShowSceneSelector(!showSceneSelector)}
      className="  border-left border-right pr-3 pl-3 h-100 mr-3 ml-3 row align-middle cursor-pointer">
      <div
        style={{
          margin: '0 auto',
          display: 'inline-flex',
          alignItems: 'center',
        }}>
        <span className="scene-icon--default">
          <span>{sceneState.number}</span>
        </span>
        <img
          className="mr-1"
          style={{ verticalAlign: 'unset' }} // style required to unset foundation markup
          src={getIconByKind(sceneState.kind)}
        />
        <h2 className="mb-0 text-bold">
          {truncate(sceneState.name || '', 40, true)}
          <SearchableDropdown
            position="right"
            style={{ width: '477px', top: '10px', right: '-55px' }}
            placeholder="Search by scene name or number..."
            data={sceneList}
            show={showSceneSelector}
            hideHandler={() => {
              // this function is intentionally left empty
            }}
            clickHandler={(item) => {
              window.Turbolinks.visit(
                `/scenarios/${scenarioID}/cards/${item.id}/editor`
              )
            }}
          />
        </h2>

        <FaChevronDown className="ml-2 " />
      </div>
    </div>
  )

  const previousScene = (
    <div
      className="h-100  cursor-pointer"
      onClick={() => navigateScenes(false)}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      <FaChevronLeft className="mr-1" />
      Previous scene
    </div>
  )
  const nextScene = (
    <div
      className=" h-100 cursor-pointer"
      onClick={() => navigateScenes(true)}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
      }}>
      Next scene
      <FaChevronRight className="ml-1" />
    </div>
  )
  return (
    <div className="c-video__editor--header">
      <div className="xlarge-up">
        <div className="back-button">{backButton}</div>
        <div id="center_piece" className="center-piece">
          {previousScene}
          {sceneSelector}
          {nextScene}
        </div>
        <div>{sceneState.video.name}</div>
      </div>
      <div className="small-up">
        <div className="top-row">
          <div className="back-button">{backButton}</div>
          <div>{sceneState.video.name}</div>
        </div>
        <div className="bottom-row">
          {previousScene}
          {sceneSelector}
          {nextScene}
        </div>
      </div>
    </div>
  )
}

export default Header
