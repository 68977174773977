import React from 'react'

const ButtonGroup = ({ children, className = '', ...props }) => {
  return (
    <div
      className={`o-button-group flex-container ${className}`}
      {...props}>
      {children}
    </div>
  )
}

const Button = ({ active, children, last, className = '', ...props }) => {
  return (
    <button
      className={`pt-1 pb-1 pl-1-5 pr-1-5 cursor-pointer text-bold border-radius tracking-tighter flex-container align-middle border-light ${
        active ? 'bg-stable' : 'text-stable'
      } ${className}`}
      {...props}>
      {children}
    </button>
  )
}
ButtonGroup.Button = Button

export default ButtonGroup
