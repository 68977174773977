import React from 'react'
import { FaLock } from 'react-icons/fa'

const ManagedByClientPage = ({ workspaceManagedByClientName }) => {
  return (
    <div
      data-testid="empty-group-wrapper"
      className="o-empty-state"
      style={{ maxWidth: '500px' }}>
      <div className="flex-container align-center">
        <span className="text-center">
          <span className="o-table__empty--icon">
            <div className="flex-container align-center m-2">
              <FaLock size="150" />
            </div>
          </span>
          <h3 className="text-bold text-dark">
            This workspace is managed by {workspaceManagedByClientName}
          </h3>
          <p className="text-stable-dark">
            Switch to this workspace to change subscription and manage all
            workspaces
          </p>
        </span>
      </div>
    </div>
  )
}

export default ManagedByClientPage
