import React from 'react'
import {
  FaDesktop,
  FaMobileAlt,
  FaTabletAlt,
  FaVrCardboard,
} from 'react-icons/fa'

const mapTypeToIcon = {
  SMARTPHONE: <FaMobileAlt />,
  TABLET: <FaTabletAlt />,
  HEADSET: <FaVrCardboard />,
  DESKTOP: <FaDesktop />,
}

const mapTypeToString = {
  'SMARTPHONE': 'Smartphone',
  'TABLET': 'Tablet',
  'HEADSET': 'VR Headset',
  'DESKTOP': 'Desktop',
}

export const DeviceTypeIcon = ({ type }) => {
  return mapTypeToIcon[type] ?? <></>
}

export const getDeviceTypeString = (type) => {
  return mapTypeToString[type] ?? ''
}
