import React from 'react'

const Select = ({
  values,
  disabled,
  readOnly,
  selected,
  placeholder,
  changeHandler,
}) => {
  return (
    <select
      data-testid="select"
      disabled={disabled}
      readOnly={readOnly}
      value={selected || placeholder}
      onChange={({ target: { value } }) => changeHandler(value)}>
      {placeholder && (
        <option key="placeholder" disabled value={placeholder}>
          {placeholder}
        </option>
      )}
      {values.map(([value, text]) => (
        <option key={`id-${value}`} value={value}>
          {text}
        </option>
      ))}
    </select>
  )
}

export default Select
