import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaVrCardboard, FaChevronDown, FaEllipsisH } from 'react-icons/fa'
import { getDevices } from '../apollo/query/devices'
import TableHeader from '../components/UI/TableHeader'
import Table from '../components/Table'
import Delay from '../utils/helpers/Delay'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import withApollo from '../hooks/withApollo'
import { sortHandler, filterColumnsHandler } from '../components/tableFunctions'
import usePreferences from '../hooks/usePreferences'
import TableColumnsDropdown from '../components/UI/TableColumnsDropdown'
import TableAddButton from '../components/UI/Table/TableAddButton'
import Checkbox from '../components/UI/Form/Checkbox'
import { default as S } from '../utils/lang/en.json'
import { sortOnKey, dateFormat } from '../utils/format'
import SharedDevicesOptionDropdown from '../components/SharedDevices/SharedDevicesOptionDropdown'
import TableSearchBar from '../components/UI/Table/TableSearchBar'
import EmptyPage from '../components/EmptyPage'
import Filter from '../components/UI/Menu/Filter'
import AddSharedDeviceModal from '../components/SharedDevices/Modal/AddSharedDeviceModal'
import EditSharedDeviceModal from '../components/SharedDevices/Modal/EditSharedDeviceModal'
import DeleteSharedDeviceModal from '../components/SharedDevices/Modal/DeleteSharedDeviceModal'
import Toast from '../components/UI/Notifications/Toast'
import Dropdown from '../components/UI/Menu/Dropdown'
import DataTooltip from '../components/UI/DataTooltip'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import { handleApolloError } from '../utils/errors'

const SharedDevices = () => {
  const [preferences, setPreferences] = usePreferences(
    'sharedDevices',
    'deviceName'
  )
  const [selectedItems, setSelectedItems] = useState([])
  const [currentDeviceId, setCurrentDeviceId] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmptyPage, setShowEmptyPage] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [filteredType, setFilteredType] = useState(null)
  const [dismissToast, setDismissToast] = useState(false)

  const { data, loading, error, refetch } = useQuery(getDevices, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ devices }) => {
      if (!devices.length) setShowEmptyPage(true)
      else setShowEmptyPage(false)
    },
  })

  if (loading || !preferences)
    return (
      <Delay timeout="500">
        <LoadingSpinner text="Searching for devices" />
      </Delay>
    )
  if (error) handleApolloError(error)

  const tableHeaders = [
    'deviceName',
    'deviceType',
    'deviceRegistration',
    'deviceGroups',
  ]
  const tableSearchBar = (
    <>
      <TableSearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        showEmptyState={showEmptyState}
        setShowEmptyState={setShowEmptyState}
      />
      <span className="cell shrink large-order-2 mr-1">
        <Filter
          text="Type"
          icon={<FaChevronDown />}
          selected={filteredType || 'All'}
          filterHandler={(filter) =>
            setFilteredType(filter === 'All' ? '' : filter)
          }
          filterOptions={[
            'All',
            ...new Set(data.devices.map((d) => d.description)),
          ]}
        />
      </span>
    </>
  )

  const getDeviceGroups = (groups) => {
    if (!groups.length) return '-'
    const addDates = (start, end) => {
      const s = dateFormat(start, 'DD MMM YYYY', false)
      const e = dateFormat(end, 'DD MMM YYYY', false)
      return `<li class=' mr-1 text-italic' style='list-style:none'>has access from ${s} until ${e}</li>`
    }
    const groupContent = sortOnKey([...groups], 'name', false)
      .map((g) => {
        return `<li class='text-left'>${g.name}${
          g.startDate && g.endDate ? addDates(g.startDate, g.endDate) : ''
        }</li>`
      })
      .join('')

    const title = `<div class='text-bold text-left'>Connected to ${groups.length} groups</div><ul class='mb-0'>${groupContent}</ul>`
    return (
      <DataTooltip title={title}>
        <span className="text-underline">
          {groups.length} group{groups.length === 1 ? '' : 's'}
        </span>
      </DataTooltip>
    )
  }

  const sortedData = () => {
    const deviceData = [...data.devices]
    const parsedData = deviceData.reduce((a, d) => {
      if (d.registration === 'UNREGISTERED') return a

      const searchValues = `${d.name} ${d.description} ${d.type} ${
        S.dataTypes.devices.signInMethod[d.registration]
      }`.toLowerCase()

      if (!filteredType || filteredType === d.description) {
        if (searchValues.includes(searchQuery.toLowerCase())) {
          a.push({
            id: d.id,
            deviceName: d.name,
            deviceType: d.description,
            deviceRegistration:
              S.dataTypes.devices.signInMethod[d.registration],
            deviceGroups: getDeviceGroups(d.groups),
          })
        }
      }

      return a
    }, [])

    if (!parsedData.length && !showEmptyState) setShowEmptyState(true)
    if (parsedData.length && showEmptyState) setShowEmptyState(false)

    return sortOnKey(
      parsedData,
      preferences.sortOn.value,
      preferences.sortOn.isAscending
    )
  }

  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    return setSelectedItems(() => newSelection)
  }
  const content = () =>
    tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            style={{ marginBottom: '0px' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.sharedDevices.headers[item]}
          />
        </li>
      )
    })
  const renderOptions = (id) => {
    return (
      <SharedDevicesOptionDropdown
        id={id}
        setCurrentDeviceId={setCurrentDeviceId}
      />
    )
  }
  const loadModals = () => {
    const appendTo = '#shared-devices'
    return (
      <>
        <AddSharedDeviceModal
          id="add-device-modal"
          appendTo={appendTo}
          refetchDevices={refetch}
        />
        <EditSharedDeviceModal
          id="edit-device-modal"
          appendTo={appendTo}
          clearCurrentDeviceId={() => setCurrentDeviceId(null)}
          devices={
            currentDeviceId
              ? [data.devices.find((i) => i.id === currentDeviceId)]
              : selectedItems.map((id) => data.devices.find((i) => i.id === id))
          }
        />
        <DeleteSharedDeviceModal
          id="delete-device-modal"
          appendTo={appendTo}
          clearCurrentDeviceId={() => setCurrentDeviceId(null)}
          devices={data.devices}
          clearSelectedItems={() => setSelectedItems([])}
          ids={currentDeviceId ? [currentDeviceId] : selectedItems}
        />
      </>
    )
  }
  const pageContent = () => {
    if (showEmptyPage)
      return (
        <EmptyPage
          type="shared devices"
          icon={<FaVrCardboard />}
          linktext="Click here to add a device"
          clickHandler={() => $('#add-device-modal').foundation('open')}
        />
      )
    if (!showEmptyPage && showEmptyState)
      return (
        <FilterEmptyState
          type="Shared Devices"
          icon={<FaVrCardboard />}
          clickHandler={() => {
            setSearchQuery('')
            setSelectedItems([])
            setFilteredType(null)
            setShowEmptyState(false)
          }}
        />
      )
    return (
      <Table
        data={sortedData()}
        headers={tableHeaders.filter(
          (i) => !preferences.filteredColumns.includes(i)
        )}
        sortOn={preferences.sortOn}
        sortHandler={(value) => sortHandler(value, preferences, setPreferences)}
        selectedItems={selectedItems}
        selectable
        checkboxHandler={checkboxHandler}
        selectAllHandler={(ids) => setSelectedItems(ids)}
        type="sharedDevices"
        rowSuffix={(i) => renderOptions(i)}
        placeholderRows={2}
      />
    )
  }
  const toastContent = (
    <Dropdown
      id="dropup-toast"
      position="top"
      alignment="right"
      offset={{ left: '17px' }}
      button={
        <button
          data-toggle="dropup-toast"
          className="cursor-pointer h-100 outline-none">
          <FaEllipsisH />
        </button>
      }>
      <>
        <li className="o-dropdown__list-item" data-open="edit-device-modal">
          Edit shared devices
        </li>
        <hr className="mb-1 mt-1" />
        <li className="o-dropdown__list-item" data-open="delete-device-modal">
          Delete shared devices
        </li>
      </>
    </Dropdown>
  )
  return (
    <div id="shared-devices">
      {loadModals()}
      <div className="grid-container fluid">
        <TableHeader
          title="Shared device"
          length={sortedData().length}
          leftBar={tableSearchBar}
          rightBar={
            <>
              <TableColumnsDropdown content={content} />
              <TableAddButton dataOpen="add-device-modal" title="New Device" />
            </>
          }
        />
      </div>
      <div className="o-table--page-container grid-container fluid">
        {pageContent()}
      </div>
      <Toast
        dismissable
        isClosing={dismissToast}
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'devices' : 'device'}
        closeHandler={() => {
          setDismissToast(true)
          setTimeout(() => setDismissToast(false), 300)
          setTimeout(() => setSelectedItems([]), 10)
        }}>
        {toastContent}
      </Toast>
    </div>
  )
}
export default withApollo(SharedDevices)
