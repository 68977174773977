import React from 'react'
import { numberToCurrency } from '../../../utils/helpers/javascript'
import { getAdditionalPrice, getPlanPrice } from '../../../utils/helpers/subscription'
import Modal from '../../UI/Modal'

const ManagePlanModal = ({ id, appendTo, currentClient }) => {
  const planPrice = getPlanPrice(
    currentClient.planBillingCurrency,
    currentClient.plan
  )

  const getSupportEmailLink = () => (
    <a
      className="u-color-stable-dark text-underline"
      href="mailto:support@warpvr.com">
      support@warpvr.com
    </a>
  )

  return (
    <Modal
      id={id}
      key={id}
      appendTo={appendTo}
      overflowY="visible"
      headerText="Manage plan"
      buttons={
        <button
          className="hollow button secondary wide text-bold"
          data-close={id}>
          Close
        </button>
      }
      banner={
        <div className="border-bottom pt-3 pb-3 pl-3 pr-3 mb-3">
          <div className="row mb-2">
            <label className="columns small-5">Current plan</label>
            <div className="columns flex-container text-normal align-justify align-middle u-color-stable-dark">
              <span>{currentClient.plan.name}</span>
              {planPrice && (
                <span>
                  {numberToCurrency(
                    planPrice,
                    currentClient.planBillingCurrency
                  )}{' '}
                  / year
                </span>
              )}
            </div>
          </div>
          <div className="row align-justify align-middle">
            <label className="columns small-5">Workspaces</label>
            <div className="columns flex-container text-normal align-justify align-middle u-color-stable-dark">
              <span>{currentClient.plan.includedWorkspaces}</span>
              {!currentClient.plan.legacy && (
                <span>
                  {getAdditionalPrice(
                    'workspace',
                    currentClient.planBillingCurrency,
                    currentClient.plan.includedWorkspaces
                  )}{' '}
                  / year
                </span>
              )}
            </div>
          </div>
        </div>
      }>
      <p>
        In case you would like to upgrade or downgrade your plan or billing
        period, please contact us through phone, chat or {getSupportEmailLink()}
        . We will be in touch as soon as possible.
      </p>
    </Modal>
  )
}

export default ManagePlanModal
