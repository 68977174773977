export const init = () => {
  $('input[data-toggle-bundle-id]').on("change", function() {
    var textInput = $("#app_" + $(this).data('toggle-bundle-id') + "_bundle_id");

    if ($(this).is(':checked')) {
      if (textInput.val() === "") {
        var previousVal = textInput.data('previous-val');
        if (previousVal !== "") {
          textInput.val(previousVal);
        }
      }
    } else {
      var currentVal = textInput.val();
      if (currentVal !== "") {
        textInput.data('previous-val', currentVal);
        textInput.val("");
      }
    }
  });
}
