export function init() {
  // Add class to let snackbar pop up
  $("[data-snackbar]").addClass("c-snackbar--loaded");

  // Auto hide snackbar after 5s
  if ($(".c-snackbar--loaded").length > 0) {
    setTimeout(function() {
      $(".c-snackbar--loaded").removeClass("c-snackbar--loaded");
    }, 5000);
  }
}
