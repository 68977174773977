import { observable, reaction, computed, toJS } from 'mobx'
import SceneStore from './card/scene'
import EndingStore from './card/ending'
import AnswerStore from './answer'
import SelectionStore from './selection'

class DiagramStore {
  @observable authenticity_token = ''

  @observable cards = []

  @observable highlightedScene = null

  @observable urls

  @observable activeAnswer

  @observable selection = {}

  @observable activeElement = {}

  @observable
  doubleClickedCanvas = {
    active: false,
    x: 0,
    y: 0,
  }

  @observable
  midPoint = {
    x: 0,
    y: 0,
  }

  @observable
  canvas = {
    width: 320000,
    height: 160000,
    padding: 20,
  }

  zoomLevels = [0.25, 0.5, 0.75, 1]

  @observable zoomIndex = this.zoomLevels.length - 1

  @observable zoomFactor = this.zoomLevels[this.zoomIndex]

  workarea = jQuery('.c-workarea--fit')

  zoomElement = jQuery('[data-zoom-level]')

  zoomAnimator = null

  constructor(cards, authenticity_token, urls, cameraLocations) {
    this.authenticity_token = authenticity_token

    this.urls = urls

    this.cameraLocations = cameraLocations

    this.selection = new SelectionStore(this)

    this.initCards(cards)

    reaction(
      () => [this.activeElement],
      () => {
        this.resetState(this.activeElement)
      }
    )

    reaction(
      () => [this.zoomFactor],
      () => {
        this.alignCanvas()
      }
    )

    reaction(
      () => [this.zoomIndex],
      () => {
        this.saveCanvasState()
      }
    )

    return this
  }

  setInitialState() {
    if (this.getCanvasState()) {
      // state loaded from cookie
      this.workarea.scrollLeft(this.midPoint.x)
      this.workarea.scrollTop(this.midPoint.y)
    } else if (this.startScene != null) {
      // set to start scene
      this.scrollTo(this.startScene)
    } else if (this.cards.length > 0) {
      // set to first scene
      this.scrollTo(this.cards[0])
    } else {
      // set to middle of canvas
      this.midPoint = {
        x: this.canvas.width / 4,
        y: this.canvas.height / 2,
      }
      this.alignCanvas()
    }
    this.saveCanvasState()
  }

  saveCanvasState() {
    const currentX = this.workarea.scrollLeft()
    const currentY = this.workarea.scrollTop()

    const state = {
      zoomIndex: this.zoomIndex,
      midPoint: {
        x: currentX,
        y: currentY,
      },
    }

    window.localStorage.setItem(this.urls.scenario_url, JSON.stringify(state))
  }

  getCanvasState() {
    let state = window.localStorage.getItem(this.urls.scenario_url)
    if (state) {
      state = JSON.parse(state)
      this.midPoint = state.midPoint
      this.zoomIndex = state.zoomIndex
      this.zoomFactor = this.zoomLevels[this.zoomIndex]
      this.zoomElement.html(`${this.zoomLabel}%`)
      return true
    }
    return false
  }

  search(keyword) {
    return toJS(
      this.cards.filter((c) => {
        if (c instanceof SceneStore) {
          return (
            c.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
            c.priority == keyword
          )
        }
        return false
      })
    )
  }

  zoom(direction) {
    this.alignCanvas()

    if (direction === 'in' && this.zoomIndex < this.zoomLevels.length - 1) {
      this.zoomIndex += 1
    }

    if (direction === 'out' && this.zoomIndex > 0) {
      this.zoomIndex -= 1
    }

    const difference = this.zoomFactor - this.newZoomFactor

    window.requestAnimationFrame(() => {
      this.animateZoom(difference / 4)
    })
  }

  @computed
  get newZoomFactor() {
    return this.zoomLevels[this.zoomIndex]
  }

  @computed
  get zoomLabel() {
    return this.zoomLevels[this.zoomIndex] * 100
  }

  animateZoom(factor) {
    if (Math.abs(this.zoomFactor - this.newZoomFactor) > 0.00001) {
      this.zoomFactor -= factor
      window.requestAnimationFrame(() => {
        this.animateZoom(factor)
      })
    } else {
      this.zoomFactor = this.newZoomFactor
    }
  }

  alignCanvas() {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const newMidPoint = {
      x: this.midPoint.x * this.zoomFactor,
      y: this.midPoint.y * this.zoomFactor,
    }

    this.workarea.scrollLeft(newMidPoint.x - windowWidth / 2)
    this.workarea.scrollTop(newMidPoint.y - windowHeight / 2)
  }

  initCards(cards) {
    this.cards = []
    for (const cardObj of cards) {
      let card = null
      if (cardObj.type === 'Scene') {
        card = new SceneStore(this, cardObj)
      } else {
        card = new EndingStore(this, cardObj)
      }
      this.cards.push(card)
    }

    for (const card of cards) {
      if (card.type === 'Scene') {
        for (const answerObj of card.answers) {
          const fromCard = this.findCardById(card.id)
          const toCard = this.findCardById(answerObj.link_to_id)
          fromCard.answers.push(
            new AnswerStore(this, fromCard, toCard, answerObj)
          )
        }
      }
    }
  }

  addEnding(x, y, answer_id, answer_randomized_index) {
    const ending = new EndingStore(this, {
      x,
      y,
    })
    ending.create(answer_id, answer_randomized_index)
  }

  resetState(elem = null) {
    if (elem.type === 'canvas') {
      this.resetStateOfCards()
      this.hideAllMenus()
      this.selection.resetSelectionArea()
      this.selection.unselectAllCards()
    }
    if (elem.type === 'scene' || elem.type === 'ending') {
      if (!this.selection.isHoldingShift) {
        this.selection.unselectAllCards()
      }
      this.resetStateOfCards(elem)
      this.hideAllMenus(elem)
    }
    if (elem.type === 'answer') {
      this.resetStateOfCards()
      this.hideAllMenus(elem)
      this.selection.unselectAllCards()
    }
    if (elem.type === 'selection') {
      this.hideAllMenus()
      this.resetStateOfCards()
    }
  }

  resetStateOfCards(elem = null) {
    for (const card of this.cards) {
      card.resetState(elem)
    }
  }

  hideAllMenus(elem = null) {
    for (const card of this.cards) {
      if (card.type === 'scene') {
        for (const answer of card.answers) {
          if (answer !== elem) {
            answer.showMenu = false
            answer.showLetterMenu = false
            answer.isAddingNewCard = false
          }
        }
      }
    }
    this.doubleClickedCanvas.active = false
  }

  scrollToHome() {
    this.scrollTo(this.startScene)
  }

  flipAllCards() {
    const cards = []

    for (const card of this.cards) {
      if (card.type === 'scene') {
        cards.push(card.isFlipped)
      }
    }

    // return correct true when all cards are flipped
    // return undefined when some cards are flipped
    // return false if all cards are not flipped
    const res = cards.reduce((a, b) => {
      return a === b ? a : undefined
    })

    if (res || res === undefined) {
      // all cards will be flipped to the front
      this.cards.map((card) => {
        card.isFlipped = false
        return card
      })
    } else {
      // all cards will be flipped to the back
      this.cards.map((card) => {
        card.isFlipped = true
        return card
      })
    }
  }

  findCardById(id) {
    for (const card of this.cards) {
      if (card.id === id) {
        return card
      }
    }
    return null
  }

  @computed
  get scenes() {
    return this.cards.filter((card) => card.type === 'scene')
  }

  @computed
  get startScene() {
    return this.cards.find((card) => card.start === true)
  }

  positionWithinCard(x, y) {
    for (const scene of this.cards) {
      if (scene.isWithinBoundaries(x * this.zoomFactor, y * this.zoomFactor)) {
        return scene
      }
    }
    return false
  }

  addNewCardAt(x, y, answer = null, draggable_type = 'Scene') {
    let href = `${this.urls.modal_url}?draggable_type=${draggable_type}&coordinates[x]=${x}&coordinates[y]=${y}`
    if (answer != null) {
      href += `&incoming_answer_id=${answer.id}&incoming_answer_randomized_index=${answer.randomizedIndex}`
    }

    jQuery('[data-add-card]').attr('href', href)
    jQuery('[data-add-card]').trigger('click')
  }

  scrollTo(scene, animated = false) {
    const windowWidth = window.innerWidth
    const windowHeight = this.workarea.height()
    const scrollToX =
      scene.x * this.zoomFactor - windowWidth / 2 + scene.dimensions.width / 2
    const scrollToY =
      scene.y * this.zoomFactor - windowHeight / 2 + scene.dimensions.height / 2

    if (animated) {
      this.workarea.animate({
        scrollTop: scrollToY,
        scrollLeft: scrollToX,
      })
    } else {
      this.workarea.scrollLeft(scrollToX)
      this.workarea.scrollTop(scrollToY)
    }
  }
}

export default DiagramStore
