import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { default as S } from '../../../../utils/lang/en.json'
import { handleApolloError } from '../../../../utils/errors'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import Modal from '../../../UI/Modal'
import { removeCameraLocationMutation } from '../../../../apollo/query/cameraLocations'

const DeleteCameraLocationModal = ({ id, manageModalId, appendTo, locationId, callback }) => {
  const [removeCameraLocation] = useMutation(removeCameraLocationMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({ title: 'Camera location deleted' })
      $(`#${manageModalId}`).foundation('open')
      callback(locationId)
    },
    variables: {
      id: locationId,
    },
  })

  let title = `${S.strings.confirmDeleteTextSingle} camera location?`

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      textAssertive
      closable={false}
      headerText={title}
      subtitle="This action cannot be undone"
      submitButtonText="Yes, I'm sure"
      useDefaultButtons
      submitHandler={removeCameraLocation}
    />
  )
}
export default DeleteCameraLocationModal
