import React, { useContext, useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import useFoundation from '../../hooks/useFoundation'
import LanguageContext from '../../utils/context/LanguageContext'

const TabView = ({ data, client, setIsCourse, setSelectedIndex }) => {
  const { translations: t } = useContext(LanguageContext)
  const [current, setCurrent] = useState(0)
  const [collapsed, setCollapsed] = useState(true)
  const ref = useFoundation()

  const [extraRightPadding, setExtraRightPadding] = useState(0)

  const calculateExtraPadding = () => {
    if (window.innerWidth > 1400) {
      const margin = (window.innerWidth - 1400) / 2
      return setExtraRightPadding(margin)
    }
    return setExtraRightPadding(40)
  }

  useEffect(() => {
    calculateExtraPadding()
  }, [window.innerWidth])

  useEffect(() => {
    setIsCourse(data[current].type === 'course')
    document.title = `Warp VR | ${data[current].name} | ${client.name}`
  }, [data])

  const createTabTitles = (c) => {
    const id = c === 'small' ? 'tab-small-' : 'tab-' // we need to check which tabs (mobile or desktop) are loaded for the corners
    return data.map(({ name, type }, i) => {
      return (
        <li
          id={`${id}${i}`}
          key={i}
          className={`tabs-title mr-1 ${
            current === i
              ? 'is-active c-launcher__tabbar--active corners-overlay'
              : ''
          }`}
          onMouseOver={() => {
            document.getElementById(`tab-${i}`).classList.add('corners-overlay')
          }}
          onMouseOut={() => {
            if (current !== i) {
              document
                .getElementById(`tab-${i}`)
                .classList.remove('corners-overlay')
            }
          }}
          onClick={() => {
            document.getElementById(`tab-${i}`).scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
              block: 'end',
            })
            setIsCourse(type === 'course')
            setCurrent(i)
            setSelectedIndex(i)
            setCollapsed(true)
          }}>
          <a href={`#panel-${i}`} data-tabs-target={`panel-${i}`}>
            {name}
          </a>
        </li>
      )
    })
  }

  const createTabContent = () => {
    return data.map((item, i) => {
      return (
        <div
          key={i}
          className={`tabs-panel ${current === i ? 'is-active' : ''}`}
          id={`panel-${i}`}>
          {item.render}
        </div>
      )
    })
  }

  return (
    <div ref={ref}>
      {data.length > 1 && ( // only show tabs if there are more then 1
        <div className="c-launcher__tabbar">
          <div className="grid-container fluid">
            <div className="grid-x c-launcher__page--container c-launcher__tabbar-container no-margin pb-0 align-middle">
              <div
                className={`cell grid-x  medium-shrink hide-for-large pre-text mb-2 cursor-pointer`}
                onClick={() => setCollapsed(!collapsed)}>
                <div className="cell small-12">{t.signIn.accessTo} </div>
                <span className="cell small-11 text-stable-dark">
                  {data[current].name}
                </span>
                <span className="cell-small-1">
                  {collapsed ? (
                    <>
                      <FaChevronDown />
                    </>
                  ) : (
                    <FaChevronUp />
                  )}
                </span>
              </div>
              <div className="cell shrink show-for-large pre-text">
                {t.signIn.accessTo}
              </div>
              <ul
                className={`cell auto hide-for-large c-launcher__tabbar-tabs tabs`}
                data-tabs
                hidden={collapsed}
                id="small-tabs">
                {createTabTitles('small')}
              </ul>
              <ul
                className="cell auto show-for-large c-launcher__tabbar-tabs tabs"
                data-tabs
                id="large-tabs">
                <span
                  style={{
                    display: 'inline-flex',
                    paddingRight: extraRightPadding,
                  }}>
                  {createTabTitles()}
                </span>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div
        className="tabs-content c-launcher__tabs-content"
        data-tabs-content="example-tabs">
        {createTabContent()}
      </div>
    </div>
  )
}
export default TabView
