import { useMutation } from '@apollo/client'
import React, { useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  addUserToGroupsMutation,
  removeUserFromGroupsMutation,
} from '../../../apollo/query/groups'
import Modal from '../../UI/Modal'
import TraineeDetailGroupsList from '../Forms/TraineeDetailGroupsList'
import { chunkArray } from '../../../utils/helpers/javascript'
import { removeAlert, addSuccessAlert, addErrorAlert } from '../../../utils/helpers/alerts'

const BulkManageTraineeGroupsModal = ({
  id,
  appendTo,
  userIds,
  addUsers = true,
  bulkActionCallback,
}) => {
  const groupIdsRef = useRef()
  const [key, setKey] = useState(uuidv4())

  const [addGroupsMutation] = useMutation(addUserToGroupsMutation)
  const [removeGroupsMutation] = useMutation(removeUserFromGroupsMutation)

  let alertId

  const handleSuccess = () => {
    removeAlert(alertId)

    let title
    if (addUsers) {
      title = 'Finished adding users to groups'
    } else {
      title = 'Finished removing users from groups'
    }

    addSuccessAlert({
      title
    })

    bulkActionCallback()
  }

  const handleError = (err) => {
    removeAlert(alertId)

    addErrorAlert({
      title: 'Something went wrong',
      subtitle: 'Please try again.'
    })

    throw new Error(err.message)
  }

  const createAlert = () => {
    let title

    if (addUsers) {
      title = `Currently adding ${userIds.length} trainees to ${groupIdsRef.current.length} groups`
    } else {
      title = `Currently removing ${userIds.length} trainees from ${groupIdsRef.current.length} groups`
    }
    const subtitle = `Please stay on this page until the process is done.`

    alertId = addSuccessAlert({
      title,
      subtitle,
      timeout: false,
      dismissable: false
    })
  }

  const confirmHandler = () => {
    const promise = async () => {
      const userLimit = 500
      const chunks = chunkArray(userIds, userLimit)
      const allresponses = []

      createAlert()

      if (addUsers) {
        for (const chunk of chunks) {
          const response = await addGroupsMutation({
            variables: {
              userIds: chunk,
              groupIds: groupIdsRef.current,
            },
          })
          allresponses.push(response)
        }
      } else {
        for (const chunk of chunks) {
          const result = await removeGroupsMutation({
            variables: {
              userIds: chunk,
              groupIds: groupIdsRef.current,
            },
          })
          allresponses.push(result)
        }
      }
      return allresponses
    }
    promise()
      .then(() => {
        handleSuccess()
      })
      .then(() => setKey(uuidv4()))
      .catch((err) => handleError(err))
  }
  const headerText = () => {
    if (addUsers) return 'Add selected trainees to groups'
    return 'Remove trainees from groups'
  }
  const labelText = () => {
    if (addUsers) return 'Add trainees to the following groups'
    return 'Remove trainees from the following groups'
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      overflowY="visible"
      headerText={headerText()}
      cancelHandler={() => setTimeout(() => setKey(uuidv4()), 300)}
      useDefaultButtons
      footerText={
        addUsers &&
        'Trainees will be notified by email in case of new course or scenario access.'
      }
      submitHandler={() => {
        confirmHandler()
      }}>
      <form className="mt-3" key={key}>
        <label>{labelText()}</label>
        <TraineeDetailGroupsList groupRef={groupIdsRef} />
        {/* Fake submit button to prevent enter... */}
        <button
          type="submit"
          disabled
          style={{ display: 'none' }}
          aria-hidden="true"
        />
      </form>
    </Modal>
  )
}
export default BulkManageTraineeGroupsModal
