import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { exportAttemptsMutation } from '../../apollo/query/attempts'
import RadioButton from '../UI/Form/RadioButton'
import Modal from '../UI/Modal'
import { addErrorAlert, addSuccessAlert } from '../../utils/helpers/alerts'
import { handleApolloError } from '../../utils/errors'

const ExportAttemptModal = ({ id, appendTo, filterOptions }) => {
  const [selectedValue, setSelectedValue] = useState(0)
  const kind = ['ATTEMPTS', 'CHOICES']

  const [exportAttempts] = useMutation(exportAttemptsMutation, {
    variables: {
      kind: kind[selectedValue],
      ...filterOptions,
    },
    onError: handleApolloError,
    onCompleted: (data) => {
      if (data.exportAttempts.success)
        return addSuccessAlert({
          title: 'The export of trainee data is being made',
          subtitle: 'You are notified by email when finished',
          icon: 'progress',
          timeout: false,
        })

      addErrorAlert({
        title: 'Something went wrong',
        subtitle: 'Please try again.',
      })
    },
  })

  const subtitle = () => {
    return (
      <span>
        Create a CSV export of training attempt data.{' '}
        <a
          className="text-stable-dark text-underline"
          href="https://help.warpvr.com"
          target="_blank"
          rel="noreferrer">
          More information
        </a>
      </span>
    )
  }
  return (
    <Modal
      id={id}
      headerText="Export data from selected training attempts"
      subtitle={subtitle()}
      appendTo={appendTo}
      footerText="Exports are made in the background. You are notified by email when finished."
      submitHandler={exportAttempts}
      cancelHandler={() => {
        setTimeout(() => setSelectedValue(0), 200)
      }}
      useDefaultButtons>
      <form>
        <label>What data would you like to export?</label>
        <fieldset className="small-12">
          <RadioButton
            id="radio-attempts"
            label={
              <div
                style={{ display: 'inline-flex', flexGrow: 1 }}
                className="mb-0">
                <section className=" mr-1">Attempts</section>
                <section className="text-stable-dark">
                  {` • One row per attempt`}
                </section>
              </div>
            }
            checked={selectedValue === 0}
            clickHandler={() => {
              setSelectedValue(0)
            }}
            name="select-export"
          />
          <RadioButton
            id="radio-choises"
            label={
              <div
                style={{ display: 'inline-flex', flexGrow: 1 }}
                className="mb-0 ">
                <section className="  mr-1">Choices</section>
                <section className="text-stable-dark">
                  {` • One row per choice`}
                </section>
              </div>
            }
            checked={selectedValue === 1}
            clickHandler={() => {
              setSelectedValue(1)
            }}
            name="select-export"
          />
        </fieldset>
      </form>
    </Modal>
  )
}

export default ExportAttemptModal
