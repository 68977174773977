import { gql } from '@apollo/client'

const registrationQuery = gql`
  query registration($id: ID!) {
    registration(id: $id) {
      id
      state
      code
      device {
        description
        id
      }
    }
  }
`

const getDevices = gql`
  query Devices {
    devices {
      id
      name
      registration
      description
      disableDeleteDownload
      signOutAfterSleep
      groups {
        id
        name
        endDate
        startDate
      }
    }
  }
`
const createDeviceRegistration = gql`
  mutation createRegistration {
    createRegistration {
      registration {
        id
        code
        state
        device {
          id
          name
          registration
          description
          groups {
            id
          }
        }
      }
    }
  }
`

const cancelRegistrationMutation = gql`
  mutation cancelRegistration($id: ID!) {
    cancelRegistration(id: $id) {
      registration {
        id
        state
      }
    }
  }
`

const confirmDeviceRegistrationMutation = gql`
  mutation confirmRegistration($id: ID!, $name: String!) {
    confirmRegistration(id: $id, name: $name) {
      device {
        id
        name
        registration
        description
        disableDeleteDownload
        signOutAfterSleep
        groups {
          id
        }
      }
    }
  }
`

const updateDeviceMutation = gql`
  mutation updateDevice(
    $id: ID!
    $name: String
    $registration: DeviceRegistration!
    $groupIds: [ID!]
    $disableDeleteDownload: Boolean
    $signOutAfterSleep: Boolean
  ) {
    updateDevice(
      id: $id
      name: $name
      registration: $registration
      groupIds: $groupIds
      disableDeleteDownload: $disableDeleteDownload
      signOutAfterSleep: $signOutAfterSleep
    ) {
      device {
        id
        name
        registration
        description
        disableDeleteDownload
        signOutAfterSleep
        groups {
          id
        }
      }
    }
  }
`

export {
  getDevices,
  registrationQuery,
  createDeviceRegistration,
  cancelRegistrationMutation,
  confirmDeviceRegistrationMutation,
  updateDeviceMutation,
}
