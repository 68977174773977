import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import Alert from '../../components/UI/Notifications/Alert'
import cache from '../../apollo/cache'
import { getNotifications } from '../../apollo/query/store'
import { updateNotifications } from './graphql'

const addAlert = (alert) => {
  const { notifications } = cache.readQuery({ query: getNotifications })
  updateNotifications([...notifications, alert])
}

const removeAlert = (alertId) => {
  if (!alertId) {
    return
  }

  const { notifications } = cache.readQuery({ query: getNotifications })
  updateNotifications([...notifications.filter((i) => i.props.id !== alertId)])
}

const addSuccessAlert = ({
  title,
  subtitle,
  icon,
  timeout = 6000,
  dismissable = true,
}) => {
  const alertId = uuidv4()

  addAlert(
    <Alert
      id={alertId}
      key={alertId}
      title={title}
      subtitle={subtitle}
      icon={icon}
      type="success"
      dismissable={dismissable}
      timeout={timeout}
    />
  )

  return alertId
}

const addErrorAlert = ({
  title,
  subtitle,
  icon,
  timeout = 6000,
  dismissable = true,
}) => {
  const alertId = uuidv4()

  addAlert(
    <Alert
      id={alertId}
      key={alertId}
      title={title}
      subtitle={subtitle}
      icon={icon}
      type="warning"
      dismissable={dismissable}
      timeout={timeout}
    />
  )

  return alertId
}

export { addAlert, removeAlert, addSuccessAlert, addErrorAlert }
