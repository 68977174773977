import { observable } from 'mobx'
import CardStore from '../card'

class EndingStore extends CardStore {
  @observable incomingAnswers = []

  @observable isFlipped = false

  @observable isDragging = false

  @observable showMoreMenu = false

  @observable type = 'ending'

  dimensions = {
    width: 270,
    height: 80,
  }

  show() {
    window.location.href = `${this.store.urls.endings_url}`
  }

  asJson() {
    return {
      x: this.x,
      y: this.y,
      draggable_type: 'Ending',
      draggable_attributes: {
        id: this.id,
      },
      draggable_id: this.id,
    }
  }
}

export default EndingStore
