import { capitalized } from './javascript'

export const actionFormatter = (action) => {
  const ws = action.split('_')
  return `${capitalized(ws[0])} ${ws[1] ? ws[1].toLowerCase() : ''}`
}

export const renderActionValues = (action, metadata) => {
  switch (action) {
    case 'CREATED_SCENE':
    case 'CREATED_ELEMENT':
      return null
    default:
      return metadata[0].value
  }
}
