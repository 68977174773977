import React, { useContext } from 'react'
import { TabContent } from '../../components/UI/Tabs/Tabs'
import withApollo from '../../hooks/withApollo'
import TrainingAttempts from './TrainingAttempts'
import Overview from './Overview'
import ActivityContext, {
  ActivityContextProvider,
} from '../../utils/context/ActivityContext'

const tabs = [
  { key: '', title: 'Overview' },
  { key: 'attempts', title: 'Training attempts' },
]

const ActivityTabContents = () => {
  const { searchParams, setSearchParams } = useContext(ActivityContext)
  const content = [
    <Overview key="overview-tab" />,
    <TrainingAttempts key="trainingAttempts-tab" />,
  ]

  return (
    <>
      <div className="c-sub-menu" style={{ marginTop: '-2.5rem' }}>
        <div className="grid-container fluid">
          <div className="c-sub-menu__inner">
            <ul className="c-sub-menu__list">
              {tabs.map((tab, index) => (
                <li
                  key={tab.key}
                  className={`c-sub-menu__list-item ${
                    searchParams.tab === tab.key ||
                    (!searchParams.tab && index === 0)
                      ? 'active'
                      : ''
                  }`}>
                  <a
                    className="c-sub-menu__list-link"
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        tab: tab.key ?? '',
                      })
                    }>
                    {tab.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <TabContent
        titles={tabs.map((t) => t.title)}
        content={content}
        activeTab={
          searchParams.tab
            ? tabs.findIndex((t) => t.key === searchParams.tab)
            : 0
        }
      />
    </>
  )
}

const Activity = () => {
  return (
    <ActivityContextProvider>
      <ActivityTabContents />
    </ActivityContextProvider>
  )
}

export default withApollo(Activity)
