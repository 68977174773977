export function init() {
  $('[data-zoom]').on('click', function(e) {
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    var scrollPosition = {
      x: $('.c-workarea--fit').scrollLeft(),
      y: $('.c-workarea--fit').scrollTop()
    };

    window.diagramStore.midPoint = {
      x:
        (windowWidth / 2 + scrollPosition.x) / window.diagramStore.zoomFactor,
      y:
        (windowHeight / 2 + scrollPosition.y) / window.diagramStore.zoomFactor
    };

    var val = $(this).attr('data-zoom');
    e.preventDefault();
    window.diagramStore.zoom(val);

    $('[data-zoom-level]').html(window.diagramStore.zoomLabel + '%');
  });
}
