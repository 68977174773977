import React, { useContext, useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import LanguageContext from '../../utils/context/LanguageContext'

const ScenariosCarousel = ({ scenarios }) => {
  const { translations: t } = useContext(LanguageContext)
  const [extraRightPadding, setExtraRightPadding] = useState(0)

  const calculateExtraPadding = () => {
    if (window.innerWidth > 1400) {
      const margin = (window.innerWidth - 1400) / 2
      return setExtraRightPadding(margin)
    }
    return setExtraRightPadding(40)
  }

  useEffect(() => {
    calculateExtraPadding()
  }, [window.innerWidth])

  const scenariosCarousel = () => {
    return scenarios.map((scenario, i) => {
      return (
        <div
          key={i}
          id={`scenario-${i}`}
          className={`c-launcher__scenario-carousel--card cell medium-6 xxlarge-4`}>
          <div
            className="c-launcher__scenario-carousel--card--image"
            style={{ backgroundImage: `url("${scenario.imageUrl}")` }}
          />
          <h2 className="text-bold mt-2 mb-2">{scenario.name}</h2>
          <p>{scenario.description}</p>
        </div>
      )
    })
  }

  const { containsScenarios, scenarios: scenariosText } = t.sections.about

  return (
    <>
      <div className="grid-container fluid">
        <div
          className="grid-x grid-padding-x"
          style={{ maxWidth: '1400px', margin: '0 auto' }}>
          <div className="cell small-12 medium-shrink mb-4 p-0">
            <h1 className="text-bold">
              {containsScenarios} {scenarios.length} {scenariosText}
            </h1>
          </div>
        </div>
      </div>
      <div
        id="outer-wrapper"
        className="c-launcher__scenario-carousel--container">
        <div className="c-launcher__scenario-carousel--inner grid-container fluid">
          <div className="wrapper">
            <div className="wrapper--inner show-for-small-only">
              {scenariosCarousel()}
            </div>
          </div>
        </div>
      </div>
      <ScrollContainer
        horizontal
        hideScrollbars={false}
        className="no-scrollbars show-for-medium">
        <div id="outer-wrapper" className="c-launcher__page-container">
          <div className="c-launcher__scenario-carousel--inner grid-container fluid">
            <div className="wrapper">
              <div
                className="wrapper--inner"
                style={{ paddingRight: extraRightPadding }}>
                {scenariosCarousel()}
              </div>
            </div>
          </div>
        </div>
      </ScrollContainer>
    </>
  )
}
export default ScenariosCarousel
