import React, { useEffect, useState } from 'react'
import useCurrentUser from '../../hooks/useCurrentUser'
import { useMutation } from '@apollo/client'
import { updateProfileMutation } from '../../apollo/query/user'
import { addSuccessAlert } from '../../utils/helpers/alerts'
import useFileUploader from '../../hooks/useFileUploader'
import DataTooltip from '../UI/DataTooltip'

const ProfileTab = () => {
  const [currentUser, , , refetch] = useCurrentUser()
  const [updating, setUpdating] = useState()

  const [updateProfile] = useMutation(updateProfileMutation, {
    onCompleted: () => {
      addSuccessAlert({
        title: 'Profile was updated',
      })
      refetch()
    },
  })
  const [uploadItems, setUploadItems] = useFileUploader()

  useEffect(() => {
    const fileToUpload = uploadItems[uploadItems.length - 1]
    if (fileToUpload?.blobId) {
      updateProfile({ variables: { blobId: fileToUpload.blobId } })
    }
  }, [uploadItems])

  if (!currentUser) return null

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const elements = e.target.elements
    setUpdating(true)
    updateProfile({
      variables: {
        firstName: elements.firstName.value,
        lastName: elements.lastName.value,
      },
    }).then(() => setUpdating(false))
  }

  const handleUpload = ({ target }) => {
    if (target.files[0]) setUploadItems([target.files[0]])
  }

  return (
    <>
      <div className="row border-bottom pb-3 mb-3">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Profile settings</h2>
          <p>Set your first name and last name</p>
        </div>
        <form
          onSubmit={handleFormSubmit}
          className="xlarge-6 medium-8 small-12 columns mt-0">
          <label>First name</label>
          <input
            type="text"
            name="firstName"
            required
            defaultValue={currentUser.firstName}
          />

          <label>Last name</label>
          <input
            type="text"
            name="lastName"
            required
            defaultValue={currentUser.lastName}
          />

          <label>Email</label>
          <DataTooltip title="Please contact support@warpvr.com if you need to change your email address.">
            <input
              type="email"
              defaultValue={currentUser.email}
              disabled
              className="bg-light border-light"
            />
          </DataTooltip>

          <button
            type="submit"
            className="o-button o-button--assertive float-right mt-1 o-button--loading button primary text-normal mb-0"
            disabled={updating}
            aria-hidden="true">
            Confirm
          </button>
        </form>
      </div>
      <div className="row border-bottom pb-3 mb-3" id="branding-logos">
        <div className="xlarge-3 medium-4 small-12 columns">
          <h2 className="text-bold">Profile picture</h2>
          <p>Upload a profile picture</p>
        </div>
        <div className="xlarge-6 medium-8 small-12 columns flex-container">
          <div className="row flex-dir-column">
            <div
              className="w-100 border-radius--rounded-full mt-1 border"
              style={{ width: '180px', height: '180px' }}>
              <img
                className="border-radius--rounded-full w-100 h-100"
                src={currentUser.avatar.medium}
                alt="Logo"
              />
            </div>
            <button className="o-button o-button--secondary w-100 o-file-input o-file-input--basic mt-2 text-bold">
              Upload picture
              <input
                className="o-files-drop"
                type="file"
                onChange={handleUpload}
                accept=".png,.jpg"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
export default ProfileTab
