export const newEdgeId = 'new-node-edge'

export const removeEdge = (reactFlow, id) => {
  reactFlow.deleteElements({
    edges: [{ id }],
  })
}

export const scenesToEdges = (scenes) =>
  scenes
    .filter((c) => c.elements)
    .map((scene) => [
      ...scene.elements
        .filter(
          (el) =>
            el.linkToId && !el.linkToEnding && !el.randomizedLinkToIds?.length
        )
        .map((el) => ({
          id: el.id,
          source: scene.id,
          target: el.linkToId,
          type: 'default-edge',
          sourceHandle: `e${scene.id}-${el.id}`,
          targetHandle: `e${el.linkToId}-${el.id}`,
          updatable: 'target',
          data: { points: el.points },
        })),
      ...scene.elements
        .filter((el) => el.randomizedLinkToIds.length)
        .map((el) =>
          el.randomizedLinkToIds
            .map((targetSceneId, index) => {
              if (!targetSceneId || !scenes.find((s) => s.id === targetSceneId))
                return null
              const id = `${el.id}-${index}`
              return {
                id,
                source: scene.id,
                target: targetSceneId,
                type: 'default-edge',
                sourceHandle: `e${scene.id}-${el.id}-${index}`,
                targetHandle: `e${targetSceneId}-${el.id}-${index}`,
                updatable: 'target',
                data: { points: el.points },
              }
            })
            .filter((sceneId) => !!sceneId)
        )
        .flat(),
    ])
    .flat()

export const setEdge = (reactFlow, edge) => {
  reactFlow.setEdges(
    reactFlow.getEdges().map((e) => (e.id === edge.id ? edge : e))
  )
}

export const hideEdge = (reactFlow, edge) =>
  // We are using custom hidden in data because we need to keep the edge in the graph
  // When we use the hidden property, the handle position will be wrong after restore hidden edges
  reactFlow.setEdges(
    reactFlow.getEdges().map((e) =>
      e.id === edge.id
        ? {
            ...edge,
            id: `${edge.id}-h`,
            oldId: edge.id,
            className: 'hide',
            data: {
              ...edge.data,
              customHidden: true,
            },
          }
        : e
    )
  )

export const getHiddenEdges = (reactFlow) =>
  reactFlow.getEdges().filter((e) => e.data.customHidden)

export const removeHiddenEdges = (reactFlow) => {
  const hiddenEdges = getHiddenEdges(reactFlow)
  if (hiddenEdges.length) reactFlow.deleteElements({ edges: hiddenEdges })
}

export const restoreHiddenEdges = (reactFlow) => {
  const hiddenEdges = getHiddenEdges(reactFlow)

  reactFlow.setEdges(
    reactFlow
      .getEdges()
      .filter((e) => e.id !== newEdgeId)
      .map((e) =>
        hiddenEdges.find((edge) => edge.id === e.id)
          ? {
              ...e,
              id: e.oldId,
              className: '',
              data: { ...e.data, customHidden: false },
            }
          : e
      )
  )
}

export const unSelectHoverEdges = (reactFlow) => {
  const edges = reactFlow.getEdges()
  reactFlow.setEdges(
    edges.map((edge) =>
      edge.selected && edge.data.selectType === 'hover'
        ? {
            ...edge,
            selected: false,
            data: {
              ...edge.data,
              selectType: null,
            },
          }
        : edge
    )
  )
}
