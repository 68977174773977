import { gql } from "@apollo/client"

const getAllPlanHistories = gql`
  query getAllPlanHistories {
    allPlanHistories {
      client {
        id
        name
      }
      planChange {
        changed
        changeFrom {
          id
          name
        }
        changeTo {
          id
          name
        }
      }
      periodChange {
        changed
        changeFrom
        changeTo
      }
      currencyChange {
        changed
        changeFrom
        changeTo
      }
      additionalPublishedScenariosChange {
        changed
        changeFrom
        changeTo
      }
      additionalWorkspacesChange {
        changed
        changeFrom
        changeTo
      }
      createdAt
    }
  }
`

export { getAllPlanHistories }
