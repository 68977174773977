import React from 'react'

const Stepper = ({
  steps,
  currentStep = 3,
  showActiveUntilCurrentStep = false,
}) => {
  const createSteps = () => {
    const list = []
    for (let i = 1; i <= steps; i++) {
      let isActive = false
      let isDone = false

      if (showActiveUntilCurrentStep && i < currentStep) isDone = true
      if (i === currentStep) isActive = true

      list.push(
        <div key={i} className={`stepper__item ${isDone ? 'done' : ''}`}>
          <div
            className={`stepper__number ${isActive ? 'active' : ''} ${
              isDone ? 'done' : ''
            }`}>
            {i}
          </div>
        </div>
      )
    }
    return list
  }
  return (
    <>
      <div
        data-testid="stepper"
        className="stepper"
        style={{
          maxWidth: '600px',
          width: `calc(${steps} * 62px)`,
          margin: '3rem auto',
        }}>
        {createSteps()}
      </div>
    </>
  )
}
export default Stepper
